import { z } from "zod";

export const AwardResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  player: z
    .object({
      id: z.string(),
      nickname: z.string(),
    })
    .nullable(),
  tournament: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  team: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  dateAwarded: z.coerce.date().nullable(),
  type: z.string(),
});

export const DbAwardSchema = z.object({
  id: z.string(),
  name: z.string(),
  playerId: z.string().nullable(),
  tournamentId: z.string().nullable(),
  teamId: z.string().nullable(),
  dateAwarded: z.coerce.date().nullable(),
  type: z.string(),
});

export const CreateUpdateAwardSchema = DbAwardSchema.omit({ id: true });

export type AwardResponse = z.infer<typeof AwardResponseSchema>;
export type DbAward = z.infer<typeof DbAwardSchema>;
export type CreateUpdateAward = z.infer<typeof CreateUpdateAwardSchema>;
