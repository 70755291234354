/* eslint-disable @typescript-eslint/unbound-method */
import {
  Listener,
  SubscribeArgs,
  Unsubscribe,
  DdsEdgeMessage,
  Ping,
  ChatRoom,
  ConnectionMetadata,
  ReconnectListener,
  SessionStateListener,
  AdminChatRoom,
} from "./types";
import axios, { AxiosInstance } from "axios";
import authInterceptor from "../../api/authInterceptor";
import { baseApiURL } from "../../config";

export * from "./types";

export class DDSClient {
  private _sessionOpened = false;
  private _client!: AxiosInstance;
  private readonly ddsBaseURL: string;

  public get sessionOpened() {
    return this._sessionOpened;
  }

  private set sessionOpened(value: boolean) {
    this._sessionOpened = value;
    this.triggerSessionStateChange();
  }

  private sseInstance: null | EventSource = null;
  private connectionId: null | string = null;
  private listeners: Array<Listener> = [];
  private reconnectListeners: Array<ReconnectListener> = [];
  private sessionStateListeners: Array<SessionStateListener> = [];
  private closingTimer: null | number = null;
  private connectionTimeout: null | number = null;

  constructor() {
    const url = new URL(baseApiURL);
    // cut off the subdomain
    url.hostname = `ddsedge.${url.hostname.split(".").slice(-2).join(".")}`;
    // generating URL https://ddsedge.blast.tv
    this.ddsBaseURL = url.origin;
    this._client = axios.create({
      baseURL: this.ddsBaseURL,
      withCredentials: true,
    });
    this._client.interceptors.request.use(authInterceptor);

    this.onMessage = this.onMessage.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  public open(): Promise<void> {
    return this.openConnection();
  }

  public close(): void {
    this.closeConnection();
  }

  public subscribe({
    roomId,
    newAdminMessagesListener,
    deletedMessageListener,
    restoredMessageListener,
  }: SubscribeArgs): Unsubscribe {
    const subscriptionId: string = Math.random().toString(32).split(".")[1];

    if (
      (deletedMessageListener || restoredMessageListener) &&
      roomId &&
      !this.roomExists(roomId)
    ) {
      this.subscribeToRoom(roomId);
    }

    if (newAdminMessagesListener && roomId && !this.adminRoomExists(roomId)) {
      this.subscribeToAdminRoom(roomId);
    }

    this.listeners.push({
      id: subscriptionId,
      roomId,
      newAdminMessagesListener,
      deletedMessageListener,
      restoredMessageListener,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const messageListenerIndex: number = this.listeners.findIndex(
            (messageListener) => messageListener.id === subscriptionId,
          );

          if (messageListenerIndex > -1) {
            const removedListener = this.listeners.splice(
              messageListenerIndex,
              1,
            )[0];

            if (
              (removedListener.deletedMessageListener ||
                removedListener.restoredMessageListener) &&
              roomId &&
              !this.roomExists(roomId)
            ) {
              this.unsubscribeFromRoom(roomId);
            }

            if (
              removedListener.newAdminMessagesListener &&
              roomId &&
              !this.adminRoomExists(roomId)
            ) {
              this.unsubscribeFromAdminRoom(roomId);
            }
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  public reconnect(): void {
    this.closeConnection();
    void this.openConnection().then(() => {
      this.triggerReconnect();
      const sessionStateChangeSub = this.onSessionStateChange(
        (sessionOpened) => {
          if (!sessionOpened) {
            return;
          }
          sessionStateChangeSub.unsubscribe();

          const rooms: Set<string> = new Set(
            this.listeners
              .filter(
                (listener) =>
                  typeof listener.restoredMessageListener === "function" ||
                  typeof listener.deletedMessageListener === "function",
              )
              .map(
                (messageListener) => messageListener.roomId,
              ) as Array<string>,
          );
          const adminRooms: Set<string> = new Set(
            this.listeners
              .filter(
                (listener) =>
                  typeof listener.newAdminMessagesListener === "function",
              )
              .map(
                (messageListener) => messageListener.roomId,
              ) as Array<string>,
          );

          rooms.forEach((roomId: string) => {
            this.subscribeToRoom(roomId);
          });
          adminRooms.forEach((roomId: string) => {
            this.subscribeToAdminRoom(roomId);
          });
        },
      );
    });
  }

  public onReconnect(callback: () => void): Unsubscribe {
    const reconnectId: string = Math.random().toString(32).split(".")[1];

    this.reconnectListeners.push({
      id: reconnectId,
      callback,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const listenerIndex: number = this.reconnectListeners.findIndex(
            (listener) => listener.id === reconnectId,
          );

          if (listenerIndex > -1) {
            this.reconnectListeners.splice(listenerIndex, 1);
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  public onSessionStateChange(callback: (value: boolean) => void): Unsubscribe {
    const sessionStateId: string = Math.random().toString(32).split(".")[1];

    this.sessionStateListeners.push({
      id: sessionStateId,
      callback,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const listenerIndex: number = this.sessionStateListeners.findIndex(
            (listener) => listener.id === sessionStateId,
          );

          if (listenerIndex > -1) {
            this.sessionStateListeners.splice(listenerIndex, 1);
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  private closeConnection(): void {
    this.stopStateWatcher();

    if (this.sseInstance === null) {
      return;
    }

    this.sseInstance.removeEventListener("message", this.onMessage);
    this.sseInstance.removeEventListener("error", this.onClose);
    this.sseInstance.close();
    this.sseInstance = null;

    if (this.closingTimer !== null) {
      window.clearTimeout(this.closingTimer);
    }
    this.connectionId = null;
    this.sessionOpened = false;
  }

  private openConnection(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.sseInstance = new EventSource(`${this.ddsBaseURL}/v1/c/connect`, {
        withCredentials: true,
      });
      this.sseInstance.addEventListener("message", this.onMessage);
      this.sseInstance.addEventListener("error", this.onClose);
      this.sseInstance.addEventListener("open", () => {
        resolve();
      });
    });
  }

  private onClose(): void {
    this.closingTimer = window.setTimeout(() => {
      this.closingTimer = null;
      this.reconnect();
    }, 1000);
  }

  private stopStateWatcher(): void {
    if (this.connectionTimeout !== null) {
      window.clearTimeout(this.connectionTimeout);
      this.connectionTimeout = null;
    }
  }

  private watchConnectionState(): void {
    this.stopStateWatcher();
    this.connectionTimeout = window.setTimeout(() => {
      this.reconnect();
    }, 20000);
  }

  private triggerReconnect() {
    this.reconnectListeners.forEach((listener) => {
      setTimeout(() => {
        listener.callback();
      }, 1);
    });
  }

  private triggerSessionStateChange() {
    this.sessionStateListeners.forEach((listener) => {
      setTimeout(() => {
        listener.callback(this.sessionOpened);
      }, 1);
    });
  }

  private onMessage(event: MessageEvent<string>): void {
    try {
      const data = JSON.parse(event.data) as DdsEdgeMessage;

      switch (data.type) {
        case "connection_metadata": {
          this.onConnectionMetaData(data.connection_metadata);
          break;
        }
        case "ping": {
          this.onPing(data.ping);
          break;
        }
        case "chat_room": {
          this.onChatRoom(data.chat_room);
          break;
        }
        case "admin_chat_room": {
          this.onAdminChatRoom(data.admin_chat_room);
          break;
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }

  private onConnectionMetaData(connectionMetadata: ConnectionMetadata): void {
    this.connectionId = connectionMetadata.connection_id;
    this.sessionOpened = true;
  }

  private onPing(ping: Ping): void {
    this._client
      .post(`/v1/c/pong`, {
        token: ping.token,
      })
      .catch(() => null);
    this.watchConnectionState();
  }

  private onChatRoom(chatRoom: ChatRoom): void {
    switch (chatRoom.type) {
      case "message_delete": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.deletedMessageListener === "function"
          ) {
            setTimeout(() => {
              messageListener.deletedMessageListener?.(chatRoom.message_delete);
            }, 1);
          }
        });
        break;
      }
      case "message_restore": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.restoredMessageListener === "function"
          ) {
            setTimeout(() => {
              messageListener.restoredMessageListener?.(
                chatRoom.message_restore,
              );
            }, 1);
          }
        });
        break;
      }
    }
  }

  private onAdminChatRoom(adminChatRoom: AdminChatRoom): void {
    switch (adminChatRoom.type) {
      case "message": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === adminChatRoom.room_uuid &&
            typeof messageListener.newAdminMessagesListener === "function"
          ) {
            setTimeout(() => {
              messageListener.newAdminMessagesListener?.(adminChatRoom.message);
            }, 1);
          }
        });
        break;
      }
    }
  }

  private subscribeToRoom(roomId: string): void {
    this.subscribeTo("chat_room", {
      room_uuid: roomId,
    });
  }

  private unsubscribeFromRoom(roomId: string): void {
    this.unsubscribeFrom("chat_room", {
      room_uuid: roomId,
    });
  }

  private subscribeToAdminRoom(roomId: string): void {
    this.subscribeTo("admin_chat_room", {
      room_uuid: roomId,
    });
  }

  private unsubscribeFromAdminRoom(roomId: string): void {
    this.unsubscribeFrom("admin_chat_room", {
      room_uuid: roomId,
    });
  }

  private subscribeTo(type: string, body: object): void {
    if (!this.sessionOpened) {
      return;
    }

    const requestBody = {
      type,
      connection_id: this.connectionId,
      [type]: body,
    };

    this._client.post(`/v1/c/subscription`, requestBody).catch(() => null);
  }

  private unsubscribeFrom(type: string, body: object): void {
    if (!this.sessionOpened) {
      return;
    }

    const requestBody = {
      type,
      connection_id: this.connectionId,
      [type]: body,
    };

    this._client
      .post(`/v1/c/subscription/unsubscribe`, requestBody)
      .catch(() => null);
  }

  private roomExists(roomId: string): boolean {
    return this.listeners.some(
      (listener) =>
        listener.roomId === roomId &&
        (typeof listener.restoredMessageListener === "function" ||
          typeof listener.deletedMessageListener === "function"),
    );
  }

  private adminRoomExists(roomId: string): boolean {
    return this.listeners.some(
      (listener) =>
        listener.roomId === roomId &&
        typeof listener.newAdminMessagesListener === "function",
    );
  }
}

const ddsClient = new DDSClient();

export default ddsClient;
