import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../../metricsViewership";
import { getTwitchGamesMap } from ".";

export const useGetTwitchGamesMap = () => {
  return useQuery({
    queryKey: queryKeys.twitchGames(),
    queryFn: getTwitchGamesMap,
  });
};
