import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { createMap } from "../../../../api/tournaments";

export default function usePostMap() {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["map"],
    mutationFn: async ({ mapName, matchId, scheduledAt }: { mapName: string; matchId: string; scheduledAt?: Date }) => {
      try {
        await createMap({
          mapName,
          matchId,
          scheduledAt: scheduledAt?.toISOString() || new Date().toISOString(),
        });
      } catch (error) {
        alert.showFailureAlert("Error While updating map");
        throw error;
      }
      await queryClient.refetchQueries(["matchMaps", matchId]);
    },
  });
}
