import { z } from "zod";

export const ScheduleSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  endTime: z.string().datetime(),
  startTime: z.string().datetime(),
  groupId: z.string(),
  creator: z.string(),
  isActive: z.boolean(),
  createdAt: z.string().datetime(),
});

export type Schedule = z.infer<typeof ScheduleSchema>;
