import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Stream } from "../../../../types/Streams";
import useDeleteStream from "../../hooks/useDeleteStream";

export function DeleteStreamConfirmation({
  stream,
  open,
  onClose,
  closeDetails,
  onStreamDeleted,
}: {
  stream?: Stream;
  open: boolean;
  onClose: () => void;
  closeDetails: () => void;
  onStreamDeleted: () => void;
}) {
  const { mutate: deleteStreamMutation, isLoading: isDeleting } = useDeleteStream(stream?.id ?? "", onStreamDeleted);

  const deleteStream = () => {
    if (!isDeleting) {
      deleteStreamMutation();
    }
    closeDetails();
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Deletion confirmation</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete:
            <p className="mt-1 font-medium">{stream?.title}</p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="destructive" disabled={isDeleting} onClick={deleteStream}>
            Delete
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
