import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateTournamentPrizeOptions, createTournamentPrize } from "../../../../../../api/tournaments";

export default function useCreateTournamentPrize() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateTournamentPrizeOptions) => createTournamentPrize(data),
    retry: false,
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["tournament prizes", variables.tournamentId],
      });
    },
  });
}
