import z from "zod";
import { DateStringSchema } from "./dateString";

export const DbPlayerSchema = z.object({
  id: z.string().min(1),
  nickname: z.string().min(1),
  country: z.string().nullable(),
  dateOfBirth: DateStringSchema.nullable(),
  avatarId: z.string().min(1),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
});

export type DbPlayer = z.infer<typeof DbPlayerSchema>;

export const DbPlayerGameSchema = z.object({
  gameId: z.string(),
  playerId: z.string(),
  ingameId: z.string().nullish(),
});

export type DbPlayerGame = z.infer<typeof DbPlayerGameSchema>;

export const DetailedPlayerSchema = DbPlayerSchema.omit({
  deletedAt: true,
}).extend({
  games: DbPlayerGameSchema.pick({
    gameId: true,
    ingameId: true,
  }).array(),
});

export type DetailedPlayer = z.infer<typeof DetailedPlayerSchema>;

export const PlayerCreateRequestSchema = DbPlayerSchema.pick({
  id: true,
  nickname: true,
  country: true,
  dateOfBirth: true,
  avatarId: true,
});

export const PlayerResponseSchema = DbPlayerSchema.omit({ deletedAt: true });

export const CreateMyPlayerRequestSchema = PlayerCreateRequestSchema.omit({
  id: true,
});

export const UpdatePlayerRequestSchema = DbPlayerSchema.pick({
  nickname: true,
  country: true,
  dateOfBirth: true,
}).partial();
