import { z } from "zod";

export const AuditLogRecordSchema = z.object({
  userId: z.string(),
  occurredAt: z.string().datetime(),
  creationDate: z.coerce.date(),
  action: z.string(),
  service: z.string(),
  metadata: z.record(z.unknown()).optional(),
});

export type AuditLogRecord = z.infer<typeof AuditLogRecordSchema>;

export const AuditLogsResponseSchema = z.object({
  logs: AuditLogRecordSchema.array(),
  nextPageToken: z.string().optional(),
});

export type AuditLogsResponse = z.infer<typeof AuditLogsResponseSchema>;
