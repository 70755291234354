import { z } from "zod";

export const RankingCategoriesResponseSchema = z.array(
  z.object({
    category: z.string(),
    subCategories: z.string().array(),
  }),
);

export const PostRocketLeagueLeaderboardRequestSchema = z.object({
  category: z.string(),
  subCategory: z.string(),
  tournamentId: z.string(),
  calculationDate: z.string(),
  teams: z
    .object({
      teamName: z.string(),
      points: z.number(),
      teamId: z.string(),
    })
    .array(),
});

export type PostRocketLeagueLeaderboardRequest = z.infer<typeof PostRocketLeagueLeaderboardRequestSchema>;
