import { Add, Delete, ExpandMore, OpenInNew, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import useFetchTournamentById from "../../../../hooks/useFetchTournamentById";
import { useMemo, useState } from "react";
import { useAddTournamentSponsor } from "../../../../../../api/tournaments/addTournamentSponsor/useAddTournamentSponsor";
import classNames from "classnames";
import { LoadingButton } from "@mui/lab";
import { generateImageUrl } from "../../../../../../helpers/images/generateImageUrl";
import { usePatchTournament } from "../../../../../../api/tournaments/hooks/usePatchTournament";

export const TournamentSponsorsAccordion = ({
  tournamentId,
  sponsorsHeader,
  setSponsorsHeader,
}: {
  tournamentId: string;
  sponsorsHeader: string;
  setSponsorsHeader: (header: string) => void;
}) => {
  const { data: tournament } = useFetchTournamentById(tournamentId);

  const [isAddSponsorModalOpen, setIsAddSponsorModalOpen] = useState(false);
  const [deletingSponsor, setDeletingSponsor] = useState<{ logoKey: string; url: string } | undefined>(undefined);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>
          <strong>Sponsors</strong>{" "}
          <span className="ml-4 text-sm text-white/70">
            {tournament?.metadata?.sponsors?.items.length ?? "None"} assigned
          </span>
        </Typography>
      </AccordionSummary>
      <TableContainer component={AccordionDetails}>
        <div className="flex items-start gap-4">
          <FormControl>
            <TextField
              label="Header text"
              placeholder="This tournament is sponsored by"
              value={sponsorsHeader}
              onChange={(e) => setSponsorsHeader(e.target.value)}
              className="w-full"
            />
            <FormHelperText>
              This text appears above the list of sponsors. It is optional, and can be left blank.
            </FormHelperText>
          </FormControl>
        </div>
        <Divider className="my-4" />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Logo</TableCell>
              <TableCell>Link</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tournament?.metadata?.sponsors?.items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <img
                    className="h-8 object-contain object-center"
                    src={generateImageUrl("tournament-sponsor", item.logoKey, {
                      format: "auto",
                      height: "32",
                    })}
                    alt="logo"
                  />
                </TableCell>
                <TableCell>
                  <Link href={item.url} target="_blank" rel="noreferrer">
                    {item.url} <OpenInNew className="ml-2 size-4" />
                  </Link>
                </TableCell>
                <TableCell>
                  <Button onClick={() => setDeletingSponsor(item)} color="error" variant="outlined">
                    <Delete />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3}>
                <Button startIcon={<Add />} size="small" onClick={() => setIsAddSponsorModalOpen(true)}>
                  Add a Sponsor
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {isAddSponsorModalOpen && (
        <AddSponsorModal
          isOpen={isAddSponsorModalOpen}
          onClose={() => setIsAddSponsorModalOpen(false)}
          tournamentId={tournamentId}
        />
      )}
      {deletingSponsor && (
        <DeleteSponsorModal
          isOpen={!!deletingSponsor}
          onClose={() => setDeletingSponsor(undefined)}
          sponsor={deletingSponsor}
          tournamentId={tournamentId}
        />
      )}
    </Accordion>
  );
};

const DeleteSponsorModal = ({
  isOpen,
  onClose,
  sponsor,
  tournamentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  sponsor: { logoKey: string; url: string };
  tournamentId: string;
}) => {
  const alert = useAlert();
  const { data: tournament } = useFetchTournamentById(tournamentId);
  const { mutate: patchTournament, isLoading: isPatchingTournament } = usePatchTournament(tournamentId);

  const handleDeleteSponsor = () => {
    if (!tournament?.metadata?.sponsors?.items) return; // Nothing to delete

    patchTournament(
      {
        metadata: {
          ...tournament.metadata,
          sponsors: {
            ...tournament.metadata.sponsors,
            items: tournament?.metadata?.sponsors?.items.filter((item) => item.logoKey !== sponsor.logoKey),
          },
        },
      },
      {
        onError: () => {
          alert.showFailureAlert("Failed to delete sponsor");
        },
        onSuccess: () => {
          alert.showSuccessAlert("Sponsor deleted successfully");
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Delete Sponsor?</DialogTitle>
      <DialogContent>
        <img
          className="mb-4 h-12 object-contain object-center"
          src={generateImageUrl("tournament-sponsor", sponsor.logoKey, { format: "auto", height: "48" })}
          alt="logo"
        />
        <DialogContentText>{sponsor.url}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleDeleteSponsor}
          loading={isPatchingTournament}
          startIcon={<Delete />}
          variant="contained"
          loadingPosition="start"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const AddSponsorModal = ({
  isOpen,
  onClose,
  tournamentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  tournamentId: string;
}) => {
  const { mutate: addTournamentSponsor, isLoading: isAddingSponsor } = useAddTournamentSponsor(tournamentId);
  const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
  const imageUrl = useMemo(() => (selectedImage ? URL.createObjectURL(selectedImage) : null), [selectedImage]);
  const [link, setLink] = useState("");
  const alert = useAlert();

  const handleAddSponsor = () => {
    if (!selectedImage || !link) return;

    addTournamentSponsor(
      { imageFile: selectedImage, url: link },
      {
        onError: () => {
          alert.showFailureAlert("Failed to add sponsor");
        },
        onSuccess: () => {
          alert.showSuccessAlert("Sponsor added successfully");
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle>Add a Sponsor</DialogTitle>
      <DialogContent>
        <TextField label="Link" value={link} onChange={(e) => setLink(e.target.value)} fullWidth className="my-4" />
        <div
          className={classNames(
            "group relative mx-auto flex h-40 w-96 items-center justify-center rounded-small border border-white/30",
            {
              "bg-transparent": imageUrl,
              "bg-white/10": !imageUrl,
            },
          )}
        >
          {imageUrl && (
            <img
              src={imageUrl}
              alt="preview"
              className="size-full object-contain transition-all ease-in-out group-hover:opacity-10"
            />
          )}
          <Button
            component="label"
            className={classNames(
              "absolute left-0 top-0 size-full opacity-0 transition-all ease-in-out group-hover:opacity-100",
              {
                "opacity-100": !imageUrl,
              },
            )}
          >
            {imageUrl ? "Replace image" : "Add an image"}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => {
                if (!e.target.files?.length) return;

                setSelectedImage(e.target.files?.[0] ?? null);
              }}
            />
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleAddSponsor}
          loading={isAddingSponsor}
          startIcon={<Save />}
          loadingPosition="start"
          variant="contained"
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
