import { useEffect } from "react";

import ddsClient, {
  Unsubscribe,
  NewAdminMessagesListener,
  DeletedMessageListener,
  RestoredMessageListener,
} from "../../common/DDSClient";

interface DDSEventsOptions {
  roomId?: string;
  newAdminMessagesListener?: NewAdminMessagesListener;
  deletedMessageListener?: DeletedMessageListener;
  restoredMessageListener?: RestoredMessageListener;
  disabled?: boolean;
}

const useDDSEvents = ({
  roomId,
  newAdminMessagesListener,
  deletedMessageListener,
  restoredMessageListener,
  disabled,
}: DDSEventsOptions) => {
  useEffect(() => {
    let sessionStateChangeListener: null | Unsubscribe = null;
    let eventsListener: null | Unsubscribe = null;

    if (disabled) {
      return;
    }

    const subscribeToEvents = () => {
      if (!roomId) {
        return;
      }

      eventsListener = ddsClient.subscribe({
        roomId,
        newAdminMessagesListener,
        deletedMessageListener,
        restoredMessageListener,
      });
    };

    if (!ddsClient.sessionOpened) {
      sessionStateChangeListener = ddsClient.onSessionStateChange(() => {
        sessionStateChangeListener?.unsubscribe();
        sessionStateChangeListener = null;
        subscribeToEvents();
      });
    } else {
      subscribeToEvents();
    }

    return () => {
      sessionStateChangeListener?.unsubscribe();
      eventsListener?.unsubscribe();
    };
  }, [
    roomId,
    newAdminMessagesListener,
    deletedMessageListener,
    restoredMessageListener,
    disabled,
  ]);
};

export default useDDSEvents;
