import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createMatchTeam, createMatch, matchAutoProgress } from "../../../../api/tournaments";
import { EditMatch, Match } from "../../../../api/tournaments/schemas/matches";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

export default function useCreateMatch({
  tournamentId,
  onSuccess,
  onError,
}: {
  tournamentId?: string;
  onSuccess: () => void;
  onError: () => void;
}) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["match"],
    mutationFn: async ({ gameId, match }: { gameId?: string; match?: EditMatch }): Promise<Match | undefined> => {
      let matchResponse: Match | undefined = undefined;
      if (match) {
        const teamA = match.teams.find((team) => team?.teamIndex === 10);
        const teamB = match.teams.find((team) => team?.teamIndex === 20);
        try {
          matchResponse = await createMatch({
            name: match.matchName,
            index: match.matchIndex,
            scheduledAt: match.scheduledAt.toISOString(),
            type: match.matchType,
            stageId: match.stageId,
            externalId: match.externalId,
            metadata: match.metadata,
          });
        } catch (error) {
          alert.showFailureAlert("Error while creating match data");
          throw error;
        }

        //handle team updated
        for (const team of [teamA, teamB]) {
          try {
            if (team && team.team && matchResponse) {
              await createMatchTeam({
                matchId: matchResponse.id,
                teamId: team.team.id,
                score: team.score || 0,
                index: team.teamIndex,
              });
            }
          } catch (error) {
            alert.showFailureAlert("Error while adding team data, check console for more info");
            throw error;
          }
        }

        try {
          await matchAutoProgress({ gameId, matchId: matchResponse.id });
        } catch (error) {
          alert.showInfoAlert("Error while auto progressing match, check console for more info");
          // eslint-disable-next-line no-console
          console.log(error);
        }

        //refetch matche data with updated data
        await queryClient.invalidateQueries(["matches", tournamentId]);
        await queryClient.refetchQueries(["matches", tournamentId]);
      }
      return matchResponse;
    },
    onSuccess,
    onError,
  });
}
