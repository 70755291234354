import classNames from "classnames";

import { Button } from "@/components/ui/button";
import { useState } from "react";
import PageContainer from "../../components/Layout/PageContainer";
import { CreateStreamDialog } from "./components/CreateStreamDialog";
import StreamsTable from "./components/StreamsTable";
import useFetchAwsStreams from "./hooks/useFetchStreams";
import { RefreshCcw } from "lucide-react";

export default function StreamsPage() {
  const { data: streams, refetch, isRefetching } = useFetchAwsStreams();
  const [open, setOpen] = useState(false);

  return (
    <PageContainer>
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <h1 className="text-heading">Streams</h1>
          <Button variant="outline" onClick={() => void refetch()}>
            <RefreshCcw
              className={classNames({
                "animate-spin": isRefetching,
              })}
            />
            Refresh
          </Button>
        </div>
        <Button onClick={() => setOpen(true)}>Create stream</Button>
        <CreateStreamDialog open={open} onClose={() => setOpen(false)} onStreamCreated={() => void refetch()} />
      </div>
      <StreamsTable streams={streams ?? []} isLoading={isRefetching} refetch={() => void refetch()} />
    </PageContainer>
  );
}
