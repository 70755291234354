import { TrashIcon } from "@heroicons/react/24/outline";

interface SelectEmptyProps {
  onRemove?: () => void;
  showRemove?: boolean;
}

export function SelectEmpty({ onRemove, showRemove }: SelectEmptyProps) {
  return (
    <div className="w-full h-14 rounded flex justify-between items-center cursor-pointer">
      {showRemove && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onRemove?.();
          }}
          className="border flex hover:bg-red-700 items-center hover:bg-red border-dashed rounded place-content-center group border-red px-3 h-full"
        >
          <TrashIcon className="h-5 w-5 group-hover:text-canvas-100" />
        </div>
      )}
    </div>
  );
}
