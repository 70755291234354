import {
  Breadcrumbs,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PageContainer from "../../components/Layout/PageContainer";
import { Close, Refresh } from "@mui/icons-material";
import useFetchScheduledNotifications from "./hooks/useFetchScheduledNotifications";
import { DateTime } from "luxon";
import useFetchDeliveredNotifications from "./hooks/useFetchDeliveredNotifications";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import useSendNotification from "./hooks/useSendNotification";
import useScheduleNotification from "./hooks/useScheduleNotification";
import { useState } from "react";
import useAlert from "../../providers/AlertProvider/hooks/useAlert";
import { NotificationType } from "../../api/notifications/schemas";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import classNames from "classnames";
import useDeleteScheduledNotification from "./hooks/useDeleteScheduledNotification";
import { Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const Notifications = () => {
  const [isConfirmSendNotification, setIsConfirmSendNotification] =
    useState<boolean>(false);

  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [type, setType] = useState<NotificationType>("DEFAULT");
  const [scheduledAt, setScheduledAt] = useState<DateTime>(DateTime.now());

  // Fantasy Only
  const [fantasySeason, setFantasySeason] = useState<string>("");
  const [fantasyEvent, setFantasyEvent] = useState<string>("");

  const {
    data: scheduledNotifications,
    refetch: refetchScheduledNotifications,
    isFetching: isScheduledNotificationFetching,
  } = useFetchScheduledNotifications();
  const {
    data: deliveredNotifications,
    refetch: refetchDeliveredNotifications,
    isFetching: isDeliveredNotificationFetching,
  } = useFetchDeliveredNotifications();

  const { mutate: sendNotification } = useSendNotification();
  const { mutate: scheduleNotification } = useScheduleNotification();
  const { mutate: deleteScheduledNotification } =
    useDeleteScheduledNotification();

  const alert = useAlert();

  const resetFields = () => {
    setTitle("");
    setBody("");
    setType("DEFAULT");
    setScheduledAt(DateTime.now());
  };

  const clickSendNotification = () => {
    if (!title || !body || !type) {
      alert.showFailureAlert("Please fill out title, body and type");
      return;
    }

    sendNotification(
      {
        title,
        body,
        type,
        metadata:
          type === "FANTASY" && fantasySeason && fantasyEvent
            ? {
                onlyNotifyNoTeam: true,
                seasonId: fantasySeason,
                eventId: fantasyEvent,
              }
            : undefined,
      },
      {
        onError: () => alert.showFailureAlert("Failed to send notification"),
        onSuccess: () => {
          alert.showSuccessAlert("Successfully sent notification.");
          resetFields();
          setIsConfirmSendNotification(false);
        },
      },
    );
  };

  const clickScheduleNotification = () => {
    if (!title || !body || !type || !scheduledAt) {
      alert.showFailureAlert("Please fill out title, body, type & date");
      return;
    }

    scheduleNotification(
      {
        title,
        body,
        type,
        date: scheduledAt.toUTC().toISO()!,
      },
      {
        onError: () =>
          alert.showFailureAlert("Failed to schedule notification"),
        onSuccess: () => {
          alert.showSuccessAlert("Successfully scheduled notification.");
          resetFields();
        },
      },
    );
  };

  const clickDeleteScheduledNotification = (
    type: NotificationType,
    id: string,
  ) => {
    deleteScheduledNotification(
      {
        type,
        id,
      },
      {
        onError: () =>
          alert.showFailureAlert("Failed to delete scheduled notification"),
        onSuccess: () => {
          alert.showSuccessAlert(
            "Successfully deleted scheduled notification.",
          );
          refetchScheduledNotifications().catch(() =>
            alert.showFailureAlert(
              "Failed to refetch scheduled notifications after deletion",
            ),
          );
        },
      },
    );
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <PageContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">Home</Link>
            <span>Notifications</span>
            <span>Mobile</span>
          </Breadcrumbs>
          <div className="flex flex-row gap-4 my-4">
            <div className="flex flex-col w-full gap-4">
              <Typography variant="h4">Compose Notification:</Typography>
              <div className="flex flex-row gap-4">
                <div className="flex flex-col gap-4 w-full">
                  <TextField
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    required
                  />
                  <TextField
                    label="Body"
                    value={body}
                    onChange={(e) => setBody(e.currentTarget.value)}
                    multiline
                    rows={3}
                    required
                  />
                </div>
                <div className="flex flex-col gap-4 w-full">
                  <FormControl fullWidth>
                    <InputLabel id="type-select-label">Type *</InputLabel>
                    <Select
                      labelId="type-select-label"
                      label="Type"
                      value={type}
                      onChange={(e) =>
                        setType(e.target.value as NotificationType)
                      }
                      required
                    >
                      <MenuItem value={"BROADCAST-STARTED"}>
                        Broadcast Started
                      </MenuItem>
                      <MenuItem value={"MATCH-STARTED"}>Match Started</MenuItem>
                      <MenuItem value={"FANTASY"}>Fantasy</MenuItem>
                      <MenuItem value={"PICKEMS"}>Pickems</MenuItem>
                      <MenuItem value={"NEWS"}>News</MenuItem>
                      <MenuItem value={"DEFAULT"}>Global</MenuItem>
                    </Select>
                  </FormControl>

                  {type === "FANTASY" && (
                    <div className="flex flex-row gap-4">
                      <TextField
                        label="Fantasy Season"
                        value={fantasySeason}
                        onChange={(e) =>
                          setFantasySeason(e.currentTarget.value)
                        }
                      />
                      <TextField
                        label="Fantasy Event"
                        value={fantasyEvent}
                        onChange={(e) => setFantasyEvent(e.currentTarget.value)}
                      />
                    </div>
                  )}

                  <DateTimePicker
                    label="Scheduled Date (Used ONLY when scheduling)"
                    ampm={false}
                    format="dd/LL/yyyy HH:mm"
                    value={scheduledAt}
                    onChange={(e) => setScheduledAt(e as DateTime)}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <Button
                  className="w-full"
                  variant="contained"
                  onClick={() => setIsConfirmSendNotification(true)}
                >
                  Send (immediately)
                </Button>
                <Button
                  className="w-full"
                  variant="contained"
                  onClick={clickScheduleNotification}
                >
                  Schedule
                </Button>
              </div>
            </div>
            <div className="w-full" />
          </div>
          <div className="flex flex-row gap-4">
            <div className="flex flex-col w-full">
              <Typography variant="h5">Scheduled Notifications:</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Body</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell align="right" width={36} height={36}>
                        <Button
                          onClick={() => {
                            void refetchScheduledNotifications();
                          }}
                        >
                          <Refresh
                            className={classNames({
                              "animate-spin": isScheduledNotificationFetching,
                            })}
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scheduledNotifications
                      .sort(
                        (a, b) =>
                          new Date(b.date).getTime() -
                          new Date(a.date).getTime(),
                      )
                      .map((scheduledNotification, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>{scheduledNotification.type}</TableCell>
                            <TableCell>{scheduledNotification.id}</TableCell>
                            <TableCell>{scheduledNotification.title}</TableCell>
                            <TableCell>{scheduledNotification.body}</TableCell>
                            <TableCell>
                              {scheduledNotification.status}
                            </TableCell>
                            <TableCell>
                              {DateTime.fromISO(
                                scheduledNotification.date,
                              ).toRelative()}
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={() => {
                                  const { type, id } = scheduledNotification;
                                  void clickDeleteScheduledNotification(
                                    type,
                                    id,
                                  );
                                }}
                              >
                                <Close color="error" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="flex flex-col w-full">
              <Typography variant="h5">Recent Notifications:</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Body</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell align="right" width={36} height={36}>
                        <Button
                          onClick={() => {
                            void refetchDeliveredNotifications();
                          }}
                        >
                          <Refresh
                            className={classNames({
                              "animate-spin": isDeliveredNotificationFetching,
                            })}
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deliveredNotifications
                      .sort(
                        (a, b) =>
                          new Date(b.date).getTime() -
                          new Date(a.date).getTime(),
                      )
                      .map((deliveredNotification, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>{deliveredNotification.type}</TableCell>
                            <TableCell>{deliveredNotification.id}</TableCell>
                            <TableCell>{deliveredNotification.title}</TableCell>
                            <TableCell>{deliveredNotification.body}</TableCell>
                            <TableCell>
                              {deliveredNotification.status}
                            </TableCell>
                            <TableCell colSpan={2}>
                              {DateTime.fromISO(
                                deliveredNotification.date,
                              ).toRelative()}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </PageContainer>
      </LocalizationProvider>
      {isConfirmSendNotification && (
        <ConfirmationModal
          title="Send Notification?"
          description="Are you sure that you want to send this notification immediately?"
          isOpen={isConfirmSendNotification}
          onConfirm={clickSendNotification}
          onClose={() => setIsConfirmSendNotification(false)}
        />
      )}
    </>
  );
};

export default Notifications;
