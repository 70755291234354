import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useEffect, useState } from "react";

interface PreviewItemProps {
  item: { file: File; tags: string[] };
  itemChange: ({ file, tags }: { file: File; tags: string[] }) => void;
}
export const PreviewItem = ({ item, itemChange }: PreviewItemProps) => {
  const [tagInput, setTagInput] = useState<string>("");
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!item.file) return;

    const objectUrl = URL.createObjectURL(item.file);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [item]);

  return (
    <div className="flex w-full flex-row items-center gap-4">
      <div className="size-20 min-h-20 min-w-20 rounded-small border border-foreground">
        {preview ? (
          <img src={preview} className="size-full object-contain" />
        ) : (
          <div className="size-20 rounded-small">No Preview</div>
        )}
      </div>
      <div className="flex w-full flex-col gap-2">
        <div className="flex w-full flex-col gap-2">
          <div className="flex w-full flex-row items-center gap-2">
            <Label htmlFor="tags">Tags</Label>
            <div className="flex w-full flex-row gap-1">
              <Input id="tags" type="text" value={tagInput} onChange={(e) => setTagInput(e.currentTarget.value)} />
              <Button
                disabled={!tagInput}
                onClick={() => {
                  itemChange({ file: item.file, tags: [...item.tags, tagInput] });
                  setTagInput("");
                }}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="flex flex-row gap-1">
            {item.tags.map((tag) => (
              <Badge
                className="hover:cursor-pointer"
                key={tag}
                onClick={() => {
                  itemChange({
                    file: item.file,
                    tags: item.tags.filter((item) => item !== tag),
                  });
                }}
              >
                {tag}
              </Badge>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
