import { z } from "zod";

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
export type Json = Literal | { [key: string]: Json } | Json[];
export const JsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(JsonSchema), z.record(JsonSchema)]),
);
export const gameIdsSchema = z.enum(["dota", "cs", "rl"]);
export const IssueSchema = z.object({
  errorKey: z.string(),
  gameId: gameIdsSchema,
  tournamentId: z.string(),
  entity: z.string(),
  entityId: z.string(),
  entityExternalId: z.string().nullish(),
  body: JsonSchema,
  description: z.string().optional(),
  createdAt: z.string().default(new Date().toISOString()),
  resolvedAt: z.string().optional(),
  resolved: z.boolean(),
});

export type Issue = z.infer<typeof IssueSchema>;
