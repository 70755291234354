import { blastApiClient } from "../../clients/blastApiClient";
import { z } from "zod";
export const RocketLeagueTeamSchema = z.object({
  teamName: z.string(),
  teamDisplayName: z.string().nullable(),
});

export const getRocketLeagueStatTeams = async () => {
  const { data } = await blastApiClient.get<unknown>("/v1/rlcs-statistics/teams");

  const teams = RocketLeagueTeamSchema.array().parse(data);
  return teams;
};
