import { useMutation } from "@tanstack/react-query";
import { updatePlayerRole } from "../../../../api/tournaments";

export default function useUpdatePlayerRole({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) {
  return useMutation({
    mutationKey: ["player update role"],
    mutationFn: ({
      playerId,
      fromDate,
      role,
      teamId,
    }: {
      playerId: string;
      fromDate: Date;
      role: string | null;
      teamId: string | null;
    }) =>
      updatePlayerRole({
        playerId,
        fromDate,
        role,
        teamId: teamId === "null" || teamId === "" ? null : teamId,
      }),
    onSuccess,
    onError,
  });
}
