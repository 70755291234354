import { z } from "zod";

export const BroadcastGameIdsSchema = z.enum(["cs", "dota"]);

export const BroadcastSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().optional(),
  slug: z.string(),
  live: z.boolean(),
  priority: z.number(),
  chatId: z.string(),
  videoId: z.string(),
  videoSrc: z.string(),
  videoAlternativeSrc: z.string().optional(),
  videoOffset: z.number(),
  videoStartTime: z.string().optional(),
  createdAt: z.string(),
  seriesIds: z.array(z.string()).optional(),
  gameIds: z
    .array(BroadcastGameIdsSchema)
    .default(["cs"])
    .transform((gameIds) => (gameIds?.length ? gameIds : ["cs"])),
});

export type Broadcast = z.infer<typeof BroadcastSchema>;

export interface AdditionalVideo {
  name: string;
  videoId: string;
  videoSrc: string;
  offset: number;
}
