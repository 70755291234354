import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ScheduleRequest } from "../../../api/csguessr/types";
import { createSchedule } from "../../../api/csguessr";

export const useCreateSchedule = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (props: ScheduleRequest) => {
      await createSchedule(props);
      await queryClient.invalidateQueries(["csguessr-schedules"]);
      await queryClient.refetchQueries(["csguessr-schedules"]);
      onSuccess?.();
    },
    {
      onSuccess,
      onError,
    }
  );
};
