import { useMemo } from "react";
import useFetchAvatars from "../useFetchAvatars";

const useFetchAvatar = (id?: string) => {
  const { data: avatars } = useFetchAvatars();

  return useMemo(() => {
    return avatars?.find((avatar) => avatar.id === id);
  }, [avatars, id]);
};

export default useFetchAvatar;
