import { Toaster } from "@/components/ui/toaster";
import { useToast } from "@/hooks/use-toast";
import { createContext, ReactNode, useCallback } from "react";

interface AlertProviderValue {
  showSuccessAlert: (message: string) => void;
  showFailureAlert: (message: string) => void;
  showInfoAlert: (message: string) => void;
}

export const AlertContext = createContext<AlertProviderValue | undefined>(undefined);

const AlertProvider = ({ children }: { children: ReactNode }) => {
  const { toast } = useToast();

  const showSuccessAlert = useCallback(
    (message: string) => {
      toast({
        title: "Success",
        description: message,
        variant: "default",
      });
    },
    [toast],
  );

  const showFailureAlert = useCallback(
    (message: string) => {
      toast({
        title: "Error",
        description: message,
        variant: "destructive",
      });
    },
    [toast],
  );

  const showInfoAlert = useCallback(
    (message: string) => {
      toast({
        title: "Info",
        description: message,
      });
    },
    [toast],
  );

  const alertProviderValue: AlertProviderValue = {
    showSuccessAlert,
    showFailureAlert,
    showInfoAlert,
  };

  return (
    <AlertContext.Provider value={alertProviderValue}>
      {children}
      <Toaster />
    </AlertContext.Provider>
  );
};

export default AlertProvider;
