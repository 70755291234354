import { useMutation } from "@tanstack/react-query";

import { startStream } from "../../../../api/streams";

const useStartStream = (id: string) =>
  useMutation({
    mutationKey: ["streams", "start", id],
    mutationFn: () => startStream(id),
  });

export default useStartStream;
