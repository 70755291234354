import { MultiSelect } from "@/components/multi-select";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useState } from "react";
import { deleteUserAvatar, postUserAvatar } from "../../../../api/users";
import UserProfile from "../../../../types/users";
import Avatar from "../../../../types/users/avatars";
import { useQueryClient } from "@tanstack/react-query";
import { userQueryKeys } from "@/providers/AuthProvider";

export default function EditAvatarForm({
  userDetails,
  avatars,
  onClose,
  open,
}: {
  userDetails: UserProfile;
  avatars?: Avatar[];
  onClose: (args?: { refresh?: boolean }) => void;
  open: boolean;
}) {
  const [avatarsSelected, setAvatarsSelected] = useState<string[]>(userDetails.avatars ?? []);

  const queryClient = useQueryClient();
  const updateUserAvatars = async () => {
    const previousAvatars = userDetails.avatars ?? [];
    const avatarsToRemove: string[] = [];
    const avatarsToAdd: string[] = [];

    for (const avatar of avatarsSelected) {
      if (!previousAvatars.includes(avatar)) {
        avatarsToAdd.push(avatar);
      }
    }

    for (const avatar of previousAvatars) {
      if (!avatarsSelected.includes(avatar)) {
        avatarsToRemove.push(avatar);
      }
    }

    for (const avatarID of avatarsToRemove) {
      await deleteUserAvatar(userDetails.id, avatarID);
    }

    for (const avatarID of avatarsToAdd) {
      await postUserAvatar(userDetails.id, avatarID);
    }

    await queryClient.invalidateQueries({
      queryKey: userQueryKeys.user(userDetails.id),
    });

    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit user avatars</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <MultiSelect
            value={avatarsSelected}
            onValueChange={(value) => setAvatarsSelected(value)}
            options={
              avatars?.map((avatar) => ({
                label: avatar.name,
                value: avatar.id,
                image: avatar.url,
                hidden: avatar.hidden,
              })) ?? []
            }
            maxCount={999}
          />
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button onClick={() => void updateUserAvatars()}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
