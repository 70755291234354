import { useQuery } from "@tanstack/react-query";
import { getHighEndPurchases } from "../../../../api/points";

export function useGetHighEndProducts({ delivered }: { delivered: boolean }) {
  return useQuery({
    queryKey: ["highEndProducts", delivered],
    queryFn: () => getHighEndPurchases(delivered),
    // sort by createdAt descending
    select: ({ purchases }) => ({
      purchases: purchases.sort((a, b) => b.purchaseDate.localeCompare(a.purchaseDate)),
    }),
  });
}
