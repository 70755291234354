import * as cookies from "es-cookie";
import { postLogin, PostLoginPayload, postLogout } from "../../../../api/auth";
import { updateTokensRequest } from "../../../../api/authInterceptor";

interface AccessTokenJson {
  exp: string;
  username: string;
  "cognito:groups"?: Array<string>;
}

export default class AuthService {
  get userId() {
    return this.accessTokenJson?.username;
  }

  get userRoles() {
    return this.accessTokenJson?.["cognito:groups"];
  }

  public async login(payload: PostLoginPayload) {
    return postLogin(payload);
  }

  public logout = () => postLogout();

  public updateSession() {
    return updateTokensRequest();
  }

  get isAuthenticated() {
    return this.hasAccessToken;
  }

  get hasAccessToken() {
    return !!this.accessTokenFromCookie;
  }

  get hasRefreshToken() {
    return !!this.refreshTokenFromCookie;
  }

  private get accessTokenJson(): AccessTokenJson | undefined {
    const accessToken = this.accessTokenFromCookie;
    if (accessToken === undefined) {
      return undefined;
    }

    const payload = JSON.parse(
      window.atob(decodeURIComponent(accessToken.split(".")[1])),
    ) as AccessTokenJson | undefined;
    return payload;
  }

  private get accessTokenFromCookie() {
    const accessToken = cookies.get("access_token");
    return accessToken;
  }

  private get refreshTokenFromCookie() {
    return cookies.get("refresh_token");
  }
}
