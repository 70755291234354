import { useQuery } from "@tanstack/react-query";
import { getTeams } from "../../../../api/tournaments";

export default function useFetchTeams(game?: string) {
  return useQuery({
    queryKey: ["team", game],
    queryFn: () => {
      return getTeams(game);
    },
    staleTime: 1000 * 3,
    enabled: true,
    retry: false,
  });
}
