import { useQuery } from "@tanstack/react-query";

import { getRules } from "../../../api/moderation";

const useFetchModerationRules = () => {
  return useQuery({
    queryKey: ["moderationRules"],
    queryFn: getRules,
    initialData: [],
  });
};

export default useFetchModerationRules;
