import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useGetEventsList } from "@/api/metricsViewership/hooks/useGetEventsList";
import { useNavigate } from "react-router-dom";

export const EventsTable = () => {
  const { data: events, isError } = useGetEventsList();

  const navigate = useNavigate();

  return (
    <Table>
      <TableHeader>
        <TableRow className="text-xs">
          <TableHead className="w-36">Status</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Game</TableHead>
          <TableHead>Twitch Game ID</TableHead>
          <TableHead>Starts At</TableHead>
          <TableHead>Ends At</TableHead>
          <TableHead>Tracking Channels</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isError && (
          <TableRow>
            <TableCell colSpan={6}>Something went wrong when fetching data.</TableCell>
          </TableRow>
        )}

        {events
          ?.sort((a, b) => DateTime.fromISO(b.endDate).toMillis() - DateTime.fromISO(a.endDate).toMillis())
          .map((event, i) => {
            const isUpcoming = DateTime.utc() < DateTime.fromISO(event.startDate);
            const isEnded = DateTime.utc() > DateTime.fromISO(event.endDate);

            return (
              <TableRow
                key={i}
                className={classNames({
                  "cursor-pointer transition-all duration-150 ease-in-out hover:bg-white/10": !isError,
                  "bg-green-800 bg-opacity-20 hover:bg-green-300/10": !isUpcoming && !isEnded,
                })}
                onClick={() => navigate(`/metrics/viewership/events/${event.id}`)}
              >
                <TableCell
                  className={classNames("uppercase", {
                    "text-green-500": isUpcoming,
                    "text-gray-500": isEnded,
                    "text-sm font-bold text-green-500": !isUpcoming && !isEnded,
                  })}
                >
                  {isUpcoming ? "Upcoming" : isEnded ? "Ended" : "Ongoing"}
                </TableCell>
                <TableCell>{event.name}</TableCell>
                <TableCell>{event.gameName}</TableCell>
                <TableCell>{!event.twitchGameId ? "No game assigned" : event.twitchGameId}</TableCell>
                <TableCell>
                  {DateTime.fromISO(event.startDate).toUTC().toLocaleString(DateTime.DATETIME_FULL)}
                </TableCell>
                <TableCell>{DateTime.fromISO(event.endDate).toUTC().toLocaleString(DateTime.DATETIME_FULL)}</TableCell>
                <TableCell>{event.channels.length} Channels</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};
