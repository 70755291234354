import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";

import { Broadcast, BroadcastGameIdsSchema } from "../../../../types/Broadcasts";
import { grey } from "@mui/material/colors";
import notEmpty from "../../../../shared/helpers/notEmpty";
import useFetchMatch from "../hooks/useFetchMatch";
import { generateImageUrl } from "../../../../helpers/images/generateImageUrl";
import useUpdateBroadcast from "../hooks/useUpdateBroadcast";
import { usePatron } from "../../../../providers/PatronProvider/hooks/usePatron";
import { AddMatchDialog } from "./AddMatchDialog";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { Stream } from "../../../../types/Streams";

interface BroadcastInfoProps {
  broadcast: Broadcast;
  isAdditionalDataVisible?: boolean;
  stream?: Stream;
}

const BroadcastInfo: FC<BroadcastInfoProps> = ({ broadcast, isAdditionalDataVisible = false, stream }) => {
  const navigate = useNavigate();
  const { id: broadcastId } = useParams();
  const { patron } = usePatron();
  const { showSuccessAlert, showFailureAlert } = useAlert();

  // Form data
  const [formTitle, setFormTitle] = useState<string>("");
  const [formDescription, setFormDescription] = useState<string>("");
  const [formSlug, setFormSlug] = useState<string>("");
  const [formVideoAlternativeSrc, setFormVideoAlternativeSrc] = useState<string>("");
  const [formMatchIds, setFormMatchIds] = useState<string[]>([]);
  const [formGameIds, setFormGameIds] = useState<string[]>([]);
  const [isWarningTooltipOpened, setIsWarningTooltipOpened] = useState(false);

  // Dialogs
  const [addMatchDialogOpened, setAddMatchDialogOpened] = useState(false);

  // Matches data
  const matchQueryResults = useFetchMatch(formMatchIds || []);
  const isLoadingMatch = matchQueryResults.some((result) => result.isLoading);

  const match = useMemo(() => {
    if (isLoadingMatch) return [];
    return matchQueryResults.map((result) => result.data).filter(notEmpty);
  }, [isLoadingMatch, matchQueryResults]);

  const openMatch = (matchId: string) => {
    const selectedMatch = getMatch(matchId);
    if (!selectedMatch) return;
    // Open match backoffice URL in new tab
    const url = `/to/tournaments/${selectedMatch.circuit.gameId}/${selectedMatch.tournament.id}#${matchId}`;
    navigate(url, { replace: false });
  };

  const getMatch = (matchId: string) => {
    return match.find((match) => match.id === matchId);
  };

  // Actions
  const {
    mutate: mutateUpdateBroadcast,
    isLoading: updateBroadcastLoading,
    data: updatedBroadcast,
    error: updateBroadcastError,
  } = useUpdateBroadcast({ patron, id: broadcastId as string });

  const updateBroadcast = useCallback(
    (data: Broadcast) => {
      return mutateUpdateBroadcast(data);
    },
    [mutateUpdateBroadcast],
  );

  const onAddMatchClose = (matchId: string | undefined) => {
    setAddMatchDialogOpened(false);
    if (!matchId) {
      return;
    }
    setFormMatchIds([...formMatchIds, matchId]);
  };

  // use effects
  useEffect(() => {
    if (broadcast) {
      setFormTitle(broadcast.title);
      setFormDescription(broadcast.description || "");
      setFormMatchIds(broadcast.seriesIds || []);
      setFormSlug(broadcast.slug);
      setFormVideoAlternativeSrc(broadcast.videoAlternativeSrc || "");
      setFormGameIds(broadcast.gameIds || []);
    }
  }, [broadcast]);

  useEffect(() => {
    if (!updateBroadcastLoading && updatedBroadcast) {
      showSuccessAlert("Broadcast details updated successfully.");
    }
  }, [updateBroadcastLoading, updatedBroadcast, showSuccessAlert]);

  useEffect(() => {
    if (!updateBroadcastLoading && updateBroadcastError) {
      showFailureAlert("Failed to update broadcast.");
    }
  }, [updateBroadcastLoading, updateBroadcastError, showFailureAlert]);

  const isBroadcastPublished =
    !stream || (broadcast.videoOffset === stream?.timelineOffset && broadcast.videoStartTime === stream?.startTime);

  useEffect(() => {
    setIsWarningTooltipOpened(!isBroadcastPublished);
  }, [isBroadcastPublished]);

  const handleGamesChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setFormGameIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <>
      <Grid item xs={6}>
        <Card variant="outlined" className={!isBroadcastPublished ? "border-red-700" : undefined}>
          <CardContent>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { margin: ".75rem 0" },
              }}
            >
              <Typography variant="h5" marginBottom="1rem">
                Broadcast Details
              </Typography>
              <TextField
                label="Title"
                placeholder="Broadcast title"
                variant="outlined"
                value={formTitle}
                onChange={(event) => setFormTitle(event.target.value)}
                fullWidth
              />
              <TextField
                label="Slug"
                placeholder="Broadcast slug"
                variant="outlined"
                value={formSlug}
                onChange={(event) => setFormSlug(event.target.value)}
                fullWidth
              />
              <TextField
                label="Description"
                placeholder="Broadcast description"
                variant="outlined"
                value={formDescription}
                onChange={(event) => setFormDescription(event.target.value)}
                fullWidth
              />
              <Box
                sx={{
                  border: "1px solid grey",
                  borderRadius: "4px",
                  padding: "0.25rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box display="flex" flexDirection="row" alignItems="center" paddingLeft="10px">
                  <Typography variant="subtitle2" color="lightgray" marginRight="0.5rem">
                    Match Assigned
                  </Typography>
                  <IconButton onClick={() => setAddMatchDialogOpened(true)}>
                    <AddIcon />
                  </IconButton>
                </Box>
                {!formMatchIds || formMatchIds.length === 0 ? (
                  <Typography variant="subtitle2" color={grey[400]} paddingLeft="10px">
                    Theres no match assigned to this broadcast.
                  </Typography>
                ) : (
                  <Box display="flex" justifyContent="space-between" flexDirection="column" sx={{ padding: "0 1rem" }}>
                    {formMatchIds?.map((matchId) => {
                      const foundMatch = getMatch(matchId);
                      return (
                        <Box key={matchId} display="flex" marginBottom="0.5rem">
                          <div className="flex w-full flex-row items-center rounded border border-solid border-gray-500 p-2">
                            {match.find((match) => match.id === matchId) ? (
                              <>
                                <IconButton onClick={() => openMatch(matchId)}>
                                  <LaunchIcon />
                                </IconButton>
                                <Typography variant="subtitle1">
                                  {match.find((match) => match.id === matchId)?.name}
                                </Typography>
                                <div className="ml-4 flex flex-row items-center gap-2">
                                  {foundMatch?.teamA ? (
                                    <img
                                      className="size-8 object-contain"
                                      src={generateImageUrl("teams", foundMatch.teamA.id, {
                                        width: "32",
                                        format: "auto",
                                      })}
                                    />
                                  ) : (
                                    "TBA"
                                  )}
                                  <Typography variant="subtitle2">VS</Typography>
                                  {foundMatch?.teamB ? (
                                    <img
                                      className="size-8 object-contain"
                                      src={generateImageUrl("teams", foundMatch.teamB.id, {
                                        width: "32",
                                        format: "auto",
                                      })}
                                    />
                                  ) : (
                                    "TBA"
                                  )}
                                </div>
                              </>
                            ) : (
                              <Skeleton className="size-full" />
                            )}
                          </div>
                          <IconButton
                            onClick={() => {
                              setFormMatchIds(formMatchIds.filter((id) => id !== matchId));
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
              {isAdditionalDataVisible && (
                <>
                  <TextField
                    label="Chat ID"
                    placeholder="Broadcast Chat ID"
                    variant="outlined"
                    defaultValue={broadcast.chatId}
                    disabled
                    fullWidth
                  />
                  <TextField
                    label="Video ID"
                    placeholder="Broadcast Video ID"
                    variant="outlined"
                    defaultValue={broadcast.videoId}
                    disabled
                    fullWidth
                  />
                  <TextField
                    label="Video Offset"
                    placeholder="Broadcast Video Offset"
                    variant="outlined"
                    defaultValue={`${broadcast.videoOffset} seconds`}
                    disabled
                    fullWidth
                  />
                  <TextField
                    label="Video Src"
                    variant="outlined"
                    disabled
                    value={broadcast.videoSrc || "n/a"}
                    fullWidth
                  />
                  <TextField
                    label="Video Start Time"
                    variant="outlined"
                    defaultValue={
                      broadcast.videoStartTime
                        ? DateTime.fromISO(broadcast.videoStartTime).toFormat("dd/MM/yyyy HH:mm")
                        : "n/a"
                    }
                    disabled
                    fullWidth
                  />
                </>
              )}
              <TextField
                label="Alternative Video Src"
                placeholder="Alternative Video Src"
                variant="outlined"
                value={formVideoAlternativeSrc}
                onChange={(event) => setFormVideoAlternativeSrc(event.target.value)}
                fullWidth
              />
              <FormControl fullWidth className="mb-4">
                <InputLabel>Games</InputLabel>
                <Select
                  multiple
                  fullWidth
                  value={formGameIds}
                  onChange={handleGamesChange}
                  input={<OutlinedInput label="Games" />}
                  renderValue={(selected) => {
                    return (
                      <div className="flex flex-row items-center gap-2">
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </div>
                    );
                  }}
                >
                  {BroadcastGameIdsSchema.options.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={formGameIds.includes(name)} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select the games that you want this broadcast to be visible on.</FormHelperText>
              </FormControl>
              <Tooltip
                title="Broadcast and video stream are out of sync. Please publish changes to syncronize them"
                placement="top"
                arrow
                open={isWarningTooltipOpened}
                onClose={() => {
                  setIsWarningTooltipOpened(false);
                }}
              >
                <Button
                  onClick={() => {
                    const newBroadcastDetails: Broadcast = {
                      ...broadcast,
                      title: formTitle || broadcast.title,
                      slug: formSlug || broadcast.slug,
                      description: formDescription || broadcast.description,
                      seriesIds: formMatchIds.map((id) => id.trim()).filter((id) => id.length > 0),
                      videoAlternativeSrc: formVideoAlternativeSrc,
                      gameIds: formGameIds,
                    };
                    updateBroadcast(newBroadcastDetails);
                  }}
                  disabled={updateBroadcastLoading}
                  type="button"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Publish
                </Button>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {addMatchDialogOpened && (
        <AddMatchDialog
          key="add-match-dialog"
          open={addMatchDialogOpened}
          closeDialog={(matchId) => onAddMatchClose(matchId)}
        />
      )}
    </>
  );
};

export default BroadcastInfo;
