import { useQuery } from "@tanstack/react-query";
import { getMultipleUsers } from "../api/users";

interface useGetMultipleUsersProps {
  ids: string[];
}

export function useGetMultipleUsers({ ids }: useGetMultipleUsersProps) {
  return useQuery({
    queryKey: ["multipleUsers", ids],
    queryFn: () => getMultipleUsers(ids),
    enabled: ids.length > 0,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
