import { useMutation } from "@tanstack/react-query";
import { createTeam } from "../../../../api/tournaments";
import { Team } from "../../../../api/tournaments/schemas/teams";

export default function useCreateTeam({
  onSuccess,
  onError,
}: {
  onSuccess: (data: Team) => void;
  onError: () => void;
}) {
  return useMutation({
    mutationKey: ["team"],
    mutationFn: ({
      name,
      shortName,
      nationality,
      gameId,
    }: {
      name: string;
      shortName: string;
      nationality: string;
      gameId: string;
    }) => createTeam({ name, shortName, nationality, gameId }),
    onSuccess,
    onError,
  });
}
