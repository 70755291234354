import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import useAlert from "../../../../../../../../../providers/AlertProvider/hooks/useAlert";
import { baseApiURL, environment } from "../../../../../../../../../config";
import { CopyAll } from "@mui/icons-material";

interface LinkAccordionProps {
  type: "timeline" | "statistics";
  matchId: string;
  numberOfMaps: number;
}

export const LinkAccordion = ({
  type,
  matchId,
  numberOfMaps,
}: LinkAccordionProps) => {
  const alert = useAlert();

  const copyLinks = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => alert.showSuccessAlert("Copied!"))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("Failed to copy to clipboard:", err);
        alert.showFailureAlert("Failed to copy text.");
      });
  };

  const generateDataUrl = (
    type: "timeline" | "statistics",
    matchId: string,
    mapNumber: number,
  ) => {
    const timelineHost = {
      development: "https://timeline-api.blast-dev.tv",
      production: "https://timeline-api.blast.tv",
    };
    const timelineUrl = `${
      timelineHost[environment === "production" ? "production" : "development"]
    }/v2/serverlog/${matchId}/maps/${mapNumber}`;
    const statisticsUrl = `${baseApiURL}/v1/ingest/cs/serverlogs/matches/${matchId}/maps/${mapNumber}`;

    switch (type) {
      case "timeline":
        return timelineUrl;
      case "statistics":
        return statisticsUrl;
      default:
        throw new Error("Unknown data url type, for generating data urls");
    }
  };

  return (
    <Accordion className="my-2 px-2 w-full mx-0 max-w-[1500px]">
      <AccordionSummary>
        <div className="flex flex-row items-center justify-between w-full">
          <Typography>
            {type.charAt(0).toUpperCase() + type.slice(1)} URLs
          </Typography>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              const timelineUrls = Array.from({ length: numberOfMaps }).map(
                (_, i) => generateDataUrl(type, matchId, i + 1),
              );
              copyLinks(timelineUrls.join("\n"));
            }}
          >
            <CopyAll />
          </Button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col gap-5">
          {Array.from({ length: numberOfMaps }).map((_, i) => (
            <TextField
              key={i}
              label={`Map ${i + 1}`}
              value={generateDataUrl(type, matchId, i + 1)}
              contentEditable={false}
              fullWidth={true}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
