import { blastApiClient } from "@/api/clients/blastApiClient";
import { Schedule, ScheduleSchema } from "../types/schedule";

export const createRlcsSchedule = async (
  payload: Omit<Schedule, "id" | "createdAt" | "updatedAt">,
): Promise<Schedule> => {
  const { data } = await blastApiClient.post<unknown>("/v1/rlcs-statistics/schedules", payload);

  return ScheduleSchema.parse(data);
};
