import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { PirateStreamStatus, Stream, StreamSchema } from "./schemas";

const videosApi = axios.create({
  baseURL: `${baseApiURL}/v1/pirate-streams`,
});

videosApi.interceptors.request.use(authInterceptor);

export async function getStreamsByStatus({ status }: { status: PirateStreamStatus }) {
  const { data } = await videosApi.get<unknown>(`/streams/${status}`);
  return StreamSchema.array().parse(data);
}

export async function updateStreamStatus({ stream, status }: { stream: Stream; status: PirateStreamStatus }) {
  await videosApi.put(`/streams`, {
    ...stream,
    status,
  });
}
