import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { MonitoringSNSEvent, MonitoringSNSEventSchema } from "./schemas";

const apiClient = axios.create({
  baseURL: baseApiURL,
});
apiClient.interceptors.request.use(authInterceptor);

export interface GetEventsOptions {
  topicName: string;
  startTimestamp?: string;
  endTimestamp?: string;
  limit?: number;
  page?: string;
}
interface GetEventsResult {
  events: MonitoringSNSEvent[];
  pagination: {
    currentPage: string;
    nextPage: string;
  };
}
export const getEvents = async ({
  topicName,
  startTimestamp,
  endTimestamp,
  limit,
  page,
}: GetEventsOptions): Promise<GetEventsResult> => {
  const { data } = await apiClient.get<GetEventsResult>(`/v1/monitoring/sns/${topicName}`, {
    params: {
      startTimestamp,
      endTimestamp,
      limit,
      page,
    },
  });

  const events = MonitoringSNSEventSchema.array().parse(data.events);
  return {
    events,
    pagination: data.pagination,
  };
};
