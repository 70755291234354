import { Route, Routes } from "react-router-dom";
import BroadcastCreateForm from "./BroadcastCreate";
import BroadcastDetails from "./BroadcastDetails";
import BroadcastsList from "./BroadcastsList";

export default function Broadcasts() {
  return (
    <Routes>
      <Route path="/" element={<BroadcastsList />} />
      <Route path=":patron/:id" element={<BroadcastDetails />} />
      <Route path="create" element={<BroadcastCreateForm />} />
    </Routes>
  );
}
