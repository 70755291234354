import { Autocomplete, Box, Button, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import PageContainer from "../../../components/Layout/PageContainer";
import useFetchTeams from "../hooks/teams/useFetchTeams";
import { useEffect, useRef, useState } from "react";
import { Team } from "../../../api/tournaments/schemas/teams";
import useFetchTeamPlayers from "../hooks/teams/useFetchTeamPlayers";
import { CreateTeamModal } from "./components/CreateTeamModal";
import { assetsURL } from "../../../config";
import { MuiFileInput } from "mui-file-input";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";
import useUpdateTeam from "../hooks/teams/useUpdateTeam";
import useDeleteTeam from "../hooks/teams/useDeleteTeam";
import countries, { Country } from "../../../common/countries";
import { updateTeamLogo } from "./helpers/updateTeamLogo";
import { PlayerList } from "../Players/components/PlayerList";
import { CountryPicker } from "../shared/components/CountryPicker";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "../../../components/ConfirmModal";

export const TeamsPage = () => {
  const { gameId, team } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [isDeleteTeamConfirmationModalOpen, setIsDeleteTeamConfirmationModalOpen] = useState(false);
  const teamInputRef = useRef<HTMLInputElement>(null);
  const autoCompleteRef = useRef<HTMLSelectElement>(null);

  // Form updates
  const [teamName, setTeamName] = useState<string>();
  const [teamShortName, setTeamShortName] = useState<string>();
  const [teamNationality, setTeamNationality] = useState<string>();
  const [twitterUrl, setTwitterUrl] = useState<string>();
  const [discordUrl, setDiscordUrl] = useState<string>();
  const [websiteUrl, setWebsiteUrl] = useState<string>();
  const [externalId, setExternalId] = useState<string>();
  const [image, setImage] = useState<File | null>(null);

  const { data: teams, isFetching: isFetchingTeams, refetch: refetchTeams } = useFetchTeams(gameId);
  const { data: teamPlayers } = useFetchTeamPlayers(selectedTeam?.id);
  const { data: updatedTeam, mutate: updateTeam } = useUpdateTeam({
    onSuccess: () => alert.showSuccessAlert("Successfully updated team"),
    onError: () => alert.showFailureAlert("Error while updating team data"),
  });
  const { mutate: deleteTeam } = useDeleteTeam({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully delete team");
      setIsDeleteTeamConfirmationModalOpen(false);
      setSelectedTeam(undefined);
      navigate(`/to/teams`, { replace: true });
    },
    onError: () => alert.showFailureAlert("Failed to delete team"),
  });

  useEffect(() => {
    if (teams && team) {
      const currentSelectedTeam = teams.find((tempTeam) => tempTeam.id === team);

      setSelectedTeam(currentSelectedTeam);
      return;
    }
    if (!team) {
      setSelectedTeam(undefined);
    }
  }, [team, teams]);

  useEffect(() => {
    if (!selectedTeam) {
      return;
    }

    setTeamName(selectedTeam.name);
    setTeamShortName(selectedTeam.shortName);
    setTwitterUrl(selectedTeam.socialLinks.twitter?.url);
    setDiscordUrl(selectedTeam.socialLinks.discord?.url);
    setWebsiteUrl(selectedTeam.socialLinks.website?.url);
    setExternalId(selectedTeam?.externalId ?? "");
    const nationalityCountry = countries.find((country) => country.code === selectedTeam.nationality);
    if (nationalityCountry) {
      setTeamNationality(nationalityCountry.code);
    }
  }, [selectedTeam]);

  // Only focus search field when no team is specified and teams has loaded
  useEffect(() => {
    if (teams && !team) document.getElementById("teamSelectionInput")?.focus();
  }, [teams, team]);

  // Hook to update team with properties
  useEffect(() => {
    if (!updatedTeam) return;

    setTeamName(updatedTeam.name);
    setTeamShortName(updatedTeam.shortName);
    const nationalityCountry = countries.find((country) => country.code === updatedTeam.nationality);
    if (nationalityCountry) {
      setTeamNationality(nationalityCountry.code);
    }
  }, [updatedTeam]);

  const getSocialLinks = () => {
    let socialLinks: undefined | Team["socialLinks"] = undefined;

    if (twitterUrl) {
      socialLinks = {
        ...(socialLinks ?? {}),
        twitter: {
          url: twitterUrl,
        },
      };
    }
    if (discordUrl) {
      socialLinks = {
        ...(socialLinks ?? {}),
        discord: {
          url: discordUrl,
        },
      };
    }
    if (websiteUrl) {
      socialLinks = {
        ...(socialLinks ?? {}),
        website: {
          url: websiteUrl,
        },
      };
    }

    return socialLinks;
  };

  if (!isFetchingTeams && !teams) {
    return (
      <PageContainer>
        <h3>Could not fetch teams from API</h3>
      </PageContainer>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <PageContainer>
        <Grid container alignItems={"center"} spacing={5}>
          <Grid item>
            <Autocomplete
              ref={autoCompleteRef}
              id="teamSelectionInput"
              disablePortal
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={
                teams
                  ? teams
                      .map((team) => {
                        return { id: team.id, label: team.name };
                      })
                      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
                  : []
              }
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Team" inputRef={teamInputRef} />}
              onChange={(_, value) => {
                const currentSelectedTeam = teams?.find((tempTeam) => tempTeam.id === value?.id);
                navigate(`/to/teams/${gameId}/${currentSelectedTeam?.id || ""}`, {
                  replace: true,
                });
                if (teamInputRef.current) {
                  teamInputRef.current.blur();
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => setIsCreateTeamModalOpen(true)}>
              Create Team
            </Button>
          </Grid>
        </Grid>

        {selectedTeam && (
          <>
            <Divider />
            <Box>
              <h1>Team Details:</h1>

              <Grid container spacing={5} flexDirection={"row"}>
                <Grid item>
                  <Stack spacing={2} sx={{ width: 350, mt: 1 }}>
                    <TextField label="Id" variant="standard" disabled value={selectedTeam.id} />

                    <TextField
                      label="Name"
                      variant="standard"
                      value={teamName || selectedTeam.name}
                      onChange={(e) => setTeamName(e.currentTarget.value)}
                    />

                    <TextField
                      label="Short Name"
                      variant="standard"
                      value={teamShortName || selectedTeam.shortName}
                      onChange={(e) => setTeamShortName(e.currentTarget.value)}
                    />

                    <CountryPicker
                      labelText="Nationality"
                      countries={countries}
                      defaultValue={selectedTeam.nationality as Country["code"]}
                      onCountrySelection={(selectedCountryCode) => setTeamNationality(selectedCountryCode)}
                    />

                    <TextField label="Game Id" variant="standard" disabled value={selectedTeam.gameId} />

                    <Grid container justifyContent={"space-between"}>
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={() => {
                            updateTeam({
                              teamId: selectedTeam.id,
                              name: teamName,
                              shortName: teamShortName,
                              nationality: teamNationality,
                              socialLinks: getSocialLinks(),
                              externalId: externalId,
                            });
                          }}
                        >
                          Save Team
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" onClick={() => setIsDeleteTeamConfirmationModalOpen(true)}>
                          Delete Team
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={2} sx={{ width: 350, mt: 1 }}>
                    <TextField
                      label="Twitter"
                      variant="standard"
                      placeholder="Twitter URL"
                      value={twitterUrl || selectedTeam.socialLinks.twitter?.url || ""}
                      onChange={(e) => setTwitterUrl(e.currentTarget.value)}
                    />
                    <TextField
                      label="Discord"
                      variant="standard"
                      placeholder="Discord URL"
                      value={discordUrl || selectedTeam.socialLinks.discord?.url || ""}
                      onChange={(e) => setDiscordUrl(e.currentTarget.value)}
                    />
                    <TextField
                      label="Website"
                      variant="standard"
                      placeholder="Website URL"
                      value={websiteUrl || selectedTeam.socialLinks.website?.url || ""}
                      onChange={(e) => setWebsiteUrl(e.currentTarget.value)}
                    />
                    <TextField
                      label="External ID"
                      variant="standard"
                      placeholder="External ID"
                      value={externalId}
                      onChange={(e) => setExternalId(e.currentTarget.value)}
                    />
                  </Stack>
                </Grid>
                <Grid item>
                  <Grid container flexDirection={"column"} spacing={2.5}>
                    <Grid item>
                      <img
                        height={234}
                        width={234}
                        src={`${assetsURL}/images/teams/${
                          selectedTeam.id
                        }?height=234&width=auto&format=auto&cache_busting=${Date.now()}`}
                      />
                    </Grid>
                    <Grid item>
                      <MuiFileInput
                        placeholder="Select team logo"
                        value={image}
                        onChange={(image) => {
                          if (!image || !selectedTeam) return;
                          setImage(image);

                          void updateTeamLogo({
                            image,
                            teamId: selectedTeam.id,
                            onSuccess: () => {
                              alert.showSuccessAlert("Successfully updated team logo");
                              setImage(null);
                            },
                            onError: (message) => alert.showFailureAlert(message),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <p>You may need to reload page to see new image.</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {selectedTeam && teamPlayers && (
          <>
            <Divider />
            <PlayerList players={teamPlayers} hideSearch={true} teamId={selectedTeam.id} gameId={selectedTeam.gameId} />
          </>
        )}
      </PageContainer>
      {selectedTeam && (
        <ConfirmModal
          open={isDeleteTeamConfirmationModalOpen}
          onOpenChange={() => setIsDeleteTeamConfirmationModalOpen(false)}
          onSubmit={() => {
            deleteTeam({ teamId: selectedTeam.id });
            void refetchTeams();
          }}
        >
          <Typography>Are you sure you want to delete {selectedTeam.name}?</Typography>
        </ConfirmModal>
      )}
      <CreateTeamModal
        createTeamModalIsOpen={isCreateTeamModalOpen}
        handleClose={() => {
          setIsCreateTeamModalOpen(false);
          void refetchTeams();
        }}
      />
    </div>
  );
};
