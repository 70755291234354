import { useQuery } from "@tanstack/react-query";
import { getMatchResults } from "../../../../api/fantasy";

const useFetchMatchResults = ({
  seasonId,
  eventId,
  enabled,
}: {
  seasonId?: string;
  eventId?: string;
  enabled: boolean;
}) => {
  return useQuery(
    ["matchResults", seasonId, eventId],
    () => (seasonId && eventId ? getMatchResults({ seasonId, eventId }) : undefined),
    {
      retry: true, //infinitely retry failing requests
      enabled,
    },
  );
};

export default useFetchMatchResults;
