import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import useDeleteMap from "../../../../../../../hooks/maps/useDeleteMap";

interface DeleteMapConfirmModalProps {
  isOpen: boolean;
  close: () => void;
  mapId: string;
  matchId?: string;
}

export const DeleteMapConfirmModal = ({
  isOpen,
  close,
  mapId,
  matchId,
}: DeleteMapConfirmModalProps) => {
  const { mutate: deleteMap } = useDeleteMap({
    matchId,
  });

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: "600px",
          padding: "10px",
        },
      }}
      onClose={close}
    >
      <DialogContent>
        <Typography>Are you sure you want to delete this Map?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            deleteMap({ mapId });
            close();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
