import { useEffect, useMemo, useState } from "react";
import useGetClipsCuratorEvents from "../hooks/useGetClipsCuratorEvents";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  ListSubheaderProps,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import useFetchPlayers from "../../Tournaments/Players/hooks/useFetchPlayers";
import useFetchTeams from "../../Tournaments/hooks/teams/useFetchTeams";
import { Player } from "../../../api/tournaments/schemas/players";
import { ClipCuratorEvent, ClipCuratorEventWithMatchDetails } from "../../../api/video/schemas";
import { Team } from "../../../api/tournaments/schemas/teams";
import useFetchMatchesByTournamentId from "../../Tournaments/hooks/matches/useFetchMatchesByTournamentId";
import { MatchByTournamentIdResponse } from "../../../api/tournaments/schemas/matches";
import { ClipGrid } from "./ClipGrid";
import useFetchCircuits from "../../Tournaments/hooks/useFetchCircuits";
import { Tournament } from "../../../api/tournaments/schemas/tournaments";
import { events } from "../../Broadcasts/TimelineEvents/data/events";

interface TournamentTabProps {
  addClip: (clip: ClipCuratorEventWithMatchDetails) => void;
  removeClip: (clip: ClipCuratorEventWithMatchDetails) => void;
  selectedClips: ClipCuratorEventWithMatchDetails[];
  tournaments: Tournament[];
  primaryFilter: string;
}

function MyListSubheader(props: ListSubheaderProps) {
  return <ListSubheader {...props} />;
}

MyListSubheader.muiSkipListHighlight = true;

export const ClipSearch = ({ addClip, removeClip, selectedClips, tournaments, primaryFilter }: TournamentTabProps) => {
  // Component states
  const [selectedTournamentId, setSelectedTournamentId] = useState<string>("");
  const [selectedMatchId, setSelectedMatchId] = useState<string>("");
  const [selectedMapNumber, setSelectedMapNumber] = useState<string>("");
  const [selectedRoundNumber, setSelectedRoundNumber] = useState<string>("");
  const [selectedTeamId, setSelectedTeamId] = useState<string>("");
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>("");
  const [selectedEventType, setSelectedEventType] = useState<string>("");

  const primaryFilterId = useMemo(() => {
    switch (primaryFilter) {
      case "TOURNAMENT":
        return selectedTournamentId;
      case "TIMELINE_EVENT":
        return selectedEventType;
      case "PLAYER":
        return selectedPlayerId;
      case "TEAM":
        return selectedTeamId;
      default:
        return selectedTournamentId;
    }
  }, [primaryFilter, selectedEventType, selectedPlayerId, selectedTeamId, selectedTournamentId]);

  useEffect(() => {
    setSelectedTournamentId("");
    setSelectedMatchId("");
    setSelectedMapNumber("");
    setSelectedRoundNumber("");
    setSelectedTeamId("");
    setSelectedPlayerId("");
    setSelectedEventType("");
  }, [primaryFilter]);

  // Data Querying
  const { data: circuits } = useFetchCircuits();
  const { data: tournamentMatches } = useFetchMatchesByTournamentId(
    selectedTournamentId !== "" ? selectedTournamentId : undefined,
  );
  const { data: players } = useFetchPlayers();
  const { data: teams } = useFetchTeams();
  const { data: videoClipEvents } = useGetClipsCuratorEvents({ category: primaryFilter, id: primaryFilterId });

  const tournamentsInClips = useMemo(() => {
    let filteredTournaments = tournaments;
    if (!tournaments) return [];
    if (primaryFilter !== "TOURNAMENT") {
      const videoClipEventsTournaments = new Set(
        videoClipEvents.map((videoClipEvent) => videoClipEvent.tournamentId).flat(),
      );
      filteredTournaments = tournaments.filter((t) => videoClipEventsTournaments.has(t.id));
    }

    return filteredTournaments.reduce((acc: Record<string, typeof tournaments>, tournament) => {
      if (acc[tournament.circuitId]) {
        acc[tournament.circuitId].push(tournament);
      } else {
        acc[tournament.circuitId] = [tournament];
      }
      return acc;
    }, {});
  }, [primaryFilter, tournaments, videoClipEvents]);

  const matchesInClips: MatchByTournamentIdResponse[] = useMemo(() => {
    if (!tournamentMatches) return [];
    const videoClipEventsMatches: Set<string> = new Set(
      videoClipEvents.map((videoClipEvent) => videoClipEvent.matchId),
    );

    return tournamentMatches.filter((match) => videoClipEventsMatches.has(match.matchId));
  }, [tournamentMatches, videoClipEvents]);

  const mapsInClips: number[] = useMemo(() => {
    const videoClipEventsMapNumbers: Set<number> = new Set(
      videoClipEvents.map((videoClipEvent) => videoClipEvent.mapNumber),
    );
    return [...videoClipEventsMapNumbers];
  }, [videoClipEvents]);

  const roundNumbersInClips: number[] = useMemo(() => {
    const videoClipEventsRoundNumbers: Set<number> = new Set(
      videoClipEvents.map((videoClipEvent) => videoClipEvent.roundNumber),
    );
    return [...videoClipEventsRoundNumbers];
  }, [videoClipEvents]);

  const playersInClips: Player[] = useMemo(() => {
    if (!players) return [];
    if (primaryFilter === "PLAYER") {
      return players?.toSorted((a, b) =>
        a.nickname.toLowerCase() < b.nickname.toLowerCase()
          ? -1
          : a.nickname.toLowerCase() > b.nickname.toLowerCase()
            ? 1
            : 0,
      );
    }
    const videoClipEventsPlayers: Set<string> = new Set(
      videoClipEvents.map((videoClipEvent) => videoClipEvent.playerIds).flat(),
    );

    return players
      .filter((player) => videoClipEventsPlayers.has(player.id))
      .toSorted((a, b) =>
        a.nickname.toLowerCase() < b.nickname.toLowerCase()
          ? -1
          : a.nickname.toLowerCase() > b.nickname.toLowerCase()
            ? 1
            : 0,
      );
  }, [players, primaryFilter, videoClipEvents]);

  const teamsInClips: Team[] = useMemo(() => {
    if (!teams) return [];
    if (primaryFilter === "TEAM") {
      return teams.toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
    }
    const videoClipEventsTeams: Set<string> = new Set(
      videoClipEvents.map((videoClipEvent) => videoClipEvent.teamIds).flat(),
    );

    return teams
      .filter((team) => videoClipEventsTeams.has(team.id))
      .toSorted((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  }, [primaryFilter, teams, videoClipEvents]);

  // Filtering events based on additional filters
  const videoClipEventsFiltered: ClipCuratorEvent[] = useMemo(() => {
    let filteredEvents: ClipCuratorEvent[] = videoClipEvents;

    if (selectedTournamentId) {
      filteredEvents = filteredEvents.filter(
        (videoClipEvents) => videoClipEvents.tournamentId === selectedTournamentId,
      );
    }

    if (selectedMatchId) {
      filteredEvents = filteredEvents.filter((videoClipEvent) => videoClipEvent.matchId === selectedMatchId);
    }
    if (selectedMapNumber) {
      filteredEvents = filteredEvents.filter(
        (videoClipEvent) => videoClipEvent.mapNumber === parseInt(selectedMapNumber),
      );
    }
    if (selectedRoundNumber) {
      filteredEvents = filteredEvents.filter(
        (videoClipEvent) => videoClipEvent.roundNumber === parseInt(selectedRoundNumber),
      );
    }
    if (selectedPlayerId) {
      filteredEvents = filteredEvents.filter((videoClipEvent) => videoClipEvent.playerIds.includes(selectedPlayerId));
    }
    if (selectedTeamId) {
      filteredEvents = filteredEvents.filter((videoClipEvent) => videoClipEvent.teamIds.includes(selectedTeamId));
    }
    if (selectedEventType) {
      filteredEvents = filteredEvents.filter((videoClipEvent) => videoClipEvent.eventType === selectedEventType);
    }

    return filteredEvents;
  }, [
    videoClipEvents,
    selectedTournamentId,
    selectedMatchId,
    selectedMapNumber,
    selectedRoundNumber,
    selectedPlayerId,
    selectedTeamId,
    selectedEventType,
  ]);

  const TournamentFilter = () => (
    <FormControl sx={{ width: 250 }}>
      <InputLabel id="tournament-select">Tournament</InputLabel>
      <Select
        labelId="tournament-select"
        label="Tournament"
        value={selectedTournamentId}
        onChange={(e) => setSelectedTournamentId(e.target.value)}
      >
        <MenuItem key={`event-type-0`} value={""}>
          None
        </MenuItem>
        {tournamentsInClips &&
          Object.entries(tournamentsInClips).map(([circuitId, tournaments]) => {
            const selectedItems = [];
            selectedItems.push(
              <MyListSubheader key={circuitId} className="cursor-default bg-inherit">
                {circuits?.find((c) => c.id === circuitId)?.name}
              </MyListSubheader>,
            );
            return selectedItems.concat(
              tournaments.map((tournament) => (
                <MenuItem key={tournament.id} value={tournament.id} className="cursor-pointer">
                  {tournament.name}
                </MenuItem>
              )),
            );
          })}
      </Select>
    </FormControl>
  );

  const EventTypeFilter = () => (
    <FormControl sx={{ width: 250 }}>
      <InputLabel id="event-type-select">Event Type</InputLabel>
      <Select
        labelId="event-type-select"
        label="Event Type"
        value={selectedEventType}
        onChange={(e) => setSelectedEventType(e.target.value)}
      >
        <MenuItem key={`event-type-0`} value={""}>
          None
        </MenuItem>
        {events?.map((event, index) => (
          <MenuItem key={`event-type-${index + 1}`} value={event.id}>
            {event.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const PlayerFilter = () => (
    <FormControl sx={{ width: 250 }}>
      <InputLabel id="player-select">Player</InputLabel>
      <Select
        labelId="player-select"
        label="Player"
        value={selectedPlayerId}
        onChange={(e) => setSelectedPlayerId(e.target.value)}
      >
        <MenuItem key={`player-0`} value={""}>
          None
        </MenuItem>
        {playersInClips.map((player, index) => (
          <MenuItem key={`player-${index + 1}`} value={player.id}>
            {player.nickname}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const TeamFilter = () => (
    <FormControl sx={{ width: 250 }}>
      <InputLabel id="team-select">Team</InputLabel>
      <Select
        labelId="team-select"
        label="Team"
        value={selectedTeamId}
        onChange={(e) => setSelectedTeamId(e.target.value)}
      >
        <MenuItem key={`team-0`} value={""}>
          None
        </MenuItem>
        {teamsInClips.map((team, index) => (
          <MenuItem key={`team-${index}`} value={team.id}>
            {team.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const filterSelectors: Map<string, JSX.Element> = new Map([
    ["TOURNAMENT", <TournamentFilter key="tournamentFilter" />],
    ["TIMELINE_EVENT", <EventTypeFilter key="eventFilter" />],
    ["PLAYER", <PlayerFilter key="playerFilter" />],
    ["TEAM", <TeamFilter key="teamFilter" />],
  ]);

  return (
    <Box sx={{ pt: 2 }}>
      <Typography sx={{ pb: 2 }}>Required Filter:</Typography>
      {filterSelectors.get(primaryFilter)}
      <Typography sx={{ py: 2 }}>Additional Filters:</Typography>
      <Grid container gap={2}>
        {primaryFilter !== "TOURNAMENT" && <Grid item>{filterSelectors.get("TOURNAMENT")}</Grid>}
        <Grid item>
          {
            <FormControl sx={{ width: 250 }}>
              <InputLabel id="match-select">Match</InputLabel>
              <Select
                labelId="match-select"
                label="Match"
                value={selectedMatchId}
                onChange={(e) => setSelectedMatchId(e.target.value)}
              >
                <MenuItem key={`match-0`} value={""}>
                  None
                </MenuItem>
                {matchesInClips.map((match, index) => (
                  <MenuItem key={`match-${index + 1}`} value={match.matchId}>
                    {match.matchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </Grid>
        <Grid item>
          <FormControl sx={{ width: 250 }}>
            <InputLabel id="map-number-select">Map Number</InputLabel>
            <Select
              labelId="map-number-select"
              label="Map Number"
              value={selectedMapNumber}
              onChange={(e) => setSelectedMapNumber(e.target.value)}
            >
              <MenuItem key={`map-number-0`} value={""}>
                None
              </MenuItem>
              {mapsInClips.sort().map((mapNumber, index) => (
                <MenuItem key={`map-number-${index + 1}`} value={mapNumber}>
                  {mapNumber}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: 250 }}>
            <InputLabel id="round-number-select">Round Number</InputLabel>
            <Select
              labelId="round-number-select"
              label="Round Number"
              value={selectedRoundNumber}
              onChange={(e) => setSelectedRoundNumber(e.target.value)}
            >
              <MenuItem key={`round-number-0`} value={""}>
                None
              </MenuItem>
              {roundNumbersInClips
                .sort((a, b) => a - b)
                .map((roundNumber, index) => (
                  <MenuItem key={`round-number-${index + 1}`} value={roundNumber}>
                    {roundNumber}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {primaryFilter !== "PLAYER" && <Grid item>{filterSelectors.get("PLAYER")}</Grid>}
        {primaryFilter !== "TEAM" && <Grid item>{filterSelectors.get("TEAM")}</Grid>}
        {primaryFilter !== "TIMELINE_EVENT" && <Grid item>{filterSelectors.get("TIMELINE_EVENT")}</Grid>}
      </Grid>
      <br />
      {/* Clips Grid */}
      <ClipGrid
        addClip={addClip}
        removeClip={removeClip}
        selectedClips={selectedClips}
        videoClips={videoClipEventsFiltered}
        players={players || []}
        tournaments={tournaments}
      />
    </Box>
  );
};
