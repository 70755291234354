import { useQuery } from "@tanstack/react-query";
import { getRoles } from "../../../../api/auth";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

const useFetchRoles = () => {
  const { showFailureAlert } = useAlert();

  return useQuery({
    queryKey: ["userRoles"],
    queryFn: getRoles,
    onError() {
      showFailureAlert("Something went wrong while fetching the roles");
    },
    staleTime: 60_000,
  });
};

export default useFetchRoles;
