import { z } from "zod";

export const CounterStrikleAnswerPlayerSchema = z.object({
  id: z.string(),
  nickname: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  nationality: z.string(),
  team: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullish(),
});

export type CounterStrikleAnswerPlayer = z.infer<typeof CounterStrikleAnswerPlayerSchema>;
