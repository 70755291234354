import { useQuery } from "@tanstack/react-query";
import { getPickems } from "../../../sanity/Pickems";

export default function useFetchPickemsQuestions(pickemsId?: string) {
  return useQuery(
    ["pickems", pickemsId],
    () => (pickemsId ? getPickems(pickemsId) : undefined),
    {
      enabled: !!pickemsId,
    }
  );
}
