import { useQuery } from "@tanstack/react-query";
import { getChannelList, queryKeys } from "..";

export const useGetChannelsList = () => {
  return useQuery({
    queryKey: queryKeys.channelList(),
    queryFn: getChannelList,
    // sort channels by name
    select: (data) =>
      data.sort((a, b) => {
        // replace first @ if it exists
        const aName = a.channelName.replace(/^@/, "");
        const bName = b.channelName.replace(/^@/, "");
        return aName.localeCompare(bName);
      }),
  });
};
