export function QuickRound() {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70145 9.59276L17.51 0L14.2222 7.11519L22.0135 8.0159L3.77394 21.302L12.3763 10.403L6.70145 9.59276ZM3.77391 19.5911L2.51292 13.6011L1.34158 15.8977L3.77391 19.5911ZM0.116804 18.9667L3.12082 20.4103L0 20.0402L0.116804 18.9667ZM3.12091 21.6195L0.819371 22.5564L1.46262 23.3559L3.12091 21.6195ZM4.349 24L4.56369 22.1657L5.14936 23.8824L4.349 24ZM5.99737 21.6573L7.55778 23.0039L8.27917 21.8334L5.99737 21.6573ZM14.7091 17.519L6.34111 20.7566L13.6364 19.216L14.7091 17.519Z"
        fill="#FFFE3E"
      />
    </svg>
  );
}
