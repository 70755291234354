import { Dialog, DialogTitle, Divider, DialogContent, DialogActions, Grid, TextField, Button } from "@mui/material";
import countries, { Country } from "../../../../common/countries";
import { MuiFileInput } from "mui-file-input";
import { useState, useEffect } from "react";
import { CountryPicker } from "../../shared/components/CountryPicker";
import useCreatePlayer from "../../hooks/players/useCreatePlayer";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { uploadPlayerImage } from "../helpers/uploadPlayerImage";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DateTime } from "luxon";
import { GamePicker } from "../../shared/components/GamePicker";

interface CreatePlayerModalProps {
  createPlayerModalIsOpen: boolean;
  handleClose: () => void;
}

export const CreatePlayerModal = ({ createPlayerModalIsOpen, handleClose }: CreatePlayerModalProps) => {
  const alert = useAlert();

  const [ingameId, setIngameId] = useState<string>();
  const [nickname, setNickname] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [nationality, setNationality] = useState<Country["code"]>("EU");
  const [dateOfBirth, setDateOfBirth] = useState<string>();
  const [gameId, setGameId] = useState<string>();
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>();

  const { mutate: createPlayer } = useCreatePlayer({
    onError: () => alert.showFailureAlert("Failed to create player in database."),
    onSuccess: (player) => {
      if (!player) return;
      alert.showSuccessAlert("Successfully created the player.");
      if (image) {
        uploadPlayerImage({ image, playerId: player.id })
          .then(() => {
            alert.showSuccessAlert("Player image uploaded Successfully");
            setImage(null);
            setImagePreview(undefined);
          })
          .catch(() => {
            alert.showFailureAlert("Failed to upload player image. But the player has been created in the database.");
          });
      }

      setIngameId(undefined);
      setNickname(undefined);
      setFirstName(undefined);
      setLastName(undefined);
      setNationality("EU");
      setDateOfBirth(undefined);
      setGameId(undefined);
      handleClose();
    },
  });

  useEffect(() => {
    if (!image) {
      setImagePreview(undefined);
      return;
    }
    setImagePreview(URL.createObjectURL(image));
  }, [image]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog open={createPlayerModalIsOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Create Player</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container direction="row" spacing={1}>
            <Grid item sm={8}>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <TextField
                    variant="standard"
                    size="small"
                    label="Ingame ID"
                    fullWidth
                    value={ingameId || ""}
                    onChange={(e) => setIngameId(e.target.value)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    variant="standard"
                    size="small"
                    label="Nickname"
                    fullWidth
                    value={nickname || ""}
                    onChange={(e) => setNickname(e.target.value)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    variant="standard"
                    size="small"
                    label="First Name"
                    fullWidth
                    value={firstName || ""}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    variant="standard"
                    size="small"
                    label="Last Name"
                    fullWidth
                    value={lastName || ""}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item sm={6}>
                  <CountryPicker
                    labelText="Nationality"
                    countries={countries}
                    defaultValue={"EU" as Country["code"]}
                    onCountrySelection={(selectedCountryCode) => setNationality(selectedCountryCode as Country["code"])}
                  />
                </Grid>
                <Grid item sm={6}>
                  <DateField
                    variant="standard"
                    label="Date of Birth"
                    format="dd/LL/yyyy"
                    fullWidth
                    value={dateOfBirth ? DateTime.fromISO(dateOfBirth) : undefined}
                    onChange={(e) => {
                      if (e) {
                        setDateOfBirth(e.toISO()!);
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <GamePicker labelText="Game" onGameSelection={(gameId) => setGameId(gameId)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Grid container justifyContent="center">
                <Grid item>
                  <img
                    src={imagePreview}
                    height={150}
                    width={100}
                    alt="Player Image"
                    onLoad={() => {
                      if (imagePreview) {
                        URL.revokeObjectURL(imagePreview);
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <MuiFileInput
                    placeholder="Select player image"
                    value={image}
                    onChange={(newFile) => setImage(newFile)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!nickname || !firstName || !lastName || !nationality || !dateOfBirth || !gameId) return;
              handleClose();
              createPlayer({
                ingameId: ingameId || null,
                nickname,
                firstName,
                lastName,
                nationality: nationality as string,
                dateOfBirth: new Date(dateOfBirth),
                gameId,
              });
            }}
          >
            Create Player
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
