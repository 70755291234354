import axios from "axios";
import { createUploadLink } from "../../../../api/assets";

export const uploadTeamLogo = async ({
  image,
  teamId,
}: {
  image: File;
  teamId: string;
}): Promise<boolean> => {
  const fileName = `${teamId}`;
  let uploadUrl = "";
  try {
    const { uploadUrl: createdUploadUrl } = await createUploadLink(
      "teams",
      fileName
    );
    uploadUrl = createdUploadUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error create team logo upload link: ", error);
    return false;
  }

  try {
    await axios.put(uploadUrl, image, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Length": image.size,
        "Content-Type": image.type,
      },
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error uploading team logo: ", error);
    return false;
  }
};
