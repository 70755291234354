import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import {
  completeUpload,
  createUpload,
  startVideoEncoding,
} from "../../api/video";
import PageContainer from "../../components/Layout/PageContainer";
import CollapsableTable from "./components/CollapsableTable";
import { LinearProgressWithLabel } from "./components/LinearProgressBarWithLabel";
import { formatFileName } from "./helpers/formatFileName";
import axios from "axios";

export default function ContentPage() {
  const fileInput = useRef<HTMLInputElement>(null);

  // Alerts
  const [errorAlertOpened, setErrorAlertOpened] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [successAlertOpened, setSuccessAlertOpened] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState("");

  // States
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Form Inputs
  const [series, setSeries] = useState("");
  const [episode, setEpisode] = useState("");
  const [isTrailer, setIsTrailer] = useState(false);
  const [videoFile, setVideoFile] = useState<FileList | null>(null);

  /* cSize should be byte 1024*1 = 1KB */
  const createChunks = (file: File, cSize: number): Blob[] => {
    let startPointer = 0;
    const endPointer = file.size;
    const chunks: Blob[] = [];
    while (startPointer < endPointer) {
      const newStartPointer = startPointer + cSize;
      chunks.push(file.slice(startPointer, newStartPointer));
      startPointer = newStartPointer;
    }
    return chunks;
  };

  const uploadFile = async () => {
    if (series === "") {
      setErrorAlertMessage("Please fill out the series form.");
      setErrorAlertOpened(true);
      return;
    }

    if (videoFile === null) {
      setErrorAlertMessage("Please provide a video file.");
      setErrorAlertOpened(true);
      return;
    }

    if (episode === "" && isTrailer === false) {
      setErrorAlertMessage("Either select isTrailer or fill out episode.");
      setErrorAlertOpened(true);
      return;
    }
    if (!fileInput.current) throw new Error("Couldn't get file input");

    const file = videoFile[0];
    const blob = file.slice(0, file.size, "video/mp4");
    const newFile = new File(
      [blob],
      formatFileName({ series, episode, isTrailer }),
      { type: "video/mp4" },
    );

    const chunks = createChunks(newFile, 1024 * 1024 * 50);

    const { uploadId, urls } = await createUpload({
      series,
      episode: episode === "" ? undefined : episode,
      isTrailer,
      numberOfParts: chunks.length,
    });

    setIsUploading(true);
    const partResults: { partNumber: number; eTag: string }[] = [];

    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];

      const uploadPartResult = await axios.put(url, chunks[i], {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "video/mp4",
        },
      });

      partResults.push({
        partNumber: i + 1,
        eTag: uploadPartResult.headers["etag"] as string,
      });

      setUploadProgress(Math.round((i / urls.length) * 100));
    }

    await completeUpload({
      series,
      episode,
      isTrailer,
      uploadId,
      parts: partResults,
    });

    // Start processing of uploaded video file
    await startVideoEncoding({ series, episode, isTrailer });

    // Resetting the input values
    setSeries("");
    setEpisode("");
    setIsTrailer(false);
    fileInput.current.value = "";
    setIsUploading(false);
    setSuccessAlertMessage(
      "Successfully uploaded video to cloud, starting encoding.",
    );
    setSuccessAlertOpened(true);
  };

  return (
    <PageContainer>
      <h3>Upload Video Content:</h3>
      <FormGroup sx={{ width: 360 }}>
        <TextField
          id="outlined-basic"
          label="Series"
          variant="outlined"
          sx={{ marginBottom: 1 }}
          value={series}
          onChange={(event) => setSeries(event.target.value)}
          required
        />
        <TextField
          id="outlined-basic"
          label="Episode"
          variant="outlined"
          value={episode}
          onChange={(event) => setEpisode(event.target.value)}
          sx={{ marginBottom: 1 }}
        />
        <FormControlLabel
          control={
            <Switch
              required
              checked={isTrailer}
              onChange={(event) => setIsTrailer(event.target.checked)}
            />
          }
          label="Trailer"
          sx={{ marginBottom: 1 }}
        />
        <FormControlLabel
          control={
            <input
              ref={fileInput}
              type={"file"}
              accept={".mp4"}
              onChange={(event) => setVideoFile(event.target.files)}
            ></input>
          }
          label="(Required)"
          sx={{ marginBottom: 2 }}
        />
        <Button
          sx={{ marginBottom: 2 }}
          disabled={isUploading}
          onClick={() => void uploadFile()}
        >
          Upload File
        </Button>

        {/* Progress Bar */}
        <Box sx={{ width: "100%", display: isUploading ? "block" : "none" }}>
          <LinearProgressWithLabel value={uploadProgress} />
        </Box>
      </FormGroup>

      {/* List Series and Episodes */}
      <h3>List of Series & Episodes</h3>
      <CollapsableTable></CollapsableTable>

      {/* Success Alert */}
      <Snackbar
        open={successAlertOpened}
        autoHideDuration={4000}
        onClose={() => setSuccessAlertOpened(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" variant="filled">
          {successAlertMessage}
        </Alert>
      </Snackbar>
      {/* Error Alert */}
      <Snackbar
        open={errorAlertOpened}
        autoHideDuration={4000}
        onClose={() => setErrorAlertOpened(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error" variant="filled">
          {errorAlertMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
}
