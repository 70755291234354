import { Button, Dialog, DialogActions, DialogTitle, Divider, TextField } from "@mui/material";
import { PostCircuitSchema } from "../../../../../api/tournaments/schemas/circuits";
import { patchCircuit, postNewCircuit } from "../../../../../api/tournaments";
import { useEffect, useState } from "react";
import { usePostPatchTO } from "../../../hooks/usePostPatchTO";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { ZodError } from "zod";
import { CircuitBuilder } from "../..";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { GamePicker } from "../../../shared/components/GamePicker";

interface CreateAndEditCircuitModalProps {
  circuitModalStatus: "new" | "edit" | "closed";
  circuit: CircuitBuilder;
  handleChange: (circuit: CircuitBuilder) => void;
  handleClose: () => void;
}

const CreateAndEditCircuitModal = ({
  circuitModalStatus,
  handleClose,
  circuit,
  handleChange,
}: CreateAndEditCircuitModalProps) => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  useEffect(() => {
    setReadyToSubmit(
      circuit.name !== "" &&
        circuit.name !== undefined &&
        circuit.startDate !== undefined &&
        circuit.endDate !== undefined &&
        circuit.startDate <= circuit.endDate,
    );
  }, [circuit]);

  const alert = useAlert();

  const submit = async () => {
    const data = PostCircuitSchema.safeParse(circuit);
    if (!data.success) {
      const err = data.error as ZodError<typeof PostCircuitSchema>;
      alert.showFailureAlert(`${err.errors[0].path.join()} ${err.errors[0].message}`);
      throw new Error(data.error.message);
    }
    if (circuitModalStatus === "edit" && circuit.id) {
      await patchCircuit({ startDate: data.data.startDate, endDate: data.data.endDate }, circuit.id);
    } else {
      await postNewCircuit(data.data);
    }
  };

  const mutatedSubmit = usePostPatchTO({
    onSuccess: () => {
      alert.showSuccessAlert(`Circuit was successfully ${circuit.id ? "edited" : "created"}}`);
      handleClose();
    },
    onError: () => {
      alert.showFailureAlert(`Circuit failed to ${circuit.id ? "edit" : "create"}`);
    },
    submit,
    type: "circuits",
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog open={circuitModalStatus !== "closed"} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Circuit</DialogTitle>
        <Divider />
        <div className="flex flex-wrap gap-2 p-6">
          <TextField
            required
            label="Circuit Name"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => {
              handleChange({ ...circuit, name: e.target.value });
            }}
            value={circuit.name}
            error={circuit?.name === "" || circuit === undefined}
            disabled={circuitModalStatus === "edit"}
          />
          <LocalizationProvider>
            <DesktopDatePicker
              label="Start Date"
              className="w-1/4"
              onChange={(e) => {
                handleChange({ ...circuit, startDate: e?.toJSDate() });
              }}
              value={circuit.startDate ? DateTime.fromJSDate(circuit.startDate) : undefined}
              format="dd/LL/yyyy"
            />
            <DesktopDatePicker
              label="End Date"
              onChange={(e) => {
                handleChange({ ...circuit, endDate: e?.toJSDate() });
              }}
              value={circuit.endDate ? DateTime.fromJSDate(circuit.endDate) : undefined}
              minDate={circuit.startDate ? DateTime.fromJSDate(circuit.startDate) : undefined}
              format="dd/LL/yyyy"
            />
          </LocalizationProvider>
          <div className="w-48">
            <GamePicker
              labelText="Game"
              onGameSelection={(gameId) => handleChange({ ...circuit, gameId: gameId })}
              variant="outlined"
            />
          </div>
        </div>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            disabled={!readyToSubmit}
            onClick={() => {
              mutatedSubmit.mutate();
            }}
          >
            Submit
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CreateAndEditCircuitModal;
