import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import useCreateTeam from "../../hooks/teams/useCreateTeam";
import { useEffect, useState } from "react";
import { MuiFileInput } from "mui-file-input";
import { uploadTeamLogo } from "../helpers/uploadTeamLogo";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import countries, { Country } from "../../../../common/countries";
import { CountryPicker } from "../../shared/components/CountryPicker";
import { GamePicker } from "../../shared/components/GamePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

interface CreateTeamModalProps {
  createTeamModalIsOpen: boolean;
  handleClose: () => void;
}

export const CreateTeamModal = ({ createTeamModalIsOpen, handleClose }: CreateTeamModalProps) => {
  const alert = useAlert();
  const [name, setName] = useState<string>();
  const [shortName, setShortName] = useState<string>();
  const [nationality, setNationality] = useState<string>("EU");
  const [gameId, setGameId] = useState<string>("cs");
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>();

  const { mutate: createTeam } = useCreateTeam({
    onError: () => alert.showFailureAlert("Failed to create team in database."),
    onSuccess: (team) => {
      if (!image || !team) return;

      uploadTeamLogo({ image, teamId: team.id })
        .then(() => {
          alert.showSuccessAlert("Successfully created the team");
          setName("");
          setShortName("");
          setNationality("EU");
          setGameId("");
          setImage(null);
        })
        .catch(() => {
          alert.showFailureAlert("Failed to upload team logo. But the team has been created in the database.");
        });
      handleClose();
    },
  });

  useEffect(() => {
    if (!image) {
      setImagePreview(undefined);
      return;
    }
    setImagePreview(URL.createObjectURL(image));
  }, [image]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog open={createTeamModalIsOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Create Team</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container flexDirection={"row"} spacing={5}>
            <Grid item>
              <Stack spacing={2} sx={{ width: 350 }}>
                <TextField
                  label="Name"
                  variant="standard"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />

                <TextField
                  label="Short Name"
                  variant="standard"
                  value={shortName}
                  onChange={(e) => setShortName(e.currentTarget.value)}
                />

                <CountryPicker
                  labelText="Nationality"
                  countries={countries}
                  defaultValue={"EU" as Country["code"]}
                  onCountrySelection={(selectedCountryCode) => setNationality(selectedCountryCode)}
                />

                <GamePicker labelText="Game" onGameSelection={(gameId) => setGameId(gameId)} />

                <MuiFileInput placeholder="Select team logo" value={image} onChange={(newFile) => setImage(newFile)} />
              </Stack>
            </Grid>
            <Grid item>
              <img
                src={imagePreview}
                height={300}
                width={300}
                alt="Team Logo"
                onLoad={() => {
                  if (imagePreview) {
                    URL.revokeObjectURL(imagePreview);
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            disabled={!name || !shortName || !nationality || !gameId || !image}
            onClick={() => {
              if (!name || !shortName || !nationality || !gameId) return;

              createTeam({
                name,
                shortName,
                nationality: nationality,
                gameId: gameId,
              });
            }}
          >
            Create
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
