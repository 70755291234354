import { useCallback, useEffect, useState } from "react";

import useDDSEvents from "../../../../../hooks/useDDSEvents";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";

import { truncateUsernamePatronPrefix } from "../../../helpers/truncateUsernamePatronPrefix";

import { Message } from "../../../../../types/Message";

export interface DDSMessagesOptions {
  roomId?: string;
}

const useDDSMessages = ({ roomId }: DDSMessagesOptions) => {
  const { patron } = usePatron();

  const [deletedMessages, setDeletedMessages] = useState(
    new Map<string, Message>()
  );
  const [restoredMessages, setRestoredMessages] = useState(
    new Map<string, Message>()
  );
  const [realTimeMessages, setRealTimeMessages] = useState<Array<Message>>([]);

  const newAdminMessagesListener = useCallback(
    (message: Message) => {
      truncateUsernamePatronPrefix(message, patron);
      setRealTimeMessages((currentMessages) => [...currentMessages, message]);
    },
    [patron]
  );

  const deletedMessageListener = useCallback(
    (message: Message) => {
      truncateUsernamePatronPrefix(message, patron);
      setRestoredMessages((current) => {
        current.delete(message.messageId);
        return new Map(current);
      });

      setDeletedMessages(
        (current) => new Map(current.set(message.messageId, message))
      );
    },
    [patron]
  );

  const restoredMessageListener = useCallback(
    (message: Message) => {
      truncateUsernamePatronPrefix(message, patron);
      setDeletedMessages((current) => {
        current.delete(message.messageId);
        return new Map(current);
      });

      setRestoredMessages(
        (current) => new Map(current.set(message.messageId, message))
      );
    },
    [patron]
  );

  const clearMessages = useCallback(() => {
    setDeletedMessages(new Map());
    setRestoredMessages(new Map());
    setRealTimeMessages([]);
  }, []);

  useEffect(() => {
    if (!roomId) {
      return;
    }

    // reset all previously stored real time messages data when room is changed
    clearMessages();
  }, [roomId, clearMessages]);

  useDDSEvents({
    roomId,
    newAdminMessagesListener,
    deletedMessageListener,
    restoredMessageListener,
    disabled: !roomId,
  });

  return {
    realTimeMessages,
    deletedMessages,
    restoredMessages,
    clearMessages,
  };
};

export default useDDSMessages;
