import { Box, Grid, Typography } from "@mui/material";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Grid container component="main" maxWidth="xl" margin={4}>
      {children}
    </Grid>
  );
}

function TopBar({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} mb={4}>
      {children}
    </Grid>
  );
}
function Content({
  children,
  title,
  subtitle,
}: {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
}) {
  return (
    <Grid item xs={8}>
      <Box mb={3}>
        <Typography component="h1" variant="h4">
          {title}
        </Typography>
        {subtitle && (
          <Typography component="p" variant="subtitle1">
            {subtitle}
          </Typography>
        )}
      </Box>
      {children}
    </Grid>
  );
}

function SideBar({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <Grid item xs={4} sx={{ p: 5 }}>
      {title && (
        <Typography component="h2" variant="h6">
          {title}
        </Typography>
      )}
      {children}
    </Grid>
  );
}

Layout.TopBar = TopBar;
Layout.Content = Content;
Layout.SideBar = SideBar;
