import { Info } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { TableCell } from "@/components/ui/table";

export default function StreamDetailsIconButton({ onClick }: { onClick: () => void }) {
  return (
    <TableCell>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon" onClick={onClick}>
              <Info className="size-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Stream details</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </TableCell>
  );
}
