import { useQuery } from "@tanstack/react-query";
import { getPlayerHistory } from "../../../api/tournaments";

export default function useFetchPlayerHistory(id: string) {
  return useQuery({
    queryKey: ["players history", id],
    queryFn: () => {
      return getPlayerHistory(id);
    },
    enabled: true,
    retry: false,
  });
}
