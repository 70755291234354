import { z } from "zod";

export const MonitoringSNSEventSchema = z.object({
  service: z.string(),
  subject: z.string().optional(),
  payload: z.any(),
  timestamp: z.string().datetime(),
  ttl: z.number(),
});
export type MonitoringSNSEvent = z.infer<typeof MonitoringSNSEventSchema>;
