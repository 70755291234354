import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { updateAward } from "../../../../api/tournaments";
import { AwardResponse, CreateUpdateAwardSchema } from "../../../../api/tournaments/schemas/awards";

export default function useUpdateAward({ onSuccess }: { onSuccess: () => void }) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["award"],
    mutationFn: async ({ award }: { award: AwardResponse }) => {
      //createAward for winning team
      try {
        await updateAward(
          award.id,
          CreateUpdateAwardSchema.parse({
            name: award.name,
            tournamentId: award.tournament?.id || null,
            playerId: award.player?.id || null,
            teamId: award.team?.id || null,
            type: award.type,
            dateAwarded: award.dateAwarded || null,
          }),
        );
      } catch (error) {
        alert.showFailureAlert("Error while Updating award");
        throw error;
      }

      void queryClient.invalidateQueries(["awards"]);
    },
    onSuccess,
  });
}
