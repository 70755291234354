import { useMutation } from "@tanstack/react-query";
import { invalidateCache } from "../../../../api/assets";
import { uploadPlayerImage } from "./uploadPlayerImage";

export function useUpdatePlayerImage() {
  return useMutation({
    mutationFn: uploadPlayerImage,
    onSuccess: (_, variables) => {
      void invalidateCache("players", variables.playerId);
    },
  });
}
