import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateChatRoomConfig } from "../../../../api/chat";
import { UpdateRoom } from "../../../../types/Room";

const useUpdateChatRoomConfig = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationFn: (room: UpdateRoom) => updateChatRoomConfig(room),
    onSuccess: (room) => {
      void queryClient.invalidateQueries(["ChatRoomConfig", room.roomId]);
    },
    onError: (error, room) => {
      void queryClient.invalidateQueries(["ChatRoomConfig", room.roomId]);
    },
  });

  return result;
};
export default useUpdateChatRoomConfig;
