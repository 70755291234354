import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getDotleAnswerPlayer } from ".";

export const useGetDotleAnswerPlayer = () => {
  return useQuery({
    queryKey: queryKeys.answerPlayer(),
    queryFn: getDotleAnswerPlayer,
  });
};
