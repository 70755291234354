import { blastApiClient } from "../../../clients/blastApiClient";
import { IssueSchema } from "../shared/schemas";
import { z } from "zod";

export const getIssues = async (params: { resolved?: boolean }) => {
  const { data } = await blastApiClient.get<unknown>(`/v1/issues/tournaments`, {
    params,
  });
  return z.array(IssueSchema).parse(data);
};
