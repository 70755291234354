import csIcon from "../../shared/assets/games/cs2.webp";
import rlIcon from "../../shared/assets/games/rocket.webp";
import dotaIcon from "../../shared/assets/games/dota.webp";
import crIcon from "../../shared/assets/games/clashroyale.webp";
import cocIcon from "../../shared/assets/games/coc.webp";
import brawlIcon from "../../shared/assets/games/brawl.webp";

export type Games = "cs" | "rl" | "dota" | "clashroyale" | "clashofclans" | "brawlstars";

export type GameInfo = {
  id: Games;
  label: string;
  icon: string;
};

export const GamesInfoList: GameInfo[] = [
  {
    label: "Counter-Strike",
    id: "cs",
    icon: csIcon,
  },
  {
    label: "Rocket League",
    id: "rl",
    icon: rlIcon,
  },
  {
    label: "Dota 2",
    id: "dota",
    icon: dotaIcon,
  },
  {
    label: "Clash Royale",
    id: "clashroyale",
    icon: crIcon,
  },
  {
    label: "Clash of Clans",
    id: "clashofclans",
    icon: cocIcon,
  },
  {
    label: "Brawl Stars",
    id: "brawlstars",
    icon: brawlIcon,
  },
];
