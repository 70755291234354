import { z } from "zod";

export const CounterStriklePlayerSchema = z.object({
  id: z.string(),
  nickname: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});

export type CounterStriklePlayer = z.infer<typeof CounterStriklePlayerSchema>;
