interface ScaleImageOptions {
  file: File;
}

export const scaleImage = async ({ file }: ScaleImageOptions) => {
  const image = new Image();
  image.src = URL.createObjectURL(file);

  await image.decode();

  const MAX_WIDTH = 484;
  const MAX_HEIGHT = 272;

  let width = image.width;
  let height = image.height;

  // Change the resizing logic
  if (width > height) {
    if (width > MAX_WIDTH) {
      height = height * (MAX_WIDTH / width);
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width = width * (MAX_HEIGHT / height);
      height = MAX_HEIGHT;
    }
  }

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(image, 0, 0, width, height);

  return canvas.toDataURL();
};
