import { z } from "zod";

export const RolesResponseSchema = z.object({
  roles: z
    .object({
      creationDate: z.string(),
      lastModifiedDate: z.string(),
      name: z.string(),
      description: z.string(),
    })
    .array(),
});

export const RolesPermissionsResponseSchema = z.record(
  z.string(),
  z.object({
    permissions: z.string().array(),
  })
);

export const PermissionsSchema = z
  .object({
    title: z.string(),
    description: z.string(),
    identifier: z.string(),
  })
  .array();

export const RoleSchema = RolesResponseSchema.shape.roles.element.merge(
  RolesPermissionsResponseSchema.element
);

export type Role = z.infer<typeof RoleSchema>;
