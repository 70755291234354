import axios from "axios";
import { createUploadLink, invalidateCache } from "../../../../api/assets";
import { Patron } from "../../../../providers/PatronProvider/types";

interface UploadAvatarImageProps {
  image: File;
  avatarId: string;
  type: "2d" | "3d";
  sendSuccessMessage: (message: string) => void;
  sendErrorMessage: (message: string) => void;
  patron?: Patron;
}

export const uploadAvatarImage = async ({
  avatarId,
  image,
  type,
  sendSuccessMessage,
  sendErrorMessage,
  patron,
}: UploadAvatarImageProps) => {
  const fileName = `${avatarId}_${type}`;
  let uploadUrl = "";
  try {
    uploadUrl = (await createUploadLink("avatars", fileName, patron)).uploadUrl;
  } catch (error) {
    sendErrorMessage("Failed to get upload url.");
    throw error;
  }

  try {
    await axios.put(uploadUrl, image, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Length": image.size,
        "Content-Type": image.type,
      },
    });
  } catch (error) {
    sendErrorMessage(`${type} image failed to upload.`);
    throw error;
  }

  try {
    await invalidateCache("avatars", fileName, patron);
  } catch (error) {
    sendErrorMessage("Failed to invalidate cache.");
    throw error;
  }

  sendSuccessMessage(`${type} image uploaded successfully.`);
};
