import { useQuery } from "@tanstack/react-query";
import { getCsQuizSchedules } from ".";
import { queryKeys } from "../shared/queryKeys";

export const useGetCsQuizSchedules = (isoDate: string) => {
  return useQuery({
    queryKey: queryKeys.schedules(isoDate),
    queryFn: () => getCsQuizSchedules(isoDate),
  });
};
