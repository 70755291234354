import { useQuery } from "@tanstack/react-query";
import { getRocketLeagueStatTeams } from "..";
import { rlcsStatQueryKeys } from "../../rlcsStatQueryKeys";

export function useGetRlcsStatTeams() {
  return useQuery({
    queryKey: rlcsStatQueryKeys.teams,
    queryFn: getRocketLeagueStatTeams,
  });
}
export type GetRlcsStatTeamsResponse = Awaited<ReturnType<typeof getRocketLeagueStatTeams>>;
