import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTournamentPrize } from "../../../../../../api/tournaments";

export default function useDeleteTournamentPrize() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (prizeId: string) => deleteTournamentPrize(prizeId),
    retry: false,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["tournament prizes"],
      });
    },
  });
}
