import secondsToMessage from "./secondsToMessage";

import { Suspension } from "../../../../types/Suspension";

const getModActionStatus = (suspension: undefined | Suspension) => {
  let status: string;

  if (!suspension) {
    status = "deleted";
  } else if (suspension.isPermanent) {
    status = "banned";
  } else {
    status = `suspended: ${secondsToMessage(suspension.interval).trim()}`;
  }

  return ` // <${status}>`;
};

export default getModActionStatus;
