import { useQuery } from "@tanstack/react-query";
import { getTeamLineup } from "../api/tournaments";

export const useGetLineupBySeriesId = ({ seriesId, enabled }: { seriesId?: string; enabled: boolean }) => {
  return useQuery({
    queryKey: ["lineup", seriesId],
    queryFn: () => getTeamLineup({ seriesId }),
    staleTime: 5000,
    retry: false,
    enabled,
  });
};
