import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createEvent, queryKeys } from "..";

export const useCreateEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createEvent,
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.eventsList());
    },
  });
};
