import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Tournament } from "../../../../api/tournaments/schemas/tournaments";
import { Team } from "../../../../api/tournaments/schemas/teams";
import { DetailedPlayer } from "../../../../api/tournaments/schemas/players";
import { useEffect, useState } from "react";
import useCreateTournamentAwards from "../../hooks/awards/useCreateTournamentAwards";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface TournamentAwardModalProps {
  isOpen: boolean;
  tournaments: Tournament[];
  teams: Team[];
  players: DetailedPlayer[];
  handleClose: () => void;
}

export const TournamentAwardModal = ({
  isOpen,
  tournaments,
  teams,
  players,
  handleClose,
}: TournamentAwardModalProps) => {
  const alert = useAlert();
  const [selectedTournamentId, setSelectedTournamentId] = useState<string | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const { mutate: createTournamentAwards } = useCreateTournamentAwards({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated match");
    },
  });

  useEffect(() => {
    if (selectedDate === null) {
      const endDate = tournaments.find((tournament) => tournament.id === selectedTournamentId)?.endDate;
      setSelectedDate(endDate ? new Date(endDate) : null);
    }
  }, [selectedDate, selectedTournamentId, tournaments]);

  const handleSave = () => {
    const teamPlayers = players.filter((player) => player.team?.id === selectedTeamId && player.role === "player");
    const tournamentName = tournaments.find((tournament) => tournament.id === selectedTournamentId)?.name;
    const mvpTeamId = teamPlayers.find((player) => player.id === selectedPlayerId)?.team?.id;
    if (selectedTournamentId && selectedDate && tournamentName)
      createTournamentAwards({
        tournamentId: selectedTournamentId,
        tournamentName: tournamentName,
        teamId: selectedTeamId,
        teamPlayers: teamPlayers,
        dateAwarded: selectedDate,
        mvpPlayerId: selectedPlayerId,
        mvpTeamId: mvpTeamId || null,
      });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Dialog
          open={isOpen}
          onClose={() => {
            setSelectedPlayerId(null);
            setSelectedTeamId(null);
            setSelectedTournamentId(null);
            setSelectedDate(null);
            handleClose();
          }}
          PaperProps={{
            sx: {
              maxWidth: "1500px",
              width: "100%",
              padding: "10px",
            },
          }}
        >
          <DialogContent>
            <div className="my-3 font-style-b1-body-copy"> Winner and MVP for Tournament</div>
            <Stack flexDirection={"row"} width={340} gap={2}>
              <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                <InputLabel>Tournament</InputLabel>
                <Select
                  value={selectedTournamentId || null}
                  onChange={(e) => {
                    setSelectedTournamentId(e.target.value);
                  }}
                >
                  {tournaments.map((tournament) => (
                    <MenuItem key={tournament.id} value={tournament.id}>
                      {tournament.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                <InputLabel>Winning Team</InputLabel>
                <Select
                  value={selectedTeamId || null}
                  onChange={(e) => {
                    setSelectedTeamId(e.target.value);
                  }}
                >
                  {teams.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: "250px" }}>
                <Autocomplete
                  sx={{ width: "250px" }}
                  id="select-player"
                  options={players}
                  getOptionLabel={(option) => option.nickname}
                  onChange={(_, newValue: { id: string; nickname: string } | null) => {
                    setSelectedPlayerId(newValue?.id || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        selectedPlayerId
                          ? players.find((player) => player.id === selectedPlayerId)?.nickname
                          : "Select MVP"
                      }
                      placeholder="Select / Search MVP"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.nickname}
                    </li>
                  )}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: "200px" }}>
                <DatePicker
                  label="Date Awarded"
                  format="dd/LL/yyyy"
                  value={selectedDate ? DateTime.fromJSDate(selectedDate) : DateTime.fromJSDate(new Date())}
                  onChange={(date) => setSelectedDate(date?.toJSDate() || null)}
                />
              </FormControl>
              <Button
                variant="contained"
                className="min-w-fit"
                disabled={
                  selectedTournamentId === null ||
                  selectedDate === null ||
                  (selectedPlayerId === null && selectedTeamId === null)
                }
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      </LocalizationProvider>
    </>
  );
};
