import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DateTimePickerProps } from "@mui/lab";

export default function DateTimeInput(props: DateTimePickerProps<DateTime>) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DateTimePicker {...props} format="dd/LL/yyyy HH:mm" ampm={false} />
    </LocalizationProvider>
  );
}
