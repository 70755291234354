import { blastApiClient } from "@/api/clients/blastApiClient";

import { ScheduleSchema } from "../types/schedule";

export const getRlcsSchedules = async () => {
  const data = await blastApiClient.get<unknown>("/v1/rlcs-statistics/schedules");

  const schedules = ScheduleSchema.array().safeParse(data.data);

  if (!schedules.success) {
    // eslint-disable-next-line no-console
    console.error(schedules.error);
    throw new Error("Failed to parse schedules");
  }

  return schedules.data;
};
