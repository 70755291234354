import { Button, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import useFetchMatchResults from "./hooks/useFetchMatchResults";
import useFetchMatchesByTournamentId from "../Tournaments/hooks/matches/useFetchMatchesByTournamentId";
import { useDeleteFantasyMatchResult } from "./hooks";
import ResultsTable from "./components/ResultsTable";
import UpdateModal from "./components/UpdateModal";
import useFetchAllPlayers from "./hooks/useFetchPlayers";
import ConfirmModal from "./components/ConfirmModal";
import { useQueryClient } from "@tanstack/react-query";
import LeaderboardSyncContainer from "./components/LeaderboardSyncContainer";
import useFetchSanityFantasyEvents from "@/sanity/Fantasy/useFetchSanityFantasyEvents";
import { DateTime } from "luxon";
import { OpenInNew } from "@mui/icons-material";

const FantasyDetailsPage = () => {
  const { seasonId, eventId } = useParams();

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [editingMatchId, setEditingMatchId] = useState<string>("");
  const [deletingMatchId, setDeletingMatchId] = useState<string>("");

  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string>("");
  const [isWaiting, setIsWaiting] = useState(false);
  const { mutateAsync: mutateDeleteResult } = useDeleteFantasyMatchResult();

  const { data: players, isLoading: playersIsLoading } = useFetchAllPlayers();

  const { data: allFantasyEvents, isLoading: fantasyIsLoading } = useFetchSanityFantasyEvents();

  const fantasy = allFantasyEvents?.find(
    (fantasyEvent) => fantasyEvent.season.uid === seasonId && fantasyEvent.eventId === eventId,
  );

  const { data: matches, isLoading: tournamentMatchesIsLoading } = useFetchMatchesByTournamentId(fantasy?.tournamentId);

  const {
    data: fantasyMatchResults,
    isLoading: matchesIsLoading,
    refetch: refetchMatchResults,
  } = useFetchMatchResults({
    seasonId,
    eventId,
    enabled: Boolean(seasonId && eventId),
  });

  const queryClient = useQueryClient();

  const fantasyMatches = useMemo(() => {
    if (!fantasy || !matches) return [];

    return matches.filter((match) => fantasy.stageIds.includes(match.stageId));
  }, [fantasy, matches]);

  const handleDelete = useCallback(async () => {
    if (!deletingMatchId || !fantasy?.season?.uid || !fantasy?.tournamentId) {
      return;
    }

    setIsWaiting(true);
    let hasError = false;
    const handleError = () => {
      hasError = true;
      setDeleteErrorMessage("Failed to delete match results. Please check console.");
    };

    await mutateDeleteResult(
      {
        seasonId: fantasy.season.uid,
        eventId: fantasy.eventId,
        matchId: deletingMatchId,
      },
      {
        onError: handleError,
      },
    );

    setIsWaiting(false);
    if (!hasError) {
      void queryClient.invalidateQueries(["matchResults"]);
      setDeletingMatchId("");
      void refetchMatchResults();
    }
  }, [deletingMatchId, mutateDeleteResult, queryClient, fantasy, refetchMatchResults]);

  return (
    <div className="flex max-w-screen-lg flex-col gap-4 p-4">
      <div className="flex flex-col gap-4 rounded-small bg-zinc-900/80 p-4 text-sm">
        <Typography variant="h4" component="span">
          {fantasy?.title}
        </Typography>
        <table>
          <tbody>
            <tr>
              <td>Tournament</td>
              <td colSpan={2}>
                <Button
                  component={Link}
                  to={`/to/tournaments/cs/${fantasy?.tournamentId}`}
                  target="_blank"
                  size="small"
                  color="inherit"
                  endIcon={<OpenInNew fontSize="small" />}
                  className="normal-case"
                >
                  {fantasy?.tournamentId}
                </Button>
              </td>
            </tr>
            <tr>
              <td className="py-1">Edit Lock Time</td>
              <td className="px-1">
                {fantasy?.editLockTime
                  ? DateTime.fromISO(fantasy?.editLockTime).toLocaleString(DateTime.DATETIME_MED)
                  : "-"}
              </td>
              <td className="text-gray-400">
                No edits are allowed after this time, but new users can submit their picks.
              </td>
            </tr>
            <tr>
              <td className="py-1">End Time</td>
              <td className="px-1">
                {fantasy?.endTime ? DateTime.fromISO(fantasy?.endTime).toLocaleString(DateTime.DATETIME_MED) : "-"}
              </td>
              <td className="text-gray-400">
                No new submissions or edits are allowed after this time. Should be the start of the last match in the
                event.
              </td>
            </tr>
          </tbody>
        </table>
        <LeaderboardSyncContainer
          seasonId={fantasy?.season?.uid}
          eventId={fantasy?.eventId}
          calculationSchemaId={fantasy?.calculationSchemaId ?? undefined}
        />
      </div>
      <ResultsTable
        matchResults={fantasyMatchResults ?? []}
        matches={matches ?? []}
        isLoading={fantasyIsLoading || tournamentMatchesIsLoading || matchesIsLoading || playersIsLoading}
        onAdd={() => {
          setIsAddEditModalOpen(true);
        }}
        onEdit={(matchId) => {
          setEditingMatchId(matchId);
          setIsAddEditModalOpen(true);
        }}
        onDelete={(matchId) => setDeletingMatchId(matchId)}
      />
      {fantasy && isAddEditModalOpen && (
        <UpdateModal
          fantasyEvent={fantasy}
          players={players ?? []}
          editingMatchId={editingMatchId}
          isLoading={fantasyIsLoading || tournamentMatchesIsLoading || matchesIsLoading || playersIsLoading}
          matches={fantasyMatches}
          results={fantasyMatchResults ?? []}
          open={isAddEditModalOpen}
          onClose={() => {
            setIsAddEditModalOpen(false);
            setEditingMatchId("");
          }}
          onSuccess={() => {
            void refetchMatchResults();
          }}
        />
      )}

      <ConfirmModal
        key={deletingMatchId}
        open={!!deletingMatchId}
        onCancel={() => setDeletingMatchId("")}
        onSubmit={() => void handleDelete()}
        submitText="Delete"
        submitColor="error"
        disabled={isWaiting}
        errorMessage={deleteErrorMessage}
      >
        <Typography variant="body1" fontWeight="bold">
          Delete match result?
        </Typography>
      </ConfirmModal>
    </div>
  );
};

export default FantasyDetailsPage;
