import { Alert, Box, Breadcrumbs, Button, Typography } from "@mui/material";
import { useState } from "react";
import { EmojisTable } from "./components/EmojisTable";
import { useQuery } from "@tanstack/react-query";
import { CreateEmojiModal } from "./components/CreateEmojiModal";
import { EditEmojiModal } from "./components/EditEmojiModal";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { editEmoji } from "./helpers/editEmoji";
import { deleteEmoji } from "./helpers/deleteEmoji";
import { uploadEmojiImage } from "./helpers/uploadEmojiImage";
import PageContainer from "../../../components/Layout/PageContainer";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";
import { Emoji } from "../../../types/Emoji";
import { differenceInObject } from "../shared/helpers/differenceInObject";
import { PatronBreadcrumbItem } from "../../../providers/PatronProvider/components/PatronBreadcrumbItem";
import { getEmojis } from "../../../api/assets";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";

export const Emojis = () => {
  const alert = useAlert();
  const { patron } = usePatron();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteEmojiConfirmationOpen, setIsDeleteEmojiConfirmationOpen] =
    useState(false);
  const [deleteEmojiId, setDeleteEmojiId] = useState("");
  const [editingEmoji, setEditingEmoji] = useState<Emoji | undefined>(
    undefined,
  );

  const { data: emojis } = useQuery({
    queryKey: ["emojis", patron],
    queryFn: () => getEmojis(patron),
  });

  const setActiveEditingEmoji = (emojiId: string) => {
    const editEmoji = emojis?.find((emoji) => emoji.id === emojiId);
    if (!editEmoji) return;
    setEditingEmoji(editEmoji);
    setIsEditModalOpen(true);
  };

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.secondary" fontWeight="bold">
          Assets
        </Typography>
        <Typography color="text.primary" fontWeight="bold">
          Emojis
        </Typography>
        <PatronBreadcrumbItem />
      </Breadcrumbs>

      <Box
        sx={{ display: "flex", gap: "15rem", alignItems: "center" }}
        className="mt-10"
      >
        <h2>Emojis</h2>
        <Button
          sx={{ fontWeight: "bold" }}
          variant="contained"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Create Emoji
        </Button>
      </Box>

      <Alert severity="warning">
        If data or image hasn&apos;t updated, please to clear browser cache, and
        refresh the page.
      </Alert>

      <EmojisTable emojis={emojis} setEditingEmoji={setActiveEditingEmoji} />

      {isDeleteEmojiConfirmationOpen && (
        <ConfirmationModal
          isOpen={isDeleteEmojiConfirmationOpen}
          onClose={() => setIsDeleteEmojiConfirmationOpen(false)}
          title="Delete Emoji?"
          description="Are you sure that you want to delete this emoji?"
          onConfirm={async () => {
            await deleteEmoji({
              emojiId: deleteEmojiId,
              patron,
              sendSuccessMessage: (message) => alert.showSuccessAlert(message),
              sendErrorMessage: (message) => alert.showFailureAlert(message),
            });
            setIsEditModalOpen(false);
            setIsDeleteEmojiConfirmationOpen(false);
          }}
        />
      )}

      {isCreateModalOpen && (
        <CreateEmojiModal
          onClose={() => setIsCreateModalOpen(false)}
          open={isCreateModalOpen}
          isLoading={false}
        />
      )}

      {isEditModalOpen && editingEmoji && (
        <EditEmojiModal
          open={isEditModalOpen}
          isLoading={false}
          emoji={editingEmoji}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={async ({ changedEmoji, image }) => {
            const originalEmoji = emojis?.find(
              (emoji) => emoji.id === changedEmoji.id,
            );
            if (!originalEmoji) return;

            const difference = differenceInObject({
              original: originalEmoji,
              changed: changedEmoji,
            });
            await editEmoji({
              emojiId: changedEmoji.id,
              emojiData: difference,
              patron,
              sendSuccessMessage: (message) => alert.showSuccessAlert(message),
              sendErrorMessage: (message) => alert.showFailureAlert(message),
            });

            if (image !== null) {
              await uploadEmojiImage({
                emojiId: changedEmoji.id,
                image,
                patron,
                sendSuccessMessage: (message) => {
                  alert.showSuccessAlert(message);
                },
                sendErrorMessage: (message) => {
                  alert.showFailureAlert(message);
                },
              });
            }

            setIsEditModalOpen(false);
          }}
          onDelete={(emojiId) => {
            setDeleteEmojiId(emojiId);
            setIsDeleteEmojiConfirmationOpen(true);
            setIsEditModalOpen(false);
          }}
        />
      )}
    </PageContainer>
  );
};
