import { useMutation } from "@tanstack/react-query";
import { startTriggerPoll } from "../../../../api/triggers";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

const useStartTriggerPoll = () => {
  const { showFailureAlert, showSuccessAlert } = useAlert();

  return useMutation({
    mutationFn: (id: string) => startTriggerPoll(id),
    onSuccess() {
      showSuccessAlert("Poll started successfully");
    },
    onError(error) {
      if (error instanceof Error) {
        showFailureAlert(error.message);
      } else {
        showFailureAlert("Something went wrong while starting the poll");
      }
    },
  });
};

export default useStartTriggerPoll;
