import { Modal } from "@blastorg/portal-pattern-library";
import {
  ModerationRule,
  ModerationRuleActionTypeSchema,
  ModerationRuleSchema,
  ModerationRuleActionType,
} from "../../../../types/Moderation";
import { useCallback, useState } from "react";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

interface SaveRuleModalProps {
  editingRule?: ModerationRule;
  isOpen: boolean;
  onClose: () => void;
  onAdd: (rule: ModerationRule) => void;
  onEdit: (rule: ModerationRule) => void;
}

const SaveRuleModal = ({
  editingRule,
  isOpen,
  onClose,
  onAdd,
  onEdit,
}: SaveRuleModalProps) => {
  const [id, setId] = useState<string>(editingRule?.id ?? "");
  const [description, setDescription] = useState<string>(
    editingRule?.description ?? ""
  );
  const [toxic, setToxic] = useState<ModerationRule["toxic"]>(
    editingRule?.toxic ?? 0
  );
  const [derogatory, setDerogatory] = useState<ModerationRule["derogatory"]>(
    editingRule?.derogatory ?? 0
  );
  const [violent, setviolent] = useState<ModerationRule["violent"]>(
    editingRule?.violent ?? 0
  );
  const [sexual, setsexual] = useState<ModerationRule["sexual"]>(
    editingRule?.sexual ?? 0
  );
  const [insult, setinsult] = useState<ModerationRule["insult"]>(
    editingRule?.insult ?? 0
  );
  const [profanity, setprofanity] = useState<ModerationRule["profanity"]>(
    editingRule?.profanity ?? 0
  );
  const [deathHarmAndTragedy, setdeathHarmAndTragedy] = useState<
    ModerationRule["deathHarmAndTragedy"]
  >(editingRule?.deathHarmAndTragedy ?? 0);
  const [firearmsAndWeapons, setfirearmsAndWeapons] = useState<
    ModerationRule["firearmsAndWeapons"]
  >(editingRule?.firearmsAndWeapons ?? 0);
  const [publicSafety, setpublicSafety] = useState<
    ModerationRule["publicSafety"]
  >(editingRule?.publicSafety ?? 0);
  const [health, sethealth] = useState<ModerationRule["health"]>(
    editingRule?.health ?? 0
  );
  const [religionAndBelief, setreligionAndBelief] = useState<
    ModerationRule["religionAndBelief"]
  >(editingRule?.religionAndBelief ?? 0);
  const [illicitDrugs, setillicitDrugs] = useState<
    ModerationRule["illicitDrugs"]
  >(editingRule?.illicitDrugs ?? 0);
  const [warAndConflict, setwarAndConflict] = useState<
    ModerationRule["warAndConflict"]
  >(editingRule?.warAndConflict ?? 0);
  const [finance, setfinance] = useState<ModerationRule["finance"]>(
    editingRule?.finance ?? 0
  );
  const [politics, setpolitics] = useState<ModerationRule["politics"]>(
    editingRule?.politics ?? 0
  );
  const [legal, setlegal] = useState<ModerationRule["legal"]>(
    editingRule?.legal ?? 0
  );

  const [actionType, setActionType] = useState<
    ModerationRuleActionType | undefined
  >(editingRule?.action.type);

  const handleSave = useCallback(() => {
    const validated = ModerationRuleSchema.parse({
      id,
      description,
      toxic,
      derogatory,
      violent,
      sexual,
      insult,
      profanity,
      deathHarmAndTragedy,
      firearmsAndWeapons,
      publicSafety,
      health,
      religionAndBelief,
      illicitDrugs,
      warAndConflict,
      finance,
      politics,
      legal,
      action: {
        type: actionType,
      },
    });

    const save = editingRule ? onEdit : onAdd;

    return save(validated);
  }, [
    id,
    description,
    toxic,
    derogatory,
    violent,
    sexual,
    insult,
    profanity,
    deathHarmAndTragedy,
    firearmsAndWeapons,
    publicSafety,
    health,
    religionAndBelief,
    illicitDrugs,
    warAndConflict,
    finance,
    politics,
    legal,
    actionType,
    editingRule,
    onAdd,
    onEdit,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="bg-gray-800 md:p-8 max-md:p-8 min-w-[500px]"
    >
      <span className="block mb-8 font-style-label-1 mr-auto text-left">
        {editingRule ? "Edit" : "Add"} Moderation Rule
      </span>
      <div className="flex flex-col gap-8 pb-8">
        <div className="flex items-center gap-4">
          <InputLabel>ID:</InputLabel>
          <TextField
            value={id}
            onChange={(e) => setId(e.target.value)}
            placeholder="Enter a unique ID, e.g. rule-toxic-violent, ..."
            className="flex-[3]"
            size="small"
          />
          <InputLabel id="action-select-label">Action:</InputLabel>
          <Select
            labelId="action-select-label"
            id="action-select"
            value={actionType}
            onChange={(e) =>
              setActionType(
                ModerationRuleActionTypeSchema.parse(e.target.value)
              )
            }
            className="flex-[2] text-left"
            size="small"
          >
            {ModerationRuleActionTypeSchema.options.map((actionType) => (
              <MenuItem key={actionType} value={actionType}>
                {actionType}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="flex items-center">
          <InputLabel className="w-28 text-left">Description:</InputLabel>
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter a description"
            className="w-full"
            size="small"
          />
        </div>
      </div>
      <div className="flex w-full gap-8">
        <Table className="flex-1 w-full" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Bigger than</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Toxic"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={toxic}
                  onChange={(e) => setToxic(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setToxic(newValue as ModerationRule["toxic"])
                  }
                  value={toxic}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                  color="primary"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Derogatory"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={derogatory}
                  onChange={(e) => setDerogatory(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setDerogatory(newValue as ModerationRule["derogatory"])
                  }
                  value={derogatory}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Violent"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={violent}
                  onChange={(e) => setviolent(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setviolent(newValue as ModerationRule["violent"])
                  }
                  value={violent}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Sexual"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={sexual}
                  onChange={(e) => setsexual(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setsexual(newValue as ModerationRule["sexual"])
                  }
                  value={sexual}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Insult"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={insult}
                  onChange={(e) => setinsult(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setinsult(newValue as ModerationRule["insult"])
                  }
                  value={insult}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Profanity"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={profanity}
                  onChange={(e) => setprofanity(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setprofanity(newValue as ModerationRule["profanity"])
                  }
                  value={profanity}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy whitespace-nowrap">
                {"Death Harm And Tragedy"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={deathHarmAndTragedy}
                  onChange={(e) =>
                    setdeathHarmAndTragedy(Number(e.target.value))
                  }
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setdeathHarmAndTragedy(
                      newValue as ModerationRule["deathHarmAndTragedy"]
                    )
                  }
                  value={deathHarmAndTragedy}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Firearms And Weapons"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={firearmsAndWeapons}
                  onChange={(e) =>
                    setfirearmsAndWeapons(Number(e.target.value))
                  }
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setfirearmsAndWeapons(
                      newValue as ModerationRule["firearmsAndWeapons"]
                    )
                  }
                  value={firearmsAndWeapons}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table className="flex-1 w-full" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Bigger than</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Public Safety"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={publicSafety}
                  onChange={(e) => setpublicSafety(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setpublicSafety(newValue as ModerationRule["publicSafety"])
                  }
                  value={publicSafety}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Health"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={health}
                  onChange={(e) => sethealth(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    sethealth(newValue as ModerationRule["health"])
                  }
                  value={health}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Religion And Belief"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={religionAndBelief}
                  onChange={(e) => setreligionAndBelief(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setreligionAndBelief(
                      newValue as ModerationRule["religionAndBelief"]
                    )
                  }
                  value={religionAndBelief}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Illicit Drugs"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={illicitDrugs}
                  onChange={(e) => setillicitDrugs(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setillicitDrugs(newValue as ModerationRule["illicitDrugs"])
                  }
                  value={illicitDrugs}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"War And Conflict"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={warAndConflict}
                  onChange={(e) => setwarAndConflict(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setwarAndConflict(
                      newValue as ModerationRule["warAndConflict"]
                    )
                  }
                  value={warAndConflict}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Finance"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={finance}
                  onChange={(e) => setfinance(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setfinance(newValue as ModerationRule["finance"])
                  }
                  value={finance}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Politics"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={politics}
                  onChange={(e) => setpolitics(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setpolitics(newValue as ModerationRule["politics"])
                  }
                  value={politics}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pr-1 font-style-b2-body-copy">
                {"Legal"}
              </TableCell>
              <TableCell className="flex items-center gap-4">
                <TextField
                  value={legal}
                  onChange={(e) => setlegal(Number(e.target.value))}
                  type="number"
                  className="w-20 appearance-none"
                  size="small"
                />
                <Slider
                  onChange={(e, newValue) =>
                    setlegal(newValue as ModerationRule["legal"])
                  }
                  value={legal}
                  valueLabelDisplay="auto"
                  size="small"
                  step={0.05}
                  min={0}
                  max={1}
                  className="w-32"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end mt-8 gap-4">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default SaveRuleModal;
