import { useQuery } from "@tanstack/react-query";
import { getMatchesByTournamentId } from "../../../../../api/tournaments";

const useFetchMatchesByTournamentId = (tournamentId?: string) =>
  useQuery({
    queryKey: ["fetchMatchesByTournamentId", tournamentId],
    queryFn: () => (tournamentId ? getMatchesByTournamentId(tournamentId) : undefined),
    enabled: tournamentId !== "",
  });

export default useFetchMatchesByTournamentId;
