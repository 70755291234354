import { useContext } from "react";
import PointsContext from "./PointsContext";

export function usePointsContext() {
  const context = useContext(PointsContext);
  if (context === undefined) {
    throw new Error("usePointsContext must be used within a PointsProvider");
  }
  return context;
}

export default usePointsContext;
