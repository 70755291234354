import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { DialogRow } from "../../../../components/Dialog/DialogRow";
import { DateTime } from "luxon";
import { Stream } from "../../../../types/Streams";
import { DialogContentText } from "../../../../components/Dialog/DIalogContentText";
import { useState } from "react";
import { DeleteStreamConfirmation } from "../DeleteStreamConfirmation";

export function StreamDetailsDialog({
  open,
  closeDialog,
  stream,
  refetch,
}: {
  open: boolean;
  closeDialog: () => void;
  stream?: Stream;
  refetch: () => void;
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);

  if (!open) {
    return null;
  }

  return (
    <div>
      <Dialog open={open} onOpenChange={closeDialog}>
        <DialogHeader>
          <DialogTitle>{stream?.title ?? "Fetching - details"}</DialogTitle>
        </DialogHeader>
        <Separator />
        <DialogContent>
          <DialogRow>
            <DialogContentText
              title="Scheduled for"
              contentTextValue={
                stream && stream?.startTime
                  ? DateTime.fromISO(stream?.startTime).toFormat("dd/MM/yyyy HH:mm")
                  : "Stream date"
              }
            />
            <DialogContentText title="Timeline Offset" contentTextValue={stream?.timelineOffset} />
          </DialogRow>
          <DialogRow>
            <DialogContentText title="Stream status" contentTextValue={stream?.state} />
            <DialogContentText title="Provider" contentTextValue={stream?.provider} />
          </DialogRow>
          <Separator />
          <DialogContentText
            title="Created at"
            contentTextValue={stream ? DateTime.fromISO(stream?.createdAt).toFormat("dd/MM/yyyy HH:mm") : "Stream date"}
          />
          <DialogContentText title="Output endpoint" contentTextValue={stream?.outputEndpoint} copy />
          <DialogContentText title="Ingest endpoint" contentTextValue={stream?.ingestEndpoint} copy />
          <DialogContentText title="Highest profile resolution" contentTextValue={stream?.highestProfile} />
          <DialogContentText title="Video input tier" contentTextValue={stream?.inputTier} />
          <DialogContentText title="UID" contentTextValue={stream?.id} copy />
        </DialogContent>
        <Separator />
        <DialogFooter className="flex justify-center gap-2">
          <Button variant="destructive" onClick={() => setDeleteOpen(true)}>
            Delete
          </Button>
          <Button variant="outline" onClick={closeDialog}>
            Close
          </Button>
        </DialogFooter>
      </Dialog>
      <DeleteStreamConfirmation
        open={deleteOpen}
        onStreamDeleted={refetch}
        onClose={() => setDeleteOpen(false)}
        closeDetails={closeDialog}
        stream={stream}
      />
    </div>
  );
}
