import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useFetchSanityFantasyEvents from "../../sanity/Fantasy/useFetchSanityFantasyEvents";
import classNames from "classnames";
import { useCallback } from "react";
import { FantasyEvent } from "@/sanity/Fantasy";
import { DateTime } from "luxon";

export function FantasyPage() {
  const { data: fantasyEvents, isLoading, isError } = useFetchSanityFantasyEvents();
  const navigate = useNavigate();

  const handleClick = useCallback(
    (fantasyEvent: FantasyEvent) => {
      navigate(`/fantasy/${fantasyEvent.season.uid}/${fantasyEvent.eventId}`);
    },
    [navigate],
  );

  return (
    <div className="flex flex-col gap-4 p-4">
      <Typography variant="h6">CS Fantasy events</Typography>
      <Typography variant="body2">These fantasy events are stored and edited in Sanity.</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Season</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Tournament</TableCell>
            <TableCell>Edit Lock Time</TableCell>
            <TableCell>End Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fantasyEvents?.map((fantasyEvent, index) => (
            <TableRow
              key={index}
              className={classNames({
                "cursor-pointer transition-all duration-150 ease-in-out hover:bg-white/10": !isLoading && !isError,
              })}
              onClick={() => handleClick(fantasyEvent)}
            >
              <TableCell>{fantasyEvent.season.title}</TableCell>
              <TableCell>{fantasyEvent.title}</TableCell>
              <TableCell>{fantasyEvent.tournamentId}</TableCell>
              <TableCell>
                {fantasyEvent.editLockTime
                  ? DateTime.fromISO(fantasyEvent.editLockTime).toLocaleString(DateTime.DATETIME_MED)
                  : "-"}
              </TableCell>
              <TableCell>
                {fantasyEvent.endTime
                  ? DateTime.fromISO(fantasyEvent.endTime).toLocaleString(DateTime.DATETIME_MED)
                  : "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
