import { useQuery } from "@tanstack/react-query";
import { getCircuits } from "../../../api/tournaments";

export default function useFetchCircuits(game?: string) {
  return useQuery({
    queryKey: ["circuits", game],
    queryFn: () => {
      return getCircuits(game);
    },
    staleTime: 1000 * 3,
    enabled: true,
    retry: false,
  });
}
