import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteChannel, queryKeys } from "..";

export const useDeleteChannel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteChannel,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.channelList() });
    },
  });
};
