import {
  Typography,
  TextField,
  Stack,
  AppBar,
  Toolbar,
  Button,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import { CsMetadata, DetailedPlayer, DotaMetadata } from "../../../../api/tournaments/schemas/players";
import { ChangeEvent, useEffect, useState } from "react";
import { PlayerCard } from "./PlayerCard";
import { CreatePlayerModal } from "./CreatePlayerModal";
import { useSearchParams } from "react-router-dom";

interface PlayerListProps {
  hideSearch?: boolean;
  players: DetailedPlayer[] | undefined | null;
  maxShownPlayers?: number;
  teamId?: string;
  gameId: string;
}

export const PlayerList = ({ players, maxShownPlayers = 20, hideSearch, teamId, gameId }: PlayerListProps) => {
  const [index, setIndex] = useState(1);

  const [metadataFilterName, setMetadataFilterName] = useState<string[]>([]);

  useEffect(() => {
    setIndex(1);
  }, [players]);
  const startIndex = index - 1;
  const [createPlayerModalOpen, setCreatePlayerModalOpen] = useState(false);
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [filterValue, setFilterValue] = useState<string>(urlSearchParams.get("search") ?? "");
  const [filteredPlayers, setFilteredPlayers] = useState<DetailedPlayer[]>([]);

  useEffect(() => {
    if (!players) {
      return;
    }
    const playersFiltered =
      filterValue || metadataFilterName.length
        ? players.filter((player) => {
            let shouldInclude = false;
            if (filterValue?.length) {
              if (player.nickname.toLowerCase().includes(filterValue.toLowerCase())) {
                shouldInclude = true;
              }
            }

            if (
              metadataFilterName.length &&
              (metadataFilterName.includes("role") || metadataFilterName.includes("tiAppearances"))
            ) {
              const metadata = player.dotaMetadata;
              if (!metadata) {
                return true;
              }

              metadataFilterName.forEach((name) => {
                const value = metadata[name as keyof DotaMetadata];
                if (value == null) {
                  shouldInclude = true;
                }
              });
            }

            if (
              metadataFilterName.length &&
              (metadataFilterName.includes("role") || metadataFilterName.includes("tiAppearances"))
            ) {
              const metadata = player.csMetadata;
              if (!metadata) {
                return true;
              }

              metadataFilterName.forEach((name) => {
                const value = metadata[name as keyof CsMetadata];
                if (value == null) {
                  shouldInclude = true;
                }
              });
            }

            return shouldInclude;
          })
        : [...players];

    playersFiltered.sort((a, b) => (a.nickname < b.nickname ? -1 : 1));
    setFilteredPlayers(playersFiltered);
  }, [players, filterValue, metadataFilterName]);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target?.value ?? "";
    setFilterValue(newValue);
    setUrlSearchParams((prev) => {
      if (!newValue) {
        prev.delete("search");
      } else {
        prev.set("search", newValue);
      }
      return prev;
    });
  };

  const handleChangeMetadata = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setMetadataFilterName(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        {hideSearch !== true && (
          <AppBar position="sticky">
            <Toolbar variant="regular" disableGutters>
              <Stack direction="row" sx={{ width: "100%" }} justifyContent="space-between">
                <div className="flex items-center gap-3">
                  <TextField
                    variant="outlined"
                    label="Search"
                    sx={{ m: 1 }}
                    onChange={handleFilterChange}
                    value={filterValue}
                  />

                  {gameId === "cs" ? (
                    <div>
                      <div>
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel>Missing metadata</InputLabel>
                          <Select
                            multiple
                            value={metadataFilterName}
                            onChange={handleChangeMetadata}
                            input={<OutlinedInput label="Missing metadata" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 48 * 4.5 + 8,
                                  width: 250,
                                },
                              },
                            }}
                          >
                            {["role", "majorAppearances"].map((meta) => (
                              <MenuItem key={meta} value={meta}>
                                <Checkbox checked={metadataFilterName.indexOf(meta) > -1} />
                                <ListItemText primary={meta} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ) : null}

                  {gameId === "dota" ? (
                    <div>
                      <div>
                        <FormControl sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">Missing metadata</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={metadataFilterName}
                            onChange={handleChangeMetadata}
                            input={<OutlinedInput label="Missing metadata" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 48 * 4.5 + 8,
                                  width: 250,
                                },
                              },
                            }}
                          >
                            {["role", "tiAppearances"].map((meta) => (
                              <MenuItem key={meta} value={meta}>
                                <Checkbox checked={metadataFilterName.indexOf(meta) > -1} />
                                <ListItemText primary={meta} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ) : null}
                </div>

                <Button variant="outlined" sx={{ m: 2 }} onClick={() => setCreatePlayerModalOpen(true)}>
                  Create Player
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
        )}
        <div className="grid gap-4 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5">
          {filteredPlayers &&
            filteredPlayers
              .slice(startIndex * maxShownPlayers, startIndex * maxShownPlayers + maxShownPlayers)
              .map((player) => {
                return <PlayerCard key={player.id} inputPlayer={player} teamId={teamId} />;
              })}
        </div>
      </Stack>
      <CreatePlayerModal
        createPlayerModalIsOpen={createPlayerModalOpen}
        handleClose={() => setCreatePlayerModalOpen(false)}
      />
      <div className="flex w-full items-center justify-between pt-4">
        <Pagination
          count={Math.ceil(filteredPlayers.length / maxShownPlayers)}
          shape="rounded"
          page={index}
          onChange={(_, value) => setIndex(value)}
        />
        {filteredPlayers && maxShownPlayers && filteredPlayers.length > maxShownPlayers && (
          <Typography variant="caption">
            Showing {startIndex * maxShownPlayers + 1} -{" "}
            {Math.min(startIndex * maxShownPlayers + maxShownPlayers, filteredPlayers.length)} of{" "}
            {filteredPlayers.length}
          </Typography>
        )}
      </div>
    </>
  );
};
