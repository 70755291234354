import { Button, TableCell } from "@mui/material";

interface IsCorrectCellProps {
  isCorrect?: boolean;
  onSetCorrectAnswer: () => void;
  onSetIncorrectAnswer: () => void;
  isWaiting: boolean;
}

export default function IsCorrectCell({
  isCorrect,
  onSetCorrectAnswer,
  onSetIncorrectAnswer,
  isWaiting,
}: IsCorrectCellProps) {
  return (
    <TableCell>
      <>
        {isCorrect !== false && (
          <Button
            onClick={onSetIncorrectAnswer}
            disabled={isWaiting}
            color="secondary"
          >
            Set Incorrect
          </Button>
        )}
        {isCorrect !== true && (
          <Button
            onClick={onSetCorrectAnswer}
            disabled={isWaiting}
            color="success"
          >
            Set Correct
          </Button>
        )}
      </>
    </TableCell>
  );
}
