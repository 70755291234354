import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postCsQuizQuestion } from ".";
import { queryKeys } from "../shared/queryKeys";
import { createUploadLink } from "../../assets";
import axios from "axios";

const mutationFn = async (params: { imageFile?: File; text: string; answers: string[]; correctAnswer: string }) => {
  const id = crypto.randomUUID();
  let image: string | undefined;

  if (params.imageFile) {
    //  Get upload URL
    const folder = "cs-quiz/questions";
    const data = await createUploadLink(folder, id);

    await axios.put(data.uploadUrl, params.imageFile, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": params.imageFile.type,
      },
    });
    image = `${folder}/${id}`;
  }
  await postCsQuizQuestion({
    ...params,
    id,
    image,
  });
};

export const usePostCsQuizQuestion = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.questions());
    },
  });
};
