import { blastApiClient } from "@/api/clients/blastApiClient";
import { Schedule } from "../types/schedule";

export const updateRlcsSchedule = async ({
  scheduleId,
  payload,
}: {
  scheduleId: string;
  payload: Partial<Omit<Schedule, "id" | "createdAt" | "updatedAt">>;
}) => {
  const { data } = await blastApiClient.patch<unknown>(`/v1/rlcs-statistics/schedules/${scheduleId}`, payload);

  return data;
};
