import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createPlayer } from "../../../../api/tournaments";
import { Player } from "../../../../api/tournaments/schemas/players";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

export default function useCreatePlayer({
  onSuccess,
  onError,
}: {
  onSuccess: (data: Player) => void;
  onError: () => void;
}) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["player"],
    mutationFn: async (
      properties: Pick<
        Player,
        "nickname" | "firstName" | "lastName" | "nationality" | "dateOfBirth" | "ingameId" | "gameId"
      >,
    ): Promise<Player> => {
      alert.showInfoAlert("Creating player...");
      const newPlayer = await createPlayer({ ...properties });
      if (newPlayer) {
        await queryClient.refetchQueries(["players"]);
      }
      return newPlayer;
    },
    onSuccess,
    onError,
  });
}
