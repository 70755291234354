import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  postMatchTeam,
  postMatch,
  matchAutoProgress,
} from "../../../../api/community-tournaments";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import {
  EditCommunityMatch,
  Match,
} from "../../../../api/community-tournaments/schemas/matches";

export default function useCreateMatch({
  tournamentId,
  onSuccess,
  onError,
}: {
  tournamentId?: string;
  onSuccess: () => void;
  onError: () => void;
}) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["match"],
    mutationFn: async ({
      match,
    }: {
      gameId?: string;
      match?: EditCommunityMatch;
    }): Promise<Match | undefined> => {
      let matchResponse: Match | undefined = undefined;
      if (match) {
        const teamA = match.teams.find((team) => team?.teamIndex === 10);
        const teamB = match.teams.find((team) => team?.teamIndex === 20);
        try {
          matchResponse = await postMatch({
            name: match.name,
            index: match.index,
            scheduledAt: match.scheduledAt,
            type: match.type,
            stageId: match.stageId,
          });
        } catch (error) {
          alert.showFailureAlert("Error while creating match data");
          throw error;
        }

        //handle team updated
        for (const team of [teamA, teamB]) {
          try {
            if (team && team.team && matchResponse) {
              await postMatchTeam({
                matchId: matchResponse.id,
                teamId: team.team.id,
                score: team.score || 0,
                index: team.teamIndex,
              });
            }
          } catch (error) {
            alert.showFailureAlert(
              "Error while adding team data, check console for more info",
            );
            throw error;
          }
        }

        try {
          await matchAutoProgress({ matchId: matchResponse.id });
        } catch (error) {
          alert.showInfoAlert(
            "Error while auto progressing match, check console for more info",
          );
          // eslint-disable-next-line no-console
          console.log(error);
        }

        //refetch matche data with updated data
        await queryClient.invalidateQueries(["matches", tournamentId]);
        await queryClient.refetchQueries(["matches", tournamentId]);
      }
      return matchResponse;
    },
    onSuccess,
    onError,
  });
}
