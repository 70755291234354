import { z } from "zod";

export const EventSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  twitchGameId: z.string().optional(),
  gameName: z.string().optional(),
  lastGeneratedReport: z.string().optional(),
  keywords: z.string().array().default([]),
  tournamentId: z.string().optional(),
  circuitId: z.string().optional(),
  channels: z
    .object({
      id: z.string(),
      isPrimary: z.boolean().optional(),
      scoutedAt: z.string().optional(),
      // exclude this channel's data from reports
      excluded: z.boolean().default(false),
    })
    .array(),
});

export type Event = z.infer<typeof EventSchema>;

export const UpdateEventParametersSchema = EventSchema.omit({ id: true }).partial().extend({
  gameName: z.string().nullish(), // "null" to remove
  twitchGameId: z.string().nullish(), // "null" to remove
  circuitId: z.string().nullish(), // "null" to remove
  tournamentId: z.string().nullish(), // "null" to remove
});

export type UpdateEventParameters = z.infer<typeof UpdateEventParametersSchema>;
