import { Link } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Downloading, Delete, Folder, Refresh } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useFetchEmulationTemplates } from "./hooks/useFetchEmulationTemplates";
import { useStartEmulationTask } from "./hooks/useStartEmulationTask";
import { useFetchEmulationTasks } from "./hooks/useFetchEmulationTasks";
import { getEmulationTemplates } from "../../../api/emulations";
import { useStopEmulationTask } from "./hooks/useStopEmulationTask";
import { Info } from "@mui/icons-material";
import { HowToAddTemplateModal } from "./components/HowToAddTemplateModal";

const numberToOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const EmulationTasks = () => {
  const [showHowToAddTemplateModal, setShowHowToAddTemplateModal] =
    useState(false);
  const {
    data: emulationTemplates,
    isFetching: isFetchingEmulationTemplates,
    refetch: refetchEmulationTemplates,
  } = useFetchEmulationTemplates();

  const {
    data: emulationTasks,
    isFetching: isFetchingEmulationTasks,
    refetch: refetchEmulationTasks,
  } = useFetchEmulationTasks();

  const {
    mutate: startEmulationTask,
    isLoading: isStartingEmulationTask,
    isSuccess: isStartingEmulationTaskSuccessful,
    isError: isStartingEmulationTaskError,
  } = useStartEmulationTask();

  const {
    mutate: stopEmulationTask,
    isLoading: isStoppingEmulationTask,
    isSuccess: isStoppingEmulationTaskSuccessful,
    isError: isStoppingEmulationTaskError,
  } = useStopEmulationTask();

  const [selectedTemplates, setSelectedTemplates] = useState<
    Array<Awaited<ReturnType<typeof getEmulationTemplates>>>[0]
  >([]);

  const [taskName, setTaskName] = useState<string>("");
  const handleTaskNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value.trim();
      setTaskName(newValue);
    },
    [setTaskName],
  );

  const [provider, setProvider] = useState<string>("aws");
  const handleProviderChange = useCallback(
    (e: SelectChangeEvent) => {
      if (["gcp", "aws"].includes(e.target.value)) {
        setProvider(e.target.value);
      }
    },
    [setProvider],
  );

  const handleStartEmulation = useCallback(() => {
    if (selectedTemplates.length > 0 && taskName)
      startEmulationTask({
        taskName,
        templateIds: selectedTemplates.map(({ id }) => id),
        streamProvider: provider,
      });
  }, [selectedTemplates, taskName, startEmulationTask, provider]);

  const handleSelectTemplate = useCallback(
    (template: Awaited<ReturnType<typeof getEmulationTemplates>>[0]) => {
      if (selectedTemplates.find(({ id }) => template.id === id)) return;
      setSelectedTemplates((prev) => [...prev, template]);
    },
    [selectedTemplates, setSelectedTemplates],
  );

  const handleDeselectTemplate = useCallback(
    (template: Awaited<ReturnType<typeof getEmulationTemplates>>[0]) => {
      const filtered = selectedTemplates.filter(({ id }) => template.id !== id);
      setSelectedTemplates(filtered);
    },
    [selectedTemplates, setSelectedTemplates],
  );

  const [showTaskStartedAlert, setShowTaskStartedAlert] = useState(false);
  useEffect(() => {
    if (isStartingEmulationTaskSuccessful) {
      setShowTaskStartedAlert(true);
      setSelectedTemplates([]);
      setTimeout(() => {
        setShowTaskStartedAlert(false);
      }, 5000);
    }
  }, [isStartingEmulationTaskSuccessful, setShowTaskStartedAlert]);

  const [showTaskFailedAlert, setShowTaskFailedAlert] = useState(false);
  useEffect(() => {
    if (isStartingEmulationTaskError) {
      setShowTaskFailedAlert(true);
      setTimeout(() => {
        setShowTaskFailedAlert(false);
      }, 5000);
    }
  }, [isStartingEmulationTaskError, setShowTaskFailedAlert]);

  const [showTaskStoppedAlert, setShowTaskStoppedAlert] = useState(false);
  useEffect(() => {
    if (isStoppingEmulationTaskSuccessful) {
      setShowTaskStoppedAlert(true);
      setTimeout(() => {
        setShowTaskStoppedAlert(false);
      }, 5000);
    }
  }, [isStoppingEmulationTaskSuccessful, setShowTaskStoppedAlert]);

  const [showTaskStopFailedAlert, setShowTaskStopFailedAlert] = useState(false);
  useEffect(() => {
    if (isStoppingEmulationTaskError) {
      setShowTaskStopFailedAlert(true);
      setTimeout(() => {
        setShowTaskStopFailedAlert(false);
      }, 5000);
    }
  }, [isStoppingEmulationTaskError, setShowTaskStopFailedAlert]);

  return (
    <>
      <div className="flex flex-col items-start w-full">
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{ margin: "2rem 1rem 1rem 2rem" }}
        >
          <Link color="inherit" to="/">
            Home
          </Link>
          <span>Emulations</span>
        </Breadcrumbs>

        <div className="p-8 w-full max-w-8xl">
          <Box marginBottom={2}>
            <div className="flex justify-between">
              <Typography variant="h4" marginBottom={2}>
                Emulation Tasks
              </Typography>

              <Button
                className="gap-2"
                onClick={() => setShowHowToAddTemplateModal(true)}
              >
                <Info />
                How To Add A New Template
              </Button>
            </div>
            <Typography variant="h5" marginBottom={2}>
              Match{" "}
              {
                <IconButton
                  title="Refetch templates"
                  onClick={() => void refetchEmulationTemplates()}
                >
                  <Refresh />
                </IconButton>
              }
              {isFetchingEmulationTemplates && (
                <>
                  {" "}
                  <Downloading />
                </>
              )}
            </Typography>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Match Template</TableCell>
                    <TableCell>Teams</TableCell>
                    <TableCell>Match Type</TableCell>
                    <TableCell>Maps</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emulationTemplates?.map((template) => (
                    <TableRow
                      key={template.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {template.id}
                      </TableCell>
                      <TableCell>{template.teams.join(" v ")}</TableCell>
                      <TableCell>{template.matchType}</TableCell>
                      <TableCell>
                        {template.maps
                          .sort(({ orderIndex }) => orderIndex)
                          .map((map) => map.name)
                          .join(", ")}
                      </TableCell>
                      <TableCell>
                        {selectedTemplates.find(
                          ({ id }) => template.id === id,
                        ) ? (
                          <Button
                            onClick={() => {
                              handleDeselectTemplate(template);
                            }}
                          >
                            Remove from Task
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              handleSelectTemplate(template);
                            }}
                          >
                            Add to Task
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {selectedTemplates.length > 0 && (
              <Box marginTop={2}>
                <Card>
                  <CardContent>
                    <List dense>
                      {selectedTemplates.map((template, index) => (
                        <ListItem
                          key={template.id}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => {
                                handleDeselectTemplate(template);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          }
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <Folder />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${numberToOrdinal(index + 1)} Template: ${
                              template.id
                            }`}
                            secondary={template.teams.join(" v ")}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <Stack spacing={2}>
                      <InputLabel id="task-name-label">
                        Emulation Task Name
                      </InputLabel>
                      <TextField
                        value={taskName}
                        onChange={handleTaskNameChange}
                        placeholder="Enter a name for your emulation task"
                        className="flex-[3]"
                        size="small"
                      />
                      <InputLabel id="provider-name-label">Provider</InputLabel>
                      <Select
                        labelId="provider-name-label"
                        value={provider}
                        onChange={handleProviderChange}
                      >
                        <MenuItem value="gcp">GCP</MenuItem>
                        <MenuItem value="aws">AWS</MenuItem>
                      </Select>
                      <Button
                        onClick={handleStartEmulation}
                        variant="outlined"
                        disabled={isStartingEmulationTask || !taskName}
                      >
                        Start Emulation
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            )}
          </Box>

          <Box marginBottom={2}>
            <Typography variant="h5">
              Today&apos;s Tasks{" "}
              {
                <IconButton
                  title="Refetch tasks"
                  onClick={() => void refetchEmulationTasks()}
                >
                  <Refresh />
                </IconButton>
              }
              {isFetchingEmulationTasks && (
                <>
                  {" "}
                  <Downloading />
                </>
              )}
            </Typography>
            <div className="mb-4 font-style-b2-body-copy text-white/70 max-w-xl">
              Spinning up a new emulation task can take a few minutes. If you
              have just started a new task, and got the success message, please
              wait a few minutes before trying again.
            </div>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Task Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Match IDs</TableCell>
                    <TableCell>Task Created</TableCell>
                    <TableCell>Task Status</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emulationTasks?.map((task) => (
                    <TableRow
                      key={task.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {task.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {task.taskName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {task.matches.map((match) => (
                          <div key={match.matchId}>{match.matchId}</div>
                        ))}
                      </TableCell>
                      <TableCell title={task.startedAt}>
                        {DateTime.fromISO(task.startedAt).toRelative()}
                      </TableCell>
                      <TableCell>{task.status}</TableCell>
                      <TableCell>
                        {task.status === "RUNNING" && (
                          <Button onClick={() => stopEmulationTask(task.id)}>
                            Stop
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>

      <Snackbar
        open={isStartingEmulationTask}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="info" variant="filled">
          Starting emulation task...
        </Alert>
      </Snackbar>
      <Snackbar
        open={showTaskStartedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" variant="filled">
          Emulation task started successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={showTaskFailedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error" variant="filled">
          Emulation task failed to start
        </Alert>
      </Snackbar>

      <Snackbar
        open={isStoppingEmulationTask}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="info" variant="filled">
          Stopping emulation task...
        </Alert>
      </Snackbar>
      <Snackbar
        open={showTaskStoppedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="success" variant="filled">
          Emulation task stopped successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={showTaskStopFailedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error" variant="filled">
          Emulation task failed to stop
        </Alert>
      </Snackbar>
      <HowToAddTemplateModal
        open={showHowToAddTemplateModal}
        onClose={() => setShowHowToAddTemplateModal(false)}
      />
    </>
  );
};
