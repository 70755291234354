import flagsmith from "flagsmith";
import { useQuery } from "@tanstack/react-query";
import { createContext, useEffect, useMemo, useState } from "react";
import { PostLoginPayload } from "../../api/auth";
import { getUser, getUserPermissions } from "../../api/users";
import AuthService from "./services/AuthService";
import UserProfile from "@/types/users";

export interface AuthProviderValue {
  authService: AuthService;
  isAuthenticated: boolean;
  login: (credentials: PostLoginPayload) => Promise<void>;
  logout: () => Promise<void>;
  userId?: string;
  setUserId: (userId: undefined | string) => void;
  hasAccess: boolean;
  permissions: string[];
  user: UserProfile | null;
}

export const AuthContext = createContext<AuthProviderValue | null>(null);

const authService = new AuthService();
export const userQueryKeys = {
  user: (userId?: string) => ["user", userId],
  userPermissions: (userId?: string) => ["userPermissions", userId],
};
export default function AuthProvider({ children }: { children?: React.ReactNode }) {
  const [userId, setUserId] = useState<string | undefined>(authService.userId);
  const { data: userPermissions } = useQuery({
    queryKey: userQueryKeys.userPermissions(userId),
    queryFn: async () => {
      if (userId) {
        return await getUserPermissions();
      } else {
        return [];
      }
    },
    initialData: [],
  });

  const { data: user } = useQuery({
    queryKey: userQueryKeys.user(userId),
    queryFn: async () => {
      if (userId) {
        return await getUser(userId);
      } else {
        return null;
      }
    },
  });

  const providerValue = useMemo<AuthProviderValue>(
    () => ({
      authService,
      isAuthenticated: !!userId,
      userId,
      setUserId,
      hasAccess: !!userId && userPermissions?.includes("BACKOFFICE_ACCESS"),
      permissions: userPermissions,
      user: user ?? null,
      login: (credentials) =>
        authService.login(credentials).then(() => {
          setUserId(authService.userId);
        }),
      logout: () => authService.logout().finally(() => setUserId(undefined)),
    }),
    [user, userId, userPermissions],
  );

  useEffect(() => {
    if (userId) {
      void flagsmith.identify(userId);
    } else {
      void flagsmith.logout();
    }
  }, [userId]);

  useEffect(() => {
    if (authService.hasAccessToken) {
      providerValue.setUserId(authService.userId);
    } else if (authService.hasRefreshToken) {
      void authService.updateSession().then(() => {
        providerValue.setUserId(authService.userId);
      });
    }
  }, [providerValue]);

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}
