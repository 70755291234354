import { Alert, Box, Breadcrumbs, Button, Typography } from "@mui/material";
import PageContainer from "../../../components/Layout/PageContainer";
import usePointsContext from "../context/PointsConsumer";
import PointsProvider from "../context/PointsProvider";
import { ProductsTable } from "./components/ProductsTable";
import { CreateEditProductModal } from "./components/CreateEditProductModal";
import { useGetPointsProducts } from "../../../hooks/useGetPointsProducts";
import { PointsProduct } from "../../../api/points/schemas";
import { useState } from "react";
import { RaffleModal } from "./components/RaffleModal";

const ProductsContents = () => {
  const { editCreateModalMethods } = usePointsContext();
  const { data: products } = useGetPointsProducts();
  const [raffleProduct, setRaffleProduct] = useState<PointsProduct | null>(null);

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.secondary" fontWeight="bold">
          Points
        </Typography>
        <Typography color="text.primary" fontWeight="bold">
          Products
        </Typography>
      </Breadcrumbs>

      <Box sx={{ display: "flex", gap: "15rem", alignItems: "center" }}>
        <h2>Products</h2>
        <Button sx={{ fontWeight: "bold" }} variant="contained" onClick={editCreateModalMethods.createNew}>
          Create Product
        </Button>
      </Box>

      <Alert severity="warning">
        If data or image hasn&apos;t updated, please to clear browser cache, and refresh the page.
      </Alert>

      <ProductsTable products={products?.products || []} onRaffleClick={(product) => setRaffleProduct(product)} />
      {raffleProduct && (
        <RaffleModal isOpen={!!raffleProduct} onClose={() => setRaffleProduct(null)} product={raffleProduct} />
      )}
      <CreateEditProductModal />
    </PageContainer>
  );
};

export const Products = () => {
  return (
    <PointsProvider>
      <ProductsContents />
    </PointsProvider>
  );
};
