import { useQuery } from "@tanstack/react-query";
import { getClipsCuratorEvents } from "../../../api/video";

interface UseGetClipsCuratorEventsOptions {
  category: string;
  id: string;
}
const useGetClipsCuratorEvents = (options: UseGetClipsCuratorEventsOptions) => {
  return useQuery({
    queryKey: ["clips-curator", options],
    queryFn: () => getClipsCuratorEvents(options),
    initialData: [],
    retry: false,
    enabled: options.category !== "" && options.id !== "",
  });
};

export default useGetClipsCuratorEvents;
