import { useMutation } from "@tanstack/react-query";
import { deleteStream } from "../../../../api/streams";

const useDeleteStream = (streamUid: string, onStreamDeleted: () => void) =>
  useMutation({
    mutationKey: ["streams", streamUid],
    onSuccess: () => onStreamDeleted(),
    mutationFn: () => deleteStream(streamUid),
  });

export default useDeleteStream;
