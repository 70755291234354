import { useQuery } from "@tanstack/react-query";

import { getStreamById } from "../api/streams";

interface useFetchStreamByIdOptions {
  id: string;
  enabled?: boolean;
}

const useFetchStreamById = ({ id, enabled }: useFetchStreamByIdOptions) =>
  useQuery({
    queryKey: ["streams", id],
    queryFn: () => getStreamById(id),
    enabled,
  });

export default useFetchStreamById;
