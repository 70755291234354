import { useQuery } from "@tanstack/react-query";
import { getTournamentById } from "../../../api/tournaments";
import { queryKeys } from "../../../api/tournaments/shared/queryKeys";

export default function useFetchTournamentById(tournamentId?: string) {
  return useQuery({
    queryKey: queryKeys.tournamentById(tournamentId),
    queryFn: tournamentId ? () => getTournamentById(tournamentId) : undefined,
    enabled: !!tournamentId,
  });
}
