import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postAssignReward } from "../index";
import { queryKeys } from "../shared/queryKeys";

export const usePostAssignReward = (options: { onSuccess?: () => void } = {}) => {
  const { onSuccess } = options;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postAssignReward,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.purchases() });
      onSuccess?.();
    },
  });
};
