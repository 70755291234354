import { z } from "zod";

export const TeamSocialLinksSchema = z.record(
  z.enum(["twitter", "facebook", "instagram", "youtube", "twitch", "discord", "website"]),
  z.object({
    url: z.string().url(),
  }),
);

export const TeamSchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
  nationality: z.string().nullable(),
  gameId: z.string(),
  socialLinks: TeamSocialLinksSchema.default({}),
  externalId: z.string().nullish(),
});
export type Team = z.infer<typeof TeamSchema>;
