import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { GameInfo, GamesInfoList } from "../../../../types/core/Games";

interface GamePickerProps {
  labelText: string;
  variant?: "outlined" | "filled" | "standard";
  onGameSelection: (selectedGameCode: string) => void;
}

export const GamePicker = ({ labelText, variant, onGameSelection }: GamePickerProps) => {
  const [game, setGame] = useState<GameInfo>();

  const games = GamesInfoList;

  return (
    <Autocomplete
      options={games}
      renderInput={(params) => <TextField {...params} label={labelText} variant={variant ?? "standard"} />}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <img src={option.icon} alt={option.label} className="mr-2 size-6 rounded-full" />
          {option.label}
        </li>
      )}
      value={game}
      onChange={(_, value) => {
        if (value) {
          setGame(value);
          onGameSelection(value.id);
        }
      }}
    />
  );
};
