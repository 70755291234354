import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import z from "zod";

// DPC Leagues
const LeagueListSchema = z.object({
  infos: z.array(
    z.object({
      name: z.string(),
      tier: z.number(),
      league_id: z.number(),
      start_timestamp: z.number(),
      end_timestamp: z.number(),
      status: z.number(),
      region: z.number(),
      total_prize_pool: z.number(),
    }),
  ),
});

export const useDpcLeagues = () => {
  return useQuery(["leagues"], async () => {
    const response = await axios.get(
      "https://www.dota2.com/webapi/IDOTA2League/GetLeagueInfoList/v001",
    );

    const data = LeagueListSchema.parse(response.data);

    return data;
  });
};
