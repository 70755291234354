import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { BroadcastAd, BroadcastAdRunRequest, BroadcastAdSchema } from "./types";

export const broadcastAdsApi = axios.create({
  baseURL: `${baseApiURL}/v1/broadcast-ads`,
});

broadcastAdsApi.interceptors.request.use(authInterceptor);

export const getBroadcastAds = async (): Promise<BroadcastAd[]> => {
  const { data } = await broadcastAdsApi.get<unknown>("");

  return BroadcastAdSchema.array().parse(data);
};

export const createBroadcastAd = async (broadcastAd: BroadcastAd) => {
  await broadcastAdsApi.post("", broadcastAd);
};

export const deleteBroadcastAd = async (adId: string) => {
  await broadcastAdsApi.delete(`/${adId}`);
};

export const updateBroadcastAd = async (broadcastAd: BroadcastAd) => {
  await broadcastAdsApi.put(`/${broadcastAd.adId}`, broadcastAd);
};

export const runBroadcastAd = async (adRunRequest: BroadcastAdRunRequest) => {
  await broadcastAdsApi.post(`/${adRunRequest.adId}/run`, adRunRequest);
};
