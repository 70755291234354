import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { PlayerHistoryWithMapCount } from "../../../../api/tournaments/schemas/players";
import { Team } from "../../../../api/tournaments/schemas/teams";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import { useState } from "react";
import useUpdateTeamPlayerStint from "../hooks/useUpdateTeamPlayerStint";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import useDeleteTeamPlayerStint from "../hooks/useDeleteTeamPlayerStint";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";

interface TeamHistoryEditModalProps {
  playerId: string;
  inputHistory: PlayerHistoryWithMapCount;
  isOpen: boolean;
  handleClose: () => void;
  teams: Team[];
}

export const TeamHistoryEditModal = ({
  playerId,
  inputHistory,
  isOpen,
  handleClose,
  teams,
}: TeamHistoryEditModalProps) => {
  const [history, setHistory] = useState<PlayerHistoryWithMapCount>(
    deepClone(inputHistory) as PlayerHistoryWithMapCount,
  );

  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

  const alert = useAlert();

  const { mutate: updateTeamHistory } = useUpdateTeamPlayerStint({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated player team history");
      handleClose();
    },
    onError: () => {
      alert.showFailureAlert("Failed to update player team history");
    },
  });

  const { mutate: deleteTeamHistory } = useDeleteTeamPlayerStint({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully deleted player team history");
      handleClose();
    },
    onError: () => {
      alert.showFailureAlert("Failed to delete player team history");
    },
  });

  const changeProperty = ({
    property,
    value,
  }: {
    property: keyof Pick<PlayerHistoryWithMapCount, "fromDate" | "role" | "teamId">;
    value: string | DateTime;
  }) => {
    setHistory((prev) => {
      return {
        ...prev,
        [property]: value,
      };
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
        <Dialog
          open={isOpen}
          onClose={() => {
            setConfirmingDelete(false);
            handleClose();
          }}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Change Team History</DialogTitle>
          <DialogContent>
            <Grid container flexDirection={"row"}>
              <Grid item xs={9}>
                <Grid container flexDirection={"column"} gap={2}>
                  <Grid item>
                    <DateField
                      variant="standard"
                      required
                      label="From Date"
                      format="dd/LL/yyyy"
                      fullWidth
                      value={DateTime.fromISO(history.fromDate)}
                      onChange={(e) => {
                        if (e) {
                          changeProperty({
                            property: "fromDate",
                            value: e,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Role *</InputLabel>
                      <Select
                        required
                        onChange={(e) => {
                          changeProperty({
                            property: "role",
                            value: e.target.value as string,
                          });
                        }}
                        value={history.role}
                      >
                        <MenuItem key={"player"} value={"player"}>
                          Player
                        </MenuItem>
                        <MenuItem key={"substitute"} value={"substitute"}>
                          Substitute
                        </MenuItem>
                        <MenuItem key={"coach"} value={"coach"}>
                          Coach
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    {teams && (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Team *</InputLabel>
                        <Select
                          required
                          onChange={(e) => {
                            changeProperty({
                              property: "teamId",
                              value: e.target.value as string,
                            });
                          }}
                          value={history.teamId}
                        >
                          <MenuItem value={"null"}>TEAM-LESS</MenuItem>
                          <Divider />
                          {teams
                            .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
                            .map((team) => (
                              <MenuItem key={team.id} value={team.id}>
                                {team.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={20}>
                    <Button
                      className="mr-7"
                      variant="contained"
                      onClick={() =>
                        updateTeamHistory({
                          playerId,
                          teamPlayerId: history.id,
                          fromDate: DateTime.fromISO(history.fromDate).toJSDate(),
                          role: history.role,
                          teamId: history.teamId,
                        })
                      }
                    >
                      Save
                    </Button>
                    {history.mapsPlayed === 0 && (
                      <Button
                        variant="contained"
                        className="bg-red-300 hover:bg-red-100"
                        onClick={() => setConfirmingDelete(true)}
                      >
                        Delete
                      </Button>
                    )}
                    {confirmingDelete && (
                      <Button
                        variant="contained"
                        className="mt-3 bg-red-500 hover:bg-red-300"
                        onClick={() =>
                          deleteTeamHistory({
                            playerId: playerId,
                            teamPlayerId: history.id,
                          })
                        }
                      >
                        CONFIRM DELETE
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </LocalizationProvider>
    </>
  );
};
