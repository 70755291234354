import { useQuery } from "@tanstack/react-query";
import { getDeliveredNotifications } from "../../../api/notifications";

const useFetchDeliveredNotifications = () => {
  return useQuery({
    queryKey: ["deliveredNotifications"],
    queryFn: getDeliveredNotifications,
    initialData: [],
  });
};

export default useFetchDeliveredNotifications;
