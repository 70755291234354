import { FC, useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Refresh from "@mui/icons-material/Refresh";
import ConfirmModal from "../../../../components/ConfirmModal";
import useStartStream from "../hooks/useStartStream";
import useStopStream from "../hooks/useStopStream";
import { EditStreamDialog } from "../../../Streams/components/EditStreamDialog";
import { VideoPlayerModule } from "../../../../remoteComponents/VideoPlayerModule";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import classNames from "classnames";
import { Stream } from "../../../../types/Streams";

interface VideoStreamDetailsProps {
  videoStreamId: string;
  stream?: Stream;
  isStreamFetching: boolean;
  refetchStream: () => Promise<unknown>;
  isAdditionalDataVisible?: boolean;
}

const VideoStreamDetails: FC<VideoStreamDetailsProps> = ({
  videoStreamId,
  stream,
  isStreamFetching,
  refetchStream,
  isAdditionalDataVisible = false,
}) => {
  const [editStreamDialogOpened, setEditStreamDialogOpened] = useState(false);
  const [confirmVideoModalOpened, setConfirmVideoModalOpened] = useState(false);

  const { showSuccessAlert, showFailureAlert } = useAlert();

  const { mutate: startStreamAction, isLoading: streamStarting, data: startedStream } = useStartStream(videoStreamId);

  const { mutate: stopStreamAction, isLoading: streamStopping, data: stoppedStream } = useStopStream(videoStreamId);

  const onEditStreamClose = (successful: boolean | undefined) => {
    setEditStreamDialogOpened(false);
    if (successful) {
      void refetchStream();
      showSuccessAlert("Stream updated successfully.");
    } else {
      showFailureAlert("Failed to update stream.");
    }
  };

  const startStream = () => {
    startStreamAction();
    setConfirmVideoModalOpened(false);
  };

  const stopStream = () => {
    stopStreamAction();
    setConfirmVideoModalOpened(false);
  };

  useEffect(() => {
    if (!streamStarting && startedStream) {
      void refetchStream();
    }
  }, [refetchStream, streamStarting, startedStream]);

  useEffect(() => {
    if (!streamStopping && stoppedStream) {
      void refetchStream();
    }
  }, [refetchStream, streamStopping, stoppedStream]);

  return (
    <Grid item xs={6}>
      {stream && (
        <>
          <Card variant="outlined">
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Typography variant="h4">Stream ({stream.state.toLowerCase()})</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Button
                    type="button"
                    variant="contained"
                    disabled={streamStarting || streamStopping}
                    onClick={() => setConfirmVideoModalOpened(true)}
                  >
                    {stream.state !== "STOPPED" ? "Stop" : "Start"} Video Stream
                  </Button>
                  <Button
                    className="mx-4"
                    startIcon={
                      <Refresh
                        className={classNames({
                          "animate-spin": isStreamFetching,
                        })}
                      />
                    }
                    onClick={() => void refetchStream()}
                  >
                    Refresh
                  </Button>
                  <Tooltip placement="top" title="Edit Stream">
                    <EditIcon
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => setEditStreamDialogOpened(true)}
                    />
                  </Tooltip>
                </Box>
              </Box>

              <div className="flex w-full flex-row content-end items-center">
                <ConfirmModal
                  open={confirmVideoModalOpened}
                  onOpenChange={() => setConfirmVideoModalOpened(false)}
                  onSubmit={stream.state !== "STOPPED" ? stopStream : startStream}
                >
                  <Typography variant="subtitle1" textAlign="center">
                    Are you sure you want to {stream.state !== "STOPPED" ? "Stop" : "Start"}
                    Video Stream
                  </Typography>
                </ConfirmModal>
              </div>

              {stream ? (
                <VideoPlayerModule src={stream.outputEndpoint} controls autoPlay muted />
              ) : (
                <p>Could not find broadcast video source.</p>
              )}

              <Table>
                <TableBody>
                  {isAdditionalDataVisible && (
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>{stream.title}</TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell>Start Time</TableCell>
                    <TableCell>
                      {stream.startTime ? DateTime.fromISO(stream.startTime).toFormat("dd/MM/yyyy HH:mm") : "N/A"}
                    </TableCell>
                  </TableRow>

                  {isAdditionalDataVisible && (
                    <TableRow>
                      <TableCell>Created Time</TableCell>
                      <TableCell>{DateTime.fromISO(stream.createdAt).toFormat("dd/MM/yyyy HH:mm")}</TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell>Offset</TableCell>
                    <TableCell>{stream.timelineOffset} seconds</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Resolution</TableCell>
                    <TableCell>{stream.highestProfile}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Input tier</TableCell>
                    <TableCell>{stream.inputTier}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Provider</TableCell>
                    <TableCell>{stream.provider}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Ingest Endpoint</TableCell>
                    <TableCell>{stream.ingestEndpoint}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Output Endpoint</TableCell>
                    <TableCell>{stream.outputEndpoint}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          {stream && editStreamDialogOpened && (
            <EditStreamDialog
              key="edit-stream-dialog"
              open={editStreamDialogOpened}
              closeDialog={(successful) => onEditStreamClose(successful)}
              stream={stream}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default VideoStreamDetails;
