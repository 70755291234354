import { ElementRef, SyntheticEvent, useRef, useState } from "react";
import { Breadcrumbs, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PageContainer from "../../../components/Layout/PageContainer";
import { useGrantPoints } from "./hooks/useGrantPoints";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";

export const GrantDashboard = () => {
  const { showFailureAlert } = useAlert();
  const [isGranting, setIsGranting] = useState(false);
  const [corruptedUsers, setCorruptedUsers] = useState<Array<string>>([]);
  const { mutateAsync: grantPointsAction } = useGrantPoints();
  const formRef = useRef<ElementRef<"form">>(null);

  const grantPoints = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!formRef.current) {
      return;
    }

    const data = new FormData(formRef.current);
    const [userIds, purpose, amount] = [
      [
        ...new Set(
          String(data.get("userIds"))
            .split(",")
            .map((value) => value.trim())
            .filter(Boolean),
        ),
      ],
      String(data.get("purpose")).trim(),
      Number(data.get("amount")),
    ];

    if (!userIds.length) {
      showFailureAlert("Users list not provided or contains invalid values");
      return;
    }

    const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    const invalidUserId = userIds.find(
      (userId) => userId.match(uuidPattern) === null && userId.match(/google|apple/i) === null,
    );
    if (invalidUserId) {
      showFailureAlert(`Invalid user id was provided. User id "${invalidUserId}" is invalid`);
      return;
    }

    if (!purpose) {
      showFailureAlert("Message for users is required");
      return;
    }

    if (!Number.isInteger(amount) || amount < 1) {
      showFailureAlert("Amount of points should be positive integer");
      return;
    }

    setIsGranting(true);

    const corruptedUsers: Array<string> = [];
    try {
      const chunkSize = 500;
      for (let i = 0; i < userIds.length; i += chunkSize) {
        const chunk = userIds.slice(i, i + chunkSize);
        const { hasErrors, error } = await grantPointsAction({
          userIds: chunk,
          purpose,
          amount,
        });
        if (hasErrors && error.userIds?.length) {
          corruptedUsers.push(...error.userIds);
        }
      }

      setCorruptedUsers(corruptedUsers);
      if (corruptedUsers.length) {
        formRef.current.reset();
        showFailureAlert("Some users didn't receive points. Check the list below");
      }
    } finally {
      setIsGranting(false);
    }
  };

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.secondary" fontWeight="bold">
          Points
        </Typography>
        <Typography color="text.primary" fontWeight="bold">
          Grant Dashboard
        </Typography>
      </Breadcrumbs>
      <form ref={formRef} className="my-4 flex gap-2" onSubmit={(e) => void grantPoints(e)}>
        <div className="flex-1">
          <TextField
            placeholder="Comma separated user UUIDS"
            className="w-full outline-white"
            multiline
            minRows={6}
            required
            name="userIds"
          />
          {!!corruptedUsers.length && (
            <div className="my-6">
              <Typography className="mb-4" variant="h6">
                List of user ids who didn&apos;t receive points
              </Typography>
              {corruptedUsers.map((userId) => (
                <div className="mb-1" key={userId}>
                  {userId}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex-[2]">
          <TextField
            className="mb-4 block w-80 outline-white"
            label="Message"
            variant="outlined"
            fullWidth
            required
            name="purpose"
          />
          <TextField
            className="mb-4 block w-80 outline-white"
            label="Amount of Points to Grant"
            variant="outlined"
            type="number"
            fullWidth
            required
            name="amount"
          />
          <LoadingButton loading={isGranting} type="submit" variant="contained">
            Grant
          </LoadingButton>
        </div>
      </form>
    </PageContainer>
  );
};
