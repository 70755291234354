import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postIncorrectAnswer } from "../../../api/pickems";

const usePostIncorrectAnswer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["incorrectAnswers"],
    mutationFn: postIncorrectAnswer,
    onSuccess: () => {
      void queryClient.invalidateQueries(["correctAnswers"]);
    },
  });
};

export default usePostIncorrectAnswer;
