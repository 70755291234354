import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import { type AdditionalVideo } from "../../../../../../types/Broadcasts";
import { useEffect, useState } from "react";

export interface AddVideoModalResult {
  name: string;
  offset: number;
}

interface AddVideoModalProps {
  additionalVideo: AdditionalVideo | undefined;
  setSelectedVideo: (videoId: string | null) => void;
  onSubmit: (result: AddVideoModalResult) => unknown;
  closeModal: () => unknown;
}

export function AddVideoModal({
  additionalVideo,
  setSelectedVideo,
  onSubmit,
  closeModal,
}: AddVideoModalProps) {
  const [name, setName] = useState("");
  const [offset, setOffset] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (name.length === 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [name, setIsValid]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit({ name, offset });
  };

  useEffect(() => {
    if (additionalVideo) {
      setName(additionalVideo.name || "");
      setOffset(additionalVideo.offset || 0);
    } else {
      setName("");
      setOffset(0);
    }
  }, [additionalVideo]);

  return (
    <Dialog open={true} onClose={closeModal} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        {additionalVideo ? "Update" : "Add"} Additional Video
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          rowGap="15px"
        >
          <FormControl fullWidth variant="filled">
            <TextField
              value={name}
              variant="outlined"
              label="Video Name"
              onChange={(event) => setName(event.target.value)}
            />
          </FormControl>
          <FormControl fullWidth variant="filled">
            <TextField
              value={offset}
              type="number"
              variant="outlined"
              label="Offset (seconds)"
              onChange={(event) => setOffset(Number(event.target.value))}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          variant="outlined"
          onClick={() => {
            setSelectedVideo(null);
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit}
        >
          {additionalVideo ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
