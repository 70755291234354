import axios from "axios";
import { postCreateUploadLink } from "../../../../api/tournaments";

const BLACKLIST = [/^bind/];

export const uploadPlayerConfig = async ({
  file,
  playerId,
  fileName,
}: {
  file: File;
  playerId: string;
  fileName: "config.cfg" | "videodefaults.txt" | "video.txt";
}): Promise<boolean> => {
  let uploadUrl = "";

  if (fileName === "config.cfg") {
    try {
      const text = await file.text();
      const filteredText = text
        .split("\n")
        .filter((line) => !BLACKLIST.some((pattern) => pattern.test(line)))
        .join("\n");
      file = new File([filteredText], fileName, { type: file.type });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error processing config file: ", error);
      return false;
    }
  }

  try {
    const createdUploadUrl = await postCreateUploadLink(`player-configs/${playerId}`, fileName);
    uploadUrl = createdUploadUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error creating player config file upload link: ", error);
    return false;
  }

  try {
    await axios.put(uploadUrl, file, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Length": file.size,
        "Content-Type": file.type,
      },
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error uploading player config file: ", error);
    return false;
  }
};
