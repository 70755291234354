import { Button } from "@/components/ui/button";
import Delete from "@mui/icons-material/Delete";
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import classNames from "classnames";
import { Plus } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchUserProfile from "../../../../hooks/useFetchUserProfile";
import Emoji from "../../../../types/users/emojis";
import useFetchEmojis from "../../hooks/useFetchEmojis";
import AddEmojiForm from "../AddEmojiForm";
import DeleteEmojiForm from "../DeleteEmojiForm";

export default function EditUserEmojis() {
  const { id } = useParams();
  const { data: userProfile, refetch } = useFetchUserProfile(id);
  const { data: emojis } = useFetchEmojis();
  const [deleteHover, setDeleteHover] = useState<string | null>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteEmoji, setDeleteEmoji] = useState<Emoji | null>(null);

  const [isEditUserEmojisModalOpen, setIsEditUserEmojisModalOpen] = useState(false);
  if (emojis === undefined) {
    return null;
  }

  const filteredEmojis = emojis.filter((emoji) => {
    return emoji.isUnlockOnly && userProfile?.emojis?.includes(emoji.id);
  });

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <div>
        <Box
          fontStyle={{
            display: "flex",
            paddingTop: "0.5rem",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <h2 className="text-sm">Emojis</h2>
          <Button
            className="text-emerald-500"
            size="icon"
            variant="ghost"
            onClick={() => setIsEditUserEmojisModalOpen(true)}
          >
            <Plus />
          </Button>
          <Grid
            item
            xs={3}
            style={{
              color: "gray",
              fontSize: "0.7rem",
              paddingLeft: "0.5rem",
            }}
          >
            <p>{"Only user's emojis are shown."}</p>
          </Grid>
        </Box>
        {filteredEmojis.length === 0 ? (
          <Grid>
            <Grid
              item
              xs={3}
              style={{
                color: "gray",
              }}
            >
              <p>{"User has no emojis."}</p>
            </Grid>
          </Grid>
        ) : (
          <Grid container width={"500px"} rowSpacing={0.5} columns={{ xs: 4, sm: 8, md: 9 }}>
            {filteredEmojis.map((emoji) => {
              return (
                <Grid key={emoji.id} width="2rem" item xs={1}>
                  <div
                    style={{
                      backgroundImage: `url(${emoji.url})`,
                    }}
                    className={classNames("h-12 w-12 border border-solid bg-cover bg-center", {
                      "border-green-500/60": userProfile.emojis?.includes(emoji.id) || false,
                      "border-gray-500/60": !userProfile.emojis?.includes(emoji.id) || false,
                    })}
                    onMouseEnter={() => {
                      setDeleteHover(emoji.id);
                    }}
                    onMouseLeave={() => {
                      setDeleteHover(null);
                    }}
                    title={emoji.name}
                  >
                    {deleteHover === emoji.id ? (
                      <div
                        className="pointer-events-auto size-full cursor-pointer bg-black/80"
                        onClick={() => {
                          setShowDelete(true);
                          setDeleteEmoji(emoji);
                        }}
                      >
                        <IconButton
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Delete
                            style={{
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
      </div>
      {isEditUserEmojisModalOpen && (
        <AddEmojiForm
          open={isEditUserEmojisModalOpen}
          onClose={(args) => {
            if (args?.refresh) {
              void refetch();
            }

            setIsEditUserEmojisModalOpen(false);
          }}
        />
      )}
      {showDelete && deleteEmoji && (
        <DeleteEmojiForm
          open={showDelete}
          emoji={deleteEmoji}
          onClose={(args) => {
            if (args?.refresh) {
              void refetch();
            }

            setShowDelete(false);
          }}
        />
      )}
    </>
  );
}
