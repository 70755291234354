import { useMutation } from "@tanstack/react-query";
import { deleteTriggerPoll } from "../../../../api/triggers";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

const useDeleteTriggerPoll = (id: string) => {
  const { showFailureAlert, showSuccessAlert } = useAlert();

  return useMutation({
    mutationFn: () => deleteTriggerPoll(id),
    onSuccess() {
      showSuccessAlert("Poll deleted successfully");
    },
    onError(error) {
      if (error instanceof Error) {
        showFailureAlert(error.message);
      } else {
        showFailureAlert("Something went wrong while deleting the poll");
      }
    },
  });
};

export default useDeleteTriggerPoll;
