import { useLocation, useParams } from "react-router-dom";
import useFetchStages from "../hooks/useFetchStages";
import useFetchTournamentById from "../hooks/useFetchTournamentById";
import { Button, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { StageInfo } from "./components/StageInfo";
import { Stage, StageMetadata } from "../../../api/tournaments/schemas/stages";
import useFetchMatchesByTournamentId from "../hooks/matches/useFetchMatchesByTournamentId";
import { MatchByTournamentIdResponse, EditMatch } from "../../../api/tournaments/schemas/matches";
import { MatchModal } from "./components/MatchModal";
import useFetchTeams from "../hooks/teams/useFetchTeams";
import CreateEditStageModal from "./components/CreateEditStageModal";
import useFetchCircuitById from "../hooks/useFetchCircuitById";
import { TournamentPrizesModal } from "./components/TournamentPrizesModal";
import { Add, Edit, MilitaryTech } from "@mui/icons-material";
import CreateAndEditTournamentModal from "../Tournaments/components/CreateEditTournamentModal";

export interface StageBuilder {
  id?: string;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  format?: string;
  numberOfTeams?: number | null;
  metadata?: StageMetadata | null;
  index?: number;
}

const emptyStage: StageBuilder = {
  id: undefined,
  name: undefined,
  startDate: undefined,
  endDate: undefined,
  format: undefined,
  numberOfTeams: undefined,
  metadata: undefined,
  index: undefined,
};

const TournamentStages = () => {
  const { hash } = useLocation();
  const [isTournamentPrizeModalOpen, setIsTournamentPrizeModalOpen] = useState<boolean>(false);
  const [selectedStage, setSelectedStage] = useState<Stage | undefined>();
  const [shownMatches, setShownMatches] = useState<MatchByTournamentIdResponse[]>([]);
  const [selectMatch, setSelectedMatch] = useState<EditMatch | undefined>();
  const [stageModalStatus, setStageModalStatus] = useState<"new" | "edit" | "closed">("closed");
  const [editStage, setEditStage] = useState<StageBuilder>(emptyStage);
  const [isEditingTournament, setIsEditingTournament] = useState<boolean>(false);

  const { tournamentId } = useParams();

  const { data: tournament, isLoading: isLoadingTournament } = useFetchTournamentById(tournamentId);
  const { data: circuit } = useFetchCircuitById(tournament?.circuitId || "");
  const { data: stages, refetch: refetchStages } = useFetchStages(tournamentId);
  const { data: matches, refetch: refetchMatches } = useFetchMatchesByTournamentId(tournamentId);
  const { data: teams } = useFetchTeams(circuit?.gameId);

  useEffect(() => {
    if (matches !== undefined && selectedStage !== undefined) {
      setShownMatches(matches.filter((match) => match.stageId === selectedStage?.id));
    }
  }, [matches, selectedStage]);

  useEffect(() => {
    stages && setSelectedStage(stages[0]);
  }, [stages]);

  useEffect(() => {
    if (hash) {
      setSelectedMatch(matches?.find((match) => match.matchId === hash.replace("#", "")));
    }
  }, [hash, matches]);

  if (!tournamentId) {
    return <div>Invalid URL</div>;
  }

  return (
    <div className="flex w-full flex-col">
      <div className="p-7">
        {tournament && !isLoadingTournament ? (
          <>
            <div className="mb-4 flex items-start gap-2">
              <div className="flex flex-col gap-3">
                <div className="text-5xl">{tournament.name}</div>
                <div className="text-sm text-white/70">Tournament external Id: {tournament.externalId ?? "-"}</div>
              </div>
              <Button
                onClick={() => setIsEditingTournament(true)}
                startIcon={<Edit />}
                variant="outlined"
                className="ml-auto"
              >
                Edit Tournament details
              </Button>
              <Button
                onClick={() => {
                  setIsTournamentPrizeModalOpen(true);
                }}
                startIcon={<MilitaryTech />}
                variant="outlined"
                color="secondary"
              >
                Edit Prizes
              </Button>
              <Button
                onClick={() => {
                  setEditStage(emptyStage);
                  setStageModalStatus("new");
                }}
                startIcon={<Add />}
                variant="contained"
              >
                Add a new Stage
              </Button>
            </div>
            <Tabs
              value={selectedStage}
              onChange={(e, newValue: Stage | undefined) => {
                setSelectedStage(newValue);
              }}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {stages?.map((stage) => <Tab value={stage} key={stage.id} label={stage.name} />)}
            </Tabs>
            <StageInfo
              stage={selectedStage}
              matches={shownMatches}
              matchClick={(match: EditMatch) => {
                setSelectedMatch(match);
              }}
              editClick={(stage) => {
                setEditStage(stage);
                setStageModalStatus("edit");
              }}
              tournamentMetadata={tournament.metadata}
            />
            <CreateEditStageModal
              onChange={(stage) => setEditStage(stage)}
              onClose={() => setStageModalStatus("closed")}
              tournamentId={tournament.id}
              stage={editStage}
              stageModalStatus={stageModalStatus}
              onSubmit={() => {
                void refetchStages();
                void refetchMatches();
              }}
            />
            <TournamentPrizesModal
              isOpen={isTournamentPrizeModalOpen}
              onClose={() => {
                setIsTournamentPrizeModalOpen(false);
              }}
              gameId={circuit?.gameId}
              tournament={tournament}
            />
            {selectMatch && (
              <MatchModal
                match={selectMatch}
                handleClose={() => {
                  setSelectedMatch(undefined);
                }}
                teams={teams}
                updateMatch={(match: EditMatch) => {
                  setSelectedMatch(match);
                }}
                gameId={circuit?.gameId}
              />
            )}
          </>
        ) : (
          <div>Tournament Not Found</div>
        )}
      </div>
      {isEditingTournament && tournament && (
        <CreateAndEditTournamentModal
          isOpen={isEditingTournament}
          onClose={() => setIsEditingTournament(false)}
          editingTournamentId={tournament.id}
        />
      )}
    </div>
  );
};

export default TournamentStages;
