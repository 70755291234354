import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchPickems } from "../../../api/pickems";

const useUpdatePickems = ({
  pickemsId,
  onSuccess,
  onError,
}: {
  pickemsId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["pickems", "update", pickemsId],
    mutationFn: pickemsId ? patchPickems : undefined,
    onSuccess: () => {
      if (pickemsId) {
        void queryClient.invalidateQueries(["pickems", pickemsId]);
      }
      onSuccess?.();
    },
    onError,
  });
};

export default useUpdatePickems;
