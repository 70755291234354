import { useQuery } from "@tanstack/react-query";
import { getScheduledNotifications } from "../../../api/notifications";

const useFetchScheduledNotifications = () => {
  return useQuery({
    queryKey: ["scheduledNotifications"],
    queryFn: getScheduledNotifications,
    initialData: [],
  });
};

export default useFetchScheduledNotifications;
