import { useContext } from "react";
import { useParams } from "react-router-dom";
import { PatronContext } from "..";
import { Patron, PatronTypes } from "../types";
const patrons = ["blasttv", "apex", "fortnite"] as const;
export function usePatron() {
  const { patron } = useParams();
  const patronContext = useContext(PatronContext);
  if (!patronContext) {
    throw new Error("No patron context available. Did you forget to wrap your component in a PatronProvider?");
  }

  if (patron) {
    if (!isPatron(patron)) {
      throw new Error("Unknown patron");
    }

    if (patron !== patronContext.patron) {
      patronContext.setPatron(patron);
    }
  }

  return {
    patron: patronContext.patron,
    setPatron: patronContext.setPatron,
    patrons: [...patrons],
  };
}

function isPatron(input: string): input is Patron {
  return PatronTypes.map((s) => s as string).includes(input);
}
