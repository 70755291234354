import { CircularProgress, MenuItem, Select } from "@mui/material";
import { useFetchBroadcasts } from "../../hooks";
import { usePatron } from "../../providers/PatronProvider/hooks/usePatron";
import { useListMatchTimelineEvents } from "../../api/new-timeline/hooks/useListMatchTimelineEvents";
import useFetchMatch from "../Broadcasts/BroadcastDetails/hooks/useFetchMatch";
import { useMemo, useState } from "react";
import { useFetchVideoClip } from "../../hooks/useFetchVideoClip";
import VideoPlayerModule from "../../remoteComponents/VideoPlayerModule";
import { TimelineEvent } from "../../api/new-timeline/schemas";
import { GameIcon } from "./components/GameIcon";
import { CsEvent } from "../../api/video/schemas";
import { getClipName } from "./helpers/getClipName";
import { useGetEventRelatedData } from "../../hooks/useGetEventRelatedData";
import { generateImageUrl } from "../../helpers/images/generateImageUrl";
import { Button } from "@blastorg/portal-pattern-library";
import { clipsAwsBaseUrl } from "../../config";
import { useFetchEventTranscript } from "../../hooks/useFetchEventTranscript";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { events } from "../Broadcasts/TimelineEvents/data/events";

const ClipTranscription = ({ event }: { event: TimelineEvent }) => {
  const { data: clip, isLoading: isClipLoading } = useFetchVideoClip(event.id);
  const { data: transcript, isLoading: isTranscriptLoading } = useFetchEventTranscript(event.id);
  const {
    data: { player, team },
    isLoading: isEventRelatedDataLoading,
  } = useGetEventRelatedData(event as CsEvent);

  if (!clip || isClipLoading || isEventRelatedDataLoading || isTranscriptLoading) return <CircularProgress />;

  if (clip.harvestJobStatus !== "SUCCEEDED") {
    return <div>Clip transcription is still processing... Please check back in a bit</div>;
  }

  const clipName = getClipName(event as CsEvent);

  return (
    <div className="grid grid-cols-3 gap-x-4 py-4">
      <div className="flex flex-col">
        <div className="mx-auto mb-5 flex h-16 w-fit items-center gap-3 rounded bg-slate-800 px-2 md:mx-0">
          <span className="text-yellow-500 font-style-label-3">
            Map: {event.mapNumber} Round: {event.roundNumber}
          </span>
          <GameIcon eventType={event.eventType as CsEvent["eventType"]} />
          <span className="text-yellow-500 font-style-label-3">{clipName}</span>
          {!!(team || player) && <span className="font-style-label-4">by</span>}
          {player && (
            <div className="size-12 overflow-hidden rounded-full">
              <img
                className="block h-16 w-full origin-top scale-150 object-cover"
                src={generateImageUrl("players", player.id, {
                  format: "auto",
                  height: "200",
                })}
                alt={player.nickname}
              />
            </div>
          )}
          {team && (
            <img
              src={generateImageUrl("teams", team.id, {
                format: "auto",
                height: "80",
              })}
              alt={team?.name}
              className="h-5"
            />
          )}
          {player && (
            <div>
              <div className="mb-1 text-white font-style-label-4">{player.nickname}</div>
              <div className="text-neutral-50 font-style-b4-body-copy">
                {player.firstName} {player.lastName}
              </div>
            </div>
          )}
          <span className="text-yellow-500 font-style-label-3">Duration: {transcript?.duration.toFixed(0)}s</span>
        </div>

        <div className="">
          <Button
            variant="tertiary"
            onClick={() => window.open(`${clipsAwsBaseUrl}/downloadable/${clip.eventId}.mp4`, "_blank")}
          >
            Download MP4
          </Button>
          <Button
            variant="tertiary"
            onClick={() => window.open(`${clipsAwsBaseUrl}/audio/${clip.eventId}.mp3`, "_blank")}
          >
            Download MP3
          </Button>
        </div>
      </div>
      <div className="max-h-[200px] overflow-auto">
        {transcript?.segments.map((segment) => (
          <div key={segment.id} className="flex flex-row gap-x-2">
            <span className="min-w-[4.5em]">
              {segment.start.toFixed(0)} - {segment.end.toFixed(0)}
            </span>
            <p>{segment.text}</p>
          </div>
        ))}
      </div>
      <div className="w-96">
        <VideoPlayerModule controls src={clip.src} />
      </div>
    </div>
  );
};

const MatchTranscription = ({ matchId, eventFilter }: { matchId: string; eventFilter?: string }) => {
  const { data: matchEvents } = useListMatchTimelineEvents({ matchId });
  const matchQueryResults = useFetchMatch([matchId]);

  const filteredEvents = useMemo(() => {
    if (!matchEvents) {
      return null;
    }

    const defaultFilter = matchEvents.filter(
      (event) => !["ROUND_END", "MATCH_START", "MAP_START"].includes(event.eventType),
    );

    if (!eventFilter) {
      return defaultFilter;
    }

    return defaultFilter.filter((event) => event.eventType === eventFilter);
  }, [eventFilter, matchEvents]);

  const match = useMemo(() => {
    if (!matchQueryResults) {
      return null;
    }

    return matchQueryResults.at(0)?.data;
  }, [matchQueryResults]);

  if (!matchEvents || !match) return <CircularProgress />;

  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex flex-row items-center font-style-label-2">
              <ChevronDownIcon
                className={classNames("w-8 transition", {
                  "rotate-180 transform": open,
                })}
              />{" "}
              {match.teamA?.name} vs {match.teamB?.name} | {match.stage?.name} |{" "}
              {new Date(match.scheduledAt).toLocaleString()}
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="divide-y-2">
                {filteredEvents?.map((event) => <ClipTranscription key={event.id} event={event} />)}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default function TranscriptionsPage() {
  const { patron } = usePatron();
  // TODO: Rework this away from good ol' prop drilling.
  const [selectedEvent, setSelectedEvent] = useState<string | undefined>(undefined);
  const { data: broadcasts, isLoading } = useFetchBroadcasts({ patron });

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (!broadcasts) {
    return <div>No broadcasts found.</div>;
  }

  return (
    <div className="flex grow flex-col p-8">
      <div className="flex w-96 flex-row items-center gap-x-4 pb-4">
        <span>Filter by type</span>
        <Select className="" onChange={(e) => setSelectedEvent(e.target.value)} value={selectedEvent}>
          <MenuItem value={undefined}>Select an event</MenuItem>
          {events.map((eventType) => (
            <MenuItem key={eventType.id} value={eventType.id}>
              {eventType.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      {broadcasts.map((broadcast) => (
        <>
          <h1 className="font-style-desktop-h4">{broadcast.title}</h1>
          <div>
            {broadcast.seriesIds?.map((match) => (
              <MatchTranscription key={match} matchId={match} eventFilter={selectedEvent} />
            ))}
          </div>
        </>
      ))}
    </div>
  );
}
