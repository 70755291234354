import { useMutation } from "@tanstack/react-query";
import { postReportGeneration } from "..";
import useAlert from "@/providers/AlertProvider/hooks/useAlert";

export const useRequestGenerateReport = () => {
  const alert = useAlert();
  return useMutation({
    mutationFn: (eventId: string) => postReportGeneration(eventId),
    onError: () => {
      alert.showFailureAlert("Failed to generate report");
    },
    onSuccess: () => {
      alert.showSuccessAlert("Report successfully request. Wait ~30s before downloading");
    },
  });
};
