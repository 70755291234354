import { useMutation } from "@tanstack/react-query";
import { updatePlayer } from "../../../../api/tournaments";
import { Player } from "../../../../api/tournaments/schemas/players";

export default function useUpdatePlayer({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) {
  return useMutation({
    mutationKey: ["player"],
    mutationFn: ({ playerId, properties }: { playerId: string; properties: Partial<Player> }) =>
      updatePlayer({ playerId, properties }),
    onSuccess,
    onError,
  });
}
