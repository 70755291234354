import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCsQuizQuestion } from ".";
import { queryKeys } from "../shared/queryKeys";

export const useDeleteCsQuizQuestion = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCsQuizQuestion,
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.questions());
    },
  });
};
