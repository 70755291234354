import { DateTime } from "luxon";

const getSuffix = (value: number) => {
  return value > 1 ? "s" : "";
};

const secondsToMessage = (interval: number) => {
  const duration = DateTime.now().plus({ second: interval });
  const diff = duration.diff(DateTime.now(), [
    "days",
    "hours",
    "minutes",
    "seconds",
  ]);

  const days = diff.days;
  const hours = diff.hours;
  const minutes = diff.minutes;
  const seconds = Math.round(diff.seconds);

  if (days > 0) {
    return `${days} day${getSuffix(days)} `;
  }
  if (hours > 0) {
    return `${hours} hour${getSuffix(hours)} `;
  }
  if (minutes > 0) {
    return `${minutes} minute${getSuffix(minutes)} `;
  }
  if (seconds > 0) {
    return `${seconds} second${getSuffix(seconds)} `;
  }

  return "";
};

export default secondsToMessage;
