import { ReactNode } from "react";
import { Box, Button, Divider, Modal } from "@mui/material";
import { ModalProps } from "@mui/material/Modal/Modal";
import { ConfirmModalWrapper, ModalContent, ModalFooter } from "./styled";

type ConfirmModalProps = ModalProps & {
  onCancel?: () => void;
  onSubmit?: () => void;
  submitText?: string;
  cancelText?: string;
  children: ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  submitColor?:
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
};

const ConfirmModal = ({
  onCancel,
  onSubmit,
  submitText = "Submit",
  cancelText = "Cancel",
  submitColor = "primary",
  disabled,
  errorMessage,
  children,
  ...modalProps
}: ConfirmModalProps) => {
  return (
    <Modal {...modalProps}>
      <ConfirmModalWrapper>
        <ModalContent>
          <Box display="block">{children}</Box>
          <Divider />
          <ModalFooter>
            <Button disabled={disabled} onClick={onCancel}>
              {cancelText}
            </Button>
            <Button
              disabled={disabled}
              onClick={onSubmit}
              variant="contained"
              color={submitColor}
            >
              {submitText}
            </Button>
          </ModalFooter>
          {errorMessage && (
            <Box py="0.5rem" fontSize="small" color="error.light">
              {errorMessage}
            </Box>
          )}
        </ModalContent>
      </ConfirmModalWrapper>
    </Modal>
  );
};

export default ConfirmModal;
