import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { ZodError } from "zod";
import { PointsProductSchema } from "../../../../../api/points/schemas";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import usePointsContext from "../../../context/PointsConsumer";
import { DetailsFields } from "./components/DetailsField";
import { useCreateProduct } from "./components/DetailsField/hooks/useCreateProduct";
import { useEditProduct } from "./components/DetailsField/hooks/useEditProduct";
import { useMemo } from "react";

export const CreateEditProductModal = () => {
  const { productData, imageData, editCreateModalMethods, editCreateModalData } = usePointsContext();

  const alert = useAlert();

  const { file } = imageData;

  const { mutate: uploadProduct } = useCreateProduct({
    onSuccess: () => {
      alert.showSuccessAlert("Product created successfully");
      editCreateModalMethods.handleClose();
    },
    onError: () => {
      alert.showFailureAlert("Product failed to create");
    },
  });

  const { mutate: editProduct } = useEditProduct({
    onSuccess: () => {
      alert.showSuccessAlert("Product edited successfully");
      editCreateModalMethods.handleClose();
    },
    onError: () => {
      alert.showFailureAlert("Unknown error editing product. Ask devs for help.");
    },
  });

  const submit = () => {
    const data = PointsProductSchema.safeParse(productData);

    if (!data.success) {
      const err = data.error as ZodError<typeof PointsProductSchema>;
      alert.showFailureAlert(`${err.errors[0].path.join()} ${err.errors[0].message}`);

      return;
    }

    if (editCreateModalData.isEdit) {
      editProduct({
        ...data.data,
        image: file ? file : undefined,
      });
      return;
    }

    if (!file) return;

    uploadProduct({
      ...data.data,
      image: file,
    });
  };

  const isDisabled = useMemo(() => {
    const res = PointsProductSchema.safeParse(productData);

    if (!res.success) return true;
  }, [productData]);

  return (
    <Dialog open={editCreateModalData.isOpen} onClose={editCreateModalMethods.handleClose} fullWidth maxWidth="md">
      <DialogTitle>{editCreateModalData.isEdit ? "Edit" : "Create"} Product</DialogTitle>
      <Divider />
      <DialogContent>
        <DetailsFields />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={submit} disabled={isDisabled}>
          Submit
        </Button>
        <Button variant="outlined" onClick={editCreateModalMethods.handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
