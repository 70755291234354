import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useGetIssues } from "../../../../../api/data/issues/getIssues/useGetIssues";
import { Issue } from "../../../../../api/data/issues/shared/schemas";
import { IssueEntityLinkButton } from "./IssueEntityLinkButton";
import { IssueTournamentLinkButton } from "./IssueTournamentLinkButton";

const columnsCount = 4;

export const IssuesTable = ({ onRowClick, className }: { onRowClick?: (issue: Issue) => void; className?: string }) => {
  const {
    data: issues,
    isLoading,
    isError,
  } = useGetIssues({
    resolved: false,
  });

  return (
    <Table className={classNames("mt-8 w-fit min-w-[60vw] max-w-full", className)} stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Created At {`(${DateTime.local().toFormat("ZZZZ")})`}</TableCell>
          <TableCell>Tournament</TableCell>
          <TableCell>Entity</TableCell>
          <TableCell>Error Key</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isError && !isLoading && (
          <TableRow>
            <TableCell colSpan={columnsCount}>Something went wrong when fetching the data</TableCell>
          </TableRow>
        )}
        {!isError && !isLoading && issues?.length === 0 && (
          <TableRow>
            <TableCell colSpan={columnsCount}>No records found</TableCell>
          </TableRow>
        )}
        {issues?.map((issue, i) => (
          <TableRow
            key={i}
            className={classNames({
              "cursor-pointer transition-all duration-150 ease-in-out hover:bg-white/10":
                !isLoading && !isError && onRowClick,
            })}
            onClick={() => onRowClick?.(issue)}
          >
            <TableCell>{DateTime.fromISO(issue.createdAt).toLocal().toFormat("yyyy-MM-dd HH:mm")}</TableCell>
            <TableCell>
              <IssueTournamentLinkButton {...issue} />
            </TableCell>
            <TableCell>
              {issue.entity} - <IssueEntityLinkButton {...issue} />
            </TableCell>
            <TableCell>{issue.errorKey}</TableCell>
            <TableCell className="max-w-xl">{issue.description}</TableCell>
          </TableRow>
        ))}
        {!issues?.length && !isLoading && (
          <TableRow>
            <TableCell colSpan={columnsCount} className="h-20 text-center">
              <span className="select-none italic text-gray-300">No issues found</span>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
