import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";

import { PirateStreamStatus, Stream } from "@/api/pirate-streams/schemas";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import useAlert from "@/providers/AlertProvider/hooks/useAlert";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { pirateQueryKeys } from "../../hooks/pirateQueryKeys";
import { useUpdateStreamStatus } from "../../hooks/useUpdateStreamStatus";

interface EditStreamStatusModalProps {
  pirateStream: Stream | null;
  handleClose: () => void;
  open: boolean;
}

const EditStreamStatusModal = ({ handleClose, pirateStream, open }: EditStreamStatusModalProps) => {
  const [streamStatus, setStreamStatus] = useState<PirateStreamStatus | null>(pirateStream?.status ?? null);

  const { mutate: updateStreamStatus, isLoading: isUpdatingStreamStatus } = useUpdateStreamStatus();

  useEffect(() => {
    if (pirateStream) {
      setStreamStatus(pirateStream.status);
    }
  }, [pirateStream]);

  const queryClient = useQueryClient();
  const alert = useAlert();

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && handleClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit stream status</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium">Stream Status</label>
            <Select
              value={streamStatus ?? "offline"}
              onValueChange={(value) => setStreamStatus(value as PirateStreamStatus)}
              disabled={isUpdatingStreamStatus}
            >
              <SelectTrigger className="w-[320px]">
                <SelectValue placeholder="Select a role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="live">Live</SelectItem>
                <SelectItem value="reported">Reported</SelectItem>
                <SelectItem value="offline">Offline</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => handleClose()} disabled={isUpdatingStreamStatus}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (!pirateStream || !streamStatus) return;
                void updateStreamStatus(
                  { stream: pirateStream, status: streamStatus },
                  {
                    onSuccess: async () => {
                      await queryClient.invalidateQueries({
                        queryKey: pirateQueryKeys.streams(pirateStream.status),
                      });
                      alert.showSuccessAlert("Stream status updated successfully");
                      handleClose();
                    },
                  },
                );
              }}
              disabled={isUpdatingStreamStatus}
            >
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditStreamStatusModal;
