import axios from "axios";
import { baseApiURL } from "../../config";
import {
  Location,
  LocationSchema,
  Schedule,
  ScheduleRequest,
  ScheduleSchema,
} from "./types";
import authInterceptor from "../authInterceptor";

export const csguessrApi = axios.create({
  baseURL: `${baseApiURL}/v1/csguessr`,
});

csguessrApi.interceptors.request.use(authInterceptor);

export const getLocations = async (): Promise<Location[]> => {
  const { data } = await csguessrApi.get<unknown>("/locations");

  return LocationSchema.array().parse(data);
};

export const createLocation = async (location: Location) => {
  await csguessrApi.post("/locations", location);
};

export const deleteLocation = async (id: string) => {
  await csguessrApi.delete(`/locations/${id}`);
};

export const getSchedules = async (): Promise<Schedule[]> => {
  const { data } = await csguessrApi.get<unknown>("/schedules");

  return ScheduleSchema.array().parse(data);
};

export const createSchedule = async (schedule: ScheduleRequest) => {
  await csguessrApi.post("/schedules", schedule);
};

export const deleteSchedule = async (schedule: ScheduleRequest) => {
  await csguessrApi.delete(`/schedules`, {
    data: schedule,
  });
};
