import { z } from "zod";

export const Position = z.object({
  x: z.number(),
  y: z.number(),
});

export const LocationSchema = z.object({
  id: z.string(),
  mapId: z.string(),
  position: Position,
  image: z.string(),
  createdAt: z.string().optional(),
});

export type Location = z.infer<typeof LocationSchema>;

export const ScheduleSchema = LocationSchema.extend({
  date: z.string(),
}).omit({ createdAt: true, mapId: true, position: true });

export type Schedule = z.infer<typeof ScheduleSchema>;

export const ScheduleRequestSchema = z.object({
  date: z.string(),
  locationId: z.string(),
});

export type ScheduleRequest = z.infer<typeof ScheduleRequestSchema>;
