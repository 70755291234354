import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateRules } from "../../../api/moderation";

const useUpdateModerationRules = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (...rules: Parameters<typeof updateRules>) =>
      updateRules(...rules),
    onSuccess() {
      void queryClient.invalidateQueries(["moderationRules"]);
    },
  });
};

export default useUpdateModerationRules;
