import {
  CancelOutlined,
  CheckCircleOutlined,
  QuestionMarkRounded,
} from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { PickemsChoice } from "../../../../types/pickems/PickemsChoice";
import { PickemsQuestion } from "../../../../types/pickems/PickemsQuestion";
import IsCorrectCell from "../IsCorrectCell";
import { PAGE_MAX_WIDTH, PostCorrectAnswerCallbackArgs } from "../..";
import { CorrectAnswer } from "../../../../api/pickems/types";

interface QuestionsTableProps {
  questions: PickemsQuestion[];
  correctAnswers: CorrectAnswer[];
  isLoading: boolean;
  setCorrectAnswer: (args: PostCorrectAnswerCallbackArgs) => void;
  setIncorrectAnswer: (args: PostCorrectAnswerCallbackArgs) => void;
  isWaiting: boolean;
}

interface RowData extends PickemsChoice {
  questionId: string;
  isCorrect?: boolean;
}

const mapQuestionToRowData = ({
  questions,
  correctAnswers,
}: {
  questions: PickemsQuestion[];
  correctAnswers: CorrectAnswer[];
}): RowData[] => {
  const rows: RowData[] = [];
  for (const question of questions) {
    question.choices.forEach((choice) => {
      rows.push({
        ...choice,
        questionId: question.uid,
        isCorrect: correctAnswers.find(
          (correctAnswer) =>
            correctAnswer.questionId === question.uid &&
            correctAnswer.choiceId === choice.uid,
        )?.isCorrect,
      });
    });
  }

  return rows;
};

export default function QuestionsTable({
  questions,
  correctAnswers,
  isLoading,
  setCorrectAnswer,
  setIncorrectAnswer,
  isWaiting,
}: QuestionsTableProps) {
  const rows: RowData[] = mapQuestionToRowData({ questions, correctAnswers });

  if (isLoading) return <div>Loading questions...</div>;
  if (!questions.length) return <div>No questions yet.</div>;

  return (
    <Table size="small" sx={{ maxWidth: PAGE_MAX_WIDTH }}>
      <TableHead>
        <TableRow>
          <TableCell>Question Uid</TableCell>
          <TableCell>Choice Uid</TableCell>
          <TableCell>Correct</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => (
          <TableRow key={i}>
            <TableCell>{row.questionId}</TableCell>
            <TableCell>{row.uid}</TableCell>
            <TableCell>
              {row.isCorrect === true ? (
                <CheckCircleOutlined color="success" />
              ) : row.isCorrect === false ? (
                <CancelOutlined color="error" />
              ) : (
                <QuestionMarkRounded />
              )}
            </TableCell>
            <IsCorrectCell
              isCorrect={row.isCorrect}
              isWaiting={isWaiting}
              onSetCorrectAnswer={() =>
                setCorrectAnswer({
                  questionId: row.questionId,
                  choiceId: row.uid,
                })
              }
              onSetIncorrectAnswer={() =>
                setIncorrectAnswer({
                  questionId: row.questionId,
                  choiceId: row.uid,
                })
              }
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
