import { z } from "zod";

export const BroadcastAdSchema = z.object({
  adId: z.string(),
  title: z.string(),
  url: z.string(),
});

export type BroadcastAd = z.infer<typeof BroadcastAdSchema>;

export const BroadcastAdRunRequestSchema = z.object({
  adId: z.string(),
  durationSeconds: z.number(),
  format: z.string(),
});

export type BroadcastAdRunRequest = z.infer<typeof BroadcastAdRunRequestSchema>;
