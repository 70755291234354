import { useQuery } from "@tanstack/react-query";
import { getLocations } from "../../../api/csguessr";

const useGetLocations = () => {
  return useQuery({
    queryKey: ["locations"],
    queryFn: getLocations,
  });
};

export default useGetLocations;
