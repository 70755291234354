import { Edit } from "@mui/icons-material";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Collection } from "../../../../../api/points/types/Collection";
import usePointsCollections from "../../usePointsCollections/usePointsCollections";
import { CollectionImagePreview } from "./components/CollectionImagePreview";

interface CollectionsTableProps {
  collections?: Collection[];
}

export const CollectionsTable = ({ collections }: CollectionsTableProps) => {
  const { setModal } = usePointsCollections();

  return (
    <TableContainer style={{ height: "80vh" }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>silver</TableCell>
            <TableCell>Gold</TableCell>
            <TableCell>Diamond</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collections?.map((collection) => (
            <TableRow key={collection.collectionId}>
              <TableCell>{collection.name}</TableCell>
              <TableCell>{collection.type}</TableCell>
              <TableCell>
                <CollectionImagePreview
                  images={collection.silverRarity}
                  type={collection.type}
                />
              </TableCell>
              <TableCell>
                <CollectionImagePreview
                  images={collection.goldRarity}
                  type={collection.type}
                />
              </TableCell>
              <TableCell>
                <CollectionImagePreview
                  images={collection.diamondRarity}
                  type={collection.type}
                />
              </TableCell>

              <TableCell align="right">
                <Button
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={() => {
                    setModal(true, "edit", collection);
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
