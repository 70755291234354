import * as React from "react";

import { NavMain } from "@/components/ProtectedRoute/components/NavMain";
import { NavUser } from "@/components/ProtectedRoute/components/nav-user";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from "@/components/ui/sidebar";
import { useAuth } from "@/providers/AuthProvider/hooks/useAuth";
import { RouteDefinition } from "..";
import { GameSwitcher } from "./GameSwitcher";
import { GameOption } from "./types/Game";
import { useBackoffice } from "@/providers/BackofficeProvider/useState";
import { useMemo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const games: GameOption[] = [
  {
    name: "Counter-Strike 2",
    image: "https://assets.blast.tv/game_icons/cs.png",
    value: "cs",
  },
  {
    name: "Dota 2",
    image: "https://assets.blast.tv/game_icons/dota2.jpeg",
    value: "dota",
  },
  {
    name: "Deadlock",
    image: "https://assets.blast.tv/game_icons/deadlock.png",
    value: "deadlock",
  },
  {
    name: "Rocket League",
    image: "https://assets.blast.tv/game_icons/rocketleague.png",
    value: "rl",
  },
];

export function AppSidebar({
  ...props
}: React.ComponentProps<typeof Sidebar> & {
  routes: RouteDefinition[];
}) {
  const { routes } = props;
  const auth = useAuth();
  const { currentGame } = useBackoffice();

  // Filter out the route items that the user does not have access to
  const filteredRoutes = routes
    .map((item) => {
      const filteredItems = item.items
        ?.map((subItem) => {
          if (subItem.requiredPermission) {
            if (!auth.permissions.includes(subItem.requiredPermission)) {
              return null;
            }
          }
          return subItem;
        })
        .filter((item) => item !== null);
      return {
        ...item,
        items: filteredItems,
      };
    })
    .filter((item) => (item.items?.length ?? 0) > 0 || item.path);

  const routesToRender = useMemo(() => {
    const sharedRoutes = filteredRoutes
      .map((route) => {
        // Find items that have no games in it's games array
        const sharedItems = route.items?.filter((item) => !item.games?.length);
        return { ...route, items: sharedItems };
      })
      .filter((route) => route.items?.length || (route.path && (route.games?.length ?? 0) > 0));

    const gameRoutes = filteredRoutes
      .map((route) => {
        const gameItems = route.items?.filter((item) => item.games?.includes(currentGame));
        return { ...route, items: gameItems };
      })
      .filter((route) => route.items?.length || (route.path && (route.games?.length ?? 0) > 0));
    return {
      sharedRoutes,
      gameRoutes,
    };
  }, [filteredRoutes, currentGame]);

  const { state } = useSidebar();

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <Link to="/" className="flex items-center gap-2" title="Home">
          <div
            className={classNames("flex w-full items-center gap-2 px-2", {
              "justify-center": state === "collapsed",
              "justify-start": state === "expanded",
            })}
          >
            <img
              src={`/BLAST-logo-light.svg`}
              alt="BLAST Logo"
              className={classNames({ "h-8": state === "expanded", "h-5": state === "collapsed" })}
            />

            {state === "expanded" && (
              <div className="flex flex-col items-start">
                <span className="text-sm font-bold">BLAST.tv</span>
                <span className="text-xs text-muted-foreground">Backoffice</span>
              </div>
            )}
          </div>
        </Link>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={routesToRender.sharedRoutes} title="Shared" />
        <GameSwitcher games={games} />
        {routesToRender.gameRoutes.length > 0 && <NavMain items={routesToRender.gameRoutes} title="Game Options" />}
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
