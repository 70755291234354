import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";

export interface Chips<T> {
  label: string;
  id: T;
  icon?: string;
}

export interface FilterChips<T> {
  label: string;
  chips: Chips<T>[];
  onClick: (filter: Chips<T>["id"]) => void;
  initialFilter?: Chips<T>["id"];
  clearFilter?: boolean;
  allChip?: boolean;
  onAllClick?: () => void;
}

function FilterChips<T extends string>({
  label,
  chips,
  onClick,
  initialFilter,
  clearFilter,
  allChip,
  onAllClick,
}: FilterChips<T>) {
  const [filter, setFilter] = useState<T | undefined>(initialFilter);
  const handleFilterClick = (chipId: Chips<T>["id"]) => {
    if (filter === chipId) {
      return;
    } else {
      setFilter(chipId);
      onClick(chipId);
    }
  };

  const handleAllClick = () => {
    if (allChip && onAllClick) {
      setFilter(undefined);
      onAllClick();
    }
  };

  useEffect(() => {
    if (clearFilter) {
      setFilter(undefined);
    }
  }, [clearFilter]);

  return (
    <div className="flex w-full flex-col">
      <span className="pb-1 pl-1 font-style-label-3">{label} </span>
      <Stack direction="row" spacing={1} sx={{ flexWrap: "wrap" }} useFlexGap>
        {allChip && (
          <Chip label="All" variant={filter === undefined ? "filled" : "outlined"} onClick={handleAllClick} />
        )}
        {chips.map((chip) => (
          <Chip
            className="pl-1"
            avatar={chip.icon ? <img src={chip.icon} alt={chip.label} className="size-4 rounded-full" /> : undefined}
            key={chip.id}
            label={chip.label}
            variant={filter === chip.id ? "filled" : "outlined"}
            onClick={() => handleFilterClick(chip.id)}
          />
        ))}
      </Stack>
    </div>
  );
}

export default FilterChips;
