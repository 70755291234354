import { useMutation } from "@tanstack/react-query";

import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";

import { cancelSuspension } from "../../../../../api/chat";

const useCancelSuspension = () => {
  const { showFailureAlert } = useAlert();

  return useMutation({
    mutationKey: ["cancelSuspension"],
    mutationFn: (uuid: string) => cancelSuspension(uuid),
    onError() {
      showFailureAlert("Error while canceling user's suspension");
    },
  });
};

export default useCancelSuspension;
