import { useQuery } from "@tanstack/react-query";
import { getVideoClip } from "../api/video";

export const useFetchVideoClip = (eventId?: string) => {
  return useQuery({
    queryKey: ["videoClip", eventId],
    queryFn: eventId ? () => getVideoClip({ eventId }) : undefined,
    enabled: !!eventId,
  });
};
