import { AxiosError } from "axios";
import { z } from "zod";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { useCreateChannel } from "../../../../../api/metricsViewership/hooks/useCreateChannel";
import { Channel } from "../../../../../api/metricsViewership/schemas/ChannelSchema";

export const CreateChannelModal = ({ onClose }: { onClose: () => void }) => {
  const { mutate: createChannel, isLoading } = useCreateChannel();
  const [channelName, setChannelName] = useState<string>("");
  const [channelSource, setChannelSource] = useState<Channel["channelSource"]>("twitch");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validationErrors = [];
  if (["youtube", "tiktok"].includes(channelSource) && channelName.length > 0 && !channelName.startsWith("@")) {
    validationErrors.push("Channel name must start with '@'");
  }
  if (["twitch", "kick", "blasttv"].includes(channelSource) && channelName.length > 0 && channelName.startsWith("@")) {
    validationErrors.push("Channel name can not start with '@'");
  }

  const createChannelAndClose = () => {
    if (!channelName || !channelSource) {
      return;
    }

    setErrorMessage(null);

    createChannel(
      {
        channelName,
        channelSource,
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          let message: string | undefined;
          if (error instanceof AxiosError) {
            const parsed = z
              .object({
                message: z.string(),
              })
              .safeParse(error.response?.data);

            message = parsed.success ? parsed.data.message : undefined;
          }

          setErrorMessage(message ?? "An error occurred. Check console for more details.");
        },
      },
    );
  };

  return (
    <Dialog open={true} onOpenChange={() => onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create Channel</DialogTitle>
        </DialogHeader>
        <Separator />
        <div className="grid gap-4">
          <div className="space-y-2">
            <Label htmlFor="name">Platform</Label>
            <ToggleGroup
              type="single"
              variant="outline"
              value={channelSource}
              onValueChange={(value) => setChannelSource(value as Channel["channelSource"])}
            >
              {["Twitch", "YouTube", "Tiktok", "Kick", "BLAST TV"].map((source) => (
                <ToggleGroupItem key={source} value={source.toLowerCase().replaceAll(" ", "")} className="grow">
                  {source}
                </ToggleGroupItem>
              ))}
            </ToggleGroup>
          </div>
          <div className="space-y-2">
            <Label htmlFor="name">
              Name <span className="text-red-500">*</span>
            </Label>
            <Input
              id="channelName"
              placeholder="Enter name here"
              required
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
            />
            {validationErrors.map((error) => (
              <div key={error} className="min-h-6 text-red-500 font-style-b2-body-copy">
                {error}
              </div>
            ))}
          </div>
        </div>

        <div className="min-h-6 text-red-500">{errorMessage ?? ""}</div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={createChannelAndClose} disabled={isLoading}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
