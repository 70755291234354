import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Select,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useFetchMatchVetoes from "../../../../../hooks/matches/useFetchMatchVetoes";
import mapPool from "../../../../../shared/components/mapPool";
import { EditMatch, EditTeamMatch } from "../../../../../../../api/tournaments/schemas/matches";
import { useEffect, useMemo, useState } from "react";
import { Veto } from "../../../../../../../api/tournaments/schemas/vetoes";
import useUpdateMatchVetoes from "../../../../../hooks/matches/useUpdateMatchVetoes";
import useAlert from "../../../../../../../providers/AlertProvider/hooks/useAlert";

import useCreateMatchVetoes from "../../../../../hooks/matches/useCreateMatchVetoes";
import usePostMap from "../../../../../hooks/maps/usePostMap";
import { DateTime } from "luxon";

interface VetoesProps {
  isOpen: boolean;
  handleClose: () => void;
  match: EditMatch;
  matchHasMaps: boolean;
  teamA?: EditTeamMatch;
  teamB?: EditTeamMatch;
}

export const Vetoes = ({ isOpen, handleClose, match, matchHasMaps, teamA, teamB }: VetoesProps) => {
  const alert = useAlert();
  const [vetoesWithTeamName, setVetoesWithTeamName] = useState<(Veto & { teamName: string })[]>([]);
  const [isNewVetos, setIsNewVetos] = useState<boolean>(false);
  const [generateMapsOnSave, setGenerateMapsOnSave] = useState<boolean>(false);

  const newVetoes: Veto[] = useMemo(
    () => [
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: "ban",
        index: 0,
        map: "de_ancient",
        teamId: teamA?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: "ban",
        index: 1,
        map: "de_anubis",
        teamId: teamB?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: match.matchType.toLocaleUpperCase() === "BO1" ? "ban" : "pick",
        index: 2,
        map: "de_inferno",
        teamId: teamA?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: match.matchType.toLocaleUpperCase() === "BO1" ? "ban" : "pick",
        index: 3,
        map: "de_mirage",
        teamId: teamB?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: match.matchType.toLocaleUpperCase() === "BO5" ? "pick" : "ban",
        index: 4,
        map: "de_nuke",
        teamId: teamA?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: match.matchType.toLocaleUpperCase() === "BO5" ? "pick" : "ban",
        index: 5,
        map: "de_dust2",
        teamId: teamB?.team?.id || null,
      },
      {
        id: crypto.randomUUID(),
        matchId: match.matchId || "",
        type: "pick",
        index: 6,
        map: "de_vertigo",
        teamId: null,
      },
    ],
    [match.matchId, match.matchType, teamA?.team?.id, teamB?.team?.id],
  );

  const { data: vetoes } = useFetchMatchVetoes(match.matchId);
  const { mutate: updateVeto } = useUpdateMatchVetoes();
  const { mutate: createVeto } = useCreateMatchVetoes();
  const { mutate: createMap } = usePostMap();

  useEffect(() => {
    if (!vetoes) return;

    if (vetoes.length > 0) {
      const tempVetoesWithTeamName = vetoes.map((veto) => {
        if (veto.teamId === teamA?.team?.id) {
          return { ...veto, teamName: teamA?.team?.name };
        } else if (veto.teamId === teamB?.team?.id) {
          return { ...veto, teamName: teamB?.team?.name };
        } else if (veto.teamId === undefined || veto.teamId === null) {
          return { ...veto, teamId: "Decider", teamName: "Decider" };
        } else return { ...veto, teamName: "unknown" };
      });
      setVetoesWithTeamName(tempVetoesWithTeamName);
      setIsNewVetos(false);
    } else {
      const tempVetoesWithTeamName = newVetoes.map((veto) => {
        if (veto.teamId === teamA?.team?.id) {
          return { ...veto, teamName: teamA?.team?.name };
        } else if (veto.teamId === teamB?.team?.id) {
          return { ...veto, teamName: teamB?.team?.name };
        } else if (veto.teamId === undefined || veto.teamId === null) {
          return { ...veto, teamId: "Decider", teamName: "Decider" };
        } else return { ...veto, teamName: "unknown" };
      });
      setVetoesWithTeamName(tempVetoesWithTeamName);
      setIsNewVetos(true);
    }
  }, [vetoes, newVetoes, teamA, teamB]);

  const swapTeamVetoes = () => {
    const vetoes = [...vetoesWithTeamName];

    for (let i = 0; i < vetoes.length - 1; i++) {
      vetoes[i]["teamId"] = vetoes[(i % 5) + 1]["teamId"];
      vetoes[i]["teamName"] = vetoes[(i % 5) + 1]["teamName"];
    }

    setVetoesWithTeamName(vetoes);
  };

  const changeVetoProperty = (vetoId: string, propertyName: string, propertyValue: string) => {
    const vetoes = [...vetoesWithTeamName];
    const needsVetoUpdate = vetoes.find((veto) => veto.id === vetoId);
    if (!needsVetoUpdate) return;
    const vetoIndex = needsVetoUpdate.index;
    if (propertyName === "team") {
      const [teamId, teamName] = propertyValue.split("+");
      vetoes[vetoIndex]["teamId"] = teamId;
      vetoes[vetoIndex]["teamName"] = teamName;
    }

    if (propertyName === "map") {
      vetoes[vetoIndex]["map"] = propertyValue;
    }

    if (propertyName === "type") {
      vetoes[vetoIndex]["type"] = propertyValue === "pick" ? "pick" : "ban";
    }
    setVetoesWithTeamName(vetoes);
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: "1000px",
          padding: "5px",
        },
      }}
      onClose={() => handleClose()}
    >
      <DialogContent>
        <div className="flex flex-col items-center">
          <Typography variant="h5">Map Vetoes</Typography>
          <div className="flex">
            <FormControlLabel
              control={<Checkbox onChange={(e) => setGenerateMapsOnSave(e.target.checked)} />}
              checked={generateMapsOnSave}
              disabled={matchHasMaps}
              label="Generate Maps"
            />
            <Button onClick={() => swapTeamVetoes()}>Swap Teams</Button>
          </div>
          <TableContainer className="flex w-full flex-col">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="w-[10%]">Index</TableCell>
                  <TableCell className="w-[30%]">Team</TableCell>
                  <TableCell className="w-[30%]">Map</TableCell>
                  <TableCell className="w-[30%]">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="w-full">
                {vetoesWithTeamName.map((veto) => {
                  return (
                    <TableRow key={veto.id} className="w-full">
                      <TableCell>{veto.index}</TableCell>
                      <TableCell>
                        <Select
                          className="w-[150px]"
                          value={`${veto.teamId}+${veto.teamName}`}
                          onChange={(e) => changeVetoProperty(veto.id, "team", e.target.value || "")}
                        >
                          <MenuItem value={`${teamA?.team?.id}+${teamA?.team?.name}`}>{teamA?.team?.name}</MenuItem>
                          <MenuItem value={`${teamB?.team?.id}+${teamB?.team?.name}`}>{teamB?.team?.name}</MenuItem>
                          <MenuItem value={`Decider+Decider`}>Decider</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          className="w-[150px]"
                          value={veto.map}
                          onChange={(e) => changeVetoProperty(veto.id, "map", e.target.value || "")}
                        >
                          {mapPool["cs"].map((map) => (
                            <MenuItem key={map} value={map}>
                              {map}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          className="w-[150px]"
                          value={veto.type}
                          onChange={(e) => changeVetoProperty(veto.id, "type", e.target.value || "")}
                        >
                          <MenuItem value="pick">pick</MenuItem>
                          <MenuItem value="ban">ban</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            vetoesWithTeamName.forEach((veto) => {
              if (isNewVetos) {
                createVeto(veto, {
                  onSuccess: () => {
                    alert.showSuccessAlert("Created vetoes for match");
                  },
                });
              } else {
                updateVeto(veto, {
                  onSuccess: () => {
                    alert.showSuccessAlert("Updated vetoes for match");
                  },
                });
              }
            });
            //create maps from vetos
            generateMapsOnSave &&
              vetoesWithTeamName
                .filter((veto) => veto.type === "pick")
                .forEach((veto, index) => {
                  const matchTime = DateTime.fromJSDate(match.scheduledAt).plus({
                    hours: index,
                  });
                  createMap({
                    mapName: veto.map,
                    matchId: match.matchId,
                    scheduledAt: matchTime.toJSDate(),
                  });
                });
          }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={() => handleClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
