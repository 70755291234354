import { z } from "zod";

export const CircuitSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  deletedAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  name: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  gameId: z.string(),
});

export const PostCircuitSchema = CircuitSchema.omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
});

export type PostCircuit = z.infer<typeof PostCircuitSchema>;
export type Circuit = z.infer<typeof CircuitSchema>;
