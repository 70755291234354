import { useMutation } from "@tanstack/react-query";
import {
  deleteResultsForMatch,
  putResultsForMatch,
} from "../../../api/fantasy";

export const useUpsertFantasyResult = () => {
  return useMutation({
    mutationFn: putResultsForMatch,
  });
};

export const useDeleteFantasyMatchResult = () => {
  return useMutation({
    mutationFn: deleteResultsForMatch,
  });
};
