const cleanMessage = (text: string) => {
  return (
    text
      .replace(/<br>/g, "")
      .replace(/&nbsp;/g, " ")
      .replace(String.fromCharCode(160), " ")
      .replace(/ {2,}/g, " ")
      .replace(/<!--.*-->/gs, "") || ""
  );
};

export default cleanMessage;
