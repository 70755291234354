import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postChangePurchaseDeliveredStatus } from "../../../../api/points";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

interface UseChangeProductDeliverStatus {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useChangeProductDeliverStatus = ({
  onSuccess,
  onError,
}: UseChangeProductDeliverStatus) => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["deliveredStatus"],
    mutationFn: ({ id, delivered }: { id: string; delivered: boolean }) =>
      postChangePurchaseDeliveredStatus({ id, delivered }),
    onSuccess: () => {
      onSuccess?.();
      void queryClient.invalidateQueries(["highEndProducts"]);
      alert.showSuccessAlert("Delivery status changed successfully");
    },
    onError: () => {
      onError?.();
      alert.showFailureAlert("Something went wrong");
    },
  });
};
