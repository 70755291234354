import { Add, Delete, ExpandMore, OpenInNew, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import useFetchTournamentById from "../../../../hooks/useFetchTournamentById";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { usePatchTournament } from "../../../../../../api/tournaments/hooks/usePatchTournament";
import { useAddTournamentTicketing } from "../../../../../../api/tournaments/addTournamentTicketing/useAddTournamentTicketing";
import { DateTime } from "luxon";
import { DateInput } from "../../../../../../components/DateInput";

// Ticketing items have been limited to 1 for now
const ticketingItemsLimit = 1;

export const TournamentTicketingAccordion = ({ tournamentId }: { tournamentId: string }) => {
  const { data: tournament } = useFetchTournamentById(tournamentId);
  const [isAddTicketingModalOpen, setIsAddTicketingModalOpen] = useState(false);
  const [deletingTicketing, setDeletingTicketing] = useState<
    | {
        title: string;
        startDate: string;
        endDate: string;
        cta: {
          url: string;
        };
      }
    | undefined
  >(undefined);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>
          <strong>Ticketing</strong>{" "}
          <span className="ml-4 text-sm text-white/70">
            {tournament?.metadata?.ticketing?.items.length ? "Assigned" : "Not assigned"}
          </span>
        </Typography>
      </AccordionSummary>
      <TableContainer component={AccordionDetails}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>CTA Link</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tournament?.metadata?.ticketing?.items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.title}</TableCell>
                <TableCell>{DateTime.fromISO(item.startDate).toLocaleString(DateTime.DATE_MED)}</TableCell>
                <TableCell>{DateTime.fromISO(item.endDate).toLocaleString(DateTime.DATE_MED)}</TableCell>
                <TableCell>
                  <Link href={item.cta.url} target="_blank" rel="noreferrer">
                    {item.cta.url} <OpenInNew className="ml-2 size-4" />
                  </Link>
                </TableCell>
                <TableCell>
                  <Button onClick={() => setDeletingTicketing(item)} color="error" variant="outlined">
                    <Delete />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {(tournament?.metadata?.ticketing?.items.length ?? 0) < ticketingItemsLimit && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Button startIcon={<Add />} size="small" onClick={() => setIsAddTicketingModalOpen(true)}>
                    Add a ticketing module
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isAddTicketingModalOpen && (
        <AddTicketingModal
          isOpen={isAddTicketingModalOpen}
          onClose={() => setIsAddTicketingModalOpen(false)}
          tournamentId={tournamentId}
        />
      )}
      {deletingTicketing && (
        <DeleteTicketingModal
          isOpen={!!deletingTicketing}
          onClose={() => setDeletingTicketing(undefined)}
          ticketing={deletingTicketing}
          tournamentId={tournamentId}
        />
      )}
    </Accordion>
  );
};

const DeleteTicketingModal = ({
  isOpen,
  onClose,
  ticketing,
  tournamentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  ticketing: { title: string; startDate: string; endDate: string; cta: { url: string } };
  tournamentId: string;
}) => {
  const alert = useAlert();
  const { data: tournament } = useFetchTournamentById(tournamentId);
  const { mutate: patchTournament, isLoading: isPatchingTournament } = usePatchTournament(tournamentId);

  const handleDeleteTicketing = () => {
    if (!tournament?.metadata?.ticketing?.items) return; // Nothing to delete

    patchTournament(
      {
        metadata: {
          ...tournament.metadata,
          ticketing: {
            items: tournament?.metadata?.ticketing?.items.filter((item) => item.title !== ticketing.title),
          },
        },
      },
      {
        onError: () => {
          alert.showFailureAlert("Failed to delete ticketing module");
        },
        onSuccess: () => {
          alert.showSuccessAlert("Ticketing module deleted successfully");
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Delete Ticketing Module?</DialogTitle>
      <DialogContent>
        <DialogContentText>Title: {ticketing.title}</DialogContentText>
        <DialogContentText>Start Date: {ticketing.startDate}</DialogContentText>
        <DialogContentText>End Date: {ticketing.endDate}</DialogContentText>
        <DialogContentText>CTA Link: {ticketing.cta.url}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleDeleteTicketing}
          loading={isPatchingTournament}
          startIcon={<Delete />}
          variant="contained"
          loadingPosition="start"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const AddTicketingModal = ({
  isOpen,
  onClose,
  tournamentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  tournamentId: string;
}) => {
  const { mutate: addTournamentTicketing, isLoading: isAddingTicketing } = useAddTournamentTicketing(tournamentId);
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(() => DateTime.now().plus({ days: 1 }).startOf("day"));
  const [endDate, setEndDate] = useState(() => DateTime.now().plus({ days: 7 }).startOf("day"));
  const [ctaLink, setCtaLink] = useState("");

  const alert = useAlert();

  const handleAddTicketing = () => {
    if (!title || !startDate || !endDate || !ctaLink) return;

    addTournamentTicketing(
      { title, startDate: startDate.toUTC().toISO(), endDate: endDate.toUTC().toISO(), cta: { url: ctaLink } },
      {
        onError: () => {
          alert.showFailureAlert("Failed to add ticketing module");
        },
        onSuccess: () => {
          alert.showSuccessAlert("Ticketing module added successfully");
          onClose();
        },
      },
    );
  };

  const isFormValid = title && startDate && endDate && ctaLink;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle>Add a Ticketing Module</DialogTitle>
      <DialogContent>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          className="my-2"
          required
        />
        <TextField
          label="CTA Link"
          value={ctaLink}
          onChange={(e) => setCtaLink(e.target.value)}
          fullWidth
          className="my-2"
          required
        />
        <div className="my-2 flex gap-4">
          <DateInput
            label="Start Date"
            value={startDate}
            onChange={(e) => {
              if (!e) return;

              setStartDate(e);
            }}
          />
          <DateInput
            label="End Date"
            value={endDate}
            onChange={(e) => {
              if (!e) return;

              setEndDate(e);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleAddTicketing}
          loading={isAddingTicketing}
          startIcon={<Save />}
          loadingPosition="start"
          variant="contained"
          disabled={!isFormValid}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
