import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createMatchTeam,
  deleteMatchTeam,
  matchAutoProgress,
  updateMatch,
  updateMatchTeams,
} from "../../../../api/tournaments";
import { EditMatch } from "../../../../api/tournaments/schemas/matches";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

export default function useUpdateTeam({
  tournamentId,
  onSuccess,
  onError,
}: {
  tournamentId?: string;
  onSuccess: () => void;
  onError: () => void;
}) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["match"],
    mutationFn: async ({ gameId, match }: { gameId?: string; match?: EditMatch }) => {
      if (match) {
        const teamA = match.teams.find((team) => team?.teamIndex === 10);
        const teamB = match.teams.find((team) => team?.teamIndex === 20);
        try {
          await updateMatch({
            matchId: match.matchId,
            matchProperties: {
              index: match.matchIndex,
              name: match.matchName,
              scheduledAt: match.scheduledAt.toISOString(),
              type: match.matchType,
              externalId: match.externalId === "" ? null : match.externalId || undefined,
              metadata: match.metadata,
            },
          });
        } catch (error) {
          alert.showFailureAlert("Error while updating match data");
        }

        //handle team updated
        for (const team of [teamA, teamB]) {
          try {
            if (team) {
              if (team?.prevTeamId && team.team === undefined) {
                //delete team from match
                await deleteMatchTeam({
                  matchId: match.matchId,
                  teamId: team.prevTeamId,
                });
              } else if (team.prevTeamId && team.team && team.prevTeamId === team.team?.id) {
                //update team score
                await updateMatchTeams({
                  matchId: match.matchId,
                  teamId: team.prevTeamId,
                  score: team.score || 0,
                });
              } else if (team.prevTeamId && team.team && team.prevTeamId !== team.team.id) {
                //update team by removing and creating (teamId is primary key on relational table)
                await deleteMatchTeam({
                  matchId: match.matchId,
                  teamId: team.prevTeamId,
                });
                await createMatchTeam({
                  matchId: match.matchId,
                  teamId: team.team.id,
                  score: team.score || 0,
                  index: team.teamIndex,
                });
              } else if (team.prevTeamId === undefined && team.team) {
                // add team to match
                await createMatchTeam({
                  matchId: match.matchId,
                  teamId: team.team.id,
                  score: team.score || 0,
                  index: team.teamIndex,
                });
              }
            }
          } catch (error) {
            alert.showFailureAlert("Error while updating team data, check console for more info");
            throw error;
          }
        }

        try {
          await matchAutoProgress({ gameId, matchId: match.matchId });
        } catch (error) {
          alert.showInfoAlert("Error while auto progressing match, check console for more info");
          // eslint-disable-next-line no-console
          console.log(error);
        }

        //refetch matche data with updated data
        await queryClient.invalidateQueries(["matches", tournamentId]);
        await queryClient.refetchQueries(["matches", tournamentId]);
      }
    },
    onSuccess,
    onError,
  });
}
