import { z } from "zod";

export const ServerStatusSchema = z.enum([
  "WAITING",
  "MATCH_ONGOING",
  "MATCH_ENDED",
]);

export const DbServerSchema = z.object({
  id: z.string().min(1),
  description: z.string().nullish(),
  ip: z.string().min(1),
  port: z.number(),
  rconPassword: z.string().min(1),
  gameId: z.string().min(1),
  matchId: z.string().nullish(),
  status: ServerStatusSchema,
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
});

export type DbServer = z.infer<typeof DbServerSchema>;

export const ServerResponseSchema = DbServerSchema.omit({
  deletedAt: true,
});

export const ServersResponseSchema = ServerResponseSchema.array().readonly();

export const ServerUpdateSchema = DbServerSchema.pick({
  ip: true,
  port: true,
  rconPassword: true,
  matchId: true,
  status: true,
}).partial();

export const ServerInsertSchema = z.object({
  id: z.string().min(1).nullish(),
  ip: z.string().min(1).nullish(),

  matchId: z.string().min(1).nullish(),
  status: ServerStatusSchema,
  gameId: z.enum(["cs2"]),
  description: z.string().nullish(),
});
