import { useMutation } from "@tanstack/react-query";

import {
  createBroadcast,
  CreateBroadcastPayload,
} from "../../../../../api/broadcasts";
import { Patron } from "../../../../../providers/PatronProvider/types";

export type { CreateBroadcastPayload };

const useCreateBroadcast = ({ patron }: { patron: Patron }) =>
  useMutation({
    mutationKey: ["broadcasts", patron],
    mutationFn: ({
      patron,
      broadcast,
    }: {
      patron: Patron;
      broadcast: CreateBroadcastPayload;
    }) => createBroadcast({ patron, payload: broadcast }),
  });

export default useCreateBroadcast;
