import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import classNames from "classnames";
import { ChangeEventHandler, useMemo, useState } from "react";
import { assetsURL } from "../../../../../../../config";
import usePointsContext from "../../../../../context/PointsConsumer";
import { SelectCollectionDropdown } from "./components/selectCollectionDropdown";
import { BaseProductSchema } from "../../../../../../../api/points/schemas";
import { useGetPointsProducts } from "../../../../../../../hooks/useGetPointsProducts";

const actions = [
  "NONE",
  "GRANT_VIP",
  "GRANT_TEAM_TAKEOVER",
  "GRANT_TEAM_TAKEOVER_VOTE",
  "GRANT_HEAD_TO_HEAD_POLL",
  "GRANT_EMOJI_EXPLOSION",
  "REDEEM_CASE",
];
export const DetailsFields = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [preview, setPreview] = useState<string | undefined>("");

  const { productData, productMethods, imageMethods, editCreateModalData } = usePointsContext();
  const {
    type,
    raffleTicketProductId,
    name,
    action,
    price,
    isPublished,
    id,
    cooldown,
    isRedeemableFromChat,
    isHidden,
    allowedRedemptions,
    description,
    product,
    isRedeemableOnlyOnce,
  } = productData;
  const {
    setType,
    setRaffleTicketProductId,
    setName,
    setAction,
    setPrice,
    setIsPublished,
    setCooldown,
    setIsHidden,
    setIsRedeemableFromChat,
    setAllowedRedemptions,
    setId,
    setDescription,
    setIsRedeemableOnlyOnce,
  } = productMethods;
  const { data: products } = useGetPointsProducts();
  const raffleTicketProducts = useMemo(
    () => products?.products.filter((product) => product.type === "raffle-ticket" && product.id !== id) ?? [],
    [products, id],
  );

  const { setFile } = imageMethods;

  const handlePriceChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (isNaN(Number(event.target.value))) return;

    setPrice(Number(event.target.value));
  };

  const handleRedemptionChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (isNaN(Number(event.target.value))) return;

    setAllowedRedemptions(Number(event.target.value));
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setAction(event.target.value);
  };
  const handleTimeLimitChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (isNaN(Number(event.target.value))) return;

    setCooldown(Number(event.target.value));
  };

  const handleSetDescription: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setDescription(event.target.value);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => typeof reader.result === "string" && setPreview(reader.result);
    }
  };

  return (
    <>
      <span className="pl-1 font-style-label-4">Product details</span>
      <div className="grid grid-cols-2 gap-4 pb-5 pt-4">
        <TextField
          id="outlined"
          label="Id"
          className="w-full"
          value={id}
          onChange={(e) => {
            setId(e.target.value);
          }}
          InputProps={{
            readOnly: editCreateModalData.isEdit,
          }}
          disabled={editCreateModalData.isEdit}
        />
        <TextField
          id="outlined"
          label="Name"
          className="w-full"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl>
          <InputLabel>Product Type</InputLabel>
          <Select
            disabled={editCreateModalData.isEdit}
            label="Product Type"
            value={type}
            onChange={(event) => {
              const type = BaseProductSchema.shape.type.parse(event.target.value);
              if (type === "raffle-reward" || type === "raffle-ticket") {
                setAction("NONE");
                setIsPublished(false);
                setPrice(0);
              } else {
                setRaffleTicketProductId(undefined);
              }
              return setType(type);
            }}
          >
            <MenuItem value="default">Default</MenuItem>
            <MenuItem value="raffle-ticket">Raffle Ticket</MenuItem>
            <MenuItem value="raffle-reward">Raffle Reward</MenuItem>
          </Select>
          <FormHelperText>
            All products other than <b>Raffle Reward</b> and <b>Raffle Ticket</b> must be <b>Default</b>. Raffle
            products do not have any actions associated with them.
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth className={classNames("transition-all", { "opacity-0": type !== "raffle-reward" })}>
          <InputLabel>Raffle Ticket Product</InputLabel>
          <Select
            disabled={type !== "raffle-reward"}
            label="Raffle Ticket Product"
            value={raffleTicketProductId}
            onChange={(e) => setRaffleTicketProductId(e.target.value ?? undefined)}
            renderValue={(selected) => {
              const selectedProduct = raffleTicketProducts.find((product) => product.id === selected);
              return (
                <div className="flex items-center">
                  <img
                    src={`${assetsURL}/images/products/${selectedProduct?.id}`}
                    alt={selectedProduct?.name}
                    className="mr-2 size-6"
                  />
                  {selectedProduct?.name}
                </div>
              );
            }}
          >
            <MenuItem key="none" value="">
              <em>None</em>
            </MenuItem>
            {raffleTicketProducts.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                <div className="flex items-center">
                  <img src={`${assetsURL}/images/products/${product.id}`} alt={product.name} className="mr-2 size-6" />
                  {product.name}
                </div>
              </MenuItem>
            ))}
            {/* when no raffle ticket products are available */}
            {raffleTicketProducts.length === 0 && (
              <MenuItem disabled>
                <em>No raffle ticket products found</em>
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl className="w-full">
          <InputLabel>Action</InputLabel>
          <Select label="Action" value={action} onChange={handleCategoryChange} disabled={type !== "default"}>
            {actions.map((action) => (
              <MenuItem key={action} value={action}>
                {action}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="w-full" variant="standard">
          <InputLabel htmlFor="standard-adornment-amount">Price</InputLabel>
          <Input
            value={price}
            onChange={handlePriceChange}
            id="standard-adornment-amount"
            disabled={type === "raffle-reward"}
            startAdornment={<InputAdornment position="start">₱</InputAdornment>}
          />
        </FormControl>
        <div className="col-span-2 flex flex-col gap-2">
          <TextField fullWidth value={description} label="Description" id="fullWidth" onChange={handleSetDescription} />
          {action === "REDEEM_CASE" && <SelectCollectionDropdown />}
        </div>
        <div className="col-span-2 w-full place-content-start items-start">
          <div className="flex w-fit items-center justify-center gap-4">
            <div className="size-20">
              <img
                alt="product"
                className={classNames("h-full w-full rounded-full", {
                  hidden: !imageLoaded,
                })}
                onLoad={() => setImageLoaded(true)}
                src={preview ? preview : `${assetsURL}/images/products/${id}`}
              />
              {!imageLoaded && <div className="size-full rounded-full border border-dashed border-neutral-50" />}
            </div>
            <Button variant="contained" component="label">
              Upload File
              <input type="file" hidden onChange={handleFileUpload} />
            </Button>
            <FormControlLabel
              control={<Switch />}
              checked={isPublished}
              onChange={(_, checked) => setIsPublished(checked)}
              disabled={type === "raffle-reward"}
              label="Active"
            />
            <FormControlLabel
              control={<Switch />}
              checked={isRedeemableFromChat}
              onChange={(_, checked) => setIsRedeemableFromChat(checked)}
              label="Redeemable only from chat"
            />
            <FormControlLabel
              control={<Switch />}
              checked={isHidden}
              onChange={(_, checked) => setIsHidden(checked)}
              label="Hidden"
            />
          </div>
        </div>
        <div className="grid size-full grid-cols-3 place-content-center items-center"></div>
      </div>
      <div className="rounded bg-neutral-800 px-1">
        <span className="font-style-label-4">Redemption Rules</span>
        <div className="grid grid-cols-2 gap-4 pb-5 pt-4">
          <FormControl className="w-full" variant="standard">
            <InputLabel htmlFor="max-redemptions">Max redemptions</InputLabel>
            <Input
              value={allowedRedemptions}
              onChange={handleRedemptionChange}
              id="max-redemptions"
              startAdornment={
                <InputAdornment position="start">{`${
                  product?.redemptionsLeft ? product?.redemptionsLeft : allowedRedemptions
                } / `}</InputAdornment>
              }
              endAdornment={<InputAdornment position="start">Redemptions</InputAdornment>}
            />
          </FormControl>
          <FormControl className="w-full" variant="standard">
            <InputLabel htmlFor="time-limit">Cooldown time</InputLabel>
            <Input
              value={cooldown}
              onChange={handleTimeLimitChange}
              id="time-limit"
              endAdornment={<InputAdornment position="start">Seconds</InputAdornment>}
            />
          </FormControl>
          <FormControlLabel
            control={<Switch />}
            checked={isRedeemableOnlyOnce}
            onChange={(_, checked) => setIsRedeemableOnlyOnce(checked)}
            label="Redeemable only once per user"
          />
        </div>
      </div>
    </>
  );
};
