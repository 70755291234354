import { useQuery } from "@tanstack/react-query";

import { getBroadcastById } from "../../api/broadcasts";
import { Patron } from "../../providers/PatronProvider/types";

const useFetchBroadcastById = (options: { patron?: Patron; id: string }) => {
  const patron = options.patron || "blasttv";
  return useQuery({
    queryKey: ["broadcasts", patron, options.id],
    queryFn: () => getBroadcastById({ patron, id: options.id }),
  });
};

export default useFetchBroadcastById;
