import { useMutation } from "@tanstack/react-query";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

import type { GrantPointsData } from "../../../../api/points/schemas";
import { grantPoints } from "../../../../api/points";

export const useGrantPoints = () => {
  const { showSuccessAlert, showFailureAlert } = useAlert();

  return useMutation({
    mutationFn: (data: GrantPointsData) => grantPoints(data),
    onSuccess(res) {
      if (!res.hasErrors) {
        showSuccessAlert(res.message);
      } else {
        showFailureAlert(res.message);
      }
    },
  });
};
