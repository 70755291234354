import { Button, Skeleton } from "@mui/material";
import useFetchCircuits from "../hooks/useFetchCircuits";
import { useState } from "react";
import CreateAndEditCircuitModal from "./components/CreateEditCircuitModal";
import { Link, useParams } from "react-router-dom";

export interface CircuitBuilder {
  name?: string;
  startDate?: Date;
  endDate?: Date;
  gameId?: string;
  id?: string;
}

const emptyCircuit = {
  name: undefined,
  startDate: undefined,
  endDate: undefined,
  gameId: "cs",
};

const CircuitsPage = () => {
  const { gameId } = useParams();

  const [circuitModalStatus, setCircuitModalStatus] = useState<"new" | "edit" | "closed">("closed");
  const [editCircuit, setEditCircuit] = useState<CircuitBuilder>(emptyCircuit);

  const { data: circuits, isFetching } = useFetchCircuits(gameId);

  return (
    <div className="w-full p-7">
      <h1 className="font-style-desktop-h2">Circuits</h1>
      <Button
        variant="contained"
        className="my-4"
        onClick={() => {
          setEditCircuit({ ...emptyCircuit, gameId });
          setCircuitModalStatus("new");
        }}
      >
        Add Circuit
      </Button>
      <div className="flex flex-wrap gap-4">
        {isFetching ? (
          <>
            <Skeleton variant="rounded" width="18rem" height="8rem" />
            <Skeleton variant="rounded" width="18rem" height="8rem" />
          </>
        ) : (
          circuits?.map((circuit) => (
            <div className="flex w-72 flex-col rounded-small bg-slate-800 p-3" key={circuit.id}>
              <span className="font-style-label-1">{circuit.name}</span>
              <span className="font-style-b2-body-copy">
                Start Date: {circuit.startDate.toISOString().split("T")[0]}
              </span>
              <span className="font-style-b2-body-copy">End Date: {circuit.endDate.toISOString().split("T")[0]}</span>
              <span className="font-style-b2-body-copy">Game: {circuit.gameId}</span>
              <hr className="my-3 w-full self-center border-solid border-slate-400" />
              <div>
                <Button>
                  <Link to={`/to/tournaments/${gameId}/?circuitId=${circuit.id}`}>Go to Tournaments</Link>
                </Button>
                <Button
                  onClick={() => {
                    setEditCircuit(circuit);
                    setCircuitModalStatus("edit");
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
      <CreateAndEditCircuitModal
        circuitModalStatus={circuitModalStatus}
        handleClose={() => {
          setCircuitModalStatus("closed");
          setEditCircuit(emptyCircuit);
        }}
        circuit={editCircuit}
        handleChange={setEditCircuit}
      />
    </div>
  );
};

export default CircuitsPage;
