import { environment } from "../../../config";

export interface Episode {
  title: string;
  url: string;
}

export interface Trailer {
  title: string;
  url: string;
}

/**
 * Maps the trailers and episode into title and urls for each of them
 * @param series the selected series (used to create the urls for the video files)
 * @param records list coming from the video api, which includes string of both trailers and episodes
 */
export const mapTrailerAndEpisodes = ({
  series,
  records,
}: {
  series: string;
  records: string[];
}): { trailers: Trailer[]; episodes: Episode[] } => {
  const trailers: Trailer[] = [];
  const episodes: Episode[] = [];

  records.forEach((record) => {
    // Trailer folder name (<episode number>-trailer-<uuid>) or (trailer-<uuid>)
    if (
      record.match(
        /trailer-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
      )
    ) {
      const titleWithoutUuid = record.split(
        /-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
      )[0];

      const url =
        environment === "production"
          ? `https://content.blast.tv/${series}/${record}/${series}-${titleWithoutUuid}.m3u8`
          : `https://content.blast-dev.tv/${series}/${record}/${series}-${titleWithoutUuid}.m3u8`;

      trailers.push({
        title: titleWithoutUuid.replaceAll("-", " "),
        url,
      });
    } else {
      const titleWithoutUuid = record.split(
        /-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
      )[0];

      const url =
        environment === "production"
          ? `https://content.blast.tv/${series}/${record}/${series}-${titleWithoutUuid}.m3u8`
          : `https://content.blast-dev.tv/${series}/${record}/${series}-${titleWithoutUuid}.m3u8`;

      episodes.push({
        title: titleWithoutUuid.replaceAll("-", " "),
        url,
      });
    }
  });

  return {
    episodes,
    trailers,
  };
};
