import { Autocomplete, TextField } from "@mui/material";
import { Country } from "../../../../common/countries";
import { useState } from "react";

interface CountryPickerProps {
  countries: ReadonlyArray<Country>;
  labelText: string;
  defaultValue: Country["code"];

  onCountrySelection: (selectedCountryCode: string) => void;
}

export const CountryPicker = ({
  countries,
  labelText,
  defaultValue,
  onCountrySelection,
}: CountryPickerProps) => {
  const [country, setCountry] = useState<{
    id: Country["code"];
    label: Country["name"];
  }>();

  const countryCode = defaultValue as Country["code"];
  const countryName = countries.find((country) => country.code === defaultValue)
    ?.name as Country["name"];

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={countries
        .map((country) => {
          return { id: country.code, label: country.name };
        })
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelText}
          variant="standard"
          value={{
            id: countryCode,
            label: countryName || countryCode,
          }}
        />
      )}
      value={
        country || {
          id: countryCode,
          label: countryName || countryCode,
        }
      }
      onChange={(_, value) => {
        const country = countries.find((country) => country.code === value?.id);
        if (country) {
          setCountry({
            id: country.code,
            label: country.name,
          });
          onCountrySelection(country.code);
        }
      }}
    />
  );
};
