import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { RLCSRankingsDataGrid } from "./components/RLCSRankingsDataGrid";
import { useEffect, useState } from "react";
import useFetchCategories from "./hooks/useFetchCategories";
import useFetchTeams from "./hooks/useFetchTeams";
import { postRLLeaderboard } from "../../../api/tournaments";
import { GridRowsProp } from "@mui/x-data-grid";
import ConfirmModal from "../../../components/ConfirmModal";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";
import { useQueryClient } from "@tanstack/react-query";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useGetTournaments } from "../../../api/tournaments/getTournaments/useGetTournaments";

interface RLCSRanking {
  teamId: string;
  points: number;
}

export const RLCSRankings = () => {
  const alert = useAlert();
  const [mode, setMode] = useState<"create" | "add">("add");

  const [category, setCategory] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string>("");
  const [tournament, setSelectedTournaments] = useState<string>("");
  const [date, setDate] = useState<DateTime | null>(DateTime.fromJSDate(new Date()));
  const [teams, setTeams] = useState<RLCSRanking[]>([]);
  const queryClient = useQueryClient();

  const { data: categoryRecords, isLoading: categoryRecordsLoading } = useFetchCategories("rl");
  const { data: teamRecords, isLoading: teamsIsLoading } = useFetchTeams("rl");
  const { data: tournaments } = useGetTournaments({ game: "rl" });

  const [availableCategories, setAvailableCategories] = useState<string[]>([]);
  const [availableSubCategories, setAvailableSubCategories] = useState<string[]>([]);
  const [availableTeams, setAvailableTeams] = useState<Record<string, string>>({});

  const [rows, setRows] = useState<GridRowsProp>([]);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setTeams(
      rows
        .filter((r) => !r.isNew)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        .map((r) => ({ teamId: r.teamId, points: r.points })),
    );
  }, [rows, setTeams]);

  useEffect(() => {
    setCategory("");
    setSubCategory("");
    setSelectedTournaments("");
  }, [mode]);

  useEffect(() => {
    setSubCategory("");
    setSelectedTournaments("");
    setAvailableSubCategories(categoryRecords?.find((cat) => cat.category === category)?.subCategories ?? []);
  }, [category, categoryRecords]);

  useEffect(() => {
    setCategory("");
    setSelectedTournaments("");
    setAvailableCategories(categoryRecords?.map((cat) => cat.category) ?? []);
  }, [categoryRecords]);

  useEffect(() => {
    if (teamRecords) {
      const teams: Record<string, string> = {};
      for (const record of teamRecords) {
        teams[record.id] = record.name;
      }
      setAvailableTeams(teams);
    } else {
      setAvailableTeams({});
    }
  }, [setAvailableTeams, teamRecords]);

  const isFormValid = () => category?.length && subCategory?.length && tournament?.length && date && teams.length;

  const handleSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    setIsSubmitting(true);
    postRLLeaderboard({
      category: category,
      subCategory: subCategory,
      tournamentId: tournament,
      calculationDate: date!.toISO()!.split("T")[0],
      teams: teams.map((x) => ({
        points: x.points,
        teamId: x.teamId,
        teamName: teamRecords!.find((t) => t.id === x.teamId)!.name ?? "",
      })),
    })
      .then(() => {
        setIsSubmitting(false);
        setCategory("");
        setSubCategory("");
        setSelectedTournaments("");
        setRows([]);
        alert.showSuccessAlert("Leaderboard successfully submitted!");
        queryClient.invalidateQueries(["rankings", "categories", "rl"]).catch(() => {
          // eslint-disable-next-line no-console
          console.error("Failed to invalidate queries");
        });
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setIsSubmitting(false);
        alert.showFailureAlert("An error occured when submitting leaderboard. Please check the console for details.");
      });
  };

  if (categoryRecordsLoading || teamsIsLoading) {
    <div>Loading...</div>;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
        <Grid container component="main" maxWidth="xl">
          <Grid item xs={8} sx={{ p: 5 }}>
            <Typography component="h1" variant="h5">
              RLCS Ranking
            </Typography>
            <Typography component="p" variant="subtitle1">
              Attribute points to teams for RLCS Leaderboards.
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-action-label">Select Action</InputLabel>
                    <Select
                      labelId="select-action-label"
                      id="select-action"
                      value={mode}
                      label="Select action"
                      onChange={(e) => setMode(e.target.value === "add" ? "add" : "create")}
                    >
                      <MenuItem value={"create"}>Create new ranking</MenuItem>
                      <MenuItem value={"add"}>Add points to existing ranking</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {mode === "create" && (
                    <TextField
                      name="category"
                      required
                      fullWidth
                      id="category"
                      label="Category"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    />
                  )}

                  {mode === "add" && (
                    <FormControl fullWidth>
                      <InputLabel id="select-category-label">Select Category</InputLabel>
                      <Select
                        labelId="select-category-label"
                        id="select-category"
                        value={category}
                        label="Select category"
                        onChange={(e) => setCategory(e.target.value)}
                        required
                      >
                        {availableCategories?.map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {mode === "create" && (
                    <TextField
                      required
                      fullWidth
                      id="subCategory"
                      label="Sub-category"
                      name="subCategory"
                      onChange={(e) => setSubCategory(e.target.value)}
                    />
                  )}
                  {mode === "add" && (
                    <FormControl fullWidth>
                      <InputLabel id="select-sub-category-label">Select Sub-Category</InputLabel>
                      <Select
                        labelId="select-sub-category-label"
                        id="select-sub-category"
                        value={subCategory}
                        label="Select sub-category"
                        onChange={(e) => setSubCategory(e.target.value)}
                        required
                        disabled={!category.length}
                      >
                        {availableSubCategories.map((subCategory) => (
                          <MenuItem key={subCategory} value={subCategory}>
                            {subCategory}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="select-tournament-label">Select tournament</InputLabel>
                    <Select
                      labelId="select-tournament-label"
                      id="select-tournament"
                      value={tournament}
                      label="Select tournament"
                      onChange={(e) => setSelectedTournaments(e.target.value)}
                      required
                      disabled={!category.length || !subCategory.length}
                    >
                      {tournaments?.map((tournament) => (
                        <MenuItem key={tournament.id} value={tournament.id}>
                          {tournament.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker label="Date" format="dd/LL/yyyy" value={date} onChange={(value) => setDate(value)} />
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              <RLCSRankingsDataGrid rows={rows} setRows={setRows} availableTeams={availableTeams} />
              <Button
                onClick={() => setConfirmationOpen(true)}
                type="button"
                disabled={!isFormValid() || isSubmitting}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {mode === "add" ? "Add Points" : "Create Ranking"}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ p: 5 }}>
            <Typography component="h2" variant="h6">
              Usage Instructions
            </Typography>
            <List sx={{ listStyleType: "disc", p: 2 }}>
              <ListItem sx={{ display: "list-item" }}>
                The &quot;Category&quot; field is for tournaments in the format &quot;rlcs-eu-2024&quot;, the
                &quot;Sub-Category&quot; field is for the specific majors, e.g. &quot;major-1&quot;.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Points are automatically added to the specific leaderboard as well as the WC leaderboard.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Please use a single date preferably at the end of a given tournament for updating point rankings to
                allow the arrow changes on the site to be reflected correctly.
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </LocalizationProvider>

      <ConfirmModal
        open={confirmationOpen}
        onOpenChange={() => setConfirmationOpen(false)}
        onSubmit={() => {
          handleSubmit();
          setConfirmationOpen(false);
        }}
      >
        <span>Are you sure you want to submit the leaderboard points?</span>
      </ConfirmModal>
    </>
  );
};
