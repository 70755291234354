import { useMutation } from "@tanstack/react-query";
import { ScheduleRequest } from "../../../api/csguessr/types";
import { deleteSchedule } from "../../../api/csguessr";

const useDeleteSchedule = ({
  schedule,
  onSuccess,
  onError,
}: {
  schedule?: ScheduleRequest;
  onSuccess: () => void;
  onError: () => void;
}) => {
  return useMutation({
    mutationKey: ["schedule", schedule?.locationId],
    onSuccess: () => onSuccess(),
    onError: () => onError(),
    mutationFn: schedule ? () => deleteSchedule(schedule) : undefined,
  });
};

export default useDeleteSchedule;
