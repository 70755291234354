import { toast } from "@/hooks/use-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { rlcsStatQueryKeys } from "../rlcsStatQueryKeys";
import { updateRlcsSchedule } from ".";

export const useUpdateRlcsSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateRlcsSchedule,
    onSuccess: () => {
      void queryClient.invalidateQueries(rlcsStatQueryKeys.schedules);
      toast({
        title: "Schedule updated successfully",
        description: "The schedule has been updated successfully",
      });
    },
    onError: () => {
      toast({
        title: "Failed to update schedule",
        description: "The schedule could not be updated",
        variant: "destructive",
      });
    },
  });
};
