import { useState } from "react";
import useFetchAwards from "../hooks/awards/useFetchAwards";
import { Button, Stack } from "@mui/material";
import { AwardCard } from "./components/AwardCard";
import useFetchTeams from "../hooks/teams/useFetchTeams";
import useFetchPlayers from "../Players/hooks/useFetchPlayers";
import { TournamentAwardModal } from "./components/TournamentAwardModal";
import { CreateAwardModal } from "./components/CreateAwardModal";
import { useGetTournaments } from "../../../api/tournaments/getTournaments/useGetTournaments";

export const AwardsPage = () => {
  const [createTournamentAwardModal, setCreateTournamentAwardModal] = useState<boolean>(false);
  const [createAwardModal, setCreateAwardModal] = useState<boolean>(false);

  const { data: awards } = useFetchAwards();
  const { data: teams } = useFetchTeams();
  const { data: players } = useFetchPlayers();
  const { data: tournaments } = useGetTournaments();

  return (
    <div className="p-7">
      <div className="my-3">
        <h1 className="my-3 font-style-desktop-h2">Awards</h1>
        <Button variant="contained" onClick={() => setCreateTournamentAwardModal(true)}>
          Create Tournament Award
        </Button>
        <Button variant="contained" className="mx-9" onClick={() => setCreateAwardModal(true)}>
          Create Award
        </Button>
      </div>
      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={2}>
        {awards &&
          awards.map((award) => {
            return (
              <AwardCard
                key={award.id}
                inputAward={award}
                teams={teams || []}
                players={players || []}
                tournaments={tournaments || []}
              />
            );
          })}
      </Stack>
      <TournamentAwardModal
        handleClose={() => setCreateTournamentAwardModal(false)}
        isOpen={createTournamentAwardModal}
        tournaments={tournaments || []}
        players={players || []}
        teams={teams || []}
      />
      <CreateAwardModal
        close={() => setCreateAwardModal(false)}
        isOpen={createAwardModal}
        tournaments={tournaments || []}
        players={players || []}
        teams={teams || []}
      />
    </div>
  );
};
