import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMatch } from "../../../../api/tournaments";

export default function useDeleteMatch({
  tournamentId,
  onSuccess,
  onError,
}: {
  tournamentId?: string;
  onSuccess: () => void;
  onError: () => void;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deleteMatch"],
    mutationFn: async ({ matchId }: { matchId: string }) => {
      await deleteMatch({ matchId });
      if (tournamentId) {
        await queryClient.invalidateQueries(["matches", tournamentId]);
        await queryClient.refetchQueries(["matches", tournamentId]);
      } else {
        throw Error("TournamentId is undefined cannot reftech tournament data");
      }
    },
    onSuccess,
    onError,
  });
}
