import { createContext } from "react";
import { PointsProduct } from "../../../api/points/schemas";

interface ProductData {
  type: PointsProduct["type"];
  raffleTicketProductId: PointsProduct["raffleTicketProductId"];
  name: string;
  action: string;
  price: number;
  isPublished: boolean;
  id: string;
  isRedeemableFromChat: boolean;
  cooldown: number | undefined;
  isHidden: boolean;
  allowedRedemptions: number;
  description: string;
  product: PointsProduct | null;
  isRedeemableOnlyOnce: boolean;
  collectionId?: string;
}

interface ProductMethods {
  setType: (type: PointsProduct["type"]) => void;
  setRaffleTicketProductId: (raffleTicketProductId: PointsProduct["raffleTicketProductId"]) => void;
  setName: (name: string) => void;
  setAction: (category: string) => void;
  setPrice: (price: number) => void;
  setIsPublished: (forSale: boolean) => void;
  setIsHidden: (isHidden: boolean) => void;
  setCooldown: (cooldown: number | undefined) => void;
  setIsRedeemableFromChat: (isRedeemableFromChat: boolean) => void;
  setAllowedRedemptions: (allowedRedemptions: number) => void;
  setId: (id: string) => void;
  setDescription: (description: string) => void;
  setIsRedeemableOnlyOnce: (redeemableOnlyOnce: boolean) => void;
  setCollectionId: (collectionId: string | undefined) => void;
}

interface EditCreateModalData {
  isOpen: boolean;
  isEdit: boolean;
}

interface EditCreateModalMethods {
  handleClose: () => void;
  createNew: () => void;
  editExisting: (product: PointsProduct) => void;
}

interface ImageData {
  file: File | null;
}

interface ImageMethods {
  setFile: (file: File | null) => void;
}

export interface PointsContextProps {
  productData: ProductData;
  productMethods: ProductMethods;
  imageData: ImageData;
  imageMethods: ImageMethods;
  editCreateModalData: EditCreateModalData;
  editCreateModalMethods: EditCreateModalMethods;
}

const StateContext = createContext<PointsContextProps | undefined>(undefined);

export default StateContext;
