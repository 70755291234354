import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { DateTime } from "luxon";
import { useState } from "react";
import DateTimeInput from "../../../../components/DateTimeInput";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import useCreateStream from "../../hooks/useCreateStream";

type HighestProfile = "1080p" | "1440p" | "2160p";

export function CreateStreamDialog({
  open,
  onClose,
  onStreamCreated,
}: {
  open: boolean;
  onClose: () => void;
  onStreamCreated: () => void;
}) {
  const [streamProvider, setStreamProvider] = useState<"aws" | "gcp">("gcp");

  const { mutate, isLoading } = useCreateStream({
    onSuccess: () => {
      onStreamCreated();
      onClose();
    },
    onError: () => {
      showFailureAlert(`Something went wrong while creating the stream. Please try again.`);
    },
  });

  const [dateValue, setDateValue] = useState<DateTime | undefined>(DateTime.now());
  const [title, setTitle] = useState<string>("");
  const [timelineOffset, setTimelineOffset] = useState<number | undefined>(undefined);
  const [resolution, setResolution] = useState<string>("1080p");
  const [inputTier, setInputTier] = useState<"HD" | "UHD">("HD");

  const { showFailureAlert } = useAlert();

  const changeStreamProvider = (value: string) => {
    if (value === "aws" || value === "gcp") {
      setStreamProvider(value);
    }
  };

  const create = () => {
    const newTitle = title.trim();
    const startTime = dateValue?.toUTC().toString();

    if (!newTitle || !timelineOffset || !resolution || !streamProvider || (streamProvider === "aws" && !startTime)) {
      showFailureAlert("Please fill all required fields");
      return;
    }
    mutate({
      title: newTitle,
      startTime,
      timelineOffset,
      highestProfile: resolution as HighestProfile,
      inputTier,
      provider: streamProvider,
    });
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create stream</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label htmlFor="title">Stream title *</Label>
            <Input
              id="title"
              placeholder="Enter stream title here"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="grid gap-2">
            <Label>Stream Provider *</Label>
            <Select value={streamProvider} onValueChange={changeStreamProvider}>
              <SelectTrigger>
                <SelectValue placeholder="Select provider" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="gcp">GCP</SelectItem>
                <SelectItem value="aws">AWS</SelectItem>
              </SelectContent>
            </Select>
            <p className="text-sm text-muted-foreground">AWS does not support UHD 60fps streams</p>
          </div>

          <div className="grid gap-2">
            <Label>Highest Resolution *</Label>
            <Select
              defaultValue="1080p"
              value={resolution}
              onValueChange={(value) => {
                setResolution(value);
                if (value === "2160p") {
                  setInputTier("UHD");
                } else {
                  setInputTier("HD");
                }
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select resolution" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="1080p">1080p</SelectItem>
                <SelectItem value="1440p">1440p (Upscaling)</SelectItem>
                <SelectItem value="2160p">2160p (4K)</SelectItem>
              </SelectContent>
            </Select>
            <p className="text-sm text-muted-foreground">Select higher resolution only if it&apos;s going to be used</p>
          </div>

          <div className="grid gap-2">
            <Label>Input Resolution *</Label>
            <Select value={inputTier} onValueChange={(value) => setInputTier(value as "HD" | "UHD")}>
              <SelectTrigger>
                <SelectValue placeholder="Select input resolution" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="HD">HD</SelectItem>
                <SelectItem value="UHD">UHD</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="grid gap-2">
            <Label htmlFor="offset">Timeline Offset *</Label>
            <Input
              id="offset"
              type="number"
              placeholder="Timeline offset"
              required
              value={timelineOffset}
              onChange={(e) => setTimelineOffset(e.target.valueAsNumber)}
            />
          </div>

          {streamProvider === "aws" && (
            <div className="grid gap-2">
              <Label>Stream start time *</Label>
              <DateTimeInput onChange={(newValue: DateTime | undefined) => setDateValue(newValue)} value={dateValue} />
            </div>
          )}
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} onClick={create}>
            Create stream
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
