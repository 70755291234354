import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import useDeleteMatch from "../../../../../hooks/matches/useDeleteMatch";
import useAlert from "../../../../../../../providers/AlertProvider/hooks/useAlert";

interface DeleteConfirmModalProps {
  isOpen: boolean;
  close: () => void;
  matchId: string;
  tournamentId?: string;
  closeMatchModal: () => void;
}

export const DeleteConfirmModal = ({
  isOpen,
  close,
  matchId,
  tournamentId,
  closeMatchModal,
}: DeleteConfirmModalProps) => {
  const alert = useAlert();
  const { mutate: deleteMatch } = useDeleteMatch({
    tournamentId: tournamentId,
    onSuccess: () => alert.showSuccessAlert("Delete Match Success"),
    onError: () => alert.showFailureAlert("Delete Match Failed"),
  });

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: "600px",
          padding: "10px",
        },
      }}
      onClose={close}
    >
      <DialogContent>
        <Typography>Are you sure you want to delete this match?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            deleteMatch({ matchId });
            close();
            closeMatchModal();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
