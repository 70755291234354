interface EventDetails {
  id: string;
  name: string;
  description: string;
  priority: number;
  isManual: boolean;
}

export const events: EventDetails[] = [
  // Manual Events
  {
    id: "POG",
    name: "POG",
    description: "Cool or Nice moments",
    priority: 10,
    isManual: true,
  },
  {
    id: "KEKW",
    name: "KEKW",
    description: "Caster moments funny or explanatory moments",
    priority: 10,
    isManual: true,
  },
  {
    id: "IQ",
    name: "200 IQ",
    description: "Nice setups / plays, new boosts or new angles",
    priority: 10,
    isManual: true,
  },
  {
    id: "NINJA_DEFUSE",
    name: "Ninja Defuse",
    description: "Defuse when opposition team members still alive",
    priority: 10,
    isManual: true,
  },
  // Automatic Events
  {
    id: "RAPID",
    name: "Multikill",
    description: "≥3 kills within 8 seconds",
    priority: 6,
    isManual: false,
  },
  {
    id: "ACE",
    name: "ACE",
    description: "1 player gets 5 kills",
    priority: 10,
    isManual: false,
  },
  {
    id: "AGAINST_THE_ODDS",
    name: "Clutch",
    description: "1 vs ≥2",
    priority: 8,
    isManual: false,
  },
  {
    id: "QUICK_ROUND",
    name: "Quick Round",
    description: "Round ends within 15 seconds",
    priority: 3,
    isManual: false,
  },
  {
    id: "ECO",
    name: "ECO Win",
    description: "Team wins round with pistols/lower priced weapons",
    priority: 4,
    isManual: false,
  },
  {
    id: "KNIFE",
    name: "Knife Kill",
    description: "Player gets knifed",
    priority: 9,
    isManual: false,
  },
  {
    id: "TASER",
    name: "Taser Kill",
    description: "Player gets tased",
    priority: 9,
    isManual: false,
  },
];
