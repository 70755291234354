import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { useState, Fragment, useEffect } from "react";
import { listSeries, listEpisodesAndTrailers } from "../../../api/video";
import {
  Episode,
  mapTrailerAndEpisodes,
  Trailer,
} from "../helpers/mapTrailerAndEpisode";

function Row(props: { series: string }) {
  const { series } = props;
  const [open, setOpen] = useState(false);
  const [trailers, setTrailers] = useState<Trailer[]>([]);
  const [episodes, setEpisodes] = useState<Episode[]>([]);

  const openCollapsable = () => {
    if (!open) {
      // Fetch Episodes / Trailers Here :)
      void listEpisodesAndTrailers({ series }).then((records) => {
        const result = mapTrailerAndEpisodes({ series, records });

        setTrailers(result.trailers);
        setEpisodes(result.episodes);
      });
    }

    setOpen(!open);
  };

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell width={20}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={openCollapsable}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {series}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Trailers
              </Typography>
              <Table
                style={{ marginBottom: 15 }}
                size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={350}>Title</TableCell>
                    <TableCell>URL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trailers.map((trailer) => (
                    <TableRow key={trailer.url}>
                      <TableCell component="th" scope="row" width={350}>
                        {trailer.title}
                      </TableCell>
                      <TableCell>{trailer.url}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography variant="h6" gutterBottom component="div">
                Episodes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell width={350}>Title</TableCell>
                    <TableCell>URL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {episodes.map((episode) => (
                    <TableRow key={episode.url}>
                      <TableCell component="th" scope="row" width={350}>
                        {episode.title}
                      </TableCell>
                      <TableCell>{episode.url}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function CollapsibleTable() {
  const [series, setSeries] = useState<string[]>([]);

  useEffect(() => {
    void listSeries().then((data) => setSeries(data));
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Series</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {series.map((series) => (
            <Row key={series} series={series} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
