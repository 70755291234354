import { useQuery } from "@tanstack/react-query";
import { getTriggerPollById } from "../../../../api/triggers";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

const useFetchTriggerPollById = (id: string, enabled?: boolean) => {
  const { showFailureAlert } = useAlert();

  return useQuery({
    queryKey: ["triggerPoll", id],
    queryFn: () => getTriggerPollById(id),
    onError() {
      showFailureAlert(
        `Something went wrong while fetch the poll with id ${id}`
      );
    },
    enabled,
  });
};

export default useFetchTriggerPollById;
