import { useQuery } from "@tanstack/react-query";
import { getTournamentsByCircuitId } from "../../../api/tournaments";

export default function useFetchTournamentsByCircuitId(circuitId?: string) {
  return useQuery({
    queryKey: ["circuit", circuitId, "tournaments"],
    queryFn: () => {
      return circuitId ? getTournamentsByCircuitId(circuitId) : Promise.resolve([]);
    },
    enabled: Boolean(circuitId),
    retry: true,
  });
}
