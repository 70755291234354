import { ReactNode } from "react";
import { Dialog, DialogContent, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

type ConfirmModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitText?: string;
  cancelText?: string;
  children: ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  submitVariant?: "default" | "destructive" | "outline" | "secondary" | "ghost" | "link";
};

const ConfirmModal = ({
  open,
  onOpenChange,
  onCancel,
  onSubmit,
  submitText = "Submit",
  cancelText = "Cancel",
  submitVariant = "default",
  disabled,
  errorMessage,
  children,
}: ConfirmModalProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="min-w-[400px]">
        <div className="py-4">{children}</div>
        <DialogFooter className="gap-2">
          <Button variant="outline" disabled={disabled} onClick={onCancel}>
            {cancelText}
          </Button>
          <Button disabled={disabled} onClick={onSubmit} variant={submitVariant}>
            {submitText}
          </Button>
        </DialogFooter>
        {errorMessage && <p className="text-sm text-destructive">{errorMessage}</p>}
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
