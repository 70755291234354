import { z } from "zod";

export const CollectionTypesSchema = z.enum(["avatars", "emojis"]);

export type CollectionTypes = z.infer<typeof CollectionTypesSchema>;
export const CONTEXTS_LIST = ["blast"] as const;
export const CollectionTypeSchema = z.enum(["avatars", "emojis"]);
export const Rarity = z.string().array();

export const CollectionSchema = z.object({
  type: CollectionTypeSchema,
  context: z.enum(CONTEXTS_LIST).default(CONTEXTS_LIST[0]),
  collectionId: z.string().default(crypto.randomUUID()),
  silverRarity: z.string().array().min(1),
  goldRarity: z.string().array().min(1),
  diamondRarity: z.string().array().min(1),
  name: z.string(),
});

export const CollectionsResponseSchema = z.object({
  collections: CollectionSchema.array(),
});

export type Collection = z.infer<typeof CollectionSchema>;
