import { Link, useLocation, useParams } from "react-router-dom";
import useFetchStages from "../hooks/useFetchStages";
import useFetchTournamentById from "../hooks/useFetchTournamentById";
import { Breadcrumbs, Button, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { StageInfo } from "./components/StageInfo";
import { DbStage } from "../../../api/community-tournaments/schemas/stages";
import useFetchMatchesByTournamentId from "../hooks/matches/useFetchMatchesByTournamentId";
import { MatchModal } from "./components/MatchModal";
import CreateEditStageModal from "./components/CreateEditStageModal";
import useFetchCircuitById from "../hooks/useFetchCircuitById";
import {
  DbTournamentMatch,
  EditCommunityMatch,
  // UpdateMatchRequest,
} from "../../../api/community-tournaments/schemas/matches";
import usePostShuffleTeamsOnTournament from "../hooks/usePostShuffleTeamsOnTournament";

export interface StageBuilder {
  id?: string;
  name?: string;
  startDate?: string;
  endDate?: string | null;
  format?: string;
  index?: number;
  startDateTime?: string;
  tournamentId?: string;
}

const emptyStage: StageBuilder = {
  id: undefined,
  name: undefined,
  startDate: undefined,
  endDate: undefined,
  format: undefined,
  index: undefined,
  startDateTime: undefined,
  tournamentId: undefined,
};

const CommunityTournamentStages = () => {
  const { hash } = useLocation();
  const [selectedStage, setSelectedStage] = useState<DbStage | undefined>();
  const [shownMatches, setShownMatches] = useState<DbTournamentMatch[]>([]);
  const [selectMatch, setSelectedMatch] = useState<EditCommunityMatch | undefined>();
  const [stageModalStatus, setStageModalStatus] = useState<"new" | "edit" | "closed">("closed");
  const [editStage, setEditStage] = useState<StageBuilder>(emptyStage);

  const { circuitId, tournamentId } = useParams();
  const { mutate: shuffleTeams } = usePostShuffleTeamsOnTournament({
    tournamentId,
  });

  const { data: circuit } = useFetchCircuitById(circuitId || "");
  const { data: tournament, isFetching: isFetchingTournament } = useFetchTournamentById(tournamentId);
  const { data: stages, refetch: refetchStages } = useFetchStages(tournamentId);
  const { data: matches, refetch: refetchMatches } = useFetchMatchesByTournamentId(tournamentId);

  useEffect(() => {
    if (matches !== undefined && selectedStage !== undefined) {
      setShownMatches(matches.filter((match) => match.stageId === selectedStage?.id));
    }
  }, [matches, selectedStage]);

  useEffect(() => {
    stages && setSelectedStage(stages[0]);
  }, [stages]);

  useEffect(() => {
    if (hash) {
      setSelectedMatch(matches?.find((match) => match.id === hash.replace("#", "")));
    }
  }, [hash, matches]);

  if (!circuitId || !tournamentId) {
    return <div>Invalid URL</div>;
  }

  return (
    <div className="flex w-full flex-col">
      <Breadcrumbs aria-label="breadcrumb" sx={{ margin: "2rem 1rem 1rem 2rem" }}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/community-tournaments/tournaments">
          Community Tournaments
        </Link>
        <Link color="inherit" to={`/community-tournaments/circuits/${circuitId}/tournaments`}>
          {circuitId}
        </Link>
        <span> Stages </span>
      </Breadcrumbs>
      <div className="p-7">
        {tournament && !isFetchingTournament ? (
          <>
            <h1 className="my-3 font-style-desktop-h2">{tournament.name}</h1>
            <Button
              onClick={() => {
                setEditStage(emptyStage);
                setStageModalStatus("new");
              }}
            >
              Add Stage
            </Button>
            <Button
              onClick={() => {
                shuffleTeams();
              }}
            >
              Assign teams matches
            </Button>
            <Tabs
              value={selectedStage}
              onChange={(e, newValue: DbStage | undefined) => {
                setSelectedStage(newValue);
              }}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {stages?.map((stage) => <Tab value={stage} key={stage.id} label={stage.name} />)}
            </Tabs>
            <StageInfo
              stage={selectedStage}
              matches={shownMatches}
              circuit={circuit ? { id: circuitId, name: circuit.name } : undefined}
              tournament={tournament}
              matchClick={(match: EditCommunityMatch) => {
                setSelectedMatch(match);
              }}
              editClick={(stage) => {
                setEditStage(stage);
                setStageModalStatus("edit");
              }}
            />
            <CreateEditStageModal
              onChange={(stage) => setEditStage(stage)}
              onClose={() => setStageModalStatus("closed")}
              tournamentId={tournament.id}
              stage={editStage}
              stageModalStatus={stageModalStatus}
              onSubmit={() => {
                void refetchStages();
                void refetchMatches();
              }}
            />
            {selectMatch && (
              <MatchModal
                match={selectMatch}
                handleClose={() => {
                  setSelectedMatch(undefined);
                }}
                teams={tournament.enrolledTeams}
                updateMatch={(match: EditCommunityMatch) => {
                  setSelectedMatch(match);
                }}
                gameId={circuit?.gameId}
              />
            )}
          </>
        ) : (
          <div>Tournament Not Found</div>
        )}
      </div>
    </div>
  );
};

export default CommunityTournamentStages;
