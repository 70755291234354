import { useQuery } from "@tanstack/react-query";
import { getCircuits } from "../../../api/community-tournaments";

export default function useFetchCircuits() {
  return useQuery({
    queryKey: ["communityCircuits"],
    queryFn: () => {
      return getCircuits();
    },
    staleTime: 1000 * 3,
    enabled: true,
    retry: false,
  });
}
