import { client } from "..";
import { Pickems } from "../../types/pickems/Pickems";

export const getPickemsUids = async (): Promise<{ uid: string }[]> => {
  const query = `
    *[_type == "pickems"] | order(_updatedAt desc) {
      uid
    }`;
  return client.fetch<{ uid: string }[]>(query).catch(() => {
    throw new Error("Failed to fetch data from CMS");
  });
};

export const getPickems = async (uid: string): Promise<Pickems> => {
  // Same query that is used in portal-fronend to fetch pickems on the UI:
  const query = `
    *[_type == "pickems" && uid == "${uid}"] {
      ...,
      'pickemsShowdownQuestionsModule': pickemsShowdownQuestionsModule{
        ...,
        'aFinalistsQuestion': aFinalistsQuestion{
          ...,
          'question': question->
        },
        'bFinalistsQuestion': bFinalistsQuestion{
          ...,
          'question': question->
        },
        'aChampionQuestion': aChampionQuestion{
          ...,
          'question': question->
        },
        'bChampionQuestion': bChampionQuestion{
          ...,
          'question': question->
        },
      },
      'pickemsFinalsQuestionsModule': pickemsFinalsQuestionsModule{
        ...,
        'groupA20Question': groupA20Question{
          ...,
          'question': question->
        },
        'groupA02Question': groupA02Question{
          ...,
          'question': question->
        },
        'groupB20Question': groupB20Question{
          ...,
          'question': question->
        },
        'groupB02Question': groupB02Question{
          ...,
          'question': question->
        },
        'finalistsQuestion': finalistsQuestion{
          ...,
          'question': question->
        },
        'championQuestion': championQuestion{
          ...,
          'question': question->
        },
      },
      'pickemsGroupsQuestionsModule': pickemsGroupsQuestionsModule{
        ...,
        'groupAFinalistsQuestion': groupAFinalistsQuestion{
          ...,
          'question': question->
        },
        'groupBFinalistsQuestion': groupBFinalistsQuestion{
          ...,
          'question': question->
        },
        'groupCFinalistsQuestion': groupCFinalistsQuestion{
          ...,
          'question': question->
        },
        'groupDFinalistsQuestion': groupDFinalistsQuestion{
          ...,
          'question': question->
        },
        'finalistsQuestion': finalistsQuestion{
          ...,
          'question': question->
        },
      },
    }[0]
  `;

  return client.fetch<Pickems>(query).catch(() => {
    throw new Error("failed to fetch CMS data");
  });
};

export const getPlayerPickemsUids = async (): Promise<{ uid: string }[]> => {
  const query = `
    *[_type == "playerPickems"] | order(_updatedAt desc) {
      uid
    }`;
  return client.fetch<{ uid: string }[]>(query).catch(() => {
    throw new Error("Failed to fetch data from CMS");
  });
};
