import { useMutation, useQueryClient } from "@tanstack/react-query";

type communityQueryKeys =
  | "communityCircuits"
  | "communityTournaments"
  | "communityStages";

interface useCreateCircuitProps {
  submit: () => Promise<void>;
  onSuccess?: () => void;
  onError?: (e: unknown) => void;
  type: communityQueryKeys;
}

export const usePostPatchTO = ({
  submit,
  onSuccess,
  onError,
  type,
}: useCreateCircuitProps) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      await submit();
      await queryClient.invalidateQueries([type]);
      await queryClient.refetchQueries([type]);
    },
    {
      onSuccess,
      onError,
    },
  );
};
