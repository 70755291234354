import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { HideImage } from "@mui/icons-material";
import { ScheduledQuestion } from "../../../../../api/csquiz/shared/types";
import { assetsURL } from "../../../../../config";

export const SchedulesTable = ({
  questions,
  isLoading,
  isError,
}: {
  questions: ScheduledQuestion[];
  isLoading?: boolean;
  isError?: boolean;
}) => {
  return (
    <Table size="small" className="w-fit max-w-full">
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Image</TableCell>
          <TableCell>Question</TableCell>
          <TableCell>Answer 1</TableCell>
          <TableCell>Answer 2</TableCell>
          <TableCell>Answer 3</TableCell>
          <TableCell>Answer 4</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isError && !isLoading && (
          <TableRow>
            <TableCell colSpan={7}>Something went wrong when fetching the questions</TableCell>
          </TableRow>
        )}
        {!isError && !isLoading && questions?.length === 0 && (
          <TableRow>
            <TableCell colSpan={7}>No questions found for this date</TableCell>
          </TableRow>
        )}
        {isLoading &&
          Array.from({ length: 18 }, (_, i) => (
            <TableRow key={i}>
              {Array.from({ length: 7 }, (_, i) => (
                <TableCell key={i}>
                  <div
                    className="my-1 min-h-5 w-36 animate-pulse rounded-full bg-white/20"
                    style={{ animationDelay: `${i * 50}ms` }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        {questions?.map((question, i) => {
          return (
            <TableRow key={i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                <div className="flex w-12 items-center justify-center overflow-hidden rounded-small">
                  {question.image ? (
                    <img
                      src={`${assetsURL}/images/${question.image}`}
                      width={48}
                      alt="question"
                      className="object-contain"
                    />
                  ) : (
                    <HideImage className="opacity-20" />
                  )}
                </div>
              </TableCell>
              <TableCell>{question.text}</TableCell>
              {question.answers.map((answer, i) => (
                <TableCell key={i}>{answer}</TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
