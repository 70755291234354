import { useMutation } from "@tanstack/react-query";

import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";

import { sendChatMessage } from "../../../../../api/chat";
import { SendChatMessageOptions } from "../../../../../api/chat/options";

const useSendMessage = () => {
  const { showFailureAlert } = useAlert();

  return useMutation({
    mutationFn: (body: SendChatMessageOptions) => sendChatMessage(body),
    onError() {
      showFailureAlert("Error while sending the message");
    },
  });
};

export default useSendMessage;
