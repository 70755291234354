import { useQuery } from "@tanstack/react-query";
import { getMatchVetoes } from "../../../../api/tournaments";

export default function useFetchMatchVetoes(matchId?: string) {
  return useQuery({
    queryKey: ["vetoes", matchId],
    queryFn: () => {
      return matchId ? getMatchVetoes(matchId) : [];
    },
    enabled: true,
    retry: false,
  });
}
