import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MatchMapRoundPlayerStat } from "../../../api/statistics/schemas";
import { updateMatchMapRoundStat } from "../../../api/statistics";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";

interface UseUpdateMatchMapRoundStatOptions {
  matchId: string;
  mapNumber: number;
  roundNumber: number;
}

const useUpdateMatchMapRoundStat = ({
  matchId,
  mapNumber,
  roundNumber,
}: UseUpdateMatchMapRoundStatOptions) => {
  const queryClient = useQueryClient();
  const { showFailureAlert } = useAlert();

  const result = useMutation({
    mutationFn: (
      stats: Omit<
        MatchMapRoundPlayerStat,
        "matchUid" | "mapNumber" | "roundNumber"
      >,
    ) => updateMatchMapRoundStat({ matchId, mapNumber, roundNumber, stats }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["live stats match map round"],
      });
    },
    onError() {
      showFailureAlert(
        `Something went wrong while updating the stats with matchId ${matchId}`,
      );
    },
  });

  return result;
};

export default useUpdateMatchMapRoundStat;
