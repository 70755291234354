import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { getTriggerPolls, GetPollsParams } from "../../../../api/triggers";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

const useFetchTriggerPolls = (params: GetPollsParams) => {
  const { showFailureAlert } = useAlert();

  return useInfiniteQuery({
    queryKey: ["triggerPolls", params],
    queryFn: ({ pageParam }: QueryFunctionContext<QueryKey, string>) =>
      getTriggerPolls({ ...params, pageToken: pageParam }),
    getNextPageParam: (response) => {
      return response.nextPageToken;
    },
    onError() {
      showFailureAlert("Something went wrong while fetch the polls");
    },
  });
};

export default useFetchTriggerPolls;
