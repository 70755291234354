import { useEffect, useRef } from "react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";

import useCreateRole from "../../hooks/useCreateRole";

const CreateRole = ({ onClose }: { onClose: () => void }) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const { mutate: createRole, isLoading, isSuccess } = useCreateRole();

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    onClose();
  }, [isSuccess, onClose]);

  const create = () => {
    const name = nameRef.current?.value.trim();
    const description = descriptionRef.current?.value.trim();

    if (!name || !description || name.match(/[^A-Z_]/)) {
      return;
    }

    createRole({
      name,
      description,
    });
  };

  return (
    <Dialog open={true} onOpenChange={() => onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create Role</DialogTitle>
        </DialogHeader>
        <Separator />

        <div className="grid gap-4">
          <div className="space-y-2">
            <Label htmlFor="name">
              Name <span className="text-red-500">*</span>
            </Label>
            <Input id="name" placeholder="Enter name here" ref={nameRef} required />
            <span className="text-sm text-muted-foreground">Only capital letters and underscores are allowed.</span>
          </div>

          <div className="space-y-2">
            <Label htmlFor="description">
              Description <span className="text-red-500">*</span>
            </Label>
            <Input id="description" placeholder="Enter description here" ref={descriptionRef} required />
          </div>
        </div>

        <Separator />

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={create} disabled={isLoading}>
            Create Role
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateRole;
