import { Refresh } from "@mui/icons-material";
import { Divider, IconButton, Stack } from "@mui/material";
import { useMemo } from "react";

import PageContainer from "../../../components/Layout/PageContainer";
import ModerationListItem from "../components/ModerationListItem";

import { PatronSelect } from "@/components/PatronSelect";
import { useFetchBroadcasts } from "../../../hooks";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";

function ModerationList() {
  const { patron } = usePatron();
  const { data, isLoading, refetch } = useFetchBroadcasts({
    patron,
  });

  const { live, offline } = useMemo(() => {
    const live = data?.filter((broadcast) => broadcast.live) || [];
    const offline = data?.filter((broadcast) => !broadcast.live) || [];
    return { live, offline };
  }, [data]);

  return (
    <PageContainer>
      <div className="flex">
        <div className="flex w-full items-center gap-2">
          <div className="text-heading">Moderation</div>
          <PatronSelect />
        </div>

        <IconButton onClick={() => void refetch()}>
          <Refresh />
        </IconButton>
      </div>

      {!isLoading && (
        <>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}>Live</Divider>
          <Stack spacing={2}>
            {live.map((broadcast) => (
              <ModerationListItem key={broadcast.id} broadcast={broadcast} />
            ))}
          </Stack>
          <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}>Offline</Divider>
          <Stack spacing={2}>
            {offline.map((broadcast) => (
              <ModerationListItem key={broadcast.id} broadcast={broadcast} />
            ))}
          </Stack>
        </>
      )}
    </PageContainer>
  );
}

export default ModerationList;
