import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import usePointsCollections from "../../../../usePointsCollections/usePointsCollections";
import { Collection } from "../../../../../../../api/points/types/Collection";
import { AvatarCase } from "./components/AvatarCase";
import { useMemo } from "react";
import { EmojiCase } from "./components/EmojiCase";

const actions: Collection["type"][] = ["avatars", "emojis"];

export const DetailsFields = () => {
  const { collection, handleUpdateCollection } = usePointsCollections();

  const handleAvatarChange = (
    rarity: "diamondRarity" | "silverRarity" | "goldRarity",
    oldValue: string | null,
    newValue: string | null
  ) => {
    const currentList = collection[rarity] ?? [];

    if (newValue && oldValue) {
      //  Replace at correct position
      const oldIndex = currentList.indexOf(oldValue);
      const newList = [...currentList];

      newList[oldIndex] = newValue;

      handleUpdateCollection({
        [rarity]: newList,
      });
    } else if (newValue) {
      // Add to list
      handleUpdateCollection({
        [rarity]: [...currentList, newValue],
      });
    } else if (oldValue) {
      // Remove from list
      const newList = currentList.filter((value) => value !== oldValue);

      handleUpdateCollection({
        [rarity]: newList,
      });
    }
  };

  const hiddenAvatars = useMemo(() => {
    return [
      ...(collection.diamondRarity ?? []),
      ...(collection.goldRarity ?? []),
      ...(collection.silverRarity ?? []),
    ];
  }, [collection]);

  return (
    <>
      <span className="font-style-label-4 pl-1">Collection details</span>
      <div className="grid grid-cols-2 pb-5 gap-4 pt-4">
        <TextField
          id="outlined"
          label="Name"
          className="w-full"
          value={collection.name}
          onChange={(e) =>
            handleUpdateCollection({
              name: e.target.value,
            })
          }
        />
        <FormControl className="w-full">
          <InputLabel className="">Type</InputLabel>
          <Select
            label="Type"
            className="[pointer-events: none;]"
            value={collection.type}
            onChange={(e) => {
              if (!e.target.value) return;

              handleUpdateCollection({
                type: e.target.value as Collection["type"],
              });
            }}
          >
            {actions.map((action) => (
              <MenuItem key={action} value={action}>
                {action}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="col-span-2 flex flex-col gap-5">
          {collection.type === "avatars" && (
            <>
              <AvatarCase
                pickedAvatars={collection.silverRarity ?? []}
                hiddenAvatars={hiddenAvatars}
                rarity="silver"
                onClick={handleAvatarChange}
              />
              <AvatarCase
                pickedAvatars={collection.goldRarity ?? []}
                hiddenAvatars={hiddenAvatars}
                rarity="gold"
                onClick={handleAvatarChange}
              />
              <AvatarCase
                pickedAvatars={collection.diamondRarity ?? []}
                hiddenAvatars={hiddenAvatars}
                rarity="diamond"
                onClick={handleAvatarChange}
              />
            </>
          )}
          {collection.type === "emojis" && (
            <>
              <EmojiCase
                pickedEmojis={collection.silverRarity ?? []}
                hiddenEmojis={hiddenAvatars}
                rarity="silver"
                onClick={handleAvatarChange}
              />
              <EmojiCase
                pickedEmojis={collection.goldRarity ?? []}
                hiddenEmojis={hiddenAvatars}
                rarity="gold"
                onClick={handleAvatarChange}
              />
              <EmojiCase
                pickedEmojis={collection.diamondRarity ?? []}
                hiddenEmojis={hiddenAvatars}
                rarity="diamond"
                onClick={handleAvatarChange}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
