import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";

const blastApiClient = axios.create({
  baseURL: baseApiURL,
});

blastApiClient.interceptors.request.use(authInterceptor);

export { blastApiClient };
