import { VisibilityOff } from "@mui/icons-material";
import { Button, Fade, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, Modal, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Emoji from "../../../../types/users/emojis";
import useDeleteUserEmoji from "../../../Broadcasts/BroadcastDetails/hooks/useAddUserEmoji";
import { ActionsContainer, AvatarImage, Form, FormContainer } from "./styled";

export default function DeleteEmojiForm({
  onClose,
  open,
  emoji,
}: {
  onClose: (args?: { refresh?: boolean }) => void;
  open: boolean;
  emoji: Emoji;
}) {
  const { id } = useParams();
  const theme = useTheme();

  const {
    mutate: startDeleteEmojiFromUser,
    isSuccess: isEmojiDeleted,
    isLoading: isDeletingEmoji,
  } = useDeleteUserEmoji();

  useEffect(() => {
    if (isEmojiDeleted) {
      onClose({ refresh: true });
    }
  }, [isEmojiDeleted, onClose]);

  const handleDeleteEmoji = () => {
    if (!id) {
      return;
    }
    startDeleteEmojiFromUser({
      userId: id,
      emojiId: emoji.id,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <FormContainer>
          <Form themeColor={theme.palette.mode}>
            <h3>Delete user emoji</h3>
            <MenuItem>
              <ListItemAvatar>
                <AvatarImage src={emoji?.url} />
              </ListItemAvatar>
              <ListItemText primary={emoji?.name} />
              <ListItemIcon>
                {emoji?.hidden ? (
                  <div title="Hidden">
                    <VisibilityOff />
                  </div>
                ) : null}
              </ListItemIcon>
            </MenuItem>
            <ActionsContainer>
              <Button variant="outlined" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button variant="contained" disabled={isDeletingEmoji} onClick={() => handleDeleteEmoji()}>
                Delete
              </Button>
            </ActionsContainer>
          </Form>
        </FormContainer>
      </Fade>
    </Modal>
  );
}
