import { Dialog, DialogContent, Divider } from "@mui/material";
import { CopyBlock, dracula } from "react-code-blocks";

import { DialogRow } from "../../../../components/Dialog/DialogRow";
import { DialogTitle } from "../../../../components/Dialog/DialogTitle";
import { DialogContentText } from "../../../../components/Dialog/DIalogContentText";
import { MonitoringSNSEvent } from "../../../../api/monitoring/schemas";
import { DateTime } from "luxon";

export function SNSEventDetails({ onClose, event }: { onClose: () => void; event: MonitoringSNSEvent }) {
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>SNS Event details</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogRow>
          <DialogContentText
            title="Timestamp"
            contentTextValue={DateTime.fromISO(event.timestamp).toFormat("dd-MM-yyyy HH:mm:ss")}
            className="flex-1"
          />
          <DialogContentText title="Service" contentTextValue={event.service} className="flex-1" />
          <DialogContentText title="Subject" contentTextValue={event.subject} className="flex-1" />
        </DialogRow>
        <Divider />
        <Divider />
        <DialogContentText
          title="Metadata"
          contentTextValue={
            <CopyBlock
              language="javascript"
              text={JSON.stringify(event.payload, null, 2)}
              theme={dracula}
              wrapLongLines
            />
          }
          className="flex-1"
        />
      </DialogContent>
      <Divider />
    </Dialog>
  );
}
