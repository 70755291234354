import { useQuery } from "@tanstack/react-query";
import { getStageByTournamentId } from "../../../api/community-tournaments";

export default function useFetchStages(tournamentId?: string) {
  return useQuery({
    queryKey: ["stages", tournamentId],
    queryFn: () => {
      return tournamentId ? getStageByTournamentId(tournamentId) : undefined;
    },
    enabled: true,
    retry: false,
  });
}
