import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useState } from "react";
import { updateUsername as profileUpdateUsername } from "../../../../api/users";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import UserProfile from "../../../../types/users";
import { userQueryKeys } from "@/providers/AuthProvider";

export const EditUsernameForm = ({
  open,
  handleClose,
  data,
}: {
  open: boolean;
  handleClose: () => void;
  data: Pick<UserProfile, "id" | "username">;
}) => {
  const { id } = data;
  const { showFailureAlert } = useAlert();
  const [newUsername, setNewUsername] = useState("");
  const queryClient = useQueryClient();

  const { mutate: updateUsername, isLoading } = useMutation({
    mutationFn: () => profileUpdateUsername(id, newUsername),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: userQueryKeys.user(id),
      });
      handleClose();
    },
    onError: (error) => {
      if (isAxiosError<{ message: string }>(error)) {
        if (typeof error.response?.data?.message === "string") {
          showFailureAlert(error.response.data.message);
        } else {
          showFailureAlert("Error: See console for more info");
        }
      }
      // eslint-disable-next-line no-console
      console.error(error);
    },
  });

  return (
    <Dialog open={open} onOpenChange={() => handleClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Change Username</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              New Username
            </Label>
            <Input
              id="username"
              autoComplete="off"
              className="col-span-3"
              onChange={(event) => setNewUsername(event.target.value)}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button variant="outline" onClick={() => handleClose()} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={() => updateUsername()} disabled={isLoading}>
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
