import z from "zod";

export const MediaSchema = z.object({
  id: z.string().uuid(),
  path: z.string(),
  type: z.string(),
  size: z.number(),
  height: z.number().optional(),
  width: z.number().optional(),
  tags: z.string().array(),
});

export const MetadataSchema = z.object({
  tags: z.string().array(),
  categories: z.record(z.string(), MediaSchema.array()),
});
export type Metadata = z.infer<typeof MetadataSchema>;
