/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageContainer from "../../components/Layout/PageContainer";
import useUsernameSearch from "../../hooks/useUsernameSearch";
import useEmailSearch from "./hooks/useEmailSearch";

export default function UsersPage() {
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [id, setId] = useState<string>("");

  const { data: userProfiles, isLoading } = useUsernameSearch({
    beginsWith: username,
  });

  const { data: userProfilesOnEmail, isLoading: fetchingEmails } = useEmailSearch({
    filterBy: "email",
    beginsWith: email,
  });

  const navigate = useNavigate();

  return (
    <PageContainer>
      <div className="text-heading">Users</div>
      <div className="space-y-2">
        <h4 className="text-sm font-medium">Enter user id:</h4>
        <div className="flex items-center gap-4">
          <Input
            placeholder="UUID"
            value={id}
            onChange={(event) => setId(event.target.value)}
            className="w-[360px]"
            onKeyDown={(event) => {
              if (id && event.key === "Enter") {
                navigate(`/users/management/${id}`);
              }
            }}
          />
          {id && (
            <Button variant="outline" asChild>
              <Link to={`/users/management/${id}`}>Go to details</Link>
            </Button>
          )}
        </div>
      </div>

      <div className="space-y-2">
        <h4 className="text-sm font-medium">Search by username:</h4>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" className="w-[360px] justify-start text-left font-normal">
              Username
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[360px] p-0">
            <Command>
              <CommandInput placeholder="Search username..." value={username} onValueChange={setUsername} />
              <CommandList>
                <CommandEmpty>{username.length && isLoading ? "Loading..." : "No users found."}</CommandEmpty>
                <CommandGroup>
                  {(userProfiles ?? []).map((user) => (
                    <CommandItem key={user.id} onSelect={() => navigate(`/users/management/${user.id}`)}>
                      {user.username}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      <div className="space-y-2">
        <h4 className="text-sm font-medium">Search by email:</h4>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" className="w-[360px] justify-start text-left font-normal">
              Email
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[360px] p-0">
            <Command>
              <CommandInput placeholder="Search email..." value={email} onValueChange={setEmail} />
              <CommandList>
                <CommandEmpty>{fetchingEmails && email.length ? "Loading..." : "No users found."}</CommandEmpty>
                <CommandGroup>
                  {(userProfilesOnEmail ?? []).map((user) => (
                    <CommandItem key={user.id} onSelect={() => navigate(`/users/management/${user.id}`)}>
                      {user.email}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </PageContainer>
  );
}
