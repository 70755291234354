import { z } from "zod";

export const QuestionSchema = z.object({
  id: z.string(),
  text: z.string(),
  image: z.string().optional(),
  answers: z.array(z.string()),
  correctAnswer: z.string(),
  createdAt: z.string(),
});

export type Question = z.input<typeof QuestionSchema>;

export const ScheduledQuestionSchema = QuestionSchema.omit({ createdAt: true });

export type ScheduledQuestion = z.input<typeof ScheduledQuestionSchema>;
