import axios from "axios";
import { baseApiURL } from "../../config";
import { CorrectAnswer } from "./types";
import authInterceptor from "../authInterceptor";

const pickemsApi = axios.create({
  baseURL: `${baseApiURL}/v1`,
});
pickemsApi.interceptors.request.use(authInterceptor);

export const getCorrectAnswers = async (pickemsId: string): Promise<CorrectAnswer[]> => {
  const { data } = await pickemsApi.get<CorrectAnswer[]>(`/pickems/${pickemsId}/correct-answers`);

  return data.map((item) => ({
    ...item,
    choiceId: decodeURIComponent(item.choiceId),
  }));
};

export const postCorrectAnswer = async ({
  pickemsId,
  questionId,
  choiceId,
}: {
  pickemsId: string;
  questionId: string;
  choiceId: string;
}): Promise<CorrectAnswer> => {
  const { data } = await pickemsApi.post<CorrectAnswer>(
    `/pickems/${pickemsId}/questions/${questionId}/choices/${choiceId}/correct`,
  );

  return data;
};

export const postIncorrectAnswer = async ({
  pickemsId,
  questionId,
  choiceId,
}: {
  pickemsId: string;
  questionId: string;
  choiceId: string;
}): Promise<CorrectAnswer> => {
  const { data } = await pickemsApi.post<CorrectAnswer>(
    `/pickems/${pickemsId}/questions/${questionId}/choices/${choiceId}/incorrect`,
  );

  return data;
};

interface GetUsersWithCorrectAnswersResponse {
  count: number;
  userIds: string[];
}

export const getUsersWithCorrectAnswers = async ({
  pickemsId,
  correctAnswers,
}: {
  pickemsId: string;
  correctAnswers: number;
}): Promise<GetUsersWithCorrectAnswersResponse> => {
  const { data } = await pickemsApi.get<GetUsersWithCorrectAnswersResponse>(
    `/pickems/${pickemsId}/users?correctAnswers=${correctAnswers}`,
  );

  return data;
};

export const patchPickems = async ({ pickemsId, endTime }: { pickemsId: string; endTime: string }): Promise<void> => {
  return pickemsApi.patch(`/pickems/${pickemsId}`, {
    endTime,
  });
};
