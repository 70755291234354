import { useCallback, useEffect } from "react";
import { Button, Stack, Tooltip } from "@mui/material";

import useSuspenseUser from "../../hooks/useSuspenseUser";
import useDeleteMessage from "../../hooks/useDeleteMessage";
import useFetchUserProfile from "../../../../../hooks/useFetchUserProfile";

import { useAuth } from "../../../../../providers/AuthProvider/hooks/useAuth";

import { Suspension } from "../../../../../types/Suspension";
import { Message } from "../../../../../types/Message";
import { PublicUserProfile } from "../../../../../types/users";

interface ChatModActionsProps {
  selectedMessage: null | Message;
  unselectMessage: () => void;
  selectedUser?: null | PublicUserProfile;
  unselectUser?: () => void;
}

interface ModAction {
  isPermanent: boolean;
  intervalInMinutes: number;
  text: string;
  tooltip: string;
}

const modActions: Array<ModAction> = [
  {
    isPermanent: false,
    intervalInMinutes: 0,
    text: "1",
    tooltip: "Delete",
  },
  {
    isPermanent: false,
    intervalInMinutes: 2,
    text: "2",
    tooltip: "2 MIN",
  },
  {
    isPermanent: false,
    intervalInMinutes: 10,
    text: "3",
    tooltip: "10 MIN",
  },
  {
    isPermanent: false,
    intervalInMinutes: 60,
    text: "4",
    tooltip: "1 HOUR",
  },
  {
    isPermanent: false,
    intervalInMinutes: 60 * 24, // 1 day
    text: "5",
    tooltip: "1 DAY",
  },
  {
    isPermanent: true,
    intervalInMinutes: Infinity,
    text: "B",
    tooltip: "PERM",
  },
];

const ChatModActions = ({ selectedMessage, unselectMessage, selectedUser, unselectUser }: ChatModActionsProps) => {
  const { userId } = useAuth();
  const { data: profileData } = useFetchUserProfile(userId);
  const { data: suspension, mutate: suspenseUser, reset } = useSuspenseUser();
  const { mutate: deleteMessageMutate } = useDeleteMessage();

  const deleteMessage = useCallback(
    (suspension?: Suspension) => {
      if (!selectedMessage || !profileData) {
        return;
      }

      deleteMessageMutate({
        messageId: selectedMessage.messageId,
        deletedBy: profileData?.username,
        suspensionId: suspension?.suspensionId,
      });
      unselectMessage();
    },
    [deleteMessageMutate, profileData, selectedMessage, unselectMessage],
  );

  const onActionClick = useCallback(
    (action: ModAction) => {
      if (!selectedMessage && !selectedUser) {
        return;
      }

      if (action.intervalInMinutes > 0) {
        suspenseUser({
          isPermanent: action.isPermanent,
          userId: (selectedMessage?.userId || selectedUser?.id) as string,
          interval: action.isPermanent ? 0 : action.intervalInMinutes * 60, // seconds
          messageId: selectedMessage?.messageId,
          reporterId: profileData?.id || "",
          reporterUsername: profileData?.username || "",
        });
      } else {
        deleteMessage();
      }
    },
    [deleteMessage, selectedMessage, selectedUser, suspenseUser, profileData],
  );

  // Delete message should be executed after suspension
  useEffect(() => {
    if (!suspension) {
      return;
    }

    if (selectedMessage) {
      deleteMessage(suspension);
    } else if (selectedUser) {
      unselectUser?.();
    }
    reset();
  }, [deleteMessage, reset, selectedMessage, selectedUser, suspension, unselectUser]);

  useEffect(() => {
    if (!selectedMessage && !selectedUser) {
      return;
    }

    const onKeydown = (e: KeyboardEvent) => {
      e.stopImmediatePropagation();
      switch (e.key.toLowerCase()) {
        case "1": // 1 pressed
          onActionClick(modActions[0]); // delete the message
          break;
        case "2": // 2 pressed
          onActionClick(modActions[1]); // delete the message and suspend for 2 minutes
          break;
        case "3": // 3 pressed
          onActionClick(modActions[2]); // delete the message and suspend for 10 minutes
          break;
        case "4": // 4 pressed
          onActionClick(modActions[3]); // delete the message and suspend for 1 hour
          break;
        case "5": // 5 pressed
          onActionClick(modActions[4]); // delete the message and suspend for 1 day
          break;
        case "b": // b pressed
          onActionClick(modActions[5]); // delete the message and ban the user (suspend permanently)
          break;
      }
    };
    document.addEventListener("keydown", onKeydown, false);

    return () => {
      document.removeEventListener("keydown", onKeydown, false);
    };
  }, [onActionClick, selectedMessage, selectedUser]);

  return (
    <Stack spacing={1} direction="row">
      {modActions.map((action) => (
        <Tooltip key={action.text} title={action.tooltip} placement="top">
          <Button
            variant="outlined"
            sx={{ minWidth: "auto", flex: 1, lineHeight: 1 }}
            disabled={!selectedMessage && !selectedUser}
            onClick={() => onActionClick(action)}
          >
            {action.text}
          </Button>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default ChatModActions;
