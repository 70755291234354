import { Dialog, DialogTitle, Divider, DialogActions, Button, DialogContent } from "@mui/material";
import { ReactNode } from "react";

interface ConfirmationModalProps {
  title: string;
  description: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
}

export const ConfirmationModal = ({ title, description, isOpen, onClose, onConfirm }: ConfirmationModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>{description}</DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={() => void onConfirm()}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
