import { toast } from "@/hooks/use-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createRlcsSchedule } from ".";
import { rlcsStatQueryKeys } from "../rlcsStatQueryKeys";

export const useCreateRlcsSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createRlcsSchedule,
    onSuccess: () => {
      void queryClient.invalidateQueries(rlcsStatQueryKeys.schedules);
      toast({
        title: "Schedule created successfully",
        description: "The schedule has been created successfully",
      });
    },
    onError: () => {
      toast({
        title: "Failed to create schedule",
        description: "The schedule could not be created",
        variant: "destructive",
      });
    },
  });
};
