import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { usePostPatchTO } from "../../../hooks/usePostPatchTO";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { ZodError } from "zod";
import { StageBuilder } from "../..";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  createStage,
  patchStage,
} from "../../../../../api/community-tournaments";
import {
  StageCreateRequestSchema,
  UpdateStageRequestSchema,
} from "../../../../../api/community-tournaments/schemas/stages";

interface CreateEditStageModalProps {
  tournamentId: string;
  stageModalStatus: "new" | "edit" | "closed";
  stage: StageBuilder;
  onChange: (stage: StageBuilder) => void;
  onClose: () => void;
  onSubmit: () => unknown;
}

const stageFormats = [
  "double-elim-32",
  "single-elim-8",
  "showmatch",
  "single-elim-4",
];

const CreateEditStageModal = ({
  stageModalStatus,
  onClose,
  stage,
  onChange,
  tournamentId,
  onSubmit,
}: CreateEditStageModalProps) => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(false);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);

  useEffect(() => {
    setReadyToSubmit(
      stage.name !== "" &&
        stage.index !== undefined &&
        stage.format !== undefined &&
        stage.name !== undefined &&
        stage.startDate !== undefined &&
        stage.endDate !== undefined,
    );
  }, [stage, stageModalStatus]);

  const alert = useAlert();

  const submit = async () => {
    setIsSubmitButtonDisabled(true);
    if (stageModalStatus === "new") {
      await submitCreate();
    } else if (stageModalStatus === "edit") {
      await submitEdit();
    }
    setIsSubmitButtonDisabled(false);
  };

  const submitCreate = async () => {
    if (stageModalStatus !== "new") {
      throw new Error("Incorrect modal state");
    }
    const data = StageCreateRequestSchema.safeParse({ ...stage, tournamentId });
    if (!data.success) {
      const err = data.error as ZodError<typeof StageCreateRequestSchema>;
      alert.showFailureAlert(
        `${err.errors[0].path.join()} ${err.errors[0].message}`,
      );
      throw new Error(data.error.message);
    }
    await createStage({
      ...data.data,
    });
    onSubmit();
  };

  const submitEdit = async () => {
    if (stageModalStatus !== "edit") {
      throw new Error("Incorrect modal state");
    }
    if (stage.id === undefined) {
      throw new Error("Stage ID was unexpectedly undefined");
    }

    const data = UpdateStageRequestSchema.safeParse(stage);
    if (!data.success) {
      const err = data.error as ZodError<typeof UpdateStageRequestSchema>;
      alert.showFailureAlert(
        `${err.errors[0].path.join()} ${err.errors[0].message}`,
      );
      throw new Error(data.error.message);
    }
    await patchStage(stage.id, {
      ...data.data,
    });
    onSubmit();
  };

  const mutatedSubmit = usePostPatchTO({
    onSuccess: () => {
      alert.showSuccessAlert(
        `Stage was successfully ${stage.id ? "edited" : "created"}`,
      );
      onClose();
    },
    onError: () => {
      alert.showFailureAlert(`Stage failed to ${stage.id ? "edit" : "create"}`);
    },
    submit,
    type: "communityStages",
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog
        open={stageModalStatus !== "closed"}
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {stageModalStatus === "new" ? "Create Stage" : "Edit Stage"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            required
            label="Stage Name"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => {
              onChange({ ...stage, name: e.target.value });
            }}
            value={stage.name}
            error={stage?.name === "" || stage === undefined}
          />
          <TextField
            required
            label="Index"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            onChange={(e) => {
              onChange({ ...stage, index: parseInt(e.target.value) });
            }}
            value={stage.index}
            error={stage?.index === undefined}
          />
          <LocalizationProvider>
            <DesktopDatePicker
              label="Start Date"
              className="w-1/4"
              onChange={(e) => {
                onChange({ ...stage, startDate: e?.toISODate()! });
              }}
              value={
                stage.startDate
                  ? DateTime.fromJSDate(new Date(stage.startDate))
                  : undefined
              }
              format="dd/LL/yyyy"
            />
            <DesktopDatePicker
              label="End Date"
              onChange={(e) => {
                onChange({ ...stage, endDate: e?.toISODate() });
              }}
              value={
                stage.endDate
                  ? DateTime.fromJSDate(new Date(stage.endDate))
                  : undefined
              }
              minDate={
                stage.startDate
                  ? DateTime.fromJSDate(new Date(stage.startDate))
                  : undefined
              }
              format="dd/LL/yyyy"
            />
          </LocalizationProvider>
          <FormControl className="w-52">
            <InputLabel id="modal-format-select">Format</InputLabel>
            <Select
              labelId="modal-format-select"
              value={stage.format || "none"}
              label="Circuit"
              onChange={(e) => {
                onChange({ ...stage, format: e.target.value });
              }}
            >
              {stageFormats?.map((format) => (
                <MenuItem value={format} key={format}>
                  {format}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={!readyToSubmit || isSubmitButtonDisabled}
            onClick={() => {
              mutatedSubmit.mutate();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CreateEditStageModal;
