import { useMutation } from "@tanstack/react-query";
import { runBroadcastAd } from "../../../api/broadcast-ads";
import { BroadcastAdRunRequest } from "../../../api/broadcast-ads/types";

export const useRunBroadcastAd = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  return useMutation(
    async (props: BroadcastAdRunRequest) => {
      await runBroadcastAd(props);
      onSuccess?.();
    },
    {
      onSuccess,
      onError,
    }
  );
};
