import { z } from "zod";

export const DbVetoSchema = z.object({
  id: z.string(),
  matchId: z.string(),
  teamId: z.string().nullable(),
  type: z.string(),
  map: z.string(),
  index: z.number().int(),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
});

export type DbVeto = z.infer<typeof DbVetoSchema>;
