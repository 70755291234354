import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getCounterStrikleAnswerPlayer } from ".";

export const useGetCounterStrikleAnswerPlayer = () => {
  return useQuery({
    queryKey: queryKeys.answerPlayer(),
    queryFn: getCounterStrikleAnswerPlayer,
  });
};
