import { useMutation } from "@tanstack/react-query";
import {
  CreateManualTimelineEventOptions,
  createManualTimelineEvent,
} from "../../../../api/new-timeline";

export const useCreateManualTimelineEvent = () => {
  return useMutation({
    mutationFn: (input: CreateManualTimelineEventOptions) =>
      createManualTimelineEvent(input),
  });
};
