import { z } from "zod";

// Make it a union when we have more attribute on the metadata column
export const StageMetadataSchema = z.object({
  numberOfTeamsAdvances: z.number(),
});
export type StageMetadata = z.infer<typeof StageMetadataSchema>;

export const StageSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  format: z.string(),
  numberOfTeams: z.number().nullable().optional(),
  metadata: StageMetadataSchema.nullish(),
  index: z.number(),
  tournamentId: z.string(),
});

export const PostStageSchema = StageSchema.omit({
  id: true,
});

export const PatchStageSchema = StageSchema.omit({
  id: true,
  tournamentId: true,
}).partial();

export type Stage = z.infer<typeof StageSchema>;
export type PostStage = z.infer<typeof PostStageSchema>;
export type PatchStage = z.infer<typeof PatchStageSchema>;
