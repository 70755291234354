import { z } from "zod";
import { TeamSchema } from "./teams";

export const DotaTournamentMetadata = z.object({
  _t: z.literal("dota_tournament"),
  externalStreamUrl: z.string().nullish(),
  sponsors: z
    .object({
      header: z.string().nullish(),
      items: z.array(
        z.object({
          logoKey: z.string(),
          url: z.string(),
        }),
      ),
    })
    .nullish(),
  ticketing: z
    .object({
      items: z
        .object({
          title: z.string(),
          startDate: z.string(),
          endDate: z.string(),
          cta: z.object({
            url: z.string(),
          }),
        })
        .array(),
    })
    .nullish()
    .catch(null),
  headerCta: z.object({ text: z.string(), url: z.string() }).nullish(),
});
export const DbTournamentMetaDataSchema = z.discriminatedUnion("_t", [DotaTournamentMetadata]);

export type DbTournamentMetaData = z.infer<typeof DbTournamentMetaDataSchema>;

export const TournamentSchema = z.object({
  id: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  circuitId: z.string(),
  name: z.string(),
  prizePool: z.string().nullish(),
  numberOfTeams: z.number().nullish(),
  location: z.string().nullish(),
  externalId: z.string().nullish(),
  metadata: DbTournamentMetaDataSchema.nullish(),
});

export const PostTournamentSchema = TournamentSchema.omit({
  id: true,
});

export const TournamentTeamSlotSchema = z.object({
  team: TeamSchema.nullable(),
  index: z.number(),
  qualificationMethod: z.string().nullish(),
  bountyValue: z.number().nullable(),
  bountySeed: z.number().nullable(),
});

export type PostTournament = z.infer<typeof PostTournamentSchema>;
export type Tournament = z.infer<typeof TournamentSchema>;
export type TournamentTeamSlot = z.infer<typeof TournamentTeamSlotSchema>;
