import { ArrowPathIcon, ExclamationTriangleIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import classNames from "classnames";
import { cloneDeep, isEqual } from "lodash-es";
import { DateTime } from "luxon";
import { useMemo, useRef, useState } from "react";
import { CsRoles, DetailedPlayer, DotaRoles } from "../../../../api/tournaments/schemas/players";
import countries from "../../../../common/countries";
import { assetsURL } from "../../../../config";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { useUpdatePlayerImage } from "../helpers/updatePlayerImage";
import useUpdateDotaMetadata from "../hooks/useUpdateDotaMetadata";
import useUpdatePlayer from "../hooks/useUpdatePlayer";
import { ChangeRoleModal } from "./ChangeRoleModal";
import { ChangeTeamModal } from "./ChangeTeamModal";
import { UploadConfigModal } from "./UploadConfigModal";
import useUpdateCsMetadata from "../hooks/useUpdateCsMetadata";

interface PlayerCardProps {
  inputPlayer: DetailedPlayer;
  teamId?: string;
}

export const PlayerCard = ({ inputPlayer, teamId }: PlayerCardProps) => {
  const [originalPlayer, setOriginalPlayer] = useState<DetailedPlayer>(cloneDeep(inputPlayer));
  const [imgFailed, setImgFailed] = useState<boolean>(true);
  const [changeRoleModalOpen, setChangeRoleModalOpen] = useState(false);
  const [changeTeamModalOpen, setChangeTeamModalOpen] = useState(false);
  const [uploadConfigModalOpen, setUploadConfigModalOpen] = useState(false);
  const changeImageHiddenInput = useRef<HTMLInputElement>(null);
  const [player, setPlayer] = useState<DetailedPlayer>(cloneDeep(inputPlayer));
  const alert = useAlert();

  const { mutate: uploadImage, isLoading: isUploadImageLoading, isError: isUploadImageError } = useUpdatePlayerImage();

  const [dotaRole, setDotaRole] = useState<DotaRoles | null>(player.dotaMetadata?.role ?? null);
  const [tiAppearances, setTiAppearances] = useState<number | null>(player.dotaMetadata?.tiAppearances ?? null);

  const [csRole, setCsRole] = useState<CsRoles | null>(player.csMetadata?.role ?? null);
  const [majorAppearances, setMajorAppearances] = useState<number | null>(player.csMetadata?.majorAppearances ?? null);

  const { mutate: updatePlayer } = useUpdatePlayer({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated player");
      setOriginalPlayer(player);
    },
    onError: () => {
      alert.showFailureAlert("Error while updating player");
    },
  });

  const { mutate: updateDotaMetadata } = useUpdateDotaMetadata();
  const { mutate: updateCsMetadata } = useUpdateCsMetadata();

  const handleSaveChanges = () => {
    if (player.gameId === "dota") {
      const hasRoleChanged = dotaRole !== player.dotaMetadata?.role;
      const hasTiAppearancesChanged = tiAppearances && tiAppearances !== player.dotaMetadata?.tiAppearances;

      if (hasRoleChanged || hasTiAppearancesChanged) {
        updateDotaMetadata(
          {
            playerId: player.id,
            metadata: {
              role: dotaRole ?? player.dotaMetadata?.role ?? undefined,
              tiAppearances: tiAppearances ?? player.dotaMetadata?.tiAppearances ?? 0,
            },
          },
          {
            onSuccess: () => {
              const newMetaData: {
                role: DotaRoles | null;
                tiAppearances: number | null;
              } = cloneDeep(player.dotaMetadata) ?? {
                role: null,
                tiAppearances: null,
              };

              if (hasRoleChanged) {
                newMetaData.role = dotaRole;
              }

              if (hasTiAppearancesChanged) {
                newMetaData.tiAppearances = tiAppearances;
              }

              setPlayer({
                ...player,
                dotaMetadata: newMetaData,
              });
              setOriginalPlayer({
                ...originalPlayer,
                dotaMetadata: newMetaData,
              });

              setTiAppearances(null);
              alert.showSuccessAlert("Successfully updated dota metadata");
            },
            onError: () => {
              alert.showFailureAlert("Error while updating player");
            },
          },
        );
      }
    }
    if (player.gameId === "cs") {
      const hasRoleChanged = csRole !== player.csMetadata?.role;
      const hasMajorAppearancesChanged = majorAppearances && majorAppearances !== player.csMetadata?.majorAppearances;

      if (hasRoleChanged || hasMajorAppearancesChanged) {
        updateCsMetadata(
          {
            playerId: player.id,
            metadata: {
              role: csRole ?? player.csMetadata?.role ?? undefined,
              majorAppearances: majorAppearances ?? player.csMetadata?.majorAppearances ?? 0,
            },
          },
          {
            onSuccess: () => {
              const newMetaData: {
                role: CsRoles | null;
                majorAppearances: number | null;
              } = cloneDeep(player.csMetadata) ?? {
                role: null,
                majorAppearances: null,
              };

              if (hasRoleChanged) {
                newMetaData.role = csRole;
              }

              if (hasMajorAppearancesChanged) {
                newMetaData.majorAppearances = majorAppearances;
              }

              setPlayer({
                ...player,
                csMetadata: newMetaData,
              });
              setOriginalPlayer({
                ...originalPlayer,
                csMetadata: newMetaData,
              });

              setMajorAppearances(null);
              alert.showSuccessAlert("Successfully updated cs metadata");
            },
            onError: () => {
              alert.showFailureAlert("Error while updating player");
            },
          },
        );
      }
    }
    if (!isEqual(originalPlayer, player)) {
      updatePlayer(
        {
          playerId: player.id,
          properties: player,
        },
        {
          onError: () => {
            alert.showFailureAlert("Error while updating player");
          },
          onSuccess: () => {
            alert.showSuccessAlert("Successfully updated player");
            setOriginalPlayer((prev) => {
              return {
                ...player,
                dotaMetadata: prev.dotaMetadata,
              };
            });
          },
        },
      );
    }
  };

  const hasPlayerChanged = useMemo(() => {
    if (!isEqual(originalPlayer, player)) return true;

    if (player.gameId === "dota") {
      if (dotaRole && dotaRole !== originalPlayer.dotaMetadata?.role) return true;
      if (tiAppearances && tiAppearances !== originalPlayer.dotaMetadata?.tiAppearances) return true;
    }
    if (player.gameId === "cs") {
      if (csRole && csRole !== originalPlayer.csMetadata?.role) return true;
      if (majorAppearances && majorAppearances !== originalPlayer.csMetadata?.majorAppearances) return true;
    }
  }, [originalPlayer, player, dotaRole, tiAppearances, csRole, majorAppearances]);

  const changeProperty = ({
    property,
    value,
  }: {
    property: keyof Pick<
      DetailedPlayer,
      "nickname" | "firstName" | "lastName" | "nationality" | "dateOfBirth" | "ingameId" | "isRetired"
    >;
    value: string | Date | boolean;
  }) => {
    setPlayer((prev) => {
      return {
        ...prev,
        [property]: value,
      };
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Card>
          <CardContent>
            <div className="flex flex-col gap-2">
              <Stack direction="row" justifyContent={"space-between"}>
                <Stack direction="row" alignContent={"center"}>
                  <Box>
                    <Typography variant="h6" sx={{ mb: -1 }}>
                      {player.nickname}
                    </Typography>
                    <Typography display="block" variant="caption" color="text.secondary" mb={1}>
                      {player.firstName} {player.lastName}
                    </Typography>
                    <div className="flex gap-0.5">
                      <Typography display="block" variant="caption" color="text.secondary">
                        <b>ID:</b>
                      </Typography>
                      <Typography display="block" variant="caption" color="text.secondary">
                        {player.id}
                      </Typography>
                    </div>
                    <div className="flex gap-0.5">
                      <Typography display="block" variant="caption" color="text.secondary">
                        <b>Team:</b>
                      </Typography>
                      <Typography display="block" variant="caption" color="text.secondary">
                        {player.team?.name ?? "-"}
                      </Typography>
                    </div>
                    <div className="flex gap-0.5">
                      <Typography display="block" variant="caption" color="text.secondary">
                        <b>Player Role:</b>
                      </Typography>
                      <Typography display="block" variant="caption" color="text.secondary">
                        {player.role ?? "-"}
                      </Typography>
                    </div>
                  </Box>
                </Stack>
                <div className="flex flex-col items-center gap-2">
                  <Box sx={{ mb: 0.5 }}>
                    {imgFailed ? (
                      <button
                        className="flex h-36 w-28 place-content-center items-center border border-gray-300/10 bg-zinc-800 hover:opacity-80"
                        onClick={() => changeImageHiddenInput.current?.click()}
                      >
                        {isUploadImageError ? <XMarkIcon className="size-7 text-red-500" /> : null}
                        {isUploadImageLoading ? (
                          <ArrowPathIcon className="size-7 animate-spin text-gray-300/60" />
                        ) : (
                          <PhotoIcon className="size-8 text-gray-300/60" />
                        )}
                      </button>
                    ) : null}
                    <img
                      alt="Player"
                      onError={() => setImgFailed(true)}
                      onLoad={() => setImgFailed(false)}
                      className={classNames("h-36", {
                        hidden: imgFailed,
                      })}
                      src={`${assetsURL}/images/players/${
                        player.id
                      }?format=auto&height=120&cache_busting=${Date.now()}`}
                    />
                  </Box>
                  <Button variant="outlined" size="small" onClick={() => changeImageHiddenInput.current?.click()}>
                    Change image
                  </Button>
                </div>
              </Stack>
              <div className="h-px w-full bg-zinc-300/10" />
              <div className="grid grid-cols-2 gap-2">
                <TextField
                  variant="standard"
                  size="small"
                  label="Ingame ID"
                  defaultValue={player.ingameId}
                  onChange={(e) =>
                    changeProperty({
                      property: "ingameId",
                      value: e.target.value,
                    })
                  }
                  fullWidth
                ></TextField>

                <TextField
                  variant="standard"
                  size="small"
                  label="Nickname"
                  defaultValue={player.nickname}
                  onChange={(e) =>
                    changeProperty({
                      property: "nickname",
                      value: e.target.value,
                    })
                  }
                  fullWidth
                ></TextField>

                <TextField
                  variant="standard"
                  size="small"
                  label="First Name"
                  defaultValue={player.firstName}
                  onChange={(e) =>
                    changeProperty({
                      property: "firstName",
                      value: e.target.value,
                    })
                  }
                  fullWidth
                />

                <TextField
                  variant="standard"
                  size="small"
                  label="Last Name"
                  defaultValue={player.lastName}
                  onChange={(e) =>
                    changeProperty({
                      property: "lastName",
                      value: e.target.value,
                    })
                  }
                  fullWidth
                />
                <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                  <InputLabel>Nationality</InputLabel>
                  <Select
                    value={player.nationality ?? ""}
                    onChange={(e: SelectChangeEvent<string>) => {
                      if (e.target.value === null) {
                        return;
                      }

                      changeProperty({
                        property: "nationality",
                        value: e.target.value,
                      });
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <DateField
                  variant="standard"
                  label="Date of Birth"
                  format="dd/LL/yyyy"
                  className="w-full"
                  defaultValue={player.dateOfBirth ? DateTime.fromJSDate(player.dateOfBirth) : undefined}
                  onChange={(e) => {
                    if (!e) return;
                    changeProperty({
                      property: "dateOfBirth",
                      value: e.toJSDate(),
                    });
                  }}
                />
                {player.gameId === "dota" ? (
                  <div className="relative flex h-[52px] flex-col">
                    <div className="flex items-center gap-1">
                      <span className="text-xs text-[#ffffffb3]">Role</span>
                      {!player.dotaMetadata?.role && !dotaRole ? (
                        <ExclamationTriangleIcon className="size-3 text-yellow-500" />
                      ) : null}
                    </div>
                    <Select
                      variant="standard"
                      size="small"
                      label="Role"
                      value={dotaRole ?? ""}
                      onChange={(e) => {
                        if (e.target.value === null) {
                          return;
                        }

                        setDotaRole(e.target.value as DotaRoles);
                      }}
                      fullWidth
                    >
                      <MenuItem value="carry">1: Carry</MenuItem>
                      <MenuItem value="mid">2: Midlaner</MenuItem>
                      <MenuItem value="offlane">3: Offlaner</MenuItem>
                      <MenuItem value="soft-support">4: Soft support</MenuItem>
                      <MenuItem value="hard-support">5: Hard support</MenuItem>
                    </Select>
                  </div>
                ) : null}
                {player.gameId === "dota" ? (
                  <TextField
                    variant="standard"
                    size="small"
                    label="TI Appearances"
                    value={tiAppearances ?? originalPlayer.dotaMetadata?.tiAppearances}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (isNaN(value)) {
                        return;
                      }
                      setTiAppearances(value);
                    }}
                    fullWidth
                  />
                ) : null}

                {player.gameId === "cs" ? (
                  <div className="relative flex h-[52px] flex-col">
                    <div className="flex items-center gap-1">
                      <span className="text-xs text-[#ffffffb3]">Role</span>
                      {!player.csMetadata?.role && !csRole ? (
                        <ExclamationTriangleIcon className="size-3 text-yellow-500" />
                      ) : null}
                    </div>
                    <Select
                      variant="standard"
                      size="small"
                      label="Role"
                      value={csRole ?? ""}
                      onChange={(e) => {
                        if (e.target.value === null) {
                          return;
                        }

                        setCsRole(e.target.value as CsRoles);
                      }}
                      fullWidth
                    >
                      <MenuItem value="awper">Awper</MenuItem>
                      <MenuItem value="rifler">Rifler</MenuItem>
                    </Select>
                  </div>
                ) : null}
                {player.gameId === "cs" ? (
                  <TextField
                    variant="standard"
                    size="small"
                    label="Major Appearances"
                    value={majorAppearances ?? originalPlayer.csMetadata?.majorAppearances}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (isNaN(value)) {
                        return;
                      }
                      setMajorAppearances(value);
                    }}
                    fullWidth
                  />
                ) : null}
                <div className="flex items-center gap-2">
                  <label className="text-sm">Retired?</label>
                  <Switch
                    checked={player.isRetired}
                    onChange={(e) => changeProperty({ property: "isRetired", value: e.target.checked })}
                    color="warning"
                    size="small"
                  />
                </div>
              </div>
              <Divider sx={{ my: 1 }} />
              <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                {hasPlayerChanged && (
                  <Button color="success" variant="outlined" size="small" onClick={handleSaveChanges}>
                    Save changes
                  </Button>
                )}
                {teamId && (
                  <Button variant="outlined" size="small" onClick={() => setChangeRoleModalOpen(true)}>
                    Change role
                  </Button>
                )}
                <Button variant="outlined" size="small" onClick={() => setUploadConfigModalOpen(true)}>
                  Upload configs
                </Button>
                <Button variant="outlined" size="small" color="warning" onClick={() => setChangeTeamModalOpen(true)}>
                  Change team
                </Button>
              </Stack>
            </div>
          </CardContent>
        </Card>
      </LocalizationProvider>
      <input
        type="file"
        ref={changeImageHiddenInput}
        style={{ display: "none" }}
        onChange={(e) => {
          if (!e.target.files) return;

          const image = e.target.files[0];
          uploadImage(
            {
              image,
              playerId: player.id,
            },
            {
              onSuccess: () => {
                alert.showSuccessAlert("Successfully updated player image");
              },
              onError: () => alert.showFailureAlert("Error while updating player image"),
            },
          );
        }}
      />

      {teamId && (
        <ChangeRoleModal
          handleClose={() => setChangeRoleModalOpen(false)}
          changeRoleModalIsOpen={changeRoleModalOpen}
          player={player}
          teamId={teamId}
        />
      )}
      {uploadConfigModalOpen && (
        <UploadConfigModal
          player={player}
          isOpen={uploadConfigModalOpen}
          handleClose={() => setUploadConfigModalOpen(false)}
        />
      )}
      {changeTeamModalOpen && (
        <ChangeTeamModal
          handleClose={() => setChangeTeamModalOpen(false)}
          changeTeamModalIsOpen={changeTeamModalOpen}
          player={player}
        />
      )}
    </>
  );
};
