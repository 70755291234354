import { Icon, Menu, MenuProps, MenuItem, MenuItemProps } from "@mui/material";
import styled from "styled-components/macro";

export const ChatSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 36px;
`;

export const MenuIcon = styled(Icon)({
  marginRight: "0.7rem",
});

export const SettingsMenu = ({ children, ...props }: MenuProps) => (
  <Menu
    {...props}
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: "visible",
        mt: 1.5,
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          left: 16,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      },
    }}
  >
    {children}
  </Menu>
);

export const SettingsMenuItem = ({ children, ...props }: MenuItemProps) => (
  <MenuItem
    {...props}
    dense
    sx={{
      cursor: "default",
    }}
    disableRipple
  >
    {children}
  </MenuItem>
);
