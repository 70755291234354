import { useQueries } from "@tanstack/react-query";
import { getMatch } from "../../../../../api/tournaments";

export default function useFetchMatch(matchIds: string[]) {
  return useQueries({
    queries: matchIds.map((id) => ({
      queryKey: ["fetchMatch", id],
      queryFn: () => getMatch(id),
    })),
  });
}
