import { useQuery } from "@tanstack/react-query";
import { getMetadata } from "..";

export const useGetMetadata = () => {
  return useQuery({
    queryFn: () => getMetadata(),
    queryKey: ["mediakit metadata"],
    enabled: true,
    retry: false,
  });
};
