import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  DialogActions,
  Button,
  FormControl,
  Chip,
  FormHelperText,
} from "@mui/material";
import { Prize } from "../../../../../../api/tournaments/schemas/prizes";
import useFetchTeams from "../../../../hooks/teams/useFetchTeams";
import { useMemo, useState } from "react";
import useCreateTournamentPrizes from "../hooks/useCreateTournamentPrize";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import useDeleteTournamentPrize from "../hooks/useDeleteTournamentPrize";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";

export const AddEditPrizeModal = ({
  editingPrizes,
  isOpen,
  onClose,
  gameId,
  tournamentId,
}: {
  editingPrizes?: Prize[]; // Grouped prizes for multiple teams having the same prize
  isOpen: boolean;
  onClose: () => void;
  gameId: string | undefined;
  tournamentId: string;
}) => {
  const sampleEditingPrize = editingPrizes?.[0]; // All fields are the same except for the team for grouped prizes
  const { data: teams } = useFetchTeams(gameId);
  const [prize, setPrize] = useState<string>(sampleEditingPrize?.prize ?? "");
  const [subPrize, setSubPrize] = useState<string[]>(sampleEditingPrize?.subPrize ?? []);
  const [selectedTeamIds, setSelectedTeamIds] = useState<{ id: string; label: string }[]>(
    editingPrizes
      ?.filter((p) => !!p.teamId)
      .map((p) => ({ id: p.teamId ?? "", label: teams?.find((t) => t.id === p.teamId)?.name ?? "" })) ?? [],
  );
  const [positionStart, setPositionStart] = useState<number>(sampleEditingPrize?.positionStart ?? 0);
  const [positionEnd, setPositionEnd] = useState<number>(sampleEditingPrize?.positionEnd ?? 0);

  const { mutateAsync: createPrize, isLoading: isCreatingPrize } = useCreateTournamentPrizes();
  const { mutateAsync: deletePrize, isLoading: isDeletingPrize } = useDeleteTournamentPrize();
  const alert = useAlert();

  const onSubmit = async () => {
    let hasAnyError = false;

    if (editingPrizes?.length) {
      // Delete all editing prizes and create them with new values
      for (const prize of editingPrizes) {
        await deletePrize(prize.id, {
          onError: () => {
            hasAnyError = true;

            void alert.showFailureAlert("Failed to delete existing prizes before creating new ones");
          },
        });
      }
    }

    if (selectedTeamIds.length === 0) {
      await createPrize(
        {
          tournamentId: tournamentId,
          teamId: undefined,
          prize,
          subPrize,
          positionStart,
          positionEnd,
        },
        {
          onError: () => {
            hasAnyError = true;

            void alert.showFailureAlert("Failed to create prize");
          },
        },
      );
    } else {
      for (const { id: teamId } of selectedTeamIds) {
        await createPrize(
          {
            tournamentId: tournamentId,
            teamId,
            prize,
            subPrize,
            positionStart,
            positionEnd,
          },
          {
            onError: () => {
              hasAnyError = true;

              void alert.showFailureAlert("Failed to create prize");
            },
          },
        );
      }
    }

    if (!hasAnyError) {
      onClose();
    }
  };

  const teamsOptions = useMemo(() => {
    return (
      teams
        ?.filter((team) => !selectedTeamIds.some((t) => t.id === team.id))
        .map((team) => ({
          id: team.id,
          label: team.name,
        }))
        .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)) ?? []
    );
  }, [teams, selectedTeamIds]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{editingPrizes?.length ? "Edit Prize" : "Add a Prize"}</DialogTitle>
      <DialogContent className="max-w-md pt-2">
        <TextField
          label="Prize"
          variant="outlined"
          type="text"
          placeholder="$50,000"
          fullWidth
          onChange={(e) => {
            setPrize(e.target.value);
          }}
          value={prize}
        />
        <Autocomplete
          id="teamSelectionInput"
          disablePortal={false}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          multiple
          options={teamsOptions}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Teams" />}
          value={selectedTeamIds}
          onChange={(_, value) => {
            if (value.length > 0) {
              setSelectedTeamIds(value);
            } else {
              setSelectedTeamIds([]);
            }
          }}
          className="mt-4"
        />
        <TextField
          label="Start Position"
          variant="outlined"
          type="number"
          placeholder="1"
          fullWidth
          onChange={(e) => {
            setPositionStart(parseInt(e.target.value));
          }}
          value={positionStart}
          className="mt-4"
        />
        <TextField
          label="End Position"
          variant="outlined"
          type="number"
          placeholder="3"
          fullWidth
          onChange={(e) => {
            setPositionEnd(parseInt(e.target.value));
          }}
          value={positionEnd}
          className="mt-4"
        />
        <FormControl className="mt-4 w-full !max-w-md">
          <Autocomplete
            multiple
            options={[]}
            value={subPrize}
            freeSolo
            fullWidth
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip label={option} key={key} {...tagProps} />;
              })
            }
            renderInput={(params) => (
              <TextField {...params} label="Sub Prizes" className="[&_input]:ring-0" fullWidth />
            )}
            onChange={(_, value) => setSubPrize(value)}
          />
          <FormHelperText>Enter each one and press enter</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          onClick={() => void onSubmit()}
          variant="contained"
          loading={isCreatingPrize || isDeletingPrize}
          startIcon={<Save />}
          loadingPosition="start"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
