import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { AuditLogsResponseSchema } from "../../types/Auditlogs";

const auditlogsApi = axios.create({
  baseURL: `${baseApiURL}/v1/`,
});

auditlogsApi.interceptors.request.use(authInterceptor);

export interface GetAuditLogsParams {
  creationDate?: string;
  userId?: string;
  limit?: number;
  pageToken?: string;
}

export async function getAuditLogs(params: GetAuditLogsParams) {
  const { data } = await auditlogsApi.get<unknown>("auditlogs", {
    params,
  });

  return AuditLogsResponseSchema.parse(data);
}
