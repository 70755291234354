import { useParams } from "react-router-dom";
import PageContainer from "../../components/Layout/PageContainer";
import useFetchMatchMapRoundStats from "./hooks/useFetchMatchMapRoundStats";
import useFetchMatchMapStats from "./hooks/useFetchMatchMapStats";
import { Alert, Button, Typography } from "@mui/material";
import { RoundStatsTable } from "./components/RoundStatsTable";
import { useState } from "react";
import useFetchPlayersByIngameId from "./hooks/useFetchPlayerByIngameId";
import { MapStatsTable } from "./components/MapStatsTable";
import useUpdateMatchMapStat from "./hooks/useUpdateMatchMapStat";
import useUpdateMatchMapRoundStat from "./hooks/useUpdateMatchMapRoundStat";

export const LiveStatsPage = () => {
  const { matchId, mapNumber } = useParams();

  const [selectedRound, setSelectedRound] = useState<number>(0);

  const { data: matchRoundStats } = useFetchMatchMapRoundStats({
    matchId: matchId || "",
    mapNumber: parseInt(mapNumber || "1"),
    enabled: matchId !== undefined && mapNumber !== undefined,
  });

  const { data: matchMapStats } = useFetchMatchMapStats({
    matchId: matchId || "",
    mapNumber: parseInt(mapNumber || "1"),
    enabled: matchId !== undefined && mapNumber !== undefined,
  });

  const playersDetailsByIngameId = useFetchPlayersByIngameId({
    playerIngameIds: matchMapStats?.map((stat) => stat.playerSteamId) || [],
  });

  const { mutate: updateMatchMapStat } = useUpdateMatchMapStat({
    matchId: matchId || "",
    mapNumber: parseInt(mapNumber || "0"),
  });

  const { mutate: updateMatchMapRoundStat } = useUpdateMatchMapRoundStat({
    matchId: matchId || "",
    mapNumber: parseInt(mapNumber || "0"),
    roundNumber: selectedRound || 0,
  });

  return (
    <PageContainer>
      <Alert severity="info">
        These changes will currently not affect anything in the frontend.
      </Alert>
      <h1>Map Stats Page</h1>
      <p>Match Id: {matchId}</p>
      <p>Map Number: {mapNumber}</p>
      {matchRoundStats && (
        <h1>{`Map Scores: ${
          matchRoundStats.filter((p) => p.winnerSide === "COUNTER_TERRORISTS")
            .length
        } : ${
          matchRoundStats.filter((p) => p.winnerSide === "TERRORISTS").length
        }`}</h1>
      )}

      {matchRoundStats && matchRoundStats.length > 0 && (
        <div className="my-4">
          <h1>Rounds</h1>
          <div className="flex flex-row gap-3">
            <Button
              variant="contained"
              style={{
                height: 60,
                width: 60,
              }}
              onClick={() => setSelectedRound(0)}
            >
              Map Stats
            </Button>
            {matchRoundStats.map((s, index) => (
              <Button
                key={s.roundNumber}
                variant="contained"
                style={{
                  height: 60,
                  width: 60,
                }}
                onClick={() => setSelectedRound(index + 1)}
              >
                {s.roundNumber}
              </Button>
            ))}
          </div>
        </div>
      )}

      {/* MAP STATS */}
      {selectedRound === 0 && (
        <>
          <div className="flex flex-col">
            <Typography variant="h4">MAP STATS</Typography>
            <MapStatsTable
              stats={matchMapStats || []}
              detailedPlayers={playersDetailsByIngameId.map((p) => p.data)}
              updateRow={(row) => updateMatchMapStat(row)}
            />
          </div>
        </>
      )}

      {/* ROUND STATS */}
      {selectedRound > 0 && matchRoundStats && matchRoundStats.length > 0 && (
        <>
          <div className="flex flex-col mb-2">
            <Typography variant="h4">ROUND STATS</Typography>
            <h1>
              Selected Round: {matchRoundStats[selectedRound - 1].roundNumber}
            </h1>
            <h1>Winners: {matchRoundStats[selectedRound - 1].winnerSide}</h1>
            <h1>
              {`First kill (Attacker): ${
                playersDetailsByIngameId.find(
                  (p) =>
                    p.data?.ingameId &&
                    p.data?.ingameId ===
                      matchRoundStats[selectedRound - 1]
                        .firstKillAttackerSteamId
                )?.data?.nickname
              } - ${matchRoundStats[selectedRound - 1].firstKillAttackerSide}`}
            </h1>
            <h1>
              {`First kill (Victim): ${
                playersDetailsByIngameId.find(
                  (p) =>
                    p.data?.ingameId &&
                    p.data?.ingameId ===
                      matchRoundStats[selectedRound - 1].firstKillVictimSteamId
                )?.data?.nickname
              } - ${matchRoundStats[selectedRound - 1].firstKillVictimSide}`}
            </h1>
          </div>
          <RoundStatsTable
            stats={matchRoundStats[selectedRound - 1].players}
            detailedPlayers={playersDetailsByIngameId.map((p) => p.data)}
            updateRow={(row) => updateMatchMapRoundStat(row)}
          />
        </>
      )}
    </PageContainer>
  );
};
