import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { InfoIcon } from "lucide-react";
import { DateTime } from "luxon";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Broadcast } from "../../../types/Broadcasts";

import useFetchBroadcasts from "../../../hooks/useFetchBroadcasts";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";
import useFetchTriggerPolls from "./hooks/useFetchTriggerPolls";
import useStartTriggerPoll from "./hooks/useStartTriggerPoll";

import secondsToMessage from "../../../helpers/secondsToMessage";

import ConfirmModal from "@/components/ConfirmModal";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import PageContainer from "../../../components/Layout/PageContainer";
import PollCreateModal from "./components/PollCreateModal";
import PollDetails from "./components/PollDetails";
import { PatronSelect } from "@/components/PatronSelect";

const ROWS_PER_PAGE = 10;

const TriggerPolls = () => {
  const { patron } = usePatron();
  const { data: broadcasts } = useFetchBroadcasts({ patron });
  const {
    data: polls,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useFetchTriggerPolls({
    patron,
    limit: ROWS_PER_PAGE,
  });
  const { mutate: startPoll, isLoading: isPollStarting, isSuccess: isPollStarted } = useStartTriggerPoll();

  const [page, setPage] = useState(0);
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [selectedPollId, setSelectedPollId] = useState<null | string>(null);
  const [selectedToStartPollId, setSelectedToStartPollId] = useState<null | string>(null);

  const closeCreateModal = useCallback(() => {
    setIsCreateModalOpened(false);
  }, []);

  const closePollDetails = useCallback(() => {
    setSelectedPollId(null);
  }, []);

  const broadcastsMap = useMemo<Map<string, Broadcast>>(() => {
    if (!broadcasts?.length) {
      return new Map();
    }

    return new Map(broadcasts.map((broadcast) => [broadcast.id, broadcast]));
  }, [broadcasts]);

  useEffect(() => {
    if (isPollStarting || !isPollStarted) {
      return;
    }

    setSelectedToStartPollId(null);
    void refetch();
  }, [isPollStarted, isPollStarting, refetch]);

  return (
    <PageContainer>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-4">
          <h2 className="text-heading">Polls</h2>
          <PatronSelect />
        </div>
        <Button onClick={() => setIsCreateModalOpened(true)}>Create Poll</Button>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>Broadcast</TableHead>
              <TableHead>Question</TableHead>
              <TableHead>Duration</TableHead>
              <TableHead>Started at</TableHead>
              <TableHead>Action</TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {polls?.pages[page]?.polls.map((poll) => (
              <TableRow key={poll.id}>
                <TableCell>{poll.title}</TableCell>
                <TableCell>{poll.broadcastId && broadcastsMap.get(poll.broadcastId)?.title}</TableCell>
                <TableCell>{poll.question}</TableCell>
                <TableCell>{secondsToMessage(poll.durationSeconds)}</TableCell>
                <TableCell>
                  {!!poll.startedAt && DateTime.fromISO(poll.startedAt).toFormat("dd-MM-yyyy HH:mm")}
                </TableCell>
                <TableCell>
                  {!poll.startedAt && (
                    <Button variant="ghost" onClick={() => setSelectedToStartPollId(poll.id)}>
                      START
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="icon" onClick={() => setSelectedPollId(poll.id)}>
                        <InfoIcon className="size-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>Poll details</TooltipContent>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex w-full justify-center py-3">
          <div className="w-fit">
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious isDisabled={page <= 0} onClick={() => setPage(Math.max(0, page - 1))} />
                </PaginationItem>

                {polls?.pages.map((_, index) => (
                  <PaginationItem key={index}>
                    <PaginationLink onClick={() => setPage(index)} isActive={page === index}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem>
                  <PaginationNext
                    isDisabled={!hasNextPage && page >= (polls?.pages.length ?? 0) - 1}
                    onClick={() => {
                      if (!hasNextPage && page >= (polls?.pages.length ?? 0) - 1) {
                        return;
                      }

                      if (hasNextPage && polls?.pages && page >= polls.pages.length - 1) {
                        void fetchNextPage();
                      }
                      setPage(page + 1);
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        </div>
      </div>
      {isCreateModalOpened && (
        <PollCreateModal
          onClose={closeCreateModal}
          refetch={refetch}
          open={isCreateModalOpened}
          broadcasts={broadcasts ?? []}
        />
      )}
      {selectedPollId && (
        <PollDetails
          onClose={closePollDetails}
          refetch={refetch}
          pollId={selectedPollId}
          broadcastsMap={broadcastsMap}
        />
      )}
      <ConfirmModal
        open={!!selectedToStartPollId}
        onCancel={() => setSelectedToStartPollId(null)}
        onOpenChange={() => setSelectedToStartPollId(null)}
        disabled={isPollStarting}
        onSubmit={selectedToStartPollId ? () => startPoll(selectedToStartPollId) : undefined}
      >
        <div className="text-center">Are you sure you want to start the poll</div>
      </ConfirmModal>
    </PageContainer>
  );
};

export default memo(TriggerPolls);
