import { z } from "zod";

export const MapNameSchema = z.enum([
  "de_ancient",
  "de_dust2",
  "de_overpass",
  "de_inferno",
  "de_mirage",
  "de_nuke",
  "de_vertigo",
  "de_anubis",
]);
