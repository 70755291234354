import { Modal } from "@blastorg/portal-pattern-library";
import { useDeleteChannel } from "../../../../../api/metricsViewership/hooks/useDeleteChannel";
import { Channel } from "../../../../../api/metricsViewership/schemas/ChannelSchema";
import { Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export const DeleteChannelModal = ({
  isOpen,
  onClose,
  channel,
}: {
  isOpen: boolean;
  onClose: () => void;
  channel: Channel | null;
}) => {
  const { mutate: deleteChannel, isLoading } = useDeleteChannel();

  const onSubmit = () => {
    if (!channel) {
      return;
    }

    deleteChannel(channel.id, {
      onSuccess() {
        onClose();
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="flex max-w-md flex-col items-start gap-8 bg-gray-800 p-8 text-left"
    >
      <h1 className="text-xl">Delete Channel {channel?.channelName}?</h1>
      <p>
        Are you sure you want to delete this channel? If you delete this channel, you{" "}
        <b>will not get reports for this channel anymore</b>. If you need reports from this channel, please just keep it
        disabled.
      </p>
      <div className="flex w-full justify-end gap-4">
        <Button onClick={onClose} variant="outlined" disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          onClick={onSubmit}
          variant="contained"
          color="error"
          startIcon={<Delete />}
        >
          Delete
        </LoadingButton>
      </div>
    </Modal>
  );
};
