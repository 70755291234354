import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Prize } from "../../../../../../api/tournaments/schemas/prizes";
import useDeleteTournamentPrize from "../hooks/useDeleteTournamentPrize";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import { generateImageUrl } from "../../../../../../helpers/images/generateImageUrl";

export const DeletePrizeModal = ({
  isOpen,
  onClose,
  deletingPrizes,
}: {
  isOpen: boolean;
  onClose: () => void;
  deletingPrizes: Prize[];
}) => {
  const samplePrize = deletingPrizes?.[0]; // All fields are the same except for the team for grouped prizes
  const { mutateAsync: deletePrize, isLoading: isDeleting } = useDeleteTournamentPrize();
  const alert = useAlert();

  const onConfirm = async () => {
    let hasAnyError = false;

    for (const prize of deletingPrizes) {
      await deletePrize(prize.id, {
        onError: () => {
          hasAnyError = true;

          void alert.showFailureAlert("Failed to delete prize");
        },
      });
    }

    if (!hasAnyError) {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Prize?</DialogTitle>
      <DialogContent className="min-w-80">
        <div>
          Prize: <strong>{samplePrize?.prize}</strong>
        </div>
        <div className="mt-2">
          Position:{" "}
          <strong>
            {samplePrize?.positionStart} - {samplePrize?.positionEnd}
          </strong>
        </div>
        <div className="mt-2 flex gap-1">
          Teams:
          {deletingPrizes.map(({ teamId }, index) => (
            <img
              key={index}
              className="size-6"
              src={generateImageUrl("teams", teamId ?? "", {
                format: "auto",
                height: "24",
                width: "auto",
              })}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isDeleting}
          color="error"
          onClick={() => void onConfirm()}
          startIcon={<Delete />}
          loadingPosition="start"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
