import { useQuery } from "@tanstack/react-query";
import { assetsURL, avatarVersion } from "../../../config";
import { getEmojis } from "../../../api/assets";
import { getPatronPrefix } from "../../../helpers/getPatronPrefix";

async function transformEmojis() {
  const emojis = await getEmojis();
  return emojis.map((avatar) => ({
    ...avatar,
    url:
      assetsURL +
      `${getPatronPrefix()}/emojis/${avatarVersion}/${avatar.id}/large`,
  }));
}

export default function useFetchEmojis() {
  return useQuery({
    queryKey: ["Emojis-users"],
    queryFn: () => transformEmojis(),
  });
}
