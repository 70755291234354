import { z } from "zod";

export const NotificationTypeSchema = z.union([
  z.literal("BROADCAST-STARTED"),
  z.literal("MATCH-STARTED"),
  z.literal("FANTASY"),
  z.literal("PICKEMS"),
  z.literal("NEWS"),
  z.literal("MINIGAMES"),
  z.literal("DEFAULT"),
]);
export type NotificationType = z.infer<typeof NotificationTypeSchema>;

export const NotificationSchema = z.object({
  type: NotificationTypeSchema,
  id: z.string(),
  title: z.string(),
  body: z.string(),
  status: z.string(),
  metadata: z.any().optional(),
  date: z.string().datetime(),
});
export type Notification = z.infer<typeof NotificationSchema>;
