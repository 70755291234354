import { useState } from "react";
import { DateTime } from "luxon";
import { ExpandMore } from "@mui/icons-material";
import { useGetCsQuizSchedules } from "../../../../api/csquiz/getCsQuizSchedules/useGetCsQuizSchedules";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { SchedulesTable } from "./components/SchedulesTable";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

export const SchedulesTab = () => {
  const [dates, setDates] = useState<string[]>(
    Array.from({ length: 3 }, (_, i) =>
      DateTime.now()
        .minus({ days: -1 + i })
        .toISODate(),
    ),
  );
  const [searchDate, setSearchDate] = useState<DateTime | null>(null);

  return (
    <>
      <div className="mb-8 flex items-center justify-between">
        <span className="font-style-b2-body-copy">Daily scheduled questions</span>
        <div className="flex items-center gap-4">
          <span>Search for a specific date:</span>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
            <DatePicker
              value={searchDate}
              onChange={(date) => {
                if (date && date.isValid) {
                  setSearchDate(date);
                  setDates((prev) => {
                    const isoDate = date.toISODate();

                    if (!isoDate) return prev;

                    if (prev.includes(isoDate)) return prev;

                    const newDates = [...prev, isoDate];
                    return newDates.sort().reverse();
                  });
                }
              }}
              format="dd/MM/yyyy"
            />
          </LocalizationProvider>
        </div>
      </div>
      {dates.map((date, i) => (
        <DateAccordion
          date={date}
          key={i}
          defaultExpanded={!searchDate ? date === DateTime.now().toISODate() : date === searchDate.toISODate()}
        />
      ))}
    </>
  );
};

const DateAccordion = ({ date, defaultExpanded }: { date: string; defaultExpanded?: boolean }) => {
  const { data: questions, isFetching, isError } = useGetCsQuizSchedules(date);

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <span className="font-bold">{DateTime.fromISO(date).toFormat("dd/MM/yyyy")}</span>
        {date === DateTime.now().toISODate() && <span className="ml-4 text-neutral-400">Today</span>}
      </AccordionSummary>
      <AccordionDetails>
        <SchedulesTable questions={questions ?? []} isLoading={isFetching} isError={isError} />
      </AccordionDetails>
    </Accordion>
  );
};
