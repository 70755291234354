import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../../components/Layout/PageContainer";
import QuestionsTable from "./components/QuestionsTable";
import RewardsModal from "./components/RewardsModal";
import usePickems from "./hooks/usePickems";
import LaunchIcon from "@mui/icons-material/Launch";
import SyncEndTimeButton from "./components/SyncEndTimeButton";

export interface PostCorrectAnswerCallbackArgs {
  questionId: string;
  choiceId: string;
}

export const PAGE_MAX_WIDTH = 1200;

const PickemsDetailsPage = () => {
  const { id: pickemsId } = useParams();
  const {
    pickems,
    pickemsQuestions,
    correctAnswers,
    isLoading,
    isWaiting,
    sendCorrectAnswer,
    sendIncorrectAnswer,
  } = usePickems(pickemsId);
  const [isRewardsModalOpen, setIsRewardsModalOpen] = useState(false);

  if (!pickemsId) {
    return null;
  }

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/pickems">
          Pickems
        </Link>
        <Typography color="text.primary" fontWeight="bold">
          {pickemsId}
        </Typography>
      </Breadcrumbs>
      <Divider sx={{ my: "1rem", maxWidth: PAGE_MAX_WIDTH }} />
      <Box display="flex" alignItems="center">
        <Typography variant="body2" component="span">
          {`Pickems end time (from sanity): `}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          component="span"
          marginX="1rem"
        >
          {pickems?.endTime ?? "Not defined!"}
        </Typography>
        <SyncEndTimeButton pickemsId={pickemsId} />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2" component="span" marginRight="1rem">
          Check user correct answers count or assign rewards:
        </Typography>
        <Button
          onClick={() => setIsRewardsModalOpen(true)}
          startIcon={<LaunchIcon />}
        >
          Open Modal
        </Button>
      </Box>
      <Divider sx={{ my: "1rem", maxWidth: PAGE_MAX_WIDTH }} />
      <QuestionsTable
        correctAnswers={correctAnswers ?? []}
        questions={pickemsQuestions}
        isLoading={isLoading}
        setCorrectAnswer={(args: PostCorrectAnswerCallbackArgs) => {
          sendCorrectAnswer({
            ...args,
            pickemsId,
          });
        }}
        setIncorrectAnswer={(args: PostCorrectAnswerCallbackArgs) => {
          sendIncorrectAnswer({
            ...args,
            pickemsId,
          });
        }}
        isWaiting={isWaiting}
      />
      {isRewardsModalOpen && (
        <RewardsModal
          pickemsId={pickemsId}
          open={isRewardsModalOpen}
          handleClose={() => setIsRewardsModalOpen(false)}
        />
      )}
    </PageContainer>
  );
};

export default PickemsDetailsPage;
