import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { EditMatch } from "../../../../../../../api/tournaments/schemas/matches";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useParams } from "react-router-dom";

export const GeneralInfo = ({
  match,
  gameId,
  updateMatch,
}: {
  match: EditMatch | undefined;
  gameId: string;
  updateMatch: (match: EditMatch) => void;
}) => {
  const { tournamentId } = useParams();
  return (
    <div className="flex flex-col gap-4">
      <span>GENERAL INFO</span>
      <TextField type="text" label="Match Id" defaultValue={match?.matchId} disabled={true} />
      <TextField
        type="number"
        label="Match Index"
        defaultValue={match?.matchIndex}
        InputProps={{
          inputProps: { min: 0 },
        }}
        onChange={(e) => {
          const matchIndex = parseInt(e.target.value);
          match && updateMatch({ ...match, matchIndex });
        }}
        error={match?.matchIndex === undefined || isNaN(match?.matchIndex) || match?.matchIndex < 0}
      />
      <TextField
        label="Match Name"
        defaultValue={match?.matchName}
        onChange={(e) => {
          const matchName = e.target.value;
          match && updateMatch({ ...match, matchName });
        }}
        error={match?.matchName === undefined || match?.matchName === ""}
      />
      <FormControl>
        <InputLabel id="match-types-select"> Type of Match</InputLabel>
        <Select
          labelId="match-types-select"
          value={match?.matchType || "BO3"}
          label="Type of Match"
          onChange={(e) => {
            const matchType = e.target.value;
            match && updateMatch({ ...match, matchType });
          }}
        >
          <MenuItem value="BO1">BO1</MenuItem>
          <MenuItem value="BO2">BO2</MenuItem>
          <MenuItem value="BO3">BO3</MenuItem>
          <MenuItem value="BO5">BO5</MenuItem>
          <MenuItem value="BO7">BO7</MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateTimePicker
          label="Scheduled Start"
          defaultValue={match?.scheduledAt ? DateTime.fromJSDate(match?.scheduledAt) : DateTime.fromJSDate(new Date())}
          format="dd/LL/yyyy HH:mm"
          ampm={false}
          onChange={(date) => {
            const scheduledAt = date?.toJSDate();
            match && scheduledAt && updateMatch({ ...match, scheduledAt });
          }}
        />
      </LocalizationProvider>
      {tournamentId?.includes("bounty") && (
        <TextField
          label="Wager"
          variant="outlined"
          onChange={(e) => {
            match &&
              updateMatch({
                ...match,
                metadata:
                  e.target.value && e.target.value !== ""
                    ? {
                        _t: "bounty_match",
                        wager: parseInt(e.target.value),
                      }
                    : null,
              });
          }}
          value={match?.metadata?._t == "bounty_match" ? match?.metadata?.wager || "" : ""}
        />
      )}
      {gameId === "dota" && (
        <>
          <TextField
            label="External Stream URL"
            variant="outlined"
            onChange={(e) => {
              match &&
                updateMatch({
                  ...match,
                  metadata:
                    e.target.value && e.target.value !== ""
                      ? {
                          _t: "dota_match",
                          externalStreamUrl: e.target.value,
                        }
                      : null,
                });
            }}
            value={(match?.metadata?._t == "dota_match" && match?.metadata?.externalStreamUrl) || ""}
          />
          <TextField
            label="External Id"
            variant="outlined"
            onChange={(e) => {
              match &&
                updateMatch({
                  ...match,
                  externalId: e.target.value,
                });
            }}
            value={match?.externalId || ""}
          />
        </>
      )}
    </div>
  );
};
