import { useQuery } from "@tanstack/react-query";
import { getTournaments } from "..";
import { queryKeys } from "../shared/queryKeys";

export const useGetTournaments = (options: { game?: string } = {}) => {
  return useQuery({
    queryKey: queryKeys.tournaments(options),
    queryFn: () => {
      return getTournaments(options.game);
    },
    enabled: true,
    retry: false,
  });
};
