import React, { useMemo } from "react";
import { Chip, Paper, Typography } from "@mui/material";
import { Broadcast } from "../../../types/Broadcasts";
import { useNavigate } from "react-router-dom";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";

interface ChipValues {
  [key: string]: {
    label: string;
    color: "error" | "default";
    cursor: "pointer" | "default";
  };
}

const chipValues: ChipValues = {
  live: {
    label: "Live",
    color: "error",
    cursor: "pointer",
  },
  offline: {
    label: "Offline",
    color: "default",
    cursor: "default",
  },
};

interface ModerationListItemProps {
  broadcast: Broadcast;
}

const ModerationListItem: React.FC<ModerationListItemProps> = ({ broadcast }) => {
  const navigate = useNavigate();
  const { patron } = usePatron();
  const isLive = useMemo(() => broadcast?.live, [broadcast]);
  if (!broadcast) return null;

  const onClickHandler = () => {
    if (isLive) {
      // TODO: Use relative path with a <Link /> component
      navigate(`/live/moderation/${patron}/${broadcast.id}`);
    }
  };

  const { label, color, cursor } = chipValues[isLive ? "live" : "offline"];
  return (
    <Paper
      onClick={onClickHandler}
      elevation={2}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: cursor,
      }}
      className="p-4"
    >
      <Chip sx={{ marginRight: "1rem" }} label={label} color={color} />
      <Typography textAlign={"center"}>{broadcast.title}</Typography>
    </Paper>
  );
};

export default ModerationListItem;
