import { useMutation, useQueryClient } from "@tanstack/react-query";
import { scheduleNotification } from "../../../api/notifications";

const useScheduleNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (...rules: Parameters<typeof scheduleNotification>) =>
      scheduleNotification(...rules),
    onSuccess() {
      void queryClient.invalidateQueries([
        "scheduledNotifications",
        "deliveredNotifications",
      ]);
    },
  });
};

export default useScheduleNotification;
