import { useQuery } from "@tanstack/react-query";
import { getCsQuizQuestions } from ".";
import { queryKeys } from "../shared/queryKeys";

export const useGetCsQuizQuestions = () => {
  return useQuery({
    queryKey: queryKeys.questions(),
    queryFn: getCsQuizQuestions,
    // sort questions by creation date if available
    select: (questions) => questions.sort((a, b) => (b.createdAt || "").localeCompare(a.createdAt || "")),
  });
};
