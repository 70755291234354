import { useQuery } from "@tanstack/react-query";
import { getUser } from "../api/users";

export default function useFetchUserProfile(id?: string) {
  return useQuery({
    queryKey: ["user", id],
    queryFn: () => (id ? getUser(id) : undefined),
    enabled: !!id,
  });
}
