import { z } from "zod";

export const SuspensionSchema = z.object({
  suspensionId: z.string().uuid(),
  userId: z.string(),
  messageId: z.string().uuid().optional(),
  reporterId: z.string().optional(),
  reporterUsername: z.string().optional(),
  interval: z.number(),
  isPermanent: z.boolean(),
  endTime: z.string().datetime(),
  createdAt: z.string().datetime(),
});

export type Suspension = z.infer<typeof SuspensionSchema>;
