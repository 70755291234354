import { useQuery } from "@tanstack/react-query";
import { getStreamsByStatus } from "@/api/pirate-streams";
import { PirateStreamStatus } from "@/api/pirate-streams/schemas";
import { pirateQueryKeys } from "./pirateQueryKeys";

export const useFetchStreamsByStatus = ({ status }: { status: PirateStreamStatus }) =>
  useQuery({
    queryKey: pirateQueryKeys.streams(status),
    queryFn: () => getStreamsByStatus({ status }),
    retry: 3,
  });
