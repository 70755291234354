import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
} from "react";
import { Paper, TextField, Typography } from "@mui/material";
import { breakpoints } from "@blastorg/portal-pattern-library";
import styled from "styled-components/macro";
import { useInView } from "react-intersection-observer";
import { DateTime } from "luxon";
import { uniqBy } from "lodash-es";

import useFetchChatMessages from "../../hooks/useFetchChatMessages";
import useDDSMessages from "../../hooks/useDDSMessages";
import useSendMessage from "../../hooks/useSendMessage";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";

import cleanMessage from "../../helpers/cleanMessage";

import UserChatHistory from "../UserChatHistory";

import { Message } from "../../../../../types/Message";

import ddsClient from "../../../../../common/DDSClient";

import { ChatMessage, ChatModActions } from "../";
import { useMediaQuery } from "usehooks-ts";

const ChatMessagesContainer = styled(Paper)`
  display: flex;
  flex-direction: column-reverse;
  will-change: scroll-position;
  flex: 1;
  overflow: auto;

  --scrollbar-color: #ffffff33;
  --background-color: transparent;

  ::-webkit-scrollbar {
    background-color: var(--background-color);
    width: 0.3rem;
    height: 0.25rem;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: var(--background-color);
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 1rem;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
`;

export const LiveChatContent: FC<{ chatId: string }> = ({ chatId }) => {
  const { patron } = usePatron();
  const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet}px)`);
  const {
    realTimeMessages,
    deletedMessages,
    restoredMessages,
    clearMessages: clearRealTimeMessages,
  } = useDDSMessages({
    roomId: chatId,
  });

  const { data, isLoading, fetchNextPage, refetch } = useFetchChatMessages({
    enabled: !!chatId,
    roomId: chatId,
    excludeAutomaticallyDeletedMessages: false,
    includeModHighlights: true,
    includeSuspensions: true,
    patron,
  });
  const { mutate: sendMessage } = useSendMessage();

  const containerRef = useRef<HTMLDivElement>(null);
  const { ref: lastElementRef, inView: lastElementInView } = useInView({
    rootMargin: "300px",
    skip: isLoading,
    root: containerRef.current,
  });
  const [isShowingNewMessageAlert, setIsShowingNewMessageAlert] =
    useState(false);

  const fetchedMessages = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.flatMap(({ messages }) => {
      return messages;
    });
  }, [data]);

  const messages = useMemo(() => {
    let result = uniqBy(
      realTimeMessages.concat(fetchedMessages),
      "messageId",
    ).sort(
      (a, b) =>
        DateTime.fromISO(b.createdAt).toMillis() -
        DateTime.fromISO(a.createdAt).toMillis(),
    );

    if (deletedMessages.size || restoredMessages.size) {
      result = result.map((message) => {
        if (
          deletedMessages.has(message.messageId) &&
          !restoredMessages.has(message.messageId)
        ) {
          return deletedMessages.get(message.messageId) || message;
        } else if (restoredMessages.has(message.messageId)) {
          return restoredMessages.get(message.messageId) || message;
        }
        return message;
      });
    }

    return result;
  }, [fetchedMessages, realTimeMessages, deletedMessages, restoredMessages]);

  const loadMore = useCallback(() => {
    void fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    const refetchAndClearMessages = () => {
      void refetch();
      clearRealTimeMessages();
    };
    const reconnectListener = ddsClient.onReconnect(refetchAndClearMessages);

    return () => {
      reconnectListener.unsubscribe();
    };
  }, [refetch, clearRealTimeMessages]);

  useEffect(() => {
    if (lastElementInView) {
      loadMore();
    }
  }, [lastElementInView, loadMore]);

  useEffect(() => {
    if (containerRef.current && containerRef.current.scrollTop < 0) {
      setIsShowingNewMessageAlert(true);
    }
  }, [messages]);

  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);

  const selectMessageClickHandler = useCallback(
    (message: Message) => {
      setSelectedMessage((currentSelectedMessage) =>
        !currentSelectedMessage ||
        currentSelectedMessage.messageId !== message.messageId
          ? message
          : null,
      );
    },
    [setSelectedMessage],
  );

  const unselectMessage = useCallback(() => {
    setSelectedMessage(null);
  }, []);

  const onInputKeydown = (e: KeyboardEvent) => {
    const input = e.target as HTMLInputElement;

    if (e.key === "Enter") {
      e.preventDefault();
      if (!input.value.trim()) {
        return;
      }

      sendMessage({
        roomId: chatId,
        messageContent: [
          {
            type: "text",
            content: cleanMessage(input.value),
          },
        ],
      });
      input.value = "";
    }
  };

  const seeNewMessages = () => {
    if (!containerRef.current) {
      return;
    }

    containerRef.current.scroll(0, 0);
    setIsShowingNewMessageAlert(false);
  };

  return (
    <>
      <Paper
        sx={{
          padding: "0.25rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "400px",
          minWidth: "350px",
          gap: "0.5rem",
          position: "relative",
          resize: "horizontal",
          overflow: "auto",
        }}
      >
        <ChatModActions
          selectedMessage={selectedMessage}
          unselectMessage={unselectMessage}
        />
        <ChatMessagesContainer ref={containerRef}>
          {(!isLoading || !!messages?.length) &&
            (messages?.length ? (
              <>
                {messages.map((message) => (
                  <ChatMessage
                    key={message.messageId}
                    message={message}
                    selectedMessageId={selectedMessage?.messageId}
                    onClick={() => selectMessageClickHandler(message)}
                    showOnlyTime
                  />
                ))}
                <div ref={lastElementRef}></div>
              </>
            ) : (
              <Typography variant="subtitle1" textAlign="center">
                Welcome BLAST.tv peeps to the chat!
              </Typography>
            ))}
        </ChatMessagesContainer>
        <TextField
          placeholder="Send a message"
          fullWidth
          size="small"
          onKeyDown={onInputKeydown}
          onFocus={unselectMessage}
        />
        {isShowingNewMessageAlert && (
          <Paper
            onClick={seeNewMessages}
            className="flex justify-center items-center w-40 h-10 absolute bottom-16 left-1/2 -translate-x-1/2 cursor-pointer"
          >
            <Typography>See new messages</Typography>
          </Paper>
        )}
      </Paper>
      {!isTablet && (
        <UserChatHistory
          selectedMessage={selectedMessage}
          setSelectedMessage={setSelectedMessage}
        />
      )}
    </>
  );
};

export default memo(LiveChatContent);
