import { Link } from "react-router-dom";
import {
  Alert,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import axios, { isAxiosError } from "axios";
import authInterceptor from "../../../../api/authInterceptor";
import { baseApiURL } from "../../../../config";
import { Layout } from "../../components/Layout";
import useFetchCircuits from "../../../Tournaments/hooks/useFetchCircuits";
import useFetchTournamentsByCircuitId from "../../../Tournaments/hooks/useFetchTournamentsByCircuitId";
import useFetchMatchesByTournamentId from "../../../Tournaments/hooks/matches/useFetchMatchesByTournamentId";
import useFetchMatchMaps from "../../../Tournaments/hooks/maps/useFetchMatchMaps";
import { DateTime } from "luxon";

const statisticsApi = axios.create({
  baseURL: `${baseApiURL}/v1/statistics`,
});
statisticsApi.interceptors.request.use(authInterceptor);

export const Replays = () => {
  const [files, setFiles] = useState<FileList>();
  const [circuitId, setCircuitId] = useState<string | null>(null);
  const [tournamentId, setTournamentId] = useState<string | null>(null);
  const [matchId, setMatchId] = useState<string | null>(null);
  const [mapId, setMapId] = useState<string | null>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const { data: circuits } = useFetchCircuits("rl");
  const { data: tournaments } = useFetchTournamentsByCircuitId(
    circuitId ?? undefined,
  );
  const { data: matches } = useFetchMatchesByTournamentId(
    tournamentId ?? undefined,
  );
  const { data: maps } = useFetchMatchMaps(matchId ?? undefined);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(event.target.files);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setShowErrorAlert(false);
    setShowSuccessAlert(false);

    if (!files) return;

    await statisticsApi
      .postForm(
        `/rl/replays/circuits/${circuitId}/tournaments/${tournamentId}/match/${matchId}/map/${mapId}`,
        files,
      )
      .then(() => {
        setShowSuccessAlert(true);
      })
      .catch((error) => {
        setShowErrorAlert(true);
        // eslint-disable-next-line no-console
        console.error(error);
        if (isAxiosError(error) && error.response) {
          // eslint-disable-next-line no-console
          console.error(error.response.data);
        }
      });
  };

  return (
    <Layout>
      <Layout.TopBar>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/">Home</Link>
          <span>Statistics</span>
          <span>Rocket League Replays</span>
        </Breadcrumbs>
      </Layout.TopBar>
      <Layout.Content
        title="Rocket League Replays"
        subtitle="Upload Rocket League replays here to be parsed and added to BLAST.TV statistics."
      >
        <form
          onSubmit={handleSubmit as React.FormEventHandler<HTMLFormElement>}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                type="file"
                name="file"
                id="file"
                onChange={handleFileChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="select-circuit-label">
                  Select Circuit
                </InputLabel>
                <Select
                  value={circuitId ?? ""}
                  labelId="select-circuit-label"
                  onChange={(event) => {
                    setCircuitId(event.target.value);
                    setTournamentId(null);
                    setMatchId(null);
                    setMapId(null);
                  }}
                >
                  {circuits?.map((circuit) => (
                    <MenuItem key={circuit.id} value={circuit.id}>
                      {circuit.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="select-tournament-label">
                  Select Tournament
                </InputLabel>
                <Select
                  disabled={!circuitId}
                  value={tournamentId ?? ""}
                  labelId="select-tournament-label"
                  onChange={(event) => {
                    setTournamentId(event.target.value);
                    setMatchId(null);
                    setMapId(null);
                  }}
                >
                  {tournaments
                    ?.sort(
                      (a, b) =>
                        new Date(b.startDate).getTime() -
                        new Date(a.startDate).getTime(),
                    )
                    .map((circuit) => (
                      <MenuItem key={circuit.id} value={circuit.id}>
                        {circuit.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="select-match-label">Select Match</InputLabel>
                <Select
                  disabled={!tournamentId}
                  value={matchId ?? ""}
                  labelId="select-match-label"
                  onChange={(event) => {
                    setMatchId(event.target.value);
                    setMapId(null);
                  }}
                >
                  {matches?.map((match) => {
                    const teams = match.teams
                      .map((team) => team.team.name)
                      .join(" vs ");

                    return (
                      <MenuItem key={match.matchId} value={match.matchId}>
                        {match.matchName} - {match.stageName}{" "}
                        {teams && <>- {teams}</>}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="select-map-label">Select Map</InputLabel>
                <Select
                  disabled={!matchId}
                  value={mapId ?? ""}
                  labelId="select-map-label"
                  onChange={(event) => setMapId(event.target.value)}
                >
                  {maps?.map((map) => (
                    <MenuItem key={map.id} value={map.id}>
                      <>
                        {map.name}{" "}
                        {map.scheduledAt && (
                          <>
                            -{" "}
                            {DateTime.fromJSDate(map.scheduledAt).toFormat(
                              "HH:MM dd/LL/yyyy",
                            )}
                          </>
                        )}
                      </>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                value="Upload File"
                name="submit"
                variant="contained"
                disabled={
                  !files || !circuitId || !tournamentId || !matchId || !mapId
                }
              >
                Upload
              </Button>
            </Grid>
            <Snackbar
              open={showErrorAlert}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert severity="error" variant="filled">
                Failed to upload replay... Please try again.
              </Alert>
            </Snackbar>
            <Snackbar
              open={showSuccessAlert}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={6000}
              onClose={() => setShowSuccessAlert(false)}
            >
              <Alert severity="success" variant="filled">
                Replay uploaded successfully!
              </Alert>
            </Snackbar>
          </Grid>
        </form>
      </Layout.Content>
    </Layout>
  );
};
