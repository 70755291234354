import { baseApiURL } from "@/config";
import axios from "axios";
import authInterceptor from "../authInterceptor";
import { Metadata, MetadataSchema } from "./schemas";

const mediakitApi = axios.create({
  baseURL: `${baseApiURL}/v1/mediakit`,
});
mediakitApi.interceptors.request.use(authInterceptor);

interface CreateUploadLinkOptions {
  fileNames: string[];
}
export const createUploadLink = async ({
  fileNames,
}: CreateUploadLinkOptions): Promise<{ original: string; preview: string }[]> => {
  const { data } = await mediakitApi.post<{ original: string; preview: string }[]>(`/upload-link`, {
    fileNames,
  });

  return data;
};

interface CreateMetadataOptions {
  fileName: string;
  ecosystem: string;
  type: string;
  size: number;
  height?: number;
  width?: number;
  tags: string[];
}
export const createMetadata = async (metadata: CreateMetadataOptions[]) => {
  await mediakitApi.post(`/`, metadata);
};

interface DeleteMetadataOptions {
  id: string;
}
export const deleteMetadata = async ({ id }: DeleteMetadataOptions) => {
  await mediakitApi.delete(`/${id}`);
};

export const getMetadata = async (): Promise<Metadata> => {
  const { data } = await mediakitApi.get<Metadata[]>("/");
  return MetadataSchema.parse(data);
};
