import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";

export function DateInput(props: DatePickerProps<DateTime>) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker {...props} />
    </LocalizationProvider>
  );
}
