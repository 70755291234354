import { Dialog, TextField, DialogTitle, Switch, Button } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { usePostCsQuizQuestion } from "../../../api/csquiz/postCsQuizQuestion/usePostCsQuizQuestion";
import classNames from "classnames";

export const AddQuestionModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [text, setText] = useState("");
  const [answers, setAnswers] = useState<
    {
      text: string;
      isCorrect: boolean;
    }[]
  >(Array.from({ length: 4 }, (_, index) => ({ text: "", isCorrect: index === 0 })));
  const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
  const imageUrl = useMemo(() => (selectedImage ? URL.createObjectURL(selectedImage) : null), [selectedImage]);

  const { mutate: postQuestion, isLoading } = usePostCsQuizQuestion();

  const isSaveDisabled = useMemo(() => {
    if (!text.length) return true;
    if (answers.some((a) => !a.text)) return true;
    const correctAnswers = answers.filter((a) => a.isCorrect);
    if (correctAnswers.length !== 1) return true;

    return false;
  }, [text, answers]);

  const onSubmit = () => {
    const correctAnswer = answers.find((a) => a.isCorrect);
    if (!correctAnswer) return;

    postQuestion({
      imageFile: selectedImage,
      text,
      answers: answers.map((a) => a.text),
      correctAnswer: correctAnswer.text,
    });
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setText("");
      setAnswers(Array.from({ length: 4 }, (_, index) => ({ text: "", isCorrect: index === 0 })));
      setSelectedImage(undefined);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Add a new question</DialogTitle>
      <div
        className={classNames(
          "group relative mx-auto mb-4 mt-12 flex h-40 w-96 items-center justify-center rounded-small",
          {
            "bg-transparent": imageUrl,
            "bg-white/10": !imageUrl,
          },
        )}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt="preview"
            className="size-full object-contain transition-all ease-in-out group-hover:opacity-10"
          />
        )}
        <Button
          component="label"
          className={classNames(
            "absolute left-0 top-0 size-full opacity-0 transition-all ease-in-out group-hover:opacity-100",
            {
              "opacity-100": !imageUrl,
            },
          )}
        >
          {imageUrl ? "Replace image" : "Add an image"}
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => {
              if (!e.target.files?.length) return;

              setSelectedImage(e.target.files?.[0] ?? null);
            }}
          />
        </Button>
      </div>
      <Button
        onClick={() => setSelectedImage(undefined)}
        variant="outlined"
        className={classNames("mx-auto w-fit", {
          "opacity-100": imageUrl,
          "opacity-0": !imageUrl,
        })}
        disabled={!imageUrl}
      >
        Remove image
      </Button>
      <div className="flex flex-col gap-4 p-6">
        <TextField
          variant="outlined"
          placeholder="Enter the question text"
          value={text}
          fullWidth
          onChange={(e) => setText(e.currentTarget.value)}
        />
        {answers.map((answer, i) => (
          <div className="flex items-center" key={i}>
            <TextField
              variant="outlined"
              placeholder={`Enter answer ${i + 1}`}
              value={answer.text}
              fullWidth
              onChange={(e) => {
                const newAnswers = [...answers];
                newAnswers[i].text = e.currentTarget.value;
                setAnswers(newAnswers);
              }}
            />
            <Switch
              checked={answer.isCorrect}
              color="success"
              onChange={(event) => {
                const isCorrect = event.currentTarget.checked;
                if (isCorrect) {
                  // set other answers to false
                  const newAnswers = answers.map((a, index) => ({
                    ...a,
                    isCorrect: index === i,
                  }));
                  setAnswers(newAnswers);
                } else {
                  // Set current answer to false
                  const newAnswers = [...answers];
                  newAnswers[i].isCorrect = false;
                  setAnswers(newAnswers);
                }
              }}
            />
          </div>
        ))}
        <div className="flex justify-end gap-2">
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={onSubmit} variant="contained" color="primary" disabled={isSaveDisabled || isLoading}>
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
