import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getCounterStriklePlayers } from ".";

export const useGetCounterStriklePlayers = () => {
  return useQuery({
    queryKey: queryKeys.players(),
    queryFn: getCounterStriklePlayers,
  });
};
