import { Link } from "react-router-dom";
import { ArrowUpDown } from "lucide-react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Channel } from "../../../../../api/metricsViewership/schemas/ChannelSchema";
import { PlatformIcon } from "@/routes/MetricsViewership/routes/shared/components/PlatformIcon";
import { useUpdateEvent } from "@/api/metricsViewership/hooks/useUpdateEvent";
import { UnlinkChannelModal } from "./UnlinkChannelModal";
import { Event } from "@/api/metricsViewership/schemas/EventSchema";
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import classNames from "classnames";
import { Badge } from "@/components/ui/badge";

type EventChannel = Channel & {
  isPrimary?: boolean;
  scoutedAt?: string;
};

const columns: ColumnDef<EventChannel>[] = [
  {
    accessorKey: "channelSource",
    header: ({ column }) => {
      return (
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Platform
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      );
    },
    cell: ({ row }) => <PlatformIcon platform={row.original.channelSource} />,
  },
  {
    accessorKey: "channelName",
    header: ({ column }) => {
      return (
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Name
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const channel = row.original;
      return channel.channelSource === "youtube" ? (
        <Link
          className="cursor-pointer underline"
          target="_blank"
          to={`https://www.youtube.com/${channel.channelName}`}
        >
          {channel.channelName}
        </Link>
      ) : channel.channelSource === "twitch" ? (
        <Link className="cursor-pointer underline" target="_blank" to={`https://www.twitch.tv/${channel.channelName}`}>
          {channel.channelName}
        </Link>
      ) : channel.channelSource === "kick" ? (
        <Link className="cursor-pointer underline" target="_blank" to={`https://www.kick.com/${channel.channelName}`}>
          {channel.channelName}
        </Link>
      ) : channel.channelSource === "tiktok" ? (
        <Link
          className="cursor-pointer underline"
          target="_blank"
          to={`https://www.tiktok.com/${channel.channelName}/live`}
        >
          {channel.channelName}
        </Link>
      ) : channel.channelSource === "blasttv" ? (
        <Link className="cursor-pointer underline" target="_blank" to={`https://www.blast.tv`}>
          {channel.channelName}
        </Link>
      ) : (
        channel.channelName
      );
    },
  },
  {
    accessorKey: "streams",
    header: "Streams",
    enableSorting: false,
    cell: ({ row }) => {
      const streams = row.original.streams;
      return streams?.length
        ? streams.map((stream, i) => (
            <div key={i}>
              {i + 1}: {stream.title}
            </div>
          ))
        : "-";
    },
  },
  {
    accessorKey: "scoutedAt",
    cell: ({ row }) => {
      const channel = row.original;
      return channel.scoutedAt && new Date(channel.scoutedAt).toLocaleString();
    },

    header: ({ column }) => {
      return (
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Auto Linked
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "isPrimary",
    header: ({ column }) => {
      return (
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Primary
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const channel = row.original;
      return channel.isPrimary ? <Badge className="uppercase">Primary</Badge> : null;
    },
  },
  {
    accessorKey: "excluded",
    header: ({ column }) => {
      return (
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Excluded from Reporting
          <ArrowUpDown className="ml-2 size-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const channel = row.original;
      return channel.excluded ? (
        <Badge className="uppercase" variant="destructive">
          Excluded
        </Badge>
      ) : null;
    },
  },
];

export const EventChannelsTable = ({
  eventChannels,
  event,
  onPrimarySelect,
}: {
  eventChannels: Array<
    Channel & {
      isPrimary?: boolean;
      scoutedAt?: string;
    }
  >;
  event: Event;
  onPrimarySelect: (channel: Channel) => void;
}) => {
  const { mutate: updateEvent } = useUpdateEvent(event.id);

  const toggleExcludeEvent = (channel: Channel) => {
    updateEvent({
      channels: eventChannels.map((c) => ({
        ...c,
        excluded: c.id === channel.id ? !c.excluded : !!c.excluded,
      })),
    });
  };

  const table = useReactTable({
    data: eventChannels,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className={classNames({
                      "w-32": header.id === "channelSource",
                    })}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
              <TableHead />
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
              <TableCell key="actions" className="w-80">
                {
                  <div className="flex gap-2">
                    <Button
                      className="w-32"
                      variant={row.original.isPrimary ? "outline" : "outline"}
                      onClick={() => onPrimarySelect(row.original)}
                      title={row.original.isPrimary ? "Remove primary channel" : "Set as primary channel"}
                    >
                      {row.original.isPrimary ? "Unset Primary" : "Set Primary"}
                    </Button>
                    <Button
                      className="w-32"
                      variant={row.original.excluded ? "outline" : "outline"}
                      onClick={() => toggleExcludeEvent(row.original)}
                      title={row.original.excluded ? "Include in reporting" : "Exclude from reporting"}
                      disabled={row.original.isPrimary}
                    >
                      {row.original.excluded ? "Include" : "Exclude"}
                    </Button>
                    <UnlinkChannelModal
                      eventId={event.id}
                      channel={row.original}
                      disabled={Boolean(row.original.isPrimary)}
                    />
                  </div>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
