import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TeamsChanger } from "./components/TeamsChanger";
import { SchedulesTable } from "./components/Schedules";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import CreateScheduleModal from "./components/Schedules/components/ScheduleModal";

export const RocketLeagueStatistics = () => {
  const [openCreateScheduleModal, setOpenCreateScheduleModal] = useState(false);
  return (
    <Tabs defaultValue="teams" className="w-full p-3">
      <div className="flex w-full justify-between">
        <div className="text-heading">Rocket League Statistics</div>
        <TabsList>
          <TabsTrigger value="teams">Teams</TabsTrigger>
          <TabsTrigger value="schedule">Schedule</TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value="teams">
        <div className="w-full rounded">
          <TeamsChanger />
        </div>
      </TabsContent>
      <TabsContent value="schedule">
        <CreateScheduleModal open={openCreateScheduleModal} handleClose={() => setOpenCreateScheduleModal(false)} />
        <div className="w-full rounded pt-3">
          <div className="flex w-full justify-between">
            <div className="text-lg font-bold">Schedules</div>
            <Button onClick={() => setOpenCreateScheduleModal(true)}>Add Schedule</Button>
          </div>
          <SchedulesTable />
        </div>
      </TabsContent>
    </Tabs>
  );
};
