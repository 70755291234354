import { useEffect, useState } from "react";

// Found that Droppable from react-beautiful-dnd is not working properly withouth this hook because it requires not to have StrictMode enabled
export const useStrictDroppable = (loading: boolean) => {
  const [droppableEnabled, setdroppableEnabled] = useState(false);

  useEffect(() => {
    let animation: number;

    if (!loading) {
      animation = requestAnimationFrame(() => setdroppableEnabled(true));
    }

    return () => {
      cancelAnimationFrame(animation);
      setdroppableEnabled(false);
    };
  }, [loading]);

  return [droppableEnabled];
};
