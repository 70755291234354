import { useQuery } from "@tanstack/react-query";
import { getChatRoomConfig } from "../../../../api/chat";

export interface ChatMessagesOptions {
  chatRoomId: string;
  enabled?: boolean;
}

const useFetchChatRoomConfig = ({
  enabled = true,
  chatRoomId,
}: ChatMessagesOptions) => {
  return useQuery({
    queryKey: ["ChatRoomConfig", chatRoomId],
    queryFn: () => getChatRoomConfig(chatRoomId),
    enabled,
    cacheTime: 1000 * 5,
    refetchInterval: 1000 * 60 * 2, // Update every 2 minutes incase another mod makes a changes
  });
};

export default useFetchChatRoomConfig;
