import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { DetailedPlayer } from "../../../../api/tournaments/schemas/players";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useRef } from "react";
import { updatePlayerConfig } from "../helpers/updatePlayerConfig";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

interface UploadConfigModalProps {
  isOpen: boolean;
  handleClose: () => void;
  player: DetailedPlayer;
}

export const UploadConfigModal = ({ isOpen, handleClose, player }: UploadConfigModalProps) => {
  const alert = useAlert();
  const configCfgInput = useRef<HTMLInputElement>(null);
  const videoCfgInput = useRef<HTMLInputElement>(null);
  const videoDefaultsInput = useRef<HTMLInputElement>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Upload configs for {player.nickname}</DialogTitle>
        <Divider />
        <DialogContent>
          <Button
            variant="outlined"
            onClick={() => {
              if (configCfgInput.current) {
                configCfgInput.current.click();
              }
            }}
          >
            Upload config.cfg
          </Button>
          <input
            type="file"
            ref={configCfgInput}
            style={{ display: "none" }}
            accept=".cfg"
            onChange={(e) => {
              if (!e.target.files) return;

              const file = e.target.files[0];
              void updatePlayerConfig({
                file,
                playerId: player.id,
                fileName: "config.cfg",
                onSuccess: () => {
                  alert.showSuccessAlert(`Successfully updated config.cfg for ${player.nickname}`);
                },
                onError: (message) => alert.showFailureAlert(message),
              });
            }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              if (videoCfgInput.current) {
                videoCfgInput.current.click();
              }
            }}
          >
            Upload video.txt
          </Button>
          <input
            type="file"
            ref={videoCfgInput}
            style={{ display: "none" }}
            accept=".txt"
            onChange={(e) => {
              if (!e.target.files) return;

              const file = e.target.files[0];
              void updatePlayerConfig({
                file,
                playerId: player.id,
                fileName: "video.txt",
                onSuccess: () => {
                  alert.showSuccessAlert(`Successfully updated video.txt for ${player.nickname}`);
                },
                onError: (message) => alert.showFailureAlert(message),
              });
            }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              if (videoDefaultsInput.current) {
                videoDefaultsInput.current.click();
              }
            }}
          >
            Upload videodefaults.txt
          </Button>
          <input
            type="file"
            ref={videoDefaultsInput}
            style={{ display: "none" }}
            accept=".txt"
            onChange={(e) => {
              if (!e.target.files) return;

              const file = e.target.files[0];
              void updatePlayerConfig({
                file,
                playerId: player.id,
                fileName: "videodefaults.txt",
                onSuccess: () => {
                  alert.showSuccessAlert(`Successfully updated videodefaults.txt for ${player.nickname}`);
                },
                onError: (message) => alert.showFailureAlert(message),
              });
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={() => handleClose()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
