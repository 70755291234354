import { SyntheticEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Breadcrumbs, Button, Chip, Grid, Tab, Typography } from "@mui/material";
import ConfirmModal from "../../../components/ConfirmModal";
import useFetchBroadcastById from "../../../hooks/useFetchBroadcastById";
import useGoOffline from "./hooks/useGoOffline";
import useGoLive from "./hooks/useGoLive";
import { AxiosError } from "axios";
import { AdditionalVideos } from "./components/AdditionalVideos";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";
import BroadcastInfo from "./components/BroadcastInfo";
import VideoStreamDetails from "./components/VideoStreamDetails";
import TimelineEvents from "../TimelineEvents";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import useFetchStreamById from "../../../hooks/useFetchStreamById";

const BroadcastDetail = () => {
  const { id } = useParams();
  const { patron } = usePatron();
  const { showSuccessAlert, showFailureAlert } = useAlert();

  // Tabs setup
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("home");
  // Additional data is stream details connected to broadcast
  // Usually moderators don't need them and just taking unnecessary space,
  // But for devs it could be helpful, so keeping this Easter egg to be able to easily show them
  const [isAdditionalDataVisible, setIsAdditionalDataVisible] = useState(false);

  const onChange = (_: SyntheticEvent, newValue: string) => {
    navigate(`${pathname}#${newValue}`, { replace: true });
  };

  useEffect(() => {
    if (hash) {
      setActiveTab(hash.replace("#", ""));
    }
  }, [hash]);

  const { data: broadcast, refetch: refetchBroadcast } = useFetchBroadcastById({
    patron,
    id: id as string,
  });

  // API hooks
  const {
    data: stream,
    isFetching: isStreamFetching,
    refetch: refetchStream,
  } = useFetchStreamById({
    id: broadcast?.videoId || "",
    enabled: !!broadcast?.videoId,
  });

  const {
    mutate: goOfflineMutation,
    isLoading: goOfflineLoading,
    data: goOfflineData,
    error: goOfflineError,
  } = useGoOffline({ patron, id: id as string });

  const {
    mutate: goLiveMutation,
    isLoading: goLiveLoading,
    data: goLiveData,
    error: goLiveError,
  } = useGoLive({ patron, id: id as string });

  const [confirmBroadcastModalOpened, setConfirmBroadcastModalOpened] = useState(false);

  useEffect(() => {
    if (!goOfflineLoading && goOfflineData) {
      showSuccessAlert("Broadcast went offline successfully.");
      void refetchBroadcast();
    } else if (!goLiveLoading && goLiveData) {
      showSuccessAlert("Broadcast went live successfully.");
      void refetchBroadcast();
    }
  }, [goOfflineLoading, goOfflineData, goLiveLoading, goLiveData, refetchBroadcast, showSuccessAlert]);

  useEffect(() => {
    if (goOfflineError) {
      showFailureAlert((goOfflineError as AxiosError).message || "Something went wrong.");
    } else if (goLiveError) {
      showFailureAlert((goLiveError as AxiosError).message || "Something went wrong.");
    }
  }, [goOfflineError, goLiveError, showFailureAlert]);

  if (!broadcast) {
    return null;
  }

  return (
    <>
      <div className="flex w-full flex-col">
        <Breadcrumbs aria-label="breadcrumb" sx={{ margin: "2rem 1rem 1rem 2rem" }}>
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/broadcasts">
            Broadcasts
          </Link>
          <span>{patron}</span>
          <div> {broadcast.title} </div>
        </Breadcrumbs>
        <div className="flex w-full flex-col gap-8 p-8">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Typography variant="h3">{broadcast.title}</Typography>
              <div className="ml-4" onDoubleClick={() => setIsAdditionalDataVisible(true)}>
                <Chip label={broadcast.live ? "LIVE" : "Offline"} color={broadcast.live ? "error" : "default"} />
              </div>
            </Box>
            <Button
              type="button"
              variant="contained"
              disabled={goOfflineLoading || goLiveLoading}
              onClick={() => setConfirmBroadcastModalOpened(true)}
            >
              {broadcast.live ? "Go Offline" : "Go Live"}
            </Button>
          </Box>
          <Box className="mt-2">
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList variant="fullWidth" onChange={onChange} aria-label="Broadcast details tabs">
                  <Tab label="Home" value="home" />
                  <Tab label="Additional Video" value="additional-video" />
                  <Tab label="Timeline Events" value="timeline-events" />
                </TabList>
              </Box>
              <div className="pt-8">
                <TabPanel value="home">
                  <Grid container spacing="16">
                    <BroadcastInfo
                      isAdditionalDataVisible={isAdditionalDataVisible}
                      broadcast={broadcast}
                      stream={stream}
                    />
                    <VideoStreamDetails
                      isAdditionalDataVisible={isAdditionalDataVisible}
                      videoStreamId={broadcast.videoId}
                      stream={stream}
                      isStreamFetching={isStreamFetching}
                      refetchStream={refetchStream}
                    />
                  </Grid>
                </TabPanel>
                <TabPanel value="additional-video">
                  <Grid container spacing="16">
                    <AdditionalVideos />
                  </Grid>
                </TabPanel>
                <TabPanel value="timeline-events">
                  <Grid container spacing="16">
                    <TimelineEvents />
                  </Grid>
                </TabPanel>
              </div>
            </TabContext>
          </Box>
        </div>
      </div>
      <ConfirmModal
        open={confirmBroadcastModalOpened}
        onOpenChange={() => setConfirmBroadcastModalOpened(false)}
        onSubmit={() => {
          if (broadcast.live) {
            goOfflineMutation();
            setConfirmBroadcastModalOpened(false);
          } else {
            goLiveMutation();
            setConfirmBroadcastModalOpened(false);
          }
        }}
        submitText={broadcast.live ? "Go Offline" : "Go Live"}
      >
        <Typography variant="subtitle1" textAlign="center">
          Are you sure you want to go {broadcast.live ? "OFFLINE" : "LIVE"}
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default BroadcastDetail;
