import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { EditMatch, EditTeamMatch } from "../../../../../../../api/tournaments/schemas/matches";
import { assetsURL } from "../../../../../../../config";
import { Team } from "../../../../../../../api/tournaments/schemas/teams";
import { useState } from "react";

export const TeamInfo = ({
  team,
  teams,
  teamLabel,
  match,
  updateMatch,
}: {
  team?: EditTeamMatch;
  teams?: Team[];
  teamLabel: string;
  match: EditMatch | undefined;
  updateMatch: (match: EditMatch) => void;
}) => {
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(team?.team?.id);

  const teamIndex = teamLabel === "Team A" ? 10 : 20;
  const teamOptions = [...(teams ?? [])].sort((a, b) => a.name.localeCompare(b.name));

  const handleTeamChange = (e: SelectChangeEvent<string>) => {
    const newTeam = teams?.find((team) => team.id === e.target.value);
    if (match) {
      if (!newTeam && e.target.value === undefined) {
        const previousTeamId = selectedTeamId;

        const previousTeam = match.teams.find((item) => item.team?.id === previousTeamId);
        if (!previousTeam) return;
        updateMatch({
          ...match,
          teams: [
            ...match.teams.filter((item) => item.team?.id !== previousTeamId),
            {
              score: 0,
              teamIndex: previousTeam?.teamIndex,
              prevTeamId: previousTeamId,
              team: undefined,
            },
          ],
        });

        setSelectedTeamId(undefined);
        return;
      }

      if (team) {
        //team is attached to match
        updateMatch({
          ...match,
          teams: match.teams.map((team) => {
            if (team.teamIndex === teamIndex) {
              return {
                ...team,
                team: newTeam,
              };
            } else {
              return team;
            }
          }),
        });
        return;
      }

      //has to add team to match
      updateMatch({
        ...match,
        teams: match.teams.concat([
          {
            teamIndex,
            team: newTeam,
            score: 0,
            prevTeamId: undefined,
          },
        ]),
      });
    }
  };

  return (
    <>
      {match && (
        <div className="flex flex-col gap-5">
          <img
            className="max-h-48"
            height={234}
            width={234}
            src={
              team?.team?.id
                ? `${assetsURL}/images/teams/${
                    team?.team.id
                  }?height=234&width=234&format=auto&cache_busting=${Date.now()}`
                : ""
            }
          />
          <FormControl>
            <InputLabel id={`${teamLabel}-select-label`}>{teamLabel}</InputLabel>
            <Select
              labelId={`${teamLabel}-select-label`}
              id={`${teamLabel}-select`}
              label={teamLabel}
              className="w-60"
              value={selectedTeamId}
              onChange={(e) => {
                handleTeamChange(e);
              }}
            >
              <MenuItem value={undefined}>No Team</MenuItem>
              {teamOptions.map((team) => (
                <MenuItem value={team.id} key={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="number"
            label={`${teamLabel} Score`}
            defaultValue={team?.score}
            InputProps={{
              inputProps: { min: 0 },
            }}
            disabled={team?.team?.id === undefined}
            onChange={(e) => {
              const score = parseInt(e.target.value);
              if (score >= 0) {
                updateMatch({
                  ...match,
                  teams: match.teams.map((team) => {
                    if (team.teamIndex === teamIndex) {
                      return {
                        ...team,
                        score,
                      };
                    } else {
                      return team;
                    }
                  }),
                });
              }
            }}
          />
        </div>
      )}
    </>
  );
};
