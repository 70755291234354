import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { Typography } from "@mui/material";
import { useEffect } from "react";

import { DialogContentText } from "../../../../../components/Dialog/DIalogContentText";

import { Broadcast } from "../../../../../types/Broadcasts";

import getLetterByIndex from "../../../../../helpers/getLetterByIndex";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";

import useDeleteTriggerPoll from "../../hooks/useDeleteTriggerPoll";
import useFetchTriggerPollById from "../../hooks/useFetchTriggerPollById";
import { ScrollArea } from "@/components/ui/scroll-area";

function PollDetails({
  onClose,
  refetch,
  pollId,
  broadcastsMap,
}: {
  onClose: () => void;
  refetch: () => Promise<unknown>;
  pollId: string;
  broadcastsMap: Map<string, Broadcast>;
}) {
  const { data: poll, isLoading } = useFetchTriggerPollById(pollId);
  const { mutate: deleteStream, isLoading: isDeleting, isSuccess } = useDeleteTriggerPoll(pollId);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    void refetch();
    onClose();
  }, [isSuccess, onClose, refetch]);

  if (isLoading || !poll) {
    return (
      <Dialog open={true} onOpenChange={() => onClose()}>
        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Fetching - details</DialogTitle>
          </DialogHeader>
          <div className="flex items-center justify-center py-8">
            <div className="size-8 animate-spin rounded-full border-b-2 border-gray-900" />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={true} onOpenChange={() => onClose()}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>{poll.title} - details</DialogTitle>
        </DialogHeader>

        <ScrollArea className="max-h-[80vh]">
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <DialogContentText title="Question" contentTextValue={poll.question} />
              <DialogContentText title="ID" contentTextValue={poll.id} />
            </div>

            <DialogContentText title="TOTAL VOTES" contentTextValue={poll.votes} />
            <Separator />

            <div className="grid grid-cols-2 gap-8">
              {poll.options.map((option, index) => (
                <div key={option.id}>
                  <DialogContentText title={`Answer ${getLetterByIndex(index)}`} contentTextValue={option.value} />
                  <DialogContentText title="Votes" contentTextValue={option.votes} />
                  {poll.hasImages && (
                    <div>
                      <Typography fontWeight="bold" mt="12px">
                        IMAGE
                      </Typography>
                      <img
                        src={generateImageUrl("triggers", option.id, {
                          width: "150",
                          format: "auto",
                        })}
                        width={150}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <Separator />

            <div className="grid grid-cols-2 gap-4">
              <DialogContentText title="BROADCAST ID" contentTextValue={poll.broadcastId || ""} />
              <DialogContentText
                title="BROADCAST TITLE"
                contentTextValue={poll.broadcastId ? broadcastsMap.get(poll.broadcastId)?.title : ""}
              />
            </div>
          </div>
        </ScrollArea>

        <DialogFooter className="gap-2">
          {!poll.startedAt && (
            <Button variant="destructive" onClick={() => deleteStream()} disabled={isDeleting}>
              Delete
            </Button>
          )}
          <Button variant="outline" onClick={onClose} disabled={isDeleting}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default PollDetails;
