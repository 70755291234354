import { useMutation } from "@tanstack/react-query";
import { broadcastGoLive } from "../../../../../api/broadcasts";
import { Patron } from "../../../../../providers/PatronProvider/types";

const useGoLive = ({ patron, id }: { patron: Patron; id: string }) => {
  const result = useMutation({
    mutationKey: ["goLive", patron, id],
    mutationFn: () => broadcastGoLive({ patron, id }),
  });

  return result;
};

export default useGoLive;
