// https://github.com/SteamDatabase/Protobufs/blob/6bf6fa0550f26cbaa329de2a576d2f61ee9172bd/dota2/dota_gcmessages_common_league.proto#L84
export const nodeGroupType = (nodeGroupType?: number) => {
  switch (nodeGroupType) {
    case 1:
      return "Organizational";
    case 2:
      return "Round Robin";
    case 3:
      return "Swiss";
    case 4:
      return "Single Elimination";
    case 5:
      return "Double Elimination (Loser Seed)";
    case 6:
      return "Double Elimination (All Winner)";
    case 7:
      return "Showmatch";
    case 8:
      return "GSL";
    case 9:
      return "Placement";
    default:
      return "Unknown";
  }
};

export const leagueNodeType = (nodeType?: number) => {
  switch (nodeType) {
    case 1:
      return "BO1";
    case 2:
      return "BO3";
    case 3:
      return "BO5";
    case 4:
      return "BO2";
    default:
      return "Unknown";
  }
};

// https://github.com/SteamDatabase/Protobufs/blob/6bf6fa0550f26cbaa329de2a576d2f61ee9172bd/dota2/dota_gcmessages_webapi.proto#L230
export const LeagueEventPhase = (phase?: number) => {
  switch (phase) {
    case 1:
      return "Wild Card";
    case 2:
      return "Group Stage";
    case 3:
      return "Group A";
    case 4:
      return "Group B";
    case 5:
      return "Overall";
    case 6:
      return "Playoff";
    case 7:
      return "Results";
    case 8:
      return "DPC Point Standings";
    case 9:
      return "Group C";
    case 10:
      return "Group D";
    case 11:
      return "Placement";
    default:
      return "Unknown";
  }
};
