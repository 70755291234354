import { invalidateMetadataFile, updateEmoji } from "../../../../api/assets";
import { Emoji } from "../../../../types/Emoji";
import { Patron } from "../../../../providers/PatronProvider/types";

interface UpdateEmojiProps {
  emojiId: string;
  emojiData: Partial<Emoji>;
  sendSuccessMessage: (message: string) => void;
  sendErrorMessage: (message: string) => void;
  patron?: Patron;
}

export const editEmoji = async ({
  emojiId,
  emojiData,
  sendSuccessMessage,
  sendErrorMessage,
  patron,
}: UpdateEmojiProps) => {
  try {
    await updateEmoji({
      emojiId: emojiId,
      emojiMetadata: emojiData,
      patron,
    });
  } catch (error) {
    sendErrorMessage("Failed to update emoji metadata.");
    throw error;
  }

  try {
    await invalidateMetadataFile("emojis", patron);
  } catch (error) {
    sendErrorMessage("Failed to invalidate metadata file cache.");
    throw error;
  }

  sendSuccessMessage("Updated emoji metadata.");
};
