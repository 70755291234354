export const counterStrikeMapPool = [
  "de_ancient",
  "de_anubis",
  "de_inferno",
  "de_mirage",
  "de_nuke",
  "de_overpass",
  "de_vertigo",
  "de_dust2",
  "de_train",
];

export const rocketLeagueMapPool = [
  "AquaDome",
  "Beckwith Park",
  "Champions Field",
  "DFH Stadium",
  "Deadeye Canyon",
  "Estadio Vida",
  "Farmstead",
  "Forbidden Temple",
  "Mannfield",
  "Neo Tokyo",
  "Neon Fields",
  "Salty Shores",
  "Sovereign Heights",
  "Utopia Coliseum",
  "Urban Central",
  "Wasteland",
];

export const dotaMapPool = ["dota_map"];

const mapPool: Record<string, string[]> = {
  cs: counterStrikeMapPool,
  rl: rocketLeagueMapPool,
  dota: dotaMapPool,
} as const;

export default mapPool;
