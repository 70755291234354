import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { TimelineEventSchema } from "./schemas";

const timelineApi = axios.create({
  baseURL: `${baseApiURL}/v1/timeline`,
  headers: {
    "Content-Type": "application/json",
  },
});

timelineApi.interceptors.request.use(authInterceptor);

export interface CreateManualTimelineEventOptions {
  matchId: string;
  mapNumber: number;
  roundNumber: number;
  eventType: string;
  occurredAt: string;
  endedAt: string;
}
export const createManualTimelineEvent = async ({
  matchId,
  mapNumber,
  roundNumber,
  eventType,
  occurredAt,
  endedAt,
}: CreateManualTimelineEventOptions): Promise<void> => {
  await timelineApi.post(`/cs/events/match/${matchId}`, {
    mapNumber,
    roundNumber,
    eventType,
    occurredAt,
    endedAt,
    priority: 10,
    isManualEvent: true,
  });
};

interface ListMatchTimelineEventsOptions {
  matchId?: string;
}
export const listMatchTimelineEvents = async ({
  matchId,
}: ListMatchTimelineEventsOptions) => {
  const { data } = await timelineApi.get<unknown>(
    `/cs/events/match/${matchId}`,
  );
  return TimelineEventSchema.array().parse(data);
};
