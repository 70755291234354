import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getIssues } from ".";

export const useGetIssues = (params: Parameters<typeof getIssues>[0] = {}) => {
  return useQuery({
    queryKey: queryKeys.paginated(params),
    queryFn: () => getIssues(params),
  });
};
