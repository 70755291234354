import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { getAuditLogs, type GetAuditLogsParams } from "../../../api/auditlogs";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";

const useFetchAuditlogs = (params?: Omit<GetAuditLogsParams, "pageToken">) => {
  const { showFailureAlert } = useAlert();

  return useInfiniteQuery({
    queryKey: ["auditlogs", params],
    queryFn: ({ pageParam }: QueryFunctionContext<QueryKey, string>) =>
      getAuditLogs({ ...params, pageToken: pageParam }),
    getNextPageParam: (response) => {
      return response.nextPageToken;
    },
    onError() {
      showFailureAlert("Something went wrong while fetch the audit logs");
    },
  });
};

export default useFetchAuditlogs;
