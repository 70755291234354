import { Breadcrumbs, Button, Container, Grid, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CreateLocationDialog from "./components/CreateLocationDialog";
import { useState } from "react";
import useGetLocations from "./hooks/useGetLocations";
import InfoIcon from "@mui/icons-material/Info";
import { InfoCreateLocationDialog } from "./components/InfoCreateLocationDialog";
import { ShowLocations } from "./components/ShowLocations";
import { Location } from "../../api/csguessr/types";
import classNames from "classnames";
import { ScheduleLocation } from "./components/ScheduleLocation";

export const CSGuessr = () => {
  const [createLocationDialogOpen, setCreateLocationDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const { data: locations, isLoading, refetch } = useGetLocations();
  const [tabValue, setTabValue] = useState(0);

  return (
    <div className="flex w-full flex-col">
      <Breadcrumbs aria-label="breadcrumb" sx={{ margin: "2rem 1rem 1rem 2rem" }}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <span> CSGuessr </span>
      </Breadcrumbs>

      <Container sx={{ my: 2 }}>
        <Stack>
          <Grid container justifyContent="space-between" sx={{ mb: 2.5 }}>
            <Grid item>
              <Typography variant="h3">CSGuessr</Typography>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <IconButton onClick={() => setInfoDialogOpen(true)}>
                <InfoIcon />
              </IconButton>
              <Button onClick={() => setCreateLocationDialogOpen(true)} type="button" variant="contained">
                Create New Location
              </Button>
            </Grid>
            {createLocationDialogOpen && (
              <CreateLocationDialog
                open={createLocationDialogOpen}
                closeDialog={() => setCreateLocationDialogOpen(false)}
              />
            )}
            {infoDialogOpen && (
              <InfoCreateLocationDialog open={infoDialogOpen} closeDialog={() => setInfoDialogOpen(false)} />
            )}
          </Grid>
          <Tabs value={tabValue} onChange={(_, newValue: number) => setTabValue(newValue)} className="mb-2">
            <Tab
              className={classNames("mr-2 rounded-t font-bold", {
                "bg-gray-400 text-white": tabValue === 0,
                "bg-gray-700 text-white opacity-75": tabValue !== 0,
              })}
              label="Locations"
            />
            <Tab
              className={classNames("mr-2 rounded-t font-bold", {
                "bg-gray-400 text-white": tabValue === 1,
                "bg-gray-700 text-white opacity-75": tabValue !== 1,
              })}
              label="Schedules"
            />
          </Tabs>
          {tabValue === 0 && (
            <ShowLocations
              locations={locations as Location[]}
              isLoading={isLoading}
              refetchLocations={() => void refetch()}
            />
          )}
          {tabValue === 1 && <ScheduleLocation locations={locations} />}
        </Stack>
      </Container>
    </div>
  );
};
