import { Route, Routes } from "react-router-dom";
import { EmulationTasks } from "./EmulationTasks";

export function EmulationRoutes() {
  return (
    <Routes>
      <Route path="/" element={<EmulationTasks />} />
    </Routes>
  );
}
