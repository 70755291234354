import { Breadcrumbs, Button, Skeleton } from "@mui/material";
import useFetchCircuits from "../hooks/useFetchCircuits";
import { useState } from "react";
import CreateAndEditCircuitModal from "./components/CreateEditCircuitModal";
import { Link } from "react-router-dom";
import FilterChips from "../shared/components/FilterChips";

export interface CircuitBuilder {
  name?: string;
  startDate?: string;
  endDate?: string;
  gameId?: string;
  id?: string;
}

const emptyCircuit = {
  name: undefined,
  startDate: undefined,
  endDate: undefined,
  gameId: "cs2",
};

const CircuitsPage = () => {
  const [circuitModalStatus, setCircuitModalStatus] = useState<
    "new" | "edit" | "closed"
  >("closed");
  const [editCircuit, setEditCircuit] = useState<CircuitBuilder>(emptyCircuit);
  const [gameFilter, setGameFilter] = useState<"cs2" | "rl" | "dota">("cs2");

  const { data: circuits, isFetching } = useFetchCircuits();

  const filteredCircuits = circuits?.filter(
    (circuit) => circuit.gameId === gameFilter,
  );

  return (
    <div className="flex flex-col w-full">
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ margin: "2rem 1rem 1rem 2rem" }}
      >
        <Link color="inherit" to="/">
          Home
        </Link>
        <span> Circuits </span>
      </Breadcrumbs>
      <div className="p-7">
        <h1 className="font-style-desktop-h2 my-3">Circuits</h1>
        <div>
          <FilterChips
            label="Selected Game"
            onClick={setGameFilter}
            initialFilter={gameFilter}
            chips={[
              {
                label: "Counter-Strike 2",
                id: "cs2",
              },
              {
                label: "Rocket League",
                id: "rl",
              },
              {
                label: "Dota 2",
                id: "dota",
              },
            ]}
          />
        </div>
        <Button
          variant="contained"
          className="my-4"
          onClick={() => {
            setEditCircuit({ ...emptyCircuit, gameId: gameFilter });
            setCircuitModalStatus("new");
          }}
        >
          Add Circuit
        </Button>
        <div className="flex flex-wrap gap-4">
          {isFetching ? (
            <>
              <Skeleton variant="rounded" width="18rem" height="8rem" />
              <Skeleton variant="rounded" width="18rem" height="8rem" />
            </>
          ) : (
            filteredCircuits?.map((circuit) => (
              <div
                className="bg-slate-800 w-72 flex flex-col rounded-small  p-3"
                key={circuit.id}
              >
                <span className="font-style-label-1">{circuit.name}</span>
                <span className="font-style-b2-body-copy">
                  Start Date:{" "}
                  {new Date(circuit.startDate).toISOString().split("T")[0]}
                </span>
                <span className="font-style-b2-body-copy">
                  End Date:{" "}
                  {new Date(circuit.endDate).toISOString().split("T")[0]}
                </span>
                <span className="font-style-b2-body-copy">
                  Game: {circuit.gameId}
                </span>
                <hr className="w-full self-center border-solid border-slate-400 my-3" />
                <div>
                  <Button>
                    <Link to={`/to/circuits/${circuit.id}/tournaments`}>
                      Go to Tournaments
                    </Link>
                  </Button>
                  <Button
                    onClick={() => {
                      setEditCircuit(circuit);
                      setCircuitModalStatus("edit");
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            ))
          )}
        </div>
        <CreateAndEditCircuitModal
          circuitModalStatus={circuitModalStatus}
          handleClose={() => {
            setCircuitModalStatus("closed");
            setEditCircuit(emptyCircuit);
          }}
          circuit={editCircuit}
          handleChange={setEditCircuit}
        />
      </div>
    </div>
  );
};

export default CircuitsPage;
