import { useQuery } from "@tanstack/react-query";
import { getBroadcasts } from "../api/broadcasts";
import { Patron } from "../providers/PatronProvider/types";

const useFetchBroadcasts = (options?: { patron?: Patron }) => {
  const patron = options?.patron || "blasttv";
  return useQuery({
    queryKey: ["broadcasts", patron],
    queryFn: () => getBroadcasts({ patron }),
  });
};

export default useFetchBroadcasts;
