import { Breadcrumbs, Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";
import { QuestionsTab } from "./components/QuestionsTab";
import { Box } from "@mui/system";
import { useState } from "react";
import { SchedulesTab } from "./components/SchedulesTab";

export const CSQuizPage = () => {
  const [tabValue, setTabValue] = useState<"questions" | "schedules">("questions");

  return (
    <>
      <div className="flex w-full flex-col p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <span> CS Quiz </span>
        </Breadcrumbs>
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginY: "2rem" }}>
          <Tabs value={tabValue} onChange={(_, newValue: "questions" | "schedules") => setTabValue(newValue)}>
            <Tab label="Questions database" value="questions" />
            <Tab label="Daily schedules" value="schedules" />
          </Tabs>
        </Box>
        {tabValue === "questions" ? <QuestionsTab /> : tabValue === "schedules" && <SchedulesTab />}
      </div>
    </>
  );
};
