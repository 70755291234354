import { useMutation } from "@tanstack/react-query";
import { createRole } from "../../../../api/auth";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { Role } from "../../../../api/auth/schemas";

const useCreateRole = () => {
  const { showFailureAlert } = useAlert();

  return useMutation({
    mutationFn: (role: Pick<Role, "name" | "description">) => createRole(role),
    onError() {
      showFailureAlert("Something went wrong while creating role");
    },
  });
};

export default useCreateRole;
