import { postTournament } from "..";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";

export const usePostTournament = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postTournament,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.all });
    },
  });
};
