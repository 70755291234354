import { FC } from "react";
import ChatModerationConfirmationDialog, {
  ChatModerationConfirmationDialogProps,
} from "../../../components/ChatModerationConfirmationDialog";

const chatSettingsConfirmationDialogText = {
  slowMode: {
    on: {
      title: "Activate slow mode",
      description:
        "Are you sure you want to enable slow mode with delay of [value] seconds",
    },
    off: {
      title: "Deactivate slow mode",
      description: "Are you sure you want to disable slow mode?",
    },
  },
  emojiOnly: {
    on: {
      title: "Activate emoji only mode",
      description: "Are you sure you want to enable emoji only mode?",
    },
    off: {
      title: "Deactivate emoji only mode",
      description: "Are you sure you want to disable emoji only mode?",
    },
  },
  automod: {
    on: {
      title: "Activate automod",
      description: "Are you sure you want to enable automod?",
    },
    off: {
      title: "Deactivate automod",
      description: "Are you sure you want to disable automod?",
    },
  },
};

interface interpolateTexProps {
  string: string;
  key: string;
  value: string;
}

const interpolateText = ({ string, key, value }: interpolateTexProps) => {
  if (!string || !key || !value) return string;
  const regex = new RegExp(`\\[${key}\\]`, "g");
  return string.replace(regex, value);
};

export const ConfirmSwitchChange: FC<
  Pick<
    ChatModerationConfirmationDialogProps,
    "onClose" | "onConfirm" | "open"
  > & {
    currentSwitchState: boolean;
    interpolateValue?: number;
    switchType: "slowMode" | "emojiOnly" | "automod";
  }
> = ({ interpolateValue, currentSwitchState, switchType, ...props }) => {
  const title =
    chatSettingsConfirmationDialogText[switchType][
      currentSwitchState ? "off" : "on"
    ].title;

  const descriptionText =
    chatSettingsConfirmationDialogText[switchType][
      currentSwitchState ? "off" : "on"
    ].description;

  const description = interpolateValue
    ? interpolateText({
        string: descriptionText,
        key: "value",
        value: interpolateValue.toString(),
      })
    : descriptionText;

  return (
    <ChatModerationConfirmationDialog
      {...props}
      title={title}
      description={description}
    />
  );
};

export const ConfirmEmojiOnlyAction: FC<
  Pick<
    ChatModerationConfirmationDialogProps,
    "onClose" | "onConfirm" | "open"
  > & { currentSwitchState: boolean }
> = ({ ...props }) => <ConfirmSwitchChange {...props} switchType="emojiOnly" />;

export const ConfirmAutomodAction: FC<
  Pick<
    ChatModerationConfirmationDialogProps,
    "onClose" | "onConfirm" | "open"
  > & { currentSwitchState: boolean }
> = ({ ...props }) => <ConfirmSwitchChange {...props} switchType="automod" />;

export const ConfirmSlowModeAction: FC<
  Pick<
    ChatModerationConfirmationDialogProps,
    "onClose" | "onConfirm" | "open"
  > & {
    currentSwitchState: boolean;
    interpolateValue: number;
  }
> = (props) => <ConfirmSwitchChange {...props} switchType="slowMode" />;

export const ConfirmSlowModeBufferAction: FC<
  Pick<
    ChatModerationConfirmationDialogProps,
    "onClose" | "onConfirm" | "open"
  > & { slowModeBuffer: number }
> = ({ slowModeBuffer, ...props }) => {
  return (
    <ChatModerationConfirmationDialog
      {...props}
      title={chatSettingsConfirmationDialogText.slowMode.on.title}
      description={interpolateText({
        string: chatSettingsConfirmationDialogText.slowMode.on.description,
        key: "value",
        value: slowModeBuffer.toString(),
      })}
    />
  );
};
