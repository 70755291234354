import { z } from "zod";
import { TeamSchema } from "./teams";

export const MapSchema = z.object({
  id: z.string(),
  name: z.string(),
  scheduledAt: z.coerce.date().nullable(),
  matchId: z.string(),
  startedAt: z.coerce.date().nullable(),
  endedAt: z.coerce.date().nullable(),
  externalId: z.string().nullish(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime().nullable(),
  teams: z
    .object({
      score: z.number().int(),
      team: TeamSchema.pick({
        id: true,
        name: true,
        shortName: true,
        nationality: true,
        externalId: true,
      }),
    })
    .array()
    .nullable(),
});

export const MapNoTeamSchema = MapSchema.omit({ teams: true });

export const MapUpdateRequestSchema = MapSchema.omit({
  matchId: true,
  createdAt: true,
  updatedAt: true,
}).partial();

export const TeamMapSchema = z.object({
  teamId: z.string(),
  mapId: z.string(),
  score: z.coerce.number().int().nullable(),
});

export const NewMapSchema = MapSchema.omit({
  createdAt: true,
  updatedAt: true,
  startedAt: true,
  endedAt: true,
});

export type Map = z.infer<typeof MapSchema>;
export type MapUpdateRequest = z.infer<typeof MapUpdateRequestSchema>;
export type TeamMap = z.infer<typeof TeamMapSchema>;
export type MapNoTeam = z.infer<typeof MapNoTeamSchema>;
export type NewMap = z.infer<typeof NewMapSchema>;
