import { Dialog, DialogTitle, Button } from "@mui/material";
import { useState } from "react";
import { Question } from "../../../api/csquiz/shared/types";
import { useDeleteCsQuizQuestion } from "../../../api/csquiz/deleteCsQuizQuestion/usePostCsQuizQuestion";
import { Check, Close, Delete } from "@mui/icons-material";
import { assetsURL } from "../../../config";
import { DateTime } from "luxon";

export const QuestionDetailedModal = ({
  isOpen,
  onClose,
  question,
}: {
  isOpen: boolean;
  onClose: () => void;
  question: Question;
}) => {
  const [isWaitingForDeleteConfirmation, setIsWaitingForDeleteConfirmation] = useState(false);
  const { mutate: deleteQuestion } = useDeleteCsQuizQuestion();

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>{question.text}</DialogTitle>
      <div className="flex flex-col gap-4 p-6">
        {question.image ? (
          <img
            src={`${assetsURL}/images/${question.image}`}
            alt="question"
            className="mb-8 h-40 w-fit max-w-full rounded-small object-contain"
          />
        ) : null}
        <div>
          <Check color="success" />: {question.correctAnswer}
        </div>
        {question.answers
          .filter((a) => a !== question.correctAnswer)
          .map((answer, i) => (
            <div key={i}>
              <Close color="error" />: {answer}
            </div>
          ))}
        <div className="mt-8 font-style-b2-body-copy">
          Created at: {DateTime.fromISO(question.createdAt).toFormat("dd/MM/yyyy HH:mm")}
        </div>
        <div className="font-style-b2-body-copy">ID: {question.id}</div>
        <div className="flex justify-end gap-2">
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
          <Button
            onClick={() => {
              if (isWaitingForDeleteConfirmation) {
                deleteQuestion(question.id);
                onClose();
              } else {
                setIsWaitingForDeleteConfirmation(true);

                setTimeout(() => {
                  setIsWaitingForDeleteConfirmation(false);
                }, 5000);
              }
            }}
            variant={isWaitingForDeleteConfirmation ? "contained" : "outlined"}
            color="error"
          >
            <Delete className="mr-2" />
            {isWaitingForDeleteConfirmation ? "Click again to confirm" : "Delete"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
