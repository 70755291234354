import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { HideImage } from "@mui/icons-material";
import { Question } from "../../../api/csquiz/shared/types";
import classNames from "classnames";
import { assetsURL } from "../../../config";
import { DateTime } from "luxon";

export const QuestionsTable = ({
  onRowClick,
  questions,
  isLoading,
  isError,
}: {
  onRowClick: (question: Question) => void;
  questions: Question[];
  isLoading?: boolean;
  isError?: boolean;
}) => {
  return (
    <Table size="small" className="w-fit max-w-full">
      <TableHead>
        <TableRow>
          <TableCell>Image</TableCell>
          <TableCell>Question</TableCell>
          <TableCell>Correct Answer</TableCell>
          <TableCell>Incorrect Answer 1</TableCell>
          <TableCell>Incorrect Answer 2</TableCell>
          <TableCell>Incorrect Answer 3</TableCell>
          <TableCell className="whitespace-nowrap">Created At</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isError && !isLoading && (
          <TableRow>
            <TableCell colSpan={7}>Something went wrong when fetching the questions</TableCell>
          </TableRow>
        )}
        {!isError && !isLoading && questions?.length === 0 && (
          <TableRow>
            <TableCell colSpan={7}>No questions available</TableCell>
          </TableRow>
        )}
        {isLoading && (
          <TableRow key="loading">
            {Array.from({ length: 7 }, (_, i) => (
              <TableCell key={i}>
                <div
                  className="my-2 min-h-6 w-36 animate-pulse rounded-full bg-white/20"
                  style={{ animationDelay: `${i * 50}ms` }}
                />
              </TableCell>
            ))}
          </TableRow>
        )}
        {questions?.map((question, i) => {
          const incorrectAnswers = question.answers.filter((a) => a !== question.correctAnswer);
          return (
            <TableRow
              key={i}
              className={classNames({
                "cursor-pointer transition-all duration-150 ease-in-out hover:bg-white/10":
                  !isLoading && !isError && onRowClick,
              })}
              onClick={() => onRowClick(question)}
            >
              <TableCell>
                <div className="flex w-12 items-center justify-center overflow-hidden rounded-small">
                  {question.image ? (
                    <img
                      src={`${assetsURL}/images/${question.image}`}
                      width={48}
                      alt="question"
                      className="object-contain"
                    />
                  ) : (
                    <HideImage className="opacity-20" />
                  )}
                </div>
              </TableCell>
              <TableCell>{question.text}</TableCell>
              <TableCell className="bg-green-500/10">{question.correctAnswer}</TableCell>
              <TableCell className="bg-red-500/10">{incorrectAnswers[0]}</TableCell>
              <TableCell className="bg-red-500/10">{incorrectAnswers[1]}</TableCell>
              <TableCell className="bg-red-500/10">{incorrectAnswers[2]}</TableCell>
              <TableCell title={question.createdAt}>{DateTime.fromISO(question.createdAt).toRelative()}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
