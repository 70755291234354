import { ReactNode, useState } from "react";
import { Collection } from "../../../../api/points/types/Collection";
import PointsContext, {
  PointsCollectionsContextProps,
} from "./PointsCollectionsContext";
import { ModalState } from "./types/modalState";

type partialCollection = Partial<Collection>;

export const PointsCollectionsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [collection, setCollection] = useState<partialCollection>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>("create");

  const setModal = (
    isOpen: true,
    modalState: ModalState,
    collection?: Collection
  ) => {
    setModalOpen(isOpen);
    setModalState(modalState);

    if (collection) {
      setCollection(collection);
    } else {
      setCollection({});
    }
  };

  const handleUpdateCollection = (newValues: partialCollection) => {
    const valuesToSet: partialCollection = {};

    for (const [key, value] of Object.entries(newValues)) {
      if (value !== undefined) {
        valuesToSet[key as keyof Collection] = value as never;
      }
    }

    setCollection((prev) => {
      return {
        ...prev,
        ...valuesToSet,
      };
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalState("create");
    setCollection({});
  };

  const value: PointsCollectionsContextProps = {
    collection,
    handleUpdateCollection,
    modalState,
    modalOpen,
    setModal,
    closeModal,
  };

  return (
    <PointsContext.Provider value={value}>{children}</PointsContext.Provider>
  );
};

export default PointsCollectionsProvider;
