import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import z from "zod";

// OpenDota matches
export const MatchResponseSchema = z.array(
  z.object({
    match_id: z.number(),
    radiant_win: z.boolean(),
    start_time: z.number(),
    duration: z.number(),
    leagueid: z.number(),
    radiant_score: z.number(),
    dire_score: z.number(),
    radiant_team_id: z.number(),
    radiant_team_name: z.string().nullable().optional(),
    dire_team_id: z.number(),
    dire_team_name: z.string().nullable().optional(),
    series_id: z.number(),
    series_type: z.number(),
  }),
);

export const useMatches = (leagueId?: string) => {
  return useQuery(
    ["matches", leagueId],
    async () => {
      const response = await axios.get(
        `https://api.opendota.com/api/leagues/${leagueId}/matches`,
      );

      const matches = MatchResponseSchema.parse(response.data);

      return matches;
    },
    {
      enabled: !!leagueId,
    },
  );
};
