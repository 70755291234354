import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { usePostPatchTO } from "../../../hooks/usePostPatchTO";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { ZodError } from "zod";
import { CircuitBuilder } from "../..";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { CircuitCreateRequestSchema } from "../../../../../api/community-tournaments/schemas/circuits";
import {
  patchCircuit,
  postNewCircuit,
} from "../../../../../api/community-tournaments";

interface CreateAndEditCircuitModalProps {
  circuitModalStatus: "new" | "edit" | "closed";
  circuit: CircuitBuilder;
  handleChange: (circuit: CircuitBuilder) => void;
  handleClose: () => void;
}

const CreateAndEditCircuitModal = ({
  circuitModalStatus,
  handleClose,
  circuit,
  handleChange,
}: CreateAndEditCircuitModalProps) => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  useEffect(() => {
    setReadyToSubmit(
      circuit.name !== "" &&
        circuit.name !== undefined &&
        circuit.startDate !== undefined &&
        circuit.endDate !== undefined &&
        circuit.startDate <= circuit.endDate,
    );
  }, [circuit]);

  const alert = useAlert();

  const submit = async () => {
    const data = CircuitCreateRequestSchema.safeParse(circuit);
    if (!data.success) {
      const err = data.error as ZodError<typeof CircuitCreateRequestSchema>;
      alert.showFailureAlert(
        `${err.errors[0].path.join()} ${err.errors[0].message}`,
      );
      throw new Error(data.error.message);
    }
    if (circuitModalStatus === "edit" && circuit.id) {
      await patchCircuit(
        {
          startDate: new Date(data.data.startDate),
          endDate: new Date(data.data.endDate),
        },
        circuit.id,
      );
    } else {
      await postNewCircuit(data.data);
    }
  };

  const mutatedSubmit = usePostPatchTO({
    onSuccess: () => {
      alert.showSuccessAlert(
        `Circuit was successfully ${circuit.id ? "edited" : "created"}`,
      );
      handleClose();
    },
    onError: () => {
      alert.showFailureAlert(
        `Circuit failed to ${circuit.id ? "edit" : "create"}`,
      );
    },
    submit,
    type: "communityCircuits",
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog
        open={circuitModalStatus !== "closed"}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Circuit</DialogTitle>
        <Divider />
        <div className="flex flex-wrap gap-2 p-6">
          <TextField
            required
            label="Circuit Name"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => {
              handleChange({ ...circuit, name: e.target.value });
            }}
            value={circuit.name}
            error={circuit?.name === "" || circuit === undefined}
            disabled={circuitModalStatus === "edit"}
          />
          <LocalizationProvider>
            <DesktopDatePicker
              label="Start Date"
              className="w-1/4"
              onChange={(e) => {
                handleChange({ ...circuit, startDate: e?.toISODate()! });
              }}
              value={
                circuit.startDate
                  ? DateTime.fromJSDate(new Date(circuit.startDate))
                  : undefined
              }
              format="dd/LL/yyyy"
            />
            <DesktopDatePicker
              label="End Date"
              onChange={(e) => {
                handleChange({ ...circuit, endDate: e?.toISODate()! });
              }}
              value={
                circuit.endDate
                  ? DateTime.fromJSDate(new Date(circuit.endDate))
                  : undefined
              }
              minDate={
                circuit.startDate
                  ? DateTime.fromJSDate(new Date(circuit.startDate))
                  : undefined
              }
              format="dd/LL/yyyy"
            />
          </LocalizationProvider>
          <Select
            id="game-selector"
            value={circuit.gameId}
            onChange={(e) => {
              handleChange({ ...circuit, gameId: e.target.value });
            }}
          >
            <MenuItem value="cs2">Counter-Strike 2</MenuItem>
            <MenuItem value="rl">Rocket League</MenuItem>
            <MenuItem value="dota">Dota 2</MenuItem>
          </Select>
        </div>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            disabled={!readyToSubmit}
            onClick={() => {
              mutatedSubmit.mutate();
            }}
          >
            Submit
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CreateAndEditCircuitModal;
