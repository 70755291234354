import { useQuery } from "@tanstack/react-query";
import { getTournamentPrizes } from "../../../../../../api/tournaments";

export default function useFetchTournamentPrizes(tournamentId?: string) {
  return useQuery({
    queryKey: ["tournament prizes", tournamentId],
    queryFn: () => {
      return tournamentId ? getTournamentPrizes(tournamentId) : [];
    },
    enabled: tournamentId !== undefined,
    retry: false,
  });
}
