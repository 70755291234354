import { z } from "zod";

export const TeamSidesSchema = z.union([
  z.literal("COUNTER_TERRORISTS"),
  z.literal("TERRORISTS"),
]);

export const PlayerStatSchema = z.object({
  playerSteamId: z.string(),
  kills: z.number(),
  headshots: z.number(),
  firstKills: z.number(),
  effectiveDamageDealt: z.number(),
  effectiveDamageTaken: z.number(),
  utilityDamage: z.number(),
  deaths: z.number(),
  suicides: z.number(),
  assists: z.number(),
});
export type PlayerStat = z.infer<typeof PlayerStatSchema>;

export const MatchMapRoundStatSchema = z.object({
  roundNumber: z.number(),
  winnerSide: TeamSidesSchema.nullable(),
  winnerTeamId: z.string().nullable(),
  firstKillAttackerSteamId: z.string(),
  firstKillAttackerSide: TeamSidesSchema,
  firstKillVictimSteamId: z.string(),
  firstKillVictimSide: TeamSidesSchema,
  players: PlayerStatSchema.array(),
  isSaved: z.boolean(),
});
export type MatchMapRoundStat = z.infer<typeof MatchMapRoundStatSchema>;

export const MatchMapStatSchema = z.object({
  matchUid: z.string(),
  mapNumber: z.number(),
  playerSteamId: z.string(),
  kills: z.number(),
  headshots: z.number(),
  firstKills: z.number(),
  adr: z.number(),
  adt: z.number(),
  utilityDamage: z.number(),
  deaths: z.number(),
  suicides: z.number(),
  assists: z.number(),
  maxStreak: z.number(),
});
export type MatchMapStat = z.infer<typeof MatchMapStatSchema>;

export const MatchMapRoundPlayerStatSchema = PlayerStatSchema.extend({
  matchUid: z.string(),
  mapNumber: z.number(),
  roundNumber: z.number(),
});
export type MatchMapRoundPlayerStat = z.infer<
  typeof MatchMapRoundPlayerStatSchema
>;
