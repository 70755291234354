import { Route, Routes } from "react-router-dom";

import { Avatars } from "./Avatars";
import { Emojis } from "./Emojis";

const Assets = () => {
  return (
    <Routes>
      <Route path="/avatars" element={<Avatars />} />
      <Route path="/emojis" element={<Emojis />} />
    </Routes>
  );
};

export default Assets;
