import { useQuery } from "@tanstack/react-query";
import { fetchMatchMapRoundStats } from "../../../api/statistics";

const useFetchMatchMapRoundStats = ({
  matchId,
  mapNumber,
  enabled,
}: {
  matchId: string;
  mapNumber: number;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: ["live stats match map round", matchId, mapNumber],
    queryFn: () => fetchMatchMapRoundStats(matchId || "", mapNumber || 1),
    enabled,
  });
};

export default useFetchMatchMapRoundStats;
