import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTeamPlayer } from "../../../../api/tournaments";

export default function useDeleteTeamPlayerStint({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["player update role"],
    mutationFn: async ({ teamPlayerId, playerId }: { teamPlayerId: string; playerId: string }) => {
      await deleteTeamPlayer(teamPlayerId);
      void queryClient.invalidateQueries(["players history", playerId]);
    },
    onSuccess,
    onError,
  });
}
