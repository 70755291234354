import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { X } from "lucide-react";
import { useEffect, useState } from "react";

import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import useCreateTriggerPoll from "../../hooks/useCreateTriggerPoll";

import type { CreateTriggerPollArgs } from "../../../../../api/triggers";
import { Broadcast } from "../../../../../types/Broadcasts";

import convertFileToImage from "../../../../../helpers/convertFileToImage";
import getLetterByIndex from "../../../../../helpers/getLetterByIndex";
import { ScrollArea } from "@/components/ui/scroll-area";

const PollCreateModal = ({
  open,
  onClose,
  refetch,
  broadcasts,
}: {
  open: boolean;
  onClose: () => void;
  refetch: () => Promise<unknown>;
  broadcasts: Array<Broadcast>;
}) => {
  const { mutate: createModal, isSuccess, isLoading, reset } = useCreateTriggerPoll();
  const { showFailureAlert } = useAlert();
  const [title, setTitle] = useState<string>("");
  const [broadcastId, setBroadcastId] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);

  const [options, setOptions] = useState<CreateTriggerPollArgs["options"]>(() => {
    return [
      {
        id: crypto.randomUUID(),
        orderIndex: 0,
        value: "",
      },
      {
        id: crypto.randomUUID(),
        orderIndex: 1,
        value: "",
      },
    ];
  });

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    void refetch();
    onClose();
    reset();
  }, [isSuccess, onClose, refetch, reset]);

  const create = () => {
    const optionsWithImageCount = options.reduce((count, option) => count + Number(Boolean(option.imagePreview)), 0);

    if (optionsWithImageCount !== 0 && optionsWithImageCount !== options.length) {
      showFailureAlert("You should either upload all images or leave all blank");
      return;
    }

    if (!title || !broadcastId || !question || !Number.isInteger(duration) || duration < 1) {
      return;
    }

    createModal({
      title,
      broadcastId,
      question,
      durationSeconds: duration,
      options,
      hasImages: optionsWithImageCount > 0,
    });
  };

  const addOption = () => {
    setOptions((options) => [
      ...options,
      {
        id: crypto.randomUUID(),
        value: "",
        orderIndex: (options.at(-1)?.orderIndex as number) + 1,
      },
    ]);
  };

  const removeOption = (id: string) => {
    setOptions((options) => options.filter((option) => option.id !== id));
  };

  const setImage = async (file: undefined | File, indexToUpload: number) => {
    let imagePreview: CreateTriggerPollArgs["options"][0]["imagePreview"];
    if (file) {
      imagePreview = await convertFileToImage(file);
    } else {
      imagePreview = undefined;
    }

    setOptions((options) => {
      return options.map((option, index) =>
        index === indexToUpload
          ? {
              ...option,
              imagePreview,
              image: file,
            }
          : option,
      );
    });
  };

  const setOptionText = (value: string, indexToUpload: number) => {
    setOptions((options) => {
      return options.map((option, index) =>
        index === indexToUpload
          ? {
              ...option,
              value,
            }
          : option,
      );
    });
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Create poll</DialogTitle>
        </DialogHeader>

        <ScrollArea className="max-h-[80vh]">
          <div className="grid gap-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="title">Poll Name *</Label>
                <Input
                  id="title"
                  placeholder="Enter poll name here"
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label>Broadcast *</Label>
                <Select
                  required
                  defaultValue=""
                  onValueChange={(value) => {
                    setBroadcastId(value);
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select the broadcast" />
                  </SelectTrigger>
                  <SelectContent>
                    {broadcasts?.map((broadcast) => (
                      <SelectItem key={broadcast.id} value={broadcast.id}>
                        {broadcast.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="question">Question *</Label>
                <Input
                  id="question"
                  placeholder="Enter question here"
                  required
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="duration">Poll duration (seconds)*</Label>
                <Input
                  id="duration"
                  placeholder="Enter duration here"
                  type="number"
                  required
                  onChange={(e) => setDuration(Number(e.target.value))}
                />
              </div>
            </div>

            {options.map((option, index) => (
              <div key={option.orderIndex} className="grid grid-cols-2 items-start gap-4">
                <div className="space-y-2">
                  <Label>{`Answer ${getLetterByIndex(index)} *`}</Label>
                  <Input
                    placeholder={`Answer ${getLetterByIndex(index)} text here`}
                    required
                    onChange={(e) => setOptionText(e.target.value, index)}
                  />
                </div>

                <div className="flex items-center gap-2">
                  <div className="space-y-2">
                    <Label>Image (Optional)</Label>
                    <div className="flex items-center gap-8">
                      {option.imagePreview && (
                        <img
                          className="w-[150px]"
                          src={`data:${option.imagePreview.mimeType};base64,${option.imagePreview.content}`}
                          alt=""
                        />
                      )}
                      {option.imagePreview ? (
                        <Button variant="secondary" onClick={() => void setImage(undefined, index)}>
                          Remove Image
                        </Button>
                      ) : (
                        <Button variant="secondary" asChild>
                          <label>
                            Upload Image
                            <input
                              type="file"
                              className="hidden"
                              accept="image/*"
                              onChange={(e) => void setImage(e.target?.files?.[0], index)}
                            />
                          </label>
                        </Button>
                      )}
                    </div>
                  </div>

                  {index > 1 && (
                    <Button variant="ghost" size="icon" onClick={() => removeOption(option.id)} className="mt-8">
                      <X className="size-4" />
                    </Button>
                  )}
                </div>
              </div>
            ))}

            <Button onClick={addOption} variant="secondary">
              Add option
            </Button>
          </div>
        </ScrollArea>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} onClick={create}>
            Create poll
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PollCreateModal;
