import { z } from "zod";

export const TwitchGameSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type TwitchGame = z.infer<typeof TwitchGameSchema>;

export const TwitchGamesMapSchema = z.record(TwitchGameSchema);

export type TwitchGamesMap = z.infer<typeof TwitchGamesMapSchema>;
