import { useQuery } from "@tanstack/react-query";
import { getTeamPlayers } from "../../../../api/tournaments";

export default function useFetchTeamPlayers(teamId?: string) {
  return useQuery({
    queryKey: ["team players", teamId],
    queryFn: () => {
      return getTeamPlayers(teamId || "");
    },
    enabled: teamId !== undefined,
    retry: false,
  });
}
