import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createChannel, queryKeys } from "..";

export const useCreateChannel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createChannel,
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.channelList());
    },
  });
};
