import { Add } from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import { useCallback, useState } from "react";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import PageContainer from "../../components/Layout/PageContainer";
import { ModerationRule } from "../../types/Moderation";
import { useStrictDroppable } from "../Broadcasts/BroadcastsList/hooks/useStrictDroppable";
import RuleItem from "./components/RuleItem";
import SaveRuleModal from "./components/SaveRuleModal";
import useFetchModerationRules from "./hooks/useFetchModerationRules";
import useUpdateModerationRules from "./hooks/useUpdateModerationRules";

const ModerationAI = () => {
  const { data: moderationRules, isLoading } = useFetchModerationRules();
  const { mutate: updateModerationRules } = useUpdateModerationRules();
  const [isSaveRuleModalOpen, setIsSaveRuleModalOpen] = useState(false);
  const [editingRule, setEditingRule] = useState<ModerationRule | undefined>(undefined);

  const [droppableEnabled] = useStrictDroppable(isLoading);

  const onDragEnd: OnDragEndResponder = useCallback(
    (result) => {
      const { destination, source } = result;
      if (!destination) return;
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      const newList = [...moderationRules];
      const startIndex = source.index;
      const endIndex = destination.index;
      const [movedItem] = newList.splice(startIndex, 1);
      newList.splice(endIndex, 0, movedItem);

      updateModerationRules(newList);
    },
    [moderationRules, updateModerationRules],
  );

  const onAdd = useCallback(
    (rule: ModerationRule) => {
      updateModerationRules([...moderationRules, rule]);
      setIsSaveRuleModalOpen(false);
    },
    [moderationRules, updateModerationRules],
  );
  const onEdit = useCallback(
    (rule: ModerationRule) => {
      if (!editingRule) {
        return;
      }

      const ruleIndex = moderationRules.findIndex(({ id }) => id === editingRule.id);

      if (ruleIndex === -1) {
        return;
      }

      updateModerationRules([...moderationRules.slice(0, ruleIndex), rule, ...moderationRules.slice(ruleIndex + 1)]);
      setIsSaveRuleModalOpen(false);
    },
    [editingRule, moderationRules, updateModerationRules],
  );
  const onDelete = useCallback(
    (rule: ModerationRule) => {
      updateModerationRules(moderationRules.filter(({ id }) => rule.id !== id));
    },
    [moderationRules, updateModerationRules],
  );

  return (
    <PageContainer>
      <h1 className="text-heading">Moderation (AI)</h1>
      <div className="max-w-7xl">
        <Divider className="my-4" />
        <div className="flex items-start justify-between">
          <div>
            <span className="block font-style-label-1">Prioritized Moderation Rules:</span>
            <span className="my-2 block max-w-2xl text-white/70 font-style-b2-body-copy">
              The priority is there so that the moderation api can perform more efficiently. The most likely to happen
              rules, should be given higher priority. This can be adjusted as we learn how the rules would work more
              efficiently. To reorder, simply drag and drop the rules.
            </span>
          </div>
          <Button color="primary" className="shrink-0 whitespace-nowrap" onClick={() => setIsSaveRuleModalOpen(true)}>
            <Add className="mr-2" />
            Add Rule
          </Button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          {droppableEnabled && (
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className="flex flex-col">
                  {moderationRules.map((rule, index) => (
                    <RuleItem
                      key={rule.id}
                      rule={rule}
                      index={index}
                      onDelete={onDelete}
                      onEdit={() => {
                        setEditingRule(rule);
                        setIsSaveRuleModalOpen(true);
                      }}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </DragDropContext>
      </div>
      <SaveRuleModal
        key={editingRule?.id ?? "new"}
        editingRule={editingRule}
        isOpen={isSaveRuleModalOpen}
        onClose={() => setIsSaveRuleModalOpen(false)}
        onAdd={onAdd}
        onEdit={onEdit}
      />
    </PageContainer>
  );
};

export default ModerationAI;
