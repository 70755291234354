import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { createStage, patchStage } from "../../../../../api/tournaments";
import { PostTournamentSchema } from "../../../../../api/tournaments/schemas/tournaments";
import { useEffect, useState } from "react";
import { usePostPatchTO } from "../../../hooks/usePostPatchTO";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { ZodError } from "zod";
import { StageBuilder } from "../..";
import { PatchStageSchema, PostStageSchema } from "../../../../../api/tournaments/schemas/stages";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

interface CreateEditStageModalProps {
  tournamentId: string;
  stageModalStatus: "new" | "edit" | "closed";
  stage: StageBuilder;
  onChange: (stage: StageBuilder) => void;
  onClose: () => void;
  onSubmit: () => unknown;
}

const stageFormats = [
  "double-elim-4-gauntlet",
  "gsl-4-playoff",
  "double-elim-6",
  "double-elim-8",
  "double-elim-4-skewed",
  "double-elim-8-skewed",
  "double-elim-10-skewed",
  "double-elim-12-skewed",
  "double-elim-16-skewed",
  "gauntlet-4",
  "gauntlet-9",
  "gauntlet-10",
  "single-elim-8",
  "single-elim-6",
  "single-elim-8-bounty",
  "single-elim-32-bounty",
  "swiss-16",
  "showmatch",
  "custom",
  "round-robin",
  "tie-breaker",
];

const CreateEditStageModal = ({
  stageModalStatus,
  onClose,
  stage,
  onChange,
  tournamentId,
  onSubmit,
}: CreateEditStageModalProps) => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);

  useEffect(() => {
    setReadyToSubmit(
      stage.name !== "" &&
        stage.name !== undefined &&
        stage.startDate !== undefined &&
        stage.endDate !== undefined &&
        stage.startDate <= stage.endDate &&
        stage.format !== undefined &&
        (stage.format === "round-robin"
          ? stage.numberOfTeams !== undefined && stage.numberOfTeams !== null && stage.numberOfTeams > 0
            ? true
            : false
          : true) &&
        stage.index !== undefined,
    );
  }, [stage]);

  const alert = useAlert();

  const submit = async () => {
    setIsSubmitButtonDisabled(true);
    if (stageModalStatus === "new") {
      await submitCreate();
    } else if (stageModalStatus === "edit") {
      await submitEdit();
    }
    setIsSubmitButtonDisabled(false);
  };

  const submitCreate = async () => {
    if (stageModalStatus !== "new") {
      throw new Error("Incorrect modal state");
    }
    const data = PostStageSchema.safeParse({ ...stage, tournamentId });
    if (!data.success) {
      const err = data.error as ZodError<typeof PostTournamentSchema>;
      alert.showFailureAlert(`${err.errors[0].path.join()} ${err.errors[0].message}`);
      throw new Error(data.error.message);
    }
    await createStage({
      ...data.data,
    });
    onSubmit();
  };

  const submitEdit = async () => {
    if (stageModalStatus !== "edit") {
      throw new Error("Incorrect modal state");
    }
    if (stage.id === undefined) {
      throw new Error("Stage ID was unexpectedly undefined");
    }

    const data = PatchStageSchema.safeParse(stage);
    if (!data.success) {
      const err = data.error as ZodError<typeof PostTournamentSchema>;
      alert.showFailureAlert(`${err.errors[0].path.join()} ${err.errors[0].message}`);
      throw new Error(data.error.message);
    }
    await patchStage(stage.id, {
      ...data.data,
    });
    onSubmit();
  };

  const mutatedSubmit = usePostPatchTO({
    onSuccess: () => {
      alert.showSuccessAlert(`Stage was successfully ${stage.id ? "edited" : "created"}`);
      onClose();
    },
    onError: () => {
      alert.showFailureAlert(`Stage failed to ${stage.id ? "edit" : "create"}`);
    },
    submit,
    type: "stage",
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog open={stageModalStatus !== "closed"} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>{stageModalStatus === "new" ? "Create Stage" : "Edit Stage"}</DialogTitle>
        <Divider />
        <DialogContent className="grid grid-cols-4 gap-4">
          <TextField
            required
            label="Stage Name"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              onChange({ ...stage, name: e.target.value });
            }}
            value={stage.name}
            error={stage?.name === "" || stage === undefined}
            className="col-span-3"
          />
          <TextField
            required
            label="Index (Start from 0)"
            variant="outlined"
            fullWidth
            type="number"
            onChange={(e) => {
              onChange({ ...stage, index: parseInt(e.target.value) });
            }}
            value={stage.index}
            error={stage?.index === undefined}
          />
          <LocalizationProvider>
            <DesktopDatePicker
              label="Start Date"
              onChange={(e) => {
                onChange({ ...stage, startDate: e?.toJSDate() });
              }}
              value={stage.startDate ? DateTime.fromJSDate(stage.startDate) : undefined}
              format="yyyy-LL-dd"
              className="col-span-2"
            />
            <DesktopDatePicker
              label="End Date"
              onChange={(e) => {
                onChange({ ...stage, endDate: e?.toJSDate() });
              }}
              value={stage.endDate ? DateTime.fromJSDate(stage.endDate) : undefined}
              minDate={stage.startDate ? DateTime.fromJSDate(stage.startDate) : undefined}
              format="yyyy-LL-dd"
              className="col-span-2"
            />
          </LocalizationProvider>
          <FormControl className="col-span-2">
            <InputLabel id="modal-format-select">Format</InputLabel>
            <Select
              labelId="modal-format-select"
              value={stage.format || "none"}
              label="Circuit"
              onChange={(e) => {
                onChange({ ...stage, format: e.target.value });
              }}
            >
              {stageFormats?.map((format) => (
                <MenuItem value={format} key={format}>
                  {format}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(stage.format === "round-robin" || stage.format === "tie-breaker") && (
            <>
              <TextField
                label="Number of teams"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  onChange({
                    ...stage,
                    numberOfTeams: parseInt(e.target.value),
                  });
                }}
                value={stage.numberOfTeams}
                error={
                  ((stage?.format === "round-robin" || stage?.format === "tie-breaker") &&
                    stage?.numberOfTeams === undefined) ||
                  stage?.numberOfTeams === 0
                }
              />
              {stage.format === "round-robin" && (
                <TextField
                  label="Number of teams advancing"
                  variant="outlined"
                  type="number"
                  placeholder="3"
                  onChange={(e) => {
                    onChange({
                      ...stage,
                      metadata: {
                        numberOfTeamsAdvances: parseInt(e.target.value),
                      },
                    });
                  }}
                  value={stage.metadata?.numberOfTeamsAdvances || 0}
                />
              )}
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={!readyToSubmit || isSubmitButtonDisabled}
            onClick={() => {
              mutatedSubmit.mutate();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CreateEditStageModal;
