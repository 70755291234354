import { useQuery } from "@tanstack/react-query";
import { getMatchMaps } from "../../../../api/tournaments";

export default function useFetchMatchMaps(matchId?: string) {
  return useQuery({
    queryKey: ["matchMaps", matchId],
    queryFn: () => {
      return matchId ? getMatchMaps(matchId) : undefined;
    },
    enabled: !!matchId,
    retry: false,
  });
}
