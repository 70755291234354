import useStartStream from "../../hooks/useStartStream";
import useStopStream from "../../hooks/useStopStream";
import { Stream } from "../../../../types/Streams";
import { TableCell } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { PlayCircle, StopCircle } from "lucide-react";

export default function StreamActionCell({ stream, onClick }: { stream: Stream; onClick: () => void }) {
  const { mutate: startStreamMutation, isLoading: streamStarting, data: startedStream } = useStartStream(stream.id);

  const { mutate: stopStreamMutation, isLoading: streamStopping, data: stoppedStream } = useStopStream(stream.id);

  const startStream = () => {
    if (!streamStarting) {
      startStreamMutation();
    }
  };

  const stopStream = () => {
    if (!streamStopping) {
      stopStreamMutation();
    }
  };

  useEffect(() => {
    if (!streamStarting && startedStream) {
      onClick();
    }
  }, [onClick, streamStarting, startedStream]);

  useEffect(() => {
    if (!streamStopping && stoppedStream) {
      onClick();
    }
  }, [onClick, streamStopping, stoppedStream]);

  return (
    <TableCell>
      {stream.state === "RUNNING" ? (
        <Button variant="destructive" disabled={stream.state !== "RUNNING"} onClick={stopStream}>
          Stop stream
          <StopCircle className="mr-2 size-4" />
        </Button>
      ) : (
        <Button variant="outline" disabled={stream.state !== "STOPPED"} onClick={startStream}>
          Start stream
          <PlayCircle className="mr-2 size-4" />
        </Button>
      )}
    </TableCell>
  );
}
