import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getIssuesByEntityId } from ".";

export const useGetIssuesByEntityId = (params?: Parameters<typeof getIssuesByEntityId>[0]) => {
  return useQuery({
    queryKey: queryKeys.byEntityId(params),
    queryFn: params ? () => getIssuesByEntityId(params) : undefined,
  });
};
