import { useMutation } from "@tanstack/react-query";

import { stopStream } from "../../../../../api/streams";

const useStopStream = (id: string) =>
  useMutation({
    mutationKey: ["streams", "stop", id],
    mutationFn: () => stopStream(id),
  });

export default useStopStream;
