import { useQuery } from "@tanstack/react-query";

import { getAvatars } from "../../../../../api/assets";

export default function useFetchAvatars() {
  return useQuery({
    queryKey: ["avatars"],
    queryFn: () => getAvatars(),
    cacheTime: 1000 * 60 * 60 * 12, // 12 hours
  });
}
