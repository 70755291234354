import axios from "axios";
import { z } from "zod";

import { GetChatMessagesOptions, SendChatMessageOptions } from "./options";
import { Message } from "../../types/Message";
import { Room, UpdateRoom } from "../../types/Room";
import { SuspensionSchema, Suspension } from "../../types/Suspension";

import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";

import { truncateUsernamePatronPrefix } from "../../routes/Moderation/helpers/truncateUsernamePatronPrefix";

const chatApi = axios.create({
  baseURL: `${baseApiURL}/v1/chat`,
});
chatApi.interceptors.request.use(authInterceptor);

export const getChatMessages = async ({
  patron,
  ...options
}: GetChatMessagesOptions) => {
  options.limit ??= 50;

  const response: {
    nextPageToken?: string;
    messages: Array<Message>;
  } = {
    messages: [],
  };

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { data } = await chatApi.get<typeof response>("/messages", {
      params: {
        ...options,
        pageToken: response.nextPageToken ?? options.pageToken,
      },
    });

    response.messages.push(...data.messages);
    response.nextPageToken = data.nextPageToken;

    if (
      response.messages.length >= Math.min(options.limit, 10) ||
      !response.nextPageToken
    ) {
      break;
    }
  }

  response.messages.forEach((message) => {
    truncateUsernamePatronPrefix(message, patron);
  });

  return response;
};

export const getChatRoomConfig = async (uuid: string): Promise<Room> => {
  const { data } = await chatApi.get<Room>(`/rooms/${uuid}`);

  return data;
};

export const updateChatRoomConfig = async (room: UpdateRoom): Promise<Room> => {
  const { data } = await chatApi.patch<Room>(`/rooms/${room.roomId}`, room);

  return data;
};

export const deleteMessage = async ({
  messageId,
  deletedBy,
  suspensionId,
}: {
  messageId: string;
  deletedBy: string;
  suspensionId?: string;
}): Promise<Message> => {
  const { data } = await chatApi.delete<Message>(`/messages/${messageId}`, {
    params: {
      deletedBy,
      suspensionId,
    },
  });

  return data;
};

export const restoreMessage = async ({
  messageId,
}: {
  messageId: string;
}): Promise<Message> => {
  const { data } = await chatApi.patch<Message>(
    `/messages/${messageId}/restore`,
  );
  return data;
};

export const createSuspension = async (
  body: Omit<Suspension, "suspensionId" | "endTime" | "createdAt">,
): Promise<Suspension> => {
  const { data: suspension } = await chatApi.post<Suspension>(
    "/suspensions",
    body,
  );

  return suspension;
};

export const cancelSuspension = async (id: string): Promise<void> => {
  await chatApi.delete(`/suspensions/${id}`);
};

export const getSuspensions = async (userId: string) => {
  const { data } = await chatApi.get<unknown>("/suspensions", {
    params: {
      userId,
    },
  });

  return z
    .object({
      items: SuspensionSchema.array(),
    })
    .parse(data);
};

export const sendChatMessage = async (
  body: SendChatMessageOptions,
): Promise<Message> => {
  const { data } = await chatApi.post<Message>("/messages", body);

  return data;
};
