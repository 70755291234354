import axios from "axios";
import authInterceptor, { clearCookies } from "../authInterceptor";
import { baseApiURL } from "../../config";
import {
  PermissionsSchema,
  Role,
  RolesPermissionsResponseSchema,
  RolesResponseSchema,
} from "./schemas";

const authApi = axios.create({
  baseURL: `${baseApiURL}/v1/auth`,
  withCredentials: true,
});
authApi.interceptors.request.use(authInterceptor);

export interface PostLoginPayload {
  login: string;
  password: string;
}

export async function postLogin({
  login,
  password,
}: PostLoginPayload): Promise<void> {
  return authApi.post(`/login`, {
    login,
    password,
  });
}

export async function postLogout(): Promise<void> {
  await authApi.post(`/logout`).finally(() => {
    clearCookies();
  });
}

export async function createRole(role: Pick<Role, "name" | "description">) {
  await authApi.post("/roles", role);
}

export async function getRoles() {
  const [{ data: rolesResponse }, { data: rolesPermissionsResponse }] =
    await Promise.all([
      authApi.get<unknown>("/roles"),
      authApi.get<unknown>("/roles/permissions"),
    ]);

  const { roles } = RolesResponseSchema.parse(rolesResponse);
  const rolesPermissions = RolesPermissionsResponseSchema.parse(
    rolesPermissionsResponse,
  );

  return roles.map((role) => {
    return {
      ...role,
      permissions: rolesPermissions[role.name]?.permissions,
    };
  });
}

export async function getPermissions() {
  const { data } = await authApi.get<unknown>("/permissions");

  return PermissionsSchema.parse(data);
}

export async function updateRolePermissions(
  role: string,
  permissions: Array<string>,
) {
  await authApi.post(`/permissions/${role}`, {
    permissions,
  });
}
