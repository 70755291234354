import axios from "axios";
import { baseApiURL } from "../../config";
// import { DateTime } from "luxon";
import authInterceptor from "../authInterceptor";
import {
  CircuitCreateRequest,
  DbCircuit,
  DbCircuitSchema,
} from "./schemas/circuits";
import { DateTime } from "luxon";
import {
  DbTournament,
  DbTournamentSchema,
  DetailedTournament,
  DetailedTournamentSchema,
  TournamentCreateRequest,
  TournamentPatchRequest,
} from "./schemas/tournament";
import {
  DbStage,
  DbStageSchema,
  StageCreateRequest,
  StageUpdateRequest,
} from "./schemas/stages";
import {
  DbMatch,
  DbMatchSchema,
  DbTournamentMatch,
  DbTournamentMatchSchema,
  MatchCreateRequest,
  DbTeamMatch,
  DbTeamMatchSchema,
  UpdateMatchRequest,
  DbMatchDetailsSchema,
} from "./schemas/matches";

export const communityTournamentApi = axios.create({
  baseURL: `${baseApiURL}/v1/community`,
});

communityTournamentApi.interceptors.request.use(authInterceptor);

export const getCircuits = async (): Promise<DbCircuit[]> => {
  const { data } = await communityTournamentApi.get<unknown>(
    "/tournaments/circuits",
  );
  const circuits = DbCircuitSchema.array().parse(data);
  return circuits;
};

export const postNewCircuit = async (
  circuit: CircuitCreateRequest,
): Promise<void> => {
  await communityTournamentApi.post(`/tournaments/circuits`, {
    ...circuit,
    startDate: DateTime.fromISO(circuit.startDate).toFormat("yyyy-MM-dd"),
    endDate: DateTime.fromISO(circuit.endDate).toFormat("yyyy-MM-dd"),
  });
};

export const patchCircuit = async (
  cicuitDates: { startDate: Date; endDate: Date },
  circuitId: string,
): Promise<void> => {
  await communityTournamentApi.patch(`/tournaments/circuits/${circuitId}`, {
    startDate: DateTime.fromJSDate(cicuitDates.startDate).toFormat(
      "yyyy-MM-dd",
    ),
    endDate: DateTime.fromJSDate(cicuitDates.endDate).toFormat("yyyy-MM-dd"),
  });
};

export const getCircuitById = async (circuitId: string): Promise<DbCircuit> => {
  const { data } = await communityTournamentApi.get<unknown>(
    `/tournaments/circuits/${circuitId}`,
  );
  const circuit = DbCircuitSchema.parse(data);
  return circuit;
};

export const getTournaments = async (): Promise<DbTournament[]> => {
  const { data } = await communityTournamentApi.get<unknown>(`/tournaments`);
  const tournaments = DbTournamentSchema.array().parse(data);
  return tournaments;
};
export const getTournamentsByCircuitId = async (
  circuitId: string,
): Promise<DbTournament[]> => {
  const { data } = await communityTournamentApi.get<unknown>(
    `/circuits/${circuitId}/tournaments`,
  );
  const tournaments = DbTournamentSchema.array().parse(data);
  return tournaments;
};

export const postNewTournament = async (
  tournament: TournamentCreateRequest,
): Promise<void> => {
  await communityTournamentApi.post(`/tournaments`, {
    ...tournament,
  });
};

export const patchTournament = async (
  tournament: TournamentPatchRequest,
  tournamentId: string,
): Promise<void> => {
  await communityTournamentApi.patch(`/tournaments/${tournamentId}`, {
    ...tournament,
  });
};

export const getTournamentById = async (
  tournamentId: string,
): Promise<DetailedTournament> => {
  const { data } = await communityTournamentApi.get<unknown>(
    `/tournaments/${tournamentId}`,
  );
  const tournament = DetailedTournamentSchema.parse(data);
  return tournament;
};

export const getStageByTournamentId = async (
  tournamentId: string,
): Promise<DbStage[]> => {
  const { data } = await communityTournamentApi.get<DbStage>(
    `/tournaments/${tournamentId}/stages`,
  );
  return DbStageSchema.array().parse(data);
};

export const createStage = async (
  stage: StageCreateRequest,
): Promise<DbStage> => {
  const { data } = await communityTournamentApi.post<unknown>(
    "/tournaments/stages",
    {
      ...stage,
    },
  );
  return DbStageSchema.parse(data);
};

export const patchStage = async (
  stageId: string,
  properties: StageUpdateRequest,
): Promise<DbStage> => {
  const { data } = await communityTournamentApi.patch<unknown>(
    `/stages/${stageId}`,
    {
      ...properties,
    },
  );
  return DbStageSchema.parse(data);
};

export const getMatchesByTournamentId = async (
  tournamentId: string,
): Promise<DbTournamentMatch[]> => {
  const { data } = await communityTournamentApi.get<DbTournamentMatch[]>(
    `/tournaments/${tournamentId}/matches`,
  );
  return DbTournamentMatchSchema.array().parse(data);
};

export const shuffleTeamsOntoTournamentMatches = async (
  tournamentId: string,
): Promise<void> => {
  await communityTournamentApi.post(
    `/tournaments/${tournamentId}/matches/shuffleTeams`,
  );

  return;
};

export const postMatch = async (
  match: MatchCreateRequest,
): Promise<DbMatch> => {
  const { data } = await communityTournamentApi.post<unknown>(
    "/tournaments/matches",
    {
      ...match,
    },
  );
  return DbMatchSchema.parse(data);
};

export const patchMatch = async ({
  matchId,
  matchProperties,
}: {
  matchId: string;
  matchProperties: UpdateMatchRequest;
}): Promise<DbMatch> => {
  const { data } = await communityTournamentApi.patch<unknown>(
    `/tournaments/matches/${matchId}`,
    {
      ...matchProperties,
    },
  );
  return DbMatchSchema.parse(data);
};

export const postMatchTeam = async (
  teamMatch: DbTeamMatch,
): Promise<DbTeamMatch> => {
  const { data } = await communityTournamentApi.post<unknown>(
    `/tournaments/matches/${teamMatch.matchId}/teams`,
    {
      teamId: teamMatch.teamId,
      score: teamMatch.score,
      index: teamMatch.index,
    },
  );
  return DbTeamMatchSchema.parse(data);
};

export const patchMatchTeam = async ({
  matchId,
  teamId,
  score,
}: {
  matchId: string;
  teamId: string;
  score: number;
}): Promise<DbTeamMatch> => {
  const { data } = await communityTournamentApi.patch<unknown>(
    `/tournaments/matches/${matchId}/teams/${teamId}`,
    { score },
  );
  return DbTeamMatchSchema.parse(data);
};

export const deleteMatchTeam = async ({
  matchId,
  teamId,
}: {
  matchId: string;
  teamId: string;
}): Promise<void> => {
  await communityTournamentApi.delete(
    `/tournaments/matches/${matchId}/teams/${teamId}`,
  );
};

interface MatchAutoProgressOptions {
  gameId?: string;
  matchId: string;
}
export const matchAutoProgress = async ({
  matchId,
}: MatchAutoProgressOptions) => {
  const { data } = await communityTournamentApi.get<unknown>(
    `/tournaments/matches/${matchId}/autoprogress`,
  );
  try {
    return DbMatchDetailsSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};
