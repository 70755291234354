import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateTeamPlayer } from "../../../../api/tournaments";

export default function useUpdateTeamPlayerStint({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["player update role"],
    mutationFn: async ({
      playerId,
      teamPlayerId,
      fromDate,
      role,
      teamId,
    }: {
      playerId: string;
      teamPlayerId: string;
      fromDate: Date | null;
      role?: string | null;
      teamId?: string | null;
    }) => {
      await updateTeamPlayer({
        teamPlayerId,
        teamPlayerInfo: {
          fromDate,
          role,
          teamId: teamId === "null" || teamId === "" ? null : teamId,
        },
      });
      await queryClient.invalidateQueries(["players history", playerId]);
    },
    onSuccess,
    onError,
  });
}
