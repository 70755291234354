import axios from "axios";
import { createUploadLink, invalidateCache } from "../../../../api/assets";
import { Patron } from "../../../../providers/PatronProvider/types";

interface UploadEmojiImageProps {
  image: File;
  emojiId: string;
  sendSuccessMessage: (message: string) => void;
  sendErrorMessage: (message: string) => void;
  patron?: Patron;
}

export const uploadEmojiImage = async ({
  emojiId,
  image,
  sendSuccessMessage,
  sendErrorMessage,
  patron,
}: UploadEmojiImageProps) => {
  const fileName = emojiId;
  let uploadUrl = "";
  try {
    ({ uploadUrl } = await createUploadLink("emojis", fileName, patron));
  } catch (error) {
    sendErrorMessage("Failed to get upload url.");
    throw error;
  }

  try {
    await axios.put(uploadUrl, image, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Length": image.size,
        "Content-Type": image.type,
      },
    });
  } catch (error) {
    sendErrorMessage("Emoji image failed to upload.");
    throw error;
  }

  try {
    await invalidateCache("emojis", fileName, patron);
  } catch (error) {
    sendErrorMessage("Failed to invalidate cache.");
    throw error;
  }

  sendSuccessMessage("Emoji image uploaded successfully.");
};
