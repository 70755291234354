import { CheckIcon } from "@heroicons/react/20/solid";
import {
  FormControl,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import classNames from "classnames";
import { AvatarImage } from "../../../../../../../../../../UserProfile/components/EditAvatarForm/styled";

export interface SelectWithImageOption {
  id: string;
  name: string;
  image: string;
}

interface SelectWithImageProps {
  values: SelectWithImageOption[];
  selected?: SelectWithImageOption;
  onChange: (value: SelectWithImageOption) => void;
  label: string;
  id?: string;
  optionColor?: string;
}

export default function SelectWithImage({
  values,
  selected,
  onChange,
  label,
  optionColor,
  id,
}: SelectWithImageProps) {
  return (
    <FormControl className="h-full" key={id}>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        className="border-green"
        value={selected ?? ""}
        input={<OutlinedInput label="Select" />}
        renderValue={(selected) => (
          <div className="flex gap-2 items-center ">
            <img src={selected.image} className="h-5 w-5" alt="Select" />
            <span className="font-style-label-3">{selected.name}</span>
            <div className={classNames("h-3 w-3 rounded-full", optionColor)} />
          </div>
        )}
      >
        {values?.map((avatar) => (
          <MenuItem
            onClick={() => onChange(avatar)}
            key={avatar.id}
            value={avatar.name}
          >
            {selected?.id === avatar.id ? (
              <CheckIcon className="h-5 w-5 mr-2 text-neutral-20" />
            ) : (
              <div className="h-5 w-5 mr-2" />
            )}
            <ListItemAvatar>
              <AvatarImage src={avatar.image} />
            </ListItemAvatar>
            <ListItemText primary={avatar.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
