import { useContext } from "react";
import PointsContext from "./PointsCollectionsContext";

export function usePointsCollections() {
  const context = useContext(PointsContext);
  if (context === undefined) {
    throw new Error(
      "usePointsContext must be used within a PointsCollectionsProvider"
    );
  }
  return context;
}

export default usePointsCollections;
