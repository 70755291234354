import { TableCell } from "@mui/material";
import styled from "styled-components/macro";

export const Form = styled.form<{
  themeColor: string;
}>`
  max-width: 600px;
  margin: auto;
  padding: 1rem;
  background-color: ${({ themeColor }) =>
    themeColor === "light" ? "white" : "black"};
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;

  > * {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

export const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Td = styled(TableCell)`
  border: none;
  border-bottom: none;
`;

export const AvatarImage = styled.img`
  height: 2rem;
  width: 2rem;
`;
