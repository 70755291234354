import { Alert, Box, Breadcrumbs, Button, Typography } from "@mui/material";
import PageContainer from "../../../components/Layout/PageContainer";
import { useState } from "react";
import { AvatarsTable } from "./components/AvatarsTable";
import { useQuery } from "@tanstack/react-query";
import { Avatar } from "../../../types/Avatar";
import { CreateAvatarModal } from "./components/CreateAvatarModal";
import { EditAvatarModal } from "./components/EditAvatarModal";
import useAlert from "../../../providers/AlertProvider/hooks/useAlert";
import { editAvatar } from "./helpers/editAvatar";
import { deleteAvatar } from "./helpers/deleteAvatar";
import { uploadAvatarImage } from "./helpers/uploadAvatarImage";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { differenceInObject } from "../shared/helpers/differenceInObject";
import { getAvatars } from "../../../api/assets";
import { PatronBreadcrumbItem } from "../../../providers/PatronProvider/components/PatronBreadcrumbItem";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";

export const Avatars = () => {
  const alert = useAlert();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteAvatarConfirmationOpen, setIsDeleteAvatarConfirmationOpen] =
    useState(false);
  const [deleteAvatarId, setDeleteAvatarId] = useState("");
  const [editingAvatar, setEditingAvatar] = useState<Avatar>();
  const { patron } = usePatron();

  const { data: avatars } = useQuery({
    queryKey: ["avatars", patron],
    queryFn: () => getAvatars(patron),
  });

  const setActiveEditingAvatar = (avatarId: string) => {
    const editAvatar = avatars?.find((avatar) => avatar.id === avatarId);
    if (!editAvatar) return;
    setEditingAvatar(editAvatar);
    setIsEditModalOpen(true);
  };

  const onAvatarEdit = async ({
    avatar: changedAvatar,
    smallImage,
    largeImage,
  }: {
    avatar: Avatar;
    smallImage: File | null;
    largeImage: File | null;
  }) => {
    const originalAvatar = avatars?.find(
      (avatar) => avatar.id === changedAvatar.id,
    );
    if (!originalAvatar) return;

    const difference = differenceInObject({
      original: originalAvatar,
      changed: changedAvatar,
    });

    await editAvatar({
      avatarId: changedAvatar.id,
      avatarData: difference,
      patron,
      sendSuccessMessage: (message) => alert.showSuccessAlert(message),
      sendErrorMessage: (message) => alert.showFailureAlert(message),
    });

    if (smallImage !== null) {
      await uploadAvatarImage({
        avatarId: changedAvatar.id,
        image: smallImage,
        type: "2d",
        patron,
        sendSuccessMessage: (message) => {
          alert.showSuccessAlert(message);
        },
        sendErrorMessage: (message) => {
          alert.showFailureAlert(message);
        },
      });
    }

    if (largeImage !== null) {
      await uploadAvatarImage({
        avatarId: changedAvatar.id,
        image: largeImage,
        type: "3d",
        patron,
        sendSuccessMessage: (message) => {
          alert.showSuccessAlert(message);
        },
        sendErrorMessage: (message) => {
          alert.showFailureAlert(message);
        },
      });
    }

    setIsEditModalOpen(false);
  };

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.secondary" fontWeight="bold">
          Assets
        </Typography>
        <Typography color="text.primary" fontWeight="bold">
          Avatars
        </Typography>
        <PatronBreadcrumbItem />
      </Breadcrumbs>

      <Box
        sx={{ display: "flex", gap: "15rem", alignItems: "center" }}
        className="mt-10"
      >
        <h2>Avatars</h2>
        <Button
          sx={{ fontWeight: "bold" }}
          variant="contained"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Create Avatar
        </Button>
      </Box>

      <Alert severity="warning">
        If data or image hasn&apos;t updated, please to clear browser cache, and
        refresh the page.
      </Alert>

      <AvatarsTable
        avatars={avatars}
        setEditingAvatar={setActiveEditingAvatar}
      />

      {isDeleteAvatarConfirmationOpen && (
        <ConfirmationModal
          isOpen={isDeleteAvatarConfirmationOpen}
          onClose={() => setIsDeleteAvatarConfirmationOpen(false)}
          title="Delete Avatar?"
          description="Are you sure that you want to delete this avatar?"
          onConfirm={async () => {
            await deleteAvatar({
              avatarId: deleteAvatarId,
              sendSuccessMessage: (message) => alert.showSuccessAlert(message),
              sendErrorMessage: (message) => alert.showFailureAlert(message),
              patron,
            });
            setIsEditModalOpen(false);
            setIsDeleteAvatarConfirmationOpen(false);
          }}
        />
      )}

      {isCreateModalOpen && (
        <CreateAvatarModal
          onClose={() => setIsCreateModalOpen(false)}
          open={isCreateModalOpen}
          isLoading={false}
        />
      )}

      {isEditModalOpen && editingAvatar && (
        <EditAvatarModal
          open={isEditModalOpen}
          isLoading={false}
          avatar={editingAvatar}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={(...args: Parameters<typeof onAvatarEdit>) =>
            void onAvatarEdit(...args)
          }
          onDelete={(avatarId) => {
            setDeleteAvatarId(avatarId);
            setIsDeleteAvatarConfirmationOpen(true);
            setIsEditModalOpen(false);
          }}
        />
      )}
    </PageContainer>
  );
};
