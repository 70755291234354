import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import { Stream } from "../../types/Streams";

const videosApi = axios.create({
  baseURL: `${baseApiURL}/v1/videos/streams`,
});

videosApi.interceptors.request.use(authInterceptor);

export async function getStreams(): Promise<Array<Stream>> {
  const { data } = await videosApi.get<Array<Stream>>("");

  return data;
}

export async function getStreamById(id: string): Promise<Stream> {
  const { data } = await videosApi.get<Stream>(`/${id}`);

  return data;
}

export type CreateStreamPayload = Pick<
  Stream,
  "title" | "startTime" | "timelineOffset" | "highestProfile" | "provider" | "inputTier"
>;

export async function createStream(payload: CreateStreamPayload): Promise<void> {
  return videosApi.post("", payload);
}

export async function startStream(id: string) {
  const { data } = await videosApi.post<Pick<Stream, "startTime" | "state">>(`/${id}/start`);

  return data;
}

export async function stopStream(id: string) {
  const { data } = await videosApi.post<Pick<Stream, "startTime" | "state">>(`/${id}/stop`);

  return data;
}

export async function updateStream(id: string, stream: Stream) {
  const { data } = await videosApi.patch<Stream>(`/${id}`, stream);

  return data;
}

export async function deleteStream(uid: string): Promise<void> {
  return videosApi.delete(`/${uid}`);
}
