import youtubeSvg from "./assets/youtube-icon.svg";
import twitchSvg from "./assets/twitch-icon.svg";
import tiktokSvg from "./assets/tiktok-icon.svg";
import kickSvg from "./assets/kick-icon.svg";
import blastSvg from "./assets/blast-icon.svg";

export const PlatformIcon = ({ platform }: { platform: "youtube" | "twitch" | "tiktok" | "kick" | "blasttv" }) => {
  const iconSrc = {
    youtube: youtubeSvg,
    twitch: twitchSvg,
    tiktok: tiktokSvg,
    kick: kickSvg,
    blasttv: blastSvg,
  }[platform];

  if (!iconSrc) return platform;

  return <img src={iconSrc} alt="" className="size-6 min-w-6" />;
};
