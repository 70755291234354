// External Libraries
import { ArrowPathIcon, CheckIcon, TruckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";

// Internal Libraries
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import { useGetMultipleUsers } from "../../../../../hooks/useGetMultipleUsers";
import { useChangeProductDeliverStatus } from "../../hooks/useChangeProductDeliverStatus";
import { useGetHighEndProducts } from "../../hooks/useGetHighEndProducts";
import { ConfirmDeliveryActionModal } from "../ConfirmDeliveryActionModal";

export default function DeliveryTable() {
  // State variables
  const [open, setOpen] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [product, setProduct] = useState<{
    id: string;
    name: string;
    productId: string;
    username?: string;
    email?: string;
  } | null>(null);

  // Hooks
  const { data, isLoading } = useGetHighEndProducts({ delivered });
  const { mutate } = useChangeProductDeliverStatus({
    onSuccess: () => {
      setOpen(false);
      setProduct(null);
    },
  });

  // Extract user IDs using useMemo for optimization
  const userIds = useMemo(() => {
    const ids = data?.purchases.map((purchase) => purchase.userId) ?? [];
    return [...new Set(ids)];
  }, [data]);

  const { data: users } = useGetMultipleUsers({ ids: userIds });

  // Handler Functions
  const handleEditClick = (id: string, name: string, productId: string, username?: string, email?: string) => () => {
    setOpen(true);
    setProduct({ id, name, productId, username, email });
  };

  const handleClose = () => setOpen(false);

  const handleActionConfirm = () => {
    if (product?.productId) {
      mutate({ id: product.id, delivered: !delivered });
    }
  };

  // Prepare rows and columns
  const rows = useMemo(() => {
    if (!data) return [];
    return data.purchases.map((purchase) => {
      const user = users?.find((user) => user.id === purchase.userId);
      return { ...purchase, username: user?.username, email: user?.email };
    });
  }, [data, users]);

  const columns: GridColDef<(typeof rows)[number], string>[] = [
    {
      field: "productId",
      headerName: "Image",
      headerAlign: "center",
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex w-full place-content-center items-center">
          {!!params.value && (
            <img
              src={generateImageUrl("products", params.value, {
                width: "100",
                format: "auto",
              })}
              className="h-7"
              alt="product"
            />
          )}
        </div>
      ), // renderCell will render the component
    },
    {
      field: "productName",
      headerName: "Product name",
      width: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "productPrice",
      headerName: "Cost",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "purchaseDate",
      headerName: "Purchase Date",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="flex w-full place-content-center items-center">
          {DateTime.fromISO(params.value as string).toFormat("DD")}
        </div>
      ), // renderCell will render the component
    },
    {
      field: "delivered",
      headerName: "Delivered",
      align: "center",
      flex: 1,
      headerAlign: "center",
      renderCell: () => (
        <div className="flex w-full place-content-center items-center">
          {delivered ? <CheckIcon className="size-6 text-green-500" /> : <XMarkIcon className="size-6 text-red-500" />}
        </div>
      ), // renderCell will render the component
    },
    {
      field: "username",
      headerName: "Username",
      width: 200,
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      headerName: "Deliver",
      width: 100,
      cellClassName: "actions",
      getActions: (data) => {
        return [
          <GridActionsCellItem
            key={data.row.productId}
            icon={
              <TruckIcon
                className={classNames("h-4 w-4", {
                  "text-green-500": !delivered,
                  "text-red-500": delivered,
                })}
              />
            }
            label="Edit"
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="textPrimary"
            onClick={handleEditClick(
              data.row.id,
              data.row.productName,
              data.row.productId,
              data.row.username,
              data.row.email,
            )}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full items-center justify-between">
        <ToggleButtonGroup
          color="primary"
          value={delivered ? "delivered" : "deliver"}
          exclusive
          onChange={() => setDelivered(!delivered)}
          aria-label="Platform"
        >
          <ToggleButton value="deliver">To Process</ToggleButton>
          <ToggleButton value="delivered">Processed</ToggleButton>
        </ToggleButtonGroup>
        <div>
          <ArrowPathIcon
            className={classNames("mr-3 h-5 w-5 text-gray-500", {
              "animate-spin": isLoading,
            })}
          />
        </div>
      </div>
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
      />
      <ConfirmDeliveryActionModal
        isOpen={open}
        onClose={handleClose}
        onConfirm={handleActionConfirm}
        title={!delivered ? "Confirm delivery of item" : "Confirm undelivery of item"}
        productTitle={product?.name}
        productUrl={generateImageUrl("products", product?.productId?.toString() ?? "", {
          width: "300",
          format: "auto",
        })}
        username={product?.username}
        email={product?.email}
      />
    </div>
  );
}
