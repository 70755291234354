import { Alert, Box, Breadcrumbs, Button, Typography } from "@mui/material";
import PageContainer from "../../../components/Layout/PageContainer";
import { CreateEditCollectionModal } from "./components/CreateEditCollectionsModal";
import { useGetCollections } from "./hooks/useGetCollections";
import usePointsCollections from "./usePointsCollections/usePointsCollections";
import PointsCollectionsProvider from "./usePointsCollections/PointsCollectionsProvider";
import { CollectionsTable } from "./components/CollectionsTable";

const CollectionsContents = () => {
  const { setModal } = usePointsCollections();
  const { data: collections } = useGetCollections();

  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.secondary" fontWeight="bold">
          Points
        </Typography>
        <Typography color="text.primary" fontWeight="bold">
          Collections
        </Typography>
      </Breadcrumbs>

      <Box sx={{ display: "flex", gap: "15rem", alignItems: "center" }}>
        <h2>Collections</h2>
        <Button
          sx={{ fontWeight: "bold" }}
          variant="contained"
          onClick={() => setModal(true, "create")}
        >
          Create Collection
        </Button>
      </Box>

      <Alert severity="warning">
        If data or image hasn&apos;t updated, please to clear browser cache, and
        refresh the page.
      </Alert>

      <CollectionsTable collections={collections?.collections ?? []} />

      <CreateEditCollectionModal />
    </PageContainer>
  );
};

export const Collections = () => {
  return (
    <PointsCollectionsProvider>
      <CollectionsContents />
    </PointsCollectionsProvider>
  );
};
