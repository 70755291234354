import { useMutation } from "@tanstack/react-query";
import { deleteLocation } from "../../../api/csguessr";

const useDeleteLocation = ({
  locationId,
  onSuccess,
  onError,
}: {
  locationId?: string;
  onSuccess: () => void;
  onError: () => void;
}) => {
  return useMutation({
    mutationKey: ["location", locationId],
    onSuccess: () => onSuccess(),
    onError: () => onError(),
    mutationFn: locationId ? () => deleteLocation(locationId) : undefined,
  });
};

export default useDeleteLocation;
