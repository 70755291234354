import { z } from "zod";

export const EmojiSchema = z.object({
  id: z.string(),
  name: z.string(),
  orderIndex: z.number(),
  code: z.string(),
  score: z.number().optional(),
  hidden: z.boolean().optional(),
  isUnlockOnly: z.boolean().optional(),
  isClaimable: z.boolean().optional(),
});

export type Emoji = z.infer<typeof EmojiSchema>;
