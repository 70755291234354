import { useQuery } from "@tanstack/react-query";
import { getCircuitById } from "../../../api/tournaments";

export default function useFetchCircuitById(circuitId: string) {
  return useQuery({
    queryKey: ["circuit", circuitId],
    queryFn: () => {
      return getCircuitById(circuitId);
    },
    enabled: !!circuitId,
    retry: false,
  });
}
