import { CheckCircle, Error, Sync } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useCalculateLeaderboards } from "./hooks";

type SyncState = "syncing" | "success" | "error" | "idle";

const LeaderboardSyncContainer = ({
  seasonId,
  eventId,
  calculationSchemaId,
}: {
  seasonId?: string;
  eventId?: string;
  calculationSchemaId?: string;
}) => {
  const [syncState, setSyncState] = useState<SyncState>("idle");
  const { mutate: mutateCalculateLeaderboards } = useCalculateLeaderboards();

  const handleSyncLeaderboard = useCallback(() => {
    if (!seasonId || !eventId || !calculationSchemaId) {
      return;
    }

    setSyncState("syncing");

    mutateCalculateLeaderboards(
      { seasonId, eventId, calculationSchemaId },
      {
        onSuccess: () => setSyncState("success"),
        onError: () => setSyncState("error"),
      },
    );
  }, [mutateCalculateLeaderboards, seasonId, eventId, calculationSchemaId]);

  return (
    <Box display="flex" alignItems="center">
      <Typography fontWeight="bold">Update leaderboard:</Typography>
      <Button
        startIcon={<Sync fontSize="small" />}
        variant="contained"
        sx={{ mx: "1rem" }}
        size="small"
        onClick={handleSyncLeaderboard}
        disabled={syncState === "syncing"}
        color="primary"
      >
        UPDATE
      </Button>
      {syncState === "error" && (
        <>
          <Error color="error" />
          <Typography sx={{ ml: "0.5rem" }} color="error.main" variant="body2">
            HTTP Request unsuccessful.
            <br />
            <Typography variant="caption" color="error.light">
              {
                "The http request might have failed if the process takes long but the process will continue in the background. Check console for more info."
              }
            </Typography>
          </Typography>
        </>
      )}
      {syncState === "success" && (
        <>
          <CheckCircle color="success" />
          <Typography sx={{ ml: "0.5rem" }} color="success.main" variant="body2">
            Leaderboard calculation has been started!
          </Typography>
        </>
      )}
    </Box>
  );
};

export default LeaderboardSyncContainer;
