import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  Modal,
  Paper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUpsertFantasyResult } from "../../hooks";
import { Reorder } from "framer-motion";
import { useQueryClient } from "@tanstack/react-query";
import { FantasyResult } from "../../../../api/fantasy/schemas";
import { MatchByTournamentIdResponse } from "../../../../api/tournaments/schemas/matches";
import { DetailedPlayer } from "../../../../api/tournaments/schemas/players";
import { FantasyEvent } from "@/sanity/Fantasy";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 940,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface AddModalProps {
  fantasyEvent: FantasyEvent;
  players: DetailedPlayer[];
  editingMatchId?: string;
  results: FantasyResult[];
  matches: MatchByTournamentIdResponse[];
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

function not(a: readonly DetailedPlayer[], b: readonly DetailedPlayer[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly DetailedPlayer[], b: readonly DetailedPlayer[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const getOptionLabel = (match: MatchByTournamentIdResponse) =>
  `${match.matchName}: ${match.teams[0]?.team?.name ?? "?"} vs ${match.teams[1]?.team?.name ?? "?"}`;

const UpdateModal = ({
  fantasyEvent,
  players,
  editingMatchId,
  results,
  matches,
  open,
  onClose,
  onSuccess,
  isLoading,
}: AddModalProps) => {
  const [selectedMatch, setSelectedMatch] = useState<MatchByTournamentIdResponse | undefined>(undefined);
  const [winnerTeamId, setWinnerTeamId] = useState<string>("");
  const [matchInputValue, setMatchInputValue] = useState<string>("g2");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isWaiting, setIsWaiting] = useState(false);
  const { mutateAsync: mutateUpsert } = useUpsertFantasyResult();
  const [playersHaveBeenSet, setPlayersHaveBeenSet] = useState(false);
  const isValid = !!selectedMatch && !!winnerTeamId;
  const queryClient = useQueryClient();
  const isEditMode = !!editingMatchId;

  const [wasEliminated, setWasEliminated] = useState(false);
  const [checked, setChecked] = useState<DetailedPlayer[]>([]);
  const [left, setLeft] = useState<DetailedPlayer[]>([]);
  const [right, setRight] = useState<DetailedPlayer[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWasEliminated(event.target.checked);
  };

  const handleToggle = (value: DetailedPlayer) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const matchResults = useMemo(() => {
    return results.filter((result) => result.matchId === selectedMatch?.matchId);
  }, [results, selectedMatch]);

  const autoCompleteOptions = useMemo(() => {
    return matches ? Object.values(matches).sort((a, b) => a.matchName.localeCompare(b.matchName)) : [];
  }, [matches]);

  useEffect(() => {
    if (editingMatchId) {
      const match = matches.find((match) => match.matchId === editingMatchId);
      if (match) {
        setSelectedMatch(match);
        setMatchInputValue(getOptionLabel(match));
      }
    }
  }, [editingMatchId, matches]);

  const teamIds = useMemo(() => {
    if (selectedMatch) {
      return [selectedMatch.teams[0]?.team?.id, selectedMatch.teams[1]?.team?.id].filter((id) => id !== undefined);
    }
    return [];
  }, [selectedMatch]);

  const filteredPlayers = players.filter(
    (player) => player.team?.id && teamIds.includes(player.team.id) && player.role === "player",
  );

  useEffect(() => {
    if (!playersHaveBeenSet && filteredPlayers.length) {
      // get an array of filteredPlayers that are not in matchResults
      const left = filteredPlayers.filter((player) => !matchResults.find((result) => result.playerId === player.id));
      setLeft(left);

      const right = filteredPlayers.filter((player) => matchResults.find((result) => result.playerId === player.id));

      right.sort((a, b) => {
        const aRank = matchResults.find((result) => result.playerId === a.id)?.rank;
        const bRank = matchResults.find((result) => result.playerId === b.id)?.rank;
        if (aRank && bRank) {
          return aRank - bRank;
        }
        return 1;
      });

      const winnerPlayerId = matchResults.find((result) => result.won)?.playerId;

      if (winnerPlayerId) {
        const winnerPlayer = filteredPlayers.find((player) => player.id === winnerPlayerId);
        if (winnerPlayer) {
          setWinnerTeamId(winnerPlayer.team?.id || "");
        }
      }

      const eliminatedPlayer = matchResults.find((result) => result.eliminated);
      if (eliminatedPlayer) {
        setWasEliminated(true);
      }

      setRight(right);
      setPlayersHaveBeenSet(true);
    }
  }, [filteredPlayers, left, matchResults, selectedMatch, playersHaveBeenSet]);

  const handleSave = useCallback(async () => {
    if (!fantasyEvent.season.uid || !fantasyEvent.tournamentId || isWaiting || !selectedMatch) {
      return;
    }

    setIsWaiting(true);

    const requestBody = right.map((player, index) => {
      const rank = Number(index) + 1;

      return {
        matchId: selectedMatch.matchId,
        playerId: player.id,
        teamId: player.team?.id,
        playerNickname: player.nickname,
        won: player.team?.id === winnerTeamId,
        eliminated: player.team?.id !== winnerTeamId && wasEliminated,
        rank,
      };
    });

    await mutateUpsert(
      {
        seasonId: fantasyEvent.season.uid,
        eventId: fantasyEvent.eventId,
        matchId: selectedMatch.matchId,
        requestBody,
      },
      {
        onError: () => {
          setErrorMessage("Error saving results. Please check console.");
          setIsWaiting(false);
        },
        onSuccess: () => {
          void queryClient.invalidateQueries(["matchResults"]);
          onSuccess();
          onClose();
        },
      },
    );
  }, [
    queryClient,
    fantasyEvent,
    isWaiting,
    selectedMatch,
    winnerTeamId,
    right,
    mutateUpsert,
    onClose,
    onSuccess,
    wasEliminated,
    // queryClient,
  ]);

  return (
    <Modal
      open={open}
      onClose={isWaiting ? undefined : onClose}
      aria-labelledby="results-modal-title"
      aria-describedby="results-modal-description"
    >
      <Box sx={style}>
        <Typography id="rewards-modal-title" variant="h6" fontWeight="bold" marginBottom="1rem">
          Update Results
        </Typography>
        {!isEditMode && (
          <Box display="flex" alignItems="center" my="1rem">
            <Typography id="rewards-modal-title" fontWeight="bold" mr="1rem">
              Match ID:
            </Typography>
            <Autocomplete
              options={autoCompleteOptions}
              getOptionLabel={getOptionLabel}
              sx={{ width: 500 }}
              onChange={(_, match) => {
                setSelectedMatch(match ?? undefined);
                setLeft([]);
                setRight([]);
                setPlayersHaveBeenSet(false);
                setWasEliminated(false);
              }}
              isOptionEqualToValue={(option, value) => option.matchId === value.matchId}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Match"
                  value={matchInputValue}
                  onChange={(event) => setMatchInputValue(event.target.value)}
                />
              )}
              loading={!!matchInputValue && isLoading}
              disabled={isWaiting}
            />
          </Box>
        )}
        {!!selectedMatch && <Divider sx={{ my: "1rem" }} />}
        {!!selectedMatch && (
          <Typography variant="body1" fontWeight="bold" sx={{ my: "1rem" }} component="span">
            {selectedMatch.matchName}
          </Typography>
        )}
        {!!selectedMatch && (
          <Box>
            <Box display="flex" alignItems="center" sx={{ my: "1rem" }}>
              <Typography mr="1rem">Winner Team:</Typography>
              <ToggleButtonGroup
                size="small"
                value={winnerTeamId}
                exclusive
                onChange={(_, winnerTeam: string) => setWinnerTeamId(winnerTeam)}
                color="success"
                disabled={isWaiting}
              >
                <ToggleButton value={selectedMatch.teams[0]?.team?.id ?? "Loading..."} sx={{ px: "1rem" }}>
                  {selectedMatch.teams[0]?.team?.name}
                </ToggleButton>
                <ToggleButton value={selectedMatch.teams[1]?.team?.id ?? "Loading..."} sx={{ px: "1rem" }}>
                  {selectedMatch.teams[1]?.team?.name}
                </ToggleButton>
              </ToggleButtonGroup>
              <FormGroup>
                <FormControlLabel
                  sx={{ ml: "1rem" }}
                  control={<Switch checked={wasEliminated} onChange={handleChange} />}
                  label="Was loser eliminated?"
                />
              </FormGroup>
            </Box>
            <Grid container>
              <Grid item>
                <Paper sx={{ width: 400, height: 500, overflow: "auto" }}>
                  <List dense component="div" role="list">
                    {left.map((player: DetailedPlayer, index) => {
                      const labelId = `transfer-list-item-${player.id}-label`;

                      return (
                        <ListItem key={player.id} role="listitem" button onClick={handleToggle(player)}>
                          <Checkbox
                            size="small"
                            checked={checked.indexOf(player) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                          <Box display="flex" alignItems="center" height={54} key={index}>
                            <Box
                              key={`${index}`}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0.5rem",
                                border: "1px solid",
                                placeContent: "space-between",
                                backgroundColor: "background.paper",
                                borderColor: "primary.light",
                                borderRadius: "0.25rem",
                                marginBottom: "0.5rem",
                                cursor: "pointer",
                                width: 275,
                                "&:hover": {
                                  borderColor: "primary.main",
                                },
                              }}
                            >
                              {!!player && (
                                <img
                                  src={`https://assets.blast.tv/players/v1/${player.id}/large`}
                                  width={24}
                                  alt={player.nickname}
                                />
                              )}

                              <Typography fontSize="small" sx={{ ml: "1rem" }}>
                                {player?.nickname ?? "?"} -{" "}
                              </Typography>
                              <Typography fontSize="0.5rem">{player?.id ?? "?"}</Typography>
                              {!!player && (
                                <img
                                  src={`https://assets.blast.tv/teams/v1/${player.team?.id}/large`}
                                  width={15}
                                  alt={player.nickname}
                                />
                              )}
                            </Box>
                          </Box>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ m: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ m: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <Paper sx={{ width: 400, height: 500, overflow: "auto" }}>
                  <Reorder.Group axis="y" onReorder={setRight} values={right} as="ol">
                    {right.map((player, index) => {
                      const labelId = `transfer-list-item-${player.id}-label`;

                      return (
                        <Reorder.Item key={player.id} value={player} dragMomentum={false} dragElastic={0}>
                          <ListItem key={player.id} role="listitem" button onClick={handleToggle(player)}>
                            <Checkbox
                              size="small"
                              checked={checked.indexOf(player) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                            <Box display="flex" alignItems="center" height={54} key={index}>
                              <Box
                                key={`${index}`}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "0.5rem",
                                  border: "1px solid",
                                  backgroundColor: "background.paper",
                                  borderColor: "primary.light",
                                  borderRadius: "0.25rem",
                                  marginBottom: "0.5rem",
                                  cursor: "pointer",
                                  width: 275,
                                  "&:hover": {
                                    borderColor: "primary.main",
                                  },
                                }}
                              >
                                {!!player && (
                                  <img
                                    src={`https://assets.blast.tv/players/v1/${player.id}/large`}
                                    width={24}
                                    alt={player.nickname}
                                  />
                                )}
                                <Typography fontSize="small" sx={{ ml: "1rem" }}>
                                  {player?.nickname ?? "?"} -{" "}
                                </Typography>
                                <Typography fontSize="0.5rem">{player?.id ?? "?"}</Typography>
                              </Box>
                            </Box>
                          </ListItem>
                        </Reorder.Item>
                      );
                    })}
                  </Reorder.Group>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="2rem">
          {!!errorMessage && (
            <Typography color="error" variant="body2" component="span" marginRight="1rem">
              {errorMessage}
            </Typography>
          )}
          <Button variant="outlined" color="primary" sx={{ mr: "0.5rem" }} onClick={onClose} disabled={isWaiting}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => void handleSave()}
            disabled={isWaiting || !isValid}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateModal;
