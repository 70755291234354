import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchProduct } from "../../../../../../../../api/points";
import { PointsProduct } from "../../../../../../../../api/points/schemas";
import { createProductMethod } from "./helpers/createProductMethod";

type UploadProductMutationProps = PointsProduct & {
  image?: File;
};

interface useEditProductProps {
  onSuccess?: () => void;
  onError?: (e: unknown) => void;
}

export const useEditProduct = ({ onSuccess, onError }: useEditProductProps) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (props: UploadProductMutationProps) => {
      const propsWithoutImage = { ...props };
      const image = propsWithoutImage.image;
      if (!image) {
        await patchProduct(props);
        await queryClient.invalidateQueries(["products"]);
        await queryClient.refetchQueries(["products"]);
        return;
      }

      await createProductMethod(
        {
          ...propsWithoutImage,
          image,
        },
        false,
      );
      await queryClient.invalidateQueries(["products"]);
      await queryClient.refetchQueries(["products"]);
    },
    {
      onSuccess,
      onError,
    },
  );
};
