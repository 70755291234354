import { useMemo } from "react";
import { generateImageUrl } from "../../../../../../helpers/images/generateImageUrl";
import { generateAssetsId } from "../../../../../../helpers/images/generateImageUrl/generateAssetsId";
import { usePatron } from "../../../../../../providers/PatronProvider/hooks/usePatron";

interface CollectionImagePreviewProps {
  images: string[];
  type: string;
}

export function CollectionImagePreview({
  images,
  type,
}: CollectionImagePreviewProps) {
  const { patron } = usePatron();
  const items = useMemo(() => {
    if (type === "avatars") {
      const ava = images?.map((avatar) =>
        generateImageUrl("avatars", generateAssetsId(avatar, "3d"), {
          width: "50",
          format: "auto",
        })
      );
      return ava;
    } else {
      const emo = images?.map((emoji) =>
        generateImageUrl("emojis", emoji, {
          width: "50",
          format: "auto",
          patron,
        })
      );
      return emo;
    }
  }, [images, patron, type]);
  return (
    <div className="flex">
      {items.map((img) => (
        <img
          key={img}
          src={img}
          alt="Collection"
          className="w-7 h-7 object-cover rounded-md"
        />
      ))}
    </div>
  );
}
