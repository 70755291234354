import { useMutation } from "@tanstack/react-query";

import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";

import { restoreMessage } from "../../../../../api/chat";

const useRestoreMessage = (uuid: string) => {
  const { showFailureAlert } = useAlert();

  return useMutation({
    mutationKey: ["restoreMessage", uuid],
    mutationFn: () => restoreMessage({ messageId: uuid }),
    onError() {
      showFailureAlert("Error while restoring deleted message");
    },
  });
};

export default useRestoreMessage;
