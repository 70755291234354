import { useMutation, useQueryClient } from "@tanstack/react-query";

interface useCreateCircuitProps {
  submit: () => Promise<void>;
  onSuccess?: () => void;
  onError?: (e: unknown) => void;
  type: "tournaments" | "circuits" | "stage";
}

export const usePostPatchTO = ({ submit, onSuccess, onError, type }: useCreateCircuitProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await submit();
      await queryClient.invalidateQueries([type]);
      await queryClient.refetchQueries([type]);
    },
    onSuccess,
    onError,
  });
};
