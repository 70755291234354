import { useMutation } from "@tanstack/react-query";
import { broadcastGoOffline } from "../../../../../api/broadcasts";
import { Patron } from "../../../../../providers/PatronProvider/types";

const useGoOffline = ({ patron, id }: { patron: Patron; id: string }) => {
  const result = useMutation({
    mutationKey: ["goOffline", patron, id],
    mutationFn: () => broadcastGoOffline({ patron, id }),
  });

  return result;
};

export default useGoOffline;
