import { useQuery } from "@tanstack/react-query";
import { getStreams } from "../../../../api/streams";

const useFetchAwsStreams = () =>
  useQuery({
    queryKey: ["streams"],
    queryFn: () => getStreams(),
    retry: 3,
  });

export default useFetchAwsStreams;
