import styled, { css, keyframes } from "styled-components/macro";

const riseAnimation = keyframes`
	from {
		opacity: 0;
		transform: translateY(0) scale(1);
	}
	25% {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: translateY(-10em) scale(0);
	}
`;

export const FireButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const Fire = styled.div`
  font-size: 4px;
  filter: blur($blur);
  -webkit-filter: blur($blur);
  margin: 3em auto 0 auto;
  width: 10em;
  height: 12em;
  position: absolute;
  top: -2rem;
  right: 0.75rem;
  opacity: 0.75;
`;

export const Particle = styled.div<{ randomNumber: number }>`
  animation: ${riseAnimation} 1s ease-in infinite;
  background-image: radial-gradient(
    rgb(255, 80, 0) 20%,
    rgba(255, 80, 0, 0) 70%
  );
  border-radius: 50%;
  mix-blend-mode: screen;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 3rem;
  height: 1.5rem;
  // spread particles out in time and x-position to get desired effect
  ${() =>
    Array(50)
      .fill(0)
      .map(
        (_, index) =>
          css`
            &:nth-of-type(${index + 1}) {
              animation-delay: ${index * 0.05}s;
              left: calc((100% - 3rem) * ${index / 50});
            }
          `
      )}
`;
