import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ClipCuratorEventWithMatchDetails } from "../../../api/video/schemas";
import { Card } from "@mui/material";
import { DeleteOutline, DragIndicatorOutlined } from "@mui/icons-material";
import { assetsURL } from "../../../config";
import { events } from "../../Broadcasts/TimelineEvents/data/events";

const SortableItem = ({
  id,
  selectedClip,
  removeClip,
}: {
  id: string;
  selectedClip: ClipCuratorEventWithMatchDetails;
  removeClip: (id: string) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div className="flex">
        <Card key={`selected-clip-${id}`} variant="elevation" className="flex w-full flex-col gap-2 p-2" {...listeners}>
          <div className="flex flex-row items-center gap-2">
            <DragIndicatorOutlined />
            <img height={20} width={20} src={`${assetsURL}/images/teams/${selectedClip.teamIds[0]}`} />
            <p>vs</p>
            <img height={20} width={20} src={`${assetsURL}/images/teams/${selectedClip.teamIds[1]}`} />
            <p>
              {selectedClip.tournamentName} : {selectedClip.matchName} : ({selectedClip.mapNumber}){" "}
              {selectedClip.mapName} : R{selectedClip.roundNumber}
            </p>
          </div>
          <div className="flex flex-row gap-2">
            <p>{events.find((e) => e.id === selectedClip.eventType)?.name}</p>
            {selectedClip.playerName && <p>- {selectedClip.playerName}</p>}
          </div>
        </Card>
        <div className="flex items-center bg-slate-800">
          <button onClick={() => removeClip(id)}>
            <DeleteOutline />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SortableItem;
