import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Tab,
  TextField,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { Link } from "react-router-dom";
import { useGetBroadcastAds } from "./hooks/useGetBroadcastAds";
import { useCreateBroadcastAd } from "./hooks/useCreateBroadcastAd";
import useAlert from "../../providers/AlertProvider/hooks/useAlert";

import { useRunBroadcastAd } from "./hooks/useRunBroadcastAd";

export const BroadcastAds = () => {
  const [selectedTab, setSelectedTab] = useState("1");
  const [formAdId, setFormAdId] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formUrl, setFormUrl] = useState("");
  const [runFormAdId, setRunFormAdId] = useState("");
  const [runFormFormat, setRunFormFormat] = useState("");
  const [runFormDuration, setRunFormDuration] = useState(0);
  const adFormats = ["full", "u-bracket"];
  const alert = useAlert();
  const { data: broadcastAds, isFetching, isError } = useGetBroadcastAds();
  const { mutate: createBroadcastAd, isLoading: creatingBroadcastAd } =
    useCreateBroadcastAd({
      onSuccess: () => {
        alert.showSuccessAlert("Broadcast Ad created successfully");
      },
      onError: () => {
        alert.showFailureAlert("Failed to create Broadcast Ad");
      },
    });

  const { mutate: runBroadcastAd, isLoading: runningBroadcastAd } =
    useRunBroadcastAd({
      onSuccess: () => {
        alert.showSuccessAlert("Broadcast Ad created successfully");
      },
      onError: () => {
        alert.showFailureAlert("Failed to create Broadcast Ad");
      },
    });

  const handleChange = (_: SyntheticEvent, newTab: string) => {
    setSelectedTab(newTab);
  };

  const handleSubmit = () => {
    createBroadcastAd({
      adId: formAdId,
      title: formTitle,
      url: formUrl,
    });
    setFormAdId("");
    setFormTitle("");
    setFormUrl("");
  };

  const handleRun = () => {
    runBroadcastAd({
      adId: runFormAdId,
      format: runFormFormat,
      durationSeconds: runFormDuration,
    });
    setRunFormAdId("");
    setRunFormFormat("undefined");
    setRunFormDuration(0);
  };

  const generateAdId = () => {
    const adId = crypto.randomUUID();
    setFormAdId(adId);
  };

  return (
    <div className="flex flex-col w-full">
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ margin: "2rem 1rem 1rem 2rem" }}
      >
        <Link color="inherit" to="/">
          Home
        </Link>
        <span> Broadcast Ads </span>
      </Breadcrumbs>

      <Box sx={{ width: "100%", typography: "body1", px: 4, py: 4 }}>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="LIST" value="1" />
              <Tab label="CREATE" value="2" />
              <Tab label="RUN" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {isError && <div>Failed to fetch broadcast ads</div>}
            {isFetching && <CircularProgress sx={{ m: "2rem" }} />}
            {!isFetching &&
              broadcastAds?.map((broadcastAd) => {
                return (
                  <div key={broadcastAd.adId}>
                    {broadcastAd.adId} - {broadcastAd.title}
                  </div>
                );
              })}
          </TabPanel>
          <TabPanel value="2">
            {/* Form to create a broadcast, 3 inputs: id, title, URL */}
            <div className="flex flex-col gap-4 max-w-md">
              <div className="flex items-center">
                <TextField
                  label="ID"
                  variant="outlined"
                  value={formAdId}
                  className="max-w-xs grow"
                  onChange={(e) => setFormAdId(e.target.value)}
                  disabled={creatingBroadcastAd}
                />
                <label
                  className="ml-4 text-blue-600 text-xs cursor-pointer hover:text-blue-500"
                  onClick={() => generateAdId()}
                >
                  GENERATE
                </label>
              </div>
              <TextField
                label="Title"
                variant="outlined"
                value={formTitle}
                className="max-w-xs"
                onChange={(e) => setFormTitle(e.target.value)}
                disabled={creatingBroadcastAd}
              />
              <TextField
                label="URL"
                variant="outlined"
                value={formUrl}
                onChange={(e) => setFormUrl(e.target.value)}
                disabled={creatingBroadcastAd}
              />
              <Button
                onClick={() => handleSubmit()}
                type="button"
                color="primary"
                variant="contained"
                className="w-fit"
                disabled={creatingBroadcastAd}
              >
                Create Ad
              </Button>
            </div>
          </TabPanel>
          <TabPanel value="3">
            {broadcastAds?.length ? (
              <div className="flex flex-col gap-6">
                <div className="flex gap-4 items-center">
                  <label>Select AD</label>
                  <Select
                    variant="filled"
                    className="w-48 h-10"
                    onChange={(e) => {
                      if (!e.target.value) return;
                      setRunFormAdId(e.target.value as string);
                    }}
                    disabled={runningBroadcastAd}
                  >
                    {broadcastAds.map((broadcast) => (
                      <MenuItem
                        key={broadcast.adId}
                        value={broadcast.adId}
                        className="flex justify-between"
                      >
                        {broadcast.adId}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="flex gap-4 items-center">
                  <label>Select Format</label>
                  <Select
                    variant="filled"
                    className="w-48 h-10"
                    onChange={(e) => {
                      if (!e.target.value) return;
                      setRunFormFormat(e.target.value as string);
                    }}
                    disabled={runningBroadcastAd}
                  >
                    {adFormats.map((format) => (
                      <MenuItem
                        key={format}
                        value={format}
                        className="flex justify-between uppercase"
                      >
                        {format}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <TextField
                  label="Select Duration"
                  variant="outlined"
                  value={runFormDuration}
                  type="number"
                  className="max-w-xs"
                  onChange={(e) => setRunFormDuration(Number(e.target.value))}
                  disabled={runningBroadcastAd}
                />
                <Button
                  onClick={() => handleRun()}
                  type="button"
                  color="primary"
                  variant="contained"
                  className="w-fit"
                  disabled={runningBroadcastAd}
                >
                  Run Ad
                </Button>
              </div>
            ) : (
              <label>No broadcast ads found, no ad can be run</label>
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};
