import { useQueries } from "@tanstack/react-query";
import { getPlayerByIngameId } from "../../../api/tournaments";

const useFetchPlayersByIngameId = ({ playerIngameIds }: { playerIngameIds: string[] }) => {
  return useQueries({
    queries: playerIngameIds.map((playerIngameId) => {
      return {
        queryKey: ["live stats player by ingame id", playerIngameId],
        queryFn: () => getPlayerByIngameId(playerIngameId),
      };
    }),
  });
};

export default useFetchPlayersByIngameId;
