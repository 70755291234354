import { useMutation } from "@tanstack/react-query";
import { deleteTeam } from "../../../../api/tournaments";

export default function useDeleteTeam({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) {
  return useMutation({
    mutationKey: ["team"],
    mutationFn: ({ teamId }: { teamId: string }) => deleteTeam({ teamId }),
    onSuccess,
    onError,
  });
}
