import { z } from "zod";
import { DateStringSchema } from "./dateString";

export const StageFormatSchema = z.enum([
  "double-elim-32",
  "single-elim-8",
  "showmatch",
  "single-elim-4",
]);
export type StageFormat = z.infer<typeof StageFormatSchema>;

export const DbStageSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: DateStringSchema,
  endDate: DateStringSchema.nullish(),
  format: StageFormatSchema,
  index: z.coerce.number().int(),
  tournamentId: z.string(),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
});

export type DbStage = z.infer<typeof DbStageSchema>;

export const CreateStageOptionsSchema = DbStageSchema.pick({
  name: true,
  startDate: true,
  endDate: true,
  format: true,
  index: true,
  tournamentId: true,
});

export const StageCreateRequestSchema = CreateStageOptionsSchema;

export type StageCreateRequest = z.infer<typeof StageCreateRequestSchema>;

export const StageResponseSchema = DbStageSchema.omit({ deletedAt: true });

export const UpdateStageRequestSchema = DbStageSchema.pick({
  name: true,
  startDate: true,
  endDate: true,
  format: true,
}).partial();

export type StageUpdateRequest = z.infer<typeof UpdateStageRequestSchema>;
