import { useQuery } from "@tanstack/react-query";
import { getEventTranscript } from "../api/video";

export const useFetchEventTranscript = (eventId?: string) => {
  return useQuery({
    queryKey: ["transcript", eventId],
    queryFn: eventId ? () => getEventTranscript({ eventId }) : undefined,
    enabled: !!eventId,
  });
};
