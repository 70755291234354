import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";
import { getDotlePlayers } from ".";

export const useGetDotlePlayers = () => {
  return useQuery({
    queryKey: queryKeys.players(),
    queryFn: getDotlePlayers,
  });
};
