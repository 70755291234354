import { z } from "zod";

export const ModerationRuleActionTypeSchema = z.enum([
  "DELETE_MESSAGE",
  "SUSPEND_2_MIN",
  "SUSPEND_10_MIN",
  "SUSPEND_1_HOUR",
  "SUSPEND_1_DAY",
  "HIGHLIGHT_FOR_MODERATOR",
]);

export type ModerationRuleActionType = z.infer<
  typeof ModerationRuleActionTypeSchema
>;

export const ModerationRuleActionSchema = z.object({
  type: ModerationRuleActionTypeSchema,
  /// ... other fields might be added later
});

export const ModerationRuleSchema = z.object({
  id: z.string().nonempty(),
  description: z.string().optional(),
  toxic: z.number().min(0).max(1).optional(),
  derogatory: z.number().min(0).max(1).optional(),
  violent: z.number().min(0).max(1).optional(),
  sexual: z.number().min(0).max(1).optional(),
  insult: z.number().min(0).max(1).optional(),
  profanity: z.number().min(0).max(1).optional(),
  deathHarmAndTragedy: z.number().min(0).max(1).optional(),
  firearmsAndWeapons: z.number().min(0).max(1).optional(),
  publicSafety: z.number().min(0).max(1).optional(),
  health: z.number().min(0).max(1).optional(),
  religionAndBelief: z.number().min(0).max(1).optional(),
  illicitDrugs: z.number().min(0).max(1).optional(),
  warAndConflict: z.number().min(0).max(1).optional(),
  finance: z.number().min(0).max(1).optional(),
  politics: z.number().min(0).max(1).optional(),
  legal: z.number().min(0).max(1).optional(),
  action: ModerationRuleActionSchema,
});

export type ModerationRule = z.infer<typeof ModerationRuleSchema>;
