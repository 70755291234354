import axios from "axios";

import { baseApiURL } from "../../config";

import authInterceptor from "../authInterceptor";

import { ModerationRule, ModerationRuleSchema } from "../../types/Moderation";

const moderationApi = axios.create({
  baseURL: `${baseApiURL}/v1/moderation`,
});
moderationApi.interceptors.request.use(authInterceptor);

export const getRules = async () => {
  const { data } = await moderationApi.get<unknown>("/rules");

  return ModerationRuleSchema.array().parse(data);
};

export const updateRules = async (rules: Array<ModerationRule>) => {
  const { data } = await moderationApi.put<unknown>("/rules", rules);

  return ModerationRuleSchema.array().parse(data);
};
