import { OpenInNew } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Issue } from "../../../../../api/data/issues/shared/schemas";
import { z } from "zod";

/**
 * A link button that links to the tournament details page
 */
export const IssueTournamentLinkButton = ({ tournamentId, body }: Issue) => {
  const { title, href } = useMemo((): { title: string; href: string | null } => {
    const bodyParsed = z
      .object({
        tournamentMatch: z.object({ tournament: z.object({ id: z.string(), name: z.string() }) }),
      })
      .safeParse(body && typeof body === "string" ? JSON.parse(body) : body);

    let title: string = tournamentId;
    if (
      bodyParsed.data?.tournamentMatch.tournament.name && // Has data in body
      bodyParsed.data?.tournamentMatch.tournament.id === tournamentId // Tournament id matches the issue tournament id
    ) {
      title = bodyParsed.data.tournamentMatch.tournament.name;
    }

    return {
      title,
      href: `/to/tournaments/${tournamentId}`,
    };
  }, [tournamentId, body]);

  if (!href) return <>{title}</>;

  return (
    <Button
      component={Link}
      to={href}
      target="_blank"
      size="small"
      color="inherit"
      endIcon={<OpenInNew fontSize="small" />}
      className="normal-case"
    >
      {title}
    </Button>
  );
};
