import axios from "axios";
import { baseApiURL } from "../../config";
import { FantasyResult } from "./schemas";
import authInterceptor from "../authInterceptor";

const fantasyApi = axios.create({
  baseURL: `${baseApiURL}/v1`,
});
fantasyApi.interceptors.request.use(authInterceptor);

export const getMatchResults = async ({
  seasonId,
  eventId,
}: {
  seasonId: string;
  eventId: string;
}): Promise<FantasyResult[]> => {
  const { data } = await fantasyApi.get<FantasyResult[]>(
    `/fantasy/seasons/${seasonId}/events/${eventId}/results/matches`,
  );
  return data;
};

export const putResultsForMatch = async (data: {
  seasonId: string;
  eventId: string;
  matchId: string;
  requestBody: FantasyResult[];
}): Promise<void> => {
  const { seasonId, eventId, matchId, requestBody } = data;
  return fantasyApi.put(`/fantasy/seasons/${seasonId}/events/${eventId}/results/matches/${matchId}`, requestBody);
};

export const deleteResultsForMatch = async (data: {
  seasonId: string;
  eventId: string;
  matchId: string;
}): Promise<void> => {
  const { seasonId, eventId, matchId } = data;
  return fantasyApi.delete(`/fantasy/seasons/${seasonId}/events/${eventId}/results/matches/${matchId}`);
};

export const calculateLeaderboards = async ({
  seasonId,
  eventId,
  calculationSchemaId,
}: {
  seasonId: string;
  eventId: string;
  calculationSchemaId: string;
}): Promise<void> => {
  return fantasyApi.post(`/fantasy/seasons/${seasonId}/events/${eventId}/leaderboards/calculate`, {
    calculationSchemaId,
  });
};
