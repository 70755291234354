import { invalidateMetadataFile, updateAvatar } from "../../../../api/assets";
import { Avatar } from "../../../../types/Avatar";
import { Patron } from "../../../../providers/PatronProvider/types";

interface UpdateAvatarProps {
  avatarId: string;
  avatarData: Partial<Avatar>;
  sendSuccessMessage: (message: string) => void;
  sendErrorMessage: (message: string) => void;
  patron?: Patron;
}

export const editAvatar = async ({
  avatarId,
  avatarData,
  sendSuccessMessage,
  sendErrorMessage,
  patron,
}: UpdateAvatarProps) => {
  try {
    await updateAvatar({
      avatarId: avatarId,
      avatarMetadata: avatarData,
      patron,
    });
  } catch (error) {
    sendErrorMessage("Failed to update avatar metadata.");
    throw error;
  }

  try {
    await invalidateMetadataFile("avatars", patron);
  } catch (error) {
    sendErrorMessage("Failed to invalidate metadata file cache.");
    throw error;
  }

  sendSuccessMessage("Updated avatar metadata.");
};
