import { AwardResponse as Award } from "../../../../api/tournaments/schemas/awards";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { cloneDeep, isEqual } from "lodash-es";
import { useEffect, useState } from "react";
import { Team } from "../../../../api/tournaments/schemas/teams";
import { Player } from "../../../../api/tournaments/schemas/players";
import { Tournament } from "../../../../api/tournaments/schemas/tournaments";
import useUpdateAward from "../../hooks/awards/useUpdateAward";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ConfirmDeleteAward } from "./ConfirmDeleteAward";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface AwardCardProps {
  inputAward: Award;
  teams: Team[];
  players: Player[];
  tournaments: Tournament[];
}

export const AwardCard = ({ inputAward, teams, players, tournaments }: AwardCardProps) => {
  const alert = useAlert();
  const [award, setAward] = useState<Award>(cloneDeep(inputAward));
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [hasAwardChanged, setHasPlayerChanged] = useState<boolean>(false);

  const { mutate: updateAward } = useUpdateAward({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated award");
    },
  });

  useEffect(() => {
    if (!isEqual(inputAward, award)) {
      setHasPlayerChanged(true);
    } else {
      setHasPlayerChanged(false);
    }
  }, [inputAward, award]);

  const changeProperty = ({
    property,
    value,
  }: {
    property: keyof Pick<Award, "name" | "dateAwarded" | "type" | "tournament" | "player" | "team">;
    value: string | Date | { id: string; name: string } | { id: string; nickname: string } | null;
  }) => {
    setAward((prev) => {
      return {
        ...prev,
        [property]: value,
      };
    });
  };
  const playerOptions: { id: string; nickname: string }[] = [{ id: "null", nickname: "No Player" }].concat(
    players.map((player) => ({ id: player.id, nickname: player.nickname })),
  );
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Card sx={{ width: 450 }}>
          <CardContent>
            <Stack direction="column" justifyContent={"space-between"}>
              <Stack direction="row" alignContent={"center"}>
                <Box>
                  <Typography variant="h6" sx={{ mb: -1 }}>
                    {award.name}
                  </Typography>
                </Box>
              </Stack>
              <Divider sx={{ my: 1 }} />
              <Stack direction="row" alignContent={"center"}>
                <Grid container spacing={1}>
                  <Grid item sm={15}>
                    <TextField
                      variant="outlined"
                      size="medium"
                      label="Name"
                      defaultValue={award.name}
                      onChange={(e) =>
                        changeProperty({
                          property: "name",
                          value: e.target.value,
                        })
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={15}>
                    <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                      <InputLabel>Tournament</InputLabel>
                      <Select
                        value={award.tournament?.id || "null"}
                        onChange={(e) => {
                          const tournament = tournaments.find((tournament) => tournament.id === e.target.value);
                          const value = tournament ? { id: tournament.id, name: tournament.name } : null;
                          changeProperty({
                            property: "tournament",
                            value: value,
                          });
                        }}
                      >
                        <MenuItem value="null">No Tournament</MenuItem>
                        <Divider />
                        {tournaments.map((tournament) => (
                          <MenuItem key={tournament.id} value={tournament.id}>
                            {tournament.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                      <InputLabel>Team</InputLabel>
                      <Select
                        value={award.team?.id || "null"}
                        onChange={(e) => {
                          const team = teams.find((team) => team.id === e.target.value);
                          const value = team ? { id: team.id, name: team.name } : null;
                          changeProperty({
                            property: "team",
                            value: value,
                          });
                        }}
                      >
                        <MenuItem value="null">No Team</MenuItem>
                        <Divider />
                        {teams.map((team) => (
                          <MenuItem key={team.id} value={team.id}>
                            {team.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6}>
                    <Autocomplete
                      id="select-player"
                      options={playerOptions || []}
                      getOptionLabel={(option) => option.nickname}
                      onChange={(_, newValue: { id: string; nickname: string } | null) => {
                        changeProperty({
                          property: "player",
                          value: newValue ? { id: newValue.id, nickname: newValue.nickname } : null,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={award.player ? award.player.nickname : "No Player"}
                          placeholder="Select / Search Player"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.nickname}
                        </li>
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={award.type}
                        onChange={(e) =>
                          changeProperty({
                            property: "type",
                            value: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="tournament">tournament</MenuItem>
                        <MenuItem value="mvp">mvp</MenuItem>
                        <MenuItem value="misc">misc</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                      <DatePicker
                        label="Date Awarded"
                        format="dd/LL/yyyy"
                        value={
                          award.dateAwarded ? DateTime.fromJSDate(award.dateAwarded) : DateTime.fromJSDate(new Date())
                        }
                        onChange={(date) =>
                          changeProperty({
                            property: "dateAwarded",
                            value: date?.toJSDate() || null,
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Button className="mt-2" disabled={!hasAwardChanged} onClick={() => updateAward({ award })}>
                    Save Changes
                  </Button>
                  <Button
                    variant="contained"
                    className="mt-2 bg-red-500 hover:bg-red-300"
                    onClick={() => setDeleteConfirm(true)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </LocalizationProvider>
      <ConfirmDeleteAward isOpen={deleteConfirm} close={() => setDeleteConfirm(false)} awardId={award.id} />
    </>
  );
};
