import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Event } from "../../../../../api/metricsViewership/schemas/EventSchema";
import { useGetChannelsList } from "../../../../../api/metricsViewership/hooks/useGetChannelsList";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { useEffect, useMemo, useState } from "react";
import { useUpdateEvent } from "../../../../../api/metricsViewership/hooks/useUpdateEvent";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { PlatformIcon } from "@/routes/MetricsViewership/routes/shared/components/PlatformIcon";

export const LinkChannelModal = ({ event }: { event: Event }) => {
  const { data: allChannels } = useGetChannelsList();

  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([]);
  const { mutate: updateEvent, isLoading: isSubmitting } = useUpdateEvent(event.id);

  const saveAndClose = () => {
    const channels = [
      ...event.channels,
      ...selectedChannelIds.map((id) => ({ id, isPrimary: false, excluded: false })),
    ];
    updateEvent({
      // TODO: primary channel should be selected
      channels,
    });
    setOpen(false);
  };

  const filteredAndSortedChannels = useMemo(() => {
    if (!allChannels || !event) {
      return [];
    }
    // filter based on search query
    const filtered = allChannels.filter((channel) => channel.channelName.match(new RegExp(`${searchQuery}`, "i")));

    // sort linked channels first
    return filtered.sort((a, b) => {
      if (event.channels.some((c) => c.id === a.id) && !event.channels.some((c) => c.id === b.id)) {
        return -1;
      }
      if (!event.channels.some((c) => c.id === a.id) && event.channels.some((c) => c.id === b.id)) {
        return 1;
      }
      return 0;
    });
  }, [allChannels, event, searchQuery]);

  useEffect(() => {
    // reset on close
    if (!open) {
      setSelectedChannelIds([]);
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant="outline" className="w-full">
          Link Channel
        </Button>
      </DialogTrigger>

      <DialogContent className="w-full max-w-4xl">
        <DialogHeader>
          <DialogTitle>Linking channels to {event?.name}</DialogTitle>
          <DialogDescription>
            Link channels to the event to track viewership data. Viewership data is tracked on a minute by minute basis.
          </DialogDescription>
        </DialogHeader>

        <Label htmlFor="filter">Filter</Label>
        <Input id="filter" className="w-96" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

        <div className="h-[50vh] w-full overflow-y-auto">
          <Table>
            <TableBody>
              {filteredAndSortedChannels.map((channel, i) => {
                const channelIsLinked = event.channels.some((c) => c.id === channel.id);
                const channelIsSelected = selectedChannelIds.includes(channel.id);

                return (
                  <TableRow key={i}>
                    <TableCell title={channel.channelSource}>
                      <PlatformIcon platform={channel.channelSource} />
                    </TableCell>
                    <TableCell>{channel.channelName}</TableCell>
                    <TableCell>
                      {channel.streams?.map((stream, i) => (
                        <div key={i}>
                          {i + 1}: {stream.title}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell className="text-right">
                      <Checkbox
                        disabled={channelIsLinked}
                        checked={channelIsSelected || channelIsLinked}
                        onClick={() => {
                          if (channelIsSelected) {
                            setSelectedChannelIds(selectedChannelIds.filter((id) => id !== channel.id));
                          } else {
                            setSelectedChannelIds([...selectedChannelIds, channel.id]);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className="flex w-full items-center gap-2">
          <div className="mr-auto">Linking {selectedChannelIds.length} channels to the event</div>
        </div>

        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false);
            }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button onClick={saveAndClose} disabled={selectedChannelIds.length === 0}>
            Link
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
