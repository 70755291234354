import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTeamGroup } from "..";
import { rlcsStatQueryKeys } from "../../rlcsStatQueryKeys";

export function useDeleteTeamGroup() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTeamGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rlcsStatQueryKeys.teams,
      });
    },
  });
}
