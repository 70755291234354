import { useQuery } from "@tanstack/react-query";
import { getUsersByUsername } from "../api/users";

export default function useUsernameSearch({
  beginsWith,
}: {
  beginsWith: string;
}) {
  const enabled = !!beginsWith && beginsWith.length > 1;

  return useQuery({
    queryKey: ["users", "search", beginsWith],
    queryFn: () => (enabled ? getUsersByUsername({ beginsWith }) : []),
    enabled: enabled,
  });
}
