import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { LinkAccordion } from "./components/LinkAccordion";

interface DataUrlAccordionProps {
  matchId: string;
  matchType: string;
}

export const DataUrlAccordion = ({
  matchId,
  matchType,
}: DataUrlAccordionProps) => {
  let mapsNumber: number = 0;
  switch (matchType) {
    case "BO1":
      mapsNumber = 1;
      break;
    case "BO3":
      mapsNumber = 3;
      break;
    case "BO5":
      mapsNumber = 5;
      break;
    case "BO7":
      mapsNumber = 7;
      break;
    default:
      break;
  }

  return (
    <Accordion className="my-2 px-2 w-full mx-0 max-w-[1500px]">
      <AccordionSummary className="h-9">
        Data URLs (Timeline & Statistics)
      </AccordionSummary>
      <AccordionDetails className="">
        <LinkAccordion
          type="timeline"
          matchId={matchId}
          numberOfMaps={mapsNumber}
        />
        <LinkAccordion
          type="statistics"
          matchId={matchId}
          numberOfMaps={mapsNumber}
        />
      </AccordionDetails>
    </Accordion>
  );
};
