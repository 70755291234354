import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";

import { Collection, CollectionsResponseSchema } from "./types/Collection";
import {
  GetPurchasesOptions,
  GetPurchasesResponse,
  GrantPointsData,
  GrantPointsResponseSchema,
  PointsProduct,
  PointsProductResponseSchema,
  PurchaseSchema,
} from "./schemas";

const apiClient = axios.create({
  baseURL: baseApiURL,
});
apiClient.interceptors.request.use(authInterceptor);

export const postProduct = async (data: PointsProduct) => {
  await apiClient.post("/v1/points/products/", data);
};

export const patchProduct = async (data: PointsProduct) => {
  await apiClient.patch(`/v1/points/products/${data.id}`, data);
};

export const getProducts = async () => {
  const products = await apiClient.get("/v1/points/products/?filter=all");

  const data = PointsProductResponseSchema.parse(products.data);

  return data;
};

export const getCollections = async () => {
  const collections = await apiClient.get("/v1/points/collections/blast");

  const data = CollectionsResponseSchema.parse(collections.data);

  return data;
};

export const getHighEndPurchases = async (delivered: boolean) => {
  const products = await apiClient.get(`/v1/points/purchases/high-end-purchases?delivered=${delivered}`);

  const data = GetPurchasesResponse.parse(products.data);

  return data;
};

export const postCollection = async (data: Collection) => {
  await apiClient.post(`/v1/points/collections/blast`, data);
};

export const patchCollection = async (type: Collection["type"], id: Collection["collectionId"], data: Collection) => {
  await apiClient.patch(`/v1/points/collections/blast/${type}/${id}`, data);
};

export const getPurchases = async (options: GetPurchasesOptions) => {
  const { data } = await apiClient.get<unknown>("/v1/points/purchases", {
    params: options,
  });

  return GetPurchasesResponse.parse(data);
};

export const postAssignReward = async ({ productId, userId }: { productId: string; userId: string }) => {
  const { data } = await apiClient.post<unknown>(`/v1/points/products/${productId}/users/${userId}/assign-reward`);

  return PurchaseSchema.parse(data);
};

export const approvePurchase = async (id: string) => {
  await apiClient.post(`/v1/points/purchases/${id}/approve`, {});
};

export const rejectPurchase = async (id: string, { refund }: { refund?: boolean }) => {
  await apiClient.post(`/v1/points/purchases/${id}/reject`, { refund });
};

export const postChangePurchaseDeliveredStatus = async ({ id, delivered }: { id: string; delivered: boolean }) => {
  await apiClient.post(`/v1/points/purchases/${id}/delivery`, { delivered });
};

export const grantPoints = async (data: GrantPointsData) => {
  const { data: response } = await apiClient.post<unknown>("/v1/points/grant", data);

  return GrantPointsResponseSchema.parse(response);
};
