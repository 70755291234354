import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import { generateAssetsId } from "../../../../../helpers/images/generateImageUrl/generateAssetsId";
import { Avatar } from "../../../../../types/Avatar";
import { ImageUploadForm } from "../../../shared/components/ImageUploadForm";
import { AvatarDetailsFields } from "../AvatarDetailsFields";
import { usePatron } from "../../../../../providers/PatronProvider/hooks/usePatron";

interface EditAvatarModalProps {
  open: boolean;
  isLoading: boolean;
  avatar: Avatar;
  onClose: () => void;
  onDelete: (avatarId: string) => void;
  onEdit: ({
    avatar,
    smallImage,
    largeImage,
  }: {
    avatar: Avatar;
    smallImage: File | null;
    largeImage: File | null;
  }) => void;
}

export const EditAvatarModal = ({
  open,
  isLoading,
  avatar,
  onClose,
  onDelete,
  onEdit,
}: EditAvatarModalProps) => {
  const [smallImage, setSmallImage] = useState<File | null>(null);
  const [largeImage, setLargeImage] = useState<File | null>(null);
  const [updatedAvatar, setUpdatedAvatar] = useState<Avatar | null>(null);
  const { patron } = usePatron();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create Avatar</DialogTitle>
      <Divider />
      <DialogContent>
        <AvatarDetailsFields
          avatarId={avatar.id}
          avatar={avatar}
          updateAvatar={(avatar) => setUpdatedAvatar(avatar)}
        />
        <Divider />
        <Grid container spacing={4}>
          <Grid item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h3>2D Avatar:</h3>
              <img
                src={generateImageUrl(
                  "avatars",
                  generateAssetsId(avatar.id, "2d"),
                  {
                    width: "80",
                    height: "80",
                    format: "auto",
                    patron,
                  }
                )}
                alt={avatar.id}
                height={40}
                width={40}
                style={{ marginLeft: "15px" }}
              />
            </div>

            <ImageUploadForm image={smallImage} setImage={setSmallImage} />
          </Grid>
          <Grid item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h3>3D Avatar:</h3>
              <img
                src={generateImageUrl(
                  "avatars",
                  generateAssetsId(avatar.id, "3d"),
                  {
                    width: "80",
                    height: "80",
                    format: "auto",
                    patron,
                  }
                )}
                alt={avatar.id}
                height={40}
                width={40}
                style={{ marginLeft: "15px" }}
              />
            </div>
            <ImageUploadForm image={largeImage} setImage={setLargeImage} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          style={{ marginRight: "auto" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => onDelete(avatar.id)}
          style={{ marginRight: "30px" }}
        >
          Delete Avatar
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={isLoading}
          onClick={() => {
            if (updatedAvatar === null) return;
            onEdit({ avatar: updatedAvatar, smallImage, largeImage });
          }}
        >
          Update Avatar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
