import { useQuery } from "@tanstack/react-query";
import { getSanityFantasyEvents } from ".";
import { queryKeys } from "./shared/queryKeys";

export default function useFetchSanityFantasyEvents() {
  return useQuery({
    queryKey: queryKeys.events(),
    queryFn: getSanityFantasyEvents,
    select: (data) =>
      data.sort((a, b) => {
        if (a.endTime && b.endTime) {
          return new Date(b.endTime).getTime() - new Date(a.endTime).getTime();
        }
        return 0;
      }),
  });
}
