import { useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { Button } from "@/components/ui/button";
import { ChannelsTable } from "./components/ChannelsTable";
import { useGetChannelsList } from "../../../../api/metricsViewership/hooks/useGetChannelsList";
import { Channel, ChannelSource } from "../../../../api/metricsViewership/schemas/ChannelSchema";
import { DeleteChannelModal } from "./components/DeleteChannelModal";
import { CreateChannelModal } from "./components/CreateChannelModal";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import PageContainer from "@/components/Layout/PageContainer";

export const MetricsViewershipChannelsPage = () => {
  const [selectedSource, setSelectedSource] = useState<ChannelSource>("youtube");
  const [deletingChannel, setDeletingChannel] = useState<Channel | null>(null);
  const [isAddChannelModalOpen, setIsAddChannelModalOpen] = useState(false);
  const [query, setQuery] = useState("");
  const { data: channels, isError } = useGetChannelsList();
  const filteredChannels = useMemo(
    () =>
      channels?.filter(
        (channel) => selectedSource === channel.channelSource && channel.channelName.match(new RegExp(`${query}`, "i")),
      ),
    [channels, query, selectedSource],
  );

  return (
    <div className="w-full">
      <PageContainer>
        <div className="flex w-full items-center justify-between gap-3">
          <header className="flex flex-col gap-2">
            <div className="flex gap-4">
              <h1 className="text-heading">Channels</h1>
              <Button onClick={() => setIsAddChannelModalOpen(true)}>Add Channel</Button>
            </div>
            <div className="w-fit whitespace-nowrap font-style-b2-body-copy">
              List of all channels available to be assigned to events.
            </div>
          </header>
        </div>

        <div className="flex w-full flex-col gap-3">
          <ToggleGroup
            className="justify-start"
            type="single"
            variant="outline"
            value={selectedSource}
            onValueChange={(value) => setSelectedSource(value as ChannelSource)}
          >
            {["Twitch", "YouTube", "Tiktok", "Kick", "BLAST TV"].map((source) => (
              <ToggleGroupItem key={source} value={source.toLowerCase().replaceAll(" ", "") as ChannelSource}>
                {source}
              </ToggleGroupItem>
            ))}
          </ToggleGroup>

          <TextField
            label="Filter by name"
            variant="outlined"
            size="small"
            className="w-80"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <ChannelsTable
          channels={filteredChannels ?? []}
          isError={isError}
          onDelete={(channel) => setDeletingChannel(channel)}
          noChannelsMessage={"No channels found."}
        />
      </PageContainer>

      {isAddChannelModalOpen && <CreateChannelModal onClose={() => setIsAddChannelModalOpen(false)} />}
      <DeleteChannelModal
        isOpen={!!deletingChannel}
        onClose={() => setDeletingChannel(null)}
        channel={deletingChannel}
      />
    </div>
  );
};
