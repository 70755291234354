import { useQuery } from "@tanstack/react-query";
import { getPlayers } from "../../../../api/tournaments";

export default function useFetchPlayers(game?: string) {
  return useQuery({
    queryKey: ["players", game],
    queryFn: () => {
      return getPlayers(game);
    },
    staleTime: 1000 * 3,
    enabled: true,
    retry: false,
  });
}
