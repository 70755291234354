import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { GeneralInfo } from "./components/GeneralInfo";
import { TeamInfo } from "./components/TeamInfo";
import { useParams } from "react-router-dom";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import useUpdateMatch from "../../../hooks/matches/useUpdateMatch";
import { useEffect, useState, useMemo } from "react";
// import useFetchMatchMaps from "../../../hooks/maps/useFetchMatchMaps";
// import { Maps } from "./components/Maps";
// import { Vetoes } from "./components/Vetoes";
import useCreateMatch from "../../../hooks/matches/useCreateMatch";
import { DeleteConfirmModal } from "./components/DeleteConfirmModal";
import usePostMap from "../../../hooks/maps/usePostMap";
import mapPool from "../../../shared/components/mapPool";
import { EditCommunityMatch } from "../../../../../api/community-tournaments/schemas/matches";
import { TournamentEnrolledTeam } from "../../../../../api/community-tournaments/schemas/tournament";

interface MatchModalProps {
  match: EditCommunityMatch;
  handleClose: () => void;
  teams?: TournamentEnrolledTeam[];
  updateMatch: (updatedMatch: EditCommunityMatch) => void;
  gameId?: string;
}

export const MatchModal = ({
  match,
  handleClose,
  teams,
  gameId = "cs",
}: MatchModalProps) => {
  const [editMatch, setEditMatch] = useState<EditCommunityMatch>(match);
  const [allowMapSave, setAllowMapSave] = useState<"yes" | "saveMatch" | "no">(
    "no",
  );
  // const [vetoModal, setVetoModal] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

  useEffect(() => {
    setEditMatch(match);
  }, [match]);

  useEffect(() => {
    if (
      editMatch.teams.length != 2 ||
      editMatch.id === "NEWMATCH" ||
      editMatch.teams[0] === undefined ||
      editMatch.teams[1] === undefined
    ) {
      setAllowMapSave("no");
    } else if (
      editMatch.teams.length == 2 &&
      editMatch.teams[0].team?.id === editMatch.teams[0].prevTeamId &&
      editMatch.teams[1].team?.id === editMatch.teams[1].prevTeamId
    ) {
      setAllowMapSave("yes");
    } else {
      setAllowMapSave("saveMatch");
    }
  }, [match, editMatch]);

  const alert = useAlert();
  const { tournamentId } = useParams();

  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
  useEffect(() => {
    setReadyToSubmit(
      editMatch != undefined &&
        editMatch.index >= 0 &&
        editMatch.name !== "" &&
        editMatch.name !== undefined &&
        editMatch.scheduledAt !== undefined &&
        editMatch.teams
          .map((team) => team?.score)
          .every(
            (score) =>
              score !== null &&
              score !== undefined &&
              !isNaN(score) &&
              score >= 0,
          ),
    );
  }, [editMatch]);

  const teamA = editMatch.teams?.find((team) => team?.teamIndex === 10);
  const teamB = editMatch.teams?.find((team) => team?.teamIndex === 20);

  const { mutate: submitUpdate } = useUpdateMatch({
    tournamentId: tournamentId,
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated match");
    },
    onError: () => {
      handleClose();
      alert.showFailureAlert("Error while updating match data");
    },
  });

  const { mutate: submitCreate } = useCreateMatch({
    tournamentId: tournamentId,
    onSuccess: () => {
      alert.showSuccessAlert("Successfully Created match");
    },
    onError: () => {
      handleClose();
      alert.showFailureAlert("Error while creating match data");
    },
  });

  const { mutate: postMap } = usePostMap();

  const mapComponents = useMemo(() => {
    if (editMatch.maps && editMatch.maps.length > 0) {
      return editMatch.maps
        .sort(
          (a, b) =>
            new Date(a.startedAt || a.scheduledAt)?.getTime() -
            new Date(b.startedAt || b.scheduledAt)?.getTime(),
        )
        .map((matchMap, i) => (
          <pre key={`matchMap-${match.id}-${i}`}>
            {JSON.stringify(matchMap, null, 2)}
          </pre>
          // <Maps
          //   key={matchMap.id}
          //   matchMap={matchMap}
          //   mapNumber={i + 1}
          //   teamA={teamA}
          //   teamB={teamB}
          //   allowedToSave={allowMapSave}
          //   gameId={gameId}
          // />
        ));
    } else {
      return <Typography variant="body2">No Maps found</Typography>;
    }
  }, [editMatch.maps, match.id]);

  return (
    <>
      <DeleteConfirmModal
        isOpen={deleteConfirm}
        close={() => setDeleteConfirm(false)}
        matchId={editMatch.id}
        tournamentId={tournamentId}
        closeMatchModal={handleClose}
      />
      <Dialog
        open={editMatch !== undefined}
        onClose={() => {
          handleClose();
        }}
        PaperProps={{
          sx: {
            maxWidth: "1500px",
            width: "100%",
            padding: "10px",
          },
        }}
      >
        <DialogContent className="flex flex-col gap-10">
          <div className="flex gap-28 justify-center">
            <TeamInfo
              team={teamA}
              teamLabel="Team A"
              tournamentTeams={teams}
              updateMatch={setEditMatch}
              match={editMatch}
            />
            <Stack flexDirection={"column"} width={340}>
              <GeneralInfo match={editMatch} updateMatch={setEditMatch} />
            </Stack>
            <TeamInfo
              team={teamB}
              teamLabel="Team B"
              tournamentTeams={teams}
              updateMatch={setEditMatch}
              match={editMatch}
            />
          </div>
          {/* {allowMapSave == "yes" && gameId === "cs" && (
            <Button
              variant="outlined"
              className="w-[150px] self-center"
              onClick={setVetoModal(true)}
            >
              Vetos
            </Button>
          )} */}
          {/* Maps */}
          <div className="flex flex-col">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignContent={"center"}
              className="mb-5"
            >
              <Typography variant="h6">Maps</Typography>
              <div className="flex flex-col justify-end align-bottom items-end">
                <Button
                  disabled={allowMapSave !== "yes"}
                  onClick={() => {
                    const teamIds = [];
                    teamA?.team && teamIds.push(teamA.team.id);
                    teamB?.team && teamIds.push(teamB.team.id);
                    postMap({
                      mapName: mapPool[gameId][0],
                      matchId: editMatch.id,
                    });
                  }}
                >
                  Create Map
                </Button>
                {allowMapSave === "saveMatch" && (
                  <Typography className="text-orange-400">
                    Teams Changed, Please Save Match
                  </Typography>
                )}
                {editMatch.id === "NEWMATCH" && (
                  <Typography className="text-orange-400">
                    Please Save Match
                  </Typography>
                )}
              </div>
            </Stack>
            {/* {isMatchMapsLoading && <CircularProgress />} */}
            {mapComponents}
          </div>
        </DialogContent>
        <DialogActions>
          {editMatch.id !== "NEWMATCH" && (
            <Button
              color="error"
              variant="contained"
              onClick={() => setDeleteConfirm(true)}
            >
              Delete match
            </Button>
          )}
          <div className="flex-grow" />
          <Button onClick={handleClose} variant="contained" color="inherit">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (editMatch.id === "NEWMATCH") {
                submitCreate({ gameId, match: editMatch });
              } else {
                submitUpdate({ gameId, match: editMatch });
              }
              handleClose();
            }}
            disabled={!readyToSubmit}
            variant="contained"
            color="primary"
            autoFocus
          >
            Save Match
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Vetoes
        isOpen={vetoModal}
        handleClose={() => setVetoModal(false)}
        match={match}
        matchHasMaps={(matchMaps && matchMaps.length > 0) || false}
        teamA={teamA}
        teamB={teamB}
      /> */}
    </>
  );
};
