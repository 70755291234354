import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Channel } from "../../../../../api/metricsViewership/schemas/ChannelSchema";
import { Delete } from "@mui/icons-material";
import { PlatformIcon } from "@/routes/MetricsViewership/routes/shared/components/PlatformIcon";

export const ChannelsTable = ({
  channels,
  isError,
  onDelete,
  noChannelsMessage,
}: {
  onDelete: (channel: Channel) => void;
  channels: Channel[];
  isError?: boolean;
  className?: string;
  noChannelsMessage?: string;
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="text-xs">
          <TableHead className="w-12">Platform</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Streams</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isError && (
          <TableRow>
            <TableCell colSpan={4}>Something went wrong when fetching data</TableCell>
          </TableRow>
        )}
        {!isError && channels?.length === 0 && (
          <TableRow>
            <TableCell colSpan={4}>{noChannelsMessage ?? "No channels found."}</TableCell>
          </TableRow>
        )}

        {channels?.map((channel, i) => {
          return (
            <TableRow key={i}>
              <TableCell>
                <PlatformIcon platform={channel.channelSource} />
              </TableCell>
              <TableCell>{channel.channelName}</TableCell>
              <TableCell>{channel.streams?.length ?? 0} Streams</TableCell>
              <TableCell className="text-right">
                <Button variant="ghost" size="icon" onClick={() => onDelete(channel)}>
                  <Delete />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
