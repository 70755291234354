import { useQuery } from "@tanstack/react-query";

import { getSuspensions } from "../../../../../api/chat";

export default function useFetchSuspensions(userId?: string) {
  return useQuery({
    queryKey: ["suspensions", userId],
    queryFn: userId
      ? () => getSuspensions(userId).then(({ items }) => items)
      : undefined,
    cacheTime: 1000 * 30,
    enabled: !!userId,
  });
}
