import { z } from "zod";

export const TimelineEventSchema = z.object({
  id: z.string(),
  matchId: z.string(),
  mapNumber: z.number().optional(),
  roundNumber: z.number().optional(),
  eventType: z.string(),
  occurredAt: z.string(),
  endedAt: z.string().optional(),
  priority: z.number().optional(),
  eventContent: z.any(),
  isManualEvent: z.boolean().optional(),
});
export type TimelineEvent = z.infer<typeof TimelineEventSchema>;
