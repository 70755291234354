import { Casino, Check, Close, Edit } from "@mui/icons-material";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { PointsProduct } from "../../../../../api/points/schemas";
import { assetsURL } from "../../../../../config";
import usePointsContext from "../../../context/PointsConsumer";

interface ProductsTableProps {
  products: PointsProduct[] | undefined;
  onRaffleClick: (product: PointsProduct) => void;
}

const typeTitles: Record<PointsProduct["type"], string> = {
  default: "Default",
  "raffle-reward": "Raffle Reward",
  "raffle-ticket": "Raffle Ticket",
};

export const ProductsTable = ({ products, onRaffleClick }: ProductsTableProps) => {
  const { editCreateModalMethods } = usePointsContext();

  return (
    <TableContainer style={{ height: "80vh" }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Icon</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Action</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell>Published</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.map((product) => (
            <TableRow key={product.id}>
              <TableCell>
                <img src={`${assetsURL}/images/products/${product.id}`} alt={product.name} style={{ width: "50px" }} />
              </TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>{typeTitles[product.type]}</TableCell>
              <TableCell>{product.action}</TableCell>
              <TableCell align="center">{product.price}</TableCell>
              <TableCell>{product.isPublished ? <Check /> : <Close />}</TableCell>
              <TableCell align="right">
                {product.type === "raffle-reward" && (
                  <Button
                    className="mr-2"
                    variant="outlined"
                    color="success"
                    startIcon={<Casino />}
                    onClick={() => onRaffleClick(product)}
                  >
                    Raffle
                  </Button>
                )}
                <Button
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={() => {
                    editCreateModalMethods.editExisting(product);
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
