import { ModerationRule } from "../../../types/Moderation";

export const getModerationRuleText = (rule: ModerationRule): string => {
  let text = "";

  if (rule.toxic) {
    if (text) text += " AND ";

    text += `Toxicity > ${rule.toxic}`;
  }

  if (rule.derogatory) {
    if (text) text += " AND ";

    text += `Derogatory > ${rule.derogatory}`;
  }

  if (rule.violent) {
    if (text) text += " AND ";

    text += `Violent > ${rule.violent}`;
  }

  if (rule.sexual) {
    if (text) text += " AND ";

    text += `Sexual > ${rule.sexual}`;
  }

  if (rule.insult) {
    if (text) text += " AND ";

    text += `Insult > ${rule.insult}`;
  }

  if (rule.profanity) {
    if (text) text += " AND ";

    text += `Profanity > ${rule.profanity}`;
  }

  if (rule.deathHarmAndTragedy) {
    if (text) text += " AND ";

    text += `Death, Harm, and Tragedy > ${rule.deathHarmAndTragedy}`;
  }

  if (rule.firearmsAndWeapons) {
    if (text) text += " AND ";

    text += `Firearms and Weapons > ${rule.firearmsAndWeapons}`;
  }

  if (rule.publicSafety) {
    if (text) text += " AND ";

    text += `Public Safety > ${rule.publicSafety}`;
  }

  if (rule.health) {
    if (text) text += " AND ";

    text += `Health > ${rule.health}`;
  }

  if (rule.religionAndBelief) {
    if (text) text += " AND ";

    text += `Religion and Belief > ${rule.religionAndBelief}`;
  }

  if (rule.illicitDrugs) {
    if (text) text += " AND ";

    text += `Illicit Drugs > ${rule.illicitDrugs}`;
  }

  if (rule.warAndConflict) {
    if (text) text += " AND ";

    text += `War and Conflict > ${rule.warAndConflict}`;
  }

  if (rule.finance) {
    if (text) text += " AND ";

    text += `Finance > ${rule.finance}`;
  }

  if (rule.politics) {
    if (text) text += " AND ";

    text += `Politics > ${rule.politics}`;
  }

  if (rule.legal) {
    if (text) text += " AND ";

    text += `Legal > ${rule.legal}`;
  }

  return text;
};
