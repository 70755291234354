import { z } from "zod";
import { client } from "..";

const FantasyEventSchema = z.object({
  eventId: z.string(),
  tournamentId: z.string(),
  stageIds: z
    .array(z.string())
    .nullish()
    .transform((stageIds) => stageIds ?? []),
  title: z.string(),
  endTime: z.string().nullish(),
  editLockTime: z.string().nullish(),
  calculationSchemaId: z.string().nullish(),
  season: z.object({
    uid: z.string(),
    title: z.string(),
  }),
});

export type FantasyEvent = z.infer<typeof FantasyEventSchema>;

export const getSanityFantasyEvents = async () => {
  const query = `
    *[_type == "fantasyEvent"] | order(_updatedAt desc) {
      ...,
      'tournamentId': tournamentSelectByGame.tournamentId,
      eventId,
      season->{
        uid,
        title
      },

    }`;
  const data = await client.fetch<unknown>(query).catch(() => {
    throw new Error("Failed to fetch data from CMS");
  });

  return FantasyEventSchema.array().parse(data);
};
