import z from "zod";

export const PirateStreamStatusSchema = z.enum(["live", "offline", "reported"]);

export type PirateStreamStatus = z.infer<typeof PirateStreamStatusSchema>;

export const StreamSchema = z.object({
  channelId: z.string(),
  videoId: z.string(),
  title: z.string(),
  channelTitle: z.string().optional(),
  publishedAt: z.string().optional(),
  thumbnail: z.string().optional(),
  channelThumbnail: z.string().optional(),
  tags: z.array(z.string()).optional(),
  status: PirateStreamStatusSchema,
  reason: z.string().optional(), // reason for the stream being identified as a pirate stream
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
});

export type Stream = z.infer<typeof StreamSchema>;
