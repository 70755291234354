import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import useDeleteAward from "../../hooks/awards/useDeleteAward";

interface ConfirmDeleteAwardProps {
  isOpen: boolean;
  close: () => void;
  awardId: string;
}

export const ConfirmDeleteAward = ({
  isOpen,
  close,
  awardId,
}: ConfirmDeleteAwardProps) => {
  const alert = useAlert();
  const { mutate: deleteAward } = useDeleteAward({
    onSuccess: () => alert.showSuccessAlert("Delete Match Success"),
    onError: () => alert.showFailureAlert("Delete Match Failed"),
  });

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: "600px",
          padding: "10px",
        },
      }}
      onClose={close}
    >
      <DialogContent>
        <Typography>Are you sure you want to delete this Award?</Typography>
        <Button
          className="bg-red-500 text-black mx-14 w-30 my-5"
          onClick={() => {
            deleteAward({ awardId });
            close();
          }}
        >
          Yes
        </Button>
        <Button
          onClick={close}
          className="bg-slate-600 hover:bg-slate-500 text-white"
        >
          No
        </Button>
      </DialogContent>
    </Dialog>
  );
};
