import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateBroadcast } from "../../../../../api/broadcasts";
import { Patron } from "../../../../../providers/PatronProvider/types";
import { Broadcast } from "../../../../../types/Broadcasts";

const useUpdateBroadcast = ({ patron, id }: { patron: Patron; id: string }) => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationKey: ["broadcasts", patron, id],
    mutationFn: (payload: Broadcast) =>
      updateBroadcast({ patron, id, payload }),
    onSuccess: () => {
      void queryClient.invalidateQueries(["broadcasts", patron, id]);
    },
  });

  return result;
};

export default useUpdateBroadcast;
