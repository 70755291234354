import { useQuery } from "@tanstack/react-query";
import { getEvents, GetEventsOptions } from "..";

export const useGetEvents = (data: GetEventsOptions) => {
  return useQuery({
    queryFn: () => getEvents(data),
    queryKey: ["monitoring sns", data.topicName],
    enabled: false,
    retry: false,
  });
};
