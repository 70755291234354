import { useCallback } from "react";
import { DateTime } from "luxon";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

export const useGetGameServerTime = ({
  programStartDateIsoString,
  videoOffsetSeconds,
}: {
  programStartDateIsoString: string | undefined;
  videoOffsetSeconds: number | undefined;
}) => {
  const alert = useAlert();

  const getGameServerTime = useCallback(
    (seekTimeSeconds: number) => {
      if (programStartDateIsoString === undefined) {
        alert.showFailureAlert(
          "useGetGameServerTime: programStartDateIsoString is undefined",
        );
        return;
      }
      if (seekTimeSeconds === undefined) {
        alert.showFailureAlert(
          "useGetGameServerTime: seekTimeSeconds is undefined",
        );
        return;
      }

      const programStartDate = DateTime.fromISO(
        programStartDateIsoString,
      ).toUTC();
      const whenInTime = programStartDate.plus({ seconds: seekTimeSeconds });
      const whenInTimeOffset = whenInTime.minus({
        seconds: videoOffsetSeconds,
      });

      return whenInTimeOffset.toUTC().toISO();
    },
    [alert, programStartDateIsoString, videoOffsetSeconds],
  );

  return getGameServerTime;
};
