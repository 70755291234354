import { CsEvent } from "../../../api/video/schemas";

export const getClipName = (event: CsEvent) => {
  switch (event.eventType) {
    case "AGAINST_THE_ODDS":
      return "Clutch";
    case "RAPID":
      return `${event.eventContent.numberOfKills}X MULTIKILL`;
    case "ECO":
      return "ECO WIN";
    case "KNIFE":
      return "KNIFE KILL";
    case "TAZED":
      return "ZEUS";
    default:
      return event.eventType.replaceAll("_", " ");
  }
};
