import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EditMatch } from "../../../../../api/tournaments/schemas/matches";
import { Team } from "../../../../../api/tournaments/schemas/teams";
import { GeneralInfo } from "./components/GeneralInfo";
import { TeamInfo } from "./components/TeamInfo";
import { useParams } from "react-router-dom";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import useUpdateMatch from "../../../hooks/matches/useUpdateMatch";
import { useEffect, useState, useMemo } from "react";
import useFetchMatchMaps from "../../../hooks/maps/useFetchMatchMaps";
import { Maps } from "./components/Maps";
import { Vetoes } from "./components/Vetoes";
import useCreateMatch from "../../../hooks/matches/useCreateMatch";
import { DeleteConfirmModal } from "./components/DeleteConfirmModal";
import usePostMap from "../../../hooks/maps/usePostMap";
import { DataUrlAccordion } from "./components/DataUrlsAccordion";
import mapPool from "../../../shared/components/mapPool";
import { useRegenerateMapsData } from "../../../hooks/maps/useRegenerateMapsData";

interface MatchModalProps {
  match: EditMatch;
  handleClose: () => void;
  teams?: Team[];
  updateMatch: (updatedMatch: EditMatch) => void;
  gameId?: string;
}

export const MatchModal = ({ match, handleClose, teams, gameId = "cs" }: MatchModalProps) => {
  const [editMatch, setEditMatch] = useState<EditMatch>(match);
  const [allowMapSave, setAllowMapSave] = useState<"yes" | "saveMatch" | "no">("no");
  const [vetoModal, setVetoModal] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (
      editMatch.teams.length != 2 ||
      editMatch.matchId === "NEWMATCH" ||
      editMatch.teams[0].team === undefined ||
      editMatch.teams[1].team === undefined
    ) {
      setAllowMapSave("no");
    } else if (
      editMatch.teams.length == 2 &&
      editMatch.teams[0].team?.id === editMatch.teams[0].prevTeamId &&
      editMatch.teams[1].team?.id === editMatch.teams[1].prevTeamId
    ) {
      setAllowMapSave("yes");
    } else {
      setAllowMapSave("saveMatch");
    }
  }, [match, editMatch]);

  const alert = useAlert();
  const { tournamentId } = useParams();

  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
  useEffect(() => {
    setReadyToSubmit(
      editMatch != undefined &&
        editMatch.matchIndex >= 0 &&
        editMatch.matchName !== "" &&
        editMatch.matchName !== undefined &&
        editMatch.scheduledAt !== undefined &&
        editMatch.teams
          .map((team) => team?.score)
          .every((score) => score !== null && score !== undefined && !isNaN(score) && score >= 0),
    );
  }, [editMatch]);

  const teamA = editMatch.teams.find((team) => team?.teamIndex === 10);
  const teamB = editMatch.teams.find((team) => team?.teamIndex === 20);

  const { mutate: submitUpdate } = useUpdateMatch({
    tournamentId: tournamentId,
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated match");
    },
    onError: () => {
      handleClose();
      alert.showFailureAlert("Error while updating match data");
    },
  });

  const { mutate: submitCreate } = useCreateMatch({
    tournamentId: tournamentId,
    onSuccess: () => {
      alert.showSuccessAlert("Successfully Created match");
    },
    onError: () => {
      handleClose();
      alert.showFailureAlert("Error while creating match data");
    },
  });

  const { mutate: postMap } = usePostMap();
  const { mutate: regenerateMapsData, isLoading: isRegenerateMapsDataLoading } = useRegenerateMapsData();

  const { data: matchMaps, isLoading: isMatchMapsLoading } = useFetchMatchMaps(editMatch.matchId);

  const mapComponents = useMemo(() => {
    if (matchMaps && matchMaps.length > 0) {
      return matchMaps
        .sort(
          (a, b) =>
            (a.scheduledAt?.getTime() || new Date(a.createdAt).getTime()) -
            (b.scheduledAt?.getTime() || new Date(b.createdAt).getTime()),
        )
        .map((matchMap, i) => (
          <Maps
            key={matchMap.id}
            matchMap={matchMap}
            mapNumber={i + 1}
            teamA={teamA}
            teamB={teamB}
            allowedToSave={allowMapSave}
            gameId={gameId}
          />
        ));
    } else {
      return <Typography variant="body2">No Maps found</Typography>;
    }
  }, [allowMapSave, matchMaps, teamA, teamB, gameId]);

  return (
    <>
      <DeleteConfirmModal
        isOpen={deleteConfirm}
        close={() => setDeleteConfirm(false)}
        matchId={editMatch.matchId}
        tournamentId={tournamentId}
        closeMatchModal={handleClose}
      />
      <Dialog
        open={editMatch !== undefined}
        onClose={() => {
          handleClose();
        }}
        PaperProps={{
          sx: {
            maxWidth: "1500px",
            width: "100%",
            padding: "10px",
          },
        }}
      >
        <DialogContent className="flex flex-col gap-10">
          <div className="flex justify-center gap-28">
            <TeamInfo team={teamA} teamLabel="Team A" teams={teams} updateMatch={setEditMatch} match={editMatch} />
            <Stack flexDirection={"column"} width={340}>
              <GeneralInfo match={editMatch} gameId={gameId} updateMatch={setEditMatch} />
            </Stack>
            <TeamInfo team={teamB} teamLabel="Team B" teams={teams} updateMatch={setEditMatch} match={editMatch} />
          </div>
          {allowMapSave == "yes" && gameId === "cs" && (
            <Button variant="outlined" className="w-[150px] self-center" onClick={() => setVetoModal(true)}>
              Vetos
            </Button>
          )}
          {/* Statistics & Timeline URL */}
          <DataUrlAccordion matchId={editMatch.matchId} matchType={editMatch.matchType} />
          {/* Maps */}
          <div className="flex flex-col">
            <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} className="mb-5">
              <div className="flex gap-2">
                <Typography variant="h6">Maps</Typography>
                {gameId === "dota" && (
                  <LoadingButton
                    disabled={!matchMaps?.length}
                    loading={isRegenerateMapsDataLoading}
                    type="button"
                    onClick={() => {
                      regenerateMapsData(editMatch.matchId);
                    }}
                  >
                    regenerate maps data
                  </LoadingButton>
                )}
              </div>
              <div className="flex flex-col items-end justify-end align-bottom">
                <Button
                  disabled={allowMapSave !== "yes"}
                  onClick={() => {
                    const teamIds = [];
                    teamA?.team && teamIds.push(teamA.team.id);
                    teamB?.team && teamIds.push(teamB.team.id);
                    postMap({
                      mapName: mapPool[gameId][0],
                      matchId: editMatch.matchId,
                    });
                  }}
                >
                  Create Map
                </Button>
                {allowMapSave === "saveMatch" && (
                  <Typography className="text-orange-400">Teams Changed, Please Save Match</Typography>
                )}
                {editMatch.matchId === "NEWMATCH" && (
                  <Typography className="text-orange-400">Please Save Match</Typography>
                )}
              </div>
            </Stack>
            {isMatchMapsLoading && <CircularProgress />}
            {mapComponents}
          </div>
        </DialogContent>
        <DialogActions>
          {editMatch.matchId !== "NEWMATCH" && (
            <Button color="error" variant="contained" onClick={() => setDeleteConfirm(true)}>
              Delete match
            </Button>
          )}
          <div className="grow" />
          <Button onClick={handleClose} variant="contained" color="inherit">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (editMatch.matchId === "NEWMATCH") {
                submitCreate({ gameId, match: editMatch });
              } else {
                submitUpdate({ gameId, match: editMatch });
              }
              handleClose();
            }}
            disabled={!readyToSubmit}
            variant="contained"
            color="primary"
            autoFocus
          >
            Save Match
          </Button>
        </DialogActions>
      </Dialog>
      <Vetoes
        isOpen={vetoModal}
        handleClose={() => setVetoModal(false)}
        match={match}
        matchHasMaps={(matchMaps && matchMaps.length > 0) || false}
        teamA={teamA}
        teamB={teamB}
      />
    </>
  );
};
