import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { usePatron } from "@/providers/PatronProvider/hooks/usePatron";
import { Patron } from "@/providers/PatronProvider/types";

export function PatronSelect() {
  const { patron, setPatron, patrons } = usePatron();
  return (
    <div className="w-28">
      <Select value={patron} onValueChange={(value) => setPatron(value as Patron)}>
        <SelectTrigger>
          <SelectValue placeholder="Select a patron" />
        </SelectTrigger>
        <SelectContent>
          {patrons.map((patron) => (
            <SelectItem key={patron} value={patron}>
              {patron}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
