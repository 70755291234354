import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTournamentTeam } from "..";
import { queryKeys } from "../shared/queryKeys";

export const useDeleteTournamentTeam = (tournamentId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteTournamentTeam,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.tournamentTeams(tournamentId) });
    },
  });
};
