import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { CounterStriklePlayers } from "./components/CounterStriklePlayers";
import { useGetCounterStriklePlayers } from "../../api/data/counter-strikle/getCounterStriklePlayers/useGetCounterStriklePlayers";

/**
 * Currently available on the `TOURNAMENTS_MANAGEMENT` permission, since we are showing the list of players.
 * Add specific permissions if there is functionality added later on specific to Counter Strikle.
 */
export const CounterStriklePage = () => {
  const { data: players, isLoading, isError } = useGetCounterStriklePlayers();

  return (
    <>
      <div className="flex w-full flex-col p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <span> Counter Strikle </span>
        </Breadcrumbs>
        <CounterStriklePlayers players={players ?? []} isLoading={isLoading} isError={isError} />
      </div>
    </>
  );
};
