import { useAuth } from "@/providers/AuthProvider/hooks/useAuth";
import botImage1 from "./bot1.webp";
import botImage2 from "./bot2.webp";
import botImage3 from "./bot3.webp";
import botImage4 from "./bot4.webp";

const Homepage = () => {
  const auth = useAuth();
  const random = Math.floor(Math.random() * 4);
  const botImage = [botImage1, botImage2, botImage3, botImage4][random];

  const userName = auth.user?.username;
  return (
    <div className="relative size-full">
      <img
        className="size-full object-cover opacity-25"
        style={{
          maxHeight: "calc(100svh - 64px)",
        }}
        src={botImage}
        alt="Bot madness"
      />
      {userName && (
        <div className="absolute left-1/2 top-1/4 -translate-x-1/2">
          <h1 className="font-style-desktop-h3">Welcome, {userName}</h1>
          <p className="font-style-b1-body-copy">You are now logged in to the BLAST.tv Backoffice.</p>
        </div>
      )}
    </div>
  );
};

export default Homepage;
