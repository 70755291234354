import UserProfile, {
  PublicUserProfile,
  UserChatRole,
  UserProfileOnEmail,
} from "../../types/users";
import { baseApiURL } from "../../config";
import axios from "axios";
import authInterceptor from "../authInterceptor";
import { userPermissionsSchema, userSchema } from "./schemas";

export const usersApi = axios.create({
  baseURL: baseApiURL,
});

usersApi.interceptors.request.use(authInterceptor);

export const getUsersByUsername = async ({
  beginsWith,
  limit,
}: {
  beginsWith: string;
  limit?: number;
}): Promise<PublicUserProfile[]> => {
  const { data } = await usersApi.get<PublicUserProfile[]>(
    `/v1/users/profile`,
    {
      params: {
        beginsWith,
        limit: limit ?? 10,
      },
    },
  );
  return data;
};

export const getUsersByEmail = async ({
  filterBy,
  beginsWith,
  limit,
}: {
  filterBy: string;
  beginsWith: string;
  limit?: number;
}): Promise<UserProfileOnEmail[]> => {
  const { data } = await usersApi.get<UserProfileOnEmail[]>(
    `/v1/users/profile`,
    {
      params: {
        filterBy,
        beginsWith,
        limit: limit ?? 10,
      },
    },
  );
  return data;
};

export const getUser = async (id: string): Promise<UserProfile> => {
  const { data } = await usersApi.get<UserProfile>(`/v1/users/${id}/profile`);
  return data;
};

export const putUserChatRole = async (
  id: string,
  {
    chatRole,
  }: {
    chatRole: UserChatRole;
  },
): Promise<void> => {
  await usersApi.put(`/v1/users/${id}/profile/chat-role`, {
    chatRole,
  });
};

export const deleteUserChatRole = async (id: string): Promise<void> => {
  await usersApi.delete(`/v1/users/${id}/profile/chat-role`);
};

export const postUserAvatar = async (
  id: string,
  avatarId: string,
): Promise<void> => {
  await usersApi.post(`/v1/users/${id}/profile/avatars/${avatarId}`);
};

export const deleteUserAvatar = async (
  id: string,
  avatarId: string,
): Promise<void> => {
  await usersApi.delete(`/v1/users/${id}/profile/avatars/${avatarId}`);
};

export const postUserEmoji = async (
  id: string,
  emojiId: string,
): Promise<void> => {
  await usersApi.post(`/v1/users/${id}/profile/emojis/${emojiId}`);
};

export const deleteUserEmoji = async (
  id: string,
  emojiId: string,
): Promise<void> => {
  await usersApi.delete(`/v1/users/${id}/profile/emojis/${emojiId}`);
};

export const updateUsername = async (
  id: string,
  newUsername: string,
): Promise<void> => {
  await usersApi.patch(`/v1/users/${id}/profile`, {
    username: newUsername,
  });
};

export const getUserPermissions = async (): Promise<string[]> => {
  const response = await usersApi.get<unknown>("/v1/users/permissions");
  const userPermissions = userPermissionsSchema.parse(response.data);
  return userPermissions;
};

export const banUser = async (id: string): Promise<void> => {
  await usersApi.post(`/v1/users/${id}/ban`, {
    reason: "Banned by admin from Backoffice",
  });
};

export const unbanUser = async (id: string): Promise<void> => {
  await usersApi.post(`/v1/users/${id}/unban`, {});
};

export const getMultipleUsers = async (ids: string[]) => {
  const { data } = await usersApi.post<unknown>(`/v1/users/by-ids`, {
    ids,
  });
  return userSchema.array().parse(data);
};
