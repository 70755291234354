import styled from "@emotion/styled/macro";
import { Card } from "@mui/material";

export const ConfirmModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ModalContent = styled(Card)`
  min-width: 400px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 1rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;
