import { useParams } from "react-router-dom";
import { PlayerList } from "./components/PlayerList";
import useFetchPlayers from "./hooks/useFetchPlayers";

const PlayersPage = () => {
  const { gameId } = useParams();

  const { data: players } = useFetchPlayers(gameId);

  return (
    <div className="flex w-full flex-col">
      <div className="p-7">
        <PlayerList players={players} gameId={gameId ?? ""} />
      </div>
    </div>
  );
};

export default PlayersPage;
