import { z } from "zod";

export const ProductActionSchema = z.enum([
  "NONE",
  "GRANT_VIP",
  "GRANT_TEAM_TAKEOVER",
  "GRANT_TEAM_TAKEOVER_VOTE",
  "GRANT_HEAD_TO_HEAD_POLL",
  "GRANT_EMOJI_EXPLOSION",
  "REDEEM_CASE",
]);

export type ProductAction = z.infer<typeof ProductActionSchema>;

export const BaseProductSchema = z.object({
  action: ProductActionSchema,
  allowedRedemptions: z.number().int().min(0).optional(),
  cooldown: z.number().int().min(0).optional(),
  description: z.string().optional(),
  id: z.string(),
  isHidden: z.boolean().default(true),
  isPublished: z.boolean(),
  isRedeemableFromChat: z.boolean().default(false),
  name: z.string().nonempty(),
  price: z.number().int().min(0),
  publishedAt: z.string().datetime().optional(),
  redemptionsLeft: z.number().int().min(0).optional(),
  isRedeemableOnlyOnce: z.boolean().default(false),
  collectionId: z.string().optional(),
  lastPurchasedAt: z.string().datetime().optional(),
  type: z.enum(["default", "raffle-ticket", "raffle-reward"]).default("default"),
  raffleTicketProductId: z.string().optional(),
});

const DefaultProductSchema = BaseProductSchema.extend({
  type: z.literal("default").default("default"),
});

const RaffleTicketProductSchema = BaseProductSchema.extend({
  type: z.literal("raffle-ticket"),
});

const RaffleRewardProductSchema = BaseProductSchema.extend({
  type: z.literal("raffle-reward"),
  raffleTicketProductId: z.string().optional(),
});

export const PointsProductSchema = z.union([
  DefaultProductSchema,
  RaffleTicketProductSchema,
  RaffleRewardProductSchema,
]);

export const PointsProductResponseSchema = z.object({
  products: PointsProductSchema.array(),
});

export type PointsProduct = z.infer<typeof PointsProductSchema>;

// Purchases types
export const HeadToHeadPollDetails = z.object({
  action: z.literal("GRANT_HEAD_TO_HEAD_POLL"),
  pollId: z.string(),
  approved: z.boolean().optional(),
  refunded: z.boolean().optional(),
});

export const PurchaseDetailsSchema = HeadToHeadPollDetails.optional();

export const PurchaseSchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  purchaseDate: z.string().datetime(),
  productId: z.string(),
  productName: BaseProductSchema.shape.name,
  productAction: BaseProductSchema.shape.action,
  productPrice: BaseProductSchema.shape.price,
  transactionId: z.string(),
  details: PurchaseDetailsSchema,
});

export type Purchase = z.infer<typeof PurchaseSchema>;

export const PurchasesSchema = PurchaseSchema.array();

export type Purchases = z.infer<typeof PurchasesSchema>;
export const GetPurchasesOptionsSchema = z.object({
  productId: z.string(),
  startDate: z.string().datetime().optional(),
  endDate: z.string().datetime().optional(),
  pageToken: z.string().optional(),
  limit: z.coerce.number().int().optional(),
  state: z.enum(["pending", "approved", "unapproved"]).optional(),
});

export type GetPurchasesOptions = z.infer<typeof GetPurchasesOptionsSchema>;

export const GetPurchasesResponse = z.object({
  purchases: PurchasesSchema,
  nextPageToken: z.string().optional(),
});

export const GrantPointsDataSchema = z.object({
  purpose: z.string(),
  userIds: z.string().array(),
  amount: z.number().int().min(1),
});

export type GrantPointsData = z.infer<typeof GrantPointsDataSchema>;

export const GrantPointsResponseSchema = z.object({
  message: z.string(),
  hasErrors: z.boolean(),
  error: z.record(z.union([z.literal("userIds"), z.string()]), z.string().array()),
});
