import { Delete, Edit, ExpandMore, Info, Save, Sync, Warning } from "@mui/icons-material";
import useFetchTeams from "../../../../hooks/teams/useFetchTeams";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Dialog,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import useAlert from "../../../../../../providers/AlertProvider/hooks/useAlert";
import { useGetTournamentTeams } from "../../../../../../api/tournaments/getTournamentTeams/useGetTournamentTeams";
import { useCreateTournamentTeam } from "../../../../../../api/tournaments/createTournamentTeam/useCreateTournamentTeam";
import { assetsURL } from "../../../../../../config";
import { ConfirmationModal } from "../../../../../../shared/components/ConfirmationModal";
import { useDeleteTournamentTeam } from "../../../../../../api/tournaments/deleteTournamentTeam/useDeleteTournamentTeam";
import useFetchCircuitById from "../../../../hooks/useFetchCircuitById";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TournamentTeamSlot } from "../../../../../../api/tournaments/schemas/tournaments";
import { LoadingButton } from "@mui/lab";
import useFetchTournamentById from "../../../../hooks/useFetchTournamentById";

export const TournamentTeamsAccordion = ({ tournamentId, circuitId }: { tournamentId: string; circuitId: string }) => {
  const alert = useAlert();
  const { data: tournament } = useFetchTournamentById(tournamentId);
  const { data: circuit } = useFetchCircuitById(circuitId);
  const { data: tournamentTeams, isLoading: isLoadingTournamentTeams } = useGetTournamentTeams(tournamentId);
  const [isAddEditTeamSlotModalOpen, setIsAddEditTeamSlotModalOpen] = useState(false);
  const [editingTeamSlot, setEditingTeamSlot] = useState<TournamentTeamSlot | undefined>();
  const [deletingTeamSlot, setDeletingTeamSlot] = useState<TournamentTeamSlot | undefined>();
  const { mutateAsync: createTournamentTeam, isLoading: isCreatingTournamentTeam } = useCreateTournamentTeam({
    tournamentId,
  });

  const { mutate: deleteTeam } = useDeleteTournamentTeam(tournamentId);

  const handleDeleteTeam = () => {
    if (!deletingTeamSlot) return;

    deleteTeam(
      {
        tournamentId,
        index: deletingTeamSlot.index,
      },
      {
        onSuccess: () => {
          alert.showSuccessAlert("Team deleted successfully!");
          setDeletingTeamSlot(undefined);
        },
        onError: () => {
          alert.showFailureAlert("Failed to delete team");
          setDeletingTeamSlot(undefined);
        },
      },
    );
  };

  const handleAddMissingSpots = async () => {
    const missingSpots = (tournament?.numberOfTeams ?? 0) - (tournamentTeams?.length ?? 0);
    // Max of index field in the tournamentTeams array
    const maxIndex = Math.max(...(tournamentTeams?.map((teamSlot) => teamSlot.index) ?? []), 0);
    await Promise.all(
      Array.from({ length: missingSpots }).map((_, i) => {
        return createTournamentTeam({
          tournamentId,
          index: maxIndex + i + 1,
          teamId: null,
          qualificationMethod: undefined,
          bountySeed: undefined,
          bountyValue: undefined,
        });
      }),
    );
  };

  return (
    <Accordion className="mt-4">
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>
          <strong>Teams</strong>
          {!isLoadingTournamentTeams && (tournamentTeams?.length ?? 0) < (tournament?.numberOfTeams ?? 0) && (
            <span className="ml-4 text-sm text-white/70">
              <Info color="primary" className="mr-2" />
              {`Missing ${(tournament?.numberOfTeams ?? 0) - (tournamentTeams?.length ?? 0)} slots out of ${
                tournament?.numberOfTeams ?? 0
              }`}
            </span>
          )}
          {!isLoadingTournamentTeams && (tournamentTeams?.length ?? 0) > (tournament?.numberOfTeams ?? 0) && (
            <span className="ml-4 text-sm text-white/70">
              <Warning color="warning" className="mr-2" />
              Too many slots defined! Delete{" "}
              {`${(tournamentTeams?.length ?? 0) - (tournament?.numberOfTeams ?? 0)} slots`}
            </span>
          )}
          {!isLoadingTournamentTeams && (tournamentTeams?.length ?? 0) === (tournament?.numberOfTeams ?? 0) && (
            <span className="ml-4 text-sm text-white/70">
              {`${tournamentTeams?.filter((teamSlot) => teamSlot.team).length ?? 0} teams assigned to slots`}
            </span>
          )}
        </Typography>
      </AccordionSummary>
      <TableContainer component={AccordionDetails}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Team ID</TableCell>
              <TableCell>Team External ID</TableCell>
              <TableCell>Qualification Method</TableCell>
              <TableCell>Bounty Value</TableCell>
              <TableCell>Bounty Seed</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tournamentTeams?.map((teamSlot, index) => {
              const { team, qualificationMethod, bountySeed, bountyValue } = teamSlot;
              return (
                <TableRow key={index}>
                  <TableCell>
                    {/* We use the array index, rather than the index in data. The importance is the order of the teams rather than the value of the index */}
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    {team ? (
                      <div className="flex items-center gap-3">
                        <img
                          className="size-8"
                          height={32}
                          width={32}
                          src={`${assetsURL}/images/teams/${team.id}?height=64&width=64&format=auto&cache_busting=${Date.now()}`}
                          alt={`${team.name} logo`}
                        />
                        <strong>{team.shortName}</strong>
                      </div>
                    ) : (
                      <span className="text-white/60">-</span>
                    )}
                  </TableCell>
                  <TableCell className="text-xs">{team?.id ?? "-"}</TableCell>
                  <TableCell className="text-xs">{team?.externalId ?? "-"}</TableCell>
                  <TableCell className="text-xs">{qualificationMethod ?? "-"}</TableCell>
                  <TableCell className="text-xs">{bountyValue ?? "-"}</TableCell>
                  <TableCell className="text-xs">{bountySeed ?? "-"}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setIsAddEditTeamSlotModalOpen(true);
                        setEditingTeamSlot(teamSlot);
                      }}
                      size="small"
                    >
                      <Edit />
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setDeletingTeamSlot(teamSlot);
                      }}
                      size="small"
                      className="ml-2"
                    >
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}

            {(tournamentTeams?.length ?? 0) < (tournament?.numberOfTeams ?? 0) && (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="flex items-center gap-4">
                    <LoadingButton
                      onClick={() => void handleAddMissingSpots()}
                      startIcon={<Sync />}
                      size="small"
                      loading={isCreatingTournamentTeam}
                      loadingPosition="start"
                    >
                      Add {(tournament?.numberOfTeams ?? 0) - (tournamentTeams?.length ?? 0)} missing slots
                    </LoadingButton>
                    <FormHelperText>
                      {`The "Number of Teams" is set to ${tournament?.numberOfTeams ?? 0} but there are only ${tournamentTeams?.length ?? 0} slots defined. This will automatically add the missing slots (with no teams attached).`}
                      <br /> {"This will not affect the frontend since there will be no data attached to the slots."}
                    </FormHelperText>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {(tournamentTeams?.length ?? 0) > (tournament?.numberOfTeams ?? 0) && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant="subtitle2" color="error">
                    <Warning color="warning" className="mr-2" />
                    {`The "Number of Teams" is set to ${tournament?.numberOfTeams ?? 0} but there are ${tournamentTeams?.length ?? 0} slots defined. Delete the extra slots to avoid conflicts.`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {deletingTeamSlot && (
        <ConfirmationModal
          isOpen={!!deletingTeamSlot}
          onClose={() => setDeletingTeamSlot(undefined)}
          title="Delete team?"
          description="Are you sure that you want to remove the team slot from the tournament?"
          onConfirm={() => handleDeleteTeam()}
        />
      )}
      {isAddEditTeamSlotModalOpen && (
        <AddEditTeamSlotModal
          gameId={circuit?.gameId}
          isOpen={isAddEditTeamSlotModalOpen}
          tournamentId={tournamentId}
          editingTeamSlot={editingTeamSlot}
          onClose={() => {
            setIsAddEditTeamSlotModalOpen(false);
            setEditingTeamSlot(undefined);
          }}
        />
      )}
    </Accordion>
  );
};

const AddEditTeamSlotModal = ({
  gameId,
  tournamentId,
  editingTeamSlot,
  onClose,
  isOpen,
}: {
  gameId: string | undefined;
  tournamentId: string;
  editingTeamSlot: TournamentTeamSlot | undefined;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { data: teams } = useFetchTeams(gameId);
  const [selectedOption, setSelectedOption] = useState<{ id: string; label: string }>({
    id: "",
    label: "No team",
  });
  const [qualificationMethod, setQualificationMethod] = useState<string | undefined>(
    editingTeamSlot?.qualificationMethod ?? undefined,
  );
  const [bountyValue, setBountyValue] = useState<number | undefined>(editingTeamSlot?.bountyValue ?? undefined);
  const [bountySeed, setBountySeed] = useState<number | undefined>(editingTeamSlot?.bountySeed ?? undefined);

  const alert = useAlert();

  const { mutate: createTournamentTeam, isLoading: isCreatingTournamentTeam } = useCreateTournamentTeam({
    tournamentId,
  });
  const { mutateAsync: deleteTournamentTeam } = useDeleteTournamentTeam(tournamentId);

  const options = useMemo(() => {
    if (!teams) return [];

    return [
      { id: "", label: "No team" },
      ...(teams?.map((team) => {
        return { id: team.id, label: team.name };
      }) ?? []),
    ];
  }, [teams]);

  useEffect(() => {
    const selectedTeamId = editingTeamSlot?.team?.id;
    const selectedOption = options.find((option) => option.id === selectedTeamId);
    if (selectedOption) {
      setSelectedOption(selectedOption);
    }
  }, [editingTeamSlot, options]);

  const handleSave = async () => {
    if (editingTeamSlot) {
      // Delete existing team slot, to be recreated with the same index
      await deleteTournamentTeam(
        { tournamentId, index: editingTeamSlot.index },
        {
          onError: () => {
            alert.showFailureAlert("Cannot update, failed to delete team slot");
          },
        },
      );
    }

    createTournamentTeam(
      {
        tournamentId,
        teamId: selectedOption?.id ? selectedOption.id : null,
        qualificationMethod: qualificationMethod,
        bountySeed: bountySeed,
        bountyValue: bountyValue,
        index: editingTeamSlot?.index,
      },
      {
        onSuccess: () => {
          alert.showSuccessAlert("Team slot added to tournament successfully!");
          onClose();
        },
        onError: () => {
          alert.showFailureAlert("Failed to add team to the tournament");
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <div className="p-6">
        <h4 className="text-xl font-bold">Select a Team</h4>
        <div className="flex flex-col gap-4">
          <Autocomplete
            id="teamSelectionInput"
            className="mt-4"
            disablePortal={false}
            value={selectedOption}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={options}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Team" autoFocus />}
            onChange={(_, value) => {
              if (value) {
                setSelectedOption(value);
              }
            }}
          />
          <TextField
            label="Qualification Method"
            placeholder="Invitation / Partner"
            value={qualificationMethod}
            onChange={(e) => setQualificationMethod(e.target.value)}
          />
          <TextField
            label="Bounty Value"
            placeholder="60.000"
            value={bountyValue}
            onChange={(e) => {
              const value = Number(e.target.value);
              setBountyValue(isNaN(value) ? undefined : value);
            }}
          />
          <TextField
            label="Bounty Seed"
            placeholder="1"
            value={bountySeed}
            onChange={(e) => {
              const value = Number(e.target.value);
              setBountySeed(isNaN(value) ? undefined : value);
            }}
          />
          <div className="flex flex-row gap-2">
            <LoadingButton
              variant="contained"
              fullWidth
              onClick={() => void handleSave()}
              startIcon={<Save />}
              loading={isCreatingTournamentTeam}
              loadingPosition="start"
            >
              Save
            </LoadingButton>
            <Button variant="outlined" fullWidth onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
