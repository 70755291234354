import { useQuery } from "@tanstack/react-query";
import { getAwards } from "../../../../api/tournaments";

export default function useFetchAwards() {
  return useQuery({
    queryKey: ["awards"],
    queryFn: () => {
      return getAwards();
    },
    enabled: true,
    retry: false,
  });
}
