import {
  patchCollection,
  postCollection,
} from "../../../../../../../../../api/points";
import { Collection } from "../../../../../../../../../api/points/types/Collection";

export const createCollectionMethod = async (
  props: Collection,
  newCollection: boolean
) => {
  if (newCollection) {
    await postCollection(props);
    return;
  }

  // Create Collection
  await patchCollection(props.type, props.collectionId, props);
};
