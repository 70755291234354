import { ReactNode, useEffect, useState } from "react";

import { PointsProduct } from "../../../api/points/schemas";
import PointsContext, { PointsContextProps } from "./PointsContext";

export const PointsProvider = ({ children }: { children: ReactNode }) => {
  const [type, setType] = useState<PointsProduct["type"]>("default");
  const [raffleTicketProductId, setRaffleTicketProductId] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>("");
  const [action, setAction] = useState<string>("NONE");
  const [price, setPrice] = useState<number>(0);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [id, setId] = useState<string>("");
  const [product, setProduct] = useState<PointsProduct | null>(null);
  const [isEditCreateOpen, setIsEditCreateOpen] = useState<boolean>(false);
  const [allowedRedemptions, setAllowedRedemptions] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [collectionId, setCollectionId] = useState<string | undefined>(undefined);

  const [isRedeemableOnlyOnce, setIsRedeemableOnlyOnce] = useState<boolean>(false);

  const [isHidden, setIsHidden] = useState<boolean>(false);

  const [isRedeemableFromChat, setIsRedeemableFromChat] = useState<boolean>(false);
  const [cooldown, setCooldown] = useState<number | undefined>(undefined);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    if (action !== "REDEEM_CASE" && collectionId) {
      setCollectionId(undefined);
    }
  }, [action, collectionId]);

  const clear = () => {
    setType("default");
    setRaffleTicketProductId(undefined);
    setName("");
    setAction("NONE");
    setPrice(0);
    setIsPublished(false);
    setFile(null);
    setId("");
    setDescription("");
    setCooldown(undefined);
    setAllowedRedemptions(0);
    setIsRedeemableFromChat(false);
    setProduct(null);
    setIsHidden(false);
    setIsRedeemableOnlyOnce(false);
    setCollectionId(undefined);
  };

  const generateId = () => {
    const id = crypto.randomUUID();
    setId(id);
  };

  const handleClose = () => {
    setIsEditCreateOpen(false);
    clear();
  };

  const createNew = () => {
    setIsEditCreateOpen(true);
    generateId();
    setIsEdit(false);
  };

  const editExisting = (product: PointsProduct) => {
    setType(product.type);
    setRaffleTicketProductId(product.raffleTicketProductId);
    setId(product.id);
    setName(product.name);
    setAction(product.action);
    setPrice(product.price);
    setIsPublished(product.isPublished);
    setCooldown(product.cooldown);
    setIsRedeemableFromChat(product.isRedeemableFromChat);
    setIsEditCreateOpen(true);
    setIsEdit(true);
    setAllowedRedemptions(product.allowedRedemptions || 0);
    setDescription(product.description || "");
    setProduct(product);
    setIsHidden(product.isHidden || false);
    setIsRedeemableOnlyOnce(product.isRedeemableOnlyOnce || false);
    setCollectionId(product.collectionId || undefined);
  };

  const value: PointsContextProps = {
    productData: {
      type,
      name,
      action,
      price,
      isPublished,
      isHidden,
      id,
      isRedeemableFromChat,
      cooldown,
      allowedRedemptions,
      description,
      product,
      isRedeemableOnlyOnce,
      collectionId,
      raffleTicketProductId,
    },
    productMethods: {
      setType,
      setName,
      setAction,
      setPrice,
      setIsPublished,
      setIsHidden,
      setCooldown,
      setIsRedeemableFromChat,
      setId,
      setAllowedRedemptions,
      setDescription,
      setIsRedeemableOnlyOnce,
      setCollectionId,
      setRaffleTicketProductId,
    },
    editCreateModalData: { isOpen: isEditCreateOpen, isEdit },
    editCreateModalMethods: { handleClose, createNew, editExisting },
    imageData: { file },
    imageMethods: { setFile },
  };

  return <PointsContext.Provider value={value}>{children}</PointsContext.Provider>;
};

export default PointsProvider;
