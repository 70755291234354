import { useState } from "react";
import { DateTime } from "luxon";
import classNames from "classnames";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Info from "@mui/icons-material/Info";
import Refresh from "@mui/icons-material/Refresh";

import PageContainer from "../../components/Layout/PageContainer";
import { DateInput } from "../../components/DateInput";
import { AuditLogDetails } from "./components/AuditLogDetails";

import useFetchAuditlogs from "./hooks/useFetchAuditlogs";

import { AuditLogRecord } from "../../types/Auditlogs";

export function Auditlogs() {
  const [selectedLog, setSelectedLog] = useState<null | AuditLogRecord>(null);
  const [page, setPage] = useState(0);
  const [creationDateFilter, setCreationDateFilter] = useState(() =>
    DateTime.now(),
  );
  const [userIdFilter, setUserIdFilter] = useState<undefined | string>(
    undefined,
  );
  const ROWS_PER_PAGE = 10;
  const {
    data: response,
    refetch,
    isRefetching,
    hasNextPage,
    fetchNextPage,
  } = useFetchAuditlogs({
    limit: ROWS_PER_PAGE,
    creationDate: !userIdFilter
      ? creationDateFilter.toFormat("yyyy-MM-dd")
      : undefined,
    userId: userIdFilter,
  });

  const setCreationDate = (date: DateTime) => {
    setPage(0);
    setCreationDateFilter(date);
  };

  const setUserId = (userId: undefined | string) => {
    setPage(0);
    setUserIdFilter(userId);
  };

  return (
    <PageContainer>
      <div className="flex justify-between items-center gap-5">
        <Button
          startIcon={
            <Refresh
              className={classNames({
                "animate-spin": isRefetching,
              })}
            />
          }
          onClick={() => void refetch()}
        >
          Refresh
        </Button>
        <div>
          <Typography variant="h6" align="center" mb={1}>
            Filter by
          </Typography>
          <div className="flex gap-4 items-center">
            <DateInput
              label="Creation Date"
              className="w-56"
              onChange={(e) => {
                if (e) {
                  setCreationDate(e);
                }
              }}
              value={creationDateFilter}
              format="dd/LL/yyyy"
            />
            <Typography variant="subtitle2" align="center">
              Or
            </Typography>
            <TextField
              id="outlined"
              label="User id"
              className="w-56"
              value={userIdFilter}
              onChange={(e) => {
                setUserId(e.target.value.trim() || undefined);
              }}
            />
          </div>
        </div>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User id</TableCell>
              <TableCell>Occurred at</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Action</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {response?.pages[page]?.logs.map((log) => (
              <TableRow key={log.occurredAt + log.userId + log.action}>
                <TableCell>{log.userId}</TableCell>
                <TableCell>
                  {DateTime.fromISO(log.occurredAt).toFormat(
                    "dd-MM-yyyy HH:mm:ss",
                  )}
                </TableCell>
                <TableCell>{log.service}</TableCell>
                <TableCell>{log.action}</TableCell>
                <TableCell>
                  <Tooltip title="Log details">
                    <IconButton onClick={() => setSelectedLog(log)}>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                count={
                  hasNextPage
                    ? -1
                    : (response?.pages.length || 0) * ROWS_PER_PAGE
                }
                rowsPerPage={ROWS_PER_PAGE}
                page={page}
                onPageChange={(e, page) => {
                  if (
                    hasNextPage &&
                    response?.pages &&
                    page >= response.pages.length
                  ) {
                    void fetchNextPage();
                  }

                  setPage(page);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {!!selectedLog && (
        <AuditLogDetails
          onClose={() => setSelectedLog(null)}
          log={selectedLog}
        />
      )}
    </PageContainer>
  );
}
