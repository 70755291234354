import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postRlcsStatTeam } from "..";
import { rlcsStatQueryKeys } from "../../rlcsStatQueryKeys";

export function usePostRlcsStatTeam() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postRlcsStatTeam,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rlcsStatQueryKeys.teams,
      });
    },
  });
}
