import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { DotlePlayers } from "./components/DotlePlayers";
import { useGetDotlePlayers } from "../../api/data/dotle/getDotlePlayers/useGetDotlePlayers";

/**
 * Currently available on the `TOURNAMENTS_MANAGEMENT` permission, since we are showing the list of players.
 * Add specific permissions if there is functionality added later on specific to Dotle.
 */
export const DotlePage = () => {
  const { data: players, isLoading, isError } = useGetDotlePlayers();

  return (
    <>
      <div className="flex w-full flex-col p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <span> Dotle </span>
        </Breadcrumbs>
        <DotlePlayers players={players ?? []} isLoading={isLoading} isError={isError} />
      </div>
    </>
  );
};
