import z from "zod";

export const DotaLeagueSchema = z.object({
  name: z.string(),
  tier: z.number(),
  league_id: z.number(),
  start_timestamp: z.number(),
  end_timestamp: z.number(),
  status: z.number(),
  region: z.number(),
  total_prize_pool: z.number(),
});

export type DotaLeague = z.infer<typeof DotaLeagueSchema>;
