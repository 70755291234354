import { useQuery } from "@tanstack/react-query";
import { getTournamentById } from "../../../api/community-tournaments";

export default function useFetchTournamentById(tournamentId?: string) {
  return useQuery({
    queryKey: ["tournament", tournamentId],
    queryFn: () => {
      return tournamentId ? getTournamentById(tournamentId) : undefined;
    },
    enabled: true,
    retry: false,
  });
}
