import { z } from "zod";
import { DbTeamSchema } from "./teams";
import { DbPlayerSchema } from "./players";

export const DbMapSchema = z.object({
  id: z.string().min(1),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
  name: z.string(),
  scheduledAt: z.string().datetime(),
  startedAt: z.string().datetime().nullable(),
  endedAt: z.string().datetime().nullable(),
  matchId: z.string(),
});

export type DbMap = z.infer<typeof DbMapSchema>;

export const DbTeamMapSchema = z.object({
  teamId: z.string(),
  mapId: z.string(),
  initialSide: z.enum(["CT", "T"]).nullable(),
  score: z.coerce.number().int().nullable(),
});

export type DbTeamMap = z.infer<typeof DbTeamMapSchema>;

export const PlayerMapStatusSchema = z.enum(["READY", "NOT_READY"]); // Used to check players status on the map (ready or not, etc...)

export type PlayerMapStatus = z.infer<typeof PlayerMapStatusSchema>;

export const DbTeamPlayerMapSchema = z.object({
  playerId: z.string().min(1),
  teamId: z.string().min(1),
  mapId: z.string().min(1),
  status: PlayerMapStatusSchema,
});

export type DbTeamPlayerMap = z.infer<typeof DbTeamPlayerMapSchema>;

export const MapTeamWithPlayersSchema = DbTeamSchema.extend({
  score: z.coerce.number().int().nullable(),
  players: z.array(
    DbPlayerSchema.omit({
      createdAt: true,
      updatedAt: true,
      deletedAt: true,
    }).extend({
      status: PlayerMapStatusSchema,
      ingameId: z.string().nullish(),
    }),
  ),
});

export type MapTeamWithPlayers = z.infer<typeof MapTeamWithPlayersSchema>;

export const MapCreateRequestSchema = z.object({
  name: z.string(),
  scheduledAt: z.string().datetime(),
  matchId: z.string(),
});

export const MapResponseSchema = DbMapSchema.omit({
  deletedAt: true,
});

export const UpdateMapRequestSchema = z
  .object({
    name: z.string(),
    scheduledAt: z.string().datetime(),
    startedAt: z.string(),
    endedAt: z.string(),
  })
  .partial();

export const UpdateMapScoreRequestSchema = z.object({
  scores: z
    .object({
      teamId: z.string(),
      score: z.number().int().min(0),
    })
    .array()
    .min(1),
});

export const UpdateMapScoreResponseSchema =
  UpdateMapScoreRequestSchema.shape.scores;
