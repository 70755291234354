export function differenceInObject<T>({
  original,
  changed,
}: {
  original: T;
  changed: T;
}) {
  const difference: Partial<T> = Object.keys(changed as object).reduce(
    (diff, key) => {
      if (original[key as keyof T] === changed[key as keyof T]) return diff;
      return {
        ...diff,
        [key]: changed[key as keyof T],
      };
    },
    {}
  );

  return difference;
}
