import { Modal } from "@blastorg/portal-pattern-library";
import { Event } from "../../../../../api/metricsViewership/schemas/EventSchema";
import { Button } from "@mui/material";
import { useUpdateEvent } from "../../../../../api/metricsViewership/hooks/useUpdateEvent";
import { Star, StarOutline } from "@mui/icons-material";
import { Channel } from "../../../../../api/metricsViewership/schemas/ChannelSchema";
import { LoadingButton } from "@mui/lab";
import { PlatformIcon } from "@/routes/MetricsViewership/routes/shared/components/PlatformIcon";

export const PrimaryChannelModal = ({
  isOpen,
  onClose,
  event,
  channel,
  isCurrentlyPrimary,
}: {
  isOpen: boolean;
  onClose: () => void;
  event: Event;
  channel: Channel;
  isCurrentlyPrimary: boolean;
}) => {
  const { mutate: updateEvent, isLoading: isSubmitting } = useUpdateEvent(event.id);

  const onSubmit = () => {
    updateEvent(
      {
        channels: event.channels.map((c) => ({
          ...c,
          isPrimary: c.id === channel.id ? !isCurrentlyPrimary : c.isPrimary,
        })),
      },
      {
        onSuccess() {
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="flex w-full max-w-xl flex-col items-start gap-8 bg-gray-800 p-8 text-left md:w-full"
    >
      <h1 className="text-xl">
        {isCurrentlyPrimary ? "Remove primary channel" : "Set primary channel"} for {event.name}?
      </h1>
      <p className="text-sm">
        Primary channels will be used to determine the correct timeline for the event. Data on all channels linked to
        the event will be collected when the at least one of the primary channels are live.
      </p>
      <div className="flex items-center gap-4">
        <PlatformIcon platform={channel.channelSource} />
        <div className="font-medium">{channel?.channelName}</div>
      </div>
      <div className="flex w-full items-center justify-end gap-2">
        <Button onClick={onClose} variant="outlined" disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton
          loading={isSubmitting}
          loadingPosition="start"
          onClick={onSubmit}
          variant="contained"
          startIcon={isCurrentlyPrimary ? <StarOutline /> : <Star />}
          color={isCurrentlyPrimary ? "secondary" : "primary"}
        >
          {isCurrentlyPrimary ? "Remove from primary channels" : "Set as primary channel"}
        </LoadingButton>
      </div>
    </Modal>
  );
};
