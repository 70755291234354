import { useMemo } from "react";
import { useFetchDotaLeagues } from "../../../../hooks/dota/useFetchDotaLeagues";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

type DotaTournamentsAutocompleteProps = {
  selectedTournament: string | null | undefined;
  setSelectedTournament: (value: string | null) => void;
};
export const DotaTournamentsAutocomplete = ({
  selectedTournament,
  setSelectedTournament,
}: DotaTournamentsAutocompleteProps) => {
  const { data: dotaTournaments, isLoading } = useFetchDotaLeagues({
    tier: 2,
  });
  const transformedTournaments = useMemo(() => {
    if (!dotaTournaments) return [];

    return dotaTournaments
      .map((tournament) => ({
        ...tournament,
        label: `(${tournament.league_id}) ${tournament.name}`,
        year: new Date(tournament.start_timestamp * 1000).getFullYear(),
      }))
      .sort((a, b) => {
        if (b.year !== a.year) {
          return b.year - a.year;
        }
        return a.name.localeCompare(b.name);
      });
  }, [dotaTournaments]);

  const selected = useMemo(() => {
    if (!selectedTournament) return null;

    const selected = dotaTournaments?.find((tournament) => tournament.league_id === parseInt(selectedTournament));

    if (!selected) return null;

    return {
      ...selected,
      label: `(${selected?.league_id}) ${selected?.name}`,
      year: new Date(selected?.start_timestamp * 1000).getFullYear(),
    };
  }, [selectedTournament, dotaTournaments]);

  return (
    <div className="relative">
      {isLoading && <CircularProgress className="absolute inset-0 m-auto" />}
      <Autocomplete
        className="w-full"
        disabled={isLoading}
        id="dota-tournament-selector"
        options={transformedTournaments}
        autoHighlight
        sx={{ width: 300 }}
        value={selected ?? null}
        onChange={(_, e) => setSelectedTournament(e ? e?.league_id?.toString() ?? null : null)}
        groupBy={(option) => option.year.toString()}
        isOptionEqualToValue={(option, value) => option.league_id === value.league_id}
        renderInput={(params) => <TextField {...params} label="Tournament" />}
      />
    </div>
  );
};
