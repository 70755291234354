import { z } from "zod";
import { DbPlayerSchema } from "./players";

export const DbTeamSchema = z.object({
  id: z.string(),
  name: z.string(),
  gameId: z.string(),
  joinCode: z.string(),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
});

export type DbTeam = z.infer<typeof DbTeamSchema>;

export const DbTeamPlayerSchema = z.object({
  teamId: z.string(),
  playerId: z.string(),
});

export type DbTeamPlayer = z.infer<typeof DbTeamPlayerSchema>;

export const DbTeamWithPlayersSchema = DbTeamSchema.extend({
  players: DbPlayerSchema.omit({
    createdAt: true,
    deletedAt: true,
    updatedAt: true,
  }).array(),
});

export type DbTeamWithPlayers = z.infer<typeof DbTeamWithPlayersSchema>;

const TeamNameSchema = z
  .string()
  .trim()
  .regex(/^(?!.*\s{2})/, "Team name can't contain multiple spaces")
  .min(5)
  .max(20)
  .regex(
    /^(?=[a-zA-Z0-9\s_-]*$)/,
    "Team name can only contain alphanumeric characters, dashes, underscores and spaces",
  );

export const TeamCreateRequestSchema = z.object({
  name: TeamNameSchema,
  gameId: z.string().min(1),
});

export const TeamResponseSchema = DbTeamSchema.omit({
  deletedAt: true,
  createdAt: true,
  updatedAt: true,
});

export const TeamWithPlayesResponseSchema = DbTeamWithPlayersSchema.omit({
  deletedAt: true,
  createdAt: true,
  updatedAt: true,
});

export const UpdateTeamRequestSchema = z.object({
  name: TeamNameSchema.optional(),
});

export const TeamPlayerResponseSchema = DbTeamPlayerSchema;

export const ListTeamPlayersResponseSchema = DbPlayerSchema.omit({
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
}).array();

export const JoinTeamRequestSchema = DbTeamPlayerSchema.pick({
  playerId: true,
}).extend({
  joinCode: z.string().min(1),
});
