import { z } from "zod";

export const ChannelSourceSchema = z.enum(["twitch", "youtube", "tiktok", "kick", "blasttv"]);

export const ChannelSchema = z.object({
  id: z.string(),
  channelSource: ChannelSourceSchema,
  channelName: z.string(),
  // exclude this channel's data from reports
  excluded: z.boolean().default(false),
  streams: z
    .object({
      id: z.string(),
      title: z.string().optional(),
    })
    .array()
    .optional(),
});

export type Channel = z.infer<typeof ChannelSchema>;
export type ChannelSource = z.infer<typeof ChannelSourceSchema>;
