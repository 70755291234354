import { Box } from "@mui/system";
import PageContainer from "../../components/Layout/PageContainer";
import { Button, Tab, Tabs, TextField } from "@mui/material";
import { useState } from "react";
import { ClipCuratorEventWithMatchDetails } from "../../api/video/schemas";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SelectedClip from "./components/SelectedClip";
import useAlert from "../../providers/AlertProvider/hooks/useAlert";
import useCreateClipsCuratorOperation from "./hooks/useCreateClipsCuratorOperation";
import { useNavigate } from "react-router-dom";
import { useGetTournaments } from "../../api/tournaments/getTournaments/useGetTournaments";
import { ClipSearch } from "./components/ClipSearch";
import useFetchTeams from "../Tournaments/hooks/teams/useFetchTeams";

const ClipCurator = () => {
  const [selectedTab, setSelectedTab] = useState<"TOURNAMENT" | "TIMELINE_EVENT" | "PLAYER" | "TEAM">("TOURNAMENT");
  const { data: teams } = useFetchTeams("cs");
  // TODO: Implement the ability to add a requester email
  const [requesterEmail, setRequesterEmail] = useState<string>("");
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: "TOURNAMENT" | "TIMELINE_EVENT" | "PLAYER" | "TEAM") => {
    setSelectedTab(newValue);
  };

  const alert = useAlert();
  const { mutate: createClipsCuratorOperation } = useCreateClipsCuratorOperation();
  const { data: tournaments } = useGetTournaments({ game: "cs" });

  const archiveClips = (type: "archive" | "combine") => {
    let options = [];

    switch (type) {
      case "archive":
        options = selectedClips.map((clip) => {
          return {
            name: `${clip.eventType}_${new Date(clip.date).toLocaleDateString().replace(/\//g, "-")}_MAP_${clip.mapNumber}_ROUND_${clip.roundNumber}_${teams?.find((team) => team.id == clip.teamIds[0])?.name}_vs_${teams?.find((team) => team.id == clip.teamIds[1])?.name}.mp4`,
            url: clip.url,
          };
        });
        break;
      case "combine":
        options = selectedClips.map((clip) => clip.url);
        break;
    }

    createClipsCuratorOperation(
      {
        operationType: type === "archive" ? "EXPORT_CLIPS" : "COMBINE_CLIPS",
        requesterEmail: requesterEmail.length > 0 ? requesterEmail : undefined,
        operationOptions: options,
      },
      {
        onError: () => alert.showFailureAlert("Failed to create operation request"),
        onSuccess: () => {
          alert.showSuccessAlert("Successfully created operation request");
          navigate(`/clip-curator/operations/`);
        },
      },
    );
  };

  const removeClip = (clipId: string) => {
    setSelectedClips((prev) => prev.filter((clip) => clip.id !== clipId));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!!over?.id && active.id !== over.id) {
      setSelectedClips((items) => {
        const oldIndex = items.findIndex((clip) => clip.id === active.id);
        const newIndex = items.findIndex((clip) => clip.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const [selectedClips, setSelectedClips] = useState<ClipCuratorEventWithMatchDetails[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <PageContainer className="grid h-screen max-h-screen grid-cols-3 divide-x divide-gray-700 py-0 pr-0">
        <div className="col-span-2 flex h-full flex-col justify-between">
          <div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="flex flex-row">
                <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="tournament" value="TOURNAMENT" />
                  <Tab label="timeline event" value="TIMELINE_EVENT" />
                  <Tab label="player" value="PLAYER" />
                  <Tab label="team" value="TEAM" />
                </Tabs>
                <Button
                  className="my-2 ml-auto mr-2"
                  variant="contained"
                  onClick={() => {
                    navigate("/clip-curator/operations");
                  }}
                >
                  Exports overview
                </Button>
              </div>
            </Box>
            <div role="tabpanel" id={`simple-tabpanel-${0}`} aria-labelledby={`simple-tab-${0}`}>
              <ClipSearch
                selectedClips={selectedClips}
                addClip={(eventClip) => setSelectedClips((prev) => [...prev, eventClip])}
                removeClip={(eventClip) =>
                  setSelectedClips((prev) => prev.filter((prevClip) => prevClip.id !== eventClip.id))
                }
                tournaments={tournaments || []}
                primaryFilter={selectedTab}
              />
            </div>
          </div>
        </div>
        <div className="flex max-h-screen w-full flex-col items-center gap-4">
          <span className="w-full border-b border-gray-700 py-3 text-center">
            Selected Clips ({selectedClips.length}):
          </span>
          <div className="flex w-full flex-auto flex-col gap-2 overflow-y-auto px-4">
            <SortableContext items={selectedClips} strategy={verticalListSortingStrategy}>
              {selectedClips.map((selectedClip) => (
                <SelectedClip
                  id={selectedClip.id}
                  selectedClip={selectedClip}
                  key={selectedClip.id}
                  removeClip={removeClip}
                />
              ))}
            </SortableContext>
          </div>
          <div className="flex w-full flex-col gap-y-2 p-2">
            <TextField
              label="Optional email notification when completed"
              type="email"
              autoComplete="email"
              value={requesterEmail}
              onChange={(e) => setRequesterEmail(e.target.value)}
            />
            <div className="flex flex-row items-center gap-x-2">
              <span>Export</span>
              <Button variant="contained" onClick={() => archiveClips("archive")}>
                Individually
              </Button>
              <Button variant="contained" onClick={() => archiveClips("combine")}>
                Combined
              </Button>
            </div>
          </div>
        </div>
      </PageContainer>
    </DndContext>
  );
};

export default ClipCurator;
