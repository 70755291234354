import Box from "@mui/material/Box";

import TriggerPolls from "./TriggerPolls";

export default function Streams() {
  return (
    <div className="">
      <Box className="mt-5">
        <TriggerPolls />
      </Box>
    </div>
  );
}
