import { useQuery } from "@tanstack/react-query";
import { getTournaments } from "../../../api/community-tournaments";

export default function useFetchTournaments() {
  return useQuery({
    queryKey: ["communityTournaments"],
    queryFn: () => {
      return getTournaments();
    },
    enabled: true,
    retry: false,
  });
}
