import { Button, ListSubheaderProps, ListSubheader, Card, AppBar, Toolbar, Stack, TextField } from "@mui/material";
import { ChangeEvent, useMemo, useState } from "react";
import useFetchCircuits from "../hooks/useFetchCircuits";
import { uniqueId } from "lodash-es";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CreateAndEditTournamentModal from "./components/CreateEditTournamentModal";
import { Event, Group, Paid, Today } from "@mui/icons-material";
import { useGetTournaments } from "../../../api/tournaments/getTournaments/useGetTournaments";
import FilterChips from "../shared/components/FilterChips";

function MyListSubheader(props: ListSubheaderProps) {
  return <ListSubheader {...props} />;
}

MyListSubheader.muiSkipListHighlight = true;

const TournamentsPage = () => {
  const { gameId, tournamentId } = useParams();
  const [searchParams] = useSearchParams();

  const [editingTournamentId, setEditingTournamentId] = useState<string | undefined>(tournamentId);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(!!editingTournamentId);
  const [filterValue, setFilterValue] = useState<string | null>(null);
  const [circuitId, setCircuitId] = useState<string | undefined>(searchParams.get("circuitId") || undefined);

  const { data: allTournaments } = useGetTournaments({ game: gameId });
  const { data: circuits } = useFetchCircuits(gameId);
  const navigate = useNavigate();

  const shownTournaments = useMemo(() => {
    if (allTournaments) {
      if (circuitId && filterValue) {
        return allTournaments.filter(
          (tournament) =>
            tournament.circuitId === circuitId && tournament.name.toLowerCase().includes(filterValue.toLowerCase()),
        );
      }
      if (filterValue) {
        return allTournaments.filter((tournament) => tournament.name.toLowerCase().includes(filterValue.toLowerCase()));
      }
      if (circuitId) {
        return allTournaments.filter((tournament) => tournament.circuitId === circuitId);
      }
      return allTournaments;
    } else {
      return [];
    }
  }, [circuitId, allTournaments, filterValue]);

  const shownCircuits = useMemo(() => {
    if (gameId && circuits) {
      return circuits.filter((circuit) => circuit.gameId === gameId);
    } else {
      return [];
    }
  }, [gameId, circuits]);

  function handleFilterChange(event: ChangeEvent<HTMLInputElement>) {
    const filterValue = event.target?.value;
    if (!filterValue) {
      setFilterValue(null);
    } else {
      setFilterValue(filterValue);
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col gap-6 p-7">
        <h1 className="font-style-desktop-h2">Tournaments</h1>
        <div className="flex flex-col gap-4">
          {gameId ? (
            <FilterChips
              label="Select Circuit"
              onClick={(circuitId) => setCircuitId(circuitId)}
              initialFilter={circuitId || ""}
              chips={shownCircuits?.map((circuit) => ({
                label: circuit.name,
                id: circuit.id,
              }))}
              allChip={true}
              onAllClick={() => setCircuitId(undefined)}
            />
          ) : (
            <div className="flex flex-col">
              <span className="pb-1 pl-1 font-style-label-3">Select Circuit </span>
              <span className="pl-1 opacity-50 font-style-label-4">Select a game to be able to select a circuit</span>
            </div>
          )}
        </div>

        <AppBar position="sticky">
          <Toolbar variant="regular" disableGutters>
            <Stack direction="row" sx={{ width: "100%" }} justifyContent="space-between">
              <div className="flex items-center gap-3">
                <TextField variant="outlined" label="Search" sx={{ m: 1 }} onChange={handleFilterChange} />
              </div>

              <Button variant="outlined" sx={{ m: 2 }} onClick={() => setIsAddEditModalOpen(true)}>
                Add Tournament
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>

        <div className="flex flex-wrap gap-4">
          {shownTournaments?.map((tournament) => (
            <Card className="flex w-80 grow-0 flex-col rounded-small p-3" key={tournament.name + uniqueId()}>
              <div className="flex flex-col gap-4 font-style-b2-body-copy">
                <label className="font-style-label-1">{tournament.name}</label>
                <div className="grid grid-cols-2 gap-2 opacity-70">
                  <div className="flex items-center gap-2">
                    <Today />
                    <label>{tournament.startDate.toISOString().split("T")[0]}</label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Event />
                    <label>{tournament.endDate.toISOString().split("T")[0]}</label>
                  </div>
                  {tournament.numberOfTeams && (
                    <div className="flex items-center gap-2">
                      <Group />
                      <label>{tournament.numberOfTeams}</label>
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <Paid />
                    <label>{tournament.prizePool ?? "-"}</label>
                  </div>
                </div>
              </div>
              <hr className="my-3 w-full self-center border-solid border-slate-400" />
              <div>
                <Button onClick={() => void navigate(`/to/tournaments/${gameId}/${tournament.id}`)}>
                  Go to Tournament
                </Button>
                <Button
                  onClick={() => {
                    setEditingTournamentId(tournament.id);
                    setIsAddEditModalOpen(true);
                  }}
                >
                  Edit
                </Button>
              </div>
            </Card>
          ))}
        </div>
        {isAddEditModalOpen && (
          <CreateAndEditTournamentModal
            isOpen={isAddEditModalOpen}
            editingTournamentId={editingTournamentId}
            selectedCircuitId={circuitId}
            onClose={() => {
              setIsAddEditModalOpen(false);
              setEditingTournamentId(undefined);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TournamentsPage;
