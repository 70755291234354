import { blastApiClient } from "../../clients/blastApiClient";
import { QuestionSchema } from "../shared/types";

interface PostCsQuizQuestionBody {
  id: string;
  text: string;
  answers: string[];
  correctAnswer: string;
  image?: string;
}

export const postCsQuizQuestion = async (body: PostCsQuizQuestionBody) => {
  const { data } = await blastApiClient.post<unknown>("/v1/cs-quiz/questions", body);
  return QuestionSchema.parse(data);
};
