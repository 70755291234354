import { Breadcrumbs, Button, CircularProgress, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useGetIssuesByEntityId } from "../../../../../../api/data/issues/getIssuesByEntityId/useGetIssuesByEntityId";
import { useMemo } from "react";
import { IssueEntityLinkButton } from "../../components/IssueEntityLinkButton";
import { ContentCopy } from "@mui/icons-material";
import { DateTime } from "luxon";

export const EntityDetailsPage = () => {
  const { entity, entityId, errorKey, tournamentId } = useParams();

  const { data, isLoading } = useGetIssuesByEntityId(entityId && tournamentId ? { entityId, tournamentId } : undefined);
  const issue = useMemo(() => data?.find((issue) => issue.errorKey === errorKey), [data, errorKey]);

  const bodyContent = useMemo(() => {
    if (!issue) return null;

    return JSON.stringify(typeof issue.body === "string" ? JSON.parse(issue.body) : issue.body, null, 2);
  }, [issue]);

  return (
    <div className="flex w-full flex-col p-8">
      <Breadcrumbs aria-label="breadcrumb" className="mb-8">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/issues/tournaments">
          Issues
        </Link>
        <Link color="inherit" to="/issues/tournaments">
          Tournaments
        </Link>
        <span>{tournamentId}</span>
        <span>{entity}</span>
        <span>{entityId}</span>
        <span>{errorKey}</span>
      </Breadcrumbs>
      {isLoading ? (
        <CircularProgress />
      ) : !issue ? (
        <div>No issue found</div>
      ) : (
        <Table className="w-fit max-w-full">
          <TableBody>
            <TableRow>
              <TableCell>Created At</TableCell>
              <TableCell>{DateTime.fromISO(issue.createdAt).toUTC().toFormat("yyyy-MM-dd HH:mm ZZZZ")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Error Key</TableCell>
              <TableCell>{issue.errorKey}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Entity</TableCell>
              <TableCell>
                {issue.entity}: <IssueEntityLinkButton {...issue} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>{issue.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>External ID</TableCell>
              <TableCell>{issue.entityExternalId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="align-top">Body</TableCell>
              <TableCell>
                <div className="flex items-start gap-2">
                  <pre className="max-h-[480px] overflow-y-auto text-xs">{bodyContent}</pre>
                  {bodyContent && (
                    <Button
                      size="small"
                      variant="text"
                      endIcon={<ContentCopy fontSize="small" />}
                      onClick={() => void navigator.clipboard.writeText(bodyContent)}
                    >
                      Copy
                    </Button>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
};
