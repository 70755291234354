import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTournamentTeam } from "..";
import { queryKeys } from "../shared/queryKeys";

export const useCreateTournamentTeam = ({ tournamentId }: { tournamentId: string }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.tournamentTeams(tournamentId),
    mutationFn: createTournamentTeam,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.tournamentTeams(tournamentId) });
    },
  });
};
