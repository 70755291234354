export const CS_GUESSR_MAPS = [
  {
    id: "de_mirage",
    name: "Mirage",
  },
  {
    id: "de_anubis",
    name: "Anubis",
  },
  {
    id: "de_ancient",
    name: "Ancient",
  },
  {
    id: "de_overpass",
    name: "Overpass",
  },
  {
    id: "de_vertigo",
    name: "Vertigo",
  },
  {
    id: "de_vertigo_lower",
    name: "Vertigo Lower",
  },
  {
    id: "de_nuke",
    name: "Nuke",
  },
  {
    id: "de_nuke_lower",
    name: "Nuke Lower",
  },
  {
    id: "de_inferno",
    name: "Inferno",
  },
  {
    id: "de_dust2",
    name: "Dust 2",
  },
];
