import { useMutation } from "@tanstack/react-query";

import { regenerateMapsData } from "../../../../api/tournaments";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

export const useRegenerateMapsData = () => {
  const alert = useAlert();

  return useMutation({
    mutationFn: (matchId: string) => regenerateMapsData(matchId),
    onSuccess() {
      alert.showSuccessAlert(
        "Maps data was regenerated requested successfully. Please wait a few seconds for the data to be updated.",
      );
    },
  });
};
