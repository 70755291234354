import axios from "axios";
import { createUploadLink } from "../../../../api/assets";

export const uploadPlayerImage = async ({
  image,
  playerId,
}: {
  image: File;
  playerId: string;
}): Promise<boolean> => {
  const fileName = `${playerId}`;
  let uploadUrl = "";
  try {
    const { uploadUrl: createdUploadUrl } = await createUploadLink(
      "players",
      fileName
    );
    uploadUrl = createdUploadUrl;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error create player image upload link: ", error);
    return false;
  }

  try {
    await axios.put(uploadUrl, image, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Length": image.size,
        "Content-Type": image.type,
      },
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error uploading player image: ", error);
    return false;
  }
};
