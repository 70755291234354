import { Alert, Button, CircularProgress, Container, Grid, Snackbar, Stack, Typography } from "@mui/material";
import { AxiosError, isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { useFetchBroadcasts } from "../../../hooks";
import { usePatron } from "../../../providers/PatronProvider/hooks/usePatron";
import { Broadcast } from "../../../types/Broadcasts";
import CardItem from "./CardItem";
import { reorderBroadcastPriority } from "./helpers/reorderBroadcastPriority";
import useArchiveBroadcast from "./hooks/useArchiveBroadcast";
import { useStrictDroppable } from "./hooks/useStrictDroppable";
import useUpdateBroadcastPriority from "./hooks/useUpdateBroadcastPriority";
import { PatronSelect } from "@/components/PatronSelect";

const extractErrorMessage = (error: AxiosError | Error) =>
  isAxiosError(error) ? error.message : "Something went wrong.";

const BroadcastsList = () => {
  const { patron } = usePatron();
  const navigate = useNavigate();
  const {
    mutate: updateBroadcastPriorityAction,
    isLoading: isUpdatingPriority,
    isSuccess: isUpdateSuccess,
    error: updateBroadcastPriorityError,
  } = useUpdateBroadcastPriority();

  const { data: broadcasts, isLoading } = useFetchBroadcasts({ patron });

  const { mutate: archiveBroadcastAction, isSuccess: isArchiveSuccess, error: archiveError } = useArchiveBroadcast();

  const [liveBroadcasts, setLiveBroadcasts] = useState<Broadcast[]>([]);
  const [offlineBroadcasts, setOfflineBroadcasts] = useState<Broadcast[]>([]);
  const [successAlertOpened, setSuccessAlertOpened] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState("");
  const [errorAlertOpened, setErrorAlertOpened] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [droppableEnabled] = useStrictDroppable(isLoading);

  useEffect(() => {
    setLiveBroadcasts(
      broadcasts ? broadcasts.filter((broadcast) => broadcast.live).sort((a, b) => a.priority - b.priority) : [],
    );
    setOfflineBroadcasts(broadcasts ? broadcasts.filter((broadcast) => !broadcast.live) : []);
  }, [broadcasts]);

  // Error alerting
  useEffect(() => {
    if (updateBroadcastPriorityError) {
      setErrorAlertMessage(extractErrorMessage(updateBroadcastPriorityError as AxiosError));
      setErrorAlertOpened(true);
    }

    if (archiveError) {
      setErrorAlertMessage(extractErrorMessage(archiveError as AxiosError));
      setErrorAlertOpened(true);
    }
  }, [updateBroadcastPriorityError, archiveError]);

  useEffect(() => {
    if (isArchiveSuccess) {
      setSuccessAlertMessage("Archive successful");
      setSuccessAlertOpened(true);
    }
  }, [isArchiveSuccess]);

  useEffect(() => {
    if (!isUpdatingPriority && isUpdateSuccess) {
      setSuccessAlertMessage("Broadcasts priority updated successfully");
      setSuccessAlertOpened(true);
    }
  }, [isUpdatingPriority, isUpdateSuccess]);

  const onDragEnd: OnDragEndResponder = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    const newOrderedList = reorderBroadcastPriority(liveBroadcasts, source.index, destination.index);

    updateBroadcastPriorityAction({ patron, payload: newOrderedList });
    setLiveBroadcasts(newOrderedList);
  };

  return (
    <>
      <div className="flex w-full flex-col">
        <Container sx={{ my: 2 }}>
          <Stack spacing={8}>
            <Grid container justifyContent="space-between" sx={{ mb: 2.5 }}>
              <Grid item>
                <div className="flex items-center gap-4">
                  <h2 className="text-heading">Broadcasts</h2>
                  <PatronSelect />
                </div>
              </Grid>
              <Grid item display="flex" alignItems="center">
                <Button onClick={() => void navigate("create")} type="button" variant="contained">
                  Create New Broadcast
                </Button>
              </Grid>
            </Grid>

            <DragDropContext onDragEnd={onDragEnd}>
              {droppableEnabled && (
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <Stack spacing={2} ref={provided.innerRef} {...provided.droppableProps}>
                      <Grid item display="flex" alignItems="center">
                        <Typography variant="h5">Live</Typography>
                        {isUpdatingPriority && <CircularProgress size={20} sx={{ ml: 1 }} />}
                      </Grid>
                      {liveBroadcasts.map((broadcast, index) => (
                        <CardItem key={broadcast.id} broadcast={broadcast} index={index} draggable={true} />
                      ))}
                      {liveBroadcasts.length === 0 && (
                        <Typography variant="body1">No live broadcasts at the moment</Typography>
                      )}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              )}
            </DragDropContext>

            <Stack spacing={2}>
              <Grid item>
                <Typography variant="h5">Offline</Typography>
              </Grid>
              {offlineBroadcasts.map((broadcast) => (
                <CardItem key={broadcast.id} broadcast={broadcast} onArchive={archiveBroadcastAction} />
              ))}
            </Stack>
          </Stack>
          <Snackbar
            open={successAlertOpened}
            autoHideDuration={4000}
            onClose={() => setSuccessAlertOpened(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="success" variant="filled">
              {successAlertMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorAlertOpened}
            autoHideDuration={4000}
            onClose={() => setErrorAlertOpened(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="error" variant="filled">
              {errorAlertMessage}
            </Alert>
          </Snackbar>
        </Container>
      </div>
    </>
  );
};

export default BroadcastsList;
