import { useMutation } from "@tanstack/react-query";
import { createClipsCuratorOperation } from "../../../api/video";

interface UseCreateClipsCuratorOperationOptions {
  operationType: string;
  requesterEmail?: string;
  operationOptions: unknown;
}
const useCreateClipsCuratorOperation = () => {
  return useMutation({
    mutationFn: (options: UseCreateClipsCuratorOperationOptions) => createClipsCuratorOperation(options),
  });
};

export default useCreateClipsCuratorOperation;
