import { OpenInNew } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Issue } from "../../../../../api/data/issues/shared/schemas";
import { z } from "zod";

/**
 * Show the id of the entity in a button that links to the entity details page
 */
export const IssueEntityLinkButton = ({ entity, entityId, tournamentId, body }: Issue) => {
  const { title, href } = useMemo((): { title: string; href: string | null } => {
    if (entity === "match") {
      const bodyParsed = z
        .object({
          tournamentMatch: z.object({ id: z.string(), name: z.string(), stage: z.object({ name: z.string() }) }),
        })
        .safeParse(body && typeof body === "string" ? JSON.parse(body) : body);

      return {
        title: `${bodyParsed.data?.tournamentMatch.stage.name} - ${bodyParsed.data?.tournamentMatch.name}` ?? entityId,
        href: `/to/tournaments/${tournamentId}#${entityId}`,
      };
    }

    return { title: entityId, href: null };
  }, [entity, entityId, tournamentId, body]);

  if (!href) return <>{title}</>;

  return (
    <Button
      component={Link}
      to={href}
      target="_blank"
      size="small"
      color="inherit"
      endIcon={<OpenInNew fontSize="small" />}
      className="normal-case"
    >
      {title}
    </Button>
  );
};
