import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Tournament } from "../../../../../api/tournaments/schemas/tournaments";
import { MatchByTournamentIdResponse } from "../../../../../api/tournaments/schemas/matches";
import useFetchMatchesByTournamentId from "../../hooks/useFetchMatchByTournamentId";
import { useGetTournaments } from "../../../../../api/tournaments/getTournaments/useGetTournaments";

export function AddMatchDialog({ open, closeDialog }: { open: boolean; closeDialog: (matchId?: string) => void }) {
  const [selectedMatch, setSelectedMatch] = useState<string>("");
  const [selectedTournament, setSelectedTournament] = useState<string>("");

  const { data: tournaments, isLoading: isFetchingTournaments } = useGetTournaments();

  const {
    data: match,
    isLoading: isFetchingMatch,
    refetch: refetchMatch,
  } = useFetchMatchesByTournamentId(selectedTournament);

  useEffect(() => {
    if (selectedTournament !== "") {
      void refetchMatch();
    }
  }, [refetchMatch, selectedTournament]);

  return (
    <Dialog open={open} onClose={() => closeDialog()} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Add Match</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        {!isFetchingTournaments && (
          <Box display="flex" flexDirection="column" justifyContent="space-between" rowGap="15px">
            <FormControl fullWidth variant="filled">
              <Autocomplete
                id="select-tournament"
                options={tournaments || []}
                getOptionLabel={(option) => option.name}
                onChange={(_, newValue: Tournament | null) => {
                  setSelectedTournament(newValue?.id ?? "");
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Tournament" placeholder="Select / Search Tournament" />
                )}
              />
            </FormControl>
            <FormControl fullWidth variant="filled">
              <Autocomplete
                id="select-match"
                options={match || []}
                getOptionLabel={(option) => option.matchName}
                disabled={selectedTournament === "" || isFetchingMatch}
                onChange={(_, newValue: MatchByTournamentIdResponse | null) => {
                  setSelectedMatch(newValue?.matchId ?? "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={isFetchingMatch ? "Match (Loading...)" : "Match"}
                    placeholder="Select / Search Match"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.matchId}>
                    {option.matchName}
                  </li>
                )}
              />
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => closeDialog()}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!selectedMatch} onClick={() => closeDialog(selectedMatch)}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
