import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateEvent } from "..";

export const useUpdateEvent = (id?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: id ? (parameters: Parameters<typeof updateEvent>[1]) => updateEvent(id, parameters) : undefined,
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.event(id));
    },
  });
};
