import { Stream } from "@/api/pirate-streams/schemas";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import classNames from "classnames";
import { capitalize } from "lodash-es";
import { DateTime } from "luxon";
import { useState } from "react";
import { Link } from "react-router-dom";
import EditStreamStatusModal from "./EditStreamStatusModal";
import ViewTagsModal from "./ViewTagsModal";

const tableHeaders: {
  id: string;
  label: string;
  align?: "left" | "center" | "right";
}[] = [
  { id: "status", label: "Status" },
  { id: "thumbnail", label: "Thumbnail" },
  { id: "title", label: "Video Title" },
  { id: "channelTitle", label: "Channel" },
  { id: "reason", label: "Reason" },
  { id: "publishedAt", label: "Published" },
  { id: "tags", label: "Tags" },
  { id: "actions", label: "Actions", align: "center" },
];

export const StreamsTable = ({ streams }: { streams?: Stream[] }) => {
  const [streamToConfirm, setStreamToConfirm] = useState<Stream | null>(null);
  const [tagsToView, setTagsToView] = useState<string[]>([]);
  if (!streams) {
    return null;
  }

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            {tableHeaders.map((column) => (
              <TableHead
                key={column.id}
                className={classNames({
                  "text-center": column.align === "center",
                  "text-end": column.align === "left",
                })}
              >
                {column.label}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {streams.map((stream, i) => (
            <TableRow key={i}>
              <TableCell>
                <span
                  className={classNames("flex place-content-center rounded-small p-1 text-center text-xs", {
                    "bg-green-800 text-white": stream.status === "offline",
                    "animate-pulse bg-red-500 text-red-200": stream.status === "live",
                    "bg-yellow-600 text-white": stream.status === "reported",
                  })}
                >
                  {capitalize(stream.status)}
                </span>
              </TableCell>
              <TableCell>{stream.thumbnail ? <img className="h-12" src={stream.thumbnail} /> : "-"}</TableCell>
              <TableCell>
                {stream.title ? (
                  <Link target="_blank" className="underline" to={`https://www.youtube.com/watch?v=${stream.videoId}`}>
                    {stream.title}
                  </Link>
                ) : (
                  "-"
                )}
              </TableCell>

              <TableCell>
                {stream.channelTitle ? (
                  <Link
                    target="_blank"
                    className="flex items-center gap-2 underline"
                    to={`https://www.youtube.com/channel/${stream.channelId}`}
                  >
                    {stream.channelThumbnail ? <img className="h-7" src={stream.channelThumbnail} /> : ""}

                    {stream.channelTitle}
                  </Link>
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell>{stream.reason ?? "-"}</TableCell>
              <TableCell
                title={stream.publishedAt ? DateTime.fromISO(stream.publishedAt).toFormat("dd LLL yyyy HH:MM:ss") : "-"}
              >
                {stream.publishedAt ? DateTime.fromISO(stream.publishedAt).toLocal().toRelative() : "-"}
              </TableCell>

              <TableCell>
                <Button
                  variant="outline"
                  onClick={() => setTagsToView(stream.tags ?? [])}
                  disabled={!stream.tags?.length}
                >
                  <span className="line-clamp-1 max-w-32">{stream.tags?.length ? stream.tags?.join(" ") : "-"}</span>
                </Button>
              </TableCell>
              <TableCell>
                <Button variant="outline" onClick={() => setStreamToConfirm(stream)}>
                  Change
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditStreamStatusModal
        pirateStream={streamToConfirm}
        open={!!streamToConfirm}
        handleClose={() => setStreamToConfirm(null)}
      />
      <ViewTagsModal tags={tagsToView} open={tagsToView.length > 0} handleClose={() => setTagsToView([])} />
    </div>
  );
};
