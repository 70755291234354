import { MuiFileInput } from "mui-file-input";

interface ImageUploadFormProps {
  image: File | null;
  setImage: (file: File | null) => void;
}

export const ImageUploadForm = ({ image, setImage }: ImageUploadFormProps) => {
  return (
    <>
      <MuiFileInput
        style={{ marginBottom: "12px" }}
        sx={{ width: 375 }}
        placeholder="Select file"
        value={image}
        onChange={(newFile) => setImage(newFile)}
      />
    </>
  );
};
