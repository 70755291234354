import { Route, Routes } from "react-router-dom";

import ChatModeration from "./ChatModeration";
import ModerationList from "./ModerationList";

export default function Moderation() {
  return (
    <Routes>
      <Route index element={<ModerationList />} />
      <Route path=":patron/:id" element={<ChatModeration />} />
    </Routes>
  );
}
