import sanityClient from "@sanity/client";

import { environment, sanityDataset } from "../config";

export const client = sanityClient({
  projectId: "6znhzi10",
  dataset: sanityDataset,
  apiVersion: "2023-05-31",
  useCdn: environment === "production",
});
