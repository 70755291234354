import { PirateStreamStatus } from "@/api/pirate-streams/schemas";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import classNames from "classnames";
import { RefreshCcw } from "lucide-react";
import { useMemo, useState } from "react";
import { StreamsTable } from "./components/StreamsTable";
import { useFetchStreamsByStatus } from "./hooks/useFetchStreamsByStatus";

const Streams = ({ status }: { status: PirateStreamStatus }) => {
  const { data: streams, refetch, isRefetching } = useFetchStreamsByStatus({ status });
  const [search, setSearch] = useState("");
  const filteredStreams = useMemo(() => {
    return streams?.filter((stream) => stream.title.toLowerCase().includes(search.toLowerCase()));
  }, [streams, search]);

  const sortedStreams = useMemo(() => {
    return filteredStreams?.sort((a, b) => {
      const defaultDate = "1970-01-01T00:00:00Z";
      return (a.publishedAt ?? defaultDate) > (b.publishedAt ?? defaultDate) ? -1 : 1;
    });
  }, [filteredStreams]);

  return (
    <section className="flex flex-col gap-5 rounded bg-zinc-900/80 p-3 text-sm font-medium">
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex items-center gap-2 text-neutral-500">
          <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
        <Button variant="outline" onClick={() => void refetch()}>
          <RefreshCcw
            className={classNames({
              "animate-spin": isRefetching,
            })}
          />
          Refresh
        </Button>
      </div>
      <StreamsTable streams={sortedStreams} />
    </section>
  );
};

export const PirateStreams = () => {
  return (
    <Tabs defaultValue="live" className="w-full p-3">
      <div className="flex w-full justify-between">
        <div className="text-heading">Streams</div>
        <TabsList>
          <TabsTrigger value="live">Live Streams</TabsTrigger>
          <TabsTrigger value="reported">Reported</TabsTrigger>
          <TabsTrigger value="offline">Offline</TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value="live">
        <Streams status="live" />
      </TabsContent>
      <TabsContent value="reported">
        <Streams status="reported" />
      </TabsContent>
      <TabsContent value="offline">
        <Streams status="offline" />
      </TabsContent>
    </Tabs>
  );
};
