import Tree, { RawNodeDatum } from "react-d3-tree";
import { Team } from "../hooks/useTeams";
import { TransformedData } from "../hooks/useDpcLeagueData";
import { useMemo } from "react";
import { BaseNode } from "../hooks/useDpcLeagueData";

const renderForeignObjectNode = ({
  nodeDatum,
  foreignObjectProps,
}: {
  nodeDatum: RawNodeDatum;
  foreignObjectProps: { width: number; height: number };
}) => (
  <g>
    <circle r={15}></circle>
    <foreignObject {...foreignObjectProps} x="-75" y="-25">
      <div className="bg-purple-900  flex flex-col">
        <div className="flex flex-row justify-between items-center px-1">
          {nodeDatum?.attributes?.teamALogo && (
            <img
              src={nodeDatum?.attributes?.teamALogo as string}
              alt=""
              className="h-4"
            />
          )}
          <p>{nodeDatum?.attributes?.teamAName}</p>
          {nodeDatum?.attributes?.teamAScore && (
            <p>{nodeDatum?.attributes?.teamAScore}</p>
          )}
        </div>
        <div className="flex flex-row justify-between items-center px-1">
          {nodeDatum?.attributes?.teamBLogo && (
            <img
              src={nodeDatum?.attributes?.teamBLogo as string}
              alt=""
              className="h-4"
            />
          )}
          <p>{nodeDatum?.attributes?.teamBName}</p>
          {nodeDatum?.attributes?.teamBScore && (
            <p>{nodeDatum?.attributes?.teamBScore}</p>
          )}
        </div>
      </div>
    </foreignObject>
  </g>
);

export const TreeComponent = ({
  kvTeams,
  transformedData,
}: {
  kvTeams: Record<number, Team[0]>;
  transformedData: TransformedData;
}) => {
  // BRACKET VIS STRUCTURE:

  const computedBracketData = useMemo(() => {
    // Find the node_group that has is_final_group true
    const finalStage = transformedData.find(
      (stage) =>
        stage.groups?.some(
          (group) =>
            group.is_final_group && [4, 5, 6].includes(group.node_group_type),
        ),
    );

    const playoffMatches =
      finalStage?.groups?.find((group) => group.is_final_group)?.nodes || [];

    const consideredNodes = new Set();

    type MatchNode = RawNodeDatum & {
      attributes: {
        isFinal: boolean;
        teamAName: string;
        teamBName: string;
        teamAScore: number;
        teamBScore: number;
        teamALogo: string | undefined;
        teamBLogo: string | undefined;
      };
    };

    // Function to construct the match node
    const generateMatchNode = (match: BaseNode): MatchNode => ({
      name: `${kvTeams[match.team_id_1]?.tag ?? "TBD"} vs ${
        kvTeams[match.team_id_2]?.tag ?? "TBD"
      }`,
      attributes: {
        isFinal: match.winning_node_id === 0,
        teamAName: kvTeams[match.team_id_1]?.tag ?? "TBD",
        teamBName: kvTeams[match.team_id_2]?.tag ?? "TBD",
        teamAScore: match.team_1_wins ?? 0,
        teamBScore: match.team_2_wins ?? 0,
        teamALogo: kvTeams[match.team_id_1]?.logo_url ?? "",
        teamBLogo: kvTeams[match.team_id_2]?.logo_url ?? "",
      },
      children: [
        ...(match.incoming_node_id_1
          ? [generateRecursiveChildren(match.incoming_node_id_1)]
          : []),
        ...(match.incoming_node_id_2
          ? [generateRecursiveChildren(match.incoming_node_id_2)]
          : []),
      ].filter(Boolean) as RawNodeDatum[], // Explicitly type the children array as RawNodeDatum[] and filter out undefined values
    });

    // Function to recursively find and construct children nodes
    const generateRecursiveChildren = (node_id: number) => {
      if (consideredNodes.has(node_id)) return;
      const node = playoffMatches.find((match) => match.node_id === node_id);
      if (!node) return; // This handles the case where there's no incoming_id
      consideredNodes.add(node_id);

      return generateMatchNode(node);
    };

    let computedBracketData = undefined;

    if (finalStage) {
      // Assuming there's always a grandFinal in the finalStage
      const grandFinal = playoffMatches.find(
        (match) => match.winning_node_id === 0,
      );
      if (grandFinal) {
        computedBracketData = generateMatchNode(grandFinal); // Start from the grand final and work backwards
      }
    }
    return computedBracketData;
  }, [kvTeams, transformedData]);

  const nodeSize = {
    x: 150,
    y: 50,
  };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y };
  return (
    <div className="w-[1400px] h-[600px] bg-slate-600">
      <Tree
        data={computedBracketData}
        depthFactor={-200}
        zoom={1}
        centeringTransitionDuration={0}
        renderCustomNodeElement={(rd3tProps) =>
          renderForeignObjectNode({
            ...rd3tProps,
            foreignObjectProps,
          })
        }
        pathFunc="elbow"
        collapsible={false}
        orientation="horizontal"
      />
    </div>
  );
};
