import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteMatchTeam,
  matchAutoProgress,
  patchMatch,
  patchMatchTeam,
  postMatchTeam,
} from "../../../../api/community-tournaments";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { EditCommunityMatch } from "../../../../api/community-tournaments/schemas/matches";

export default function useUpdateTeam({
  tournamentId,
  onSuccess,
  onError,
}: {
  tournamentId?: string;
  onSuccess: () => void;
  onError: () => void;
}) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["match"],
    mutationFn: async ({
      match,
    }: {
      gameId?: string;
      match?: EditCommunityMatch;
    }) => {
      if (match) {
        const teamA = match.teams.find((team) => team?.teamIndex === 10);
        const teamB = match.teams.find((team) => team?.teamIndex === 20);
        try {
          await patchMatch({
            matchId: match.id,
            matchProperties: {
              name: match.name,
              scheduledAt: match.scheduledAt,
              type: match.type,
            },
          });
        } catch (error) {
          alert.showFailureAlert("Error while updating match data");
        }

        //handle team updated
        for (const team of [teamA, teamB]) {
          try {
            if (team) {
              if (team?.prevTeamId && team.team === undefined) {
                //delete team from match
                await deleteMatchTeam({
                  matchId: match.id,
                  teamId: team.prevTeamId,
                });
              } else if (
                team.prevTeamId &&
                team.team &&
                team.prevTeamId === team.team?.id
              ) {
                //update team score
                await patchMatchTeam({
                  matchId: match.id,
                  teamId: team.prevTeamId,
                  score: team.score || 0,
                });
              } else if (
                team.prevTeamId &&
                team.team &&
                team.prevTeamId !== team.team.id
              ) {
                //update team by removing and creating (teamId is primary key on relational table)
                await deleteMatchTeam({
                  matchId: match.id,
                  teamId: team.prevTeamId,
                });
                await postMatchTeam({
                  matchId: match.id,
                  teamId: team.team.id,
                  score: team.score || 0,
                  index: team.teamIndex,
                });
              } else if (team.prevTeamId === undefined && team.team) {
                // add team to match
                await postMatchTeam({
                  matchId: match.id,
                  teamId: team.team.id,
                  score: team.score || 0,
                  index: team.teamIndex,
                });
              }
            }
          } catch (error) {
            alert.showFailureAlert(
              "Error while updating team data, check console for more info",
            );
            throw error;
          }
        }

        try {
          await matchAutoProgress({ matchId: match.id });
        } catch (error) {
          alert.showInfoAlert(
            "Error while auto progressing match, check console for more info",
          );
          // eslint-disable-next-line no-console
          console.log(error);
        }

        //refetch matche data with updated data
        await queryClient.invalidateQueries(["matches", tournamentId]);
        await queryClient.refetchQueries(["matches", tournamentId]);
      }
    },
    onSuccess,
    onError,
  });
}
