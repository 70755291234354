import { useQuery } from "@tanstack/react-query";
import { getRlcsSchedules } from ".";
import { rlcsStatQueryKeys } from "../rlcsStatQueryKeys";

export const useGetRlcsSchedules = () => {
  return useQuery({
    queryKey: rlcsStatQueryKeys.schedules,
    queryFn: getRlcsSchedules,
  });
};
