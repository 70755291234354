import { useQuery } from "@tanstack/react-query";
import { generateImageUrl } from "../../../helpers/images/generateImageUrl";
import { generateAssetsId } from "../../../helpers/images/generateImageUrl/generateAssetsId";
import { getAvatars } from "../../../api/assets";

async function transFormAvatars() {
  const avatars = await getAvatars();
  return avatars.map((avatar) => ({
    ...avatar,
    url: generateImageUrl("avatars", generateAssetsId(avatar.id, "3d"), {
      height: "80",
      width: "80",
      format: "auto",
    }),
  }));
}

export default function useFetchAvatars() {
  return useQuery({
    queryKey: ["avatars-users"],
    queryFn: () => transFormAvatars(),
  });
}
