import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useEffect, useState } from "react";
import { PreviewItem } from "./components/PreviewItem";
import { createMetadata, createUploadLink } from "@/api/mediakit";
import axios from "axios";
import { useToast } from "@/hooks/use-toast";
import { scaleImage } from "./helpers/scaleImage";

interface UploadMultipleFilesProps {
  uploadFinished: () => void;
}
export const UploadMultipleFiles = ({ uploadFinished }: UploadMultipleFilesProps) => {
  const { toast } = useToast();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [files, setFiles] = useState<FileList | null>();
  const [ecosystem, setEcosystem] = useState<string>("");
  const [changedItems, setChangedItems] = useState<Record<string, { file: File; tags: string[] }>>({});

  useEffect(() => {
    if (!files) return;

    const result: Record<string, { file: File; tags: string[] }> = {};
    new Array(files.length).fill(null).forEach((_, index) => {
      const file = files.item(index);
      if (!file) return;
      result[file.name] = {
        file,
        tags: [],
      };
    });

    setChangedItems(result);
  }, [files]);

  const uploadFiles = async () => {
    if (!ecosystem) {
      toast({
        title: "Please select an ecosystem",
        variant: "destructive",
      });
      return;
    }

    setIsUploading(true);
    const files = Object.keys(changedItems).map((key) => changedItems[key]);
    const presignedUrls = await createUploadLink({
      fileNames: files.map(({ file }) => file.name),
    });

    toast({
      title: "Uploading files...",
    });
    for (let i = 0; i < files.length; i++) {
      const { file } = files[i];
      const originalImagePresignedUrl = presignedUrls[i].original;
      const previewImagePresignedUrl = presignedUrls[i].preview;

      const scaledImage = await scaleImage({ file }).catch((error: unknown) => {
        // eslint-disable-next-line no-console
        console.error({ error }, "Error while scaling down image");
        return null;
      });
      if (!scaledImage) {
        toast({
          variant: "destructive",
          title: "Error while scaling down image for preview!",
        });
        return;
      }

      let base64Data: Uint8Array;
      try {
        base64Data = Uint8Array.from(atob(scaledImage.replace(/^data:image\/\w+;base64,/, "")), (c) => c.charCodeAt(0));
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Error while converting scaled image!",
        });
        return;
      }
      // Getting the file type, ie: jpeg, png or gif
      const scaledImageType = scaledImage.split(";")[0].split("/")[1];

      await axios.put(originalImagePresignedUrl, file, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Length": file.size,
          "Content-Type": file.type,
        },
      });
      await axios.put(previewImagePresignedUrl, base64Data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Length": base64Data.length,
          "Content-Type": `image/${scaledImageType}`,
          "Content-Encoding": "base64",
        },
      });
    }

    const promises = await Promise.allSettled(
      files.map(async ({ file, tags }) => {
        let fileHeight: number | undefined;
        let fileWidth: number | undefined;

        if (file.type === "image/png") {
          const image = new Image();
          image.src = URL.createObjectURL(file);

          try {
            await image.decode();
            fileHeight = image.height;
            fileWidth = image.width;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        }

        return {
          fileName: file.name,
          ecosystem,
          type: file.type,
          size: file.size,
          height: fileHeight,
          width: fileWidth,
          tags,
        };
      }),
    );

    await createMetadata(promises.filter((promise) => promise.status === "fulfilled").map((promise) => promise.value));
    toast({
      title: "Finished uploading files",
    });
    uploadFinished();
    setFiles(null);
    setIsUploading(false);
    setChangedItems({});
  };

  return (
    <div className="flex flex-row gap-8">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-2">
            <h1 className="text-xl font-bold">Upload Multiple File</h1>
            <div className="flex w-full max-w-sm flex-col items-start gap-1.5">
              <Label htmlFor="ecoSystem" className="w-fit text-nowrap">
                Ecosystem
              </Label>
              <Select value={ecosystem} onValueChange={(value) => setEcosystem(value)}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="blast">BLAST</SelectItem>
                  <SelectItem value="blasttv">BLAST.tv</SelectItem>
                  <SelectItem value="premier">BLAST Premier (CS)</SelectItem>
                  <SelectItem value="slam">BLAST Slam (Dota)</SelectItem>
                  <SelectItem value="bash">BLAST Bash (Supercell)</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex w-full max-w-sm flex-col items-start gap-1.5">
              <Label htmlFor="file">Files (Click to open file explorer)</Label>
              <Input
                id="file"
                type="file"
                multiple={true}
                value={files === null ? "" : undefined}
                onChange={(e) => {
                  if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                    setFiles(e.currentTarget.files);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <Button
          className="w-fit"
          disabled={isUploading || files === null}
          onClick={() => {
            uploadFiles()
              .then(() => {})
              .catch(() => {});
          }}
        >
          Upload Files
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        {Object.keys(changedItems).map((key, index) => (
          <PreviewItem
            key={index}
            item={changedItems[key]}
            itemChange={(item) => setChangedItems((prev) => ({ ...prev, [item.file.name]: item }))}
          />
        ))}
      </div>
    </div>
  );
};
