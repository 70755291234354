import { QueryFunctionContext, QueryKey, useInfiniteQuery } from "@tanstack/react-query";
import { getPurchases } from "..";
import { queryKeys } from "../shared/queryKeys";

export const useGetPurchases = ({
  options,
  enabled,
}: {
  options: Parameters<typeof getPurchases>[0];
  enabled: boolean;
}) => {
  return useInfiniteQuery({
    queryKey: queryKeys.purchases(options),
    queryFn: ({ pageParam }: QueryFunctionContext<QueryKey, string>) =>
      getPurchases({ ...options, pageToken: pageParam }),
    enabled,
  });
};
