import { GameDefinition } from "@/config";
import { createContext, useState } from "react";

function useData() {
  const [currentGame, setCurrentGame] = useState<GameDefinition>("cs");

  return {
    currentGame,
    setCurrentGame,
  };
}

// Only use this provider for backoffice data that can't be pinned down to only be relevant in very specific routes
export default function BackofficeProvider({ children }: { children?: React.ReactNode }) {
  const providerValue = useData();
  return <BackofficeContext.Provider value={providerValue}>{children}</BackofficeContext.Provider>;
}

export type BackofficeProviderValue = ReturnType<typeof useData>;
export const BackofficeContext = createContext<BackofficeProviderValue | null>(null);
