import { z } from "zod";

export const AvatarSchema = z.object({
  id: z.string(),
  name: z.string(),
  orderIndex: z.number(),
  color: z.string(),
  hidden: z.boolean().optional(),
  isUnlockOnly: z.boolean().optional(),
  isClaimable: z.boolean().optional(),
  rarity: z.enum(["silver", "gold", "diamond"]).optional(),
});

export type Avatar = z.infer<typeof AvatarSchema>;
