import { useQuery } from "@tanstack/react-query";
import { getSchedules } from "../../../api/csguessr";

const useGetSchedules = () => {
  return useQuery({
    queryKey: ["csguessr-schedules"],
    queryFn: getSchedules,
  });
};

export default useGetSchedules;
