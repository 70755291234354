import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider/hooks/useAuth";

import { AppSidebar } from "@/components/ProtectedRoute/components/AppSidebar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Separator } from "@/components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { GameDefinition, PermissionDefinition } from "@/config";
import {
  Archive,
  BarChart,
  BellRing,
  BugIcon,
  CassetteTape,
  Code,
  LucideIcon,
  PillBottle,
  ReplaceAll,
  Trophy,
  TvMinimal,
  Users,
  Volleyball,
  Palette,
  FlagIcon,
} from "lucide-react";
import { useMemo } from "react";

export type RouteDefinition = {
  path?: string;
  title: string;
  icon?: LucideIcon;
  requiredPermission?: PermissionDefinition;
  isActive?: boolean;
  games?: GameDefinition[];
  items?: {
    path: string;
    title: string;
    requiredPermission?: PermissionDefinition;
    games?: GameDefinition[];
  }[];
};

const routes: RouteDefinition[] = [
  {
    title: "Users",
    icon: Users,
    isActive: true,
    items: [
      {
        path: "/users/management",
        title: "Management",
        requiredPermission: "USERS_MANAGEMENT",
      },
      {
        path: "/users/roles",
        title: "Roles",
        requiredPermission: "ROLES_MANAGEMENT",
      },
    ],
  },
  {
    title: "Live",
    icon: TvMinimal,
    items: [
      {
        path: "/live/moderation",
        title: "Moderation",
        requiredPermission: "CHAT_MODERATION",
      },
      {
        path: "/live/moderation-ai",
        title: "Moderation (AI)",
        requiredPermission: "CHAT_MODERATION",
      },
      {
        path: "/live/chat-triggers",
        title: "Chat Triggers",
        requiredPermission: "CHAT_MODERATION",
      },
      {
        path: "/live/broadcasts",
        title: "Broadcasts",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/streams",
        title: "Streams",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/emulation",
        title: "Emulation",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/broadcast-ads",
        title: "Ads",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/transcriptions",
        title: "Transcriptions",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/notifications",
        title: "Notifications",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
    ],
  },

  {
    title: "Play",
    icon: Trophy,
    items: [
      {
        path: "/pickems",
        title: "Pickems",
        requiredPermission: "PICKEMS_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/fantasy",
        title: "Fantasy",
        requiredPermission: "PICKEMS_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/csguessr",
        title: "CS Guessr",
        requiredPermission: "CSGUESSR_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/csquiz",
        title: "CS Quiz",
        requiredPermission: "CSQUIZ_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/counter-strikle",
        title: "Counter Strikle",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/dotle",
        title: "Dotle",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["dota"],
      },
    ],
  },
  {
    title: "Items",
    icon: ReplaceAll,
    items: [
      {
        path: "/avatars",
        title: "Avatars",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
      {
        path: "/emojis",
        title: "Emojis",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
    ],
  },
  {
    title: "Points",
    icon: PillBottle,
    items: [
      {
        path: "/points/products",
        title: "Products",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
      {
        path: "/points/collections",
        title: "Case collections",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
      {
        path: "/points/delivery",
        title: "Delivery",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
      {
        path: "/points/grant",
        title: "Grant",
        requiredPermission: "USERS_MANAGEMENT",
      },
    ],
  },
  {
    title: "Tournament Organization",
    icon: Trophy,
    items: [
      {
        title: "Circuits",
        path: "/to/circuits/:gameId",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs", "dota"],
      },
      {
        title: "Tournaments",
        path: "/to/tournaments/:gameId",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs", "dota"],
      },
      {
        title: "Players",
        path: "/to/players/:gameId",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs", "dota"],
      },
      {
        title: "Teams",
        path: "/to/teams/:gameId",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs", "dota"],
      },
      {
        title: "Awards",
        path: "/to/awards",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs"],
      },
      {
        title: "rl Ranking",
        path: "/to/rl-rankings",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["rl"],
      },
      {
        title: "Statistics",
        path: "/to/rl-statistics",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["rl"],
      },
    ],
  },
  {
    path: "/content",
    title: "Content",
    icon: CassetteTape,
    requiredPermission: "CONTENT_MANAGEMENT",
    items: [
      {
        path: "/content",
        title: "Clips",
        requiredPermission: "CONTENT_MANAGEMENT",
        games: ["cs"],
      },
      {
        title: "Clip Curator",
        requiredPermission: "CLIPS_CURATOR_MANAGEMENT",
        path: "/content/clip-curator",
        games: ["cs"],
      },
    ],
  },
  {
    title: "Viewership",
    icon: BarChart,
    items: [
      {
        path: "metrics/viewership/events",
        title: "Events",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "metrics/viewership/channels",
        title: "Channels",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
    ],
  },
  {
    path: "/notifications",
    title: "Notifications",
    icon: BellRing,
    requiredPermission: "TOURNAMENTS_MANAGEMENT",
  },
  {
    title: "Community Tournaments",
    icon: Volleyball,
    games: ["cs"],
    items: [
      {
        title: "Circuits",
        path: "/community-tournaments/circuits",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs"],
      },
      {
        title: "Tournaments",
        path: "/community-tournaments/tournaments",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs"],
      },
    ],
  },
  {
    title: "Archive",
    icon: Archive,
    items: [
      {
        path: "/statistics/rl/replays",
        title: "Statistics",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["rl"],
      },
    ],
  },

  {
    path: "/issues",
    title: "Issues",
    icon: BugIcon,
    requiredPermission: "TOURNAMENTS_MANAGEMENT",
    items: [
      {
        path: "/issues/tournaments",
        title: "Tournaments",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["dota"],
      },
    ],
  },
  {
    title: "Creative",
    icon: Palette,
    items: [
      {
        path: "/mediakit",
        title: "Media Kit",
        requiredPermission: "BACKOFFICE_ACCESS",
      },
    ],
  },
  {
    title: "Monitoring",
    icon: FlagIcon,
    items: [
      {
        path: "/monitoring/pirate-streams",
        title: "Pirate Streams",
        requiredPermission: "MONITORING_PIRATE_STREAMS",
      },
    ],
  },
  // Please contain this at the lowest nav item.
  {
    title: "Developer Tools",
    icon: Code,
    items: [
      {
        title: "Auditlogs",
        path: "/developer/auditlogs",
        requiredPermission: "USERS_MANAGEMENT",
      },
      {
        title: "Monitoring SNS",
        path: "/developer/monitoring/sns",
        requiredPermission: "USERS_MANAGEMENT",
      },
    ],
  },
];

const allPaths = routes.flatMap((route) => [route.path, ...(route.items?.map((item) => item.path) || [])]);

export function ProtectedRoute() {
  const auth = useAuth();

  const location = useLocation();

  const breadcrumbs = useMemo(() => {
    if (location.pathname === "/") {
      return [{ title: "Welcome" }];
    }
    const paths = location.pathname.split("/").filter(Boolean);
    const result: { title: string; href?: string }[] = [];

    let currentPath = "";

    paths.forEach((path) => {
      currentPath += `/${path}`;
      if (allPaths.includes(currentPath)) {
        result.push({
          title: currentPath.split("/").pop()!,
          href: currentPath,
        });
      } else {
        result.push({
          title: path,
        });
      }
    });

    return result;
  }, [location.pathname]);

  if (!auth.hasAccess) {
    return (
      <Navigate
        to={`/login?redirect_path=${encodeURIComponent(location.pathname + location.search + location.hash)}`}
        replace
      />
    );
  }

  return (
    <SidebarProvider>
      <AppSidebar routes={routes} />
      <SidebarInset>
        <header className="z-10 flex h-16 shrink-0 items-center gap-2 border-b border-zinc-800 bg-zinc-900/80 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                {breadcrumbs.map((crumb, index) => (
                  <>
                    <BreadcrumbItem key={crumb.title}>
                      {index === breadcrumbs.length - 1 ? (
                        <BreadcrumbPage className="capitalize">{crumb.title}</BreadcrumbPage>
                      ) : crumb.href ? (
                        <BreadcrumbLink asChild href={crumb.href} className="capitalize">
                          <Link to={crumb.href}>{crumb.title}</Link>
                        </BreadcrumbLink>
                      ) : (
                        <BreadcrumbPage className="capitalize text-muted-foreground">{crumb.title}</BreadcrumbPage>
                      )}
                    </BreadcrumbItem>
                    {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                  </>
                ))}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>
        <Outlet />
      </SidebarInset>
    </SidebarProvider>
  );
}
