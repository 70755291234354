import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  DialogActions,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CS_GUESSR_MAPS } from "../../constants";
import classNames from "classnames";
import { useCreateLocation } from "../../hooks/useCreateLocation";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

export const CreateLocationDialog = ({
  open,
  closeDialog,
}: {
  open: boolean;
  closeDialog: (successful?: boolean) => void;
}) => {
  const maps = CS_GUESSR_MAPS;
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [posX, setPosX] = useState<number | null>(null);
  const [posY, setPosY] = useState<number | null>(null);
  const [selectedMap, setSelectedMap] = useState<string | null>(null);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const alert = useAlert();

  const { mutate: createLocation, isLoading: creatingLocation } =
    useCreateLocation({
      onSuccess: () => {
        alert.showSuccessAlert("Location created successfully");
        closeDialog(true);
      },
      onError: () => {
        alert.showFailureAlert("Failed to create location");
      },
    });

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedMap && posX && posY && selectedImage) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [selectedMap, posX, posY, selectedImage]);

  function saveLocation() {
    if (selectedMap && posX && posY && selectedImage && !creatingLocation) {
      createLocation({
        mapId: selectedMap,
        posX: posX,
        posY: posY,
        image: selectedImage,
      });
    }
  }

  function transformPosition(position: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, x, y] = position.split(" ");
    setPosX(Number(x));
    setPosY(Number(y));
  }

  return (
    <Dialog open={open} onClose={() => closeDialog()} fullWidth maxWidth="md">
      <DialogTitle>Create new location</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="row" className="w-full justify-between">
          <div className="w-full p-6">
            <h4 className="pb-2">
              Paste{" "}
              <span className="text-orange-400 bg-slate-900 px-2 py-1 rounded">
                getpos
              </span>{" "}
              command output here
            </h4>
            <TextField
              className="w-full"
              placeholder="setpos (X) (Y) (Z);setang..."
              variant="outlined"
              onChange={(e) => {
                transformPosition(e.target.value);
              }}
            />
            <div className="mt-4">
              <h4 className="py-2">Select Map</h4>
              <div className="flex flex-wrap gap-2 justify-start">
                {maps.map((map) => (
                  <div
                    key={map.id}
                    className={classNames(
                      "p-2 w-28 h-16 flex justify-center items-center text-center rounded cursor-pointer transition-all",
                      {
                        "bg-gray-700": selectedMap !== map.id,
                        "bg-slate-500": selectedMap === map.id,
                      },
                    )}
                    onClick={() => setSelectedMap(map.id)}
                  >
                    <h4 className="uppercase">{map.name}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col p-6">
            <div className="bg-slate-300 w-96 h-96 flex justify-center items-center">
              {!imageUrl && (
                <h4 className="text-center text-black">No image selected</h4>
              )}
              {imageUrl && <img src={imageUrl} alt="preview" />}
            </div>
            <Button
              sx={{ fontWeight: "bold", mt: 2, display: "flex" }}
              variant="contained"
              component="label"
            >
              Select Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setSelectedImage(e.target.files?.[0] ?? null)}
              />
            </Button>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()}>Cancel</Button>
        <Button
          onClick={saveLocation}
          variant="contained"
          disabled={saveDisabled || creatingLocation}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLocationDialog;
