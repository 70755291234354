import { useQuery } from "@tanstack/react-query";
import { getDotaLeagues } from "../../../../api/tournaments";

export const useFetchDotaLeagues = ({ tier, year }: { tier?: number; year?: string }) => {
  return useQuery({
    queryKey: ["dotaLeagues", { tier, year }],
    queryFn: () => {
      return getDotaLeagues({ tier, year });
    },
    enabled: true,
  });
};
