import { FantasyResult } from "../../../../api/fantasy/schemas";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useMemo } from "react";
import { MatchByTournamentIdResponse } from "../../../../api/tournaments/schemas/matches";

interface ResultsTableProps {
  matchResults: FantasyResult[];
  matches: MatchByTournamentIdResponse[];
  isLoading: boolean;
  onAdd: () => void;
  onDelete: (matchId: string) => void;
  onEdit: (matchId: string) => void;
}

export default function ResultsTable({ matchResults, matches, onAdd, onEdit, onDelete, isLoading }: ResultsTableProps) {
  const computedResults: {
    [matchId: string]: {
      results: FantasyResult[];
      match: MatchByTournamentIdResponse;
    };
  } = useMemo(
    () =>
      matchResults.reduce(
        (acc, result) => {
          const match = matches.find((match) => match.matchId === result.matchId);

          if (!match) {
            return acc;
          }

          if (!acc[result.matchId]) {
            acc[result.matchId] = {
              results: [],
              match,
            };
          }

          acc[result.matchId].results.push(result);

          return acc;
        },
        {} as {
          [matchId: string]: {
            results: FantasyResult[];
            match: MatchByTournamentIdResponse;
          };
        },
      ),
    [matchResults, matches],
  );

  const sortedResults = useMemo(() => {
    return Object.values(computedResults).sort((a, b) => {
      if (!a.match || !b.match) {
        return 0;
      }

      return new Date(a.match.scheduledAt).getTime() - new Date(b.match.scheduledAt).getTime();
    });
  }, [computedResults]);

  if (isLoading) {
    return <CircularProgress sx={{ m: "2rem" }} />;
  }

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Match</TableCell>
            <TableCell>Team A</TableCell>
            <TableCell>Team B</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>
              <Button onClick={() => onAdd()} color="primary" size="small" title="Add new result" startIcon={<Add />}>
                Add Match Results
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedResults.map(({ match }) => {
            if (!match) return null;

            return (
              <TableRow key={match.matchId}>
                <TableCell>{match.matchName}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <img
                      src={`https://assets.blast.tv/teams/v1/${match.teams[0]?.team?.id}/large`}
                      alt="team-a"
                      width={16}
                    />
                    <Typography marginLeft="0.5rem" fontSize="small">
                      {match.teams[0]?.team?.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <img
                      src={`https://assets.blast.tv/teams/v1/${match.teams[1]?.team?.id}/large`}
                      alt="team-b"
                      width={16}
                    />
                    <Typography marginLeft="0.5rem" fontSize="small">
                      {match.teams[1]?.team?.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{match.scheduledAt.toLocaleString()}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    title="Edit match results"
                    onClick={() => onEdit(match.matchId)}
                    color="primary"
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    title="Delete match results"
                    onClick={() => onDelete(match.matchId)}
                    color="primary"
                    sx={{ ml: "1rem" }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
          {Object.keys(computedResults).length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>No match results found.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!matches.length && (
        <Typography variant="body2" marginY="2rem">
          No results found for this fantasy event.
        </Typography>
      )}
    </>
  );
}
