import { DateTime } from "luxon";
import { memo, useCallback, useState } from "react";

import useFetchRoles from "./hooks/useFetchRoles";

import PageContainer from "../../../components/Layout/PageContainer";
import CreateRole from "./components/CreateRole";
import EditRole from "./components/EditRole";

import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Edit } from "lucide-react";
import { Role } from "../../../api/auth/schemas";

const Roles = () => {
  const { data: roles, refetch } = useFetchRoles();
  const [selectedRole, setSelectedRole] = useState<undefined | Role>(undefined);
  const [isCreateDialogOpened, setIsCreateDialogOpened] = useState(false);

  const resetSelectedRole = useCallback(() => {
    setSelectedRole(undefined);
    void refetch();
  }, [refetch]);

  const closeCreateDialog = useCallback(() => {
    setIsCreateDialogOpened(false);
    void refetch();
  }, [refetch]);

  return (
    <div className="w-full">
      <PageContainer>
        <div className="flex flex-col gap-5">
          <div className="flex w-full items-center justify-between gap-3">
            <div className="text-heading">Roles</div>
            <Button variant="default" onClick={() => setIsCreateDialogOpened(true)}>
              Create
            </Button>
          </div>
          <div className="w-full">
            <Table>
              <TableHeader>
                <TableRow className="text-xs">
                  <TableHead>Name</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead>Created At</TableHead>
                  <TableHead>Modified At</TableHead>
                  <TableHead />
                </TableRow>
              </TableHeader>
              <TableBody>
                {roles?.map((role) => (
                  <TableRow key={role.name} className="text-xs">
                    <TableCell>{role.name}</TableCell>
                    <TableCell>{role.description}</TableCell>
                    <TableCell>{DateTime.fromISO(role.creationDate).toFormat("dd-MM-yyyy")}</TableCell>
                    <TableCell className="text-xs">
                      {DateTime.fromISO(role.lastModifiedDate).toFormat("dd-MM-yyyy")}
                    </TableCell>
                    <TableCell>
                      <Button variant="ghost" size="icon" onClick={() => setSelectedRole(role)}>
                        <Edit />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {selectedRole && <EditRole role={selectedRole} onClose={resetSelectedRole} />}
            {isCreateDialogOpened && <CreateRole onClose={closeCreateDialog} />}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default memo(Roles);
