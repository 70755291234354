import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { DetailedPlayer } from "../../../../api/tournaments/schemas/players";
import { useState } from "react";
import useUpdatePlayerRole from "../hooks/useUpdatePlayerRole";
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from "@mui/lab";
import useFetchPlayerHistory from "../../hooks/useFetchPlayerHistory";
import useFetchTeams from "../../hooks/teams/useFetchTeams";
import { TeamHistoryEditModal } from "./TeamHistoryEditModal";
import { PlayerHistoryWithMapCount } from "../../../../api/tournaments/schemas/players";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";

interface ChangeTeamModalProps {
  changeTeamModalIsOpen: boolean;
  handleClose: () => void;
  player: DetailedPlayer;
}

export const ChangeTeamModal = ({ changeTeamModalIsOpen, handleClose, player }: ChangeTeamModalProps) => {
  const alert = useAlert();
  const { data: teams } = useFetchTeams(player.gameId);
  const { data: playerHistory } = useFetchPlayerHistory(player.id);

  const [fromDate, setFromDate] = useState<DateTime | null>(null);
  const [role, setRole] = useState<string>("");
  const [teamId, setTeamId] = useState<string>("");

  const [editHistory, setEditHistory] = useState<PlayerHistoryWithMapCount | null>(null);

  const { mutate: changePlayerTeam } = useUpdatePlayerRole({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated player role");
      handleClose();
    },
    onError: () => {
      alert.showFailureAlert("Failed to update player role");
    },
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Dialog open={changeTeamModalIsOpen} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>Change {player.nickname} team</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container flexDirection={"row"}>
              <Grid item xs={4}>
                <Grid container flexDirection={"column"} gap={2}>
                  <Grid item>
                    <DateField
                      variant="standard"
                      required
                      label="From Date"
                      format="dd/LL/yyyy"
                      fullWidth
                      value={fromDate}
                      onChange={(e) => setFromDate(e)}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Role *</InputLabel>
                      <Select
                        required
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                        value={role}
                      >
                        <MenuItem key={"player"} value={"player"}>
                          Player
                        </MenuItem>
                        <MenuItem key={"substitute"} value={"substitute"}>
                          Substitute
                        </MenuItem>
                        <MenuItem key={"coach"} value={"coach"}>
                          Coach
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    {teams && (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Team *</InputLabel>
                        <Select
                          required
                          onChange={(e) => {
                            setTeamId(e.target.value);
                          }}
                          value={teamId}
                        >
                          <MenuItem value={"null"}>TEAM-LESS</MenuItem>
                          <Divider />
                          {teams
                            .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
                            .map((team) => (
                              <MenuItem key={team.id} value={team.id}>
                                {team.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" component="h1" textAlign="center">
                  History of {player.nickname}
                </Typography>
                <Typography variant="caption" component="h1" textAlign="center">
                  (descending order)
                </Typography>
                {playerHistory && (
                  <Timeline style={{ maxHeight: "250px", overflowY: "scroll" }}>
                    {playerHistory.map((ph, index) => {
                      return (
                        <TimelineItem key={ph.id}>
                          <TimelineSeparator>
                            <TimelineDot />
                            {index + 1 !== playerHistory.length && <TimelineConnector />}
                          </TimelineSeparator>
                          <TimelineContent
                            className="hover:cursor-pointer hover:bg-slate-500"
                            onClick={() => setEditHistory(ph)}
                          >
                            <Typography variant="body1" component="span">
                              {ph.teamId ? teams?.find((team) => team.id === ph.teamId)?.name : "No team"} -{" "}
                              <i>{ph.role}</i>
                            </Typography>
                            <Typography variant="body2">
                              From: {DateTime.fromFormat(ph.fromDate, "yyyy-LL-dd").toFormat("dd/LL/yyyy")}
                            </Typography>
                            <Typography variant="body2">Maps: {ph.mapsPlayed}</Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Grid container flexDirection="row" justifyContent="space-between">
              <Grid item>
                <Button variant="outlined" onClick={() => handleClose()} sx={{ marginRight: 1 }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (!teamId || !role || !fromDate) {
                      alert.showInfoAlert("You need to fill out the details");
                      return;
                    }

                    changePlayerTeam({
                      playerId: player.id,
                      teamId: teamId === "null" ? null : teamId,
                      role,
                      fromDate: fromDate.toJSDate(),
                    });
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
      {editHistory && (
        <TeamHistoryEditModal
          playerId={player.id}
          inputHistory={editHistory}
          isOpen={editHistory !== null}
          handleClose={() => setEditHistory(null)}
          teams={teams || []}
        />
      )}
    </>
  );
};
