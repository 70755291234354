import { Breadcrumbs } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { IssuesTable } from "./components/IssuesTable";

export const TournamentsIssuesPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col p-8">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <span>Issues</span>
        <span>Tournaments</span>
      </Breadcrumbs>
      <IssuesTable
        onRowClick={(issue) =>
          navigate(`/issues/tournaments/${issue.tournamentId}/${issue.entity}/${issue.entityId}/${issue.errorKey}`)
        }
      />
    </div>
  );
};
