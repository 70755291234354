import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import { Stream } from "../../../../types/Streams";
import { useEffect, useState } from "react";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import useUpdateStream from "../../hooks/useUpdateStream";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export function EditStreamDialog({
  open,
  closeDialog,
  stream,
}: {
  open: boolean;
  closeDialog: (successful?: boolean) => void;
  stream: Stream;
}) {
  const {
    mutate: updateStreamAction,
    isLoading: isUpdatingStream,
    data: updatedStream,
  } = useUpdateStream(stream?.id ?? "");

  const [formStartTime, setFormStartTime] = useState<DateTime | null>(null);
  const [formTimelineOffset, setFormTimelineOffset] = useState<number>(0);

  useEffect(() => {
    if (stream) {
      setFormStartTime(
        stream.startTime ? DateTime.fromISO(stream.startTime) : null,
      );
      setFormTimelineOffset(stream.timelineOffset);
    }
  }, [stream]);

  useEffect(() => {
    if (!isUpdatingStream && updatedStream) {
      closeDialog(true);
    }
  }, [isUpdatingStream, updatedStream, closeDialog]);

  const updateStream = () => {
    const formattedDate = formStartTime?.toUTC().toString() ?? "";
    const newStreamDetails: Stream = {
      ...stream,
      startTime: formattedDate || stream?.startTime,
      timelineOffset: formTimelineOffset ?? stream?.timelineOffset,
    };

    updateStreamAction(newStreamDetails);
  };

  return (
    <Dialog open={open} onClose={() => closeDialog()} fullWidth maxWidth="md">
      <DialogTitle>
        Edit Stream: <span>{stream?.title}</span>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          {stream.provider === "aws" && (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateTimePicker
                label="Start Time"
                value={formStartTime}
                onChange={(date) => setFormStartTime(date ?? null)}
                ampm={false}
                format="dd/LL/yyyy HH:mm"
              />
            </LocalizationProvider>
          )}

          <TextField
            label="Timeline Offset"
            type="number"
            value={formTimelineOffset}
            onChange={(event) =>
              setFormTimelineOffset(Number(event.target.value))
            }
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()} disabled={isUpdatingStream}>
          Cancel
        </Button>
        <Button
          onClick={updateStream}
          disabled={isUpdatingStream}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
