import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createProductMethod } from "./helpers/createProductMethod";

interface useCreateProductProps {
  onSuccess?: () => void;
  onError?: (e: unknown) => void;
}

export const useCreateProduct = ({ onSuccess, onError }: useCreateProductProps) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (props: Parameters<typeof createProductMethod>[0]) => {
      await createProductMethod(props, true);
      await queryClient.invalidateQueries(["products"]);
      await queryClient.refetchQueries(["products"]);
    },
    {
      onSuccess,
      onError,
    },
  );
};
