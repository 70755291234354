import axios from "axios";

import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";

import {
  TriggerPoll,
  TriggerPollOption,
  TriggerPollSchema,
  TriggerPollsListSchema,
} from "../../types/TriggerPolls";
import { Patron } from "../../providers/PatronProvider/types";

export type CreateTriggerPollArgs = Omit<
  TriggerPoll,
  "id" | "startedAt" | "votes" | "endDate" | "options"
> & {
  options: Array<
    Omit<TriggerPollOption, "votes"> & {
      image?: File;
      imagePreview?: {
        content: string;
        mimeType: string;
      };
    }
  >;
};

export interface GetPollsParams {
  patron?: Patron;
  limit?: number;
  pageToken?: string;
}

const triggersApi = axios.create({
  baseURL: `${baseApiURL}/v1/triggers`,
});

triggersApi.interceptors.request.use(authInterceptor);

export const createTriggerPoll = async (body: CreateTriggerPollArgs) => {
  await triggersApi.post("/polls", body);
};

export const getTriggerPolls = async (params: GetPollsParams) => {
  const { data } = await triggersApi.get<unknown>("/polls", { params });

  return TriggerPollsListSchema.parse(data);
};

export const getTriggerPollById = async (id: string) => {
  const { data } = await triggersApi.get<unknown>(`/polls/${id}`);

  return TriggerPollSchema.parse(data);
};

export const deleteTriggerPoll = async (id: string) => {
  await triggersApi.delete(`/polls/${id}`);
};

export const startTriggerPoll = async (id: string) => {
  await triggersApi.post(`/polls/${id}/start`);
};
