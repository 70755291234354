"use client";

import { ChevronRight } from "lucide-react";

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import { RouteDefinition } from "..";
import classNames from "classnames";
import { useBackoffice } from "@/providers/BackofficeProvider/useState";

export function NavMain({ items, title }: { items: RouteDefinition[]; title: string }) {
  const { pathname } = useLocation();
  const { currentGame } = useBackoffice();

  const isActive = (item: RouteDefinition) => {
    if (item.path && pathname.includes(item.path)) {
      return true;
    }
    if (item.items) {
      return item.items.some((subItem) => pathname.includes(subItem.path ?? ""));
    }
    return false;
  };

  return (
    <SidebarGroup>
      <SidebarGroupLabel>{title}</SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => (
          <Collapsible key={item.title} asChild defaultOpen={item.isActive} className="group/collapsible">
            <SidebarMenuItem>
              {item.items && item.items.length > 0 ? (
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton
                    tooltip={item.title}
                    className={classNames({
                      "rounded-md bg-sidebar-accent": isActive(item),
                    })}
                  >
                    {item.icon && <item.icon />}
                    <span className="line-clamp-1">{item.title}</span>

                    <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                  </SidebarMenuButton>
                </CollapsibleTrigger>
              ) : (
                <Link
                  to={
                    item.games?.includes(currentGame)
                      ? (item.path ?? "").replace(":gameId", currentGame)
                      : (item.path ?? "")
                  }
                >
                  <SidebarMenuButton
                    tooltip={item.title}
                    className={classNames({
                      "rounded-md bg-sidebar-accent": isActive(item),
                    })}
                  >
                    {item.icon && <item.icon />}
                    <span className="line-clamp-1">{item.title}</span>
                  </SidebarMenuButton>
                </Link>
              )}
              <CollapsibleContent className="mt-0.5">
                <SidebarMenuSub>
                  {item.items?.map((subItem) => (
                    <SidebarMenuSubItem
                      key={subItem.title}
                      className={classNames({
                        "rounded-md bg-sidebar-accent": pathname === subItem.path,
                      })}
                    >
                      <SidebarMenuSubButton asChild>
                        <Link
                          to={
                            subItem.games?.includes(currentGame)
                              ? (subItem.path ?? "").replace(":gameId", currentGame)
                              : (subItem.path ?? "")
                          }
                        >
                          <span>{subItem.title}</span>
                        </Link>
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  ))}
                </SidebarMenuSub>
              </CollapsibleContent>
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
