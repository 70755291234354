import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useCallback, useState } from "react";
import { deleteUserChatRole, putUserChatRole } from "../../../../api/users";
import UserProfile, { UserChatRole } from "../../../../types/users";
import { useQueryClient } from "@tanstack/react-query";
import { userQueryKeys } from "@/providers/AuthProvider";

export default function EditChatForm({
  open,
  handleClose,
  data,
}: {
  open: boolean;
  handleClose: () => void;
  data: Pick<UserProfile, "id" | "chatRole">;
}) {
  const { id } = data;
  const [chatRole, setChatRole] = useState<UserChatRole | "">(data.chatRole ?? "");
  const [isWaiting, setIsWaiting] = useState(false);

  const queryClient = useQueryClient();
  const updateChatRole = useCallback(async () => {
    if (!id) {
      throw new Error("User id is not defined");
    }

    setIsWaiting(true);

    try {
      if (chatRole) {
        await putUserChatRole(id, { chatRole });
      } else {
        await deleteUserChatRole(id);
      }
      await queryClient.invalidateQueries({
        queryKey: userQueryKeys.user(id),
      });
      handleClose();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsWaiting(false);
    }
  }, [id, chatRole, queryClient, handleClose]);

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && handleClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit user</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium">Chat Role</label>
            <Select
              value={chatRole}
              onValueChange={(value) => setChatRole(value === "none" ? "" : (value as UserChatRole))}
              disabled={isWaiting}
            >
              <SelectTrigger className="w-[320px]">
                <SelectValue placeholder="Select a role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="none">-</SelectItem>
                <SelectItem value="admin">Admin</SelectItem>
                <SelectItem value="moderator">Moderator</SelectItem>
                <SelectItem value="verified">Verified</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => handleClose()} disabled={isWaiting}>
              Cancel
            </Button>
            <Button onClick={() => void updateChatRole()} disabled={isWaiting}>
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
