import { ExpandMore, OpenInNew } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const TournamentCustomModulesAccordion = ({
  headerCtaText,
  headerCtaUrl,
  setHeaderCtaText,
  setHeaderCtaUrl,
}: {
  headerCtaText: string;
  headerCtaUrl: string;
  setHeaderCtaText: (text: string) => void;
  setHeaderCtaUrl: (url: string) => void;
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>
          <strong>Custom CTA</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="flex flex-col gap-4">
        <TextField
          label="CTA Text"
          value={headerCtaText}
          onChange={(e) => setHeaderCtaText(e.target.value)}
          required
          fullWidth
          className="block max-w-md"
        />
        <div className="flex items-center gap-2">
          <TextField
            label="CTA URL"
            value={headerCtaUrl}
            onChange={(e) => setHeaderCtaUrl(e.target.value)}
            required
            fullWidth
            className="block max-w-md"
            type="url"
          />
          <Button
            component={Link}
            href={headerCtaUrl}
            target="_blank"
            rel="noopener noreferrer"
            disabled={!headerCtaUrl}
            startIcon={<OpenInNew />}
          >
            Preview link
          </Button>
        </div>
        <FormHelperText>
          This CTA will be displayed in the header of the tournament page. It will be displayed as a button that links
          to the URL you provide.
          <br />
          Remove the values and save in order to remove the CTA.
        </FormHelperText>
      </AccordionDetails>
    </Accordion>
  );
};
