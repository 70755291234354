import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import z from "zod";

const teamStandingSchema = z.object({
  standing: z.number(),
  team_id: z.number(),
  team_name: z.string(),
  team_tag: z.string(),
  team_logo: z.string(),
  team_logo_url: z.string().nullable().optional(),
  wins: z.number(),
  losses: z.number(),
  score: z.string(),
  team_abbreviation: z.string(),
  score_tiebreak_group: z.string(),
  score_tiebreak_below: z.string(),
  score_tiebreak_random: z.string(),
  is_pro: z.boolean(),
});

export type TeamStanding = z.infer<typeof teamStandingSchema>;

const baseNodeSchema = z.object({
  name: z.string(),
  node_id: z.number(),
  node_group_id: z.number(),
  winning_node_id: z.number(),
  losing_node_id: z.number(),
  incoming_node_id_1: z.number(),
  incoming_node_id_2: z.number(),
  node_type: z.number(),
  scheduled_time: z.number(),
  actual_time: z.number(),
  series_id: z.number(),
  team_id_1: z.number(),
  team_id_2: z.number(),
  matches: z.array(
    z.object({
      match_id: z.string(),
      winning_team_id: z.number(),
    }),
  ),
  team_1_wins: z.number(),
  team_2_wins: z.number(),
  has_started: z.boolean(),
  is_completed: z.boolean(),
  stream_ids: z.array(z.number()),
  vods: z.array(z.any()), // Define more specifically if possible
});

export type BaseNode = z.infer<typeof baseNodeSchema>;

export type Node = z.infer<typeof baseNodeSchema> & {
  node_groups?: NodeGroup[];
};

const nodeSchema: z.ZodType<Node> = baseNodeSchema.extend({
  node_groups: z.lazy(() => z.array(nodeGroupSchema).optional()),
});

const baseNodeGroupSchema = z.object({
  name: z.string(),
  node_group_id: z.number(),
  parent_node_group_id: z.number(),
  incoming_node_group_ids: z.array(z.number()),
  advancing_node_group_id: z.number(),
  advancing_team_count: z.number(),
  team_count: z.number(),
  node_group_type: z.number(),
  default_node_type: z.number(),
  round: z.number(),
  max_rounds: z.number(),
  is_tiebreaker: z.boolean(),
  is_final_group: z.boolean(),
  is_completed: z.boolean(),
  phase: z.number(),
  region: z.number(),
  start_time: z.number(),
  end_time: z.number(),
  secondary_advancing_node_group_id: z.number(),
  secondary_advancing_team_count: z.number(),
  tertiary_advancing_node_group_id: z.number(),
  tertiary_advancing_team_count: z.number(),
  elimination_dpc_points: z.number(),
  team_standings: z.array(teamStandingSchema),
  nodes: z.array(nodeSchema),
});

export type NodeGroup = z.infer<typeof baseNodeGroupSchema> & {
  node_groups?: NodeGroup[];
};

const nodeGroupSchema: z.ZodType<NodeGroup> = baseNodeGroupSchema.extend({
  node_groups: z.lazy(() => z.array(nodeGroupSchema).optional()),
});

const prizePoolSchema = z.object({
  base_prize_pool: z.number(),
  total_prize_pool: z.number(),
  prize_split_pct_x100: z.array(z.number()),
  prize_pool_items: z.array(z.any()), // Define more specifically if possible
});

const seriesInfoSchema = z.object({
  series_id: z.number(),
  series_type: z.number(),
  start_time: z.number(),
  match_ids: z.array(z.string()),
  team_id_1: z.number(),
  team_id_2: z.number(),
});

export type SeriesInfo = z.infer<typeof seriesInfoSchema>;

const registeredPlayerSchema = z.object({
  account_id: z.number(),
  name: z.string(),
  team_id: z.number(),
});

export const DpcLeagueDataSchema = z.object({
  info: z.object({
    league_id: z.number(),
    name: z.string(),
    tier: z.number(),
    region: z.number(),
    url: z.string(),
    description: z.string(),
    notes: z.string().optional(),
    start_timestamp: z.number(),
    end_timestamp: z.number(),
    pro_circuit_points: z.number(),
    image_bits: z.number(),
    status: z.number(),
    most_recent_activity: z.number(),
    registration_period: z.number(),
  }),
  prize_pool: prizePoolSchema,
  admins: z.array(z.any()), // Depending on the structure, you may want to define this more specifically
  streams: z.array(z.any()), // Same as above, define more specifically if needed
  node_groups: z.array(nodeGroupSchema),
  series_infos: z.array(seriesInfoSchema),
  registered_players: z.array(registeredPlayerSchema),
});

export type DpcLeagueData = z.infer<typeof DpcLeagueDataSchema>;

// Typings for the transformedData
export type TransformedData = {
  stageId: number;
  groups: {
    name: string;
    groupId: number;
    standings: TeamStanding[];
    matches: Node[];
    type: number;
  } & NodeGroup[];
  type: number;
}[];

export const useDpcLeagueData = (leagueId?: string) => {
  return useQuery(
    ["dpcLeagueData", leagueId],
    async () => {
      const response = await axios.get(
        `https://www.dota2.com/webapi/IDOTA2DPC/GetLeagueData/v001?league_id=${leagueId}&delay_seconds=0`,
      );

      const data = DpcLeagueDataSchema.parse(response.data);

      return data;
    },
    { enabled: !!leagueId },
  );
};
