import axios from "axios";
import { createUploadLink, invalidateCache } from "../../../../../../../../../api/assets";
import { patchProduct, postProduct } from "../../../../../../../../../api/points";
import { PointsProduct } from "../../../../../../../../../api/points/schemas";

type createProductMethodProps = PointsProduct & {
  image: File;
};

export const createProductMethod = async (props: createProductMethodProps, newProduct: boolean) => {
  // Get upload URL
  const data = await createUploadLink("products", props.id);

  // Upload image
  await axios.put(data.uploadUrl, props.image, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Length": props.image.size,
      "Content-Type": props.image.type,
    },
  });

  // Invalidate cache
  await invalidateCache("products", props.id);

  if (newProduct) {
    await postProduct(props);
    return;
  }

  // Create product
  await patchProduct(props);
};
