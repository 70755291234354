import { useMemo, useEffect, useState, useCallback } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Skeleton } from "@mui/material";
import { PointsProduct, Purchase } from "../../../../api/points/schemas";
import { useGetPurchases } from "../../../../api/points/hooks/useGetPurchases";
import { LoadingButton } from "@mui/lab";
import { Casino, Check, OpenInNew } from "@mui/icons-material";
import useFetchUserProfile from "../../../../hooks/useFetchUserProfile";
import { usePostAssignReward } from "../../../../api/points/hooks/usePostAssignReward";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

type RaffleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  product: PointsProduct;
};

export const RaffleModal = ({ isOpen, onClose, product }: RaffleModalProps) => {
  const { data: previousWinnerPurchasesData } = useGetPurchases({
    options: {
      productId: product.id,
    },
    enabled: product.type === "raffle-reward" && !!product.raffleTicketProductId,
  });

  const previousWinnerPurchase = useMemo(() => {
    return previousWinnerPurchasesData?.pages?.[0]?.purchases?.[0];
  }, [previousWinnerPurchasesData]);

  const [winnerPurchase, setWinnerPurchase] = useState<Purchase>();

  useEffect(() => {
    if (previousWinnerPurchase) {
      setWinnerPurchase(previousWinnerPurchase);
    }
  }, [previousWinnerPurchase]);

  const navigate = useNavigate();
  const { data: userProfile } = useFetchUserProfile(winnerPurchase?.userId);
  const { mutate: postAssignReward, isLoading: isAssigningReward } = usePostAssignReward({
    onSuccess: () => {
      onClose();
      navigate("/points/delivery");
    },
  });
  const {
    data: ticketPurchases,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useGetPurchases({
    options: {
      productId: product.raffleTicketProductId!,
    },
    enabled: product.type === "raffle-reward" && !!product.raffleTicketProductId,
  });

  // Fetch all pages when the component mounts
  useEffect(() => {
    if (hasNextPage) {
      void fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const allTickets = useMemo(() => {
    return ticketPurchases?.pages.flatMap((page) => page.purchases) || [];
  }, [ticketPurchases]);

  const uniqueUsers: Set<string> = useMemo(() => {
    return allTickets.reduce((acc, curr) => {
      acc.add(curr.userId);
      return acc;
    }, new Set<string>());
  }, [allTickets]);

  const drawWinner = () => {
    const winner = allTickets[Math.floor(Math.random() * allTickets.length)];
    setWinnerPurchase(winner);
  };

  const handleAssignReward = useCallback(() => {
    if (!winnerPurchase) return;

    postAssignReward({
      userId: winnerPurchase.userId,
      productId: product.id,
    });
  }, [winnerPurchase, product.id, postAssignReward]);

  if (product.type !== "raffle-reward") return null;

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      fullWidth
    >
      <DialogTitle>{product.name}</DialogTitle>
      <DialogContent className="flex flex-col items-center gap-8">
        {!product.raffleTicketProductId ? (
          <p>
            No raffle ticket product ID found. Assign a raffle ticket product to the item, to be able to assign a winner
            for the raffle.
          </p>
        ) : (
          <>
            <div className="flex w-full items-center justify-between gap-4">
              <div>
                <div className="shrink-0 text-sm">
                  Purchased tickets:
                  <strong className="ml-4">{allTickets.length.toLocaleString()}</strong>
                </div>
                <div className="shrink-0 text-sm">
                  Unique users:
                  <strong className="ml-4">{uniqueUsers.size.toLocaleString()}</strong>
                </div>
              </div>
              <LoadingButton
                variant="outlined"
                startIcon={<Casino />}
                disabled={isFetching || !!winnerPurchase}
                loading={isFetching}
                loadingPosition="start"
                onClick={drawWinner}
              >
                Draw winner
              </LoadingButton>
            </div>
          </>
        )}
        <div
          className={classNames(
            "flex min-w-[440px] flex-col items-start gap-2 rounded-small bg-gray-800 p-4 text-sm transition-all",
            {
              "opacity-50": !winnerPurchase,
            },
          )}
        >
          <div className="text-lg font-bold">{userProfile ? userProfile.username : "?"}</div>
          <table className="w-full text-sm">
            <tbody>
              <tr>
                <td className="whitespace-nowrap pr-8">Ticket ID:</td>
                <td className="w-full">
                  {winnerPurchase ? (
                    <div>{winnerPurchase.id}</div>
                  ) : (
                    <Skeleton className="h-4 w-full" animation={false} />
                  )}
                </td>
              </tr>
              <tr>
                <td className="pr-8">User ID:</td>
                <td className="w-full">
                  {winnerPurchase ? (
                    <div>{winnerPurchase.userId}</div>
                  ) : (
                    <Skeleton className="h-4 w-full" animation={false} />
                  )}
                </td>
              </tr>
              <tr>
                <td className="pr-8">Email:</td>
                <td className="w-full">
                  {userProfile ? <div>{userProfile.email}</div> : <Skeleton className="h-4 w-2/3" animation={false} />}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContent>
      <DialogActions>
        {!previousWinnerPurchase ? (
          <LoadingButton
            onClick={handleAssignReward}
            variant="contained"
            disabled={!winnerPurchase}
            loadingPosition="start"
            loading={isAssigningReward}
            startIcon={<Check />}
          >
            Assign reward
          </LoadingButton>
        ) : (
          <Button component={RouterLink} to="/points/delivery" variant="text" startIcon={<OpenInNew />}>
            See reward in delivery
          </Button>
        )}
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
