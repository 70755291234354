import { usePatchTournament } from "../hooks/usePatchTournament";
import { getTournamentById } from "..";
import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { queryKeys } from "../shared/queryKeys";

export const useAddTournamentTicketing = (tournamentId: string) => {
  const { mutateAsync: patchTournament } = usePatchTournament(tournamentId);

  const mutationFn = async (newItem: { title: string; startDate: string; endDate: string; cta: { url: string } }) => {
    // Get tournament, to make sure we don't overwrite existing metadata
    const tournament = await getTournamentById(tournamentId);

    await patchTournament({
      metadata: {
        ...(tournament.metadata ?? { _t: "dota_tournament" }),
        ticketing: {
          items: [...(tournament.metadata?.ticketing?.items ?? []), newItem],
        },
      },
    });
  };

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.tournamentById(tournamentId) });
    },
  });
};
