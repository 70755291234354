import axios from "axios";
import { baseApiURL, clipsAwsBaseUrl } from "../../config";
import authInterceptor from "../authInterceptor";
import { ClipCuratorEventSchema, ClipCuratorOperationSchema, ClipSchema, TranscriptionSchema } from "./schemas";

export const videoApi = axios.create({
  baseURL: `${baseApiURL}/v1/videos`,
});

videoApi.interceptors.request.use(authInterceptor);

interface RequestPayload {
  series: string;
  episode?: string;
  isTrailer: boolean;
}

interface CreateUploadRequest extends RequestPayload {
  numberOfParts: number;
}

interface CompleteUploadRequest extends RequestPayload {
  uploadId: string;
  parts: { partNumber: number; eTag: string }[];
}

export const createUpload = async ({
  series,
  episode,
  isTrailer,
  numberOfParts,
}: CreateUploadRequest): Promise<{ uploadId: string; urls: string[] }> => {
  const { data } = await videoApi.post<{ uploadId: string; urls: string[] }>(`/content/upload`, {
    series,
    episode,
    isTrailer,
    numberOfParts,
  });
  return {
    uploadId: data.uploadId,
    urls: data.urls,
  };
};

export const completeUpload = async ({
  series,
  episode,
  isTrailer,
  uploadId,
  parts,
}: CompleteUploadRequest): Promise<void> => {
  await videoApi.post("/content/upload/complete", {
    series,
    episode,
    isTrailer,
    uploadId,
    parts,
  });
};

export const startVideoEncoding = async ({ series, episode, isTrailer }: RequestPayload): Promise<void> => {
  await videoApi.post(`/content/process`, {
    series: series,
    episode: episode,
    isTrailer: isTrailer,
  });
};

export const listSeries = async (): Promise<string[]> => {
  const { data } = await videoApi.get<{ series: Array<string> }>(`/content/series`);
  return data.series;
};

export const listEpisodesAndTrailers = async ({ series }: { series: string }): Promise<string[]> => {
  const { data } = await videoApi.get<{ episodes: Array<string> }>(`/content/episodes/${series}`);
  return data.episodes;
};

export interface HarvestClipOptions {
  matchId: string;
  eventId: string;
}
export const harvestClip = async ({ matchId, eventId }: HarvestClipOptions): Promise<void> => {
  await videoApi.post(`/clips/harvest`, {
    matchId,
    eventId,
  });
};

export const getVideoClip = async ({ eventId }: { eventId: string }) => {
  const { data } = await videoApi.get<unknown>(`/clips/${eventId}`);
  return ClipSchema.parse(data);
};

export const getEventTranscript = async ({ eventId }: { eventId: string }) => {
  const { data } = await axios.get<unknown>(`${clipsAwsBaseUrl}/transcripts/${eventId}.json`);
  return TranscriptionSchema.parse(data);
};

export const getClipsCuratorEvents = async ({ category, id }: { category: string; id: string }) => {
  const { data } = await videoApi.get<unknown>(`clips-curator/${category}/${id}`);
  return ClipCuratorEventSchema.array().parse(data);
};

export const createClipsCuratorOperation = async ({
  operationType,
  operationOptions,
  requesterEmail,
}: {
  operationType: string;
  operationOptions: unknown;
  requesterEmail?: string;
}) => {
  const { data } = await videoApi.post<unknown>("clips-curator/operations", {
    operationType,
    requesterEmail,
    operationOptions,
  });
  return ClipCuratorOperationSchema.parse(data);
};

export const getClipsCuratorOperations = async (all = false) => {
  let url = "clips-curator/operations";
  if (all) {
    url += "?requester=all";
  }
  const { data } = await videoApi.get<unknown>(url);
  return ClipCuratorOperationSchema.array().parse(data);
};
