import { z } from "zod";

export const DotlePlayerSchema = z.object({
  id: z.string(),
  nickname: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});

export type DotlePlayer = z.infer<typeof DotlePlayerSchema>;
