import { useQuery } from "@tanstack/react-query";
import { getClipsCuratorOperations } from "../../../api/video";

const useGetClipCuratorOperations = (all = false) => {
  return useQuery({
    queryKey: ["clips-curator", "operations", all ? "all" : "my"],
    queryFn: () => getClipsCuratorOperations(all),
    initialData: [],
    retry: false,
  });
};

export default useGetClipCuratorOperations;
