import { useMutation } from "@tanstack/react-query";

import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";

import { deleteMessage } from "../../../../../api/chat";

const useDeleteMessage = () => {
  const { showFailureAlert } = useAlert();

  return useMutation({
    mutationKey: ["deleteMessage"],
    mutationFn: ({
      messageId,
      deletedBy,
      suspensionId,
    }: {
      messageId: string;
      deletedBy: string;
      suspensionId?: string;
    }) => deleteMessage({ messageId, deletedBy, suspensionId }),
    onError: () => {
      showFailureAlert("Error while deleting the message");
    },
  });
};

export default useDeleteMessage;
