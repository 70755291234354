import { useUpdateEvent } from "../../../../../api/metricsViewership/hooks/useUpdateEvent";
import { Channel } from "../../../../../api/metricsViewership/schemas/ChannelSchema";
import { PlatformIcon } from "@/routes/MetricsViewership/routes/shared/components/PlatformIcon";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useGetEventById } from "@/api/metricsViewership/hooks/useGetEventById";
import { CircleX } from "lucide-react";

export const UnlinkChannelModal = ({
  channel,
  eventId,
  disabled,
  className,
}: {
  channel: Channel;
  eventId: string;
  disabled: boolean;
  className?: string;
}) => {
  const [open, setOpen] = useState(false);

  const { data: event } = useGetEventById(eventId);
  const { mutate: updateEvent, isLoading: isSubmitting } = useUpdateEvent(eventId);

  const saveAndClose = () => {
    const channels = event?.channels.filter(({ id }) => id !== channel.id);
    updateEvent({
      channels,
    });
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" className={className} disabled={disabled} size="icon" title="Unlink channel">
          <CircleX />
        </Button>
      </DialogTrigger>

      <DialogContent className="w-full max-w-4xl">
        <DialogHeader>
          <DialogTitle>Unlink channel</DialogTitle>
          <DialogDescription>
            Unlinked channel will no longer be tracked for this event, and will not be shown in reports.
          </DialogDescription>
        </DialogHeader>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <PlatformIcon platform={channel?.channelSource} />
              </TableCell>
              <TableCell>{channel?.channelName}</TableCell>
              <TableCell>{channel?.id}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false);
            }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button onClick={saveAndClose}>Unlink</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
