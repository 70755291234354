import { assetsURL } from "../../../config";
import { ImageCategory } from "./types/ImageCategory";
import { ImageOptions } from "./types/ImageOptions";

const getCategoryBasePath = (category: ImageCategory) => {
  switch (category) {
    case "dota2-hero":
      return "images/dota2/heroes";

    case "dota2-rank":
      return "images/dota2/rank-icons";

    case "tournament-sponsor":
      return "images";

    default:
      return `images/${category}`;
  }
};

export const generateImageUrl = (category: ImageCategory, filename: string, options?: ImageOptions) => {
  if (!options) return `${assetsURL}/${getCategoryBasePath(category)}/${filename}`;

  const searchParams = new URLSearchParams(options);

  return `${assetsURL}/${getCategoryBasePath(category)}/${filename}?${searchParams.toString()}`;
};
