import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Map } from "../../../../../../../api/tournaments/schemas/maps";
import { EditTeamMatch } from "../../../../../../../api/tournaments/schemas/matches";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useState } from "react";
import mapPool from "../../../../../shared/components/mapPool";
import usePatchMap from "../../../../../hooks/maps/usePatchMap";
import { DeleteMapConfirmModal } from "./components/DeleteMapConfirmModal";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

interface MatchMapProps {
  matchMap: Map;
  mapNumber: number;
  teamA?: EditTeamMatch;
  teamB?: EditTeamMatch;
  allowedToSave: "yes" | "saveMatch" | "no";
  gameId: string;
}
export const Maps = ({ matchMap, mapNumber, teamA, teamB, allowedToSave, gameId }: MatchMapProps) => {
  const [editMap, setEditMap] = useState<Map>(matchMap);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

  const teamAMap = matchMap.teams?.find((team) => team.team.id === teamA?.team?.id);
  const teamBMap = matchMap.teams?.find((team) => team.team.id === teamB?.team?.id);

  const { mutate: updateMap } = usePatchMap(gameId);

  return (
    <>
      <DeleteMapConfirmModal
        isOpen={deleteConfirm}
        close={() => setDeleteConfirm(false)}
        mapId={editMap.id}
        matchId={editMap.matchId}
      />
      <Accordion key={matchMap.id} className="mx-0 my-2 w-full max-w-[1500px] rounded px-2">
        <AccordionSummary className="h-9">
          <div className="flex w-full justify-between">
            <div className="flex">
              <Typography>
                {matchMap.name}
                {matchMap.externalId && ` (${matchMap.externalId})`}
              </Typography>{" "}
            </div>
            <Typography>
              {teamAMap?.score} {teamAMap?.team.name} vs {teamBMap?.team.name} {teamBMap?.score}
            </Typography>{" "}
            <Typography>Start: {matchMap.scheduledAt && new Date(matchMap.scheduledAt).toTimeString()}</Typography>{" "}
            <Typography>End: {matchMap.endedAt && new Date(matchMap.endedAt).toLocaleString()}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className="border-t-2 border-solid pt-8">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2">
              <FormControl>
                <InputLabel id={`${matchMap.id}-map`}>MAP</InputLabel>
                <Select
                  labelId={`${matchMap.id}-map`}
                  label="Map"
                  value={editMap.name}
                  onChange={(e) => {
                    setEditMap({ ...editMap, name: e.target.value });
                  }}
                >
                  {mapPool[gameId].map((map) => (
                    <MenuItem key={map} value={map}>
                      {map}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  label="Scheduled Start"
                  ampm={false}
                  format="dd/LL/yyyy HH:mm"
                  defaultValue={
                    editMap.scheduledAt ? DateTime.fromJSDate(editMap.scheduledAt) : DateTime.fromJSDate(new Date())
                  }
                  onChange={(date) => {
                    const scheduledAt = date?.toJSDate();
                    scheduledAt && setEditMap({ ...editMap, scheduledAt: scheduledAt });
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  label="Actual Start"
                  ampm={false}
                  format="dd/LL/yyyy HH:mm"
                  defaultValue={
                    editMap.startedAt ? DateTime.fromJSDate(editMap.startedAt) : DateTime.fromJSDate(new Date())
                  }
                  onChange={(date) => {
                    const startedAt = date?.toJSDate();
                    startedAt && setEditMap({ ...editMap, startedAt: startedAt });
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                  label="End Time"
                  ampm={false}
                  format="dd/LL/yyyy HH:mm"
                  defaultValue={
                    editMap.endedAt ? DateTime.fromJSDate(editMap.endedAt) : DateTime.fromJSDate(new Date())
                  }
                  onChange={(date) => {
                    const endedAt = date?.toJSDate();
                    endedAt && setEditMap({ ...editMap, endedAt: endedAt });
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="flex flex-row gap-2">
              <TextField
                type="number"
                label={teamAMap ? `Team A (${teamAMap.team.name}) Score` : `Team A Score`}
                value={editMap.teams?.find((t) => t.team?.id === teamA?.team?.id)?.score || "0"}
                onChange={(e) => {
                  const score = parseInt(e.target.value);

                  const newTeams =
                    editMap.teams && score >= 0
                      ? editMap.teams.map((team) => {
                          if (team.team.id === teamAMap?.team?.id) {
                            return {
                              ...team,
                              score,
                            };
                          } else return team;
                        })
                      : editMap.teams;
                  setEditMap({
                    ...editMap,
                    teams: newTeams,
                  });
                }}
              />
              <TextField
                type="number"
                label={teamBMap ? `Team B (${teamBMap.team.name}) Score` : `Team B Score`}
                value={editMap.teams?.find((t) => t.team?.id === teamB?.team?.id)?.score || "0"}
                onChange={(e) => {
                  const score = parseInt(e.target.value);

                  const newTeams =
                    editMap.teams && score >= 0
                      ? editMap.teams.map((team) => {
                          if (team.team.id === teamBMap?.team?.id) {
                            return {
                              ...team,
                              score,
                            };
                          } else return team;
                        })
                      : editMap.teams;
                  setEditMap({
                    ...editMap,
                    teams: newTeams,
                  });
                }}
              />
              <TextField
                label={"External Id"}
                value={editMap.externalId}
                onChange={(e) => {
                  setEditMap({
                    ...editMap,
                    externalId: e.target.value,
                  });
                }}
              />
            </div>

            <div className="flex justify-end gap-2">
              <Button variant="outlined" onClick={() => setDeleteConfirm(true)}>
                Delete Map
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={allowedToSave !== "yes"}
                onClick={() => updateMap({ map: editMap })}
              >
                Save Map
              </Button>
              {allowedToSave === "saveMatch" && (
                <Typography className="max-w-[150px] text-center text-orange-400">Teams Changed Save Match</Typography>
              )}
              {gameId === "cs" && (
                <Button variant="outlined">
                  <Link to={`/livestats/match/${matchMap.matchId}/maps/${mapNumber}`}>View Stats</Link>
                </Button>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
