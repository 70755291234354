import { z } from "zod";

export const TriggerPollOptionSchema = z.object({
  id: z.string(),
  value: z.string(),
  votes: z.number(),
  orderIndex: z.number(),
});

export type TriggerPollOption = z.infer<typeof TriggerPollOptionSchema>;

export const TriggerPollSchema = z.object({
  id: z.string(),
  broadcastId: z.string().nullable(),
  title: z.string(),
  question: z.string(),
  durationSeconds: z.number(),
  startedAt: z.string().nullable().optional(),
  options: TriggerPollOptionSchema.array(),
  votes: z.number(),
  hasImages: z.boolean().default(false),
  customPollDetails: z
    .object({
      redeemedBy: z.string().optional(),
      redeemedByUsername: z.string().optional(),
      selectedTeamId: z.string().optional(),
      productId: z.string().optional(),
    })
    .nullable()
    .optional(),
});

export type TriggerPoll = z.infer<typeof TriggerPollSchema>;

export const TriggerPollsListSchema = z.object({
  polls: TriggerPollSchema.omit({
    options: true,
    votes: true,
  }).array(),
  nextPageToken: z.string().optional(),
});
