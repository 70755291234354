import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteAward } from "../../../../api/tournaments";

export default function useDeleteAward({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deleteMatch"],
    mutationFn: async ({ awardId }: { awardId: string }) => {
      await deleteAward(awardId);
      await queryClient.invalidateQueries(["awards"]);
    },
    onSuccess,
    onError,
  });
}
