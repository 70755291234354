import { EventsTable } from "./components/EventsTable";
import PageContainer from "@/components/Layout/PageContainer";
import { AddEditEventModal } from "../shared/components/AddEditEventModal";

export const MetricsViewershipEventsPage = () => {
  return (
    <div className="w-full">
      <PageContainer>
        <div className="flex w-full items-center justify-between gap-3">
          <header className="flex flex-col gap-2">
            <div className="flex gap-4">
              <h1 className="text-heading">Events</h1>
              <AddEditEventModal />
            </div>
            <div className="w-fit whitespace-nowrap font-style-b2-body-copy">
              Viewership data is tracked on a minute by minute basis from the start of the event until the end via the
              events assigned primary channels.
            </div>
          </header>
        </div>
        <EventsTable />
      </PageContainer>
    </div>
  );
};
