import { useMutation, useQueryClient } from "@tanstack/react-query";
import { archiveBroadcast } from "../../../../../api/broadcasts";
import { Patron } from "../../../../../providers/PatronProvider/types";

const useArchiveBroadcast = () => {
  const queryClient = useQueryClient();

  const result = useMutation({
    mutationKey: ["archiveBroadcast"],
    mutationFn: ({
      patron,
      broadcastId,
    }: {
      patron: Patron;
      broadcastId: string;
    }) => archiveBroadcast({ patron, id: broadcastId }),
    onSuccess: () => {
      void queryClient.invalidateQueries(["broadcasts"]);
    },
  });

  return result;
};
export default useArchiveBroadcast;
