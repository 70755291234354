import { z } from "zod";

export const DbCircuitSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  gameId: z.string(),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  updatedAt: z.string().datetime().nullable(),
});

export type DbCircuit = z.infer<typeof DbCircuitSchema>;

export const CircuitCreateRequestSchema = DbCircuitSchema.pick({
  name: true,
  gameId: true,
  startDate: true,
  endDate: true,
});

export type CircuitCreateRequest = z.infer<typeof CircuitCreateRequestSchema>;

export const UpdateCircuitRequestSchema = DbCircuitSchema.pick({
  startDate: true,
  endDate: true,
}).partial();

export type UpdateCircuitRequest = z.infer<typeof UpdateCircuitRequestSchema>;
