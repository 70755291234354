import { z } from "zod";
import { TeamSchema } from "./teams";

export const PlayerSchema = z.object({
  id: z.string(),
  nickname: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  nationality: z.string().nullable(),
  dateOfBirth: z.coerce.date().nullable(),
  ingameId: z.string().nullable(),
  gameId: z.string(),
  isRetired: z.boolean().optional().default(false),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date().nullable(),
});

export type Player = z.infer<typeof PlayerSchema>;

export const PlayerWithRoleSchema = PlayerSchema.extend({
  role: z.string().nullable(),
});
export type PlayerWithRole = z.infer<typeof PlayerWithRoleSchema>;

export const PlayerHistorySchema = z.object({
  id: z.string(),
  fromDate: z.string(),
  role: z.string().nullable(),
  teamId: z.string().nullable(),
});
export type PlayerHistory = z.infer<typeof PlayerHistorySchema>;

export const PlayerHistoryWithMapCountSchema = PlayerHistorySchema.extend({
  mapsPlayed: z.number(),
});
export type PlayerHistoryWithMapCount = z.infer<typeof PlayerHistoryWithMapCountSchema>;

export const DotaRolesSchema = z.enum(["carry", "mid", "offlane", "soft-support", "hard-support"]);

export type DotaRoles = z.infer<typeof DotaRolesSchema>;

export const DotaMetadataSchema = z.object({
  role: DotaRolesSchema.nullable(),
  tiAppearances: z.number().nullable(),
});

export type DotaMetadata = z.infer<typeof DotaMetadataSchema>;

export const CsRolesSchema = z.enum(["awper", "rifler"]);

export type CsRoles = z.infer<typeof CsRolesSchema>;

export const CsMetadataSchema = z.object({
  role: CsRolesSchema.nullable(),
  majorAppearances: z.number().nullable(),
});

export type CsMetadata = z.infer<typeof CsMetadataSchema>;

export const DetailedPlayerSchema = PlayerWithRoleSchema.extend({
  team: TeamSchema.nullable(),
  dotaMetadata: DotaMetadataSchema.nullish(),
  csMetadata: CsMetadataSchema.nullish(),
});
export type DetailedPlayer = z.infer<typeof DetailedPlayerSchema>;

export const PlayerV2Schema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  nickname: z.string(),
  nationality: z.string().nullable(),
  dateOfBirth: z.coerce.date().nullable(),
  role: z.string().nullish(),
  ingameId: z.string().nullish(),
  team: TeamSchema.nullish(),
});
export type PlayerV2 = z.infer<typeof PlayerV2Schema>;

export const LineupV2Schema = z.object({
  teamA: z.object({
    id: z.string(),
    name: z.string(),
    shortName: z.string(),
    nationality: z.string(),
    players: PlayerV2Schema.array(),
  }),
  teamB: z.object({
    id: z.string(),
    name: z.string(),
    shortName: z.string(),
    nationality: z.string(),
    players: PlayerV2Schema.array(),
  }),
});
