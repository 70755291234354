import { useContext } from "react";
import { AuthContext } from "..";
import { AuthProviderValue } from "..";

type UseAuth = Omit<AuthProviderValue, "authService">;

export function useAuth(): UseAuth {
  const authContext = useContext(AuthContext);

  const auth = {
    ...authContext,
  };

  delete auth.authService;

  return auth as UseAuth;
}
