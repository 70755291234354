import { Button } from "@mui/material";
import { useGetCsQuizQuestions } from "../../../api/csquiz/getCsQuizQuestions/useGetCsQuizQuestions";
import { Add } from "@mui/icons-material";
import { AddQuestionModal } from "./AddQuestionModal";
import { useState } from "react";
import { Question } from "../../../api/csquiz/shared/types";
import { QuestionDetailedModal } from "./QuestionDetailedModal";
import { QuestionsTable } from "./QuestionsTable";

export const QuestionsTab = () => {
  const { data: questions, isFetching, isError } = useGetCsQuizQuestions();
  const [isAddQuestionModalOpen, setIsAddQuestionModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);

  return (
    <>
      <div className="mb-8 flex items-center justify-between">
        <span className="font-style-b2-body-copy">
          Daily scheduled questions will be selected randomly from the list of questions bellow.
        </span>
        <Button onClick={() => setIsAddQuestionModalOpen(true)} variant="contained">
          <Add className="mr-2" />
          Add a new question
        </Button>
      </div>
      <QuestionsTable
        questions={questions ?? []}
        isLoading={isFetching}
        isError={isError}
        onRowClick={(question) => setSelectedQuestion(question)}
      />
      <AddQuestionModal isOpen={isAddQuestionModalOpen} onClose={() => setIsAddQuestionModalOpen(false)} />
      {selectedQuestion && (
        <QuestionDetailedModal isOpen onClose={() => setSelectedQuestion(null)} question={selectedQuestion} />
      )}
    </>
  );
};
