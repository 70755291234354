import { Breadcrumbs } from "@mui/material";
import { Auditlogs } from "./Auditlogs";
import { Link } from "react-router-dom";

export default function AuditlogsPage() {
  return (
    <div className="m-8 w-full max-w-7xl">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <span> Auditlogs </span>
      </Breadcrumbs>
      <div className="w-full font-style-b1-body-copy">
        <Auditlogs />
      </div>
    </div>
  );
}
