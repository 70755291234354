import { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Stream } from "../../../../types/Streams";
import { DateTime } from "luxon";
import { StreamDetailsDialog } from "../StreamDetailsDialog";
import StreamDetailsIconButton from "../StreamDetailsCell";
import EditStreamDetailsCell from "../EditStreamCell";
import { EditStreamDialog } from "../EditStreamDialog";
import StreamActionCell from "../ActionCell";
import useFetchStreamById from "../../../../hooks/useFetchStreamById";

interface StreamsTableProps {
  streams: Stream[];
  isLoading: boolean;
  refetch: () => void;
}

const tableHeaders = [
  { id: "Title", label: "Title" },
  { id: "Scheduled", label: "Scheduled at" },
  { id: "Provider", label: "Provider" },
  { id: "Details", label: "Details" },
  { id: "Manage", label: "Manage" },
  { id: "Action", label: "Action" },
  { id: "Status", label: "Status" },
];

export default function StreamsTable({ streams, refetch }: StreamsTableProps) {
  const [selectedDetailsRow, setSelectedDetailsRow] = useState<Stream | undefined>(undefined);
  const [selectedEditRow, setSelectedEditRow] = useState<Stream | undefined>(undefined);
  const { data: stream } = useFetchStreamById({
    id: selectedDetailsRow?.id as string,
    enabled: !!selectedDetailsRow?.id,
  });

  const handleClose = () => {
    setSelectedDetailsRow(undefined);
    setSelectedEditRow(undefined);
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            {tableHeaders.map((column) => (
              <TableHead key={column.id}>{column.label}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {streams.map((stream, i) => (
            <TableRow key={i}>
              <TableCell>{stream.title}</TableCell>
              <TableCell>
                {stream.startTime && DateTime.fromISO(stream.startTime).toFormat("dd/MM/yyyy HH:mm")}
              </TableCell>
              <TableCell>{stream.provider}</TableCell>
              <StreamDetailsIconButton
                onClick={() => {
                  setSelectedDetailsRow(stream);
                }}
              />
              <EditStreamDetailsCell
                onClick={() => {
                  setSelectedEditRow(stream);
                }}
              />
              <StreamActionCell onClick={refetch} stream={stream} />
              <TableCell>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span className="inline-flex">{stream.state}</span>
                    </TooltipTrigger>
                    <TooltipContent>
                      <ul>
                        <li>STARTING</li>
                        <li>RUNNING</li>
                        <li>STOPPING</li>
                        <li>STOPPED</li>
                        <li>OTHER: &lt;value&gt;</li>
                      </ul>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StreamDetailsDialog
        key={`detail-${selectedDetailsRow?.id || "none"}`}
        open={!!selectedDetailsRow}
        refetch={refetch}
        closeDialog={handleClose}
        stream={stream}
      />
      {selectedEditRow && (
        <EditStreamDialog
          key={`edit-${selectedEditRow?.id || "none"}`}
          open={!!selectedEditRow}
          closeDialog={handleClose}
          stream={selectedEditRow}
        />
      )}
    </>
  );
}
