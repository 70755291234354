import { useContext } from "react";

import { AlertContext } from "../index";

const useAlert = () => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error("useAlertContext must be used within a AlertProvider");
  }
  return context;
};

export default useAlert;
