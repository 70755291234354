import { CsEvent } from "../api/video/schemas";

export const getPlayerIdFromEvent = (event: CsEvent) => {
  switch (event.eventType) {
    case "AGAINST_THE_ODDS":
      return event.eventContent?.winningPlayer.playerId;
    case "RAPID":
      return event.eventContent?.playerIngameId;
    case "TAZED":
    case "KNIFE":
    case "ACE":
      return event.eventContent?.attackingPlayer.playerId;
    default:
      return null;
  }
};
