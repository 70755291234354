import axios, { InternalAxiosRequestConfig } from "axios";
import * as cookies from "es-cookie";

import { baseApiURL } from "../../config";

const getDomain = () => {
  return window.location.host.split(".").slice(-2).join(".");
};

export const clearCookies = () => {
  cookies.remove("access_token", {
    domain: `.${getDomain()}`,
  });
  cookies.remove("refresh_token", {
    domain: `.${getDomain()}`,
  });
};

let updateRequest: null | Promise<unknown> = null;

export async function updateTokensRequest() {
  try {
    const token = cookies.get("refresh_token");
    if (!token) {
      throw new Error();
    }

    await axios.post(
      `${baseApiURL}/v1/auth/token`,
      {},
      {
        withCredentials: true,
      },
    );
  } catch (e) {
    clearCookies();
  }
}

export const updateTokens = () => {
  if (updateRequest) {
    return updateRequest;
  }

  updateRequest = new Promise<void>((resolve, reject) => {
    updateTokensRequest().then(() => {
      updateRequest = null;
      resolve();
    }, reject);
  });
  return updateRequest;
};

/*
 * This interceptor added to check session validation before every request to the server
 * Main concept that in every request (except authorization request) should be included access token
 * Should be checked is access token valid (follows JWT rules and is not expired)
 * If token is expired it should request for new tokens pair using refresh token (if it valid also)
 * If in this process any error caused then session is invalid and should be terminated
 * */
const authInterceptor = async (config: InternalAxiosRequestConfig) => {
  let accessToken = cookies.get("access_token");

  // checking if access token exists
  if (accessToken) {
    try {
      // fetch data encrypted in api token
      const payload = JSON.parse(
        window.atob(decodeURIComponent(accessToken.split(".")[1])),
      ) as {
        exp: number;
      };

      // if access token is expired need to request for a new pair of tokens
      if (new Date(payload.exp * 1000) < new Date()) {
        // if refresh token is valid, fetching for a new pair of tokens
        throw new Error();
      }
    } catch (e) {
      // if any error caused while reading data, or in process of requesting
      // then access token is invalid and should fetch for a new pair of tokens
      await updateTokens();
      accessToken = cookies.get("access_token");
    }
  } else {
    await updateTokens();
    accessToken = cookies.get("access_token");
  }

  // including access token in every request in authorization header
  if (accessToken) {
    config.headers.set("Authorization", `Bearer ${accessToken}`);
  }

  return config;
};

export default authInterceptor;
