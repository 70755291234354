import { useQuery } from "@tanstack/react-query";
import { fetchMatchMapStats } from "../../../api/statistics";

const useFetchMatchMapStats = ({
  matchId,
  mapNumber,
  enabled,
}: {
  matchId: string;
  mapNumber: number;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: ["live stats match map", matchId, mapNumber],
    queryFn: () => fetchMatchMapStats(matchId, mapNumber),
    enabled,
  });
};

export default useFetchMatchMapStats;
