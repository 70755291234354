import { Broadcast } from "../../../../types/Broadcasts";

export const reorderBroadcastPriority = (
  liveBroadcasts: Broadcast[],
  startIndex: number,
  endIndex: number
): Broadcast[] => {
  const reOrderedList = [...liveBroadcasts];
  const [movedItem] = reOrderedList.splice(startIndex, 1);
  reOrderedList.splice(endIndex, 0, movedItem);

  for (let i = 0; i < reOrderedList.length; i++) {
    reOrderedList[i].priority = i;
  }

  return reOrderedList;
};
