import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postCorrectAnswer } from "../../../api/pickems";

const usePostCorrectAnswer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["correctAnswers"],
    mutationFn: postCorrectAnswer,
    onSuccess: () => {
      void queryClient.invalidateQueries(["correctAnswers"]);
    },
  });
};

export default usePostCorrectAnswer;
