import axios from "axios";
import { createUploadLink, invalidateCache } from "../../../api/assets";

import { Location } from "../../../api/csguessr/types";
import { createLocation } from "../../../api/csguessr";

interface createLocationMethodProps {
  image: File;
  posX: number;
  posY: number;
  mapId: string;
}

export const createLocationMethod = async (
  props: createLocationMethodProps,
) => {
  const locationUuid = crypto.randomUUID();

  //  Get upload URL
  const data = await createUploadLink("cs-guessr/locations", locationUuid);

  await axios.put(data.uploadUrl, props.image, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": props.image.type,
    },
  });

  // Invalidate cache
  await invalidateCache("cs-guessr/locations", locationUuid);

  // Create location
  const location: Location = {
    id: locationUuid,
    mapId: props.mapId,
    position: {
      x: props.posX,
      y: props.posY,
    },
    image: `cs-guessr/locations/${locationUuid}`,
  };

  await createLocation(location);
};
