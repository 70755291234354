import { Route, Routes } from "react-router-dom";
import { Replays } from "./rl/replays";

export function Statistics() {
  return (
    <Routes>
      <Route index path="/" element={<Replays />} />
      <Route path="/rl/replays" element={<Replays />} />
    </Routes>
  );
}
