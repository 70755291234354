import {
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Breadcrumbs,
  ListSubheaderProps,
  ListSubheader,
  Card,
} from "@mui/material";
import useFetchTournaments from "../hooks/useFetchTournaments";
import { useEffect, useMemo, useState } from "react";
import useFetchCircuits from "../hooks/useFetchCircuits";
import { uniqueId } from "lodash-es";
import { Link, useNavigate, useParams } from "react-router-dom";
import CreateAndEditTournamentModal from "./components/CreateEditTournamentModal";
import { Event, Group, Paid, Today } from "@mui/icons-material";
import { DbTournament } from "../../../api/community-tournaments/schemas/tournament";

function MyListSubheader(props: ListSubheaderProps) {
  return <ListSubheader {...props} />;
}

MyListSubheader.muiSkipListHighlight = true;

const gameIdToName: Record<string, string> = {
  cs: "Counter-Strike 2",
  rl: "Rocket League",
  dota: "Dota 2",
};

export interface tournamentBuilder {
  name?: string;
  startDate?: string;
  endDate?: string | null;
  circuitId?: string;
  id?: string;
  maxNumberOfTeams?: number | null;
  prizePool?: string | null;
  location?: string | null;
  signupStartsAt?: string;
  signupEndsAt?: string;
}

const emptyTournament: tournamentBuilder = {
  name: undefined,
  startDate: undefined,
  endDate: undefined,
  id: undefined,
  circuitId: undefined,
  maxNumberOfTeams: undefined,
  prizePool: undefined,
  location: undefined,
  signupStartsAt: undefined,
  signupEndsAt: undefined,
};

const CommunityTournamentsPage = () => {
  const { circuitId } = useParams();
  const [tournamentModalStatus, setTournamentModalStatus] = useState<"new" | "edit" | "closed">("closed");
  const [editTournament, setEditTournament] = useState<tournamentBuilder>(emptyTournament);
  const [shownTournaments, setShownTournaments] = useState<DbTournament[]>([]);

  const { data: allTournaments } = useFetchTournaments();
  const { data: circuits } = useFetchCircuits();
  const navigate = useNavigate();

  const groupedCircuits = useMemo(() => {
    if (circuits) {
      return circuits.reduce((acc: Record<string, typeof circuits>, circuit) => {
        if (acc[circuit.gameId]) {
          acc[circuit.gameId].push(circuit);
        } else {
          acc[circuit.gameId] = [circuit];
        }
        return acc;
      }, {});
    }
  }, [circuits]);

  useEffect(() => {
    if (circuitId && allTournaments) {
      setShownTournaments(allTournaments?.filter((tournament) => tournament.circuitId === circuitId));
    } else {
      setShownTournaments(allTournaments || []);
    }
  }, [circuitId, allTournaments]);

  return (
    <div className="flex w-full flex-col">
      <Breadcrumbs aria-label="breadcrumb" sx={{ margin: "2rem 1rem 1rem 2rem" }}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <span> Community Tournaments </span>
      </Breadcrumbs>
      <div className="p-7">
        <h1 className="my-3 font-style-desktop-h2">Community Tournaments</h1>

        <FormControl className="w-52">
          <InputLabel id="circuit-select">Circuit</InputLabel>
          <Select
            className="cursor-pointer"
            labelId="circuit-select"
            value={circuitId || "none"}
            label="Circuit"
            onChange={(e) => {
              if (e.target.value === "none") {
                navigate("/community-tournaments/tournaments", {
                  replace: true,
                });
              } else {
                navigate(`/community-tournaments/circuits/${e.target.value}/tournaments`, {
                  replace: true,
                });
              }
            }}
          >
            <MenuItem value="none">All</MenuItem>
            {groupedCircuits &&
              Object.entries(groupedCircuits).map(([gameId, circuits]) => {
                const selectItems = [];
                selectItems.push(
                  <MyListSubheader key={gameId} className="cursor-default bg-inherit">
                    {gameIdToName[gameId]}
                  </MyListSubheader>,
                );
                return selectItems.concat(
                  circuits.map((circuit) => (
                    <MenuItem key={circuit.id} value={circuit.id} className="cursor-pointer">
                      {circuit.name}
                    </MenuItem>
                  )),
                );
              })}
          </Select>
        </FormControl>

        <Button onClick={() => setTournamentModalStatus("new")} className="my-4 block">
          Add Tournament
        </Button>

        <div className="flex flex-wrap gap-4">
          {shownTournaments?.map((tournament) => (
            <Card className="flex w-80 grow-0 flex-col rounded-small p-3" key={tournament.name + uniqueId()}>
              <div className="flex flex-col gap-4 font-style-b2-body-copy">
                <label className="font-style-label-1">{tournament.name}</label>
                <div className="grid grid-cols-2 gap-2 opacity-70">
                  <div className="flex items-center gap-2">
                    <Today />
                    <label>{new Date(tournament.startDate).toISOString().split("T")[0]}</label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Event />
                    <label>{tournament.endDate && new Date(tournament.endDate).toISOString().split("T")[0]}</label>
                  </div>
                  {tournament.maxNumberOfTeams && (
                    <div className="flex items-center gap-2">
                      <Group />
                      <label>{tournament.maxNumberOfTeams}</label>
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <Paid />
                    <label>{tournament.prizePool ?? "-"}</label>
                  </div>
                </div>
              </div>
              <hr className="my-3 w-full self-center border-solid border-slate-400" />
              <div>
                <Button
                  onClick={() => {
                    navigate(`/community-tournaments/circuits/${tournament.circuitId}/tournaments/${tournament.id}`);
                  }}
                >
                  Go to Tournament
                </Button>
                <Button
                  onClick={() => {
                    setEditTournament(tournament);
                    setTournamentModalStatus("edit");
                  }}
                >
                  Edit
                </Button>
              </div>
            </Card>
          ))}
        </div>
        <CreateAndEditTournamentModal
          circuits={circuits}
          handleChange={setEditTournament}
          tournament={circuitId ? { ...editTournament, circuitId } : editTournament}
          circuitId={circuitId}
          tournamentModalStatus={tournamentModalStatus}
          handleClose={() => {
            setTournamentModalStatus("closed");
            setEditTournament(emptyTournament);
          }}
        />
      </div>
    </div>
  );
};

export default CommunityTournamentsPage;
