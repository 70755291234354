import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { DetailedPlayer } from "../../../../api/tournaments/schemas/players";
import { useState } from "react";
import useUpdatePlayerRole from "../hooks/useUpdatePlayerRole";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface ChangeRoleModalProps {
  changeRoleModalIsOpen: boolean;
  handleClose: () => void;
  player: DetailedPlayer;
  teamId: string;
}

export const ChangeRoleModal = ({ changeRoleModalIsOpen, handleClose, player, teamId }: ChangeRoleModalProps) => {
  const alert = useAlert();

  const [fromDate, setFromDate] = useState<DateTime | null>(null);
  const [role, setRole] = useState<string>();

  const { mutate: updatePlayerRole } = useUpdatePlayerRole({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully updated player role");
      handleClose();
    },
    onError: () => {
      alert.showFailureAlert("Failed to update player role");
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
      <Dialog open={changeRoleModalIsOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Change {player.nickname}&apos;s role</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container flexDirection={"row"} gap={2}>
            <Box>
              <Grid container flexDirection={"column"} gap={2}>
                <Grid item>
                  <DateField
                    variant="standard"
                    required
                    label="From Date"
                    format="dd/LL/yyyy"
                    value={fromDate}
                    onChange={(e) => setFromDate(e)}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container flexDirection={"column"} gap={2}>
                <Grid item>
                  <FormControl variant="standard" sx={{ minWidth: "194px" }}>
                    <InputLabel>Role</InputLabel>
                    <Select
                      required
                      onChange={(e) => {
                        setRole(e.target.value as string);
                      }}
                    >
                      <MenuItem key={"player"} value={"player"}>
                        Player
                      </MenuItem>
                      <MenuItem key={"substitute"} value={"substitute"}>
                        Substitute
                      </MenuItem>
                      <MenuItem key={"coach"} value={"coach"}>
                        Coach
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!fromDate || !role || !teamId) {
                alert.showInfoAlert("You need to fill out all required fields");
                return;
              }

              updatePlayerRole({
                playerId: player.id,
                fromDate: fromDate.toJSDate(),
                role,
                teamId,
              });
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
