import axios from "axios";
import { baseApiURL } from "../../config";
import { Patron } from "../../providers/PatronProvider/types";

import { AdditionalVideo, Broadcast } from "../../types/Broadcasts";
import { Stream } from "../../types/Streams";
import authInterceptor from "../authInterceptor";

const getBaseUrl = (patron: Patron) => `${baseApiURL}/v1/patrons/${patron}/broadcasts`;
const broadcastsApi = axios.create({});
broadcastsApi.interceptors.request.use(authInterceptor);

export async function getBroadcasts({ patron }: { patron: Patron }): Promise<Array<Broadcast>> {
  const { data } = await broadcastsApi.get<Array<Broadcast>>(`${getBaseUrl(patron)}`);

  return data;
}

export async function getBroadcastById({ patron, id }: { patron: Patron; id: string }): Promise<Broadcast> {
  const { data } = await broadcastsApi.get<Broadcast>(`${getBaseUrl(patron)}/${id}`);

  return data;
}

export async function updateBroadcast({
  patron,
  id,
  payload,
}: {
  patron: Patron;
  id: string;
  payload: Broadcast;
}): Promise<void> {
  return broadcastsApi.patch(`${getBaseUrl(patron)}/${id}`, payload);
}

export async function broadcastGoLive({ patron, id }: { patron: Patron; id: string }): Promise<void> {
  return broadcastsApi.post(`${getBaseUrl(patron)}/${id}/go-live`);
}

export async function broadcastGoOffline({ patron, id }: { patron: Patron; id: string }): Promise<void> {
  return broadcastsApi.post(`${getBaseUrl(patron)}/${id}/go-offline`);
}

export type CreateBroadcastPayload = Pick<Broadcast, "title" | "slug" | "description" | "streamProvider" | "gameIds"> & {
  videoHighestProfile: Stream["highestProfile"];
  videoInputTier: Stream["inputTier"];
};

export async function createBroadcast({
  patron,
  payload,
}: {
  patron: Patron;
  payload: CreateBroadcastPayload;
}): Promise<void> {
  return broadcastsApi.post(`${getBaseUrl(patron)}`, payload);
}

export async function updateBroadcastPriority({
  patron,
  payload,
}: {
  patron: Patron;
  payload: { id: string; priority: number }[];
}): Promise<void> {
  return broadcastsApi.post(`${getBaseUrl(patron)}/update-priority`, payload);
}

export async function archiveBroadcast({ patron, id }: { patron: Patron; id: string }): Promise<void> {
  return broadcastsApi.delete(`${getBaseUrl(patron)}/${id}`);
}

export async function addAdditionalVideo({
  patron,
  broadcastId,
  name,
  offset,
}: {
  patron: Patron;
  broadcastId: string;
  name: string;
  offset: number;
}): Promise<AdditionalVideo> {
  const { data } = await broadcastsApi.post<AdditionalVideo>(`${getBaseUrl(patron)}/${broadcastId}/additional-video`, {
    name,
    offset,
  });
  return data;
}

export async function updateAdditionalVideo({
  patron,
  broadcastId,
  videoId,
  payload,
}: {
  patron: Patron;
  broadcastId: string;
  videoId: string;
  payload: Partial<AdditionalVideo>;
}): Promise<void> {
  return broadcastsApi.patch(`${getBaseUrl(patron)}/${broadcastId}/additional-video/${videoId}`, payload);
}

export async function removeAdditionalVideo({
  patron,
  broadcastId,
  videoId,
}: {
  patron: Patron;
  broadcastId: string;
  videoId: string;
}): Promise<void> {
  return broadcastsApi.delete(`${getBaseUrl(patron)}/${broadcastId}/additional-video`, {
    data: { videoId },
  });
}
