import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AwardResponse } from "../../../../api/tournaments/schemas/awards";
import { useState } from "react";
import { Tournament } from "../../../../api/tournaments/schemas/tournaments";
import { Player } from "../../../../api/tournaments/schemas/players";
import { Team } from "../../../../api/tournaments/schemas/teams";
import useCreateAward from "../../hooks/awards/useCreateAward";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface CreateAwardModalProps {
  isOpen: boolean;
  tournaments: Tournament[];
  players: Player[];
  teams: Team[];
  close: () => void;
}

const newAward: AwardResponse = {
  id: "NEW",
  name: "",
  tournament: null,
  team: null,
  player: null,
  type: "tournament",
  dateAwarded: null,
};

export const CreateAwardModal = ({ isOpen, tournaments, players, teams, close }: CreateAwardModalProps) => {
  const alert = useAlert();
  const [award, setAward] = useState<AwardResponse>(newAward);

  const { mutate: createAward } = useCreateAward({
    onSuccess: () => {
      alert.showSuccessAlert("Successfully created award");
      close();
    },
  });

  const changeProperty = ({
    property,
    value,
  }: {
    property: keyof Pick<AwardResponse, "name" | "dateAwarded" | "type" | "tournament" | "player" | "team">;
    value: string | Date | { id: string; name: string } | { id: string; nickname: string } | null;
  }) => {
    setAward((prev) => {
      return {
        ...prev,
        [property]: value,
      };
    });
  };
  const playerOptions: { id: string; nickname: string }[] = [{ id: "null", nickname: "No Player" }].concat(
    players.map((player) => ({ id: player.id, nickname: player.nickname })),
  );
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Dialog
          open={isOpen}
          onClose={() => {
            setAward(newAward);
            close();
          }}
          PaperProps={{
            sx: {
              maxWidth: "1500px",
              width: "100%",
              padding: "10px",
            },
          }}
        >
          <DialogContent>
            <div className="mb-7 font-style-b1-body-copy"> Create Award</div>
            <Grid container spacing={1}>
              <Grid item sm={15}>
                <TextField
                  variant="outlined"
                  size="medium"
                  label="Name"
                  defaultValue={award.name}
                  onChange={(e) =>
                    changeProperty({
                      property: "name",
                      value: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item sm={15}>
                <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                  <InputLabel>Tournament</InputLabel>
                  <Select
                    value={award.tournament?.id || "null"}
                    onChange={(e) => {
                      const tournament = tournaments.find((tournament) => tournament.id === e.target.value);
                      const value = tournament ? { id: tournament.id, name: tournament.name } : null;
                      changeProperty({
                        property: "tournament",
                        value: value,
                      });
                    }}
                  >
                    <MenuItem value="null">No Tournament</MenuItem>
                    <Divider />
                    {tournaments.map((tournament) => (
                      <MenuItem key={tournament.id} value={tournament.id}>
                        {tournament.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                  <InputLabel>Team</InputLabel>
                  <Select
                    value={award.team?.id || "null"}
                    onChange={(e) => {
                      const team = teams.find((team) => team.id === e.target.value);
                      const value = team ? { id: team.id, name: team.name } : null;
                      changeProperty({
                        property: "team",
                        value: value,
                      });
                    }}
                  >
                    <MenuItem value="null">No Team</MenuItem>
                    <Divider />
                    {teams.map((team) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <Autocomplete
                  id="select-player"
                  options={playerOptions || []}
                  getOptionLabel={(option) => option.nickname}
                  onChange={(_, newValue: { id: string; nickname: string } | null) => {
                    changeProperty({
                      property: "player",
                      value: newValue ? { id: newValue.id, nickname: newValue.nickname } : null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={award.player ? award.player.nickname : "No Player"}
                      placeholder="Select / Search Player"
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.nickname}
                    </li>
                  )}
                />
              </Grid>
              <Grid item sm={6}>
                <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={award.type}
                    onChange={(e) =>
                      changeProperty({
                        property: "type",
                        value: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="tournament">tournament</MenuItem>
                    <MenuItem value="mvp">mvp</MenuItem>
                    <MenuItem value="misc">misc</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl variant="standard" sx={{ minWidth: "100%" }}>
                  <DatePicker
                    label="Date Awarded"
                    format="dd/LL/yyyy"
                    value={award.dateAwarded ? DateTime.fromJSDate(award.dateAwarded) : DateTime.fromJSDate(new Date())}
                    onChange={(date) =>
                      changeProperty({
                        property: "dateAwarded",
                        value: date?.toJSDate() || null,
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                className="mt-5 w-36"
                onClick={() => createAward({ award })}
                disabled={!award.name || award.name === ""}
              >
                Save
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </LocalizationProvider>
    </>
  );
};
