import { useQuery } from "@tanstack/react-query";
import { getPermissions } from "../../../../api/auth";

import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";

const useFetchPermissions = () => {
  const { showFailureAlert } = useAlert();

  return useQuery({
    queryKey: ["userPermissions"],
    queryFn: getPermissions,
    onError() {
      showFailureAlert("Something went wrong while fetching the permissions");
    },
    staleTime: Infinity,
  });
};

export default useFetchPermissions;
