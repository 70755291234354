import { z } from "zod";

export const VetoSchema = z.object({
  id: z.string(),
  matchId: z.string(),
  teamId: z.string().nullable(),
  type: z.enum(["pick", "ban"]),
  map: z.string(),
  index: z.number().int(),
});

export type Veto = z.infer<typeof VetoSchema>;
