import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Tournament } from "../../../../../api/tournaments/schemas/tournaments";
import { useEffect, useMemo, useState } from "react";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";
import { TournamentTeamsAccordion } from "./components/TournamentTeamsAccordion";
import { DotaTournamentsAutocomplete } from "./components/DotaTournamentsAutocomplete";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Link } from "react-router-dom";
import useFetchCircuits from "../../../hooks/useFetchCircuits";
import { usePatchTournament } from "../../../../../api/tournaments/hooks/usePatchTournament";
import { usePostTournament } from "../../../../../api/tournaments/hooks/usePostTournament";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
import { TournamentSponsorsAccordion } from "./components/TournamentSponsorsAccordion";
import { TournamentTicketingAccordion } from "./components/TournamentTicketingAccordion";
import { TournamentCustomModulesAccordion } from "./components/TournamentCustomModulesAccordion";
import useFetchTournamentById from "../../../hooks/useFetchTournamentById";

const CreateAndEditTournamentModal = ({
  isOpen,
  onClose,
  editingTournamentId,
  selectedCircuitId,
}: {
  isOpen: boolean;
  onClose: () => void;
  editingTournamentId?: string;
  selectedCircuitId?: string;
}) => {
  const { data: editingTournament } = useFetchTournamentById(editingTournamentId);
  const alert = useAlert();

  // Tournament details:
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [circuitId, setCircuitId] = useState<string>(selectedCircuitId ?? "");
  const [numberOfTeams, setNumberOfTeams] = useState<number>(0);
  const [location, setLocation] = useState("");
  const [externalId, setExternalId] = useState<string>("");
  const [externalStreamUrl, setExternalStreamUrl] = useState("");
  const { mutate: patchTournament, isLoading: isPatchingTournament } = usePatchTournament(editingTournament?.id);
  const { mutate: postTournament, isLoading: isPostingTournament } = usePostTournament();
  const { data: circuits } = useFetchCircuits();

  // Sponsors:
  const [sponsorsHeader, setSponsorsHeader] = useState(editingTournament?.metadata?.sponsors?.header ?? "");

  // Custom CTA
  const [headerCtaText, setHeaderCtaText] = useState("");
  const [headerCtaUrl, setHeaderCtaUrl] = useState("");

  useEffect(() => {
    if (editingTournament) {
      // Tournament details:
      setName(editingTournament.name);
      setStartDate(editingTournament.startDate);
      setEndDate(editingTournament.endDate);
      setCircuitId(editingTournament.circuitId);
      setNumberOfTeams(editingTournament.numberOfTeams ?? 0);
      setLocation(editingTournament.location ?? "");
      setExternalId(editingTournament.externalId ?? "");
      setExternalStreamUrl(editingTournament.metadata?.externalStreamUrl ?? "");

      // Sponsors:
      setSponsorsHeader(editingTournament.metadata?.sponsors?.header ?? "");

      // Custom CTA:
      setHeaderCtaText(editingTournament.metadata?.headerCta?.text ?? "");
      setHeaderCtaUrl(editingTournament.metadata?.headerCta?.url ?? "");
    }
  }, [editingTournament]);

  const isValid = useMemo(() => {
    return !!name && !!startDate && !!endDate && startDate <= endDate && !!circuitId && !!numberOfTeams;
  }, [name, startDate, endDate, circuitId, numberOfTeams]);

  const handleSubmit = () => {
    if (!name || !startDate || !endDate || startDate > endDate || !circuitId || !numberOfTeams) {
      return;
    }

    if (editingTournament) {
      const metadata: Tournament["metadata"] = {
        ...(editingTournament.metadata ?? { _t: "dota_tournament" }),
        externalStreamUrl: externalStreamUrl ?? undefined,
        headerCta: headerCtaText && headerCtaUrl ? { text: headerCtaText, url: headerCtaUrl } : undefined,
        sponsors: { header: sponsorsHeader, items: editingTournament.metadata?.sponsors?.items ?? [] },
      };

      // Tournament details:
      void patchTournament(
        {
          startDate,
          endDate,
          numberOfTeams,
          location: location ?? null,
          externalId: externalId ?? null,
          metadata,
        },
        {
          onError: () => {
            alert.showFailureAlert("Failed to edit tournament");
          },
          onSuccess: () => {
            alert.showSuccessAlert("Tournament edited successfully");
            onClose();
          },
        },
      );
    } else {
      void postTournament(
        {
          name,
          startDate,
          endDate,
          circuitId,
          numberOfTeams,
          location: location ?? null,
          externalId: externalId ?? null,
          metadata: externalStreamUrl ? { _t: "dota_tournament", externalStreamUrl } : undefined,
        },
        {
          onError: () => {
            alert.showFailureAlert("Failed to create tournament");
          },
          onSuccess: () => {
            alert.showSuccessAlert("Tournament created successfully");
            onClose();
          },
        },
      );
    }
  };

  const gameId = useMemo(() => {
    return circuits?.find((c) => c.id === circuitId)?.gameId;
  }, [circuits, circuitId]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle>Tournament</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="grid grid-cols-2 items-center gap-4">
            <TextField
              required
              label="Tournament Name"
              variant="outlined"
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              disabled={!!editingTournament}
            />
            <FormControl>
              <InputLabel id="modal-circuit-select">Circuit</InputLabel>
              <Select
                labelId="modal-circuit-select"
                value={circuitId}
                label="Circuit"
                onChange={(e) => {
                  setCircuitId(e.target.value);
                }}
                disabled={!!editingTournament}
              >
                {circuits?.map((circuit) => (
                  <MenuItem value={circuit.id} key={circuit.id}>
                    {circuit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider>
              <DesktopDatePicker
                label="Start Date"
                format="dd/LL/yyyy"
                onChange={(e) => {
                  if (!e) return;

                  setStartDate(e.toJSDate());
                }}
                value={startDate ? DateTime.fromJSDate(startDate) : undefined}
              />
              <DesktopDatePicker
                label="End Date"
                format="dd/LL/yyyy"
                onChange={(e) => {
                  if (!e) return;

                  setEndDate(e.toJSDate());
                }}
                value={endDate ? DateTime.fromJSDate(endDate) : undefined}
                minDate={startDate ? DateTime.fromJSDate(startDate) : undefined}
              />
            </LocalizationProvider>
            <TextField
              required
              label="Number Of Teams"
              variant="outlined"
              type="number"
              onChange={(e) => {
                setNumberOfTeams(Number(e.target.value));
              }}
              value={numberOfTeams}
            />
            <TextField
              label="Location"
              variant="outlined"
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              value={location}
            />
            {gameId === "dota" ? (
              <DotaTournamentsAutocomplete
                selectedTournament={externalId}
                setSelectedTournament={(value: string | null) => {
                  setExternalId(value ?? "");
                }}
              />
            ) : (
              <TextField
                label="External ID"
                variant="outlined"
                onChange={(e) => {
                  setExternalId(e.target.value);
                }}
                value={externalId || ""}
              />
            )}
            {gameId === "dota" && (
              <TextField
                label="External Stream URL"
                variant="outlined"
                onChange={(e) => {
                  setExternalStreamUrl(e.target.value);
                }}
                value={externalStreamUrl}
              />
            )}
          </div>
          {editingTournament && (
            <>
              <TournamentTeamsAccordion tournamentId={editingTournament.id} circuitId={editingTournament.circuitId} />
              <TournamentSponsorsAccordion
                tournamentId={editingTournament.id}
                sponsorsHeader={sponsorsHeader}
                setSponsorsHeader={setSponsorsHeader}
              />
              <TournamentTicketingAccordion tournamentId={editingTournament.id} />
              <TournamentCustomModulesAccordion
                headerCtaText={headerCtaText}
                headerCtaUrl={headerCtaUrl}
                setHeaderCtaText={setHeaderCtaText}
                setHeaderCtaUrl={setHeaderCtaUrl}
              />
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          {editingTournament && (
            <Button component={Link} to={`/to/tournaments/${editingTournament.id}`} variant="text" className="mr-auto">
              Go To Tournament
            </Button>
          )}
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!isValid}
            onClick={() => handleSubmit()}
            loading={isPatchingTournament || isPostingTournament}
            startIcon={<Save />}
            loadingPosition="start"
          >
            Save Tournament Details
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default CreateAndEditTournamentModal;
