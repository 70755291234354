/* eslint-disable tailwindcss/no-custom-classname */
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "../src/tailwind/App.css";
import AlertProvider from "./providers/AlertProvider";
import AuthProvider from "./providers/AuthProvider";
import PatronProvider from "./providers/PatronProvider";
import Assets from "./routes/Assets";
import ContentPage from "./routes/Content";
import { FantasyPage } from "./routes/Fantasy";
import FantasyDetailsPage from "./routes/FantasyDetailsPage";
import Homepage from "./routes/Home";
import Login from "./routes/Login";
import Moderation from "./routes/Moderation";
import PickemsPage from "./routes/Pickems";
import PickemsDetailsPage from "./routes/PickemsDetailsPage";
import Streams from "./routes/Streams";
import ChatTriggers from "./routes/ChatTriggers";
import UserProfilePage from "./routes/UserProfile";
import UsersPage from "./routes/Users";
import CircuitsPage from "./routes/Tournaments/Circuits";
import { Products } from "./routes/points/Products";
import { GrantDashboard } from "./routes/points/GrantDashboard";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { flagsmithEnvironmentId } from "./config";
import Broadcasts from "./routes/Broadcasts";
import { Collections } from "./routes/points/Collections";
import { Delivery } from "./routes/points/Delivery";
import { CSGuessr } from "./routes/CSGuessr";
import Roles from "./routes/Users/Roles";
import ModerationAI from "./routes/ModerationAI";
import TournamentsPage from "./routes/Tournaments/Tournaments";
import PlayersPage from "./routes/Tournaments/Players";
import { TeamsPage } from "./routes/Tournaments/Teams";
import TournamentStages from "./routes/Tournaments/TournamentStages";
import { EmulationRoutes } from "./routes/Emulations/EmulationRoutes";
import { AwardsPage } from "./routes/Tournaments/Awards";
import { LiveStatsPage } from "./routes/LiveStats";
import { BroadcastAds } from "./routes/BroadcastAds";
import { RLCSRankings } from "./routes/Tournaments/RLCSRankings";
import { Statistics } from "./routes/Statistics/Statistics";
import Notifications from "./routes/Notifications/Notifications";
import { DotaTest } from "./routes/DotaTest";
import Auditlogs from "./routes/Auditlogs";
import CommunityCircuitsPage from "./routes/CommunityTournaments/Circuits";
import CommunityTournamentsPage from "./routes/CommunityTournaments/Tournaments";
import CommunityTournamentStages from "./routes/CommunityTournaments/TournamentStages";
import TranscriptionsPage from "./routes/Transcription";
import ClipCurator from "./routes/ClipCurator";
import { MetricsViewershipEventsPage } from "./routes/MetricsViewership/routes/EventsPage";
import ClipCuratorOperations from "./routes/ClipCurator/Operations";
import { CSQuizPage } from "./routes/CSQuiz";
import { MonitoringSNS } from "./routes/Monitoring";
import { MetricsViewershipChannelsPage } from "./routes/MetricsViewership/routes/ChannelsPage";
import { MetricsViewershipEventDetailsPage } from "./routes/MetricsViewership/routes/EventDetailsPage";
import { CounterStriklePage } from "./routes/CounterStrikle";
import { DotlePage } from "./routes/Dotle";
import { TournamentsIssuesPage } from "./routes/Issues/routes/Tournaments";
import { EntityDetailsPage } from "./routes/Issues/routes/Tournaments/routes/EntityDetails";
import { ProtectedRoute } from "./components/ProtectedRoute";
import BackofficeProvider from "./providers/BackofficeProvider";
import { MediaKitPage } from "./routes/MediaKit";
import { PirateStreams } from "./routes/PirateStreams";
import { RocketLeagueStatistics } from "./routes/RocketLeagueStatistics";
import { GlobalLoadingIndicator } from "./components/GlobalLoadingIndicator";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <FlagsmithProvider
      options={{
        environmentID: flagsmithEnvironmentId,
        api: "https://fs.blast.tv/api/v1/",
      }}
      flagsmith={flagsmith}
    >
      <ThemeProvider theme={darkTheme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <PatronProvider>
              <BackofficeProvider>
                <AlertProvider>{children}</AlertProvider>
              </BackofficeProvider>
            </PatronProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </FlagsmithProvider>
  );
};

function App() {
  return (
    <AppProvider>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="App flex bg-zinc-800">
        <CssBaseline />
        <GlobalLoadingIndicator />
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Homepage />} />
              <Route path="/users">
                <Route index element={<Navigate to="/users/management" replace />} />
                <Route path="management" element={<UsersPage />} />
                <Route path="roles" element={<Roles />} />
                <Route path="management/:id" element={<UserProfilePage />} />
              </Route>
              <Route path="pickems">
                <Route index element={<PickemsPage />} />
                <Route path=":id" element={<PickemsDetailsPage />} />
              </Route>
              <Route path="points">
                <Route path="products" element={<Products />} />
                <Route path="collections" element={<Collections />} />
                <Route path="delivery" element={<Delivery />} />
                <Route path="grant" element={<GrantDashboard />} />
              </Route>
              <Route path="fantasy">
                <Route index element={<FantasyPage />} />
                <Route path=":seasonId/:eventId" element={<FantasyDetailsPage />} />
              </Route>
              <Route path="dota" element={<DotaTest />} />
              <Route path="content" element={<ContentPage />}>
                <Route index element={<ContentPage />} />
              </Route>
              <Route path="content/clip-curator" element={<ClipCurator />}>
                <Route path="operations">
                  <Route index element={<ClipCuratorOperations />} />
                  <Route path=":id" element={<ClipCuratorOperations />} />
                </Route>
              </Route>

              <Route path="metrics/viewership">
                <Route index element={<Navigate to="/metrics/viewership/events" replace />} />
                <Route path="events">
                  <Route index element={<MetricsViewershipEventsPage />} />
                  <Route path=":id" element={<MetricsViewershipEventDetailsPage />} />
                </Route>
                <Route path="channels" element={<MetricsViewershipChannelsPage />} />
              </Route>

              <Route path="/live">
                <Route path="streams" element={<Streams />} />
                <Route path="broadcasts/*" element={<Broadcasts />} />
                <Route path="broadcast-ads" element={<BroadcastAds />} />
                <Route path="moderation/*" element={<Moderation />} />
                <Route path="moderation-ai" element={<ModerationAI />} />
                <Route path="chat-triggers" element={<ChatTriggers />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="statistics/*" element={<Statistics />} />
                <Route path="emulation/*" element={<EmulationRoutes />} />
                <Route path="transcriptions" element={<TranscriptionsPage />} />
              </Route>
              <Route path="/*" element={<Assets />} />
              <Route path="/csguessr" element={<CSGuessr />} />
              <Route path="/csquiz" element={<CSQuizPage />} />
              <Route path="/counter-strikle" element={<CounterStriklePage />} />
              <Route path="/dotle" element={<DotlePage />} />
              <Route path="/mediakit" element={<MediaKitPage />} />
              <Route path="/developer/auditlogs" element={<Auditlogs />} />
              <Route path="/developer/monitoring/sns" element={<MonitoringSNS />} />
              <Route path="/monitoring">
                <Route path="pirate-streams" element={<PirateStreams />} />
              </Route>

              <Route path="/issues">
                <Route index element={<Navigate to="/issues/tournaments" replace />} />
                <Route path="tournaments">
                  <Route index element={<TournamentsIssuesPage />} />
                  <Route path=":tournamentId/:entity/:entityId/:errorKey" element={<EntityDetailsPage />} />
                </Route>
              </Route>
              <Route path="/community-tournaments">
                <Route path="circuits">
                  <Route index element={<CommunityCircuitsPage />} />
                  <Route path=":circuitId/tournaments">
                    <Route index element={<CommunityTournamentsPage />} />
                    <Route path=":tournamentId" element={<CommunityTournamentStages />} />
                  </Route>
                </Route>
                <Route path="tournaments" element={<CommunityTournamentsPage />} />
              </Route>
              <Route path="/livestats/match/:matchId/maps/:mapNumber" element={<LiveStatsPage />} />
              <Route path="/to">
                <Route path="circuits">
                  <Route path=":gameId" element={<CircuitsPage />} />
                </Route>
                <Route path="tournaments">
                  <Route path=":gameId" element={<TournamentsPage />} />
                  <Route path=":gameId/:tournamentId" element={<TournamentStages />} />
                </Route>
                <Route path="players">
                  <Route path=":gameId" element={<PlayersPage />} />
                </Route>
                <Route path="teams">
                  <Route path=":gameId" element={<TeamsPage />} />
                  <Route path=":gameId/:team" element={<TeamsPage />} />
                </Route>
                <Route path="awards" element={<AwardsPage />} />
                <Route path="rlcs-rankings" element={<RLCSRankings />} />
                <Route path="rl-statistics" element={<RocketLeagueStatistics />} />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AppProvider>
  );
}

export default App;
