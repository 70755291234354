import { Grid } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchUserProfile from "../../../../hooks/useFetchUserProfile";
import useFetchAvatars from "../../hooks/useFetchAvatars";
import EditAvatarForm from "../EditAvatarForm";
import { Button } from "@/components/ui/button";
import { Edit } from "lucide-react";

export default function EditUserAvatars() {
  const { id } = useParams();
  const { data: userProfile } = useFetchUserProfile(id);
  const { data: avatars } = useFetchAvatars();
  const [isEditUserAvatarsModalOpen, setIsEditUserAvatarsModalOpen] = useState(false);
  if (avatars === undefined) {
    return null;
  }

  const filteredAvatars = avatars.filter((avatar) => {
    return avatar.isUnlockOnly;
  });

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <div>
        <div className="flex items-center">
          <h2 className="text-sm">Avatars</h2>
          <Button
            variant="ghost"
            size="icon"
            className="text-emerald-500"
            onClick={() => setIsEditUserAvatarsModalOpen(true)}
          >
            <Edit />
          </Button>
        </div>

        <Grid container width={"500px"} rowSpacing={0.5} columns={{ xs: 4, sm: 8, md: 9 }}>
          {filteredAvatars.map((avatar) => {
            return (
              <Grid key={avatar.id} width="2rem" item xs={1}>
                <div
                  style={{
                    backgroundImage: `url(${avatar.url})`,
                  }}
                  className={classNames("h-12 w-12 border bg-cover bg-center", {
                    "border-green-500": userProfile.avatars?.includes(avatar.id),
                    "border-gray-500": !userProfile.avatars?.includes(avatar.id),
                  })}
                  title={avatar.name}
                >
                  {!userProfile.avatars?.includes(avatar.id) ? <div className="size-full bg-black/50" /> : null}
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
      {isEditUserAvatarsModalOpen && (
        <EditAvatarForm
          open={isEditUserAvatarsModalOpen}
          onClose={() => {
            setIsEditUserAvatarsModalOpen(false);
          }}
          userDetails={userProfile}
          avatars={filteredAvatars}
        />
      )}
    </>
  );
}
