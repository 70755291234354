import { useMutation } from "@tanstack/react-query";
import { updateTeam } from "../../../../api/tournaments";
import { Team } from "../../../../api/tournaments/schemas/teams";

export default function useUpdateTeam({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) {
  return useMutation({
    mutationKey: ["team"],
    mutationFn: ({
      teamId,
      name,
      shortName,
      nationality,
      socialLinks,
      externalId,
    }: {
      teamId: string;
      name?: string;
      shortName?: string;
      nationality?: string;
      socialLinks?: Team["socialLinks"];
      externalId?: string;
    }) =>
      updateTeam({
        teamId,
        name,
        shortName,
        nationality,
        socialLinks,
        externalId,
      }),
    onSuccess,
    onError,
  });
}
