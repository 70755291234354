import { Button, Card, ListItem, ListItemText } from "@mui/material";
import { EditMatch, MatchByTournamentIdResponse } from "../../../../../api/tournaments/schemas/matches";
import { Stage } from "../../../../../api/tournaments/schemas/stages";
import { DbTournamentMetaData } from "../../../../../api/tournaments/schemas/tournaments";
import { Edit } from "@mui/icons-material";

export const StageInfo = ({
  stage,
  matches,
  matchClick,
  editClick,
  tournamentMetadata,
}: {
  stage?: Stage;
  matches: MatchByTournamentIdResponse[];
  matchClick: (match: EditMatch) => void;
  editClick: (stage: Stage) => unknown;
  tournamentMetadata?: DbTournamentMetaData | null;
}) => {
  if (stage === undefined) {
    return <div>No stages</div>;
  }

  const newMatch: MatchByTournamentIdResponse = {
    stageId: stage.id,
    stageName: stage.name,
    stageIndex: stage.index,
    matchId: "NEWMATCH",
    matchName: "",
    matchType: "BO3",
    matchIndex: matches.length,
    scheduledAt: new Date(),
    teams: [],
    metadata:
      tournamentMetadata?._t === "dota_tournament" && tournamentMetadata.externalStreamUrl
        ? {
            _t: "dota_match",
            externalStreamUrl: tournamentMetadata.externalStreamUrl,
          }
        : null,
  };

  return (
    <div className="mt-1 max-w-5xl">
      <Card sx={{ p: 1 }} className="grid grid-cols-4 gap-1">
        <ListItem>
          <ListItemText primary="Name" secondary={stage.name} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Index" secondary={stage.index} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Format" secondary={stage.format} />
        </ListItem>
        <ListItem>
          <Button onClick={() => editClick(stage)} startIcon={<Edit />}>
            Edit stage details
          </Button>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Date"
            secondary={`${stage.startDate.toISOString().split("T")[0]} - ${stage.endDate.toISOString().split("T")[0]}`}
          />
        </ListItem>
        <ListItem className="col-span-2">
          <ListItemText primary="Stage ID" secondary={stage.id} />
        </ListItem>
      </Card>
      <div className="my-2 font-style-desktop-h4">Matches</div>
      <div className="flex flex-col gap-3">
        {matches.length !== 0 && matches !== undefined ? (
          matches.map((match) => (
            <Button
              key={match.matchId}
              color="primary"
              variant="contained"
              className="flex flex-col items-start gap-2"
              onClick={() => {
                matchClick({
                  ...match,
                  teams: match.teams.map((matchTeam) => ({
                    ...matchTeam,
                    prevTeamId: matchTeam.team.id,
                  })),
                });
              }}
            >
              <div>
                {match.matchName} - INDEX {match.matchIndex}
              </div>
              <div className="flex gap-1 font-style-b3-body-copy">
                {match.teams.map((team, index) => (
                  <div key={index}>
                    {team.team.name ? team.team.name : "TBD"}
                    {team.score !== null ? ` (${team.score})` : ""}
                    {index !== match.teams.length - 1 ? " :" : ""}
                  </div>
                ))}
              </div>
            </Button>
          ))
        ) : (
          <div>No matches for stage found</div>
        )}
      </div>
      <Button
        className="mt-4"
        onClick={() => {
          matchClick(newMatch);
        }}
      >
        Add Match
      </Button>
    </div>
  );
};
