import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import useFetchTournamentPrizes from "./hooks/useFetchTournamentPrizes";
import { Prize } from "../../../../../api/tournaments/schemas/prizes";
import { useMemo, useState } from "react";
import { Add, Delete, Edit, Save } from "@mui/icons-material";
import { AddEditPrizeModal } from "./components/AddEditPrizeModal";
import { generateImageUrl } from "../../../../../helpers/images/generateImageUrl";
import { DeletePrizeModal } from "./components/DeletePrizeModal";
import { Tournament } from "../../../../../api/tournaments/schemas/tournaments";
import { usePatchTournament } from "../../../../../api/tournaments/hooks/usePatchTournament";
import { LoadingButton } from "@mui/lab";
import useAlert from "../../../../../providers/AlertProvider/hooks/useAlert";

interface TournamentPrizesModalProps {
  isOpen: boolean;
  onClose: () => void;
  gameId?: string;
  tournament: Tournament;
}

export const TournamentPrizesModal = ({ isOpen, onClose, gameId, tournament }: TournamentPrizesModalProps) => {
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(false);
  const [editingPrizes, setEditingPrizes] = useState<Prize[]>([]);
  const [deletingPrizes, setDeletingPrizes] = useState<Prize[]>([]);

  const tournamentId = tournament.id;
  const { data: prizes } = useFetchTournamentPrizes(tournamentId);
  const [tournamentPrizePool, setTournamentPrizePool] = useState<string>(tournament.prizePool ?? "");
  const { mutate: patchTournamentMutate, isLoading: isUpdatingTournament } = usePatchTournament(tournamentId);
  const alert = useAlert();

  const groupedPrizes = useMemo(
    () =>
      prizes?.reduce<{ [key: string]: Prize[] }>((r, a) => {
        r[a.prize] = r[a.prize] || [];
        r[a.prize].push(a);
        return r;
      }, {}),
    [prizes],
  );

  const rows = useMemo(() => {
    return Object.values(groupedPrizes || {}).sort((a, b) => a[0].positionStart - b[0].positionStart);
  }, [groupedPrizes]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Tournament prizes breakdown</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Prize</TableCell>
                <TableCell>Teams</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Sub Prizes</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((prizes, index) => {
                const prizeTeams = prizes.map((prize) => prize.teamId).filter((teamId) => !!teamId);
                return (
                  <TableRow key={index}>
                    <TableCell>{prizes[0].prize}</TableCell>
                    <TableCell>
                      <div key={index} className="flex gap-1">
                        {prizeTeams.map((teamId, index) => (
                          <img
                            key={index}
                            className="size-6"
                            src={generateImageUrl("teams", teamId ?? "", {
                              format: "auto",
                              height: "24",
                              width: "auto",
                            })}
                          />
                        ))}
                        {!prizeTeams.length && <>-</>}
                      </div>
                    </TableCell>
                    <TableCell>
                      {prizes[0].positionStart === prizes[0].positionEnd
                        ? `Position ${prizes[0].positionStart}`
                        : `Position: ${prizes[0].positionStart} - ${prizes[0].positionEnd}`}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-col gap-1 text-xs">
                        {prizes[0].subPrize?.map((subPrize, i) => {
                          return (
                            <div key={i} className="w-fit rounded-small bg-white/5 px-2 py-0.5">
                              {subPrize}
                            </div>
                          );
                        })}
                      </div>
                    </TableCell>
                    <TableCell className="w-48 text-right">
                      <Button
                        onClick={() => {
                          setEditingPrizes(prizes);
                          setIsAddEditModalOpen(true);
                        }}
                        startIcon={<Edit />}
                        size="small"
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => setDeletingPrizes(prizes)}
                        startIcon={<Delete />}
                        size="small"
                        color="error"
                        className="ml-2"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={5}>
                  <Button onClick={() => setIsAddEditModalOpen(true)} startIcon={<Add />} size="small">
                    Add a Prize
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Divider className="my-8" />
        <div className="flex items-center gap-4">
          <TextField
            label="Tournament Prize Pool"
            variant="outlined"
            type="text"
            placeholder="$500,000"
            fullWidth
            onChange={(e) => {
              setTournamentPrizePool(e.target.value);
            }}
            value={tournamentPrizePool}
            className="max-w-sm"
          />
          <LoadingButton
            variant="contained"
            onClick={() =>
              void patchTournamentMutate(
                { prizePool: tournamentPrizePool },
                {
                  onError: () => {
                    alert.showFailureAlert("Failed to update prize pool");
                  },
                },
              )
            }
            loading={isUpdatingTournament}
            startIcon={<Save />}
            loadingPosition="start"
            disabled={tournamentPrizePool === (tournament.prizePool ?? "")}
          >
            Update Prize Pool
          </LoadingButton>
        </div>
        {isAddEditModalOpen && (
          <AddEditPrizeModal
            tournamentId={tournamentId}
            isOpen={isAddEditModalOpen}
            onClose={() => {
              setEditingPrizes([]);
              setIsAddEditModalOpen(false);
            }}
            editingPrizes={editingPrizes}
            gameId={gameId}
          />
        )}
        {deletingPrizes.length > 0 && (
          <DeletePrizeModal
            isOpen={!!deletingPrizes.length}
            onClose={() => setDeletingPrizes([])}
            deletingPrizes={deletingPrizes}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
