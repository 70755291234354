import { uploadPlayerConfig } from "./uploadPlayerConfig";

export const updatePlayerConfig = async ({
  file,
  playerId,
  fileName,
  onSuccess,
  onError,
}: {
  file: File;
  playerId: string;
  fileName: "config.cfg" | "videodefaults.txt" | "video.txt";
  onSuccess: () => void;
  onError: (message: string) => void;
}) => {
  if (!file || !playerId) return;
  try {
    await uploadPlayerConfig({ file, playerId, fileName });
  } catch (error) {
    onError("Failed to upload player config file");
    return;
  }

  onSuccess();
};
