import { useContext } from "react";
import { BackofficeContext, BackofficeProviderValue } from ".";

export function useBackoffice(): BackofficeProviderValue {
  const backofficeContext = useContext(BackofficeContext);

  const backoffice = {
    ...backofficeContext,
  };

  return backoffice as BackofficeProviderValue;
}
