import { z } from "zod";

const CsEventBaseSchema = z.object({
  id: z.string(),
  matchId: z.string(),
  mapNumber: z.number(),
  roundNumber: z.number(),
  occurredAt: z.string().datetime(),
  priority: z.number(),
});

export const CsEventSchema = z.discriminatedUnion("eventType", [
  // MATCH_START
  CsEventBaseSchema.pick({ id: true, matchId: true, occurredAt: true }).extend({
    eventType: z.literal("MATCH_START"),
    eventContent: z.object({
      teamA: z.object({
        id: z.string(),
        shorthand: z.string(),
      }),
      teamB: z.object({
        id: z.string(),
        shorthand: z.string(),
      }),
    }),
  }),
  // MAP_START
  CsEventBaseSchema.pick({
    id: true,
    matchId: true,
    occurredAt: true,
    mapNumber: true,
  }).extend({
    mapNumber: z.number(),
    eventType: z.literal("MAP_START"),
    eventContent: z.object({
      mapName: z.string(),
    }),
  }),
  // ROUND_END
  CsEventBaseSchema.pick({
    id: true,
    matchId: true,
    occurredAt: true,
    mapNumber: true,
    roundNumber: true,
  }).extend({
    mapNumber: z.number(),
    roundNumber: z.number(),
    eventType: z.literal("ROUND_END"),
  }),

  // Knife Event (Knife kill in the game)
  CsEventBaseSchema.extend({
    eventType: z.literal("KNIFE"),
    eventContent: z
      .object({
        attackingPlayer: z.object({
          playerId: z.string().nullable(),
          side: z.string().nullable(),
        }),
        victimPlayer: z.object({
          playerId: z.string().nullable(),
          side: z.string().nullable(),
        }),
      })
      .optional(),
  }),
  // Tazed Event (Tazed kill in the game)
  CsEventBaseSchema.extend({
    eventType: z.literal("TAZED"),
    eventContent: z
      .object({
        attackingPlayer: z.object({
          playerId: z.string().nullable(),
          side: z.string().nullable(),
        }),
        victimPlayer: z.object({
          playerId: z.string().nullable(),
          side: z.string().nullable(),
        }),
      })
      .optional(),
  }),
  // ACE Event (1 Player getting 5 kills w. any weapon)
  CsEventBaseSchema.extend({
    eventType: z.literal("ACE"),
    eventContent: z
      .object({
        attackingPlayer: z.object({
          playerId: z.string().nullable(),
          side: z.string().nullable(),
        }),
      })
      .optional(),
  }),
  // Quick Round Event (Round ended very quickly. As an example, 15 seconds)
  CsEventBaseSchema.extend({
    eventType: z.literal("QUICK_ROUND"),
    eventContent: z
      .object({
        winningTeam: z.object({
          side: z.string(),
        }),
      })
      .optional(),
  }),
  // Clutch - Win 1 vs >= 2
  CsEventBaseSchema.extend({
    eventType: z.literal("AGAINST_THE_ODDS"),
    eventContent: z
      .object({
        numberOfOpponents: z.number(),
        winningPlayer: z.object({
          playerId: z.string(),
          side: z.string(),
        }),
      })
      .optional(),
  }),
  // Rapid Event (>= 3 Kills within an 8 seconds mark )
  CsEventBaseSchema.extend({
    eventType: z.literal("RAPID"),
    eventContent: z.object({
      numberOfKills: z.number(),
      playerIngameId: z.string().optional(),
    }),
  }),
  // ECO Event (Team on an eco wins the round versus a team with higher impact weapons.)
  CsEventBaseSchema.extend({
    eventType: z.literal("ECO"),
    eventContent: z
      .object({
        winningTeam: z.object({
          side: z.string(),
        }),
      })
      .optional(),
  }),
  CsEventBaseSchema.extend({
    eventType: z.literal("KEKW"),
  }),
  CsEventBaseSchema.extend({
    eventType: z.literal("IQ"),
  }),
  CsEventBaseSchema.extend({
    eventType: z.literal("NINJA_DEFUSE"),
  }),
  CsEventBaseSchema.extend({
    eventType: z.literal("POG"),
  }),
]);
export type CsEvent = z.infer<typeof CsEventSchema>;

export const ClipSchema = z.object({
  matchId: z.string(),
  eventId: z.string(),
  eventType: z.string(),
  src: z.string(),
  harvestJobStatus: z.string(),
  isDownloadable: z.boolean().default(false),
});

export type Clip = z.infer<typeof ClipSchema>;

const SegmentSchema = z.object({
  id: z.number(),
  seek: z.number(),
  start: z.number(),
  end: z.number(),
  text: z.string(),
  tokens: z.array(z.number()),
  temperature: z.number(),
  avg_logprob: z.number(),
  compression_ratio: z.number(),
  no_speech_prob: z.number(),
});

export const TranscriptionSchema = z.object({
  task: z.string(),
  language: z.string(),
  duration: z.number(),
  text: z.string(),
  segments: z.array(SegmentSchema),
});

export type Transcription = z.infer<typeof TranscriptionSchema>;

export const ClipCuratorEventSchema = z.object({
  group: z.string(),
  category: z.string(),
  date: z.string().datetime(),
  id: z.string().uuid(),
  url: z.string().url(),
  playerIds: z.string().array(),
  teamIds: z.string().array(),
  eventType: z.string(),
  tournamentId: z.string(),
  matchId: z.string(),
  mapNumber: z.number(),
  roundNumber: z.number(),
  metadata: z.any(),
});
export type ClipCuratorEvent = z.infer<typeof ClipCuratorEventSchema>;

export const ClipCuratorEventWithMatchDetailsSchema = ClipCuratorEventSchema.extend({
  mapName: z.string().optional(),
  tournamentName: z.string().optional(),
  matchName: z.string().optional(),
  playerName: z.string().optional(),
});

export type ClipCuratorEventWithMatchDetails = z.infer<typeof ClipCuratorEventWithMatchDetailsSchema>;

export const ClipCuratorOperationSchema = z.object({
  operationId: z.string(),
  operationType: z.string(),
  operationOptions: z.any(),
  createdAt: z.string(),
  state: z.string(),
  requesterId: z.string(),
  output: z.string().optional(),
  requesterEmail: z.string().optional(),
});
export type ClipCuratorOperation = z.infer<typeof ClipCuratorOperationSchema>;
