import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMap } from "../../../../api/tournaments";

export default function useDeleteMap({ matchId }: { matchId?: string }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deleteMap"],
    mutationFn: async ({ mapId }: { mapId: string }) => {
      await deleteMap(mapId);
      if (matchId) {
        await queryClient.invalidateQueries(["matchMaps", matchId]);
        await queryClient.refetchQueries(["matchMaps", matchId]);
      } else {
        throw Error("MatchId is undefined cannot reftech tournament data");
      }
    },
  });
}
