import { EyeOff } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import useFetchUserProfile from "../../../../hooks/useFetchUserProfile";
import Emoji from "../../../../types/users/emojis";
import useAddUserEmoji from "../../../Broadcasts/BroadcastDetails/hooks/useDeleteUserEmoji";
import useFetchEmojis from "../../hooks/useFetchEmojis";

export default function AddEmojiForm({
  onClose,
  open,
}: {
  onClose: (args?: { refresh?: boolean }) => void;
  open: boolean;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { id } = useParams();
  const [chosenEmoji, setChosenEmoji] = useState<Emoji | null>(null);
  const { data: userProfile } = useFetchUserProfile(id);
  const { data: emojis } = useFetchEmojis();
  const filteredEmojis = emojis?.filter((emoji) => {
    return emoji.isUnlockOnly && !userProfile?.emojis?.includes(emoji.id);
  });

  const { mutate: startAddEmojiToUser, isSuccess: isEmojiAdded, isLoading: isAddingEmoji } = useAddUserEmoji();

  useEffect(() => {
    if (isEmojiAdded) {
      onClose({ refresh: true });
    }
  }, [isEmojiAdded, onClose]);

  const handleAddEmoji = () => {
    if (!chosenEmoji || !id) {
      return;
    }
    startAddEmojiToUser({
      userId: id,
      emojiId: chosenEmoji.id,
    });
  };

  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add user emoji</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4">
          <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
            <PopoverTrigger asChild>
              <Button variant="outline" role="combobox" className="w-full justify-between">
                {chosenEmoji ? (
                  <>
                    <img src={chosenEmoji.url} className="mr-2 size-4" alt={chosenEmoji.name} />
                    {chosenEmoji.name}
                  </>
                ) : (
                  "Pick an emoji..."
                )}
              </Button>
            </PopoverTrigger>

            <PopoverContent>
              <Command>
                <CommandInput placeholder="Search emojis..." />

                <CommandList>
                  <CommandEmpty>No emoji found.</CommandEmpty>
                  <CommandGroup>
                    {(filteredEmojis ?? [])?.map((emoji) => (
                      <CommandItem
                        key={emoji.id}
                        onSelect={() => {
                          setChosenEmoji(emoji);
                          setIsPopoverOpen(false);
                        }}
                        className="flex items-center gap-2"
                      >
                        <img src={emoji.url} className="size-4" alt={emoji.name} />
                        <span>{emoji.name}</span>
                        {emoji.hidden && <EyeOff className="ml-auto size-4" />}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>

          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button disabled={isAddingEmoji} onClick={() => handleAddEmoji()}>
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
