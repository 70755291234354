import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlert from "../../../../providers/AlertProvider/hooks/useAlert";
import { createAward } from "../../../../api/tournaments";
import { CreateUpdateAwardSchema } from "../../../../api/tournaments/schemas/awards";
import { PlayerWithRole } from "../../../../api/tournaments/schemas/players";

export default function useCreateTournamentAwards({ onSuccess }: { onSuccess: () => void }) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["award"],
    mutationFn: async ({
      tournamentId,
      tournamentName,
      mvpPlayerId,
      mvpTeamId,
      teamId,
      dateAwarded,
      teamPlayers,
    }: {
      tournamentId: string;
      tournamentName: string;
      mvpPlayerId: string | null;
      mvpTeamId: string | null;
      teamId: string | null;
      dateAwarded: Date;
      teamPlayers: PlayerWithRole[];
    }) => {
      //createAward for winning team
      if (teamId !== null) {
        try {
          await createAward(
            CreateUpdateAwardSchema.parse({
              tournamentId,
              name: tournamentName + " Winner",
              playerId: null,
              teamId,
              dateAwarded: dateAwarded.toDateString(),
              type: "tournament",
            }),
          );
          for (const player of teamPlayers) {
            await createAward(
              CreateUpdateAwardSchema.parse({
                tournamentId,
                name: tournamentName + " Winner",
                playerId: player.id,
                teamId: teamId,
                dateAwarded: dateAwarded.toDateString(),
                type: "tournament",
              }),
            );
          }
        } catch (error) {
          alert.showFailureAlert("Error while creating award for Team and players");
          throw error;
        }
      }

      if (mvpPlayerId !== null) {
        try {
          await createAward(
            CreateUpdateAwardSchema.parse({
              tournamentId,
              name: tournamentName + " MVP",
              playerId: mvpPlayerId,
              teamId: mvpTeamId,
              dateAwarded: dateAwarded.toDateString(),
              type: "mvp",
            }),
          );
        } catch (error) {
          alert.showFailureAlert("Error while creating award for MVP");
          throw error;
        }
      }

      void queryClient.invalidateQueries(["awards"]);
    },
    onSuccess,
  });
}
