import { useCreateRlcsSchedule } from "@/api/rocketLeagueStatistics/createRlcsSchedule/useCreateRlcsSchedule";
import { useUpdateRlcsSchedule } from "@/api/rocketLeagueStatistics/updateRlcsSchedule/useUpdateRlcsSchedule";
import { DatePicker } from "@/components/DatePicker";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "@/hooks/use-toast";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { getBallChasingGroupPath, getGroupIdFromPath } from "../../helpers/ballChasingPathHelpers";

interface ScheduleModalProps {
  handleClose: () => void;
  open: boolean;
  initialSchedule?: {
    id: string;
    name: string;
    groupId: string;
    creator: string;
    isActive: boolean;
    startTime: string;
    endTime: string;
  };
}

const ScheduleModal = ({ handleClose, open, initialSchedule }: ScheduleModalProps) => {
  const [name, setName] = useState<string>(initialSchedule?.name ?? "");
  const [creator, setCreator] = useState<string>(initialSchedule?.creator ?? "76561199225615730");
  const [path, setPath] = useState<string>(
    initialSchedule?.groupId ? getBallChasingGroupPath(initialSchedule.groupId) : "",
  );
  const [isActive, setIsActive] = useState<boolean>(initialSchedule?.isActive ?? true);
  const [startTime, setStartTime] = useState<DateTime | null>(
    initialSchedule?.startTime ? DateTime.fromISO(initialSchedule.startTime) : null,
  );
  const [endTime, setEndTime] = useState<DateTime | null>(
    initialSchedule?.endTime ? DateTime.fromISO(initialSchedule.endTime) : null,
  );

  const { mutate: createSchedule, isLoading: isCreatingSchedule } = useCreateRlcsSchedule();
  const { mutate: updateSchedule, isLoading: isUpdatingSchedule } = useUpdateRlcsSchedule();

  useEffect(() => {
    if (initialSchedule) {
      setName(initialSchedule.name);
      setCreator(initialSchedule.creator);
      setPath(getBallChasingGroupPath(initialSchedule.groupId));
      setIsActive(initialSchedule.isActive);
      setStartTime(initialSchedule.startTime ? DateTime.fromISO(initialSchedule.startTime) : null);
      setEndTime(initialSchedule.endTime ? DateTime.fromISO(initialSchedule.endTime) : null);
    }
  }, [initialSchedule]);

  const handleSubmit = () => {
    if (!name || !path || !endTime) {
      toast({
        title: "Error",
        description: "Please fill in all required fields",
        variant: "destructive",
      });
      return;
    }

    const startTimeISO = startTime?.toUTC().toISO();
    const endTimeISO = endTime?.toUTC().toISO();
    if (!startTimeISO || !endTimeISO) {
      toast({
        title: "Error",
        description: "Please fill in all required fields",
        variant: "destructive",
      });
      return;
    }

    const groupId = getGroupIdFromPath(path);
    if (!groupId) {
      toast({
        title: "Error",
        description: "Invalid path. Please enter a valid BallChasing group path",
        variant: "destructive",
      });
      return;
    }

    const scheduleData = {
      name,
      groupId,
      creator,
      isActive,
      startTime: startTimeISO,
      endTime: endTimeISO,
    };

    if (initialSchedule) {
      updateSchedule(
        {
          scheduleId: initialSchedule.id,
          payload: scheduleData,
        },
        {
          onSuccess: () => {
            toast({
              title: `Schedule ${initialSchedule ? "updated" : "created"} successfully`,
              variant: "default",
            });
            handleClose();
          },
          onError: () => {
            toast({
              title: "Failed to update schedule",
              variant: "destructive",
            });
          },
        },
      );
    } else {
      createSchedule(scheduleData, {
        onSuccess: () => {
          toast({
            title: `Schedule ${initialSchedule ? "updated" : "created"} successfully`,
            variant: "default",
          });
          handleClose();
        },
        onError: () => {
          toast({
            title: "Failed to create schedule",
            variant: "destructive",
          });
        },
      });
    }
  };

  const isLoading = isCreatingSchedule || isUpdatingSchedule;

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && handleClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{initialSchedule ? "Edit" : "Create"} Schedule</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Name</label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter schedule name"
              disabled={isLoading}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Path</label>
            <Input
              value={path}
              onChange={(e) => setPath(e.target.value)}
              placeholder="Enter schedule path"
              disabled={isLoading}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Creator&apos;s Steam ID</label>
            <Input
              value={creator}
              onChange={(e) => setCreator(e.target.value)}
              placeholder="Enter creator's Steam ID"
              disabled={isLoading}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Status</label>
            <Select
              value={isActive ? "active" : "inactive"}
              onValueChange={(value) => setIsActive(value === "active")}
              disabled={isLoading}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="active">Active</SelectItem>
                <SelectItem value="inactive">Inactive</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Start Time</label>
            <DatePicker
              value={startTime?.toJSDate()}
              onChange={(value) => setStartTime(value ? DateTime.fromJSDate(value) : null)}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">End Time</label>
            <DatePicker
              value={endTime?.toJSDate()}
              onChange={(value) => setEndTime(value ? DateTime.fromJSDate(value) : null)}
            />
          </div>

          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => handleClose()} disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} disabled={isLoading}>
              {initialSchedule ? "Save Changes" : "Create"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleModal;
