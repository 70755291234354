import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

import { Role } from "../../../../../api/auth/schemas";

import { MultiSelect } from "@/components/multi-select";
import { useEffect, useMemo, useState } from "react";
import useFetchPermissions from "../../hooks/useFetchPermissions";
import useUpdateRole from "../../hooks/useUpdateRole";

interface EditRoleProps {
  role: Role;
  onClose: () => void;
}

const EditRole = ({ onClose, role }: EditRoleProps) => {
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(role.permissions || []);
  const { data: permissions } = useFetchPermissions();
  const { mutate: updateRole, isLoading: isUpdatingRole, isSuccess: isRoleUpdated } = useUpdateRole();

  const selectedPermissionsValue = useMemo(() => {
    if (!permissions) {
      return [];
    }

    return permissions
      .filter((permission) => selectedPermissions?.includes(permission.identifier))
      .map((permission) => permission.identifier);
  }, [selectedPermissions, permissions]);

  useEffect(() => {
    if (!isRoleUpdated) {
      return;
    }

    onClose();
  }, [isRoleUpdated, onClose]);

  const submit = () => {
    updateRole({
      role,
      permissions: [...selectedPermissions],
    });
  };

  return (
    <Dialog open={true} onOpenChange={() => onClose()}>
      <DialogContent>
        <DialogHeader className="flex w-full items-center justify-start gap-2">
          <div className="flex items-center gap-2">
            <DialogTitle>Edit {role.name.toLowerCase()}</DialogTitle>
          </div>
        </DialogHeader>
        <div className="p-4">
          <MultiSelect
            maxCount={99}
            options={
              permissions?.map((permission) => ({
                label: permission.title,
                value: permission.identifier,
              })) || []
            }
            value={selectedPermissionsValue}
            onValueChange={setSelectedPermissions}
          ></MultiSelect>
        </div>
        <DialogFooter>
          <Button onClick={submit} className="h-full" disabled={isUpdatingRole}>
            Save
          </Button>
          <Button variant="outline" className="h-full" onClick={onClose}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditRole;
