import axios from "axios";
import { baseApiURL } from "../../config";
import authInterceptor from "../authInterceptor";
import {
  MatchMapRoundPlayerStat,
  MatchMapRoundStat,
  MatchMapRoundStatSchema,
  MatchMapStat,
  MatchMapStatSchema,
  PlayerStat,
  PlayerStatSchema,
} from "./schemas";

const statisticsApi = axios.create({
  baseURL: `${baseApiURL}/v1/statistics`,
});

statisticsApi.interceptors.request.use(authInterceptor);

export async function createMatchViews(matchUid: string): Promise<void> {
  return statisticsApi.post("/csgo/matches", {
    matchUid,
  });
}

export async function putMatchDelay({
  matchUid,
  delaySeconds,
}: {
  matchUid: string;
  delaySeconds: number;
}): Promise<void> {
  await statisticsApi.put(`/csgo/matches/${matchUid}/delay`, {
    delaySeconds,
  });
}

export async function deleteMatchDelay(matchUid: string): Promise<void> {
  await statisticsApi.delete(`/csgo/matches/${matchUid}/delay`);
}

export const fetchMatchMapRoundStats = async (
  matchUid: string,
  mapNumber: number,
): Promise<MatchMapRoundStat[]> => {
  const { data } = await statisticsApi.get<unknown>(
    `/matches/${matchUid}/maps/${mapNumber}/rounds`,
  );
  try {
    return MatchMapRoundStatSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

export const fetchMatchMapStats = async (
  matchUid: string,
  mapNumber: number,
): Promise<MatchMapStat[]> => {
  const { data } = await statisticsApi.get<unknown>(
    `/matches/${matchUid}/maps/${mapNumber}`,
  );
  try {
    return MatchMapStatSchema.array().parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

interface UpdateMatchMapStatOptions {
  matchId: string;
  mapNumber: number;
  stats: Omit<MatchMapStat, "matchUid" | "mapNumber">;
}
export const updateMatchMapStat = async ({
  matchId,
  mapNumber,
  stats,
}: UpdateMatchMapStatOptions): Promise<MatchMapStat> => {
  const { data } = await statisticsApi.patch<unknown>(
    `/matches/${matchId}/maps/${mapNumber}`,
    stats,
  );
  try {
    return MatchMapStatSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};

interface UpdateMatchMapRoundStatOptions {
  matchId: string;
  mapNumber: number;
  roundNumber: number;
  stats: Omit<
    MatchMapRoundPlayerStat,
    "matchUid" | "mapNumber" | "roundNumber"
  >;
}
export const updateMatchMapRoundStat = async ({
  matchId,
  mapNumber,
  roundNumber,
  stats,
}: UpdateMatchMapRoundStatOptions): Promise<PlayerStat> => {
  const { data } = await statisticsApi.patch<unknown>(
    `/matches/${matchId}/maps/${mapNumber}/rounds/${roundNumber}`,
    stats,
  );
  try {
    return PlayerStatSchema.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
};
