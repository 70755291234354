function convertFileToImage(file: File): Promise<{
  content: string;
  mimeType: string;
}> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        let base64Data = e.target.result;
        if (base64Data instanceof ArrayBuffer) {
          const decoder = new TextDecoder("utf-8");
          base64Data = decoder.decode(base64Data);
        }

        const type = base64Data.split(";")[0].split(":")[1];

        resolve({
          content: base64Data.split("base64,")[1],
          mimeType: type,
        });
      } else {
        reject("Problem with converting");
      }
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
}

export default convertFileToImage;
