import {
  Switch,
  Typography,
  OutlinedInput,
  Snackbar,
  Alert,
  IconButton,
  Grow,
} from "@mui/material";
import { Tune, Assistant, EmojiEmotions, Speed } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  ConfirmSlowModeAction,
  ConfirmSlowModeBufferAction,
  ConfirmAutomodAction,
  ConfirmEmojiOnlyAction,
} from "./ChatSettingsCustomConfirmationDialogs";
import useFetchChatRoomConfig from "../../hooks/useFetchChatRoomConfig";
import useUpdateChatRoomConfig from "../../hooks/useUpdateChatRoomConfig";
import {
  ChatSettingsWrapper,
  MenuIcon,
  SettingsMenu,
  SettingsMenuItem,
} from "./styled";

interface ChatSettingsProps {
  chatId?: string;
}

const ChatSettings: React.FC<ChatSettingsProps> = ({ chatId }) => {
  const { data, isLoading, isRefetching } = useFetchChatRoomConfig({
    chatRoomId: chatId || "",
    enabled: !!chatId,
  });

  const {
    mutate: updateChatRoom,
    isLoading: isUpdatingConfig,
    error: errorUpdatingChatRoom,
  } = useUpdateChatRoomConfig();

  const [isInSlowMode, setIsInSlowMode] = useState(false);
  const [slowModeBuffer, setSlowModeBuffer] = useState(5);
  const [showSlowModeDialog, setShowSlowModeDialog] = useState(false);
  const [showSlowModeBufferDialog, setShowSlowModeBufferDialog] =
    useState(false);

  const [isChatEmojiOnly, setIsChatEmojiOnly] = useState(false);
  const [showEmojiOnlyDialog, setShowEmojiOnlyDialog] = useState(false);

  const [isAutomodEnabled, setIsAutomodEnabled] = useState(true);
  const [showAutomodDialog, setShowAutomodDialog] = useState(false);

  const [isAiAutomodEnabled, setIsAiAutomodEnabled] = useState(true);
  const [showAiAutomodDialog, setShowAiAutomodDialog] = useState(false);

  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  useEffect(() => {
    if (data && !isRefetching) {
      setIsInSlowMode(data.slowMode);
      setSlowModeBuffer(data.slowModeInterval);
      setIsChatEmojiOnly(data.emojiOnlyMode);
      setIsAutomodEnabled(data.isAutomodActive);
      setIsAiAutomodEnabled(data.isAiAutomodActive);
    }
  }, [data, isRefetching]);

  useEffect(() => {
    if (errorUpdatingChatRoom) {
      setShowErrorSnackbar(true);
    }
  }, [errorUpdatingChatRoom]);

  const [showSettings, setShowSettings] = useState(false);
  const menuButtonRef = React.useRef<HTMLButtonElement>(null);

  if (!chatId) return null;

  return (
    <ChatSettingsWrapper>
      <IconButton
        ref={menuButtonRef}
        onClick={() => setShowSettings(!showSettings)}
      >
        <Tune />
      </IconButton>
      <SettingsMenu
        open={showSettings}
        onClose={() => setShowSettings(false)}
        anchorEl={menuButtonRef.current}
      >
        <SettingsMenuItem divider>
          <MenuIcon>
            <Assistant />
          </MenuIcon>
          <Typography>Automod</Typography>
          <Switch
            checked={isAutomodEnabled}
            onChange={() => setShowAutomodDialog(true)}
            disabled={isLoading || isRefetching || isUpdatingConfig}
          />
        </SettingsMenuItem>
        <SettingsMenuItem divider>
          <MenuIcon>
            <Assistant />
          </MenuIcon>
          <Typography>AI Automod</Typography>
          <Switch
            checked={isAiAutomodEnabled}
            onChange={() => setShowAiAutomodDialog(true)}
            disabled={isLoading || isRefetching || isUpdatingConfig}
          />
        </SettingsMenuItem>
        <SettingsMenuItem divider>
          <MenuIcon>
            <EmojiEmotions />
          </MenuIcon>
          <Typography>Emoji only</Typography>
          <Switch
            checked={isChatEmojiOnly}
            onChange={() => setShowEmojiOnlyDialog(true)}
            disabled={isLoading || isRefetching || isUpdatingConfig}
          />
        </SettingsMenuItem>
        <SettingsMenuItem>
          <MenuIcon>
            <Speed />
          </MenuIcon>
          <Typography>Slow mode</Typography>
          <Switch
            checked={isInSlowMode}
            onChange={() => setShowSlowModeDialog(true)}
            disabled={isLoading || isRefetching || isUpdatingConfig}
          />
          <Grow in={isInSlowMode} timeout={300} unmountOnExit mountOnEnter>
            <OutlinedInput
              size="small"
              sx={{ width: "5.1rem" }}
              type="number"
              value={slowModeBuffer}
              onChange={(e) =>
                setSlowModeBuffer(Number(e.target.value.replace(/\D/, "")))
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowSlowModeBufferDialog(true);
                }
              }}
            />
          </Grow>
        </SettingsMenuItem>
      </SettingsMenu>
      <ConfirmAutomodAction
        open={showAutomodDialog}
        onClose={() => setShowAutomodDialog(false)}
        onConfirm={() => {
          updateChatRoom({
            roomId: chatId,
            isAutomodActive: !isAutomodEnabled,
          });
          setIsAutomodEnabled(!isAutomodEnabled);
          setShowAutomodDialog(false);
        }}
        currentSwitchState={isAutomodEnabled}
      />
      <ConfirmAutomodAction
        open={showAiAutomodDialog}
        onClose={() => setShowAiAutomodDialog(false)}
        onConfirm={() => {
          updateChatRoom({
            roomId: chatId,
            isAiAutomodActive: !isAiAutomodEnabled,
          });
          setIsAiAutomodEnabled(!isAiAutomodEnabled);
          setShowAiAutomodDialog(false);
        }}
        currentSwitchState={isAiAutomodEnabled}
      />
      <ConfirmEmojiOnlyAction
        open={showEmojiOnlyDialog}
        onClose={() => setShowEmojiOnlyDialog(false)}
        onConfirm={() => {
          updateChatRoom({
            roomId: chatId,
            emojiOnlyMode: !isChatEmojiOnly,
          });
          setIsChatEmojiOnly(!isChatEmojiOnly);
          setShowEmojiOnlyDialog(false);
        }}
        currentSwitchState={isChatEmojiOnly}
      />
      <ConfirmSlowModeAction
        open={showSlowModeDialog}
        onClose={() => setShowSlowModeDialog(false)}
        onConfirm={() => {
          updateChatRoom({
            roomId: chatId,
            slowMode: !isInSlowMode,
          });
          setIsInSlowMode(!isInSlowMode);
          setShowSlowModeDialog(false);
        }}
        currentSwitchState={isInSlowMode}
        interpolateValue={slowModeBuffer}
      />
      <ConfirmSlowModeBufferAction
        open={showSlowModeBufferDialog}
        onClose={() => setShowSlowModeBufferDialog(false)}
        onConfirm={() => {
          updateChatRoom({
            roomId: chatId,
            slowModeInterval: slowModeBuffer,
          });
          setShowSlowModeBufferDialog(false);
        }}
        slowModeBuffer={slowModeBuffer}
      />
      <Snackbar
        open={showErrorSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowErrorSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error" variant="filled">
          Error updating chat room settings
        </Alert>
      </Snackbar>
    </ChatSettingsWrapper>
  );
};

export default ChatSettings;
